import { push } from 'connected-react-router'
import CartSelectors from 'modules/domain/cart/selectors'
import { ProductActions } from 'modules/domain/product/duck'
import { apiCall } from 'modules/sagaEffects'
import { generateCountryPath } from 'modules/sagaHelpers'
import { Progress } from 'modules/types'
import { all, call, debounce, delay, put, race, select, take, takeLatest } from 'redux-saga/effects'
import { RequestError } from 'service/api/errors'
import { Promocode, PromocodeType } from 'types/entities/promocode'
import CartRoutes from 'views/pages/Cart/routes'
import { DEFAULT_QTY } from 'views/pages/Producer/Product/ProductItem/AdderWidget/constants'
import CartActions from './duck'
import {
  addItem,
  clearCart,
  deleteItem,
  fetchCart,
  fetchProductWizard,
  fetchPromocodeStatus,
  updateItem,
} from './managers'
import { AddCartItemParams, CartItem, CartItemResponse, PromocodeErrorType, UpdateCartItemParams } from './types'
import { v4 as uuid } from 'uuid'

export const cartInitSaga = function*(_action: ReturnType<typeof CartActions.cartInitRequested>) {
  try {
    const initProgress = yield select(CartSelectors.initProgress)

    if (initProgress === Progress.SUCCESS) {
      return
    }
    const cart = yield call(apiCall, fetchCart)

    yield put(CartActions.cartInitRequestSucceed(cart))
  } catch (err) {
    yield put(CartActions.cartInitRequestFailed())
  }
}

export const refreshCartSaga = function*(_action: ReturnType<typeof CartActions.refreshCartRequested>) {
  try {
    const promocodes: Promocode[] = yield select(CartSelectors.allPromocodes)
    const cart = yield call(
      apiCall,
      fetchCart,
      promocodes.map(p => p.code),
    )
    yield put(CartActions.refreshCartRequestSucceed(cart))
  } catch (err) {
    yield put(CartActions.refreshCartRequestFailed())
  }
}

export const itemAddSaga = function*({ payload }: ReturnType<typeof CartActions.itemAddRequested>) {
  const [product_id, producer, params, pendingId = uuid()] = payload
  try {
    yield put(CartActions.itemAdd(product_id, producer, pendingId))
    const item: CartItemResponse = yield call(apiCall, addItem, {
      ...params,
      seed_treatment_id: params.seedTreatment,
      quantity: params.qty,
      product_id,
      producer_id: producer.id,
    })
    yield put(
      CartActions.itemAddSucceed(
        {
          images: item.product.images,
          title: item.product.title_i18n,
          description: item.product.description_i18n,
          default_packaging: item.product.default_packaging_i18n,
          qty: params.qty,
          ...item,
        },
        pendingId,
      ),
    )
    yield put(ProductActions.discountsRequested(item.product_id, params.qty))
    yield put(CartActions.refreshCartRequested())
  } catch (err) {
    yield put(CartActions.itemAddFailed(pendingId))
  }
}

export const fakeItemAddSaga = function*({ payload }: ReturnType<typeof CartActions.fakeItemAddRequested>) {
  const [product, params, fakeId = uuid()] = payload
  try {
    yield put(CartActions.fakeItemAdd(product, payload[1], fakeId))
    const item = yield call(apiCall, addItem, {
      ...params,
      seed_treatment_id: params.seedTreatment,
      quantity: params.qty,
      product_id: product.id,
      producer_id: product.producer_id,
    })
    yield put(CartActions.fakeItemAddSucceed())
    yield put(ProductActions.discountsRequested(item.product_id, params.qty))
    yield put(CartActions.refreshCartRequested())
  } catch (err) {
    yield put(CartActions.itemAddFailed(fakeId))
  }
}

export const itemsAddSaga = function*({
  payload: [orders, producerSlug],
}: ReturnType<typeof CartActions.itemsAddRequested>) {
  try {
    const data: AddCartItemParams[] = orders.map(order => ({
      packaging: order.packaging,
      product_id: order.productId,
      producer_id: order.producerId,
      quantity: order.qty,
      seed_treatment_id: order.seedTreatment,
      options: order.options,
      wizard_comment: order.wizard_comment,
    }))

    const items = yield all(data.map((params: AddCartItemParams) => call(apiCall, addItem, params)))

    yield put(CartActions.itemsAddSucceed())
    yield all(items.map(item => put(ProductActions.discountsRequested(item.product_id, item.quantity))))
    yield put(CartActions.refreshCartRequested())
    const path = yield call(generateCountryPath, CartRoutes.ProducerCart, { producerSlug })
    yield put(push(path))
  } catch (err) {
    yield put(CartActions.itemsAddFailed())
  }
}

export const itemUpdateSaga = function*({ payload, type }: ReturnType<typeof CartActions.itemUpdateRequested>) {
  const [id, { quantity, packaging, seed_treatment_id, options, wizard_data, wizard_comment }] = payload
  const itemBeforeUpdate = yield select(CartSelectors.cartEntry, id)
  try {
    yield put(CartActions.itemUpdated(...payload))
    const { needToDebounce } = yield race({
      timeout: delay(300),
      needToDebounce: take(type),
    })
    if (needToDebounce) {
      return
    }

    const params: UpdateCartItemParams = {}
    if (quantity === 0) {
      return
    }
    params.quantity = quantity
    if (typeof packaging !== 'undefined') {
      params.packaging = packaging
    }
    if (seed_treatment_id) {
      params.seed_treatment_id = seed_treatment_id
    }
    if (options) {
      params.options = options
    }
    if (wizard_data) {
      params.wizard_data = wizard_data
    }
    if (wizard_comment) {
      params.wizard_comment = wizard_comment
    }
    const { item, ignore } = yield race({
      item: call(apiCall, updateItem, id, params),
      ignore: take(type),
    })

    // we need to cancel that 👆 api call, but ok for now
    if (ignore) {
      return
    }
    yield put(CartActions.itemUpdateSucceed(item))
    yield put(ProductActions.discountsRequested(item.product_id, quantity || DEFAULT_QTY))
    yield put(CartActions.refreshCartRequested())
  } catch (err) {
    yield put(CartActions.itemUpdateFailed(id, itemBeforeUpdate))
  }
}

export const itemRemoveSaga = function*({ payload: id }: ReturnType<typeof CartActions.itemRemoveRequested>) {
  const removedItem: CartItem = yield select(CartSelectors.cartEntry, id)
  const cartInfo = yield removedItem.product.producer
    ? select(CartSelectors.producerCartInfo, removedItem.product.producer.slug)
    : undefined

  try {
    const allEntries: CartItem[] = yield select(CartSelectors.cartEntries)
    const shouldRemoveCartInfo =
      Object.values(allEntries).filter(entry => entry.producer_id === removedItem.producer_id).length <= 1

    yield put(CartActions.itemRemove(id))

    if (cartInfo && shouldRemoveCartInfo) {
      yield put(CartActions.cartInfoRemove(cartInfo.slug))
    }

    yield call(apiCall, deleteItem, id)
    yield put(CartActions.itemRemoveSucceed())
    yield put(CartActions.refreshCartRequested())
  } catch (err) {
    yield put(CartActions.itemRemoveFailed(removedItem))
    yield put(CartActions.cartInfoRemoveFailed(cartInfo))
  }
}

export const clearCartSaga = function*({ payload: producerId }: ReturnType<typeof CartActions.clearRequested>) {
  try {
    yield call(apiCall, clearCart, { producer_ids: [producerId] })
    yield put(CartActions.clearRequestSucceed())
    yield put(CartActions.refreshCartRequested())
  } catch (e) {
    yield put(CartActions.clearRequestFailed())
  }
}

export const checkPromocode = function*({ payload: params }: ReturnType<typeof CartActions.checkPromocode>) {
  try {
    const appliedPromocodes = yield select(CartSelectors.promocodes, params.producerSlug)
    const response: Promocode = yield call(apiCall, fetchPromocodeStatus, {
      ...params,
      appliedPromocodes: appliedPromocodes.map((p: Promocode) => p.code),
    })
    yield put(CartActions.checkPromocodeSuccess(params.producerSlug, params.code, response))
    if (response.params.type !== PromocodeType.Simple) {
      yield put(CartActions.selectEditablePromocode(params.producerSlug, params.code))
    }
    yield put(CartActions.refreshCartRequested())
  } catch (e) {
    const errType = e instanceof RequestError ? e.type : 'unknown'
    yield put(CartActions.checkPromocodeError(errType as PromocodeErrorType))
  }
}

export const productWizardSaga = function*({
  payload: [id, params],
}: ReturnType<typeof CartActions.productWizardRequested>) {
  try {
    const response = yield call(apiCall, fetchProductWizard, id, params)
    yield put(CartActions.productWizardSucceed([response]))
  } catch (e) {
    yield put(CartActions.productWizardFailed())
  }
}

const CartSaga = function*() {
  yield all([
    takeLatest(CartActions.cartInitRequested.type, cartInitSaga),
    takeLatest(CartActions.refreshCartRequested.type, refreshCartSaga),
    debounce(50, CartActions.fakeItemAddRequested.type, fakeItemAddSaga),
    debounce(50, CartActions.itemAddRequested.type, itemAddSaga),
    takeLatest(CartActions.itemsAddRequested.type, itemsAddSaga),
    takeLatest(CartActions.itemUpdateRequested.type, itemUpdateSaga),
    takeLatest(CartActions.itemRemoveRequested.type, itemRemoveSaga),
    takeLatest(CartActions.clearRequested.type, clearCartSaga),
    takeLatest(CartActions.checkPromocode.type, checkPromocode),
    takeLatest(CartActions.removePromocode.type, refreshCartSaga),
    takeLatest(CartActions.productWizardRequested.type, productWizardSaga),
  ])
}

export default CartSaga

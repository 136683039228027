import { Button, Currency, Input, NumberInput, Progress, SimpleSelect, useAction } from '@agro-club/frontend-shared'
import { FormikErrors, FormikTouched, useFormik } from 'formik'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useCountryPath } from 'hooks/useCountryPath'
import useLangPicker from 'hooks/useLangPicker'
import CartActions from 'modules/domain/cart/duck'
import CartSelectors from 'modules/domain/cart/selectors'
import { useProductDiscounts } from 'modules/domain/product/hooks'
import ProductSelectors from 'modules/domain/product/selectors'
import { ProductOptions as ProductOptionsType } from 'modules/domain/productOptions/types'
import { productToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import styled from 'styled-components'
import Link from 'views/components/Link/Link'
import ProductOptions from 'views/components/ProductOptions/ProductOptions'
import { placeholderStyleFn } from 'views/components/ProductOptions/styled'
import { Routes } from 'views/pages/routes'
import { HowItWorksButton } from 'views/ui/HowItWorks/HowItWorks'
import * as Yup from 'yup'
import { ProductItemDto } from '../ProductItem'
import * as Styled from '../styled'
import { AdderWidgetProps } from './AdderWidgetManager'
import { ChangeQtyType } from './constants'
import AdderWidgetEvents from './events'

const ContentGroup = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;

  &:not(:first-child) {
    margin-top: 16px;
  }
`

const QuantityContentGroup = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
  margin-bottom: 48px;
`

const GroupTitle = styled.div`
  margin-right: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  & > a {
    display: flex;
    flex-direction: column;
  }
  & > * {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
`
const Packaging = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
`

const MaxQtyHint = styled.div`
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: ${props => props.theme.color.backgroundBase};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 8px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`

const NumberInputContainer = styled.div`
  position: relative;
`

const MinMaxContainer = styled.p`
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  margin: 0;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
`

const MinMax: React.FC<{ min?: number; max?: number }> = ({ min, max }) => {
  const { t } = useTranslation('order')
  const content = useMemo(
    () => [min && t('minPcs', { count: min }), max && t('maxPcs', { count: max })].filter(Boolean).join('; '),
    [min, max, t],
  )
  if (!min && !max) {
    return null
  }

  return <MinMaxContainer>{content}</MinMaxContainer>
}

export const PackagingInput = styled(Input)`
  max-width: 100px;
  justify-self: end;
`

const SeedTreatmentSelect: React.FC<{
  product: ProductItemDto
  value?: string
  onChange: (val: string) => void
  formikErrors?: FormikErrors<{ seedTreatment: string }>
  formikTouched?: FormikTouched<{
    seedTreatment: string
  }>
}> = ({ product, value, onChange, formikErrors, formikTouched }) => {
  const { t } = useTranslation(['order', 'productOptions'])
  const { pick } = useLangPicker()

  const treatmentOptions = useMemo(
    () => product.activeSeedTreatment.map(st => ({ id: st.id, title: pick(st.title_i18n) })),
    [product.activeSeedTreatment, pick],
  )
  return treatmentOptions.length ? (
    <SectionWrapper data-test-id={'seed-treatment-select'}>
      <ContentGroup>
        <SectionTitle>{t('sections.seedTreatment')}</SectionTitle>
        <SimpleSelect
          aria-label={t('sections.seedTreatmentSelectLabel')}
          options={treatmentOptions}
          value={value}
          onChange={onChange}
          name={'seed-treatment-select'}
          required
          errorText={formikErrors?.seedTreatment}
          invalid={formikTouched?.seedTreatment && !!formikErrors?.seedTreatment}
          customStyles={{
            placeholder: placeholderStyleFn,
          }}
          placeholder={`${t('productOptions:placeholderPrefix')} ${t(
            `productOptions:types.seed_treatment`,
          ).toLowerCase()}`}
        />
      </ContentGroup>
    </SectionWrapper>
  ) : null
}

export type AdderWidgetMobileProps = Omit<AdderWidgetProps, 'values'> & {
  productOptions: ProductOptionsType[]
  isQtyDisabled: boolean
} & AdderWidgetProps['values']

type AlreadyAddedProps = Omit<AdderWidgetMobileProps, 'producer'>

const AlreadyAdded: React.FC<AlreadyAddedProps> = ({
  product,
  qty,
  onQtyChange,
  seedTreatment,
  onSeedTreatmentChange,
  options,
  onOptionsChange,
  productOptions,
  cartItem,
  isQtyDisabled,
}) => {
  const generateCountryPath = useCountryPath()
  const { t } = useTranslation('order')
  const currentDiscountAmount = useSelector(ProductSelectors.currentDiscountPerBag)
  const nextLvlQty = useSelector(ProductSelectors.nextLvlQty)

  const [, discounts] = useProductDiscounts()
  const hasNoDiscounts = !discounts || !discounts.length

  const { track } = useAnalyticsSSR()
  const { producerSlug } = useParams<{ producerSlug: string }>()

  const isFake = Boolean(cartItem?.is_fake)

  const handleAddDiscountSuggestionQty = (addQty: number) => {
    if (!isFake) {
      const newQty = (qty || 0) + addQty
      track(AdderWidgetEvents.ChageProductQtyInTruck, {
        ...productToEventParams(product),
        type: ChangeQtyType.AddMore,
        quantity: newQty,
      })
      onQtyChange(newQty)
    }
  }

  const isMaxQtyReached = !!(product.max_qty && qty && qty >= product.max_qty)

  return (
    <Styled.Container>
      <QuantityContentGroup>
        <GroupTitle>{t('sections.quantity')}</GroupTitle>
        <NumberInputContainer>
          <NumberInput
            disabled={isFake || isQtyDisabled}
            min={product.min_qty}
            max={product.max_qty}
            value={qty || 0}
            onChange={qty => onQtyChange(qty)}
            size={'large'}
            onDecrease={() =>
              track(AdderWidgetEvents.ChageProductQtyInTruck, {
                ...productToEventParams(product),
                type: ChangeQtyType.Minus,
              })
            }
            onIncrease={() =>
              track(AdderWidgetEvents.ChageProductQtyInTruck, {
                ...productToEventParams(product),
                type: ChangeQtyType.Plus,
              })
            }
            testId={'item-count'}
          />
          <MinMax min={product.min_qty} max={product.max_qty} />
        </NumberInputContainer>
      </QuantityContentGroup>
      {isMaxQtyReached ? <MaxQtyHint>{t('maxQtyHint')}</MaxQtyHint> : null}
      <ContentGroup style={{ marginBottom: '16px' }}>
        <GroupTitle>{t('sections.package')}</GroupTitle>
        <Packaging>{product.default_packaging}</Packaging>
      </ContentGroup>
      {!hasNoDiscounts && (
        <Styled.DiscountSuggestion
          disabled={cartItem?.is_fake}
          nextLvlQty={nextLvlQty}
          currentDiscountAmount={currentDiscountAmount}
          currency={product.producer?.currency || Currency.CAD}
          units={product.units}
          onQtyAdd={handleAddDiscountSuggestionQty}
        />
      )}
      <SeedTreatmentSelect product={product} value={seedTreatment} onChange={onSeedTreatmentChange} />
      <ProductOptions
        selectedIds={options}
        options={product.options}
        onChange={onOptionsChange}
        optionsRequiredErrors={[]}
        productOptions={productOptions}
      />
      {!!product.alt_packaging && (
        <ContentGroup>
          <GroupTitle>
            {t('sections.packaging')}
            <br />
            {product.alt_packaging}
          </GroupTitle>
        </ContentGroup>
      )}
      <ButtonsGroup>
        <Link to={generateCountryPath(Routes.ProducerCart, { producerSlug })}>
          <Button
            intent={'secondary'}
            size={'big'}
            onClick={() => track(AdderWidgetEvents.GoToCheckout, { ...productToEventParams(product), quantity: qty })}
            //disabled={isFake}
            progress={isFake ? Progress.WORK : Progress.IDLE}
          >
            {t('gotoCheckout')}
          </Button>
        </Link>
      </ButtonsGroup>
    </Styled.Container>
  )
}

const HowItWorksButtonStyled = styled(HowItWorksButton)`
  margin: 30px auto 0;
`

const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${props => props.theme.color.secondary200};
`

const SectionHint = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
  margin-top: 5px;
  margin-bottom: 15px;
`

const SectionWrapper = styled.div`
  margin-top: 16px;
`

type NotAddedProps = Omit<AdderWidgetMobileProps, 'cartItem'>

const NotAdded: React.FC<NotAddedProps> = ({
  product,
  qty = 1,
  onQtyChange,
  seedTreatment,
  onSeedTreatmentChange,
  options,
  onOptionsChange,
  productOptions,
  isQtyDisabled,
}) => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation('order')
  const addAction = useAction(CartActions.fakeItemAddRequested)
  const currentDiscountAmount = useSelector(ProductSelectors.currentDiscountPerBag)
  const hasSimilarProductInCart = useSelector(state => CartSelectors.hasCategory(state, product.subcategory_id || ''))
  const [, discounts] = useProductDiscounts()
  const nextLvlQty = useSelector(ProductSelectors.nextLvlQty)
  const hasNoDiscounts = !discounts || !discounts.length

  const validator = useMemo(() => {
    return Yup.object({
      seedTreatment: Yup.string(),
    })
  }, [])

  const formik = useFormik({
    initialValues: {
      seedTreatment: seedTreatment || '',
    },
    validationSchema: validator,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    enableReinitialize: true,
    validateOnMount: true,
  })

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    formik.submitForm()

    if (formik.isValid) {
      track(AdderWidgetEvents.AddToTruckClick, { ...productToEventParams(product), quantity: qty })
      addAction(product, {
        qty,
        packaging: null,
        seedTreatment,
        options,
      })
    }
  }

  const handleHowItWorksClick = () => {
    track(AdderWidgetEvents.HowItWorksClick, productToEventParams(product))
  }

  const handleAddDiscountSuggestionQty = (addQty: number) => {
    const newQty = (qty || 0) + addQty
    track(AdderWidgetEvents.ChangeProductQty, {
      ...productToEventParams(product),
      type: ChangeQtyType.AddMore,
      quantity: newQty,
    })
    onQtyChange(newQty)
  }

  return (
    <Styled.Container>
      <QuantityContentGroup>
        <GroupTitle>{t('sections.quantity')}</GroupTitle>
        <NumberInputContainer>
          <NumberInput
            min={product.min_qty}
            max={product.max_qty}
            onDecrease={() =>
              track(AdderWidgetEvents.ChangeProductQty, { ...productToEventParams(product), type: ChangeQtyType.Minus })
            }
            onIncrease={() =>
              track(AdderWidgetEvents.ChangeProductQty, { ...productToEventParams(product), type: ChangeQtyType.Plus })
            }
            value={qty || 0}
            onChange={onQtyChange}
            size={'large'}
            testId={'item-count'}
            disabled={isQtyDisabled}
          />
          <MinMax min={product.min_qty} max={product.max_qty} />
        </NumberInputContainer>
      </QuantityContentGroup>
      <ContentGroup style={{ marginBottom: '16px' }}>
        <GroupTitle>{t('sections.package')}</GroupTitle>
        <Packaging>{product.default_packaging}</Packaging>
      </ContentGroup>
      {!hasNoDiscounts && (
        <Styled.DiscountSuggestion
          nextLvlQty={nextLvlQty}
          currency={product.producer?.currency || Currency.CAD}
          currentDiscountAmount={currentDiscountAmount}
          units={product.units}
          isCartEmpty={!hasSimilarProductInCart}
          onQtyAdd={handleAddDiscountSuggestionQty}
        />
      )}
      <form onSubmit={handleFormSubmit}>
        <SeedTreatmentSelect
          product={product}
          value={seedTreatment}
          onChange={value => {
            formik.setFieldValue('seedTreatment', value)
            onSeedTreatmentChange(value)
          }}
          formikErrors={formik.errors}
          formikTouched={formik.touched}
        />
        <ProductOptions
          selectedIds={options}
          options={product.options}
          onChange={onOptionsChange}
          optionsRequiredErrors={[]}
          productOptions={productOptions}
        />
        {!!product.alt_packaging && (
          <SectionWrapper>
            <SectionTitle>{t('sections.packaging')}</SectionTitle>
            <SectionHint>{t('packagingHint')}</SectionHint>
          </SectionWrapper>
        )}
        {!!product.docusign_document_id && (
          <SectionWrapper>
            <SectionTitle>{t('sections.legalNotesHeader')}</SectionTitle>
            <SectionHint>{t('sections.legalNotesDescription')}</SectionHint>
          </SectionWrapper>
        )}
        <ButtonsGroup>
          <Button type={'submit'} id={'add-to-cart-button'} size={'big'} intent={'primary-action'} filled>
            {t('addToCart')}
          </Button>
        </ButtonsGroup>
      </form>
      <HowItWorksButtonStyled
        items={product.producer?.config?.seller_config?.how_it_works || []}
        onClick={handleHowItWorksClick}
      />
    </Styled.Container>
  )
}

const AdderWidgetMobile: React.VFC<AdderWidgetMobileProps> = ({ cartItem, isQtyDisabled, ...props }) => {
  return (
    <div data-test-id="adder-widget-mobile">
      {cartItem ? (
        <AlreadyAdded {...props} cartItem={cartItem} isQtyDisabled={isQtyDisabled} />
      ) : (
        <NotAdded {...props} isQtyDisabled={isQtyDisabled} />
      )}
    </div>
  )
}

export default AdderWidgetMobile

import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  #drawer {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    overflow-y: auto;
    &[data-open='true']{
      display: flex;
    }
  }

  a {
    color: ${props => props.theme.color.primary600};
  }

  .primaryAction {
    color: ${props => props.theme.color.onPrimaryDark};
    background-color: ${props => props.theme.color.accentApproving100 + '22'};

    &:hover {
      background-color: ${props => props.theme.color.accentApproving100 + '44'};
      color: ${props => props.theme.color.onPrimaryDark};
    }

    &:focus {
      background-color: ${props => props.theme.color.accentApproving100 + '44'};
      box-shadow: none;
    }
  }

`

export default GlobalStyle

import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css, StyledProps } from 'styled-components'
// import Segment from 'views/ui/Segment/Segment'
import { toShortFullYear } from 'helpers/date'
import Link from 'views/components/Link/Link'
import ProfileRoutes from 'views/pages/Profile/routes'
import { useDocumentList } from 'modules/domain/documents/hooks'
// import { useSelector } from 'react-redux'
// import DocumentSelectors from 'modules/domain/documents/selectors'
import DocumentActions from 'modules/domain/documents/duck'
// import { DocumentStatus } from 'modules/domain/documents/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { Progress } from 'modules/types'
import { Button, SectionBody, SectionContainer, SectionTitle, useAction } from '@agro-club/frontend-shared'
import { useCountryPath } from 'hooks/useCountryPath'

type Status = 'signed' | 'need_sign' | 'envelope_created' | 'processing'
type Item = {
  id: string
  status: Status
  date: string
  name: string
}

// const FilterWrapper = styled.div`
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 20px;
// `

const TitleInner = styled.div`
  display: grid;
  align-items: center;
  grid-gap: 28px;
  grid-template-columns: auto auto;

  ${props => props.theme.media.mobile`
    width: 100%;
    justify-content: space-between;
  `}
`

const ListContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;

  ${props => props.theme.media.mobile`
    flex-direction: column;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  `}
`

const ItemWrapper = styled(Link)`
  color: #000;
  border: 1px solid ${props => props.theme.color.outlineNeutral};
  min-height: 88px;
  border-radius: 16px;
  padding: 12px 8px 8px 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'title title'
    'date status';
  transition: background-color 0.1s ease-in, border-color 0.1s ease-in;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.color.primary5};
    border-color: transparent;
  }
`
const ItemTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  grid-area: title;
`
const ItemDate = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  grid-area: date;
  justify-self: start;
  align-self: end;
`

const statusCss = (props: StyledProps<{ status: Status }>) => {
  let bgColor = 'none'
  let color = '#000'
  switch (props.status) {
    case 'need_sign':
    case 'envelope_created':
      bgColor = props.theme.color.accentNotify
      color = props.theme.color.onPrimaryLight
      break
    case 'signed':
      bgColor = props.theme.color.backgroundApproved
      color = props.theme.color.accentApproving
      break
    case 'processing':
      bgColor = props.theme.color.primary500
      color = props.theme.color.onPrimaryLight
      break
  }
  return css`
    color: ${color};
    background-color: ${bgColor};
  `
}

const ItemStatus = styled.div<{ status: Status }>`
  border-radius: 12px;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  grid-area: status;
  padding: 0 8px;
  justify-self: end;
  align-self: end;
  ${statusCss}
`

const EmptyList = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.secondary300};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 40px;
`

const ErrorText = styled(EmptyList)``

const DocumentItem: React.FC<Item> = ({ status, name, date, id }) => {
  const generateCountryPath = useCountryPath()
  const { t } = useTranslation('profile')
  const statusText: Record<Status, string> = {
    signed: t('documentsList.status.signed'),
    processing: t('documentsList.status.processing'),
    envelope_created: t('documentsList.status.unsigned'),
    need_sign: t('documentsList.status.needToSign'),
  }

  return (
    <ItemWrapper
      data-test-id={'document-item'}
      to={generateCountryPath(ProfileRoutes.DocumentDetails, { documentId: id })}
    >
      <ItemTitle>{name}</ItemTitle>
      <ItemDate>{toShortFullYear(date)}</ItemDate>
      <ItemStatus data-test-id={'document-status'} status={status}>
        {statusText[status]}
      </ItemStatus>
    </ItemWrapper>
  )
}

const SectionBodyStyled = styled(SectionBody)`
  padding: 24px 40px;
  ${props => props.theme.media.tablet`
    padding: 12px 24px;
  `}
  ${props => props.theme.media.mobile`
    padding: 12px 16px;
  `}
`

const Component: React.FC<{ title?: React.ReactNode }> = ({ title, children }) => {
  return (
    <SectionContainer noDivider>
      <SectionTitle>{title}</SectionTitle>
      <SectionBodyStyled>{children}</SectionBodyStyled>
    </SectionContainer>
  )
}

const DocumentsList: React.FC = () => {
  const { t } = useTranslation('profile')
  // const filter = useSelector(DocumentSelectors.filter)
  // const updateFilerAction = useAction(DocumentActions.filterUpdated)
  const listRequestAction = useAction(DocumentActions.listRequested)
  const [progress, list] = useDocumentList()

  // const filters = [
  //   {
  //     title: t('documentsList.filter.all'),
  //     id: 'all',
  //   },
  //   {
  //     title: t('documentsList.filter.signed'),
  //     id: 'signed',
  //   },
  //   {
  //     title: t('documentsList.filter.unsigned'),
  //     id: 'unsigned',
  //   },
  // ]
  //
  // const selectedFilter = filter.status ? filter.status : 'all'
  // const handleUpdateStatusFilter = (statusValue: string) => {
  //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //   const { status, ...rest } = filter
  //   if (statusValue === 'all') {
  //     updateFilerAction(rest)
  //   } else {
  //     updateFilerAction({
  //       ...rest,
  //       status: statusValue as DocumentStatus,
  //     })
  //   }
  // }

  const title = (
    <TitleInner>
      <div>{t('documentsList.title')}</div>
      {/*<FilterWrapper>*/}
      {/*  <Segment onChange={handleUpdateStatusFilter} name={'filter'} items={filters} value={selectedFilter} />*/}
      {/*</FilterWrapper>*/}
    </TitleInner>
  )

  if (progress === Progress.WORK || progress === Progress.IDLE) {
    return (
      <Component title={title}>
        <SpinnerLayout />
      </Component>
    )
  }

  if (progress === Progress.ERROR || !list) {
    return (
      <Component title={title}>
        <ErrorText>
          {t('documentsList.listFetchError')}
          <br />
          <Button intent={'primary'} onClick={() => listRequestAction({})}>
            {t('documentsList.retryButtonText')}
          </Button>
        </ErrorText>
      </Component>
    )
  }

  if (!list.length) {
    return (
      <Component title={title}>
        <EmptyList>{t('documentsList.emptyList')}</EmptyList>
      </Component>
    )
  }

  return (
    <Component title={title}>
      <ListContainer>
        {list.map(item => (
          <DocumentItem {...item} key={item.id} />
        ))}
      </ListContainer>
    </Component>
  )
}

export default DocumentsList

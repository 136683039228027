import React from 'react'
import styled from 'styled-components'
import QuoteIcon from './quote.svg'

const QuoteAuthor = styled.div`
  font-weight: bold;
`

const QuoteContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${props => props.theme.color.primary50};
  font-size: 16px;
  line-height: 24px;
  padding: 40px 24px 16px;

  ${props => props.theme.media.desktop`
    margin-top: 48px;
  `}

  ${props => props.theme.media.tablet`
    margin-top: 40px;
  `}

  ${props => props.theme.media.mobile`
    margin-top: 24px;
  `}
  position: relative;
  &::before {
    position: absolute;
    top: -24px;
    left: 24px;
    width: 64px;
    height: 64px;
    content: '';
    background-image: url("${QuoteIcon}");
  }
`

const QuoteAuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;

  > *:not(:first-child) {
    margin-left: 4px;
  }
`

export const QuoteBlock: React.FC<{
  text: string
  author?: string
  company?: string
}> = ({ text, author, company }) => {
  const authorEl = author ? <QuoteAuthor>{author}</QuoteAuthor> : null
  const companyEl = company ? (
    <div>
      {author ? ' — ' : ''}
      {company}
    </div>
  ) : null

  return (
    <QuoteContainer>
      <div data-test-id="quote-text">{text}</div>
      {author || company ? (
        <QuoteAuthorContainer data-test-id="quote-author">
          {authorEl}
          {companyEl}
        </QuoteAuthorContainer>
      ) : null}
    </QuoteContainer>
  )
}

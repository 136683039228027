import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, BackButton, Input, useAction, useDidMount } from '@agro-club/frontend-shared'
import { useFormik } from 'formik'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import { AnalyticsEvent, Progress } from 'modules/types'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import { Page } from 'modules/utils/analytics-utils/pageNames'

const DELAY = 60

const SmsCodeInfo = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

const SmsInputContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 4fr;
  margin-top: 16px;
`

const RetryDelay = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

const SmsFormButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 48px;

  & > *:not(:last-child) {
    margin-right: 16px;
  }
`

const SmsSubmitButtonContainer = styled.div`
  & > * {
    width: 100%;
  }
  width: 180px;

  ${props => props.theme.media.mobile`
    width: 100%;
  `}
`

const RetryBtn = styled.button`
  border: none;
  background: none;
  box-shadow: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.primary500};
  text-decoration: underline;
  text-align: left;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const RetryWrapper = styled.div`
  display: flex;
  align-items: end;
`

const RetryInnerWrapper = styled.div`
  display: flex;
  height: 70%;
  align-items: center;
`

const SmsCancelButtonContainer = styled.div``

export type SmsCodeVerificationFormProps = {
  code: string
}

const analyticsParametersName = {
  signInSmsBack: 'signin_sms_back',
  smsConfirmTap: 'signin_sms_confirm_tap',
  smsResendCodeTap: 'signin_sms_resend_code_tap',
}

type PropsType = {
  phone: string
  onSubmit(props: SmsCodeVerificationFormProps): void
}

const SmsCodeVerificationForm: React.FC<PropsType> = ({ phone, onSubmit }) => {
  const { t } = useTranslation(['auth', 'common'])
  const progress = useSelector(AuthSelectors.smsVerificationProgress)
  const backAction = useAction(AuthActions.setStep, 'credentials')
  const submitPhoneAction = useAction(AuthActions.phoneSubmitted)
  const { track } = useAnalyticsSSR()
  const detectedCountry = useDetectedCountry()
  const codeInputRef = useRef<HTMLInputElement>(null)
  const formik = useFormik<SmsCodeVerificationFormProps>({
    initialValues: {
      code: '',
    },
    onSubmit,
  })

  const [countdown, setCountdown] = useState(DELAY)
  const [timer, setTimer] = useState<number | null>(null)
  const [blocked, setBlocked] = useState(false)

  useDidMount(() => {
    track(AnalyticsEvent.Page, {
      name: Page.SignInSmsScreen,
      country: detectedCountry,
    })
  })

  useEffect(() => {
    if (countdown) {
      const timer = window.setTimeout(() => {
        setCountdown(value => Math.max(value - 1, 0))
      }, 1000)
      setTimer(timer)
    }
  }, [countdown])

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [timer])

  useEffect(() => {
    codeInputRef.current?.focus()
  }, [])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    formik.submitForm()
    setBlocked(true)
    track(analyticsParametersName.smsConfirmTap, { country: detectedCountry })
  }

  const handleRetrySmsCode = () => {
    submitPhoneAction(phone)
    setCountdown(DELAY)
    track(analyticsParametersName.smsResendCodeTap, { country: detectedCountry })
  }

  const suggestedPhone = useMemo(() => {
    return `<span style="font-weight: 600;white-space: nowrap;">${phone}</span>`
  }, [phone])

  const handleInputFocus = useCallback(() => {
    setBlocked(false)
  }, [])

  return (
    <form onSubmit={handleSubmit} data-test-id="sms-form" id="sms-form">
      <SmsCodeInfo dangerouslySetInnerHTML={{ __html: t('codeSentMsg', { phone: suggestedPhone }) }} />
      <RetryDelay dangerouslySetInnerHTML={{ __html: t('codeEnteringTime') }} />
      <SmsInputContainer>
        <Input
          {...formik.getFieldProps('code')}
          label={t('smsCode')}
          limit={5}
          autoComplete={'one-time-code'}
          invalid={progress === Progress.ERROR}
          onFocus={handleInputFocus}
          ref={codeInputRef}
          errorText={t('wrongCode')}
        />
        <RetryWrapper>
          <RetryInnerWrapper>
            {countdown ? (
              <RetryDelay dangerouslySetInnerHTML={{ __html: t('retryDelay', { seconds: countdown }) }} />
            ) : (
              <RetryBtn onClick={handleRetrySmsCode}>{t('retry')}</RetryBtn>
            )}
          </RetryInnerWrapper>
        </RetryWrapper>
      </SmsInputContainer>
      <SmsFormButtonsContainer>
        <SmsCancelButtonContainer>
          <BackButton
            onClick={() => {
              track(analyticsParametersName.signInSmsBack, { country: detectedCountry })
              backAction()
            }}
            disabled={progress === Progress.WORK}
            size={'big'}
          />
        </SmsCancelButtonContainer>
        <SmsSubmitButtonContainer>
          <Button
            id="sms-form-next-button"
            intent={'primary-action'}
            filled
            size={'big'}
            type={'submit'}
            disabled={blocked}
          >
            {t('common:confirm')}
          </Button>
        </SmsSubmitButtonContainer>
      </SmsFormButtonsContainer>
    </form>
  )
}

export default SmsCodeVerificationForm

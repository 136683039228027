import {
  Button,
  IconQuestion,
  NumberInput,
  Progress,
  SectionBody,
  SectionContainer,
  SimpleSelect,
  Tooltip,
  useAction,
} from '@agro-club/frontend-shared'
import { FormikErrors, FormikTouched, useFormik } from 'formik'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useCountryPath } from 'hooks/useCountryPath'
import useLangPicker from 'hooks/useLangPicker'
import CartActions from 'modules/domain/cart/duck'
import CartSelectors from 'modules/domain/cart/selectors'
import { useProducer } from 'modules/domain/producer/hooks'
import { useProductDiscounts } from 'modules/domain/product/hooks'
import ProductSelectors from 'modules/domain/product/selectors'
import { ProductOptions as ProductOptionsType } from 'modules/domain/productOptions/types'
import { productToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import { getPrettyPrice } from 'modules/utils/helpers'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { Currency } from 'types/entities'
import Link from 'views/components/Link/Link'
import ProductOptions from 'views/components/ProductOptions/ProductOptions'
import { placeholderStyleFn } from 'views/components/ProductOptions/styled'
import ProducerRoutes from 'views/pages/Producer/routes'
import { Routes } from 'views/pages/routes'
import * as Yup from 'yup'
import { ProductItemDto } from '../ProductItem'
import * as Styled from '../styled'
import { AdderWidgetProps } from './AdderWidgetManager'
import { ChangeQtyType } from './constants'
import AdderWidgetEvents from './events'

const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-gap: 16px;
  & > a {
    display: flex;
    flex-direction: column;
  }
`

const Quantity = styled(NumberInput)`
  margin-top: 16px;
  margin-bottom: 16px;
`

const SectionWrapper = styled.div`
  margin-top: 16px;
`

const Packaging = styled.div`
  margin: 12px 0 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
`

const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${props => props.theme.color.secondary200};
`

const SectionHint = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
  margin-top: 5px;
  margin-bottom: 15px;
`

const OrderNowHint = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

const MaxQtyHint = styled.div`
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: ${props => props.theme.color.backgroundBase};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 8px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

const MinMaxContainer = styled.div`
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-top: -10px;
  margin-bottom: 8px;
`

const LegalNotesSectionContainer = styled.div`
  position: relative;
  border-radius: 8px;
  margin: 16px -16px 0;
  padding: 12px 16px 16px;
  background-color: ${props => props.theme.color.accentDestructive50};
`

const LegalNotesSectionHint = styled(SectionHint)`
  margin-bottom: 0;
`

const LegalNotesDescriptionTooltipContent = styled.div`
  width: 260px;
`

const LegalNotesQuestionMark = styled(IconQuestion)`
  position: absolute;
  fill: ${props => props.theme.color.onSurfaceMidEmphasys};
  right: 10px;
  top: 10px;
`

export const LegalNotes: React.FC<{ text: string; description: string; title: string }> = ({
  text,
  title,
  description,
}) => {
  return (
    <Tooltip
      visibleOnHover={true}
      tooltipContent={<LegalNotesDescriptionTooltipContent>{description}</LegalNotesDescriptionTooltipContent>}
      temporary={true}
    >
      <LegalNotesSectionContainer>
        <SectionTitle>{title}</SectionTitle>
        <LegalNotesSectionHint>{text}</LegalNotesSectionHint>
        <LegalNotesQuestionMark />
      </LegalNotesSectionContainer>
    </Tooltip>
  )
}

const MinMax: React.FC<{ min?: number; max?: number }> = ({ min, max }) => {
  const { t } = useTranslation('order')
  const content = useMemo(
    () => [min && t('minPcs', { count: min }), max && t('maxPcs', { count: max })].filter(Boolean).join('; '),
    [min, max, t],
  )
  if (!min && !max) {
    return null
  }

  return <MinMaxContainer>{content}</MinMaxContainer>
}

const SeedTreatmentSelect: React.FC<{
  product: ProductItemDto
  value?: string
  onChange: (val: string) => void
  formikErrors?: FormikErrors<{ seedTreatment: string }>
  formikTouched?: FormikTouched<{
    seedTreatment: string
  }>
}> = ({ product, value, onChange, formikErrors, formikTouched }) => {
  const { t } = useTranslation(['order', 'productOptions'])
  const { pick } = useLangPicker()

  const treatmentOptions = useMemo(
    () => product.activeSeedTreatment.map(st => ({ id: st.id, title: pick(st.title_i18n) })),
    [product.activeSeedTreatment, pick],
  )
  return treatmentOptions.length ? (
    <SectionWrapper data-test-id={'seed-treatment-select'}>
      <SectionTitle>{t('sections.seedTreatment')}</SectionTitle>
      <SimpleSelect
        aria-label={t('sections.seedTreatmentSelectLabel')}
        options={treatmentOptions}
        value={value}
        onChange={onChange}
        name={'seed-treatment-select'}
        required
        errorText={formikErrors?.seedTreatment}
        invalid={formikTouched?.seedTreatment && !!formikErrors?.seedTreatment}
        customStyles={{
          placeholder: placeholderStyleFn,
        }}
        placeholder={`${t('productOptions:placeholderPrefix')} ${t(
          `productOptions:types.seed_treatment`,
        ).toLowerCase()}`}
      />
    </SectionWrapper>
  ) : null
}

export type AdderWidgetDesktopProps = Omit<AdderWidgetProps, 'values'> & {
  productOptions: ProductOptionsType[]
  isQtyDisabled: boolean
} & AdderWidgetProps['values']

type AlreadyAddedProps = Omit<AdderWidgetDesktopProps, 'producer'>

const AlreadyAdded: React.FC<AlreadyAddedProps> = ({
  product,
  qty,
  onQtyChange,
  seedTreatment,
  onSeedTreatmentChange,
  options,
  onOptionsChange,
  productOptions,
  cartItem,
  isQtyDisabled,
}) => {
  const generateCountryPath = useCountryPath()
  const { t } = useTranslation('order')
  const currentDiscountAmount = useSelector(ProductSelectors.currentDiscountPerBag)
  const nextLvlQty = useSelector(ProductSelectors.nextLvlQty)

  const [, discounts] = useProductDiscounts()
  const hasNoDiscounts = !discounts || !discounts.length
  const isMaxQtyReached = !!(product.max_qty && qty && qty >= product.max_qty)
  const { track } = useAnalyticsSSR()
  const { pick } = useLangPicker()
  const match = useRouteMatch<{ producerSlug?: string }>(ProducerRoutes.ProducerInfoPage)
  const [, producer] = useProducer(match?.params.producerSlug)
  const { order_now_hint_i18n } = { ...producer?.config?.seller_config }
  const { producerSlug } = useParams<{ producerSlug: string }>()

  const formatPrice = useCallback(
    (price: number | string) => getPrettyPrice(price, product.producer?.currency || Currency.CAD, product.price_type),
    [product],
  )

  const handleAddDiscountSuggestionQty = (addQty: number) => {
    if (!cartItem?.is_fake) {
      const newQty = (qty || 0) + addQty
      track(AdderWidgetEvents.ChageProductQtyInTruck, {
        ...productToEventParams(product),
        type: ChangeQtyType.AddMore,
        quantity: newQty,
      })
      onQtyChange(newQty)
    }
  }

  const isFake = Boolean(cartItem?.is_fake)

  return (
    <SectionBody noGrid>
      {order_now_hint_i18n && <OrderNowHint>{pick(order_now_hint_i18n)}</OrderNowHint>}
      <Styled.TitleStyled>{t('orderNow')}</Styled.TitleStyled>
      {product.price && <Styled.PriceStyled>{formatPrice(product.price)}</Styled.PriceStyled>}
      <Packaging>{product.default_packaging}</Packaging>
      <Quantity
        disabled={isFake || isQtyDisabled}
        min={product.min_qty}
        max={product.max_qty}
        name={'quantity'}
        selectTextOnFocus={true}
        onDecrease={() =>
          track(AdderWidgetEvents.ChageProductQtyInTruck, {
            ...productToEventParams(product),
            type: ChangeQtyType.Minus,
          })
        }
        onIncrease={() =>
          track(AdderWidgetEvents.ChageProductQtyInTruck, {
            ...productToEventParams(product),
            type: ChangeQtyType.Plus,
          })
        }
        value={qty || 0}
        onChange={qty => onQtyChange(qty)}
        size={'large'}
        testId={'item-count'}
      />
      <MinMax min={product.min_qty} max={product.max_qty} />
      {isMaxQtyReached ? (
        <MaxQtyHint dangerouslySetInnerHTML={{ __html: t('maxQtyHint') }} data-test-id={'max-qty-hint'} />
      ) : null}
      {!hasNoDiscounts && (
        <Styled.DiscountSuggestion
          disabled={cartItem?.is_fake}
          nextLvlQty={nextLvlQty}
          currentDiscountAmount={currentDiscountAmount}
          currency={product.producer?.currency || Currency.CAD}
          units={product.units}
          onQtyAdd={handleAddDiscountSuggestionQty}
        />
      )}
      <SeedTreatmentSelect product={product} onChange={onSeedTreatmentChange} value={seedTreatment} />
      <ProductOptions
        selectedIds={options}
        options={product.options}
        onChange={onOptionsChange}
        optionsRequiredErrors={[]}
        productOptions={productOptions}
      />
      {!!product.alt_packaging && (
        <SectionWrapper>
          <SectionTitle>{t('sections.packaging')}</SectionTitle>
          <SectionHint>{t('packagingHint')}</SectionHint>
        </SectionWrapper>
      )}
      {product.docusign_document_id && product.legal_notes ? (
        <LegalNotes
          text={product.legal_notes.text}
          description={product.legal_notes.description}
          title={product.legal_notes.title}
        />
      ) : null}
      <ButtonsContainer>
        <Link to={generateCountryPath(Routes.ProducerCart, { producerSlug })}>
          <Button
            intent={'secondary'}
            onClick={() => track(AdderWidgetEvents.GoToCheckout, { ...productToEventParams(product), quantity: qty })}
            disabled={isFake}
            progress={isFake ? Progress.WORK : Progress.IDLE}
          >
            {t('gotoCheckout')}
          </Button>
        </Link>
      </ButtonsContainer>
    </SectionBody>
  )
}

type NotAddedProps = Omit<AdderWidgetDesktopProps, 'cartItem'>

const NotAdded: React.FC<NotAddedProps> = ({
  product,
  qty = 1,
  altPackagingQty = null,
  onQtyChange,
  seedTreatment,
  onSeedTreatmentChange,
  options,
  onOptionsChange,
  productOptions,
  isQtyDisabled,
}) => {
  const { t } = useTranslation('order')
  const addAction = useAction(CartActions.fakeItemAddRequested)
  const { pick } = useLangPicker()
  const currentDiscountAmount = useSelector(ProductSelectors.currentDiscountPerBag)
  const hasSimilarProductInCart = useSelector(state => CartSelectors.hasCategory(state, product.subcategory_id || ''))
  const nextLvlQty = useSelector(ProductSelectors.nextLvlQty)
  const [, discounts] = useProductDiscounts()
  const hasNoDiscounts = !discounts || !discounts.length
  const { track } = useAnalyticsSSR()
  const { order_now_hint_i18n } = { ...product.producer?.config?.seller_config }

  const formatPrice = useCallback(
    (price: number | string) => getPrettyPrice(price, product.producer?.currency || Currency.CAD, product.price_type),
    [product],
  )

  const validator = useMemo(() => {
    return Yup.object({
      seedTreatment: Yup.string(),
    })
  }, [])
  const formik = useFormik({
    initialValues: {
      seedTreatment: seedTreatment || '',
    },
    validationSchema: validator,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    enableReinitialize: true,
    validateOnMount: true,
  })

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    formik.submitForm()
    if (formik.isValid) {
      track(AdderWidgetEvents.AddToTruckClick, { ...productToEventParams(product), quantity: qty })
      addAction(product, {
        qty,
        packaging: altPackagingQty,
        seedTreatment,
        options,
      })
    }
  }

  const handleHowItWorksClick = () => {
    track(AdderWidgetEvents.HowItWorksClick, productToEventParams(product))
  }

  const handleAddDiscountSuggestionQty = (addQty: number) => {
    const newQty = (qty || 0) + addQty
    track(AdderWidgetEvents.ChangeProductQty, {
      ...productToEventParams(product),
      type: ChangeQtyType.AddMore,
      quantity: newQty,
    })
    onQtyChange(newQty)
  }

  return (
    <Styled.CustomSectionBody>
      {order_now_hint_i18n && <OrderNowHint>{pick(order_now_hint_i18n)}</OrderNowHint>}
      <Styled.TitleStyled>{t('orderNow')}</Styled.TitleStyled>
      {product.price && <Styled.PriceStyled>{formatPrice(product.price)}</Styled.PriceStyled>}
      <Packaging>{product.default_packaging}</Packaging>
      <Quantity
        min={product.min_qty}
        max={product.max_qty}
        name={'quantity'}
        selectTextOnFocus={true}
        onDecrease={() =>
          track(AdderWidgetEvents.ChangeProductQty, { ...productToEventParams(product), type: ChangeQtyType.Minus })
        }
        onIncrease={() =>
          track(AdderWidgetEvents.ChangeProductQty, { ...productToEventParams(product), type: ChangeQtyType.Plus })
        }
        value={qty || 0}
        onChange={onQtyChange}
        size={'large'}
        testId={'item-count'}
        disabled={isQtyDisabled}
      />
      <MinMax max={product.max_qty} min={product.min_qty} />
      {!hasNoDiscounts && (
        <Styled.DiscountSuggestion
          units={product.units}
          nextLvlQty={nextLvlQty}
          currentDiscountAmount={currentDiscountAmount}
          isCartEmpty={!hasSimilarProductInCart}
          currency={product.producer?.currency || Currency.CAD}
          onQtyAdd={handleAddDiscountSuggestionQty}
        />
      )}
      <form onSubmit={handleFormSubmit}>
        <SeedTreatmentSelect
          product={product}
          onChange={value => {
            formik.setFieldValue('seedTreatment', value)
            onSeedTreatmentChange(value)
          }}
          formikErrors={formik.errors}
          formikTouched={formik.touched}
          value={seedTreatment}
        />
        <ProductOptions
          selectedIds={options}
          options={product.options}
          onChange={onOptionsChange}
          optionsRequiredErrors={[]}
          productOptions={productOptions}
        />
        {!!product.alt_packaging && (
          <SectionWrapper>
            <SectionTitle>{t('sections.packaging')}</SectionTitle>
            <SectionHint>{t('packagingHint')}</SectionHint>
          </SectionWrapper>
        )}
        {product.docusign_document_id && product.legal_notes ? (
          <LegalNotes
            text={product.legal_notes.text}
            description={product.legal_notes.description}
            title={product.legal_notes.title}
          />
        ) : null}
        <ButtonsContainer>
          <Button
            type={'submit'}
            id={'add-to-cart-button'}
            data-test-id={'add-to-cart-button'}
            intent={'primary-action'}
            filled
          >
            {t('addToCart')}
          </Button>
        </ButtonsContainer>
      </form>
      <Styled.HowItWorksButtonStyled
        items={product.producer?.config?.seller_config?.how_it_works || []}
        onClick={handleHowItWorksClick}
      />
    </Styled.CustomSectionBody>
  )
}

const AdderWidgetDesktop: React.VFC<AdderWidgetDesktopProps> = ({ cartItem, isQtyDisabled, ...props }) => {
  return (
    <div data-test-id="adder-widget-desktop">
      <SectionContainer noDivider>
        {cartItem ? (
          <AlreadyAdded {...props} cartItem={cartItem} isQtyDisabled={isQtyDisabled} />
        ) : (
          <NotAdded {...props} isQtyDisabled={isQtyDisabled} />
        )}
      </SectionContainer>
    </div>
  )
}

export default AdderWidgetDesktop

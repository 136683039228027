import { createSelector } from 'reselect'
import { AppGlobalState } from '../../types'
import { Order } from 'types/order'

const dict = (state: AppGlobalState) => state.order.items
const ids = (state: AppGlobalState) => state.order.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, id: string): Order | undefined =>
  state.order.items[id] || Object.values(state.order.items).find(item => item.slug === id)
const itemFetchProgress = (state: AppGlobalState) => state.order.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.order.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.order.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.order.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.order.listFetchNextError
const list = createSelector(dict, ids, (dict, ids) => {
  const result: Order[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.order.filter
const sorting = (state: AppGlobalState) => state.order.sorting
const page = (state: AppGlobalState) => state.order.page
const total = (state: AppGlobalState) => state.order.total
const updateProgress = (state: AppGlobalState) => state.order.updateProgress
const removeProgress = (state: AppGlobalState) => state.order.removeProgress
const addProgress = (state: AppGlobalState) => state.order.addProgress
const hasNext = (state: AppGlobalState) => state.order.total > state.order.ids.length
const pageSize = (state: AppGlobalState) => state.order.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.order.total / state.order.pageSize)

const OrderSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
}

export default OrderSelectors

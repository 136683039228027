import React from 'react'
import { Switch, Route, Redirect, useLocation, useRouteMatch } from 'react-router-dom'
import ProducerRoutes from './routes'
import { Modal } from '@agro-club/frontend-shared'
import { HowItWorks, WelcomeHeading } from 'views/ui/HowItWorks/HowItWorks'
import useCookieValue from 'hooks/useCookieValue'
import qs from 'query-string'
import { useProducer } from 'modules/domain/producer/hooks'
import ProductItem from './Product/ProductItem/ProductItem'
import ProductList from './Product/ProductList/ProductList'
import CategoryList from './Category/CategoryList/CategoryList'
import SubCategoryList from './SubCategory/SubCategoryList/SubCategoryList'
import { useCountryPath } from 'hooks/useCountryPath'
import { Routes } from '../routes'
import CardList from './Card/CardList/CardList'
import CardItem from './Card/CardItem/CardItem'

const useWelcomePopup = () => {
  const [cookie, setCookie] = useCookieValue('welcome')
  const location = useLocation()
  const { query } = qs.parseUrl(location.search) || {}
  const close = () => setCookie('1')
  return [!cookie && !!query.welcome, close] as const
}

const ProducerPage: React.FC = () => {
  const [welcomePopup, closeWelcomePopup] = useWelcomePopup()
  const generateCountryPath = useCountryPath()
  const match = useRouteMatch<{ producerSlug?: string }>(ProducerRoutes.ProducerInfoPage)
  const [, producer] = useProducer(match?.params.producerSlug)
  const rootPath = generateCountryPath(Routes.Root)
  const isStorefront = producer?.sku_orders

  return (
    <>
      <Switch>
        <Route path={ProducerRoutes.ProductOrCardItem}>{isStorefront ? <CardItem /> : <ProductItem />}</Route>
        <Route path={ProducerRoutes.ProductOrCardList}>{isStorefront ? <CardList /> : <ProductList />}</Route>
        <Route path={ProducerRoutes.SubCategoryList}>
          <SubCategoryList />
        </Route>
        <Route path={ProducerRoutes.CategoryList}>
          <CategoryList />
        </Route>
        {/* TODO-1523 remove the commented code below, if nothing bad happened since
        <Route path={ProducerRoutes.ProducerInfoPage}>
          <Redirect to={rootPath} />
        </Route> */}
        <Redirect to={rootPath} />
      </Switch>
      <Modal isOpen={welcomePopup} onClose={closeWelcomePopup} heading={<WelcomeHeading />}>
        <HowItWorks onProceed={closeWelcomePopup} welcome items={producer?.config?.seller_config?.how_it_works || []} />
      </Modal>
    </>
  )
}

export default ProducerPage

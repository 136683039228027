import { CountryPhoneInput, CountryPhoneInputProps } from '@agro-club/frontend-shared'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import CollectionSelectors from 'modules/domain/collection/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

type PhoneInputProps = Omit<
  CountryPhoneInputProps,
  'defaultCountryCode' | 'countryOptions' | 'countryOptionsProgress' | 'name'
> &
  Partial<Pick<CountryPhoneInputProps, 'name' | 'defaultCountryCode' | 'countryOptions' | 'countryOptionsProgress'>>

const PhoneInput: React.FC<PhoneInputProps> = props => {
  const countryPhoneCodes = useSelector(CollectionSelectors.countryPhoneCodes)

  const countryPhoneCodesProgress = useSelector(CollectionSelectors.countryPhoneCodesProgress)
  const detectedCountry = useDetectedCountry()
  return (
    <CountryPhoneInput
      {...props}
      name={'phone-input'}
      defaultCountryCode={detectedCountry || countryPhoneCodes[0]?.id}
      countryOptions={countryPhoneCodes || []}
      countryOptionsProgress={countryPhoneCodesProgress}
    />
  )
}

export default PhoneInput

import { AppGlobalState } from '../../types'
import { createSelector } from 'reselect'
import { CartItem, QtySuggestion } from 'modules/domain/cart/types'
import { isPromocodeWithComment, isPromocodeWithLegalText, Promocode } from 'types/entities/promocode'
import env from 'env'
import { DuckFootWizardNode, helpersObject, ProductWizardNode, ProductWizardType } from '@agro-club/frontend-shared'
import { CalculatedDiscountDto } from 'types/entities/discount'

export const getCartInitProgress = (state: AppGlobalState) => state.cart.initProgress
export const refreshProgress = (state: AppGlobalState) => state.cart.refreshProgress
export const getCartEntries = (state: AppGlobalState) => state.cart.entries
export const getCartPendingEntries = (state: AppGlobalState) => state.cart.pendingEntries

export const getCartPendingEntriesByProducerSlug = createSelector(
  getCartPendingEntries,
  (_: AppGlobalState, producerSlug: string) => producerSlug,
  (pendingEntries, producerSlug) => {
    return Object.values(pendingEntries).filter(entry => entry.producer_slug === producerSlug)
  },
)

export const getCartEntriesByProductId = createSelector(
  getCartEntries,
  (_: AppGlobalState, id: string) => id,
  (entries, id) => {
    return Object.values(entries).filter(entry => entry.product_id === id)
  },
)
export const getCartEntriesByProductSlug = createSelector(
  getCartEntries,
  (_: AppGlobalState, slug: string) => slug,
  (entries, slug) => {
    return Object.values(entries).filter(entry => entry.product.slug === slug)
  },
)
export const getCartEntry = (state: AppGlobalState, id: string) => state.cart.entries[id]
export const getCartEntryByParams = (state: AppGlobalState, productId: string, params: Partial<CartItem> = {}) => {
  const searchKeyPairs = helpersObject.pairs(params)
  const items = Object.values(state.cart.entries).filter(entry => {
    if (entry.product_id !== productId) {
      return false
    }
    return searchKeyPairs.every(([key, val]) => {
      if (typeof val === 'undefined' || val === null) {
        return true
      }
      if (key === 'options') {
        return JSON.stringify(entry[key]) === JSON.stringify(val)
      }
      return entry[key] === val
    })
  })
  return items[0] || undefined
}
export const getAddProgress = (state: AppGlobalState) => state.cart.addProgress
export const getRemoveProgress = (state: AppGlobalState) => state.cart.removeProgress
export const getAddItemsProgress = (state: AppGlobalState) => state.cart.addItemsProgress
export const getUpdateProgress = (state: AppGlobalState) => state.cart.updateProgress
export const getTermsAgreed = (state: AppGlobalState) => state.cart.termsAgreed

const getDiscountInitProgress = (state: AppGlobalState) => state.cart.discountsInitProgress
const getDiscounts = (state: AppGlobalState, producerSlug: string) => {
  if (!state.cart.cartInfo[producerSlug]) return []
  return state.cart.cartInfo[producerSlug].discounts
}
const getInitRetryAttempts = (state: AppGlobalState) =>
  env.BROWSER ? state.cart.initRetryAttempts : state.cart.initRetryAttempts - 1
const getCreditOfferAccepted = (state: AppGlobalState, producerId: string) =>
  state.cart.cartInfo[producerId]?.creditOfferAccepted

const getPromocodes = (state: AppGlobalState, producerSlug: string) => {
  if (!state.cart.cartInfo[producerSlug]) {
    return []
  }
  return state.cart.cartInfo[producerSlug].promocodes
}
const getAllPromocodes = (state: AppGlobalState) =>
  Object.keys(state.cart.cartInfo).reduce(
    (acc: Promocode[], key: string) => [...acc, ...state.cart.cartInfo[key].promocodes],
    [],
  )
const getLastPromocodeStatus = (state: AppGlobalState) => state.cart.lastPromocodeStatus
const getCheckPromocodeProgress = (state: AppGlobalState) => state.cart.checkPromocodeProgress
const getSelectedPromocode = (state: AppGlobalState) => state.cart.selectedPromocode

const cartEntriesSelector = createSelector(getCartEntries, map => map)
const cartEntriesByProductIdSelector = createSelector(getCartEntriesByProductId, list => list)

const cartItemsSelector = createSelector(cartEntriesSelector, (entries): CartItem[] => Object.values(entries))
const hasCategory = (state: AppGlobalState, subcategoryId: string) =>
  Object.values(state.cart.entries).some(entry => entry.product.subcategory_id === subcategoryId)
const cartInfo = (state: AppGlobalState) => state.cart.cartInfo
const producerCartInfo = (state: AppGlobalState, producerSlug) => state.cart.cartInfo[producerSlug]
const cartItemsByProducer = (state: AppGlobalState, producerId: string) =>
  Object.values(state.cart.entries).filter(e => e.product.producer?.slug === producerId || e.producer_id === producerId)
const hasTotalInfo = createSelector(
  producerCartInfo,
  cartInfo => cartInfo && !!(cartInfo.total || cartInfo.total_savings),
)

const addQtySuggestions = createSelector(
  getDiscounts,
  getCartEntries,
  (discounts, cartEntries): QtySuggestion => {
    return Object.values(cartEntries).reduce((acc: QtySuggestion, entry: CartItem) => {
      const calculatedDiscount = discounts.find(d => d.rule.products_ids.includes(entry.product_id))

      return { ...acc, [entry.id]: calculatedDiscount ? calculatedDiscount.tiers.map(t => t.qty_to_apply).sort() : [] }
    }, {})
  },
)

const nextDiscountLvlSuggestions = createSelector(getDiscounts, cartItemsSelector, (discounts, cartItems) => {
  // TODO Let's use ramda here?
  return cartItems.reduce((suggestions: { [key: string]: number }, item: CartItem) => {
    const nextLvlQty = discounts
      .filter(d => d.next_lvl_qty > 0)
      .reduce(
        (minNextLvlQty: number, discount: CalculatedDiscountDto) =>
          discount.rule.products_ids.includes(item.product_id) && discount.next_lvl_qty < minNextLvlQty
            ? discount.next_lvl_qty
            : minNextLvlQty,
        Infinity,
      )
    return { ...suggestions, [item.id]: isFinite(nextLvlQty) ? nextLvlQty : 0 }
  }, {})
})

const hasDiscount = (producerSlug, entryId) =>
  createSelector(
    (state: AppGlobalState) => getDiscounts(state, producerSlug),
    (state: AppGlobalState) => getCartEntry(state, entryId),
    (discounts, entry) => discounts.some(d => entry && d.rule.products_ids.includes(entry.product_id)),
  )

const cartCountSelector = createSelector(
  getCartEntries,
  getCartPendingEntries,
  (entries, pendingEntries) => Object.keys(entries).length + Object.keys(pendingEntries).length,
)

const selectedPromocodeSelector = createSelector(getPromocodes, getSelectedPromocode, (codesList, selectedCode) => {
  const selected = codesList.find(item => item.code === selectedCode)
  if (selected) {
    return selected.code
  }
  return codesList.find(item => {
    if (isPromocodeWithComment(item)) {
      return !!item.params.prompt
    }
    if (isPromocodeWithLegalText(item)) {
      return !!item.params.legal_text
    }
  })?.code
})

const productWizard = (state: AppGlobalState): ProductWizardNode<any>[] | null => {
  return state.cart.productWizardData
}

const productWizardDuckFoot = createSelector(productWizard, data => {
  if (data && data[0]?.type === ProductWizardType.DuckFoot) {
    return data[0] as DuckFootWizardNode
  } else {
    return null
  }
})

const productWizardProgress = (state: AppGlobalState) => {
  return state.cart.productWizardProgress
}

const getFarmerComment = (state: AppGlobalState, producerId: string) => state.cart.cartInfo[producerId]?.farmerComment

const CartSelectors = {
  initProgress: getCartInitProgress,
  cartEntry: getCartEntry,
  cartEntryByParams: getCartEntryByParams,
  cartEntries: cartEntriesSelector,
  cartEntriesByProductId: cartEntriesByProductIdSelector,
  cartEntriesByProductSlug: getCartEntriesByProductSlug,
  cartPendingEntriesByProducerSlug: getCartPendingEntriesByProducerSlug,
  count: cartCountSelector,
  cartItems: cartItemsSelector,
  cartItemsByProducer,
  cartInfo,
  hasTotalInfo,
  hasDiscount,
  discountInitProgress: getDiscountInitProgress,
  getDiscounts,
  producerCartInfo,
  discounts: getDiscounts,
  qtySuggestions: addQtySuggestions,
  nextDiscountLvlSuggestions,
  addProgress: getAddProgress,
  addItemsProgress: getAddItemsProgress,
  updateProgress: getUpdateProgress,
  refreshProgress,
  initRetryAttempts: getInitRetryAttempts,
  creditOfferAccepted: getCreditOfferAccepted,
  termsAgreed: getTermsAgreed,
  hasCategory,
  promocodes: getPromocodes,
  allPromocodes: getAllPromocodes,
  lastPromocodeStatus: getLastPromocodeStatus,
  checkPromocodeProgress: getCheckPromocodeProgress,
  selectedPromocode: selectedPromocodeSelector,
  productWizard,
  productWizardProgress,
  productWizardDuckFoot,
  farmerComment: getFarmerComment,
}

export default CartSelectors

import useCartInfo from 'hooks/useCartInfo'
import { useCartList } from 'modules/domain/cart/hooks'
import { useCartSkuList } from 'modules/domain/cartSku/hooks'
import { Progress } from 'modules/types'
import { getPrettyPrice } from 'modules/utils/helpers'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Company, Currency } from 'types/entities'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const TotalRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;

  & > :first-child {
    color: ${props => props.theme.color.onSurfaceMidEmphasys};
  }

  & > :last-child {
    color: ${props => props.theme.color.onPrimaryDark};
  }
`

const TotalRow: React.FC<{ value?: string; title: string; className?: string }> = ({ title, value, className }) => {
  return value ? (
    <TotalRowWrapper className={className}>
      <span>{title}</span>
      <span>{value}</span>
    </TotalRowWrapper>
  ) : null
}

const TotalSavingsRow = styled(TotalRow)`
  & > span:first-child,
  & > span:last-child {
    color: ${props => props.theme.color.accentNotify};
  }
`

const TotalCostRow = styled(TotalRow)`
  & > span:first-child,
  & > span:last-child {
    color: ${props => props.theme.color.onPrimaryDark};
    font-weight: 600;
  }
`

const DetailedInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${props => props.theme.color.secondary200};
  padding-bottom: 4px;
  margin-bottom: 8px;
`

const OrderPreviewTotal: React.FC<{ producerSlug: string; producer?: Company }> = ({ producerSlug, producer }) => {
  const { t } = useTranslation('discount')
  const [cartListProgress] = useCartList(producerSlug)
  const [skuListProgress] = useCartSkuList(producerSlug)
  const cartInfo = useCartInfo(producer)
  const formatPrice = useCallback((price: number) => getPrettyPrice(price, producer?.currency || Currency.CAD), [
    producer,
  ])

  const progress = producer?.sku_orders ? skuListProgress : cartListProgress

  if (progress !== Progress.SUCCESS) {
    return <SpinnerLayout />
  }

  if (!cartInfo) return null

  return (
    <Wrapper>
      <DetailedInfoWrapper>
        <TotalRow title={t('subtotal')} value={cartInfo.subtotal > 0 ? formatPrice(cartInfo.subtotal) : ''} />
        <TotalRow title={t('promo')} value={cartInfo.promo_savings > 0 ? formatPrice(cartInfo.promo_savings) : ''} />
        <TotalSavingsRow
          title={t('discountTotal')}
          value={cartInfo.total_savings > 0 ? formatPrice(cartInfo.total_savings) : ''}
        />
      </DetailedInfoWrapper>
      <TotalCostRow title={t('total')} value={cartInfo.total > 0 ? formatPrice(cartInfo.total) : ''} />
    </Wrapper>
  )
}

export default OrderPreviewTotal

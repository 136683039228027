import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.accentNotify};
  margin-bottom: 16px;
`

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const OptionWrapper = styled.div`
  border-radius: 8px;
  padding: 4px 8px;
  &:hover {
    background: ${({ theme }) => theme.color.primary50};
  }
  cursor: pointer;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: ${({ theme }) => theme.color.onSurfaceHighEmphasys};
`

type Option = {
  title: string
  value: string
}

type Props = {
  options: Option[]
  qtyToAdd?: number
  onSelect: (value: string, qty?: number, opt?: Option) => void
}

export const SpecifyProductScreen: React.FC<Props> = ({ options, qtyToAdd, onSelect }) => {
  return (
    <Wrapper data-test-id={'product-options'}>
      <Title>{'Choose product to add'}</Title>
      <OptionsContainer data-test-id={'product-options-container'}>
        {options.map((opt: Option, idx: number) => (
          <OptionWrapper
            key={idx}
            data-test-id={`option-${opt.value}`}
            onClick={() => onSelect(opt.value, qtyToAdd, opt)}
          >
            {opt.title}
          </OptionWrapper>
        ))}
      </OptionsContainer>
    </Wrapper>
  )
}

export default SpecifyProductScreen

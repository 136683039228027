import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { CardBestOffersMeta, Card, CardBestOffer } from './types'

const dict = (state: AppGlobalState) => state.card.items
const ids = (state: AppGlobalState) => state.card.ids
const item = (state: AppGlobalState, idOrSlug: string): Card | undefined => {
  return state.card.items[idOrSlug]
    ? state.card.items[idOrSlug]
    : Object.values(state.card.items).find(p => p.slug === idOrSlug)
}
const meta = (state: AppGlobalState, id: string) =>
  state.card.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.card.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.card.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.card.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.card.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.card.listFetchNextError
const list = createSelector(dict, ids, (dict, ids) => {
  const result: Card[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const bestOfferFetchProgress = (state: AppGlobalState) => state.card.bestOfferFetchProgress
const bestOfferFetchError = (state: AppGlobalState) => state.card.bestOfferFetchError

const bestOffers = (state: AppGlobalState) => state.card.bestOffers
const bestOffersMeta = (state: AppGlobalState, slug: string): CardBestOffersMeta =>
  state.card.bestOffersMeta[slug] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    slug,
  }

const bestOffer = (state: AppGlobalState, slug: string): CardBestOffer | undefined => {
  return state.card.bestOffers[slug]
}

const fetchExclusiveItemError = (state: AppGlobalState) => state.card.fetchExclusiveItemError

const filter = (state: AppGlobalState) => state.card.filter
const sorting = (state: AppGlobalState) => state.card.sorting
const page = (state: AppGlobalState) => state.card.page
const total = (state: AppGlobalState) => state.card.total

const hasNext = (state: AppGlobalState) => state.card.page * state.card.pageSize < state.card.total
const pageSize = (state: AppGlobalState) => state.card.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.card.total / state.card.pageSize)

export const CardSelectors = {
  filter,
  sorting,
  page,
  total,

  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,

  bestOfferFetchProgress,
  bestOfferFetchError,
  bestOffer,
  bestOffersMeta,
  bestOffers,

  fetchExclusiveItemError,
}

export default CardSelectors

import { IconCheckCircle } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import React from 'react'
import styled from 'styled-components'
import { LocalizedValue } from 'types/entities'

const ExtrasContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-self: start;
`

const ExtrasIconContainer = styled.div`
  margin-right: 18px;
  display: flex;
`

const CheckCircleStyled = styled(IconCheckCircle)`
  fill: ${({ theme }) => theme.color.primary600};
  width: 20px;
  height: 20px;
`

const ExtrasItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  &:not(:first-child) {
    margin-top: 8px;
  }
`

const ExtrasDescription = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-left: 4px;
  color: ${props => props.theme.color.secondary200};
`

const ExtrasTitle = styled.div`
  display: flex;
  font-weight: 600;
  justify-content: center;
  padding: 16px 0px;
`

export const Extras: React.FC<{
  items: { title: LocalizedValue; description?: LocalizedValue; icon?: React.ReactNode }[]
}> = ({ items }) => {
  const { pick } = useLangPicker()
  return (
    <ExtrasContainer>
      {items.map(({ title, icon, description }, idx) => {
        if (pick(title).startsWith('***')) {
          return <ExtrasTitle key={idx}>{pick(title).split('***')[1]}</ExtrasTitle>
        }

        return (
          <ExtrasItem key={idx} data-test-id="card-feature">
            <ExtrasIconContainer>{icon || <CheckCircleStyled />}</ExtrasIconContainer>
            {pick(title)}
            {description ? <ExtrasDescription>{pick(description)}</ExtrasDescription> : null}
          </ExtrasItem>
        )
      })}
    </ExtrasContainer>
  )
}

import { useSelector } from 'react-redux'
import { Progress, ResourceHook } from 'modules/types'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'
import CardActions from './duck'
import CardSelectors from './selectors'
import { Card, CardBestOffer, CardListRequestFilter, CardListRequestSorting } from './types'
import { useEffect, useState } from 'react'
import * as managers from './managers'
import useApiService from 'hooks/useApiService'

export type CardListRequestParams = {
  sellerSlug: string
  subCategorySlug: string
  filter?: CardListRequestFilter
  sorting?: CardListRequestSorting
  page?: number
}

export const useCardList: ResourceHook<Card[], [string, string]> = (sellerSlug: string, subCategorySlug: string) => {
  const progress = useSelector(CardSelectors.listFetchProgress)
  const list = useSelector(CardSelectors.list)
  const page = usePageQuery()
  const params: CardListRequestParams = { sellerSlug, subCategorySlug }
  if (page) params.page = page
  const fetchAction = useAction(CardActions.listRequested, params)

  useDidMount(fetchAction)

  return [progress, list]
}

export const useCard: ResourceHook<Card> = (sellerSlug: string, idOrSlug: string) => {
  const meta = useSelector(state => CardSelectors.meta(state, idOrSlug))
  const item = useSelector(state => CardSelectors.item(state, idOrSlug))
  const fetchAction = useAction(CardActions.itemRequested, sellerSlug, idOrSlug)

  useEffect(() => {
    if (!idOrSlug) return
    if (!item || (item.id !== idOrSlug && item.slug !== idOrSlug)) {
      fetchAction()
    }
  }, [fetchAction, idOrSlug, item])

  return [meta.fetchProgress, item]
}

export const useCardBestOffer: ResourceHook<CardBestOffer, [string]> = (slug: string) => {
  const progress = useSelector(CardSelectors.bestOfferFetchProgress)
  const bestOffer = useSelector(state => CardSelectors.bestOffer(state, slug))

  return [progress, bestOffer]
}

// TODO remove this hook when useCardList will be able to handle filters properly
export const useWizardCardListOnce: ResourceHook<Card[], [string, string]> = (
  sellerSlug: string,
  subCategorySlug: string,
) => {
  const apiService = useApiService()
  const [progress, setProgress] = useState(Progress.IDLE)
  const [items, setItems] = useState<Card[]>([])

  useDidMount(() => {
    if (!apiService) return
    setProgress(Progress.WORK)
    managers
      .getList(apiService)(sellerSlug, subCategorySlug, { is_exclusive: true }, {}, 1, 100)
      .then(response => {
        setItems(response.data)
      })
  })

  return [progress, items]
}

import React, { useEffect, useMemo, useState } from 'react'
import SkuOptionsComponent, { SkuOptionsContext } from '../SkuOptionsComponent/SkuOptionsComponent'
import { ProductOptions, ProductOptionType } from 'modules/domain/productOptions/types'
import { StorefrontItem, Sku, SkuOption } from 'modules/domain/sku/types'
import { PackageTypeSelect } from 'views/components/PackageTypeSelect/PackageTypeSelect'
import { PackageTypes } from 'modules/domain/packageTypes/types'
import { Button, Progress } from '@agro-club/frontend-shared'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

export const MatcherButton = styled(Button)`
  margin-top: 16px;
  text-decoration: underline;
  padding: 8px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Errors = styled.div`
  color: gray;
  text-align: center;
`

const SkuOptionsMatcher: React.VFC<{
  sku_package_id?: string
  sku_options?: SkuOption[]
  availableSku: StorefrontItem[]
  productOptions: ProductOptions[]
  progress: Progress
  onPackageTypeChange?: (value: string) => void
  onOptionsChange?: (value: SkuOption[]) => void
  onSkuMatched?: (value?: Sku) => void
  optionsRequired?: boolean
  packageTypes: PackageTypes[]
  showErrors?: boolean
  disabled?: boolean
  context?: SkuOptionsContext
  buttons?: React.ReactNode
  error?: string | null
  isAddedFromCrm?: boolean
}> = ({
  sku_package_id,
  sku_options = [],
  availableSku,
  productOptions,
  progress,
  onPackageTypeChange,
  onOptionsChange,
  onSkuMatched,
  optionsRequired,
  packageTypes,
  showErrors,
  disabled,
  context,
  buttons,
  error,
  isAddedFromCrm = false,
}) => {
  const [packageId, setPackageId] = useState<string | undefined>(sku_package_id)
  const [options, setOptions] = useState(sku_options)
  const { t } = useTranslation('order')

  const availablePackageIds = useMemo(() => availableSku.map(item => item.params.package_id), [availableSku])
  const availableOptionsIds = useMemo(() => {
    const allOptions = availableSku
      .map(item => item.params.options)
      .flat()
      .filter(Boolean)
    return [...new Set(allOptions.map(item => item.option_id))]
  }, [availableSku])

  const matchedSku = useMemo(() => {
    return availableSku
      .filter(sku => sku.params.package_id === packageId)
      .filter(sku => {
        if (!sku.params.options) return false
        // todo cut all active ingredient multi-options
        const ids = options?.filter(opt => opt.type !== ProductOptionType.ACTIVE_INGREDIENT).map(opt => opt.option_id)
        return sku.params.options
          .filter(opt => opt.type !== ProductOptionType.ACTIVE_INGREDIENT)
          .map(opt => opt.option_id)
          .every(value => ids?.includes(value))
      })
  }, [availableSku, options, packageId])

  const filteredOptions = useMemo(() => {
    let skus = availableSku

    if (packageId) {
      skus = skus.filter(item => item.params.package_id === packageId)
    }
    if (options?.length && !isAddedFromCrm) {
      skus = skus.filter(sku => {
        const skuOptionIds = sku.params.options.map(o => o.option_id)
        return options.every(opt => skuOptionIds.includes(opt.option_id))
      })
    }

    const allOptionsInSkus = skus.map(item => item.params.options).flat()
    const idsInSkus = [...new Set(allOptionsInSkus.map(item => item.option_id))]

    return idsInSkus.length ? productOptions.filter(option => idsInSkus.includes(option.id)) : []
  }, [availableSku, options, packageId, productOptions, isAddedFromCrm])

  const minMatchedOptions = useMemo(() => {
    if (!availableSku.length) return 0
    const opts = availableSku.map(sku => sku.params.options.length)
    return Math.min(...opts)
  }, [availableSku])

  const handleSkuMatched: typeof onSkuMatched = value => {
    onSkuMatched?.(value)
  }

  const handlePackageTypeChange = value => {
    setPackageId(value as string)
    setOptions([])
    onOptionsChange?.([])
    onPackageTypeChange?.(value)
    handleSkuMatched(undefined)
  }

  const handleOptionsChange = (value: SkuOption[]) => {
    setOptions(value)
    onOptionsChange?.(value)
  }

  useEffect(() => {
    setPackageId(sku_package_id)
    setOptions(sku_options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sku_package_id])

  useEffect(() => {
    if (matchedSku.length === 1) {
      if ((options?.length || 0) > matchedSku[0].params.options.length) {
        handleSkuMatched(undefined)
        return
      }

      handleSkuMatched(matchedSku[0] as Sku)
      return
    }

    let matchedItem: StorefrontItem | undefined
    if (matchedSku.length > 1) {
      let min = minMatchedOptions
      matchedSku.forEach(matched => {
        const optionsLength = matched.params.options.length
        if (optionsLength > min) {
          min = optionsLength
          matchedItem = matched
        }
      })

      if (matchedItem) {
        handleSkuMatched(matchedItem as Sku)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageId, options])

  return (
    <>
      <PackageTypeSelect
        value={packageId}
        invalid={showErrors && !packageId}
        packageTypes={packageTypes}
        onChange={handlePackageTypeChange}
        filter={(item: PackageTypes) => availablePackageIds.includes(item.id)}
        progress={progress}
        disabled={disabled || isAddedFromCrm}
        context={context}
        wrapOptionsText
        wrapValueText
      />
      {packageId && (
        <SkuOptionsComponent
          initialValues={options}
          onChange={handleOptionsChange}
          productOptions={filteredOptions}
          filter={(option: ProductOptions) => availableOptionsIds.includes(option.id)}
          optionsRequired={optionsRequired}
          showErrors={showErrors}
          context={context}
          progress={progress}
          disabled={disabled}
        />
      )}
      <Buttons>
        {buttons}
        {!!packageId && !isAddedFromCrm && (
          <MatcherButton
            variant="text"
            intent="danger"
            onClick={() => {
              handleOptionsChange([])
              handlePackageTypeChange(undefined)
            }}
            data-test-id="reset-sku"
          >
            {t('resetOptions')}
          </MatcherButton>
        )}
      </Buttons>
      {error && <Errors>{error}</Errors>}
    </>
  )
}

export default SkuOptionsMatcher

export enum Page {
  Cart = 'cart_screen',
  CategoryList = 'catalog_categories_screen',
  Checkout = 'checkout_screen',
  CheckoutConfirmationScreen = 'checkout_confirmation_screen',
  CheckoutDoneScreen = 'checkout_done_screen',
  CheckoutRetailerScreen = 'checkout_retailer_screen',
  ProducerCart = 'cart_screen',
  ProductList = 'catalog_products_screen',
  ProductItem = 'product_screen',
  ProcoteWizardNode = 'wizard_screen',
  ProfileAccountInfo = 'profile_screen',
  ProfileDeliveryPoint = 'profile_delivery_screen',
  ProfileOrders = 'profile_orders_screen',
  ProfileDocuments = 'profile_documents_screen',
  Root = 'catalog_companies_screen',
  SignIn = 'signin_phone_screen',
  SignInSmsScreen = 'signin_sms_screen',
  SignUpAccountInfoScreen = 'signup_account_info_screen',
  SubCategoryList = 'catalog_subcategories_screen',
}

import { push } from 'connected-react-router'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { apiCall } from 'modules/sagaEffects'
import { generateCountryPath } from 'modules/sagaHelpers'
import { AppGlobalState, Progress } from 'modules/types'
import { all, call, debounce, delay, put, race, select, take, takeLatest } from 'redux-saga/effects'
import { RequestError } from 'service/api/errors'
import { Promocode, PromocodeType } from 'types/entities/promocode'
import CartRoutes from 'views/pages/Cart/routes'
import CartSkuActions from './duck'
import { addItem, clearCart, deleteItem, fetchCart, fetchPromocodeStatus, updateItem } from './managers'
import {
  AddCartSkuItemDTO,
  AddCartSkuItemParams,
  CartSkuInfo,
  CartSkuItem,
  CartSkuState,
  UpdateCartSkuItemParams,
} from './types'
import { v4 as uuid } from 'uuid'
import { PromocodeErrorType } from '../cart/types'
import ProducerSelectors from '../producer/selectors'
import { Company } from 'types/entities'

export const cartInitSaga = function*(_action: ReturnType<typeof CartSkuActions.cartInitRequested>) {
  try {
    const initProgress = yield select(CartSkuSelectors.initProgress)

    if (initProgress === Progress.SUCCESS) {
      return
    }
    const cart = yield call(apiCall, fetchCart)

    yield put(CartSkuActions.cartInitRequestSucceed(cart))
  } catch (err) {
    const { detail } = RequestError.parseError(err)
    yield put(CartSkuActions.cartInitRequestFailed(detail))
  }
}

export const refreshCartSaga = function*(_action: ReturnType<typeof CartSkuActions.refreshCartRequested>) {
  try {
    const promocodes: Promocode[] = yield select(CartSkuSelectors.allPromocodes)
    const cart = yield call(
      apiCall,
      fetchCart,
      promocodes.map(p => p.code),
    )
    yield put(CartSkuActions.refreshCartRequestSucceed(cart))
  } catch (err) {
    const { detail } = RequestError.parseError(err)

    yield put(CartSkuActions.refreshCartRequestFailed(detail))
  }
}

export const itemAddSaga = function*({ payload }: ReturnType<typeof CartSkuActions.itemAddRequested>) {
  const fakeId = uuid()
  const cartInfo: CartSkuInfo | undefined = yield select(CartSkuSelectors.cartInfoBySellerId, payload.card.seller_id)

  try {
    yield put(CartSkuActions.itemAdd(payload, fakeId))

    const addItemDTO: AddCartSkuItemDTO = {
      sku_id: payload.sku.id,
      quantity: payload.quantity,
      seller_id: payload.card.seller_id,
      product_card_id: payload.card.id,
      wizard_comment: payload.wizard_comment,
      wizard_data: payload.wizard_data,
    }

    yield call(apiCall, addItem, addItemDTO)
    yield put(CartSkuActions.itemAddSucceed())

    yield put(CartSkuActions.refreshCartRequested())
  } catch (err) {
    const { detail } = RequestError.parseError(err)
    yield put(CartSkuActions.itemAddFailed(fakeId, detail, cartInfo?.slug))
  }
}

export const itemsAddSaga = function*({
  payload: [orders, producerSlug],
}: ReturnType<typeof CartSkuActions.itemsAddRequested>) {
  try {
    const producer: Company = yield select(ProducerSelectors.item, producerSlug)
    const farmerComment = yield select(CartSkuSelectors.farmerComment, producer.slug)
    const data: AddCartSkuItemParams[] = orders.map(order => ({
      seller_id: order.sellerId,
      quantity: order.quantity,
      sku_id: order.sku_id,
      sku: order.sku,
      product_card_id: order.product_card_id,
      wizard_comment: order.wizard_comment,
      wizard_data: order.wizard_data,
      farmer_comment: farmerComment,
    }))
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const items = yield all(data.map((params: AddCartSkuItemParams) => call(apiCall, addItem, params)))
    yield put(CartSkuActions.itemsAddSucceed())

    yield put(CartSkuActions.refreshCartRequested())
    const path = yield call(generateCountryPath, CartRoutes.ProducerCart, { producerSlug })
    yield put(push(path))
  } catch (err) {
    const { detail } = RequestError.parseError(err)

    yield put(CartSkuActions.itemsAddFailed(detail))
  }
}

export const itemUpdateSaga = function*({ payload, type }: ReturnType<typeof CartSkuActions.itemUpdateRequested>) {
  const [id, dto] = payload
  const itemBeforeUpdate: CartSkuItem = yield select(CartSkuSelectors.cartEntry, id)
  const { slug } = yield select(CartSkuSelectors.cartInfoBySellerId, itemBeforeUpdate.seller_id)
  const index = yield select(CartSkuSelectors.cartEntryIndexInSellerObject, id, slug)
  if (index === -1) return
  try {
    yield put(CartSkuActions.itemUpdated(id, index, slug, payload[1]))
    const { needToDebounce } = yield race({
      timeout: delay(300),
      needToDebounce: take(type),
    })
    if (needToDebounce) {
      return
    }

    const params: UpdateCartSkuItemParams = {
      seller_id: itemBeforeUpdate.seller_id,
      quantity: itemBeforeUpdate.quantity,
      wizard_data: itemBeforeUpdate.wizard_data,
      wizard_comment: itemBeforeUpdate.wizard_comment,
      sku_id: itemBeforeUpdate.sku_id,
      ...dto,
    }
    if (params.quantity === '0') {
      return
    }
    const { item, ignore } = yield race({
      item: call(apiCall, updateItem, id, params),
      ignore: take(type),
    })

    // we need to cancel that 👆 api call, but ok for now
    if (ignore) {
      return
    }
    yield put(CartSkuActions.itemUpdateSucceed(item))
    yield put(CartSkuActions.refreshCartRequested())
  } catch (err) {
    const { detail } = RequestError.parseError(err)

    yield put(CartSkuActions.itemUpdateFailed(id, index, slug, itemBeforeUpdate, detail))
  }
}

export const itemRemoveSaga = function*({ payload: id }: ReturnType<typeof CartSkuActions.itemRemoveRequested>) {
  const removedItem: CartSkuItem = yield select(CartSkuSelectors.cartEntry, id)
  const cartInfo = removedItem.seller_id
    ? yield select(CartSkuSelectors.cartInfoBySellerId, removedItem.seller_id)
    : undefined

  try {
    yield put(CartSkuActions.itemRemove(id, cartInfo.slug))

    yield call(apiCall, deleteItem, id)
    yield put(CartSkuActions.itemRemoveSucceed())
    yield put(CartSkuActions.refreshCartRequested())
  } catch (err) {
    const { detail } = RequestError.parseError(err)
    yield put(CartSkuActions.itemRemoveFailed(removedItem, detail))
    yield put(CartSkuActions.cartInfoRemoveFailed(cartInfo))
  }
}

export const clearCartSaga = function*({ payload: sellerId }: ReturnType<typeof CartSkuActions.clearRequested>) {
  const prevState: CartSkuState = yield select((state: AppGlobalState) => state.cartSku)

  try {
    yield put(CartSkuActions.clear(sellerId))
    yield call(apiCall, clearCart, { seller_ids: [sellerId] })
    yield put(CartSkuActions.clearRequestSucceed())
    yield put(CartSkuActions.refreshCartRequested())
  } catch (err) {
    const { detail } = RequestError.parseError(err)

    yield put(CartSkuActions.clearRequestFailed(prevState, detail))
  }
}

export const checkPromocode = function*({ payload: params }: ReturnType<typeof CartSkuActions.checkPromocode>) {
  try {
    const appliedPromocodes = yield select(CartSkuSelectors.promocodes, params.producerSlug)
    const response: Promocode = yield call(apiCall, fetchPromocodeStatus, {
      ...params,
      appliedPromocodes: appliedPromocodes.map((p: Promocode) => p.code),
    })
    yield put(CartSkuActions.checkPromocodeSuccess(params.producerSlug, params.code, response))
    if (response.params.type !== PromocodeType.Simple) {
      yield put(CartSkuActions.selectEditablePromocode(params.producerSlug, params.code))
    }
    yield put(CartSkuActions.refreshCartRequested())
  } catch (err) {
    const { detail } = RequestError.parseError(err)

    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CartSkuActions.checkPromocodeError(errType as PromocodeErrorType, detail))
  }
}

const CartSkuSaga = function*() {
  yield all([
    takeLatest(CartSkuActions.cartInitRequested.type, cartInitSaga),
    takeLatest(CartSkuActions.refreshCartRequested.type, refreshCartSaga),
    debounce(50, CartSkuActions.itemAddRequested.type, itemAddSaga),
    takeLatest(CartSkuActions.itemsAddRequested.type, itemsAddSaga),
    takeLatest(CartSkuActions.itemUpdateRequested.type, itemUpdateSaga),
    takeLatest(CartSkuActions.itemRemoveRequested.type, itemRemoveSaga),
    takeLatest(CartSkuActions.clearRequested.type, clearCartSaga),
    takeLatest(CartSkuActions.checkPromocode.type, checkPromocode),
    takeLatest(CartSkuActions.removePromocode.type, refreshCartSaga),
  ])
}

export default CartSkuSaga

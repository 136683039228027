import { BadgeDto } from 'modules/domain/product/types'
import React from 'react'
import Badge from 'views/components/ProductBadges/Badge'

export const BadgeList: React.FC<{ badges?: BadgeDto[] }> = ({ badges = [] }) => {
  return (
    <>
      {badges.map((badge: BadgeDto) => (
        <Badge key={badge.id} {...badge} />
      ))}
    </>
  )
}

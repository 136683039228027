import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { ProductOptions, ProductOptionsListRequestFilter, ProductOptionsListRequestSorting } from './types'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { ProductOptionsState } from './types'
import { FetchError } from 'modules/domain/types'

const initialState: ProductOptionsState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,

  filter: {},
  sorting: {},
}

class ProductOptionsReducer extends ImmerReducer<ProductOptionsState> {
  listRequested(params: {
    filter?: ProductOptionsListRequestFilter
    sorting?: ProductOptionsListRequestSorting
    page?: number
  }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
  }

  listRequestSucceed(list: ProductOptions[]) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
  }

  listRequestFailed(error: FetchError) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }

  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  itemRequestSucceed(item: ProductOptions) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchError = null
    this.draftState.items[item.id] = item
  }

  itemRequestFailed(id: string, error: FetchError) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
  }
}

export const ProductOptionsActions = createActionCreators(ProductOptionsReducer)
export default ProductOptionsActions
export const reducer = createReducerFunction(ProductOptionsReducer, initialState)

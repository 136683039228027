import { all, takeLatest, put, takeEvery, call } from 'redux-saga/effects'
import CollectionActions from './duck'
import * as managers from './managers'
import { Category, Country, FilterAttribute, PromoInfo, Region } from './types'
import { ListResponse } from 'types/api'
import { Company } from 'types/entities'
import { apiCall } from 'modules/sagaEffects'
import { CountryCodeEntry } from '@agro-club/frontend-shared'
import AuthActions from '../auth/duck'

export const fetchRegionsSaga = function*({ payload: country }: ReturnType<typeof CollectionActions.regionsRequested>) {
  try {
    const response: ListResponse<Region> = yield call(apiCall, managers.fetchRegions, country)
    yield put(CollectionActions.regionsRequestSucceed(response.data))
  } catch (err) {
    yield put(CollectionActions.regionsRequestFailed())
  }
}

export const fetchCategories = function*(_: ReturnType<typeof CollectionActions.categoriesRequested>) {
  try {
    const res: ListResponse<Category> = yield call(apiCall, managers.fetchCategories)
    yield put(CollectionActions.categoriesRequestSucceed(res.data))
  } catch (err) {
    yield put(CollectionActions.categoriesRequestFailed())
  }
}

export const fetchCompanyCategories = function*({
  payload: [id, isStorefront],
}: ReturnType<typeof CollectionActions.companyCategoriesRequested>) {
  try {
    const response: Category[] | ListResponse<Category> | void = yield call(
      apiCall,
      managers.fetchCompanyCategories,
      id,
      isStorefront,
    )

    const categories = (response as ListResponse<Category>).data
      ? (response as ListResponse<Category>).data
      : (response as Category[])

    yield put(CollectionActions.companyCategoriesRequestSucceed(id, categories || []))
  } catch (err) {
    yield put(CollectionActions.companyCategoriesRequestFailed(id))
  }
}

export const fetchCompanySubCategories = function*({
  payload: [producerId, categorySlug, isStorefront],
}: ReturnType<typeof CollectionActions.companySubCategoriesRequested>) {
  try {
    const response: Category[] | ListResponse<Category> | void = yield call(
      apiCall,
      managers.fetchCompanySubCategories,
      producerId,
      categorySlug,
      isStorefront,
    )

    const subCategories = (response as ListResponse<Category>).data
      ? (response as ListResponse<Category>).data
      : (response as Category[])

    yield put(CollectionActions.companySubCategoriesRequestSucceed(producerId, categorySlug, subCategories || []))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
    yield put(CollectionActions.companySubCategoriesRequestFailed(producerId, categorySlug))
  }
}

export const fetchDistributorsSaga = function*({
  payload: [filter, enableGrouping],
}: ReturnType<typeof CollectionActions.distributorsRequested>) {
  try {
    const response: ListResponse<Company> = yield call(apiCall, managers.fetchDistributors, filter, enableGrouping)
    yield put(CollectionActions.distributorsRequestSucceed(response.data || [], filter))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
    yield put(CollectionActions.distributorsRequestError())
  }
}

export const fetchFilterAttributesSaga = function*({
  payload: [producerId, subCategoryId],
}: ReturnType<typeof CollectionActions.filterAttributesRequested>) {
  try {
    const response: ListResponse<FilterAttribute> = yield call(
      apiCall,
      managers.fetchFilterAttributes,
      producerId,
      subCategoryId,
    )
    yield put(CollectionActions.filterAttributesRequestSucceed(response.data, producerId, subCategoryId))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
    yield put(CollectionActions.filterAttributesRequestFailed())
  }
}

export const fetchPromoInfo = function*(_: ReturnType<typeof CollectionActions.promoInfoRequested>) {
  try {
    const res: ListResponse<PromoInfo> = yield call(apiCall, managers.fetchPromoInfo)
    yield put(CollectionActions.promoInfoRequestSucceed(res.data))
  } catch (err) {
    yield put(CollectionActions.promoInfoRequestFailed())
  }
}

export const fetchCountryPhoneCodes = function*(_: ReturnType<typeof CollectionActions.countryPhoneCodesRequested>) {
  try {
    const res: ListResponse<CountryCodeEntry> = yield call(apiCall, managers.fetchCountryPhoneCodes)
    yield put(CollectionActions.countryPhoneCodesRequestSucceed(res.data))
  } catch (err) {
    yield put(CollectionActions.countryPhoneCodesRequestFailed())
  }
}

export const fetchCounties = function*(_: ReturnType<typeof CollectionActions.countriesRequested>) {
  try {
    const res: ListResponse<Country> = yield call(apiCall, managers.fetchCountries)
    yield put(CollectionActions.countriesRequestSucceed(res.data))
  } catch (err) {
    yield put(CollectionActions.countriesRequestFailed())
  }
}

const CollectionSaga = function*() {
  yield all([
    takeLatest(CollectionActions.regionsRequested.type, fetchRegionsSaga),
    takeLatest(CollectionActions.categoriesRequested.type, fetchCategories),
    takeEvery(CollectionActions.companyCategoriesRequested.type, fetchCompanyCategories),
    takeEvery(CollectionActions.companySubCategoriesRequested.type, fetchCompanySubCategories),
    takeEvery(CollectionActions.distributorsRequested.type, fetchDistributorsSaga),
    takeEvery(CollectionActions.filterAttributesRequested.type, fetchFilterAttributesSaga),
    takeLatest(CollectionActions.promoInfoRequested.type, fetchPromoInfo),
    // fetching countries & country codes once on the app init
    takeLatest(AuthActions.initRequested.type, fetchCountryPhoneCodes),
    takeLatest(AuthActions.initRequested.type, fetchCounties),
  ])
}

export default CollectionSaga

import React, { useContext } from 'react'
import { ApiService as IApiService } from 'service/api/interface'

export const ApiServiceContext = React.createContext<{ apiService?: IApiService }>({})

const useApiService = () => {
  const { apiService } = useContext(ApiServiceContext)
  return apiService
}

export default useApiService

import { BasicSelect, IconPickup } from '@agro-club/frontend-shared'
import useAnalyticDataFromLocation from 'hooks/useAnalyticDataFromLocation'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useCountryPath } from 'hooks/useCountryPath'
import CartSelectors from 'modules/domain/cart/selectors'
import { CartInfo } from 'modules/domain/cart/types'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { CartSkuInfo } from 'modules/domain/cartSku/types'
import { useProducerList } from 'modules/domain/producer/hooks'
import { arrToDict } from 'modules/utils/helpers'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { components } from 'react-select'
import styled from 'styled-components'
import { Company } from 'types/entities'
import { Dict } from 'types/generics'
import CartRoutes from 'views/pages/Cart/routes'
import { useNavigation } from 'views/pages/Profile/Menu'
import IconButton from 'views/ui/IconButton/IconButton'
import HeaderEvents from '../Header/events'
import logoPlaceholder from 'assets/images/logo-placeholder.svg'
import { theme } from 'theme/theme'

const StyledIconButton = styled(IconButton)`
  ${props => props.theme.media.mobile`
    height: 32px;
    min-width: 32px;
    padding: 6px;
  `}
`

const LogoImg = styled.img`
  max-height: 20px;
  max-width: 40px;
  object-fit: contain;
  flex-shrink: 0;
`

const StyledControl = styled(components.Control)`
  border: none !important;
`

const StyledOptionLabel = styled.div`
  display: flex;
  justify-content: space-between;
`
const TitleWrapper = styled.div`
  max-width: 165px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
`

const styles = {
  placeholder: () => ({ display: 'none' }),
  singleValue: () => ({ display: 'none' }),
  valueContainer: () => ({ width: '0', padding: '0' }),
  indicatorsContainer: () => ({ display: 'none' }),
  menu: provided => ({ ...provided, right: 0 }),
}

const getCartInfoTitle = (cartInfo: CartInfo | CartSkuInfo) => cartInfo.title
const getCartInfoValue = (cartInfo: CartInfo | CartSkuInfo) => cartInfo.slug

export const CartButton: React.FC = ({ children: childrenFromProps }) => {
  const generateCountryPath = useCountryPath()
  const { t } = useTranslation('header')
  const { track } = useAnalyticsSSR()
  const analyticData = useAnalyticDataFromLocation()
  const [, producers] = useProducerList()
  const producersDict: Dict<Company> = useMemo(() => arrToDict(producers?.map(p => ({ ...p, id: p.slug })) || []), [
    producers,
  ])

  const { navigate } = useNavigation()
  const cartInfo = useSelector(CartSelectors.cartInfo)
  const cartSkuInfo = useSelector(CartSkuSelectors.cartInfo)

  const cartsInfo = useMemo(() => [...Object.values(cartInfo), ...Object.values(cartSkuInfo)], [cartInfo, cartSkuInfo])

  const isMultiCart = cartsInfo.length > 1

  const IconButton = useMemo(
    () => (
      <StyledIconButton
        id="cart-button"
        data-test-id={'cart-button'}
        aria-label={t('cartLinkLabel')}
        Icon={IconPickup}
        doNotWrapText
        to={
          isMultiCart
            ? undefined
            : cartsInfo.length
            ? generateCountryPath(CartRoutes.ProducerCart, { producerSlug: cartsInfo[0].slug })
            : generateCountryPath(CartRoutes.Cart)
        }
        iconColor={theme.color.accentApproving100}
        className="primaryAction"
      >
        {childrenFromProps}
      </StyledIconButton>
    ),
    [cartsInfo, childrenFromProps, generateCountryPath, isMultiCart, t],
  )

  const Control = useMemo(
    () => ({ children, ...props }: any) => (
      <StyledControl {...props}>
        {IconButton}
        {children}
      </StyledControl>
    ),
    [IconButton],
  )

  return (
    <div data-test-id="cart-button-wrapper">
      {isMultiCart ? (
        <BasicSelect
          options={cartsInfo}
          onChange={option => {
            if (option) {
              const path = generateCountryPath(CartRoutes.ProducerCart, { producerSlug: option.slug })
              navigate(path)
            }
          }}
          onMenuOpen={() => {
            track(HeaderEvents.UpperlineClick, { type: 'truck', ...analyticData })
          }}
          getOptionLabel={getCartInfoTitle}
          getOptionValue={getCartInfoValue}
          isSearchable={false}
          customStyles={styles}
          components={{
            Control,
          }}
          menuMaxWidth="250px"
          menuMinWidth="150px"
          formatOptionLabel={option => (
            <StyledOptionLabel>
              <TitleWrapper>{option.title}</TitleWrapper>
              <LogoImg src={producersDict[option.slug]?.logo_url || logoPlaceholder} />
            </StyledOptionLabel>
          )}
        />
      ) : (
        IconButton
      )}
    </div>
  )
}

export default CartButton

import React, { useCallback, useState } from 'react'
import {
  Checkbox,
  Button,
  IconQuestion,
  IconTruck,
  IconUser,
  Modal,
  useDidMount,
  useFormManager,
} from '@agro-club/frontend-shared'
import styled, { StyledProps } from 'styled-components'
import * as Styled from './styled'
import AddressForm, { AddressFormType } from 'views/components/AddressForm/AddressForm'
import UserInfoForm, { PersonalData } from 'views/components/UserInfoForm/UserInfoForm'
import { useTranslation } from 'react-i18next'
import PartnerInfoForm from 'views/components/PartnerInfoForm/PartnerInfoForm'
import { AnalyticsEvent, Progress } from 'modules/types'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { RequiredBut } from 'types/generics'
import { Partner, Partnership } from 'types/entities/userProfile'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import useWLFeatureFlags from 'hooks/useWLFeatureFlags'
import { generateCustomFeatureFlag, generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import { FeatureFlagModifiers, FieldLocation, FieldNames } from 'types/entities/config'
import OrderProvince, { OrderProvinceForm } from '../OrderProvince/OrderProvince'
import { SignUpEvents } from './events'
import { Page } from 'modules/utils/analytics-utils/pageNames'

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: grid;
  grid-gap: 14px;
  grid-template-columns: max-content max-content;
  color: ${(props: StyledProps<{}>) => props.theme.color.onSurfaceHighEmphasys};
  margin-bottom: 24px;
  align-items: center;

  &:not(:first-child) {
    margin-top: 40px;
  }
`

const LearnMoreWrapper = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  display: flex;
  color: ${props => props.theme.color.primary600};
  align-items: center;
  transition: opacity 0.2s ease-in;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  > span {
    margin-left: 5px;
  }
`

const QuestionIcon = styled(IconQuestion)`
  fill: ${props => props.theme.color.primary600};
  width: 16px;
  height: 16px;
`

const FormSection = styled(Styled.FormSection)`
  margin-bottom: 32px;
`

const CommonFarmHelp: React.FC = () => {
  const [opened, setOpened] = useState(false)
  const { t } = useTranslation('profile')
  return (
    <LearnMoreWrapper onClick={() => setOpened(!opened)}>
      <QuestionIcon />
      <span>{t('accountInformation.farmLearnMore')}</span>
      <Modal isOpen={opened} onClose={() => setOpened(false)}>
        {t('accountInformation.farmLearnMoreText')}
      </Modal>
    </LearnMoreWrapper>
  )
}

enum Forms {
  UserInfo = 'userInfo',
  MailingAddress = 'mailingAddress',
}

type FormikManagerData = {
  userInfo: RequiredBut<PersonalData, 'cheque_payee_name'>
  deliveryPoint: Required<AddressFormType>
  mailingAddress: Required<AddressFormType>
  orderProvince: OrderProvinceForm
  'partner-0': Partner
  'partner-1': Partner
  'partner-2': Partner
  // and so on
}

export type SignUpFormData = {
  userInfo: Required<Omit<PersonalData, 'cheque_payee_name'>>
  mailingAddress: Required<AddressFormType>
  mainAddress: Required<AddressFormType>
  partnership: Partnership
  orderProvince: OrderProvinceForm
}

const SignUpForm: React.FC<{
  onSubmit(form: SignUpFormData): void
  email?: string
  phone?: string
  retailerRegions?: string[]
  progress?: Progress
}> = ({ onSubmit, email, phone, retailerRegions, progress }) => {
  const { t } = useTranslation(['signUp', 'common', 'profile'])

  const formManager = useFormManager<FormikManagerData>()
  const [partners, setPartners] = useState([{}])
  const [sameAsMailingAddress, setSameAsMailingAddress] = useState(false)
  const [defaultOrderProvinceId, setDefaultOrderProvinceId] = useState('')
  const { track } = useAnalyticsSSR()
  const detectedCountry = useDetectedCountry()

  const checkFeatureFlag = useWLFeatureFlags()
  const hasAccess = useCallback(flag => !checkFeatureFlag(flag), [checkFeatureFlag])

  // do not use hasAccess() here, this flag works not like partnership-hidden flag
  const isDeliveryAddressAvailable = checkFeatureFlag(
    generateCustomFeatureFlag(FieldLocation.AccountForm, FieldNames.DeliveryAddress),
  )

  const mainAddress: AddressFormType = { country: detectedCountry }

  useDidMount(() => {
    track(AnalyticsEvent.Page, {
      name: Page.SignUpAccountInfoScreen,
      country: detectedCountry,
    })
  })

  const handleFormSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()
      const [valid, forms] = await formManager.submitAll()
      if (!valid) {
        return
      }

      if (valid) {
        const partners = Object.keys(forms)
          .filter(key => {
            if (key.startsWith('partner')) {
              const item = forms[key as 'partner-0']
              return Object.values(item as Partner).some(Boolean)
            }
            return false
          })
          .map(key => forms[key as 'partner-0'])

        const { cheque_payee_name, ...userInfo } = forms[Forms.UserInfo]
        onSubmit({
          userInfo,
          mailingAddress: forms['mailingAddress'],
          mainAddress: isDeliveryAddressAvailable ? forms['deliveryPoint'] : forms['mailingAddress'],
          orderProvince: forms['orderProvince'],
          partnership: {
            cheque_payee_name: cheque_payee_name || '',
            partners: partners || null,
          },
        })
        track(SignUpEvents.AccountInfoConfirmClick, {
          country: detectedCountry,
        })
      }
    },
    [detectedCountry, formManager, isDeliveryAddressAvailable, onSubmit, track],
  )

  const handleSetSameAsMailingAddress = useCallback(() => {
    setSameAsMailingAddress(!sameAsMailingAddress)

    if (!sameAsMailingAddress) {
      formManager.slots['deliveryPoint'].setValues(formManager.slots['mailingAddress'].values)
    }
  }, [formManager.slots, sameAsMailingAddress])

  const handleAddPartnershipPerson = () => {
    setPartners([...partners, {}])
  }

  const handleAddressProvinceChange = (provinceId: string) => {
    setDefaultOrderProvinceId(provinceId)
  }

  const onChangeAddressFormCountry = (country: string) => {
    formManager.slots['mailingAddress'].setFieldValue('country', country)
  }

  return (
    <form>
      <Title data-test-id={'signup-form'}>
        <IconUser />
        {t('accountInformation.title')}
      </Title>
      {/* personal information */}
      <FormSection title={t('accountInformation.personalInfo')}>
        <UserInfoForm
          initialValues={{
            email,
            phone_number: phone,
            country: detectedCountry,
          }}
          editable
          useFormik={formManager.bind('userInfo')}
          fields={{ email: { locked: false }, phone_number: { hidden: false, locked: true } }}
          onChangeAddressFormCountry={onChangeAddressFormCountry}
        />
      </FormSection>
      {/* mailing address */}
      <FormSection title={t('accountInformation.mailingAddress')}>
        <AddressForm
          initialValues={{ country: detectedCountry }}
          editable
          useFormik={formManager.bind('mailingAddress')}
          formToSync={sameAsMailingAddress ? formManager.slots['deliveryPoint'] : null}
          onProvinceChange={handleAddressProvinceChange}
        />
      </FormSection>
      {/* province that can be used to filter retailers (in the process of placing an order) */}
      <OrderProvince
        initialValues={{
          retailer_regions_ids: retailerRegions || [],
          country: formManager.slots['userInfo']?.values.country || detectedCountry,
        }}
        editable={true}
        useFormik={formManager.bind('orderProvince')}
        defaultProvinceId={defaultOrderProvinceId}
      />
      {/* partnership */}
      {hasAccess([
        generateFieldModifierString(FieldLocation.AccountForm, FieldNames.Partnership, FeatureFlagModifiers.Hidden),
      ]) && (
        <FormSection title={t('accountInformation.commonFarm')} optional>
          <Styled.PartnershipInfo>
            {t('profile:partnership.info')}
            <CommonFarmHelp />
          </Styled.PartnershipInfo>
          {partners.map((item, index) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore computed form manager key
            return <PartnerInfoForm key={index} useFormik={formManager.bind(`partner-${index}`)} editable />
          })}
          <Styled.PartnershipButton onClick={handleAddPartnershipPerson} type="button">
            {t('profile:partnership.addPerson')}
          </Styled.PartnershipButton>
        </FormSection>
      )}
      {/* delivery address */}
      {isDeliveryAddressAvailable && (
        <>
          <Title>
            <IconTruck />
            {t('deliveryPoint.title')}
          </Title>

          <FormSection>
            <Styled.CheckboxWrapper>
              <Checkbox
                label={t('deliveryPoint.sameAsMailing')}
                isChecked={sameAsMailingAddress}
                onChange={handleSetSameAsMailingAddress}
              />
            </Styled.CheckboxWrapper>
            <AddressForm
              initialValues={mainAddress}
              editable={!sameAsMailingAddress}
              useFormik={formManager.bind('deliveryPoint')}
            />
          </FormSection>
        </>
      )}
      <Button
        id="signup-form-submit"
        intent={'primary'}
        filled
        disabled={!formManager.dirty}
        onClick={handleFormSubmit}
        progress={progress}
      >
        {t('common:confirm')}
      </Button>
    </form>
  )
}

export default SignUpForm

import { LocalizedValue, Status } from 'types/entities'

export enum DiscountTypes {
  Quantity = 'quantity',
  Promocode = 'promocode',
  Gift = 'non_monetary',
  Percentage = 'percentage',
}

export type QtyTierRule = {
  type: DiscountTypes.Quantity
  amount: string
  min_qty: number
  max_qty?: number
}

export type PromocodeTierRule = {
  type: DiscountTypes.Promocode
  amount: string
  min_qty: number
  max_qty?: number

  promocode: string
}

export type GiftTierRule = {
  type: DiscountTypes.Gift
  min_qty: number
  max_qty?: number
  limit: number
  available_gifts: string[]
  amount: string
}

export type PercentTierRule = {
  end_date: Date
  min_qty: number
  max_qty?: number
  percent: string
  start_date: Date
  type: DiscountTypes.Percentage
  amount?: string
}

export type TierRulesUnion = Partial<QtyTierRule | PromocodeTierRule | GiftTierRule | PercentTierRule>

export enum DiscountRuleScope {
  FarmerOrders = 'farmer_orders',
  DistributorOrder = 'distributor_orders',
}

export type DiscountRule = {
  id: string
  _id: string // TODO remove _id & all the references as soon as backend is ready
  producer_id: string
  season_id: string
  campaign_name?: string
  slug?: string
  products_ids: string[]
  status: Status
  title: string
  start_date: string
  end_date: string
  tiers_rules: TierRulesUnion[]
  rule_type?: DiscountTypes

  link_url_i18n: LocalizedValue
  link_label_i18n: LocalizedValue

  scopes?: DiscountRuleScope[]
}

export type CalculatedTier = {
  amount: string
  is_applied: boolean
  qty_to_apply: number
  show?: boolean
  tier_rule: TierRulesUnion
}

export type CalculatedDiscountDto = {
  is_related: boolean
  rule: DiscountRule
  tiers: CalculatedTier[]
  amount: string
  next_lvl_qty: number
}

import React, { useMemo } from 'react'
import { Progress, SimpleSelect, SimpleSelectProps } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import { useTranslation } from 'react-i18next'
import { Status } from 'types/entities'
import { PackageTypes } from 'modules/domain/packageTypes/types'
import { Select } from '../Select/Select'
import styled from 'styled-components'
import { SkuOptionsContext } from '../SkuOptionsComponent/SkuOptionsComponent'

export type PackageTypeSelectProps = Omit<SimpleSelectProps, 'options'> & {
  packageTypes: PackageTypes[]
  filter?: (item: PackageTypes) => boolean
  disabled?: boolean
  context?: SkuOptionsContext
}

const Wrapper = styled.div`
  margin-top: 16px;
`

export const PackageTypeSelect: React.FC<PackageTypeSelectProps> = ({
  value,
  packageTypes,
  filter,
  disabled = false,
  progress,
  context,
  ...otherProps
}) => {
  const { t } = useTranslation('packageTypes')
  const { pick } = useLangPicker()
  const options = useMemo(() => {
    let filteredList = packageTypes
    if (filter) {
      filteredList = packageTypes.filter(filter)
    }

    return filteredList
      .filter(item => item.status === Status.Active)
      .map(({ id, title_i18n }) => ({
        id,
        title: pick(title_i18n),
      }))
  }, [filter, packageTypes, pick])

  const isLoading = useMemo(() => !!(!value && (progress === Progress.WORK || progress === Progress.ERROR)), [
    progress,
    value,
  ])

  const SelectComponent = context === SkuOptionsContext.Cart ? SimpleSelect : Select

  return (
    <Wrapper data-test-id={'package-type-select'}>
      <SelectComponent
        label={context === SkuOptionsContext.Cart ? undefined : t('label')}
        value={value}
        options={options}
        placeholder={t('placeholder')}
        errorText={t('validation:field_required')}
        required
        isSearchable
        isLoading={isLoading}
        isDisabled={disabled}
        variant={context === SkuOptionsContext.Cart ? 'small' : 'default'}
        {...otherProps}
      />
    </Wrapper>
  )
}

import React from 'react'
import styled, { StyledProps } from 'styled-components'
import Menu from './Menu'

import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import Routes from './routes'
import { Routes as AllRoutes } from 'views/pages/routes'
import Breadcrumbs from 'views/ui/Breadcrumbs/Breadcrumbs'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import AccountInfoForm from './AccountInfoForm/loadable'
import DeliveryPointForm from './DeliveryPointForm/loadable'
import OrdersList from './OrdersList/loadable'
import OrderDetails from './OrderDetails/loadable'
import DocumentsList from './DocumentsList/DocumentsList'
import DocumentDetails from './DocumentDetails/DocumentDetails'
import { useCountryPath } from 'hooks/useCountryPath'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  align-items: start;
  grid-gap: 24px;

  ${props => props.theme.media.smallTablet`
    grid-template-columns: auto;
  `}

  ${props => props.theme.media.mobile`
    grid-template-columns: auto;
    grid-gap: 0;
  `}
`

const ContentWrapper = styled.div`
  margin-bottom: 48px;
  ${props => props.theme.media.mobile`
    border-top: 1px solid ${(props: StyledProps<{}>) => props.theme.color.secondary50};
  `}
`

const ProfilePage = () => {
  const generateCountryPath = useCountryPath()
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated)
  const location = useLocation()

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: generateCountryPath(AllRoutes.SignIn), state: { from: location.pathname } }} />
  }

  return (
    <Container>
      <Breadcrumbs />
      <Wrapper>
        <Menu />
        <ContentWrapper>
          <Switch>
            <Route path={Routes.AccountInfo} exact>
              <AccountInfoForm />
            </Route>
            <Route path={Routes.DeliveryPoint} exact>
              <DeliveryPointForm />
            </Route>
            <Route path={Routes.Orders} exact>
              <OrdersList />
            </Route>
            <Route path={Routes.OrderDetails}>
              <OrderDetails />
            </Route>
            <Route path={Routes.Documents} exact>
              <DocumentsList />
            </Route>
            <Route path={Routes.DocumentDetails}>
              <DocumentDetails />
            </Route>
            <Redirect to={generateCountryPath(Routes.AccountInfo)} />
          </Switch>
        </ContentWrapper>
      </Wrapper>
    </Container>
  )
}

export default ProfilePage

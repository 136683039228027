// import { endpoints } from 'modules/endpoints'
import { Document, DocumentListRequestSorting, DocumentListRequestFilter, DocumentDTO, SignUrlDTO } from './types'
import { ListResponse } from 'types/api'
import { ApiService } from 'service/api/interface'
import * as stubs from './stubs/documents'
import { endpoints } from 'modules/endpoints'

export const getList = (apiClient: ApiService) => async (
  _filter: DocumentListRequestFilter,
  _sorting: DocumentListRequestSorting,
  _page: number,
  _pageSize: number,
): Promise<ListResponse<Document>> => {
  return apiClient.get(endpoints.documents())
}

export const getItem = (apiClient: ApiService) => async (id: string) => {
  return apiClient.get(endpoints.documents(id))
}

export const getItemUrl = (apiClient: ApiService) => async (id: string) => {
  const res = await apiClient.get<ArrayBuffer>(endpoints.documentFileUrl(id), {}, { responseType: 'arraybuffer' })
  return URL.createObjectURL(new Blob([res], { type: 'application/pdf' }))
}

export const getItemSignedUrl = (apiClient: ApiService) => async (id: string) => {
  const res = await apiClient.get<ArrayBuffer>(endpoints.documentSignedFileUrl(id), {}, { responseType: 'arraybuffer' })
  return URL.createObjectURL(new Blob([res], { type: 'application/pdf' }))
}

export const addItem = (_apiClient: ApiService) => async (_dto: DocumentDTO) => {
  return Promise.resolve(stubs.oneItem)
}

export const updateItem = (_apiClient: ApiService) => async (_id: string, _dto: Partial<DocumentDTO>) => {
  return Promise.resolve(stubs.oneItem)
}

export const removeItem = (_apiClient: ApiService) => async (_id: string) => {
  return Promise.resolve(stubs.oneItem)
}

export const getSignUrl = (apiService: ApiService) => async (dto: SignUrlDTO) => {
  return apiService.post<{ url: string }>(endpoints.documentEnvelopeUrl(dto.templateId), { return_url: dto.returnUrl })
}

export const documentSignSuccessCallback = (apiService: ApiService) => (id: string) => {
  return apiService.put(endpoints.documents(id), { status: 'processing' })
}

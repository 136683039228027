import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import {
  RelatedProduct,
  RelatedProductState,
  RelatedProductListRequestFilter,
  RelatedProductListRequestSorting,
} from './types'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

import { FetchError } from 'modules/domain/types'

export const initialState: RelatedProductState = {
  productId: '',

  items: {},
  meta: {},
  ids: [],
  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: 8,
}

class RelatedProductReducer extends ImmerReducer<RelatedProductState> {
  listRequested(params: {
    productId: string
    filter?: RelatedProductListRequestFilter
    sorting?: RelatedProductListRequestSorting
    page?: number
  }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchError = null
    this.draftState.productId = params.productId
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }
  listRequestSucceed(list: RelatedProduct[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }
  listRequestFailed(error: FetchError) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }

  filterUpdated(filter: RelatedProductListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingUpdated(sorting: RelatedProductListRequestSorting) {
    this.draftState.sorting = sorting
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
  }

  listRequestNextSucceed(list: RelatedProduct[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...new Set([...this.draftState.ids, ...getIds(list)])]
  }

  listRequestNextFailed(error: FetchError) {
    this.draftState.listFetchNextProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }
}

export const RelatedProductActions = createActionCreators(RelatedProductReducer)
export default RelatedProductActions
export const reducer = createReducerFunction(RelatedProductReducer, initialState)

import React from 'react'
import { CartItem } from 'modules/domain/cart/types'
import { useSelector } from 'react-redux'
import CartSelectors from 'modules/domain/cart/selectors'
import { QtyDiscountHint } from 'views/components/QtyDiscountHint/QtyDiscountHint'

export const QtyHintDesktop: React.FC<{
  item: CartItem
  onChange: (id: string, value: number) => void
  active?: boolean
}> = ({ item, onChange, active }) => {
  const hasDiscount = useSelector(CartSelectors.hasDiscount(item.product?.producer?.slug, item.id))
  const suggestions = useSelector(state =>
    CartSelectors.nextDiscountLvlSuggestions(state, item.product?.producer?.slug || ''),
  )
  if (!hasDiscount) return null

  const { id, qty } = item

  return (
    <QtyDiscountHint // Exclude negative and zero suggestions and find min qty to apply next level discount.
      missingQty={suggestions[id]}
      onAddItems={amount => onChange(id, Number(qty) + amount)}
      // If max suggestion for category equals to zero or negative number - it is max discount
      isHighestDiscountReached={suggestions[id] <= 0}
      active={active}
    />
  )
}

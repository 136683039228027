import React from 'react'
import Cart from 'views/components/Cart/Cart'
import CartSku from 'views/components/CartSku/CartSku'
import Breadcrumbs from 'views/ui/Breadcrumbs/Breadcrumbs'
import { useTranslation } from 'react-i18next'
import useHelmet from 'hooks/useHelmet'
import usePersistentScroll from 'hooks/usePersistentScroll'
import { useParams } from 'react-router'
import { useProducer } from 'modules/domain/producer/hooks'

const CartPage = () => {
  const { t } = useTranslation(['cart', 'checkout'])
  const { producerSlug } = useParams<{ producerSlug?: string }>()
  const [, producer] = useProducer(producerSlug)

  useHelmet({ title: t('cart:metaTitle') })
  usePersistentScroll('cart')

  const breadcrumbs = [{ title: t('checkout:cartTitle') }]
  const isStorefront = producer?.sku_orders

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      {isStorefront ? <CartSku /> : <Cart />}
    </>
  )
}

export default CartPage

import { defaultTheme, createMedia, creatMediaQueries } from '@agro-club/frontend-shared'

const breakpoints = {
  desktop: { minWidth: 1280 },
  smallDesktop: { minWidth: 1280, maxWidth: 1440 },
  tablet: { maxWidth: 1279, minWidth: 768 },
  smallTablet: { maxWidth: 1023 },
  mobile: { maxWidth: 767 },
}

export const queries = creatMediaQueries(breakpoints)

export const theme = {
  ...defaultTheme,
  media: createMedia(breakpoints),
  queries,
  breakpoints,
}

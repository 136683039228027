import useApiService from 'hooks/useApiService'
import { Progress, ResourceHook } from 'modules/types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import CartSkuSelectors from '../cartSku/selectors'
import { fetchSkuIncentivePrograms } from './managers'
import { CalculatedSkuDiscountDto, CalculatedSkuTier } from './types'
import { Sku } from '../sku/types'

export const useSkuDiscountInfo = (discounts?: CalculatedSkuDiscountDto[], sku?: Sku) => {
  const nextLvlQuantity = useMemo(() => {
    if (!discounts) return 1
    // TODO Let's use ramda here?
    const allTiers: CalculatedSkuTier[] = []

    discounts?.forEach(d => {
      allTiers.push(...d.tiers)
    })

    const allPositiveSuggestions = allTiers.map(tier => tier.qty_to_apply).filter(qty => qty > 0)

    const qty = Math.min(...allPositiveSuggestions)
    return isFinite(qty) ? qty : 0
  }, [discounts])

  const currentDiscountAmount = useMemo(
    () => (discounts ? discounts.reduce((sum, d) => sum + parseFloat(d.amount), 0) : 0),
    [discounts],
  )

  const currentDiscountAmountPerBag = useMemo(() => {
    const appliedTiers = discounts?.reduce((tiers: CalculatedSkuTier[], discounts) => {
      const tier = discounts.tiers.find(t => t.is_applied)
      if (tier) {
        return [...tiers, tier]
      }
      return tiers
    }, [])

    const totalDiscountPerBag = appliedTiers?.reduce(
      (acc: number, tier) => acc + parseFloat(tier.tier_rule.amount || '0'),
      0,
    )

    return totalDiscountPerBag || 0
  }, [discounts])

  const hasDiscount = useMemo(() => {
    return !!(discounts && discounts.length && discounts.some(d => d.program.sku_ids.includes(sku?.id || '')))
  }, [discounts, sku?.id])

  return {
    currentDiscountAmountPerBag,
    nextLvlQuantity,
    currentDiscountAmount,
    hasDiscount,
    isHighestDiscountReached: nextLvlQuantity <= 0,
  }
}

export type UseSkuCalculatedDiscountsProps = {
  sellerSlug: string | undefined
  skuId: string | undefined
  quantity: string
  preventFetch?: boolean
}

export const useSkuDiscounts: ResourceHook<CalculatedSkuDiscountDto[], [UseSkuCalculatedDiscountsProps]> = ({
  skuId = '',
  quantity,
  sellerSlug = '',
}) => {
  const api = useApiService()
  const [progress, setProgress] = useState(Progress.IDLE)
  const [discounts, setDiscounts] = useState<CalculatedSkuDiscountDto[]>()
  const getDiscountsBySkuMemo = useCallback(state => CartSkuSelectors.getDiscountsBySku(state, sellerSlug, skuId), [
    sellerSlug,
    skuId,
  ])
  const discountsFromCart = useSelector(getDiscountsBySkuMemo)
  const mounted = useRef(false)

  useEffect(() => {
    setDiscounts([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuId])

  useEffect(() => {
    mounted.current = true
    if (api && skuId) {
      setProgress(Progress.WORK)
      fetchSkuIncentivePrograms(api)({ quantity, sku_id: skuId })
        .then(result => {
          if (mounted.current) {
            setDiscounts(result.discounts)
            setProgress(Progress.SUCCESS)
          }
        })
        .catch(() => {
          if (mounted.current) {
            setProgress(Progress.ERROR)
          }
        })
    }
    return () => {
      mounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, skuId])

  useEffect(() => {
    if (discountsFromCart && discountsFromCart.length) setDiscounts(discountsFromCart)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountsFromCart])

  return [progress, discounts]
}

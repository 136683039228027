import { IconCross, IconPlus } from '@agro-club/frontend-shared'
import styled from 'styled-components'

export const SkuOptionSelect = styled.div`
  position: relative;
  &:not(:first-of-type) {
    margin-top: 16px;
  }
`

export const AddOption = styled(IconPlus)`
  position: absolute;
  top: -4px;
  right: 20px;
  fill: ${props => props.theme.color.primary500};
  cursor: pointer;
  z-index: 1;
`

export const RemoveOption = styled(IconCross)`
  position: absolute;
  top: 1px;
  right: 0;
  fill: ${props => props.theme.color.accentDestruct500};
  cursor: pointer;
  z-index: 1;
`

export const PercentageOption = styled.div`
  margin-top: 8px;
`

import useWhiteLabelConfig from 'hooks/useWhiteLabelConfig'
import { useCallback } from 'react'
import { checkFeatureFlags } from 'helpers/featureFlags'

const useWLFeatureFlags = () => {
  const { config } = useWhiteLabelConfig()
  const check = useCallback(config => checkFeatureFlags(config?.feature_flags), [])

  return check(config)
}

export default useWLFeatureFlags

import React, { useCallback, useState } from 'react'
import styled, { css, StyledProps } from 'styled-components'
import { useScrollTrigger } from '@agro-club/frontend-shared'
import useMatchMedia from 'hooks/useMatchMedia'
import { theme, queries } from 'theme/theme'

const paddings = css`
  padding-left: 80px;
  padding-right: 80px;

  ${props => props.theme.media.smallDesktop`
    padding-left: 40px;
    padding-right: 40px;
  `}
  ${props => props.theme.media.tablet`
    padding-left: 32px;
    padding-right: 32px;
  `}
  ${props => props.theme.media.mobile`
    padding-left: 0;
    padding-right: 0;
  `}
`

const RootStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${(props: StyledProps<{}>) => props.theme.color.backgroundPrimary};

  @supports (-webkit-touch-callout: none) {
    /* hack for Safari */
    min-height: -webkit-fill-available;
  }
`

const HeaderStyled = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
  background: ${(props: StyledProps<{}>) => props.theme.color.onPrimaryLight};
  height: ${(props: StyledProps<{}>) => props.theme.size.headerHeight}px;
  transition: 0.2s box-shadow;

  ${paddings}

  ${props => props.theme.media.mobile`
    height: ${(props: StyledProps<{}>) => props.theme.size.mobileHeaderHeight}px;
  `}

  [data-scrolled='true'] & {
    box-shadow: 0 0 30px -10px ${(props: StyledProps<{}>) => props.theme.color.secondary200};
  }
`

const ContentStyled = styled.main`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: calc(${(props: StyledProps<{}>) => props.theme.size.maxContentWidth}px + 160px);
  margin: 0 auto;
  width: 100%;
  flex-grow: 1;
  ${paddings};

  &::before {
    content: '';
    display: block;
    height: ${(props: StyledProps<{}>) => props.theme.size.headerHeight}px;
  }

  ${props => props.theme.media.smallDesktop`
     max-width: calc(${(props: StyledProps<{}>) => props.theme.size.maxContentWidth}px + 80px);
  `}
  ${props => props.theme.media.tablet`
    margin-bottom: 20px;
  `}
  ${props => props.theme.media.mobile`
    &::before {
      height: ${(props: StyledProps<{}>) => props.theme.size.mobileHeaderHeight}px;
    }
  `}
`

const FooterStyled = styled.footer`
  flex-shrink: 0;
  background: ${(props: StyledProps<{}>) => props.theme.color.secondary25};
  ${paddings}
`

export const Root: React.FC = ({ children }) => {
  const isMobile = useMatchMedia(queries.mobile)
  const [isScrolled, setIsScrolled] = useState(false)
  const handleScrollDown = useCallback(() => setIsScrolled(false), [])
  const handleScrollUp = useCallback(() => setIsScrolled(true), [])
  const delta = (isMobile ? theme.size.mobileHeaderHeight : theme.size.headerHeight) * 0.3
  const { anchor } = useScrollTrigger(handleScrollDown, handleScrollUp, delta)
  return (
    <RootStyled data-scrolled={isScrolled}>
      {anchor}
      {children}
    </RootStyled>
  )
}

export const Header: React.FC = ({ children }) => {
  return <HeaderStyled id={'page-header'}>{children}</HeaderStyled>
}

export const Main: React.FC = ({ children }) => {
  return <ContentStyled id={'page-main'}>{children}</ContentStyled>
}

export const Footer: React.FC = ({ children }) => {
  return <FooterStyled id={'page-footer'}>{children}</FooterStyled>
}

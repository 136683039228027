import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Input, FormikHook, SimpleSelect } from '@agro-club/frontend-shared'
import { FormikHelpers } from 'formik'
import { ProfileAddress } from 'types/entities/userProfile'
import { CountrySelect } from '../CountrySelect/CountrySelect'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import { useRegions } from 'modules/domain/collection/hooks'
import { useSelector } from 'react-redux'
import CollectionSelectors from 'modules/domain/collection/selectors'

export type AddressFormType = Partial<ProfileAddress>

type Props = {
  initialValues: AddressFormType
  editable?: boolean
  useFormik: FormikHook
  formToSync?: FormikHelpers<ProfileAddress> | null
  onProvinceChange?: (provinceId: string) => void
}

const InputsContainer = styled.div`
  width: 100%;
`

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

const AddressForm = ({ initialValues, editable = true, useFormik, formToSync, onProvinceChange }: Props) => {
  const { t } = useTranslation(['common', 'profile', 'validation'])
  const defaultCountry = useDetectedCountry()

  const validator = useMemo(() => {
    const requiredStr = () => Yup.string().required(t('validation:field_required'))
    const requiredWithNoSpaces = () =>
      Yup.string()
        .trim(t('validation:extraSpaces'))
        .strict(true)
        .required(t('validation:field_required'))

    return Yup.object({
      city: requiredWithNoSpaces(),
      country: requiredStr(),
      region_id: requiredStr(),
      postal_code: requiredStr(),
      address: requiredWithNoSpaces(),
    })
  }, [t])

  const formik = useFormik<AddressFormType>({
    initialValues: {
      ...initialValues,
      country: initialValues.country || defaultCountry,
    },
    onSubmit: noop,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validator,
  })

  const countries = useSelector(CollectionSelectors.countries)
  const [, regions] = useRegions(countries.map(country => country.id))
  const regionsOptions = useMemo(
    () =>
      regions
        ? regions
            .filter(e => e.country === formik.values.country)
            .map(reg => ({
              id: reg.id,
              title: reg.title,
            }))
        : [],
    [regions, formik.values.country],
  )

  const handleInputChange = useCallback(
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const onChangeHandler = formik.getFieldProps(field).onChange

      if (onChangeHandler) {
        onChangeHandler(e)
      }
      if (formToSync) {
        formToSync.setFieldValue(field, e.target.value)
      }
    },
    [formik, formToSync],
  )

  const handleSimpleSelectChange = (field: string, value?: string | number): void => {
    formik.setFieldValue(field, value)

    if (formToSync) {
      formToSync.setFieldValue(field, value)
    }
  }

  const handleProvinceChange = (value?: string | number): void => {
    if (!!onProvinceChange && typeof value === 'string') {
      onProvinceChange(value)
    }
  }

  const shouldDisplayState = !initialValues.region_id && initialValues.state

  return (
    <InputsContainer>
      <Styled.FormRow>
        <Input
          {...formik.getFieldProps('address')}
          onChange={handleInputChange('address')}
          label={t('addressForm.address')}
          disabled={!editable}
          invalid={formik.touched.address && !!formik.errors.address}
          errorText={formik.errors.address}
        />
      </Styled.FormRow>
      <Styled.FormRow>
        <Input
          {...formik.getFieldProps('city')}
          onChange={handleInputChange('city')}
          label={t('addressForm.city')}
          disabled={!editable}
          invalid={formik.touched.city && !!formik.errors.city}
          errorText={formik.errors.city}
        />
      </Styled.FormRow>
      <Styled.FormRow>
        {shouldDisplayState && (
          <Input
            {...formik.getFieldProps('state')}
            onChange={handleInputChange('state')}
            label={t('addressForm.state')}
            disabled={true}
            invalid={formik.touched.state && !!formik.errors.state}
            errorText={formik.errors.state}
            optional
          />
        )}
      </Styled.FormRow>
      <Styled.FormRow data-test-id={'state-select'}>
        <SimpleSelect
          options={regionsOptions}
          {...formik.getFieldProps('region_id')}
          value={formik.values.region_id}
          isSearchable
          onChange={v => {
            handleSimpleSelectChange('region_id', v)
            handleProvinceChange(v)
            const region = regionsOptions.find(reg => reg.id === v)
            region && formik.setFieldValue('state', region.title)
          }}
          label={t('addressForm.provinceOrState')}
          isDisabled={!editable}
          invalid={formik.touched.region_id && !!formik.errors.region_id}
          errorText={formik.errors.region_id}
        />
      </Styled.FormRow>
      <Styled.FormRow>
        <Styled.CountryAndZipContainer>
          <CountrySelect
            onChange={value => formik.setFieldValue('country', value)}
            onMenuClose={() => formik.setTouched({ ...formik.touched, country: true })}
            value={formik.values.country}
            label={t('addressForm.country')}
            invalid={formik.touched.country && !!formik.errors.country}
            errorText={formik.errors.country}
            isDisabled
          />
          <Input
            {...formik.getFieldProps('postal_code')}
            onChange={handleInputChange('postal_code')}
            label={t('addressForm.postalCodeOrZip')}
            disabled={!editable}
            invalid={formik.touched.postal_code && !!formik.errors.postal_code}
            errorText={formik.errors.postal_code}
          />
        </Styled.CountryAndZipContainer>
      </Styled.FormRow>
    </InputsContainer>
  )
}

export default AddressForm

import { SectionBody, SectionContainer, useAction, useScrollToTop } from '@agro-club/frontend-shared'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useCountryPath } from 'hooks/useCountryPath'
import useHelmet from 'hooks/useHelmet'
import BadgeNew from 'views/components/BadgeNew/BadgeNew'
import useLangPicker from 'hooks/useLangPicker'
import { BadgeDto, Product, SeedTreatment } from 'modules/domain/product/types'
import BadgeLimited from 'views/components/BadgeLimited/BadgeLimited'
import Layout404 from 'views/layouts/404/404'
import { useSelector } from 'react-redux'
import ProductSelectors from 'modules/domain/product/selectors'
import ErrorLayout from 'views/layouts/ErrorLayout/ErrorLayout'
import { PromoInfoType } from 'modules/domain/collection/types'
import CollectionSelectors from 'modules/domain/collection/selectors'

import useMatchMedia from 'hooks/useMatchMedia'
import CartActions from 'modules/domain/cart/duck'
import CartSelectors from 'modules/domain/cart/selectors'
import { CartItem } from 'modules/domain/cart/types'
import ProductActions from 'modules/domain/product/duck'
import { useProduct } from 'modules/domain/product/hooks'
import { Progress } from 'modules/types'
import { productToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import { Page } from 'modules/utils/analytics-utils/pageNames'
import { getPrettyPrice } from 'modules/utils/helpers'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { queries } from 'theme/theme'
import { Currency } from 'types/entities'
import { DEFAULT_QTY } from './AdderWidget/constants'
import DiscountBar from './DiscountBar/DiscountBar'

import Badge from 'views/components/ProductBadges/Badge'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import AdderWidgetManager, {
  AdderValues,
} from 'views/pages/Producer/Product/ProductItem/AdderWidget/AdderWidgetManager'
import { Routes } from 'views/pages/routes'
import Breadcrumbs from 'views/ui/Breadcrumbs/Breadcrumbs'
import ProductItemEvents from './events'
import * as Styled from './styled'
import BadgeOutOfStock from 'views/components/BadgeOutOfStock/BadgeOutOfStock'
import GiftApplyingTerms from 'views/components/GiftApplyingTerms/GiftApplyingTerms'
import RelatedProducts from 'views/components/RelatedProducts/RelatedProducts'

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: stretch;
  flex-grow: 1;
  padding: 100px 0;
`

const BadgeList: React.FC<{ badges?: BadgeDto[] }> = ({ badges = [] }) => {
  return (
    <>
      {badges.map((badge: BadgeDto) => (
        <Badge key={badge.id} {...badge} />
      ))}
    </>
  )
}

const YoutubeVideo: React.FC<{ src: string; onVideoClick: () => void }> = ({ src, onVideoClick }) => {
  const isMobile = useMatchMedia(queries.mobile)
  const isTablet = useMatchMedia(queries.tablet)
  const height = isMobile ? 162 : isTablet ? 270 : 270
  const iFrame = useRef<HTMLIFrameElement | null>(null)
  const handleClickVideo = useCallback((): void => {
    if (iFrame.current) {
      if (document.activeElement === iFrame.current) {
        onVideoClick()
      }
    }
  }, [onVideoClick])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.focus() //force focus on the current window;
      window.addEventListener('blur', handleClickVideo)
    }
    return () => window.removeEventListener('blur', handleClickVideo)
  }, [handleClickVideo])

  return (
    <Styled.VideoIFrame
      ref={iFrame}
      width="100%"
      height={`${height}px`}
      src={src}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={'Video'}
    />
  )
}

const QuoteBlock: React.FC<{
  quote: {
    text: string
    author?: string
    company?: string
  }
}> = ({ quote }) => {
  const author = <Styled.QuoteAuthor>{quote.author}</Styled.QuoteAuthor>
  const company = (
    <div>
      {quote.author ? ' — ' : ''}
      {quote.company}
    </div>
  )

  return (
    <Styled.QuoteContainer>
      <Styled.QuoteText>{quote.text}</Styled.QuoteText>
      {quote.author || quote.company ? (
        <Styled.QuoteAuthorContainer>
          {quote.author ? author : null}
          {quote.company ? company : null}
        </Styled.QuoteAuthorContainer>
      ) : null}
    </Styled.QuoteContainer>
  )
}

export type ProductItemDto = Product & { activeSeedTreatment: SeedTreatment[] }

type ProductDetailsForm = {
  product: ProductItemDto
  firstAlreadyAddedItem?: CartItem
}

const ProductDetails: React.FC<ProductDetailsForm> = ({ product, firstAlreadyAddedItem }) => {
  const { t } = useTranslation(['order'])
  const isDesktop = useMatchMedia(queries.desktop)
  const isTablet = useMatchMedia(queries.tablet)
  const isMobile = useMatchMedia(queries.mobile)
  const { pick } = useLangPicker()
  const { track } = useAnalyticsSSR()
  const [mainPhoto, setMainPhoto] = useState<string | undefined>(undefined)

  const defaultOptions = useMemo(() => (firstAlreadyAddedItem ? firstAlreadyAddedItem.options : []), [
    firstAlreadyAddedItem,
  ])

  const defaultQty = useMemo(
    () => (firstAlreadyAddedItem ? firstAlreadyAddedItem.qty : product?.default_qty || product?.min_qty || DEFAULT_QTY),
    [product, firstAlreadyAddedItem],
  )

  const isOutOfStock = !!product.is_out_of_stock

  const [adderValues, setAdderValues] = useState<AdderValues>({
    qty: defaultQty,
    seedTreatment: firstAlreadyAddedItem?.seed_treatment_id,
    altPackagingQty: null,
    options: defaultOptions,
  })

  const updateItemAction = useAction(CartActions.itemUpdateRequested)
  const addCartItemAction = useAction(CartActions.fakeItemAddRequested)
  const getDiscountsAction = useAction(ProductActions.discountsRequested)

  const cartItem = useSelector(state =>
    CartSelectors.cartEntryByParams(state, product.id, {
      seed_treatment_id: adderValues.seedTreatment,
      options: adderValues.options,
    }),
  )

  const features = (product?.features_i18n || []).map(feature => ({ title: feature }))
  const attributes = useMemo(() => {
    return [...(product?.attributes || [])].map(attr => ({
      title: attr.title_i18n,
      value: attr.value_i18n,
    }))
  }, [product])

  const handleVideoClick = () => {
    track(ProductItemEvents.VideoClick, productToEventParams(product))
  }

  const onAddCartItem = useCallback(
    (qty: number) => {
      addCartItemAction(product, {
        qty,
        seedTreatment: adderValues.seedTreatment,
        packaging: adderValues.altPackagingQty,
      })
    },
    [addCartItemAction, adderValues.altPackagingQty, adderValues.seedTreatment, product],
  )

  const handleQtyChange = useCallback(
    (qty: number) => {
      setAdderValues({ ...adderValues, qty })
      if (!cartItem) {
        getDiscountsAction(product.id, qty, { seed_treatment_id: adderValues.seedTreatment })
      } else if (!cartItem?.is_fake) {
        updateItemAction(cartItem.id, {
          quantity: qty,
          packaging: adderValues.altPackagingQty,
          seed_treatment_id: adderValues.seedTreatment,
        })
      }
    },
    [adderValues, cartItem, getDiscountsAction, product.id, updateItemAction],
  )

  const handleSeedTreatmentChange = (seedTreatment?: string) => setAdderValues({ ...adderValues, seedTreatment })
  const handleOptionsChange = (options?: string[]) => setAdderValues({ ...adderValues, options })

  const handleDiscountApply = useCallback(
    (qtyToApply: number) => {
      handleQtyChange(adderValues.qty + qtyToApply)
      if (!cartItem) {
        onAddCartItem(adderValues.qty + qtyToApply)
      }
    },
    [adderValues, onAddCartItem, handleQtyChange, cartItem],
  )

  useEffect(() => {
    if (cartItem?.seed_treatment_id) handleSeedTreatmentChange(cartItem?.seed_treatment_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItem?.seed_treatment_id])

  useEffect(() => {
    if (cartItem?.options) handleOptionsChange(cartItem?.options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItem?.options])

  useEffect(() => {
    if (cartItem?.qty) handleQtyChange(cartItem?.qty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItem?.qty])

  return (
    <Styled.InnerContainer>
      <Styled.MainSectionWrapper>
        <SectionContainer noDivider>
          <SectionBody noGrid>
            <Styled.MainSection>
              {product.images[0] ? (
                <>
                  <Styled.PhotoBox>
                    <Styled.MainPhoto>
                      <img src={mainPhoto ?? product.images[0]} alt={pick(product.title_i18n)} />
                    </Styled.MainPhoto>
                    {product.images.length > 1 && (
                      <Styled.PhotoGallery>
                        {product.images?.map(image => (
                          <Styled.PhotoGalleryItem key={image}>
                            <img src={image} alt={pick(product.title_i18n)} onMouseOver={() => setMainPhoto(image)} />
                          </Styled.PhotoGalleryItem>
                        ))}
                      </Styled.PhotoGallery>
                    )}
                  </Styled.PhotoBox>
                </>
              ) : null}
              <Styled.Content>
                <Styled.Title data-test-id={'product-title'}>
                  <div>{pick(product.title_i18n)}</div>
                  {product.is_new ? (
                    <Styled.BadgeContainer>
                      <BadgeNew />
                    </Styled.BadgeContainer>
                  ) : null}
                  {product.is_limited ? (
                    <Styled.BadgeContainer>
                      <BadgeLimited />
                    </Styled.BadgeContainer>
                  ) : null}
                  {product.is_out_of_stock ? (
                    <Styled.BadgeContainer>
                      <BadgeOutOfStock />
                    </Styled.BadgeContainer>
                  ) : null}
                </Styled.Title>
                {isMobile && product.price && !product.wizard_id && (
                  <Styled.Price>
                    {getPrettyPrice(product.price, product.producer?.currency || Currency.CAD, product.price_type)}
                  </Styled.Price>
                )}
                {!isDesktop && (
                  <Styled.BadgeListContainer>
                    <BadgeList badges={product?.enrich_badges} />
                  </Styled.BadgeListContainer>
                )}
                <Styled.Description
                  dangerouslySetInnerHTML={{ __html: `<div>${pick(product.description_i18n)}</div>` }}
                ></Styled.Description>
                {isMobile && (
                  <AdderWidgetManager
                    product={product}
                    cartItem={cartItem}
                    values={adderValues}
                    onQtyChange={handleQtyChange}
                    onSeedTreatmentChange={handleSeedTreatmentChange}
                    onOptionsChange={handleOptionsChange}
                  />
                )}
                <Styled.Extras items={features} />
                {product.video_url && <YoutubeVideo src={product.video_url} onVideoClick={handleVideoClick} />}
                {product.quote?.text && <QuoteBlock quote={product.quote} />}
                {attributes.length > 0 && <Styled.Specs items={attributes} />}
                <Styled.Footer>
                  {product.files?.map((file, idx) => (
                    <Styled.FooterButton
                      key={idx}
                      icon={'document'}
                      to={pick(file.url_i18n)}
                      target={'_blank'}
                      onClick={() => track(ProductItemEvents.ProductInfoFileClick, productToEventParams(product))}
                    >
                      {pick(file.title_i18n) || t('ProductInfoFileButton')}
                    </Styled.FooterButton>
                  ))}
                </Styled.Footer>
                {pick(product.trademark_language_i18n) ? (
                  <Styled.LegalText>{pick(product.trademark_language_i18n)}</Styled.LegalText>
                ) : null}
              </Styled.Content>
              {isDesktop && (
                <Styled.BadgeListContainer>
                  <BadgeList badges={product?.enrich_badges} />
                </Styled.BadgeListContainer>
              )}
            </Styled.MainSection>
          </SectionBody>
        </SectionContainer>
        {isMobile && product.is_gift && (
          <>
            <GiftApplyingTerms product={product} />
          </>
        )}
        <RelatedProducts
          title={product.is_gift ? t('relatedProducts:titleForRelatedProducts') : t('relatedProducts:titleForGifts')}
          product={product}
        />
      </Styled.MainSectionWrapper>
      <div>
        {(isDesktop || isTablet) && (
          <>
            <AdderWidgetManager
              product={product}
              cartItem={cartItem}
              values={adderValues}
              onQtyChange={handleQtyChange}
              onSeedTreatmentChange={handleSeedTreatmentChange}
              onOptionsChange={handleOptionsChange}
            />
            {product.is_gift && (
              <>
                <GiftApplyingTerms product={product} />
              </>
            )}
            <Styled.Spacer />
            {!isOutOfStock && <DiscountBar product={product} onApply={handleDiscountApply} />}
          </>
        )}
        {isMobile && !isOutOfStock && <DiscountBar product={product} onApply={handleDiscountApply} />}
      </div>
    </Styled.InnerContainer>
  )
}

const ProductItem: React.FC = () => {
  useScrollToTop()
  const { t } = useTranslation(['order', 'discountBanner'])
  const params = useParams<{ productOrCardSlug: string; producerSlug: string }>()
  const generateCountryPath = useCountryPath()

  const [progress, product] = useProduct(params.productOrCardSlug)
  const alreadyAddedItem = useSelector(state =>
    CartSelectors.cartEntriesByProductSlug(state, params.productOrCardSlug),
  )[0]
  const cartInitProgress = useSelector(CartSelectors.initProgress)
  const meta = useSelector(state => ProductSelectors.meta(state, params.productOrCardSlug))
  const promoInfo = useSelector(state =>
    CollectionSelectors.promoInfo(state, PromoInfoType.CatalogCategory, product?.producer_id, product?.subcategory_id),
  )

  const productData = useMemo(() => {
    if (progress === Progress.SUCCESS && product) {
      return {
        title: product.title,
        titleMeta: product.title_meta || '',
        descriptionMeta: product.description_meta || '',
      }
    }
    return {
      title: '',
    }
  }, [progress, product])

  const breadcrumbs = useMemo(() => {
    const items: { path?: string; title: string }[] = []

    if (progress === Progress.SUCCESS && product) {
      if (product.producer) {
        items.push({
          path: generateCountryPath(Routes.CategoryList, { producerSlug: product.producer.slug }),
          title: product.producer.internal_name,
        })
      }
      if (product.category && product.producer) {
        items.push({
          path: generateCountryPath(Routes.SubCategoryList, {
            producerSlug: product.producer.slug,
            categorySlug: product.category.slug,
          }),
          title: product.category.title,
        })
      }
      if (product.subcategory && product.producer) {
        items.push({
          path: generateCountryPath(Routes.ProductOrCardList, {
            producerSlug: product.producer.slug,
            categorySlug: product.category?.slug,
            subCategorySlug: product.subcategory.slug,
          }),
          title: product.subcategory.title,
        })
      }
      if (productData) {
        items.push({
          title: productData.title,
        })
      }
    }
    return items
  }, [product, productData, progress, generateCountryPath])

  const promoInfoAnalyticData = {
    page: Page.ProductItem,
    ...productToEventParams(product),
  }

  useHelmet({ title: productData.titleMeta, description: productData.descriptionMeta, ogImage: product?.images?.[0] })

  const loading = () => (
    <SpinnerWrapper>
      <SpinnerLayout />
    </SpinnerWrapper>
  )
  const errorUnknown = () => <ErrorLayout />
  const error404 = () => <Layout404 />

  if (progress === Progress.WORK || cartInitProgress === Progress.WORK) {
    return loading()
  }

  if (!product) {
    return error404()
  }

  if (progress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  const productItem: ProductItemDto = {
    ...product,
    activeSeedTreatment: product.seed_treatment.filter(st => st.is_active) || [],
  }

  return (
    <Styled.Wrapper>
      <Breadcrumbs items={breadcrumbs} />
      {promoInfo && (
        <Styled.DiscountBanner
          primaryText={promoInfo ? promoInfo.title : t('primary')}
          secondaryText={promoInfo ? promoInfo.description : t('secondary')}
          modalText={promoInfo && promoInfo.message ? promoInfo.message.description : t('modalText')}
          modalTitle={promoInfo && promoInfo.message ? promoInfo.message.title : ''}
          size={'large'}
          styles={promoInfo.styles}
          analyticData={promoInfoAnalyticData}
        />
      )}
      <ProductDetails product={productItem} firstAlreadyAddedItem={alreadyAddedItem} />
    </Styled.Wrapper>
  )
}

export default ProductItem

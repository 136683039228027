import React from 'react'
import { TotalCheckoutWidget } from 'views/components/DiscountWidget/DiscountTotal'
import CartDefault from './CartDefault'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CartSelectors from 'modules/domain/cart/selectors'
import { SectionContainer, SectionTitle, SectionBody } from '@agro-club/frontend-shared'
import { useProducer } from 'modules/domain/producer/hooks'

const SectionBodyStyled = styled(SectionBody)`
  padding: 16px 24px 40px;
`

const TruckContainer = styled.div`
  margin-bottom: 32px;
  ${props => props.theme.media.smallTablet`
    max-width: 100%;
  `}
`

const CartDesktop: React.FC<{
  producerSlug: string
  optionsRequiredErrors: string[][]
  seedTreatmentErrors: boolean[]
  requiredOptionsValid: (id?: string, updatedOptions?: string[]) => boolean
  isQtyDisabled: boolean
}> = ({ producerSlug, optionsRequiredErrors, seedTreatmentErrors, requiredOptionsValid, isQtyDisabled }) => {
  const { t } = useTranslation(['checkout', 'discount'])
  const hasTotalInfo = useSelector(state => CartSelectors.hasTotalInfo(state, producerSlug))
  const [, producer] = useProducer(producerSlug)

  return (
    <div>
      {hasTotalInfo && <TotalCheckoutWidget producer={producer} />}
      <TruckContainer>
        <SectionContainer noDivider>
          <SectionTitle>
            {t('cart.title')} | {producer?.official_name || ''}
          </SectionTitle>
          <SectionBodyStyled paddedOnMobile noGrid>
            <CartDefault
              producerSlug={producerSlug}
              optionsRequiredErrors={optionsRequiredErrors}
              seedTreatmentErrors={seedTreatmentErrors}
              requiredOptionsValid={requiredOptionsValid}
              isQtyDisabled={isQtyDisabled}
            />
          </SectionBodyStyled>
        </SectionContainer>
      </TruckContainer>
    </div>
  )
}

export default CartDesktop

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from 'translations/en.json'
import en_US from 'translations/en_US.json'
import ru from 'translations/ru.json'
import fr from 'translations/fr.json'
import es from 'translations/es.json'
import es_SV from 'translations/es_SV.json'

import { i18nComposeWithUIComponents } from '@agro-club/frontend-shared'

const resources = i18nComposeWithUIComponents({
  en,
  ru,
  fr,
  es,
  en_US,
  es_SV,
})

export const init = defaultLang => {
  return i18n.use(initReactI18next).init({
    resources: resources,
    lng: defaultLang && resources[defaultLang] && defaultLang,
    fallbackLng: 'en',
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  })
}

export default i18n

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  padding: 0 8px;
  height: 24px;
  background-color: ${props => props.theme.color.secondary50};
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  color: ${props => props.theme.color.secondary300};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
`

const BadgeLimited: React.FC = () => {
  const { t } = useTranslation('product')
  return <Container data-test-id="badge-limited">{t('badge.limited')}</Container>
}

export default BadgeLimited

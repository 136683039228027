import { AxiosError } from 'axios'
import env from 'env'
import { Severity } from '@sentry/browser'
import HttpStatuses from 'http-status-codes'

type SentryInstance = typeof import('@sentry/browser')

const sentryErrorStatuses = new Set([
  HttpStatuses.INTERNAL_SERVER_ERROR,
  HttpStatuses.NOT_IMPLEMENTED,
  HttpStatuses.BAD_GATEWAY,
])

const sentryWarningStatuses = new Set([HttpStatuses.BAD_REQUEST, HttpStatuses.CONFLICT])

export const captureAxiosError = (sentry: SentryInstance | null | undefined, error: AxiosError) => {
  if (
    !sentry ||
    (error.response?.status &&
      !sentryErrorStatuses.has(error.response.status) &&
      !sentryWarningStatuses.has(error.response.status))
  )
    return
  const isBrowser = env.BROWSER

  const resolveErrorLevel = (status: number): Severity => {
    switch (true) {
      case status < 500:
        return Severity.Info
      case sentryWarningStatuses.has(status):
        return Severity.Warning
      default:
        return Severity.Error
    }
  }

  if (isBrowser) {
    sentry.addBreadcrumb({
      category: 'request',
      type: 'http',
      data: {
        path: error.config.url,
        method: error.config.method,
        type: 'error',
        error: {
          message: error.message,
          name: error.name,
        },
      },
    })
  }

  sentry.withScope(scope => {
    const httpStatus = error.response?.status || 0
    const apiHandle = error.config.url as string
    const level = resolveErrorLevel(httpStatus)
    scope.setTag('kind', 'apiRequest')
    scope.setTag('apiHandle', apiHandle)
    scope.setTag('httpStatus', String(httpStatus))
    scope.setTag('ssr', String(!isBrowser))
    scope.setLevel(level)
    scope.setExtras({
      httpStatus,
      apiHandle,
      method: error.config.method,
      query: error.config.params,
      data: error.config.data,
    })

    error.name = isBrowser ? 'Client API request error' : 'SSR API Request error'
    error.message = `${error.config.method} ${apiHandle} ${error.message}`
    sentry.captureException(error, {
      fingerprint: [apiHandle],
    })
  })
}

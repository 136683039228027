import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Drawer from 'views/ui/Drawer/Drawer'
import { CollapseButton, SectionContainer, SectionTitle, SectionBody } from '@agro-club/frontend-shared'
import DiscountTotal from 'views/components/DiscountWidget/DiscountTotal'
import { useProducer } from 'modules/domain/producer/hooks'
import CartSkuDefault from 'views/pages/Checkout/CartSku/CartSkuDefault'

const Body = styled(SectionBody)<{ collapsed: boolean }>`
  display: ${props => (props.collapsed ? 'none' : 'block')};
  overflow-y: auto;
`

const Container = styled(SectionContainer)<{ sticky: boolean; collapsed: boolean }>`
  background: ${props => props.theme.color.onPrimaryLight};
  position: ${props => (props.sticky && props.collapsed ? 'fixed' : 'absolute')};
  bottom: 0;
  left: 0;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.08);
  width: 100%;
  max-height: 100%;
`

const ListWrapper = styled.div`
  margin-top: 16px;
`

const CartSkuMobile: React.FC<{
  sticky: boolean
  producerSlug: string
}> = ({ sticky, producerSlug }) => {
  const { t } = useTranslation('checkout')
  const [collapsed, setCollapsed] = useState(true)
  const [, producer] = useProducer(producerSlug)
  const handleCollapse = useCallback(() => setCollapsed(!collapsed), [collapsed])

  return (
    <Drawer open={!collapsed} initialHeight={56}>
      <Container sticky={sticky} collapsed={collapsed} noDivider>
        <SectionTitle onClick={handleCollapse}>
          {t('cart.title')}
          <CollapseButton onClick={handleCollapse} collapsed={collapsed} />
        </SectionTitle>
        <Body paddedOnMobile collapsed={collapsed}>
          <div style={{ margin: '-12px -16px 0 -16px' }}>
            <DiscountTotal producer={producer} />
          </div>
          <ListWrapper>
            <CartSkuDefault producerSlug={producerSlug} />
          </ListWrapper>
        </Body>
      </Container>
    </Drawer>
  )
}

export default CartSkuMobile

import styled, { StyledProps } from 'styled-components'
import React from 'react'
import { Button, IconFilter } from '@agro-club/frontend-shared'
import DiscountBannerComponent from 'views/ui/DiscountBanner/DiscountBanner'
import Link from 'views/components/Link/Link'

const stickyFilterHeight = '56px'

export const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`
export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  ${props => props.theme.media.smallTablet`
    grid-template-columns: 1fr;
  `}

  ${props => props.theme.media.mobile`
    grid-template-columns: 1fr;
    grid-area: initial;
    grid-gap: 8px;
    padding: 0 16px calc(${stickyFilterHeight} + 32px);
  `}
`
export const ListItem = styled(Link)`
  position: relative;
  text-decoration: none;
  background-color: white;
  width: 100%;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 104px calc(100% - 120px);
  grid-gap: 16px;
  padding: 0 16px;
  overflow: hidden;
  transition: box-shadow 0.2s ease-in;
  &:hover {
    box-shadow: ${props => props.theme.shadow.blur40};
    cursor: pointer;
  }

  color: unset;
  height: auto;
  align-items: stretch;
`
export const ItemImage = styled.img`
  width: auto;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  display: block;
`

export const ItemImageStub = styled.div`
  width: 104px;
  height: 108px;
  border-radius: 8px;
  opacity: 0.5;
  background-color: ${props => props.theme.color.secondary50};
  margin: 16px 0;

  ${props => props.theme.media.mobile`
    max-height: 102px;
  `}
`

export const ItemDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  & > * {
    margin-bottom: 4px;
  }
`

export const ItemTitle = styled.div`
  color: ${props => props.theme.color.secondary200};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

export const ItemId = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props => props.theme.media.mobile`
      white-space: normal;
      overflow: visible;
  `}
`

export const ItemDescription = styled.div`
  font-size: 14px;
  line-height: 20px;
  word-wrap: break-word;
  ${props => props.theme.media.mobile`
    height: auto;
  `}
`

export const ItemSaveUpToContainer = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #ff9900;
`

export const FilterFooter = styled.div`
  display: none;
  height: 56px;
  padding: 8px 16px 0;
  flex-direction: row;
  justify-content: space-between;
`

export const FilterContainer = styled.div`
  background: ${props => props.theme.color.onPrimaryLight};
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  grid-area: filter;
  position: relative;

  ${props => props.theme.media.desktop`
    align-self: start;
    padding-bottom: 16px;
  `}
  ${props => props.theme.media.tablet`
    display: flex;
    flex-direction: row;
    padding: 20px 24px 12px;
  `}

  ${props => props.theme.media.mobile`
    border-radius: 8px 8px 0 0;
    box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.08);
    width: 100%;
    max-height: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    display: grid;
    grid-template-rows: max-content 1fr max-content;
    z-index: 1;

    ${FilterFooter} {
      &[data-collapsed="false"] {
        display: flex;
      }
    }

    &[data-sticky="true"][data-collapsed="true"] {
      position: fixed;
    }

    &[data-anchor-reached="true"][data-collapsed="true"] {
      bottom: 76px;
    }
  `}
`

const FilterGroupTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${props => props.theme.color.secondary200};
  margin-right: 24px;
  margin-bottom: 12px;
`

const FilterGroupBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  ${props => props.theme.media.desktop`
    & > * {
      margin-bottom: 12px;
      margin-right: 12px;
    }
  `}

  ${props => props.theme.media.tablet`
    & > * {
      margin-bottom: 8px;
      margin-right: 8px;
    }
  `}

  ${props => props.theme.media.mobile`
    & > * {
      margin-bottom: 12px;
      margin-right: 12px;
    }
  `}
`

const FilterImg = styled.img<{ noFilters: boolean }>`
  max-height: ${props => (props.noFilters ? '100px' : '50px')};
  max-width: 150px;
  align-self: flex-start;
  ${props => props.theme.media.mobile`
    max-height: 32px;
  `}
`

export const FilterTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 16px 0;
  display: none;
  ${props => props.theme.media.desktop`
    display: block;
  `}
`

const FilterIconStyled = styled(IconFilter)<StyledProps<{ ['data-active']: boolean }>>`
  margin-right: 8px;
  fill: ${props => (props['data-active'] ? props.theme.color.onPrimaryLight : props.theme.color.primary600)};
`

const FilterControlsContainer = styled.div`
  display: none;

  ${props => props.theme.media.mobile`
    display: block;
  `}
`

const FilterButtonStyled = styled(Button)<{ active: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${props => (props.active ? props.theme.color.onPrimaryLight : props.theme.color.primary600)};
`

export const FilterButton: React.FC<{ active?: boolean; onClick(): void }> = ({ active = false, onClick }) => {
  return (
    <FilterControlsContainer>
      <FilterButtonStyled active={active} onClick={onClick} intent={active ? 'primary' : 'secondary'} filled>
        <FilterIconStyled data-active={active} />
        Filters
      </FilterButtonStyled>
    </FilterControlsContainer>
  )
}

export const FilterHeader = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.media.desktop`
    padding: 16px 24px 0;
  `}
  ${props => props.theme.media.tablet`
    padding: 0;
  `}
  ${props => props.theme.media.mobile`
    padding: 8px 16px 0;
    flex-direction: row;
    justify-content: space-between;
    height: ${stickyFilterHeight};
  `}
`

export const FilterBody = styled.div<StyledProps<{ collapsed: boolean }>>`
  display: grid;
  ${props => props.theme.media.desktop`
    padding: 0 24px;
    grid-template-columns: auto;
  `}
  ${props => props.theme.media.tablet`
    grid-template-columns: max-content 1fr;
    margin-left: 24px;
    flex-grow: 1;
  `}
  ${props => props.theme.media.mobile`
    padding: 0 16px ;
    grid-template-columns: auto;
    overflow-y: auto;
    display: ${(props: StyledProps<{ collapsed: boolean }>) => (props.collapsed ? 'none' : 'grid')};
  `}
`

export const FilterLogoContainer = styled.div`
  min-width: 126px;
`
export const FilterLogo: React.FC<{ src: string; noFilters: boolean }> = ({ src, noFilters }) => {
  return <FilterImg src={src} noFilters={noFilters} />
}

export const FilterGroup: React.FC<{ title: string }> = ({ title, children }) => {
  return (
    <>
      <FilterGroupTitle>{title}</FilterGroupTitle>
      <FilterGroupBody>{children}</FilterGroupBody>
    </>
  )
}

export const DiscountBanner = styled(DiscountBannerComponent)`
  margin-bottom: 24px;
`

export const MainContent = styled.div`
  position: relative;
  display: grid;
  min-height: 0;
  min-width: 0;
  flex-grow: 1;
  ${props => props.theme.media.desktop`
    grid-template-areas: "filter list";
    grid-template-columns: minmax(min-content, 302px) 1fr;
    grid-gap: 24px;
  `}

  ${props => props.theme.media.tablet`
    grid-template-areas:
      "filter"
      "list";
    grid-template-rows: min-content 1fr;
    grid-gap: 24px;
  `}

  ${props => props.theme.media.mobile`
    display: block;
  `}
`

export const ClearFilterButton = styled.button`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
  box-shadow: none;
  text-decoration: underline;
  background: ${(props: StyledProps<{}>) => props.theme.color.onPrimaryLight};
  border-radius: 8px;
  height: 40px;
  color: ${(props: StyledProps<{}>) => props.theme.color.accentDestructive};
  white-space: nowrap;
`

export const ClearFilterButtonDesktop = styled(ClearFilterButton)`
  ${props => props.theme.media.mobile`
    display: none;
  `}

  ${props => props.theme.media.desktop`
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
  `}

  ${props => props.theme.media.tablet`
    flex-grow: 0;
  `}
`

export const ClearFilterButtonMobile = styled(ClearFilterButton)``

export const EmptyListContainer = styled.div`
  width: 100%;
`

export const EmptyListHeading = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${(props: StyledProps<{}>) => props.theme.color.onPrimaryDark};
`

export const EmptyListText = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${(props: StyledProps<{}>) => props.theme.color.onSurfaceMidEmphasys};
  margin-top: 8px;
`

export const SidebarBlock = styled.div`
  grid-area: filter;
`

export const ReOrderFormContainer = styled.div`
  margin-top: 16px;

  ${props => props.theme.media.mobile`
      margin-top: 0;
      margin-bottom: 24px;
  `}

  ${props => props.theme.media.tablet`
      margin-bottom: 24px;
  `}
`

export const ItemImageWrapper = styled.div`
  height: 108px;
  width: 104px;
  margin: 16px 0;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
`

export const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 / 3;

  ${props => props.theme.media.mobile`
    grid-column: 1;
  `}
`

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px 12px;

  > * {
    margin-left: 5px;
  }
`

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: stretch;
  flex-grow: 1;
  padding: 100px 0;
`

import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  Button,
  IconLeaf,
  IconPickup,
  IconQuestion,
  IconStore,
  IconTruck,
  IconUser,
  Modal,
} from '@agro-club/frontend-shared'
import { HowItWorksItem } from 'types/entities'
import useLangPicker from 'hooks/useLangPicker'

type SVGIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>

const IconStyled = css`
  fill: ${props => props.theme.color.secondary300};
  grid-area: icon;
  margin-top: 2px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const HeadingInner = styled.span`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`

const Title = styled.header`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
  text-align: center;
  margin-bottom: 16px;
`

const Content = styled.div`
  max-width: ${(props: { compact?: boolean }) => (props.compact ? 'auto' : '300px')};
`

const StepStyled = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  grid-template-areas:
    'icon title'
    '. descr';
  grid-gap: 0 16px;

  &:not(:first-child) {
    margin-top: 18px;
  }
  svg {
    ${IconStyled}
  }
`
const StepTitleStyled = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
  grid-area: title;
`
const StepDescrStyled = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  grid-area: descr;
`

const ButtonStyled = styled(Button)`
  min-width: 208px;
  margin-top: 40px;
  align-self: center;
`

const HowItWorksButtonStyled = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.color.primary600};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  :focus {
    outline: none;
  }
`

const QuestionIcon = styled(IconQuestion)`
  fill: ${props => props.theme.color.primary600};
  margin-right: 10px;
`

const Step: React.FC<{ Icon?: SVGIcon; title: string; descr?: string; emoji?: string }> = ({
  Icon,
  title,
  descr,
  emoji,
}) => {
  return (
    <StepStyled>
      {!!Icon && <Icon />}
      {!!emoji && <span>{emoji}</span>}
      <StepTitleStyled>{title}</StepTitleStyled>
      {descr && <StepDescrStyled>{descr}</StepDescrStyled>}
    </StepStyled>
  )
}

export const WelcomeHowItWorksContent: React.FC<{ compact?: boolean }> = ({ compact }) => {
  const { t } = useTranslation('common')
  return (
    <Content compact={compact}>
      <Step Icon={IconPickup} title={t('howItWorks.step1.title')} descr={t('howItWorks.step1.descr')} />
      <Step Icon={IconUser} title={t('howItWorks.step2.title')} descr={t('howItWorks.step2.descr')} />
      <Step Icon={IconStore} title={t('howItWorks.step3.title')} descr={t('howItWorks.step3.descr')} />
      <Step Icon={IconTruck} title={t('howItWorks.step4.title')} descr={t('howItWorks.step4.descr')} />
    </Content>
  )
}

export const HowItWorksContent: React.FC<{ compact?: boolean; items: HowItWorksItem[] }> = ({ compact, items }) => {
  const { pick } = useLangPicker()

  return (
    <Content compact={compact}>
      {items.map((item, idx) => (
        <Step key={idx} emoji={item.emoji} title={pick(item.title_i18n)} descr={pick(item.subtitle_i18n)} />
      ))}
    </Content>
  )
}

export const WelcomeHeading = () => {
  const { t } = useTranslation('common')
  return (
    <HeadingInner>
      <IconLeaf />
      {t('howItWorks.welcome')}
    </HeadingInner>
  )
}

export const HowItWorks: React.FC<{
  onProceed?: () => void
  welcome?: boolean
  items: HowItWorksItem[]
}> = ({ onProceed, welcome, items }) => {
  const { t } = useTranslation('common')

  return (
    <Wrapper data-test-id={'welcome-popup'}>
      {welcome && <Title>{t('howItWorks.title')}</Title>}
      {items.length ? <HowItWorksContent items={items} /> : <WelcomeHowItWorksContent />}
      {welcome && (
        <ButtonStyled intent={'primary'} filled onClick={onProceed}>
          {t('howItWorks.proceed')}
        </ButtonStyled>
      )}
    </Wrapper>
  )
}

type PropsType = {
  className?: string
  items: HowItWorksItem[]
  onlyIcon?: boolean
  onClick: () => void
}

export const HowItWorksButton: React.FC<PropsType> = ({
  className,
  items = [],
  onlyIcon = false,
  onClick,
}: PropsType) => {
  const { t } = useTranslation('common')
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <HowItWorksButtonStyled
        data-test-id="how-it-works-button"
        className={className}
        onClick={() => {
          onClick()
          setIsOpen(true)
        }}
      >
        <QuestionIcon />
        {!onlyIcon && t('howItWorks.titleQuestion')}
      </HowItWorksButtonStyled>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} heading={t('howItWorks.title')}>
        <HowItWorks items={items} />
      </Modal>
    </>
  )
}

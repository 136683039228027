import { controlStyle, CustomSelectStyles, SimpleSelect, SimpleSelectProps } from '@agro-club/frontend-shared'
import React from 'react'
import styled from 'styled-components'

export type SelectProps = SimpleSelectProps

export const SelectLabel = styled.h3`
  margin: 0 0 8px 0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;

  color: black;
`

export const customStyles: CustomSelectStyles = {
  control: (...args) => ({
    ...controlStyle(...args),
    minWidth: '120px',
    minHeight: '40px',
    borderRadius: '8px',
  }),
  placeholder: base => ({
    ...base,
    position: 'absolute',
    maxWidth: 'calc(100% - 8px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  input: base => ({
    ...base,
    paddingTop: '0',
    margin: '0',
  }),
}

export const Select = ({ label, ...props }: SelectProps) => {
  return (
    <>
      <SelectLabel>{label}</SelectLabel>
      <SimpleSelect customStyles={customStyles} {...props} />
    </>
  )
}

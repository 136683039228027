import { endpoints } from 'modules/endpoints'
import { Card, CardListRequestSorting, CardListRequestFilter, CardBestOffer } from './types'
import { ListResponse } from 'types/api'
import { ApiService } from 'service/api/interface'

export const getList = (apiClient: ApiService) => (
  sellerSlug: string,
  subCategorySlug: string,
  filter: CardListRequestFilter,
  sorting: CardListRequestSorting,
  page: number,
  pageSize: number,
  isExclusive?: boolean,
) => {
  return apiClient.get<ListResponse<Card>>(endpoints.card(sellerSlug), {
    ...sorting,
    subcategory: subCategorySlug,
    seller_id: filter.sellerId,
    skus: filter.skus,
    status: filter.status,
    filter_attributes:
      filter.attributes && Object.keys(filter.attributes).length ? JSON.stringify(filter.attributes) : undefined,
    page: page,
    page_size: pageSize,
    is_exclusive: isExclusive || filter.is_exclusive,
  })
}

export const getItem = (apiClient: ApiService) => (sellerSlug: string, id: string) => {
  return apiClient.get<Card>(endpoints.cardById(id, sellerSlug))
}

export const getExclusiveItem = (apiClient: ApiService) => (id: string) => {
  return apiClient.get<Card>(endpoints.exclusiveCard(id))
}

export const getBestOffer = (apiClient: ApiService) => (sellerSlug: string, cardSlug: string) => {
  return apiClient.get<CardBestOffer>(endpoints.cardBestOffer({ sellerSlug, cardSlug }))
}

import styled from 'styled-components'
import { Typography } from '@agro-club/frontend-shared'

export const Text = styled.div<{ collapsed: boolean; textStyles: string }>`
  ${Typography.Text}
  ${props => props.textStyles + ';'}
  ${props =>
    props.collapsed &&
    `overflow: hidden;
	position: relative;
	height: 60px;

	&:after {
		content: "";
		text-align: right;
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		height: 2em;
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%);
		pointer-events: none;
	}`}
`

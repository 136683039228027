enum ProfileRoutes {
  Profile = '/:country/profile',
  AccountInfo = '/:country/profile/account',
  DeliveryPoint = '/:country/profile/delivery',
  Orders = '/:country/profile/orders',
  OrdersActive = '/:country/profile/orders/active',
  OrdersCompleted = '/:country/profile/orders/completed',
  OrderDetails = '/:country/profile/orders/:orderId',
  Documents = '/:country/profile/documents',
  DocumentDetails = '/:country/profile/documents/:documentId',
}

export default ProfileRoutes

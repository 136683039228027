import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import {
  Document,
  DocumentDTO,
  DocumentListRequestFilter,
  DocumentListRequestSorting,
  SignCallbackEvent,
  SignUrlDTO,
} from './types'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { DocumentState } from './types'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { ErrorDetail } from 'service/api/errors'

const initialState: DocumentState = {
  items: {},
  meta: {},
  files: {},
  signedFiles: {},
  ids: [],
  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,

  signUrl: null,
  signStage: 'idle',
  signCallbackEvent: null,

  singSuccessCallbackProgress: Progress.IDLE,
}

class DocumentReducer extends ImmerReducer<DocumentState> {
  listRequested(params: { filter?: DocumentListRequestFilter; sorting?: DocumentListRequestSorting; page?: number }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }
  listRequestSucceed(list: Document[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }
  listRequestFailed() {
    this.draftState.listFetchProgress = Progress.ERROR
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }
  itemRequestSucceed(item: Document) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
  }
  itemRequestFailed(id: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
  }

  itemFileUrlRequested(id: string) {
    this.draftState.files[id] = {
      url: null,
      progress: Progress.WORK,
    }
  }

  itemFileUrlRequestSucceed(id: string, url: string) {
    this.draftState.files[id] = {
      url,
      progress: Progress.SUCCESS,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemFileUrlRequestFailed(id: string, error: ErrorDetail) {
    this.draftState.files[id] = {
      url: null,
      progress: Progress.ERROR,
    }
  }

  itemSignedFileUrlRequested(id: string) {
    this.draftState.signedFiles[id] = {
      url: null,
      progress: Progress.WORK,
    }
  }

  itemSignedFileUrlRequestSucceed(id: string, url: string) {
    this.draftState.signedFiles[id] = {
      url,
      progress: Progress.SUCCESS,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemSignedFileUrlRequestFailed(id: string, error: ErrorDetail) {
    this.draftState.signedFiles[id] = {
      url: null,
      progress: Progress.ERROR,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addRequested(dto: DocumentDTO) {
    this.draftState.addProgress = Progress.WORK
  }
  addSucceed(item: Document) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
    this.draftState.meta[item.id] = {
      id: item.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
  }
  addFailed() {
    this.draftState.addProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateRequested(id: string, item: Partial<DocumentDTO>) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
  }
  updateSucceed(item: Document) {
    this.draftState.items[item.id] = item
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateError = null
  }
  updateFailed(id: string) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateProgress = Progress.ERROR
  }

  filterUpdated(filter: DocumentListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingUpdated(sorting: DocumentListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
  }

  listRequestNextSucceed(list: Document[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...this.draftState.ids, ...getIds(list)]
  }

  listRequestNextFailed() {
    this.draftState.listFetchNextProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
  }
  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex(item => item === id)
    if (-1 !== i) {
      const ids = this.draftState.ids
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }

  removeFailed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    this.draftState.meta[id].removeProgress = Progress.ERROR
  }

  signUrlRequested(_data: SignUrlDTO) {
    this.draftState.signStage = 'urlFetch'
  }

  signUrlRequestedFailed() {
    this.draftState.signStage = 'urlError'
  }

  signUrlUserValidationFailed() {
    this.draftState.signStage = 'userProfile'
  }

  signUrlRequestCompleted(url: string) {
    this.draftState.signUrl = url
    this.draftState.signStage = 'sign'
  }

  signCallbackEventReceived(documentId: string, callbackEvent: SignCallbackEvent) {
    this.draftState.signCallbackEvent = callbackEvent
    this.draftState.signStage = 'signComplete'
    if (callbackEvent === 'signing_complete') {
      this.draftState.singSuccessCallbackProgress = Progress.WORK
    }
  }

  signSuccessCallbackSuccessfullyHandled() {
    this.draftState.singSuccessCallbackProgress = Progress.SUCCESS
  }

  signSuccessCallbackFailedToHandle() {
    this.draftState.singSuccessCallbackProgress = Progress.ERROR
  }

  signProcessReset() {
    this.draftState.signUrl = null
    this.draftState.signStage = 'idle'
    this.draftState.signCallbackEvent = null
  }
}

export const DocumentActions = createActionCreators(DocumentReducer)
export default DocumentActions
export const reducer = createReducerFunction(DocumentReducer, initialState)

import React, { useContext } from 'react'

export const UAContext = React.createContext<{ device: 'mobile' | 'tablet' | 'desktop'; os: string | null }>({
  device: 'desktop',
  os: null,
})

const useUserAgentDevice = () => {
  const { device, os } = useContext(UAContext)
  return { device, os }
}

export default useUserAgentDevice

import styled from 'styled-components'
import IconButton from 'views/ui/IconButton/IconButton'

export const ReOrderContainer = styled.div`
  align-self: start;
  margin-top: 16px;
  ${props => props.theme.media.mobile`
    margin: 0 16px;
  `}
  padding: 16px 24px 24px;
  border-radius: 16px;
  background: #ffffff;
  color: ${props => props.theme.color.onPrimaryLight};
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.04);
`

export const ReOrderTitle = styled.div`
  line-height: 24px;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
  font-weight: 600;
  font-size: 18px;
`

export const ReOrderDescription = styled.div`
  margin-top: 8px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
  font-weight: 500;
  font-size: 14px;
`

export const SubmitCodeButton = styled(IconButton)`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 32px;
  min-width: 32px;
  height: 32px;
  padding: 0;
  color: ${({ theme }) => theme.color.primary50};
`

export const InputWrapper = styled.div`
  position: relative;
  margin-top: 16px;
`

export const ErrorText = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.color.accentDestructive};
`

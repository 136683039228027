import { WhiteLabelConfig } from 'service/whiteLabelConfig/interface'

export const DefaultWhiteLabelConfig: WhiteLabelConfig = {
  id: '',
  title: 'Default',
  slug: 'default',
  company_ids: [''],
  domains: [],
  default_country_code: 'CA',
  default_lang: 'en',
  available_countries: ['CA', 'RU'],
  phone_codes: [
    {
      id: 'CA',
      name: 'Canada',
      phone_prefix: '1',
    },
    {
      id: 'RU',
      name: 'Russia',
      phone_prefix: '7',
    },
  ],
}

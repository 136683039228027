import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import CartSelectors from 'modules/domain/cart/selectors'
import { AnalyticsEvent, Progress } from 'modules/types'
import { Routes } from 'views/pages/routes'
import { useCountryPath } from 'hooks/useCountryPath'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { Procote, ProcoteWizardNode, useAction } from '@agro-club/frontend-shared'
import CartActions from 'modules/domain/cart/duck'
import { useProducer } from 'modules/domain/producer/hooks'
import { SubmitOptionsType } from '@agro-club/frontend-shared'
import { UpdateCartItemParams } from 'modules/domain/cart/types'
import { useWizardProductList } from 'modules/domain/product/hooks'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { Page } from 'modules/utils/analytics-utils/pageNames'
import WizardEvents from '../events'
import ProductSelectors from 'modules/domain/product/selectors'
import { productToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'

type PropsType = {
  opened: boolean
  onClose: () => void
  isMobile: boolean
  productWizard: ProcoteWizardNode | null
  categoryId: string
}

export const ProductWizardProcote: FC<PropsType> = ({
  productWizard,
  opened,
  onClose,
  isMobile,
  categoryId,
}: PropsType) => {
  const [productId, setProductId] = useState('')
  const progress = useSelector(CartSelectors.productWizardProgress)
  const generateCountryPath = useCountryPath()
  const history = useHistory()
  const { producerSlug, productOrCardSlug: commonProductSlug } = useParams<{
    producerSlug: string
    productOrCardSlug: string
  }>()
  const addAction = useAction(CartActions.itemAddRequested)
  const [, producer] = useProducer(producerSlug)
  const updateItemAction = useAction(CartActions.itemUpdateRequested)
  const cartItem = useSelector(state => CartSelectors.cartEntryByParams(state, productId))
  const { track } = useAnalyticsSSR()
  const commonProduct = useSelector(state => ProductSelectors.item(state, commonProductSlug))

  const [, products = []] = useWizardProductList({
    filter: {
      category: categoryId,
    },
    isExclusive: true,
  })

  const handleGoToCart = useCallback(() => {
    history.push(generateCountryPath(Routes.ProducerCart, { producerSlug }))
  }, [generateCountryPath, history, producerSlug])

  const handleAddToCart = useCallback(
    (productId: string, options?: SubmitOptionsType) => {
      producer && addAction(productId, producer, options)
    },
    [addAction, producer],
  )

  const handleUpdateCart = useCallback(
    (params: UpdateCartItemParams) => {
      cartItem?.id && updateItemAction(cartItem.id, params)
    },
    [cartItem?.id, updateItemAction],
  )

  const handleSendAnalyticsTrack = additionalParams => {
    track(WizardEvents.WizardClick, {
      ...productToEventParams(commonProduct),
      ...additionalParams,
    })
  }

  const handleSendAnalyticsPage = additionalParams => {
    track(AnalyticsEvent.Page, {
      name: Page.ProcoteWizardNode,
      ...productToEventParams(commonProduct),
      ...additionalParams,
    })
  }

  const wizardJSX =
    (progress === Progress.SUCCESS || opened) && productWizard ? (
      <Procote
        allowChooseExactProduct
        productWizard={productWizard}
        opened={opened}
        isMobile={isMobile}
        onClose={onClose}
        handleGoToCart={handleGoToCart}
        handleMarketAddProduct={handleAddToCart}
        handleMarketUpdateProduct={handleUpdateCart}
        handleProductId={setProductId}
        products={products}
        onSendAnalyticsTrack={handleSendAnalyticsTrack}
        onSendAnalyticsPage={handleSendAnalyticsPage}
      />
    ) : null

  return <>{wizardJSX}</>
}

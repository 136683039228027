import styled, { StyledProps } from 'styled-components'
import React from 'react'
import { IconCrossCircle, Tooltip } from '@agro-club/frontend-shared'

type ThemedProps = StyledProps<{}>

export const Image = styled.div`
  height: 60px;
  width: 60px;
  background: no-repeat center center / contain;
  background-image: url(${(props: { url: string }) => props.url});
  margin-right: 16px;
  flex-shrink: 0;
`
export const ProductCell = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 300px;
`
export const Text = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

export const StandardUnitsText = styled(Text)`
  padding-left: 16px;
`

export const BoldText = styled.span`
  font-weight: 600;
  font-size: 16px;

  ${props => props.theme.media.mobile`
    font-size: 14px;
  `}
`
export const SmallText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

export const LegalNotes = styled(Tooltip)`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${props => props.theme.color.onPrimaryDark};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  & > svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    fill: ${props => props.theme.color.accentDestructive};
  }
`

export const LegalNotesTooltipContent = styled.div`
  width: 200px;
`

export const AltPackaging = styled(SmallText)`
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
  margin-top: 6px;
`

export const NoWrapText = styled.span`
  white-space: nowrap;
`
export const ProductCellText = styled.span`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 4px;
`
export const RemoveIconStyled = styled(IconCrossCircle)`
  transition: 0.3s fill;
`
export const RemoveWrapper = styled.div`
  padding: 10px;
  cursor: pointer;

  &:hover ${RemoveIconStyled} {
    fill: ${(props: ThemedProps) => props.theme.color.primary600};
  }

  ${props => props.theme.media.mobile`
    align-self: start;
  `}
`
export const Remove: React.FC<{ onClick: () => void }> = ({ onClick, ...props }) => {
  return (
    <RemoveWrapper onClick={onClick} {...props}>
      <RemoveIconStyled />
    </RemoveWrapper>
  )
}

export const QtyCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MobileCartItem = styled.div`
  border-bottom: 1px solid ${(props: ThemedProps) => props.theme.color.onSurfaceLowEmphasys};
  padding-bottom: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`
export const MobileCartList = styled.div`
  padding: 8px 0 16px;
`
export const MobileBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  padding-right: 8px;

  &:first-child {
    margin-top: 0;
    padding-right: 0;
  }
`
export const MobileLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
`

export const SeedTreatment = styled.div`
  margin-top: 4px;
  color: ${(props: ThemedProps) => props.theme.color.onSurfaceHighEmphasys};
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
`

export const Cost = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`

export const WizardNoteText = styled.span`
  max-height: 40px;
  max-width: 640px;
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
  text-transform: uppercase;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${props => props.theme.media.tablet`
    max-width: 320px;
    font-size: 8px;
  `}

  ${props => props.theme.media.mobile`
    max-width: 320px;
    font-size: 8px;
  `}
`

export const ProductOption = styled.div`
  line-height: 20px;
  font-size: 14px;
`

export const PackSize = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: ${({ theme }) => theme.color.onSurfaceHighEmphasys};

  ${props => props.theme.media.tablet`
    margin-top: 8px;
  `}
`

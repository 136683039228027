import React from 'react'
import useMatchMedia from 'hooks/useMatchMedia'
import { queries } from 'theme/theme'
import AdderWidgetDesktop from './AdderWidgetDesktop'
import AdderWidgetMobile from './AdderWidgetMobile'
import { CartItem } from 'modules/domain/cart/types'
import { ProductItemDto } from '../ProductItem'
import { useProductOptionsList } from 'modules/domain/productOptions/hooks'
import { ProductWizardManager } from 'views/components/ProductWizards/ProductWizardManager'
import OutOfStockWidget from '../OutOfStockWidget'
import { useParams } from 'react-router-dom'
import useCompanyFeatureFlags from 'hooks/useCompanyFeatureFlags'
import { generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import { FeatureFlagModifiers, FieldLocation, FieldNames } from 'types/entities/config'

export type AdderValues = {
  qty: number
  seedTreatment?: string
  altPackagingQty: number | null
  options?: string[]
}

export type AdderWidgetProps = {
  product: ProductItemDto
  cartItem?: CartItem
  values: AdderValues
  onSeedTreatmentChange: (seedTreatment?: string) => void
  onQtyChange: (qty: number) => void
  onOptionsChange: (options?: string[]) => void
}

const AdderWidgetManager: React.FC<AdderWidgetProps> = ({ product, values, ...props }) => {
  const isMobile = useMatchMedia(queries.mobile)
  const [, productOptions] = useProductOptionsList()

  const { producerSlug } = useParams<{ producerSlug: string }>()
  const hasAccess = useCompanyFeatureFlags(producerSlug)
  const isQtyDisabled = hasAccess(
    generateFieldModifierString(FieldLocation.CartList, FieldNames.Qty, FeatureFlagModifiers.Disabled),
  )

  if (product.is_gift) {
    return null
  }

  if (product.wizard_id) {
    return <ProductWizardManager product={product} isMobile={isMobile} />
  }

  if (product.is_out_of_stock) {
    return <OutOfStockWidget product={product} />
  }

  if (isMobile) {
    return (
      <AdderWidgetMobile
        {...values}
        {...props}
        product={product}
        productOptions={productOptions || []}
        isQtyDisabled={isQtyDisabled}
      />
    )
  } else {
    return (
      <AdderWidgetDesktop
        {...values}
        {...props}
        product={product}
        productOptions={productOptions || []}
        isQtyDisabled={isQtyDisabled}
      />
    )
  }
}

export default AdderWidgetManager

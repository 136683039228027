import useLangPicker from 'hooks/useLangPicker'
import React, { memo, useMemo } from 'react'
import styled from 'styled-components'
import { LocalizedValue } from 'types/entities'

const SpecsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-self: start;
  font-size: 16px;
  line-height: 24px;
  width: 100%;

  ${props => props.theme.media.mobile`
    margin-top: 24px;
    font-size: 14px;
    line-height: 20px;
    justify-self: auto;
  `}
`
const SpecsItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  &::after {
    content: '';
    display: block;
    flex-grow: 1;
    order: 2;
    color: ${props => props.theme.color.onSurfaceLowEmphasys};
    background-image: radial-gradient(circle, currentcolor 1px, transparent 1px);
    background-position-y: 15px;
    background-size: 4px 2px;
    background-repeat: repeat-x;
    margin: 0 8px;
  }
  &:not(:first-child) {
    margin-top: 6px;
  }
`
const SpecsKey = styled.div`
  flex-grow: 0;
  font-size: 16px;
  line-height: 24px;
  order: 1;
  font-weight: 600;
`
const SpecsValue = styled.div`
  flex-grow: 0;
  order: 3;
  max-width: 50%;
  text-align: right;
`
export const Specs: React.FC<{ items: { title: LocalizedValue; value: LocalizedValue }[] }> = memo(({ items }) => {
  const { pick } = useLangPicker()
  const attrs = useMemo(() => {
    const buf: Record<string, string[]> = {}
    items.forEach(attr => {
      const title = pick(attr.title)
      const value = pick(attr.value)
      if (!buf[title]) {
        buf[title] = []
      }
      buf[title].push(value)
    })

    const result = Object.entries(buf).map(([title, value]) => {
      return {
        title,
        value: value.filter(Boolean).join(';'),
      }
    })
    return result
  }, [items, pick])

  return (
    <SpecsContainer>
      {attrs.map((item, idx) => (
        <SpecsItemContainer key={idx} data-test-id="card-attribute">
          <SpecsKey>{item.title}</SpecsKey>
          <SpecsValue dangerouslySetInnerHTML={{ __html: item.value }} />
        </SpecsItemContainer>
      ))}
    </SpecsContainer>
  )
})
Specs.displayName = 'Specs'

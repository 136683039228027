import { call, getContext, race, select, take } from 'redux-saga/effects'
import { ApiService } from 'service/api/interface'
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import { matchPath } from 'react-router'
import { AnalyticsInstance } from 'analytics'
import env from 'env'
import qs from 'query-string'
import CartActions from 'modules/domain/cart/duck'
import CartSelectors from 'modules/domain/cart/selectors'
import { Progress } from 'modules/types'
import AuthSelectors from 'modules/domain/auth/selectors'
import AuthActions from 'modules/domain/auth/duck'

type ArgumentsType<T> = T extends (api: ApiService) => (...args: infer V) => Promise<unknown> ? V : never
export function* apiCall<T extends (api: ApiService) => (...args: any[]) => Promise<unknown>>(
  manager: T,
  ...args: ArgumentsType<T>
) {
  const apiService = yield getContext('apiService')
  return yield call(manager(apiService), ...args)
}

export function* getTokenService() {
  return yield getContext('tokenService')
}

export function* getAnalyticsInstance(): Generator<AnalyticsInstance> {
  return yield (getContext('analyticsInstance') as unknown) as AnalyticsInstance
}

export function makeRouteWatcher<
  // routes
  R extends { [route: string]: string }
>(
  routesMap: R,
  callbackGen: (result: {
    params: { [key: string]: string }
    query: { [key: string]: string | string[] | null }
    isFirstRender: boolean
    silent: boolean
    match: R[keyof R]
  }) => any,
) {
  const routes = Object.values(routesMap)
    .map(r => r.split('/'))
    .sort((a, z) => z.length - a.length)
    .map(r => r.join('/'))

  return function* routeWatcherGen() {
    while (1) {
      const action: LocationChangeAction = yield take(LOCATION_CHANGE)
      const match = matchPath(action.payload.location.pathname, routes)
      if (!match || !match.isExact) {
        continue
      }

      const initProgress: Progress = yield select(AuthSelectors.initProgress)
      if ([Progress.IDLE, Progress.WORK].includes(initProgress)) {
        yield race({
          success: take(AuthActions.initRequestSucceed.type),
          error: take(AuthActions.initRequestFailed.type),
        })
      }

      const cartInitProgress: Progress = yield select(CartSelectors.initProgress)
      if (!env.BROWSER && [Progress.IDLE, Progress.WORK].includes(cartInitProgress)) {
        yield race({
          success: take(CartActions.cartInitRequestSucceed.type),
          error: take(CartActions.cartInitRequestFailed.type),
        })
      }

      if (action.payload.isFirstRendering && env.BROWSER) {
        continue
      }

      if (!action.payload.isFirstRendering && !env.BROWSER) {
        continue
      }

      yield call(callbackGen, {
        params: match.params,
        query: qs.parse(action.payload.location.search),
        match: match.path as R[keyof R],
        isFirstRender: action.payload.isFirstRendering,

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO add state type globally
        silent: action.payload.location.state?.silent || false,
      })
    }
  }
}

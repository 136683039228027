import { AppGlobalState } from '../../types'
import { createSelector } from 'reselect'
import { CartSkuItem } from 'modules/domain/cartSku/types'
import { isPromocodeWithComment, isPromocodeWithLegalText, Promocode } from 'types/entities/promocode'
import env from 'env'
import { QtySuggestion } from '../cart/types'
import { ProcoteWizardNode, ProductWizardType } from '@agro-club/frontend-shared'

export const getCartInitProgress = (state: AppGlobalState) => state.cartSku.initProgress
export const refreshProgress = (state: AppGlobalState) => state.cartSku.refreshProgress
export const getCartEntries = (state: AppGlobalState) => state.cartSku.entries
export const getCartPendingEntries = (state: AppGlobalState) => state.cartSku.pendingEntries

export const getCartPendingEntriesBySellerSlug = createSelector(
  getCartPendingEntries,
  (_: AppGlobalState, producerSlug: string) => producerSlug,
  (pendingEntries, producerSlug) => {
    return Object.values(pendingEntries).filter(entry => entry.producer_slug === producerSlug)
  },
)

export const getCartEntryByCardSlug = createSelector(
  getCartEntries,
  (_: AppGlobalState, slug: string) => slug,
  (entries, slug) => {
    return Object.values(entries).find(entry => entry.card?.slug === slug)
  },
)

export const getCartEntryBySkuId = createSelector(
  getCartEntries,
  (_: AppGlobalState, skuId: string) => skuId,
  (entries, skuId): CartSkuItem | undefined => {
    return Object.values(entries).find(entry => entry.sku_id === skuId)
  },
)

export const getCartEntry = (state: AppGlobalState, id: string) => state.cartSku.entries[id]
export const getCartEntryIndexInSellerObject = (state: AppGlobalState, id: string, slug: string) =>
  state.cartSku.cartInfo[slug].entries?.findIndex(entry => entry.id === id) ?? -1

export const getAddProgress = (state: AppGlobalState) => state.cartSku.addProgress
const addErrorDetails = (state: AppGlobalState) => state.cartSku.addErrorDetails
export const getRemoveProgress = (state: AppGlobalState) => state.cartSku.removeProgress
const removeErrorDetails = (state: AppGlobalState) => state.cartSku.removeErrorDetails
export const getAddItemsProgress = (state: AppGlobalState) => state.cartSku.addItemsProgress
const addItemsErrorDetails = (state: AppGlobalState) => state.cartSku.addItemsErrorDetails
export const getUpdateProgress = (state: AppGlobalState) => state.cartSku.updateProgress
const updateErrorDetails = (state: AppGlobalState) => state.cartSku.updateErrorDetails
export const getTermsAgreed = (state: AppGlobalState) => state.cartSku.termsAgreed

const getDiscountInitProgress = (state: AppGlobalState) => state.cartSku.discountsInitProgress
const discountsInitErrorDetails = (state: AppGlobalState) => state.cartSku.discountsInitErrorDetails
const getInitRetryAttempts = (state: AppGlobalState) =>
  env.BROWSER ? state.cartSku.initRetryAttempts : state.cartSku.initRetryAttempts - 1
const getCreditOfferAccepted = (state: AppGlobalState, producerId: string) =>
  state.cartSku.cartInfo[producerId]?.creditOfferAccepted

const getPromocodes = (state: AppGlobalState, producerSlug: string) => {
  if (!state.cartSku.cartInfo[producerSlug]) {
    return []
  }
  return state.cartSku.cartInfo[producerSlug].promocodes
}
const getAllPromocodes = (state: AppGlobalState) =>
  Object.keys(state.cartSku.cartInfo).reduce(
    (acc: Promocode[], key: string) => [...acc, ...state.cartSku.cartInfo[key].promocodes],
    [],
  )
const getLastPromocodeStatus = (state: AppGlobalState) => state.cartSku.lastPromocodeStatus
const getCheckPromocodeProgress = (state: AppGlobalState) => state.cartSku.checkPromocodeProgress
const checkPromocodeErrorDetails = (state: AppGlobalState) => state.cartSku.checkPromocodeErrorDetails
const getSelectedPromocode = (state: AppGlobalState) => state.cartSku.selectedPromocode

const cartEntriesSelector = createSelector(getCartEntries, map => map)
const cartItemsSelector = createSelector(cartEntriesSelector, (entries): CartSkuItem[] => Object.values(entries))
const hasCategory = (state: AppGlobalState, subcategoryId: string) =>
  Object.values(state.cartSku.entries).some(entry => entry.card?.subcategory_id === subcategoryId)
const cartInfo = (state: AppGlobalState) => state.cartSku.cartInfo
const cartInfoBySellerId = createSelector(
  cartInfo,
  (_, id: string) => id,
  (cartInfo, id) => {
    return Object.values(cartInfo).find(info => info.seller?.id === id)
  },
)
const sellerCartInfo = createSelector(
  cartInfo,
  (_, sellerSlug: string | undefined) => sellerSlug,
  (cartInfo, sellerSlug) => (sellerSlug ? cartInfo[sellerSlug] : undefined),
)

const getDiscounts = createSelector(sellerCartInfo, sellerCartInfo => {
  if (!sellerCartInfo) return undefined
  return sellerCartInfo.discounts
})

const cartItemsBySellerId = (state: AppGlobalState, sellerId: string) =>
  Object.values(state.cartSku.entries).filter(e => e.seller_id === sellerId)

const cartItemsBySellerSlug = (sellerSlug: string) =>
  createSelector(
    (state: AppGlobalState) => sellerCartInfo(state, sellerSlug),
    cartInfo => {
      if (!cartInfo) return []
      return cartInfo.entries?.length ? cartInfo.entries : []
    },
  )

const hasTotalInfo = createSelector(
  sellerCartInfo,
  cartInfo => cartInfo && !!(cartInfo.total || cartInfo.total_savings),
)

const addQtySuggestions = createSelector(
  getDiscounts,
  getCartEntries,
  (discounts, cartEntries): QtySuggestion => {
    return Object.values(cartEntries).reduce((acc: QtySuggestion, entry: CartSkuItem) => {
      const calculatedDiscount = discounts?.find(d => d.program.sku_ids.includes(entry.sku_id || ''))

      return { ...acc, [entry.id]: calculatedDiscount ? calculatedDiscount.tiers.map(t => t.qty_to_apply).sort() : [] }
    }, {})
  },
)

const nextDiscountLvlSuggestions = createSelector(getDiscounts, cartItemsSelector, (discounts, cartItems) => {
  return cartItems.reduce((suggestions, item) => {
    const nextLvlQty = discounts
      ?.filter(d => d.next_lvl_qty > 0)
      .reduce(
        (minNextLvlQty, discount) =>
          discount.program.sku_ids.includes(item.sku_id || '') && discount.next_lvl_qty < minNextLvlQty
            ? discount.next_lvl_qty
            : minNextLvlQty,
        Infinity,
      )
    return { ...suggestions, [item.id]: nextLvlQty && isFinite(nextLvlQty) ? nextLvlQty : 1 }
  }, {})
})

const getDiscountsBySku = createSelector(
  getDiscounts,
  (_, __, skuId: string) => skuId,
  (discounts, skuId) => discounts?.filter(d => d.program.sku_ids.includes(skuId)),
)

const getDiscountsByEntry = createSelector(
  getCartEntry,
  (state: AppGlobalState, _, cartEntry) => getDiscountsBySku(state, cartEntry.sku_id),
  (_, discount) => discount,
)

const hasDiscount = (producerSlug, entryId) =>
  createSelector(
    (state: AppGlobalState) => getDiscounts(state, producerSlug),
    (state: AppGlobalState) => getCartEntry(state, entryId),
    (discounts, entry) => discounts?.some(d => entry && d.program.sku_ids.includes(entry.sku_id || '')),
  )

const cartCountSelector = createSelector(
  getCartEntries,
  getCartPendingEntries,
  (entries, pendingEntries) => Object.keys(entries).length + Object.keys(pendingEntries).length,
)

const selectedPromocodeSelector = createSelector(getPromocodes, getSelectedPromocode, (codesList, selectedCode) => {
  const selected = codesList.find(item => item.code === selectedCode)
  if (selected) {
    return selected.code
  }
  return codesList.find(item => {
    if (isPromocodeWithComment(item)) {
      return !!item.params.prompt
    }
    if (isPromocodeWithLegalText(item)) {
      return !!item.params.legal_text
    }
  })?.code
})

const productWizard = (state: AppGlobalState): ProcoteWizardNode[] | null => {
  return state.cartSku.productWizardData
}

const productWizardDuckFoot = createSelector(productWizard, data => {
  if (data && data[0]?.type === ProductWizardType.DuckFoot) {
    return data[0]
  } else {
    return null
  }
})

const productWizardProgress = (state: AppGlobalState) => {
  return state.cartSku.productWizardProgress
}

const getFarmerComment = (state: AppGlobalState, sellerSlug: string) =>
  state.cartSku.cartInfo[sellerSlug]?.farmerComment

const CartSkuSelectors = {
  initProgress: getCartInitProgress,
  cartEntry: getCartEntry,
  cartEntries: cartEntriesSelector,
  cartEntryByCardSlug: getCartEntryByCardSlug,
  cartEntryBySkuId: getCartEntryBySkuId,
  cartPendingEntriesBySellerSlug: getCartPendingEntriesBySellerSlug,
  count: cartCountSelector,
  cartItems: cartItemsSelector,
  cartItemsBySellerId,
  cartItemsBySellerSlug,
  cartInfo,
  cartInfoBySellerId,
  hasTotalInfo,
  hasDiscount,
  discountInitProgress: getDiscountInitProgress,
  getDiscounts,
  sellerCartInfo,
  discounts: getDiscounts,
  getDiscountsBySku,
  getDiscountsByEntry,
  qtySuggestions: addQtySuggestions,
  nextDiscountLvlSuggestions,
  addProgress: getAddProgress,
  addItemsProgress: getAddItemsProgress,
  updateProgress: getUpdateProgress,
  refreshProgress,
  initRetryAttempts: getInitRetryAttempts,
  creditOfferAccepted: getCreditOfferAccepted,
  termsAgreed: getTermsAgreed,
  hasCategory,
  promocodes: getPromocodes,
  allPromocodes: getAllPromocodes,
  lastPromocodeStatus: getLastPromocodeStatus,
  checkPromocodeProgress: getCheckPromocodeProgress,
  selectedPromocode: selectedPromocodeSelector,
  productWizard,
  productWizardProgress,
  productWizardDuckFoot,
  cartEntryIndexInSellerObject: getCartEntryIndexInSellerObject,
  farmerComment: getFarmerComment,
  addErrorDetails,
  updateErrorDetails,
  removeErrorDetails,
  addItemsErrorDetails,
  checkPromocodeErrorDetails,
  discountsInitErrorDetails,
}

export default CartSkuSelectors

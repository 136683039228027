import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'

import * as Styled from './styled'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Input, FormikHook } from '@agro-club/frontend-shared'
import AuthSelectors from 'modules/domain/auth/selectors'
import { useSelector } from 'react-redux'
import { Progress } from 'modules/types'
import PhoneInput from 'views/components/PhoneInput/PhoneInput'
import { CountrySelect } from '../CountrySelect/CountrySelect'
import { CountryCode } from 'libphonenumber-js'
import useWLFeatureFlags from 'hooks/useWLFeatureFlags'
import { generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import { FeatureFlagModifiers, FieldLocation, FieldNames } from 'types/entities/config'
import { useDetectedCountry } from 'hooks/useDetectedCountry'

export type PersonalData = {
  first_name?: string
  last_name?: string
  farm_name?: string
  country?: CountryCode
  phone_number?: string
  email?: string
  cheque_payee_name?: string
  tax_id?: string
}

type Props = {
  initialValues: PersonalData
  editable?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useFormik: FormikHook
  onChangeAddressFormCountry: (country: string) => void
  fields?: {
    email?: {
      locked: boolean
    }
    phone_number?: {
      locked: boolean
      hidden: boolean
    }
  }
}

const defaultFields = {
  phone_number: { hidden: false, locked: false },
  email: { locked: false },
}

const InputsContainer = styled.div`
  width: 100%;
`

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}
const UserInfoForm: React.FC<Props> = ({
  initialValues,
  editable = true,
  useFormik,
  onChangeAddressFormCountry,
  fields = {},
}) => {
  const { t } = useTranslation(['common', 'profile', 'validation', 'auth'])
  const formFields = { ...defaultFields, ...fields }
  const signUpError = useSelector(AuthSelectors.getAuthError)
  const signUpProgress = useSelector(AuthSelectors.registerProgress)
  const defaultCountry = useDetectedCountry()
  const checkFeatureFlag = useWLFeatureFlags()
  const hasTaxIdAccess = !checkFeatureFlag(
    generateFieldModifierString(FieldLocation.AccountForm, FieldNames.TaxID, FeatureFlagModifiers.Hidden),
  )

  const isEmailNotRequired = checkFeatureFlag(
    generateFieldModifierString(FieldLocation.AccountForm, FieldNames.Email, FeatureFlagModifiers.NotRequired),
  )

  const validator = useMemo(() => {
    const required = () => Yup.string().required(t('validation:field_required'))
    const checkEmailRequired = () =>
      isEmailNotRequired ? Yup.string() : required().email(t('validation:invalidEmail'))
    return Yup.object({
      first_name: required(),
      last_name: required(),
      phone_number: formFields.phone_number.hidden ? Yup.string() : required(),
      email: checkEmailRequired(),
      cheque_payee_name: Yup.string(),
      tax_id: hasTaxIdAccess ? required() : Yup.string(),
    })
  }, [formFields.phone_number.hidden, hasTaxIdAccess, t, isEmailNotRequired])

  const formik = useFormik<PersonalData>({
    initialValues: {
      ...initialValues,
      country: initialValues.country || defaultCountry,
    },
    validationSchema: validator,
    onSubmit: noop,
    enableReinitialize: true,
    validateOnMount: true,
  })

  useEffect(() => {
    if (signUpError && signUpProgress === Progress.ERROR) {
      formik.setFieldError('email', t('auth:errors.emailTaken'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUpError, signUpProgress])

  const onChangeCountryValue = (country: string) => {
    formik.setFieldValue('country', country)
    onChangeAddressFormCountry(country)
  }

  return (
    <InputsContainer>
      <Styled.FormRow>
        <Input
          {...formik.getFieldProps('first_name')}
          label={t('common:firstName')}
          disabled={!editable}
          invalid={formik.touched.first_name && !!formik.errors.first_name}
          errorText={formik.errors.first_name}
        />
      </Styled.FormRow>
      <Styled.FormRow>
        <Input
          {...formik.getFieldProps('last_name')}
          label={t('common:lastName')}
          disabled={!editable}
          invalid={formik.touched.last_name && !!formik.errors.last_name}
          errorText={formik.errors.last_name}
        />
      </Styled.FormRow>
      <Styled.FormRow>
        <Input
          {...formik.getFieldProps('farm_name')}
          label={t('profile:accountInformation.farmName')}
          disabled={!editable}
          optional
        />
      </Styled.FormRow>
      {!checkFeatureFlag([
        generateFieldModifierString(FieldLocation.AccountForm, FieldNames.CheckPayeeName, FeatureFlagModifiers.Hidden),
      ]) && (
        <Styled.FormRow>
          <Input
            {...formik.getFieldProps('cheque_payee_name')}
            label={t('profile:accountInformation.chequePayeeName')}
            invalid={formik.touched.cheque_payee_name && !!formik.errors.cheque_payee_name}
            errorText={formik.errors.cheque_payee_name}
            disabled={!editable}
            optional
          />
        </Styled.FormRow>
      )}
      <Styled.FormRow data-test-id={'country-select'}>
        <CountrySelect
          onChange={onChangeCountryValue}
          value={formik.values.country}
          label={t('profile:accountInformation.country')}
          invalid={formik.touched.country && !!formik.errors.country}
          errorText={formik.errors.country}
          isDisabled={!editable}
        />
      </Styled.FormRow>
      {!formFields.phone_number.hidden && (
        <Styled.FormRow width={'250px'}>
          <PhoneInput
            name={'phone_number'}
            phoneNumber={formik.values.phone_number}
            onBlur={() => {
              formik.setFieldTouched('phone_number', true)
            }}
            onChange={(phone: string) => {
              formik.setFieldValue('phone_number', phone)
              formik.setFieldTouched('phone_number', true)
            }}
            label={t('common:phone')}
            disabled={true}
          />
        </Styled.FormRow>
      )}
      <Styled.FormRow>
        <Input
          {...formik.getFieldProps('email')}
          label={t('common:email')}
          type={'email'}
          disabled={!editable || formFields.email.locked}
          invalid={formik.touched.email && !!formik.errors.email}
          errorText={formik.errors.email}
          optional={isEmailNotRequired}
        />
      </Styled.FormRow>
      {hasTaxIdAccess && (
        <Styled.FormRow>
          <Input
            {...formik.getFieldProps('tax_id')}
            label={t('common:taxID')}
            disabled={!editable}
            invalid={formik.touched.tax_id && !!formik.errors.tax_id}
            errorText={formik.errors.tax_id}
            optional={!hasTaxIdAccess}
          />
        </Styled.FormRow>
      )}
    </InputsContainer>
  )
}

export default UserInfoForm

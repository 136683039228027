import { Progress, ResourceHook } from 'modules/types'
import { Category, DistributorFilter, FilterAttribute, Region } from './types'
import { useSelector } from 'react-redux'
import { useDidMount, useAction, CountryCodeEntry } from '@agro-club/frontend-shared'
import { useCallback, useEffect, useMemo, useState } from 'react'
import CollectionSelectors, { getCompanyCategory, getCompanySubCategory } from './selectors'
import CollectionActions from './duck'
import { Dict } from 'types/generics'
import { createSelector } from 'reselect'
import { arrToDict, dictToArr } from 'modules/utils/helpers'
import { Company } from 'types/entities'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import { CountryCode } from 'libphonenumber-js'
import { useParams } from 'react-router-dom'
import { useProducer } from '../producer/hooks'

export const useRegions: ResourceHook<Region[]> = (country: CountryCode) => {
  const progress = useSelector(CollectionSelectors.regionsFetchProgress)
  const regions = useSelector(CollectionSelectors.regions)
  const fetchAction = useAction(CollectionActions.regionsRequested)

  useDidMount(fetchAction.bind(null, country))

  return [progress, regions]
}

export const useRegionsSearch = (regions: Region[] | void) => {
  const [search, setSearch] = useState('')

  const filteredRegions = useMemo(
    () => (search && regions ? regions.filter(opt => !opt.parent_id || opt.title.includes(search)) : regions),
    [regions, search],
  )

  return { search, setSearch, filteredRegions }
}

export const useRegionSelection = (regionsOptions: Region[] | void, checkedRegions: Region[]) => {
  const checkedRegionsIds = useMemo(() => checkedRegions.map(item => item.id), [checkedRegions])
  const [checked, setChecked] = useState<string[]>([])

  useEffect(() => {
    setChecked(checkedRegionsIds.map(c => c))
  }, [checkedRegionsIds])

  const onChange = useCallback(
    (id: string) => {
      if (!regionsOptions) return

      const regionToCheck = regionsOptions.find(opt => opt.id === id)
      if (!regionToCheck) return

      if (!regionToCheck.parent_id && regionsOptions.find(opt => opt.parent_id === regionToCheck.id)) {
        const childrenIds = regionsOptions.filter(opt => opt.parent_id === regionToCheck.id).map(opt => opt.id)
        const checkedChildCount = childrenIds.reduce((acc, cid) => (checked.includes(cid) ? ++acc : acc), 0)
        const isAllChecked = checkedChildCount === childrenIds.length

        if (isAllChecked) {
          setChecked(checked.filter(cid => !childrenIds.includes(cid)))
        } else {
          setChecked(checked.concat(childrenIds))
        }
      } else {
        if (checked.includes(id)) {
          setChecked(checked.filter(cid => cid !== id))
        } else {
          setChecked([...checked, id])
        }
      }
    },
    [checked, regionsOptions],
  )

  const checkAll = useCallback(() => {
    if (!regionsOptions) return
    const allOptionsIds = regionsOptions.filter(opt => opt.parent_id).map(opt => opt.id)
    setChecked(allOptionsIds)
  }, [regionsOptions])

  const clear = useCallback(() => {
    if (!regionsOptions) return
    const allOptionsIds = regionsOptions.filter(opt => opt.parent_id).map(opt => opt.id)
    setChecked(checked.filter(cid => !allOptionsIds.includes(cid)))
  }, [regionsOptions, checked])

  const options = useMemo(
    () =>
      regionsOptions &&
      regionsOptions
        .filter(opt => !opt.parent_id)
        .map(option => {
          return {
            ...option,
            options: regionsOptions
              .filter(opt => option.id === opt.parent_id)
              .map(opt => ({
                ...opt,
                isChecked: checked.includes(opt.id),
              })),
          }
        }),
    [checked, regionsOptions],
  )

  return { options, onChange, clear, checkAll, checked }
}

export const useCategoryList: ResourceHook<Dict<Category>> = () => {
  const progress = useSelector(CollectionSelectors.categoriesFetchProgress)
  const list = useSelector(CollectionSelectors.categories)
  const fetchAction = useAction(CollectionActions.categoriesRequested)

  useDidMount(() => {
    if (progress === Progress.IDLE) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useSubCategoryList: ResourceHook<Dict<Category>> = () => {
  const progress = useSelector(CollectionSelectors.categoriesFetchProgress)
  const list = useSelector(CollectionSelectors.subCategories)
  const fetchAction = useAction(CollectionActions.categoriesRequested)

  useDidMount(() => {
    if (progress === Progress.IDLE) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useCompanyCategoryList: ResourceHook<Category[]> = (companyId: string) => {
  const selector = useMemo(() => createSelector(getCompanyCategory, entry => entry), [])
  const entry = useSelector(state => selector(state, companyId))
  const progress = entry?.progress || Progress.IDLE
  const items = entry?.items || ([] as const)

  const [, company] = useProducer(companyId)
  let isStorefront = false
  if (company?.sku_orders) isStorefront = true

  const fetchAction = useAction(CollectionActions.companyCategoriesRequested, companyId, isStorefront)

  useDidMount(() => {
    if (progress === Progress.IDLE) {
      fetchAction()
    }
  })

  return [progress, items]
}

export const useCompanyCategoryDict: ResourceHook<Dict<Category>> = (companyId: string) => {
  const [progress, items = []] = useCompanyCategoryList(companyId)
  return [progress, arrToDict(items)]
}

export const useCategory: ResourceHook<Category> = (idOrSlug: string) => {
  const [progress] = useCategoryList()
  const category = useSelector(state => CollectionSelectors.categoryByIdOrSlug(state, idOrSlug))
  return [progress, category]
}

export const useCompanySubCategoryList: ResourceHook<Category[]> = (producerSlug: string, categorySlug: string) => {
  const selector = useMemo(() => createSelector(getCompanySubCategory, entry => entry), [])
  const entry = useSelector(state => selector(state, producerSlug, categorySlug))
  const progress = entry?.progress || Progress.IDLE
  const items = entry?.items || ([] as const)

  const [, company] = useProducer(producerSlug)
  let isStorefront = false
  if (company?.sku_orders) isStorefront = true

  const fetchAction = useAction(
    CollectionActions.companySubCategoriesRequested,
    producerSlug,
    categorySlug,
    isStorefront,
  )

  useDidMount(() => {
    if (progress === Progress.IDLE) {
      fetchAction()
    }
  })

  return [progress, items]
}

export const useCompanySubCategoryDict: ResourceHook<Dict<Category>> = (companyId: string) => {
  const [progress, items = []] = useCompanySubCategoryList(companyId)
  return [progress, arrToDict(items)]
}

export const useSubcategory: ResourceHook<Category> = (idOrSlug: string) => {
  const [progress] = useSubCategoryList()
  const subcategory = useSelector(state => CollectionSelectors.categoryByIdOrSlug(state, idOrSlug))
  return [progress, subcategory]
}

export const useDistributorsList: ResourceHook<Company[]> = (filter: DistributorFilter, enableGrouping?: boolean) => {
  const items = useSelector(CollectionSelectors.distributors)
  const progress = useSelector(CollectionSelectors.distributorsFetchProgress)
  const { producerSlug } = useParams<{ producerSlug: string }>()
  const [, producer] = useProducer(producerSlug)
  const producerId = filter.for_producer_id ?? producer?.id

  if (!filter.for_producer_id) filter.for_producer_id = producerId
  const fetchAction = useAction(CollectionActions.distributorsRequested, filter, enableGrouping)

  const doNotFetch = useMemo(() => {
    return !producerId || progress === Progress.WORK || (enableGrouping && !filter.can_deliver_products_ids?.length)
  }, [enableGrouping, filter.can_deliver_products_ids?.length, producerId, progress])

  useEffect(() => {
    if (doNotFetch) return

    fetchAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.can_deliver_products_ids?.length, producerId])

  return [progress, dictToArr(items)]
}

export const useFilterAttributes: ResourceHook<FilterAttribute[]> = (producerSlug: string, subCategorySlug: string) => {
  const progress = useSelector(CollectionSelectors.filterAttributesProgress)
  const items = useSelector(state => CollectionSelectors.filterAttributes(state, producerSlug, subCategorySlug))
  const stateProducerId = useSelector(CollectionSelectors.filterAttributesProducerId)
  const stateSubCategoryId = useSelector(CollectionSelectors.filterAttributesSubCategoryId)
  const fetchAction = useAction(CollectionActions.filterAttributesRequested, producerSlug, subCategorySlug)

  useDidMount(() => {
    if (stateSubCategoryId !== subCategorySlug || stateProducerId !== producerSlug) {
      fetchAction()
    }
  })

  return [progress, items]
}

export const useCountryPhoneCodeList: ResourceHook<CountryCodeEntry[]> = () => {
  const progress = useSelector(CollectionSelectors.countryPhoneCodesProgress)
  const list = useSelector(CollectionSelectors.countryPhoneCodes)
  const fetchAction = useAction(CollectionActions.countryPhoneCodesRequested)

  useDidMount(() => {
    if (progress === Progress.IDLE) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useCountryPhoneCodeListWithDefaultCountry = (): [
  Progress,
  CountryCodeEntry[],
  CountryCode | undefined,
] => {
  const [progress, list = []] = useCountryPhoneCodeList()
  const detectedCountry = useDetectedCountry()
  let defaultCountryCode

  if (list.length) {
    if (detectedCountry && list.findIndex(item => item.id === detectedCountry)) {
      defaultCountryCode = detectedCountry
    } else {
      defaultCountryCode = list[0]?.id
    }
    // TODO add some default value here for a case when list is empty
  }

  return [progress, list, defaultCountryCode]
}

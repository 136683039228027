import { useProducer } from 'modules/domain/producer/hooks'
import { checkFeatureFlags } from 'helpers/featureFlags'
import { useCallback } from 'react'
import { CompanyConfig } from 'types/entities'

const useCompanyFeatureFlags = (idOrSlug: string) => {
  const [, producer] = useProducer(idOrSlug)
  const config = producer?.config as CompanyConfig

  const check = useCallback(
    config => checkFeatureFlags(config?.seller_config?.feature_flags || config?.feature_flags),
    [],
  )

  return check(config)
}

export default useCompanyFeatureFlags

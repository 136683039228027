import { Currency } from 'types/entities'
import { CountryCode } from 'libphonenumber-js'

export const DADATA_COMPANY_API_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party'
export const DADATA_ADDRESS_API_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
export const DADATA_API_KEY = 'e9640d8a3572c018cadaafb2aaf42d8338476edc'

export const apiDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSS'

export const API_BASE_URL = 'https://api-company-filters.stage.agro.club/api/v1'
export const AUTH_API_BASE_URL = 'https://api-company-filters.stage.agro.club/api/v1'

export const AVAILABLE_CURRENCY: Currency[] = [Currency.RUB, Currency.UAH, Currency.CAD, Currency.USD]

export const DATE_FORMAT_SHORT = 'dd.MM.yy'
export const DATE_FORMAT_SHORT_FULL_YEAR = 'dd.MM.yyyy'

export const LIST_PAGE_SIZE = 50

export const LOCAL_STORAGE_LANG = '_app_lang'

export const DEFAULT_COUNTRY: CountryCode = 'CA'
export const AVAILABLE_COUNTRY: CountryCode[] = ['RU', 'CA', 'ES', 'US']

export const COOKIE_KEYS = {
  ipCountry: 'cf-ipcountry',
  countrySuggestionDismissed: 'cs-dismissed',
}

export enum STORAGE_KEYS {
  ProcoteQuestionnaire = 'procote_questionnaire',
}

export const PROCOTE_WIZARD_VALUE = 'L'

import React, { useMemo } from 'react'
import styled from 'styled-components'

import * as Styled from './styled'
import { Input, FormikHook } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Partner } from 'types/entities/userProfile'
import PhoneInput from 'views/components/PhoneInput/PhoneInput'

type Props = {
  initialValues?: Partner
  editable?: boolean
  useFormik: FormikHook
}

const InputsContainer = styled.div`
  width: 100%;
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 16px;
    border-bottom: 1px solid ${props => props.theme.color.secondary50};
    padding-bottom: 24px;
  }
`

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}
const PartnerInfoForm: React.FC<Props> = ({ initialValues = {}, editable = true, useFormik }) => {
  const { t } = useTranslation(['profile', 'common'])
  const validator = useMemo(() => {
    return Yup.object({
      first_name: Yup.string(),
      last_name: Yup.string(),
      phone_number: Yup.string(),
      farm_name: Yup.string(),
    })
  }, [])

  const formik = useFormik<Partner>({
    initialValues,
    validationSchema: validator,
    onSubmit: noop,
    enableReinitialize: true,
    validateOnMount: true,
  })

  return (
    <InputsContainer>
      <Styled.NameRow>
        <Input {...formik.getFieldProps('first_name')} label={t('common:firstName')} disabled={!editable} />
        <Input {...formik.getFieldProps('last_name')} label={t('common:lastName')} disabled={!editable} />
      </Styled.NameRow>
      <Styled.FormRow width={'250px'}>
        <PhoneInput
          name={'phone_number'}
          phoneNumber={formik.values.phone_number}
          onBlur={() => {
            formik.setFieldTouched('phone_number', true)
          }}
          onChange={(phone: string) => {
            formik.setFieldValue('phone_number', phone)
            formik.setFieldTouched('phone_number', true)
          }}
          label={t('common:phone')}
          disabled={!editable}
        />
      </Styled.FormRow>
      <Styled.FormRow>
        <Input {...formik.getFieldProps('farm_name')} label={t('partnership.farmName')} disabled={!editable} />
      </Styled.FormRow>
    </InputsContainer>
  )
}

export default PartnerInfoForm

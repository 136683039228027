import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from '@agro-club/frontend-shared'

export enum CloseType {
  Ok = 'ok',
  Close = 'close',
}

type Props = {
  isOpen: boolean
  onClose: (CloseType) => void
  text?: string
  title?: string
}

const StyledButton = styled(Button)`
  margin-top: 40px;
  min-width: 208px;
`

const ContentWrapper = styled.div`
  overflow: auto;
  max-height: 50vh;
  white-space: pre-wrap;

  & > p {
    color: ${props => props.theme.color.onSurfaceMidEmphasys};
  }

  & > p.note {
    font-size: 12px;
    color: ${props => props.theme.color.onSurfaceMidEmphasys};
    line-height: 14px;
    margin: 0px;
  }
`

const DiscountInfoPopup: React.FC<Props> = ({ isOpen, text, title, onClose }) => {
  const { t } = useTranslation(['discountBanner', 'common'])

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(CloseType.Close)} heading={title ? title : t('modalHeading')}>
      <ContentWrapper dangerouslySetInnerHTML={{ __html: text ? text : t('modalText') }} />
      <StyledButton intent={'primary-action'} filled onClick={() => onClose(CloseType.Ok)}>
        {t('common:ok')}
      </StyledButton>
    </Modal>
  )
}

export default DiscountInfoPopup

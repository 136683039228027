import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import PackageTypesActions from './duck'
import PackageTypesSelectors from './selectors'
import { PackageTypes } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { apiCall } from 'modules/sagaEffects'

export const fetchList = function*() {
  try {
    let currentPage = yield select(PackageTypesSelectors.page)
    const filter = yield select(PackageTypesSelectors.filter)
    const sorting = yield select(PackageTypesSelectors.sorting)
    const pageSize = yield select(PackageTypesSelectors.pageSize)
    let response: ListResponse<PackageTypes> = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(apiCall, managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(PackageTypesActions.listRequestSucceed(data, total_count, page))
  } catch (err) {
    yield put(PackageTypesActions.listRequestFailed())
  }
}

export const fetchAllList = function*() {
  try {
    const response: ListResponse<PackageTypes> = yield call(apiCall, managers.getListAll)
    const { data } = response
    yield put(PackageTypesActions.listAllRequestSucceed(data))
  } catch (err) {
    yield put(PackageTypesActions.listAllRequestFailed())
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(PackageTypesSelectors.page)
    const filter = yield select(PackageTypesSelectors.filter)
    const sorting = yield select(PackageTypesSelectors.sorting)
    const pageSize = yield select(PackageTypesSelectors.pageSize)
    const { data, total_count }: { data: PackageTypes[]; total_count: number } = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(PackageTypesActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    yield put(PackageTypesActions.listRequestNextFailed())
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof PackageTypesActions.itemRequested>) {
  try {
    const item: PackageTypes = yield call(apiCall, managers.getItem, id)
    yield put(PackageTypesActions.itemRequestSucceed(item))
  } catch (err) {
    yield put(PackageTypesActions.itemRequestFailed(id))
  }
}

const PackageTypesSaga = function*() {
  yield all([
    takeLatest(PackageTypesActions.itemRequested.type, fetchItem),
    takeLatest(PackageTypesActions.listRequested.type, fetchList),
    takeLatest(PackageTypesActions.listAllRequested.type, fetchAllList),
    takeLatest(PackageTypesActions.filterUpdated.type, fetchList),
    takeLatest(PackageTypesActions.sortingUpdated.type, fetchList),
    takeLatest(PackageTypesActions.filterHasBeenReset.type, fetchList),
    takeLatest(PackageTypesActions.sortingHasBeenReset.type, fetchList),
    takeLatest(PackageTypesActions.listRequestedNext.type, fetchListNext),
  ])
}

export default PackageTypesSaga

import styled, { StyledProps } from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useThrottle } from '@agro-club/frontend-shared'

const QtyHintWrapper = styled.div<StyledProps<{ isHighestDiscountReached: boolean }>>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme, isHighestDiscountReached }) =>
    isHighestDiscountReached ? theme.color.backgroundBase : theme.color.primary25};
  border-radius: 8px;
  margin-top: 8px;
  padding: 8px;
  & > :last-child {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    text-align: center;

    color: ${({ theme, isHighestDiscountReached }) =>
      isHighestDiscountReached ? theme.color.accentApproving : theme.color.secondary300};
  }

  ${props => props.theme.media.mobile`
    width: 100%;
    max-width: 100%;
      margin-bottom: 8px;
  `}
`
const AddItemsButton = styled.button`
  outline: none;
  cursor: pointer;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  margin-bottom: 4px;

  text-align: center;
  text-decoration-line: underline;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.color.primary600};
`
export const AddItemsHint = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  text-align: center;
  color: ${({ theme }) => theme.color.secondary300};
`
export const QtyDiscountHint: React.FC<{
  isHighestDiscountReached: boolean
  missingQty: number
  onAddItems: (amount: number) => void
  active?: boolean
}> = ({ isHighestDiscountReached, missingQty, onAddItems, active = true }) => {
  const { t } = useTranslation('cart')
  const [onAddClick] = useThrottle(() => onAddItems(missingQty), 500)

  return (
    <QtyHintWrapper data-test-id={'qty-discount-hint'} isHighestDiscountReached={isHighestDiscountReached}>
      {!isHighestDiscountReached ? (
        <>
          {active ? (
            <AddItemsButton data-test-id={'add-more-button'} onClick={onAddClick}>
              {t('addMore', { amount: missingQty })}
            </AddItemsButton>
          ) : (
            <AddItemsHint>{t('addMore', { amount: missingQty })}</AddItemsHint>
          )}
          <div>{t('getDiscountHint')}</div>
        </>
      ) : (
        <div data-test-id={'max-discount-reached'}>{t('reachedMaxDiscount')}</div>
      )}
    </QtyHintWrapper>
  )
}

import React, { useContext, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import SpecifyProductScreen from './SpecifyProductScreen'
import { getPrettyPrice } from 'modules/utils/helpers'
import { Currency } from 'types/entities'
import { IconCheck } from '@agro-club/frontend-shared'
import * as Styled from './styled'
import { CalculatedDiscountDto, CalculatedTier, PercentTierRule } from 'types/entities/discount'
import { ProductUnits } from 'modules/domain/product/types'
import { CalculatedSkuDiscountDto, CalculatedSkuTier } from 'modules/domain/discountsSku/types'
import { Dict } from 'types/generics'

type ProductOption = {
  title: string
  value: string
}

type TierProps = {
  dataTestId?: string
  onUpgradeClick: () => void
  currency: Currency
  units?: Partial<ProductUnits>
  isApplied?: boolean
  qtyToApply?: number
  amount?: string
  maxQty?: number
  minQty?: number
  percent?: string
}

const Tier: React.FC<TierProps> = ({
  dataTestId,
  isApplied,
  qtyToApply,
  amount = 0,
  maxQty,
  minQty,
  onUpgradeClick,
  currency,
  units,
  percent,
}) => {
  const themeContext = useContext(ThemeContext)
  const { t } = useTranslation(['discount', 'common'])

  const discountText = percent
    ? `${percent}%`
    : `${getPrettyPrice(amount, currency)}/${units?.singular || t('common:bag')}`
  const conditionText = maxQty
    ? `${t('common:for')} ${minQty} - ${maxQty} ${units?.plural || t('common:bag_plural')}`
    : minQty
    ? `${t('common:for')} ${minQty}+ ${units?.plural || t('common:bag_plural')}`
    : ''

  const handleActionClick = useCallback(() => {
    onUpgradeClick()
  }, [onUpgradeClick])

  return (
    <Styled.EntryWrapper data-test-id={dataTestId || 'tier-entry'}>
      <Styled.EntryCondition>
        <div>{discountText}</div>
        <div>{conditionText}</div>
      </Styled.EntryCondition>
      {(isApplied || (!!qtyToApply && qtyToApply > 0)) && (
        <Styled.EntryAction data-test-id={`apply-${dataTestId}`} isApplied={isApplied} onClick={handleActionClick}>
          {isApplied ? (
            <>
              <IconCheck
                style={{ fill: themeContext.color.accentApproving, marginRight: '5px' }}
                title={'applied'}
                data-test-id={'check-icon'}
              />
              {t('applied')}
            </>
          ) : (
            `${t('addMoreToGetDiscount', { amount: qtyToApply })}`
          )}
        </Styled.EntryAction>
      )}
    </Styled.EntryWrapper>
  )
}

type GroupedTiersProps<TierType extends unknown> = React.HTMLAttributes<HTMLDivElement> & {
  title?: string
  tiers: TierType[]
  currency: Currency
  units?: ProductUnits
  onApply: (qty: number) => void
  getIsApplied: (tier: TierType) => boolean
  getQtyToApply: (tier: TierType) => number
  getAmount: (tier: TierType) => string | undefined
  getMaxQty: (tier: TierType) => number | undefined
  getMinQty: (tier: TierType) => number | undefined
  getPercent: (tier: TierType) => string | undefined
  id: string
}

const GroupedTiers = <TierType extends unknown>({
  title,
  tiers,
  onApply,
  currency,
  units,
  getIsApplied,
  getAmount,
  getMaxQty,
  getMinQty,
  getQtyToApply,
  getPercent,
  id,
  ...props
}: GroupedTiersProps<TierType>) => {
  return (
    <Styled.SectionWrapper {...props} data-test-id={`group-${id}`}>
      {title && <Styled.SectionTitle>{title}</Styled.SectionTitle>}
      {tiers.map((tier, idx) => {
        const qtyToApply = getQtyToApply(tier)
        return (
          <Tier
            key={idx}
            currency={currency}
            dataTestId={`tier-${idx}`}
            isApplied={getIsApplied(tier)}
            amount={getAmount(tier)}
            maxQty={getMaxQty(tier)}
            minQty={getMinQty(tier)}
            percent={getPercent(tier)}
            qtyToApply={qtyToApply}
            units={units}
            onUpgradeClick={() => onApply(qtyToApply)}
          />
        )
      })}
    </Styled.SectionWrapper>
  )
}

export type BaseQtyDiscountWidgetProps<DiscountType extends unknown, TierType extends unknown> = {
  title: string
  type?: Styled.WidgetType
  options?: Dict<ProductOption[]>
  onGetDiscount: (qty: number, key?: string) => void
  dataTestId?: string
  currency: Currency
  units?: ProductUnits
  discounts: DiscountType[]
  getIsApplied: (tier: TierType) => boolean
  getQtyToApply: (tier: TierType) => number
  getAmount: (tier: TierType) => string | undefined
  getMaxQty: (tier: TierType) => number | undefined
  getMinQty: (tier: TierType) => number | undefined
  getPercent: (tier: TierType) => string | undefined
  getDiscountId: (discount: DiscountType) => string
  getDiscountTitle: (discount: DiscountType) => string
  getDiscountTiers: (discount: DiscountType) => TierType[]
}

export const BaseQtyDiscountWidget = <DiscountType extends unknown, TierType extends unknown>({
  title,
  discounts,
  currency,
  options = {},
  onGetDiscount,
  dataTestId,
  units,
  getDiscountId,
  getDiscountTitle,
  getDiscountTiers,
  ...props
}: BaseQtyDiscountWidgetProps<DiscountType, TierType>) => {
  const [showOptions, setShowOptions] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState('')
  const [qtyToAdd, setQtyToAdd] = useState(0)

  const onApply = useCallback(
    (groupId: string, appendQty: number) => {
      const ruleOptions = options[groupId] || []
      if (!ruleOptions.length) {
        onGetDiscount(appendQty)
      } else if (ruleOptions.length === 1) {
        onGetDiscount(appendQty, ruleOptions[0].value)
      } else {
        setSelectedGroup(groupId)
        setShowOptions(true)
        setQtyToAdd(appendQty)
      }
    },
    [setShowOptions, onGetDiscount, options],
  )

  return (
    <Styled.Wrapper data-test-id={`widget-${dataTestId}`}>
      {!showOptions ? (
        <>
          <Styled.Title data-test-id={'qty-widget-title'}>{title}</Styled.Title>
          <Styled.SectionContainer data-test-id={'tiers-container'}>
            {discounts.map((discount, idx) => {
              const id = getDiscountId(discount)
              return (
                <GroupedTiers
                  key={idx}
                  currency={currency}
                  title={discounts.length > 1 ? getDiscountTitle(discount) : undefined}
                  tiers={getDiscountTiers(discount)}
                  units={units}
                  onApply={qty => {
                    onApply(id, qty)
                  }}
                  id={id}
                  {...props}
                />
              )
            })}
          </Styled.SectionContainer>
        </>
      ) : (
        <SpecifyProductScreen
          options={options[selectedGroup]}
          qtyToAdd={qtyToAdd}
          onSelect={(key, qty) => {
            onGetDiscount(qty || 0, key)
            setShowOptions(false)
          }}
        />
      )}
    </Styled.Wrapper>
  )
}

export type QtyDiscountWidgetProps = Omit<
  BaseQtyDiscountWidgetProps<CalculatedDiscountDto, CalculatedTier>,
  | 'getDiscountId'
  | 'getDiscountTitle'
  | 'getDiscountTiers'
  | 'getIsApplied'
  | 'getQtyToApply'
  | 'getAmount'
  | 'getMaxQty'
  | 'getMinQty'
  | 'getPercent'
>

export const QtyDiscountWidget: React.VFC<QtyDiscountWidgetProps> = props => {
  return (
    <BaseQtyDiscountWidget<CalculatedDiscountDto, CalculatedTier>
      {...props}
      getAmount={tier => tier.tier_rule.amount}
      getIsApplied={tier => tier.is_applied}
      getMaxQty={tier => tier.tier_rule.max_qty}
      getMinQty={tier => tier.tier_rule.min_qty}
      getPercent={tier => (tier.tier_rule as PercentTierRule).percent}
      getQtyToApply={tier => tier.qty_to_apply}
      getDiscountId={discount => discount.rule.id}
      getDiscountTitle={discount => discount.rule.title}
      getDiscountTiers={discount => discount.tiers}
    />
  )
}

export type SkuQtyDiscountWidgetProps = Omit<
  BaseQtyDiscountWidgetProps<CalculatedSkuDiscountDto, CalculatedSkuTier>,
  | 'getDiscountId'
  | 'getDiscountTitle'
  | 'getDiscountTiers'
  | 'getIsApplied'
  | 'getQtyToApply'
  | 'getAmount'
  | 'getMaxQty'
  | 'getMinQty'
  | 'getPercent'
>

export const SkuQtyDiscountWidget: React.VFC<SkuQtyDiscountWidgetProps> = props => {
  return (
    <BaseQtyDiscountWidget<CalculatedSkuDiscountDto, CalculatedSkuTier>
      {...props}
      getAmount={tier => tier.tier_rule.amount}
      getIsApplied={tier => tier.is_applied}
      getMaxQty={tier => tier.tier_rule.max_qty}
      getMinQty={tier => tier.tier_rule.min_qty}
      getQtyToApply={tier => tier.qty_to_apply}
      getDiscountId={discount => discount.program.id}
      getDiscountTitle={discount => discount.program.title}
      getDiscountTiers={discount => discount.tiers}
      getPercent={tier => (tier.tier_rule as PercentTierRule).percent}
    />
  )
}

import { SectionContainer } from '@agro-club/frontend-shared'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useProducer } from 'modules/domain/producer/hooks'
import { productToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import ProducerRoutes from '../../routes'
import AdderWidgetEvents from './AdderWidget/events'
import { ProductItemDto } from './ProductItem'
import * as Styled from './styled'

type Props = {
  product: ProductItemDto
}

const OutOfStockWidget: React.FC<Props> = ({ product }) => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation('order')
  const match = useRouteMatch<{ producerSlug?: string }>(ProducerRoutes.ProducerInfoPage)
  const [, producer] = useProducer(match?.params.producerSlug)

  const sellerConfig = producer?.config?.seller_config
  const outOfStockInfo = sellerConfig?.out_of_stock_info

  const emailValue = outOfStockInfo?.email
  const text = (outOfStockInfo?.text || t('contactLocalRep')) + ' '

  const handleHowItWorksClick = () => track(AdderWidgetEvents.HowItWorksClick, productToEventParams(product))

  return (
    <div data-test-id="out-of-stock-widget">
      <SectionContainer noDivider>
        <Styled.CustomSectionBody>
          <Styled.TitleStyled>{t('outOfStock')}</Styled.TitleStyled>
          <Styled.OutOfStockContent>
            {text}
            {!!emailValue && <a href={`mailto:${emailValue}`}>{emailValue}</a>}
          </Styled.OutOfStockContent>
          <Styled.HowItWorksButtonStyled items={sellerConfig?.how_it_works || []} onClick={handleHowItWorksClick} />
        </Styled.CustomSectionBody>
      </SectionContainer>
    </div>
  )
}

export default OutOfStockWidget

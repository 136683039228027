import { Region } from 'modules/domain/collection/types'
import { CountryCode } from 'libphonenumber-js'
import { Season } from 'modules/domain/season/types'

export type AvailableLanguages = 'RU' | 'EN' | 'FR'

export enum Status {
  Deleted = 'deleted',
  Active = 'active',
  Inactive = 'inactive',
}

export enum CompanyType {
  User = 'user',
  Agro = 'agro',
  Producer = 'producer',
  Distributor = 'distributor',
}

export enum BusinessModel {
  Full = 'full',
  Lite = 'lite',
}

export enum Currency {
  RUB = 'RUB',
  USD = 'USD',
  UAH = 'UAH',
  CAD = 'CAD',
  EUR = 'EUR',
}

export enum Permissions {
  MANAGER = 'manager',
  ADMIN = 'admin',
  HEAD = 'head',
}

export type ApiRole =
  | 'user'
  | 'farmer'
  | 'agro:admin'
  | 'agro:manager'
  | 'producer:head'
  | 'producer:manager'
  | 'distributor:head'
  | 'distributor:manager'

export type LocalizedValue = {
  en?: string
  ru?: string
  ua?: string
  fr?: string
  es?: string
}

export type HowItWorksItem = {
  emoji?: string
  title: string
  title_i18n?: LocalizedValue
  subtitle?: string
  subtitle_i18n?: LocalizedValue
}

export type TruckInfo = {
  title_i18n: LocalizedValue
  description_i18n: LocalizedValue
  learn_more_url: string
}

export type FeatureFlagPermission = {
  [permission: string]: boolean
}

export type FeatureFlags = {
  [key: string]: FeatureFlagPermission
}

export type OutOfStockInfo = {
  text_i18n?: LocalizedValue
  text?: string
  email?: string
}

export type SellerConfig = {
  feature_flags: FeatureFlags
  how_it_works: HowItWorksItem[]
  truck_info: TruckInfo
  order_now_hint_i18n: LocalizedValue
  default_distributor_id: string
  has_exclusive_goods: boolean
  out_of_stock_info: OutOfStockInfo
}

export type RetailerConfig = {}

export type CompanyConfig = {
  seller_config?: SellerConfig
  retailer_config?: RetailerConfig
  id?: string
}

export type Company = {
  id: string
  slug: string
  is_active: boolean
  credit_offer?: boolean
  company_type: CompanyType.Producer
  official_name: string
  internal_name: string
  description: string
  short_description: string
  business_id: string
  address: string
  zip_code?: string
  bank_details: string
  business_model: BusinessModel
  country: CountryCode
  currency: Currency

  regions?: Region[] | null
  regions_ids?: string[]
  logo_url?: string

  producers_relations: string[]

  config?: CompanyConfig
  sku_orders: boolean
  currentSeason?: Season
}

export enum PriceType {
  Msrp = 'msrp',
  None = 'not_specified',
}

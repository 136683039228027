import { ListResponse } from 'types/api'
import { Category, Country, DistributorFilter, FilterAttribute, PromoInfo, Region } from './types'
import { endpoints } from 'modules/endpoints'
import { Company, CompanyType } from 'types/entities'
import { ApiService } from 'service/api/interface'
import { CountryCodeEntry } from '@agro-club/frontend-shared'

export const fetchRegions = (apiClient: ApiService) => async (country: string) => {
  const result = await apiClient.get<ListResponse<Region>>(endpoints.regions(), { country, page_size: 1000 })
  return { data: result.data, total_count: result.total_count }
}

export const fetchCategories = (apiClient: ApiService) => () => {
  return apiClient.get<ListResponse<Category>>(endpoints.category(), { page_size: 200 })
}

export const fetchCompanyCategories = (apiClient: ApiService) => (companyId: string, isStorefront: boolean) => {
  if (isStorefront) {
    return apiClient.get<Category[]>(endpoints.storefrontCategories(companyId), { page_size: 200, parent: 'null' })
  } else {
    return apiClient.get<Category[]>(endpoints.companyCategories(companyId), { page_size: 200, parent_id: 'null' })
  }
}

export const fetchCompanySubCategories = (apiClient: ApiService) => (
  companyId: string,
  categorySlug: string,
  isStorefront: boolean,
) => {
  if (isStorefront) {
    return apiClient.get<Category[]>(endpoints.storefrontCategories(companyId), {
      page_size: 200,
      parent: categorySlug,
    })
  } else {
    return apiClient.get<Category[]>(endpoints.companyCategories(companyId), {
      page_size: 200,
      parent_id: categorySlug,
    })
  }
}

export const fetchDistributors = (apiClient: ApiService) => (filter: DistributorFilter, enableGrouping?: boolean) => {
  const endpoint = enableGrouping ? endpoints.companiesWithDelivery() : endpoints.companies()
  return apiClient.get<ListResponse<Company>>(endpoint, {
    ...filter,
    company_type: CompanyType.Distributor,
    page_size: 1000,
  })
}

export const fetchFilterAttributes = (apiClient: ApiService) => async (producerId: string, subCategoryId: string) => {
  const [producer, subCategory] = await Promise.all([
    apiClient.get<Company>(endpoints.companies(producerId)),
    apiClient.get<Category>(endpoints.category(subCategoryId)),
  ])
  if (producer.sku_orders) {
    return apiClient.get<ListResponse<FilterAttribute>>(endpoints.cardFilterAttributes(), {
      seller_id: producer.id,
      subcategory_id: subCategory.id,
    })
  } else {
    return apiClient.get<ListResponse<FilterAttribute>>(endpoints.productFilterAttributes(), {
      producer_id: producer.id,
      subcategory: subCategory.id,
    })
  }
}

export const fetchPromoInfo = (apiClient: ApiService) => async () => {
  return apiClient.get<ListResponse<PromoInfo>>(endpoints.promoInfo(), { page_size: 250 })
}

export const fetchCountryPhoneCodes = (apiClient: ApiService) => async () => {
  return apiClient.get<ListResponse<CountryCodeEntry>>(endpoints.phoneCodes(), { page_size: 250 })
}

export const fetchCountries = (apiClient: ApiService) => async () => {
  return apiClient.get<ListResponse<Country>>(endpoints.country(), { page_size: 250 })
}

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, RemovePopover } from '@agro-club/frontend-shared'

import AuthSelectors from 'modules/domain/auth/selectors'
import { Progress } from 'modules/types'

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, min-content);
  gap: 16px;

  ${props => props.theme.media.mobile`
    max-width: 400px;
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, max-content);
  `}
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const GdprRemovePopover = styled(RemovePopover)`
  ${props => props.theme.media.mobile`
    margin-bottom: 12px;
  `}
`

const GdprButton = styled(Button)`
  width: max-content;
  ${props => props.theme.media.mobile`
    width: 100%;
  `}
`

const GdprRequestInfo = styled.p`
  margin: 12px 0 0 0;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

const GdprRequestError = styled(GdprRequestInfo)`
  color: ${props => props.theme.color.accentDestructive};
`

type GdprSectionType = {
  profileEmail: string | null
  onGdprSendClick: (email: string) => void
  onGdprSendWithoutEmailClick: () => void
  onGdprEraseClick: () => void
}

const GdprSection: React.FC<GdprSectionType> = ({
  profileEmail,
  onGdprSendClick,
  onGdprSendWithoutEmailClick,
  onGdprEraseClick,
}) => {
  const { t } = useTranslation('profile')
  const gdprSendProgress = useSelector(AuthSelectors.getGdprSendProgress)
  const gdprEraseProgress = useSelector(AuthSelectors.getGdprEraseProgress)

  const renderGdprSendTooltip = () => {
    switch (gdprSendProgress) {
      case Progress.SUCCESS:
        return <GdprRequestInfo>{t('gdpr.sendDataSuccess')}</GdprRequestInfo>
      case Progress.ERROR:
        return <GdprRequestError>{t('gdpr.commonError')}</GdprRequestError>
      default:
        return null
    }
  }

  const renderGdprEraseTooltip = () => {
    switch (gdprEraseProgress) {
      case Progress.SUCCESS:
        return <GdprRequestInfo>{t('gdpr.eraseDataSuccess')}</GdprRequestInfo>
      case Progress.ERROR:
        return <GdprRequestError>{t('gdpr.commonError')}</GdprRequestError>
      default:
        return null
    }
  }

  return (
    <Container>
      <ButtonWrapper>
        {profileEmail ? (
          <GdprRemovePopover
            onRemove={() => onGdprSendClick(profileEmail)}
            text={t('gdpr.sendDataPopover', { email: profileEmail })}
            submitText={t('gdpr.sendDataSubmitText')}
            submitIntent="secondary"
            cancelText={t('gdpr.sendDataCancelText')}
          >
            <GdprButton
              intent="primary-action"
              filled={false}
              size="small"
              disabled={gdprSendProgress === Progress.WORK || gdprSendProgress === Progress.SUCCESS}
            >
              {t('gdpr.sendDataButton')}
            </GdprButton>
          </GdprRemovePopover>
        ) : (
          <GdprButton
            onClick={onGdprSendWithoutEmailClick}
            intent="primary-action"
            filled={false}
            size="small"
            disabled={gdprSendProgress === Progress.WORK || gdprSendProgress === Progress.SUCCESS}
          >
            {t('gdpr.sendDataButton')}
          </GdprButton>
        )}

        {renderGdprSendTooltip()}
      </ButtonWrapper>

      <ButtonWrapper>
        <GdprRemovePopover
          onRemove={onGdprEraseClick}
          text={t('gdpr.eraseDataPopover')}
          submitText={t('gdpr.eraseDataSubmitText')}
          cancelText={t('gdpr.eraseDataCancelText')}
        >
          <GdprButton
            intent="danger"
            size="small"
            disabled={gdprEraseProgress === Progress.WORK || gdprEraseProgress === Progress.SUCCESS}
          >
            {t('gdpr.eraseDataButton')}
          </GdprButton>
        </GdprRemovePopover>
        {renderGdprEraseTooltip()}
      </ButtonWrapper>
    </Container>
  )
}

export default GdprSection

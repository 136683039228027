import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Progress, ResourceHook } from 'modules/types'
import { useDidMount, useAction, usePageQuery } from '@agro-club/frontend-shared'
import DocumentActions from './duck'
import DocumentSelectors from './selectors'
import { Document, DocumentFileState } from './types'

export const useDocumentList: ResourceHook<Document[]> = () => {
  const progress = useSelector(DocumentSelectors.listFetchProgress)
  const list = useSelector(DocumentSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(DocumentActions.listRequested, page ? { page } : {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const useDocumentItem: ResourceHook<Document> = (id: string) => {
  const meta = useSelector(state => DocumentSelectors.meta(state, id))
  const item = useSelector(state => DocumentSelectors.item(state, id))
  const fetchAction = useAction(DocumentActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useDocumentItemById: ResourceHook<Document> = (id: string) => {
  const meta = useSelector(state => DocumentSelectors.meta(state, id))
  const item = useSelector(state => DocumentSelectors.item(state, id))
  const fetchAction = useAction(DocumentActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}

export const useDocumentFileUrl = (id: string): DocumentFileState => {
  const item = useSelector(state => DocumentSelectors.itemFileUrl(state, id))
  const fetchAction = useAction(DocumentActions.itemFileUrlRequested, id)
  useEffect(() => {
    if (item.progress === Progress.IDLE) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return item
}

export const useSignedDocumentFileUrl = (id: string): DocumentFileState => {
  const item = useSelector(state => DocumentSelectors.itemSignedFileUrl(state, id))
  const fetchAction = useAction(DocumentActions.itemSignedFileUrlRequested, id)
  useEffect(() => {
    if (item.progress === Progress.IDLE) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return item
}

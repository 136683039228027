import React from 'react'
import { useParams } from 'react-router-dom'
import { useOrder } from 'modules/domain/order/hooks'
import { Progress } from 'modules/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import SkuOrderDetails from 'views/pages/Profile/OrderDetails/SkuOrderDetails'
import ProductOrderDetails from 'views/pages/Profile/OrderDetails/ProductOrderDetails'
import { SpinnerWrapper } from 'views/pages/Profile/styled'

const OrderDetails = () => {
  const params = useParams<{ orderId: string }>()
  const [progress, order] = useOrder(params.orderId)
  const isSkuOrder = !!order?.sku_items.length

  if (progress === Progress.WORK || !order) {
    return (
      <SpinnerWrapper>
        <SpinnerLayout />
      </SpinnerWrapper>
    )
  }

  return isSkuOrder ? <SkuOrderDetails /> : <ProductOrderDetails />
}

export default OrderDetails

import styled, { StyledProps } from 'styled-components'

type FormRowProps = {
  width?: string
  hideTablet?: boolean
  hideMobile?: boolean
  hideDesktop?: boolean
}

export const FormRow = styled.div<StyledProps<FormRowProps>>`
  :not(:last-child) {
    margin-bottom: 20px;
  }

  width: ${({ width }) => (width ? width : '100%')};
`

export const NameRow = styled(FormRow)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`

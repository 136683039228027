import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Styled from 'views/pages/Profile/styled'
import { queries } from 'theme/theme'
import useMatchMedia from 'hooks/useMatchMedia'
import { CartList, MobileCartList } from 'views/components/CartList/CartList'
import {
  Button,
  IconPencil,
  IconCross,
  SectionContainer,
  SectionTitle,
  useAction,
  RemovePopover,
  Input,
} from '@agro-club/frontend-shared'
import { clone } from 'ramda'
import { equals } from 'ramda'
import Routes from 'views/pages/Profile/routes'
import useHelmet from 'hooks/useHelmet'
import useWLFeatureFlags from 'hooks/useWLFeatureFlags'
import { useHistory, useParams } from 'react-router-dom'
import { useOrder } from 'modules/domain/order/hooks'
import { Progress } from 'modules/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { OrderItem, OrderItemDTO, OrderStatus } from 'types/order/index'
import { CartItem, UpdateCartItemParams } from 'modules/domain/cart/types'
import { useSelector } from 'react-redux'
import OrderSelectors from 'modules/domain/order/selectors'
import OrderActions from 'modules/domain/order/duck'
import {
  generateActionAccessString,
  generateButtonAccessString,
  generateFieldModifierString,
} from 'modules/utils/generateStringHelpers'
import {
  Actions,
  Buttons,
  CustomFeatureName,
  FeatureFlagModifiers,
  FieldLocation,
  FieldNames,
  Sections,
} from 'types/entities/config'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { ProfileEvents } from 'views/pages/Profile/events'
import { useProductOptionsList } from 'modules/domain/productOptions/hooks'
import { useCountryPath } from 'hooks/useCountryPath'
import CartActions from 'modules/domain/cart/duck'
import CartSelectors from 'modules/domain/cart/selectors'
import { Status } from 'types/entities'
import useCompanyFeatureFlags from 'hooks/useCompanyFeatureFlags'
import { generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'
import { useTheme } from 'styled-components'

const mapOrderItemToCartItem = (item: OrderItem, discount_total: string, showUnits: boolean): CartItem => ({
  title: item.product.title_i18n,
  description: item.product.description_i18n,
  producer_id: item.product.producer_id,
  product: item.product,
  id: item.product_id, // workaround bc items in order have no ids
  product_id: item.product_id,
  qty: parseInt(item.quantity),
  default_packaging: item.product.default_packaging_i18n,
  alt_packaging: item.product.alt_packaging_i18n,
  packaging: item.packaging,
  seed_treatment_id: item.seed_treatment_id,
  options: item.options,
  // TODO real image here
  images: item.product.images,
  subcategory_id: item.product.subcategory_id,
  cost: item.cost,
  discount_amount: discount_total,
  showUnits,
  units: item.product.units,
})

const mapCartItemsToOrderItems = (item: CartItem): OrderItemDTO => ({
  product_id: item.product_id,
  quantity: String(item.qty),
  packaging: item.packaging,
  seed_treatment_id: item.seed_treatment_id,
  options: item.options,
})

type Action =
  | { type: 'init'; data: CartItem[] }
  | { type: 'remove'; id: string }
  | { type: 'change-qty'; id: string; qty: UpdateCartItemParams['quantity'] }
  | { type: 'change-packaging'; id: string; packaging: UpdateCartItemParams['packaging'] }
  | { type: 'change-seed-treatment'; id: string; seed_treatment_id: UpdateCartItemParams['seed_treatment_id'] }
  | { type: 'change-options'; id: string; options: UpdateCartItemParams['options'] }
  | { type: 'reset'; data: CartItem[] }

function reducer(state: CartItem[], action: Action) {
  switch (action.type) {
    case 'init':
      return action.data
    case 'remove':
    case 'change-qty':
    case 'change-seed-treatment':
    case 'change-options':
    case 'change-packaging':
      const index = state.findIndex(item => item.id === action.id)
      const newList = clone(state)
      switch (action.type) {
        case 'remove':
          newList.splice(index, 1)
          break
        case 'change-qty':
          if (newList[index]) {
            newList[index].qty = action.qty || 0
          }
          break
        case 'change-packaging':
          if (newList[index]) {
            newList[index].packaging = action.packaging
          }
          break
        case 'change-seed-treatment':
          if (newList[index]) {
            newList[index].seed_treatment_id = action.seed_treatment_id
          }
          break
        case 'change-options':
          if (newList[index]) {
            newList[index].options = action.options
          }
          break
      }
      return newList
    case 'reset':
      return action.data
    default:
      throw new Error()
  }
}

const ProductOrderDetails = () => {
  const params = useParams<{ orderId: string }>()
  const { t } = useTranslation(['profile', 'common'])
  const isMobile = useMatchMedia(queries.mobile)
  const [editable, setEditable] = useState(false)
  const [progress, order] = useOrder(params.orderId)

  const updateProgress = useSelector(OrderSelectors.updateProgress)
  const prevUpdateProgress = useRef(updateProgress)
  const updateAction = useAction(OrderActions.updateRequested)
  const cancelOrderAction = useAction(OrderActions.cancelOrderRequested)
  const checkFeatureFlag = useWLFeatureFlags()
  const { track } = useAnalyticsSSR()
  const theme = useTheme()
  const [, productOptions] = useProductOptionsList()
  const history = useHistory()
  const generateCountryPath = useCountryPath()
  const addItemsAction = useAction(CartActions.itemsAddRequested)
  const itemsInCart = useSelector(state => CartSelectors.cartItemsByProducer(state, order?.producer?.slug || ''))
  const addItemsProgress = useSelector(CartSelectors.addItemsProgress)
  const refreshProgress = useSelector(CartSelectors.refreshProgress)
  const checkCompanyFeatureFlags = useCompanyFeatureFlags(order?.producer?.slug || '')
  const farmerConfirmRequired = checkCompanyFeatureFlags(
    generateCustomFeatureFlag(Sections.Orders, CustomFeatureName.FarmerConfirmRequired),
  )
  const showUnits = checkCompanyFeatureFlags(
    generateFieldModifierString(FieldLocation.CartList, FieldNames.Units, FeatureFlagModifiers.Enabled),
  )
  const isQtyDisabled = checkCompanyFeatureFlags(
    generateFieldModifierString(FieldLocation.CartList, FieldNames.Qty, FeatureFlagModifiers.Disabled),
  )
  const isPreorder = useMemo(
    () => !order?.interaction.confirmed_by_farmer && order?.status !== 'canceled' && farmerConfirmRequired,
    [farmerConfirmRequired, order?.interaction.confirmed_by_farmer, order?.status],
  )

  const [farmerComment, setFarmerComment] = useState('')

  useEffect(() => {
    if (order) setFarmerComment(order.farmer_comment || '')
  }, [order])

  const cartItems: CartItem[] = useMemo(() => {
    if (!order) {
      return []
    }
    return order.items.map(item => mapOrderItemToCartItem(item, order.discount_total, showUnits))
  }, [order, showUnits])

  const [editedCartItems, dispatch] = useReducer(reducer, clone(cartItems))

  useEffect(() => {
    dispatch({ type: 'init', data: cartItems })
  }, [cartItems])

  const handleRemoveItem = useCallback(
    (id: string) => {
      track(ProfileEvents.OrderDeleteProductTap)
      dispatch({ type: 'remove', id })
    },
    [track],
  )

  const handleQtyChange = useCallback((id: string, qty: UpdateCartItemParams['quantity']) => {
    dispatch({ type: 'change-qty', id, qty: qty || 0 })
  }, [])

  const handlePackagingChange = useCallback((id: string, packaging: UpdateCartItemParams['packaging']) => {
    dispatch({ type: 'change-packaging', id, packaging: packaging || 0 })
  }, [])

  const handleSeedTreatmentChange = useCallback(
    (id: string, seed_treatment: UpdateCartItemParams['seed_treatment_id']) => {
      dispatch({ type: 'change-seed-treatment', id, seed_treatment_id: seed_treatment || '' })
    },
    [],
  )

  const handleOptionsChange = useCallback((id: string, options: UpdateCartItemParams['options']) => {
    dispatch({ type: 'change-options', id, options: options || [] })
  }, [])

  const cartProps = {
    editable,
    data: editable ? editedCartItems : cartItems,
    onRemove: handleRemoveItem,
    onQtyChange: handleQtyChange,
    onPackagingChange: handlePackagingChange,
    onSeedTreatmentChange: handleSeedTreatmentChange,
    onOptionsChange: handleOptionsChange,
    emptyText: t('orders.emptyOrder'),
  }

  const handleSaveClick = useCallback(() => {
    if (order) {
      track(ProfileEvents.OrderEditSaveTap)
      updateAction(order.id, { items: editedCartItems.map(mapCartItemsToOrderItems), revision: order.revision })
    }
  }, [editedCartItems, order, track, updateAction])

  useEffect(() => {
    if (prevUpdateProgress.current !== Progress.SUCCESS && updateProgress === Progress.SUCCESS) {
      setEditable(false)
    }
  }, [updateProgress, prevUpdateProgress])

  const handleCancelClick = useCallback(() => {
    track(ProfileEvents.OrderEditCancelTap)
    setEditable(false)
    dispatch({ type: 'reset', data: cartItems })
  }, [cartItems, track])

  const handleRefresh = useCallback(() => {
    setEditable(false)
    dispatch({ type: 'reset', data: cartItems })
    if (order) {
      updateAction(order?.id, {})
    }
  }, [cartItems, order, updateAction])

  const handleCancelOrderClick = useCallback(() => {
    track(ProfileEvents.OrderCancelTap)
    cancelOrderAction(order)
  }, [cancelOrderAction, track, order])

  const handleConfirmOrder = useCallback(() => {
    if (order) {
      const redirectPath = generateCountryPath(Routes.Orders)
      updateAction(
        order.id,
        {
          interaction: {
            ...order.interaction,
            confirmed_by_farmer: true,
          },
          status: OrderStatus.Confirmed,
        },
        redirectPath,
      )
    }
  }, [generateCountryPath, order, updateAction])

  const handleBack = useCallback(() => {
    history.push(generateCountryPath(Routes.Orders))
  }, [generateCountryPath, history])

  const canRepeatOrder = useMemo(
    () => !cartItems.every(item => itemsInCart.find(i => i.product_id === item.product_id)),
    [cartItems, itemsInCart],
  )

  const repeatButtonDisabled = useMemo(() => {
    const inProgress = addItemsProgress === Progress.WORK || refreshProgress === Progress.WORK
    return !canRepeatOrder || inProgress
  }, [addItemsProgress, canRepeatOrder, refreshProgress])

  const canAddItemToCart = useCallback(
    (item: CartItem) => {
      const isActive = item.product.status === Status.Active
      const inStock = !item.product.is_out_of_stock
      const inCart = itemsInCart.find(i => i.product_id === item.product_id)

      return isActive && inStock && !inCart
    },
    [itemsInCart],
  )

  const handleRepeatOrder = useCallback(() => {
    if (!canRepeatOrder) return

    const reorderedItems = cartItems
      .map((item: CartItem) => {
        if (!canAddItemToCart(item)) return

        return {
          producerId: item.producer_id,
          productId: item.product_id,
          qty: item.qty,
          packaging: item.packaging || null,
          seedTreatment: item.seed_treatment_id,
          options: item.options,
        }
      })
      .filter(Boolean)

    if (!reorderedItems.length) return
    addItemsAction(reorderedItems, order?.producer?.slug)
  }, [addItemsAction, canAddItemToCart, canRepeatOrder, cartItems, order?.producer?.slug])

  useHelmet({ title: t('orders.list.title', { id: params.orderId }) })

  if ((progress === Progress.SUCCESS && !order) || progress === Progress.ERROR) {
    return (
      <Styled.ContentWrapper>
        <SectionContainer noDivider>
          <SectionTitle onBack={handleBack}>
            <Styled.Title>{t('orders.list.title', { id: '' })}</Styled.Title>
          </SectionTitle>
        </SectionContainer>
        <Styled.Container paddedOnMobile>{t('orders.notFound')}</Styled.Container>
      </Styled.ContentWrapper>
    )
  }

  if (progress === Progress.WORK || !order) {
    return (
      <Styled.SpinnerWrapper>
        <SpinnerLayout />
      </Styled.SpinnerWrapper>
    )
  }

  const isDirty = cartItems.length && editedCartItems.length && !equals(cartItems, editedCartItems)
  const isEditAllowed =
    ![OrderStatus.Canceled, OrderStatus.Completed, OrderStatus.Deleted].includes(order.status) &&
    !checkFeatureFlag(generateActionAccessString(Sections.OrderDetails, Actions.Edit, FeatureFlagModifiers.Restricted))
  const revisionMismatch = updateProgress === Progress.ERROR

  const isCancelOrderButtonVisible = !checkFeatureFlag(
    generateButtonAccessString(Sections.OrderDetails, Buttons.CancelOrder, FeatureFlagModifiers.Hidden),
  )

  const cancelOrderBtnJSX = isCancelOrderButtonVisible &&
    (order.status === OrderStatus.New || order.status === OrderStatus.Changed) && (
      <RemovePopover
        onRemove={handleCancelOrderClick}
        text={t('common:confirmCancelOrder')}
        position="below"
        cancelText={t('common:no')}
        submitText={t('common:yesCancel')}
      >
        <Styled.CancelButton iconColor={theme.color.secondary200} hideTextOnMobile Icon={IconCross}>
          {t('common:cancel')}
        </Styled.CancelButton>
      </RemovePopover>
    )

  return (
    <Styled.ContentWrapper>
      <SectionContainer noDivider>
        <SectionTitle onBack={handleBack} backButton={!editable}>
          <Styled.Title>
            {editable ? t('orders.list.titleEdit', { id: order.slug }) : t('orders.list.title', { id: order.slug })}
          </Styled.Title>
          {/* TODO restore order editing */}
          {!editable && isEditAllowed && (
            <Styled.EditButton
              Icon={IconPencil}
              hideTextOnMobile={true}
              onClick={() => {
                track(ProfileEvents.OrderEditTap)
                setEditable(true)
              }}
              data-test-id="profile-order-edit"
            >
              {t('common:edit')}
            </Styled.EditButton>
          )}
          {cancelOrderBtnJSX}
        </SectionTitle>
        <Styled.Container paddedOnMobile>
          {isMobile ? (
            <MobileCartList
              {...cartProps}
              optionsRequiredErrors={[]}
              seedTreatmentErrors={[]}
              productOptions={productOptions || []}
              showUnits={showUnits}
              isQtyDisabled={isQtyDisabled}
            />
          ) : (
            <CartList
              optionsRequiredErrors={[]}
              {...cartProps}
              seedTreatmentErrors={[]}
              productOptions={productOptions || []}
              showUnits={showUnits}
              isQtyDisabled={isQtyDisabled}
            />
          )}
          {farmerComment && <Input disabled value={farmerComment} />}
          {editable && (
            <Styled.ButtonsWrapper>
              <Button
                intent={'primary'}
                size={'big'}
                filled
                onClick={handleSaveClick}
                disabled={!isDirty || revisionMismatch}
                progress={updateProgress}
              >
                {t('common:saveChanges')}
              </Button>
              <Button
                intent={'cancel'}
                size={'big'}
                onClick={handleCancelClick}
                disabled={updateProgress === Progress.WORK}
              >
                {t('common:cancel')}
              </Button>
            </Styled.ButtonsWrapper>
          )}
          {!editable && (
            <Styled.ButtonsWrapper>
              {isPreorder ? (
                <Button
                  intent={'primary'}
                  size={'big'}
                  filled
                  onClick={handleConfirmOrder}
                  data-test-id="confirm-order-button"
                  progress={updateProgress}
                  disabled={updateProgress === Progress.WORK}
                >
                  {t('orders.orderDetails.confirmOrder')}
                </Button>
              ) : (
                <Button
                  intent={'primary'}
                  size={'big'}
                  filled
                  onClick={handleRepeatOrder}
                  disabled={repeatButtonDisabled}
                  progress={addItemsProgress}
                  data-test-id="repeat-order-button"
                >
                  {t('orders.orderDetails.repeatOrder')}
                </Button>
              )}
            </Styled.ButtonsWrapper>
          )}
        </Styled.Container>
      </SectionContainer>
      {revisionMismatch && (
        <Styled.RevisionWarning>
          <div>{t('orders.orderDetails.errors.revisionMismatch')}</div>
          <Button intent={'warning'} filled progress={progress} onClick={handleRefresh}>
            {t('orders.orderDetails.refreshOrder')}
          </Button>
        </Styled.RevisionWarning>
      )}
    </Styled.ContentWrapper>
  )
}

export default ProductOrderDetails

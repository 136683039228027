import React, { useContext, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components'
import SpecifyProductScreen from './SpecifyProductScreen'
import { IconCheck } from '@agro-club/frontend-shared'
import * as Styled from './styled'
import { CalculatedDiscountDto } from 'types/entities/discount'
import { CalculatedSkuDiscountDto } from 'modules/domain/discountsSku/types'

type ProductOption = {
  title: string
  value: string
  productId: string
}

const Wrapper = styled(Styled.Wrapper)`
  background-image: url('/img/umbrella.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 200px;
`

const Text = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  max-width: 190px;
  margin-bottom: 8px;
`

const EntryAction = styled(Styled.EntryAction)`
  color: ${({ theme, isApplied }) => (isApplied ? theme.color.accentApproving : theme.color.primary600)};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const LearnMore = styled.a`
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  margin-bottom: 12px;
`

export type SpecialOfferDiscountWidgetProps = {
  title: string
  type?: Styled.WidgetType
  options?: ProductOption[]
  onGetDiscount: (qty: number, key?: string) => void
  dataTestId?: string
  discount: CalculatedDiscountDto | CalculatedSkuDiscountDto
  learnMoreUrl?: string
  learnMoreUrlLabel?: string
}

export const SpecialOfferDiscountWidget: React.FC<SpecialOfferDiscountWidgetProps> = ({
  title,
  discount,
  learnMoreUrl,
  options = [],
  onGetDiscount,
  dataTestId,
  learnMoreUrlLabel,
}) => {
  const themeContext = useContext(ThemeContext)
  const { t } = useTranslation(['discount', 'common'])
  const [showOptions, setShowOptions] = useState(false)
  const [qtyToAdd, setQtyToAdd] = useState(0)

  const onApply = useCallback(
    (appendQty: number) => {
      const ruleOptions = options || []
      if (!ruleOptions.length) {
        onGetDiscount(appendQty)
      } else if (ruleOptions.length === 1) {
        onGetDiscount(appendQty, ruleOptions[0].value)
      } else {
        setShowOptions(true)
        setQtyToAdd(appendQty)
      }
    },
    [setShowOptions, onGetDiscount, options],
  )

  const tier = useMemo(() => (discount.tiers.length ? discount.tiers[0] : undefined), [discount])

  if (!discount || !tier) {
    return null
  }

  return (
    <Wrapper data-test-id={`widget-${dataTestId}`}>
      {!showOptions ? (
        <>
          <Styled.Title data-test-id={'special-offer-widget-title'}>{title}</Styled.Title>
          <ContentWrapper data-test-id={'tiers-container'}>
            <Text>{t('specialOfferText')}</Text>
            {learnMoreUrl && (
              <LearnMore href={learnMoreUrl} target={'blank'}>
                {learnMoreUrlLabel || t('common:learnMore')}
              </LearnMore>
            )}
            <EntryAction isApplied={tier.is_applied} onClick={() => !tier.is_applied && onApply(tier.qty_to_apply)}>
              {tier.is_applied ? (
                <>
                  <IconCheck
                    style={{ fill: themeContext.color.accentApproving, marginRight: '5px' }}
                    title={'applied'}
                    data-test-id={'check-icon'}
                  />
                  {t('applied')}
                </>
              ) : (
                `${t('addMore', { amount: tier.qty_to_apply })}`
              )}
            </EntryAction>
          </ContentWrapper>
        </>
      ) : (
        <SpecifyProductScreen
          options={options}
          qtyToAdd={qtyToAdd}
          onSelect={(key, qty) => {
            onGetDiscount(qty || 0, key)
            setShowOptions(false)
          }}
        />
      )}
    </Wrapper>
  )
}

export default SpecialOfferDiscountWidget

import React, { useMemo } from 'react'
import * as Styled from './styled'
import Breadcrumbs from 'views/ui/Breadcrumbs/Breadcrumbs'
import { useParams } from 'react-router-dom'
import { Routes } from 'views/pages/routes'
import { Progress } from 'modules/types'
import useHelmet from 'hooks/useHelmet'
import { useTranslation } from 'react-i18next'
import { useCompanyCategoryList } from 'modules/domain/collection/hooks'
import { useProducer } from 'modules/domain/producer/hooks'
import { Category, PromoInfoType } from 'modules/domain/collection/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import useLangPicker from 'hooks/useLangPicker'
import { useSelector } from 'react-redux'
import ProducerSelectors from 'modules/domain/producer/selectors'
import ErrorLayout from 'views/layouts/ErrorLayout/ErrorLayout'
import Layout404 from 'views/layouts/404/404'
import CollectionSelectors from 'modules/domain/collection/selectors'
import { Redirect } from 'react-router'
import ProducerRoutes from '../../routes'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import CategoryListEvents from './events'
import ReOrderForm from 'views/components/ReOrderForm/ReOrderForm'
import { useCountryPath } from 'hooks/useCountryPath'
import styled from 'styled-components'
import { queries } from 'theme/theme'
import useMatchMedia from 'hooks/useMatchMedia'
import { Page } from 'modules/utils/analytics-utils/pageNames'
import { categoryToEventParams, producerToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import { Company } from 'types/entities'

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: stretch;
  flex-grow: 1;
  padding: 100px 0;
`

type PropsType = {
  categories?: Category[]
  producer?: Company
}

const List: React.FC<PropsType> = ({ categories = [], producer }: PropsType) => {
  const { track } = useAnalyticsSSR()
  const { producerSlug } = useParams<{ producerSlug: string }>()
  const generateCountryPath = useCountryPath()
  const { pick } = useLangPicker()
  return (
    <Styled.List>
      {!!categories?.length &&
        categories.map(category => {
          const image = category.images[0]
          return (
            <Styled.Item
              key={category.id}
              to={generateCountryPath(Routes.SubCategoryList, { producerSlug, categorySlug: category.slug })}
              data-test-id={'category-card'}
              onClick={() => track(CategoryListEvents.CategoryItemClick, categoryToEventParams(producer, category))}
            >
              {image ? <Styled.ItemImage src={image} /> : <Styled.ItemImageStub />}
              <div>
                <Styled.ItemTitle>{pick(category.title_i18n)}</Styled.ItemTitle>
                <Styled.ItemDescription>{pick(category.description_i18n)}</Styled.ItemDescription>
                {!!category.discount_text && <Styled.DiscountBadge text={pick(category.discount_text_i18n)} />}
              </div>
            </Styled.Item>
          )
        })}
    </Styled.List>
  )
}

const CategoryList: React.FC = () => {
  const { producerSlug } = useParams<{ producerSlug: string }>()
  const generateCountryPath = useCountryPath()
  const [producerProgress, producer] = useProducer(producerSlug)
  const producerMeta = useSelector(state => ProducerSelectors.meta(state, producerSlug))
  const [progress, categories] = useCompanyCategoryList(producerSlug)
  const promoInfo = useSelector(state =>
    CollectionSelectors.promoInfo(state, PromoInfoType.CatalogProducer, producer?.id),
  )
  const { t } = useTranslation('discountBanner')
  const isMobile = useMatchMedia(queries.mobile)

  const producerData = useMemo(() => {
    if (producerProgress === Progress.SUCCESS) {
      const { official_name, description, logo_url } = producer || {}
      return {
        name: official_name || '',
        description: description || '',
        logo: logo_url,
      }
    }
    return {}
  }, [producer, producerProgress])

  const breadcrumbs = useMemo(
    () =>
      producerData.name
        ? [
            {
              title: producerData.name,
            },
          ]
        : [],
    [producerData.name],
  )

  const promoInfoAnalyticData = {
    page: Page.CategoryList,
    ...producerToEventParams(producer),
  }

  useHelmet({ title: producerData.name, description: producerData.description })

  const loading = () => (
    <SpinnerWrapper>
      <SpinnerLayout />
    </SpinnerWrapper>
  )
  const errorUnknown = () => <ErrorLayout />
  const error404 = () => <Layout404 />

  if (producerProgress === Progress.WORK || progress === Progress.WORK || progress === Progress.IDLE) {
    return loading()
  }

  if (producerMeta.fetchProgress === Progress.ERROR) {
    if (producerMeta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (categories && categories.length === 1) {
    return (
      <Redirect
        to={generateCountryPath(ProducerRoutes.SubCategoryList, { producerSlug, categorySlug: categories[0].slug })}
      />
    )
  }

  const hasExclusiveGoods = producer?.config?.seller_config?.has_exclusive_goods

  return (
    <Styled.Wrapper>
      <Breadcrumbs items={breadcrumbs} />
      <Styled.MainContent>
        <Styled.SidebarBlock>
          <Styled.ProducerBlock>
            <Styled.ProducerImage src={producer?.logo_url || ''} />
            <Styled.ProducerName>{producer?.official_name || ''}</Styled.ProducerName>
            <Styled.ProducerDescription>
              {producer?.short_description || producer?.description || ''}
            </Styled.ProducerDescription>
          </Styled.ProducerBlock>
          {!isMobile && hasExclusiveGoods && (
            <Styled.ReOrderFormContainer>
              <ReOrderForm isStorefront={producer?.sku_orders} />
            </Styled.ReOrderFormContainer>
          )}
        </Styled.SidebarBlock>
        {promoInfo && (
          <Styled.PromoInfo
            primaryText={promoInfo ? promoInfo.title : t('primary')}
            secondaryText={promoInfo ? promoInfo.description : t('secondary')}
            modalText={promoInfo && promoInfo.message ? promoInfo.message.description : t('modalText')}
            modalTitle={promoInfo && promoInfo.message ? promoInfo.message.title : ''}
            size={'large'}
            styles={promoInfo.styles}
            analyticData={promoInfoAnalyticData}
          />
        )}
        {isMobile && hasExclusiveGoods && (
          <Styled.ReOrderFormContainer>
            <ReOrderForm isStorefront={producer?.sku_orders} />
          </Styled.ReOrderFormContainer>
        )}
        <List categories={categories} producer={producer} />
      </Styled.MainContent>
    </Styled.Wrapper>
  )
}

export default CategoryList

import React, { useMemo } from 'react'
import { Breadcrumbs as UIBreadcrumbs } from '@agro-club/frontend-shared'

import Link from 'views/components/Link/Link'
import useBreadcrumbs from 'hooks/useBreadcrumbs'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useAnalyticDataFromParams from 'hooks/useAnalyticDataFromParams'

type BreadcrumbType = {
  path?: string
  title: string | React.ReactElement
}

type PropsType = {
  items?: BreadcrumbType[]
}

enum AnalyticsEvent {
  BreadCrumbs = 'bread_crumbs',
}

const Breadcrumbs: React.FC<PropsType> = ({ items = [] }: PropsType) => {
  const breadcrumbs = useBreadcrumbs()
  const { track } = useAnalyticsSSR()
  const analyticData = useAnalyticDataFromParams()

  const breadcrumbsWithLinks = useMemo(() => {
    return breadcrumbs.map(item => ({
      ...item,
      title: item.path ? <Link to={item.path}>{item.title}</Link> : item.title,
    }))
  }, [breadcrumbs])

  const handleAnalytic = (item: BreadcrumbType) => {
    if (typeof item.title === 'string') {
      track(AnalyticsEvent.BreadCrumbs, { title: item.title, ...analyticData })
    } else {
      track(AnalyticsEvent.BreadCrumbs, { title: item.title.props.children, ...analyticData })
    }
  }

  const itemsWithLinks = useMemo(() => {
    return items.map(item => {
      return {
        ...item,
        title: item.path ? <Link to={item.path}>{item.title}</Link> : item.title,
      }
    })
  }, [items])

  return (
    <div data-test-id="breadcrumbs">
      <UIBreadcrumbs breadcrumbs={breadcrumbsWithLinks} items={itemsWithLinks} onClick={handleAnalytic} />
    </div>
  )
}

export default Breadcrumbs

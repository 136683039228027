import { ProductOptions } from 'modules/domain/productOptions/types'
import { SkuOption } from 'modules/domain/sku/types'
import { useTranslation } from 'react-i18next'
import useLangPicker from './useLangPicker'

const useSkuOptions = (skuOptions: SkuOption[], productOptions: ProductOptions[]) => {
  const { t } = useTranslation('productOptions')
  const { pick } = useLangPicker()

  return skuOptions
    .map(({ option_id, params }) => {
      const option = productOptions.find(opt => opt.id === option_id)
      return {
        id: option?.id,
        type: t(`types.${option?.type}`),
        title: pick(option?.title_i18n),
        params,
      }
    })
    .filter(o => !!o.title)
}

export default useSkuOptions

import useApiService from 'hooks/useApiService'
import { Progress } from 'modules/types'
import { useEffect, useState } from 'react'
import { getSkuById } from './managers'
import { Sku } from './types'

export const useSkuById = (id?: string): [Progress, Sku | null] => {
  const apiService = useApiService()
  const [progress, setProgress] = useState(Progress.IDLE)
  const [sku, setSku] = useState<Sku | null>(null)

  useEffect(() => {
    if (!apiService || !id) return
    setProgress(Progress.WORK)

    getSkuById(apiService)(id).then(response => {
      setSku(response)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return [progress, sku]
}

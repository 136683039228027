import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Progress, ResourceHook } from 'modules/types'
import ProductOptionsActions from './duck'
import ProductOptionsSelectors from './selectors'
import { ProductOptions } from './types'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useProductOptionsList: ResourceHook<ProductOptions[]> = () => {
  const progress = useSelector(ProductOptionsSelectors.listFetchProgress)
  const list = useSelector(ProductOptionsSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(ProductOptionsActions.listRequested, page ? { page } : {})

  useDidMount(() => {
    if (progress === Progress.IDLE) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useProductOptions: ResourceHook<ProductOptions> = (id: string) => {
  const meta = useSelector(state => ProductOptionsSelectors.meta(state, id))
  const item = useSelector(state => ProductOptionsSelectors.item(state, id))
  const fetchAction = useAction(ProductOptionsActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useProductOptionsById: ResourceHook<ProductOptions> = (id: string) => {
  const meta = useSelector(state => ProductOptionsSelectors.meta(state, id))
  const item = useSelector(state => ProductOptionsSelectors.item(state, id))
  const fetchAction = useAction(ProductOptionsActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}

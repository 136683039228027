import axios, { AxiosError, AxiosInstance, AxiosResponse, Method } from 'axios'
import { TokenService } from 'service/token/interface'
import { stringify } from 'query-string'
import { END } from 'redux-saga'
import HttpStatuses from 'http-status-codes'
import { BadRequestError, NotFoundError, UnauthorizedError, ValidationError } from 'service/api/errors'
import { ApiService as IApiService, RequestBody, RequestOptions, UrlParams } from 'service/api/interface'
import { refreshToken as refreshTokenPair } from 'modules/domain/auth/managers'
import { EventEmitter } from 'events'
import { Dict, makeRefreshTokenAndRetry } from '@agro-club/frontend-shared'

const handleApiErrors = (error: AxiosError) => {
  const status = error.response?.status

  switch (status) {
    case HttpStatuses.NOT_FOUND:
      throw new NotFoundError(error)

    case HttpStatuses.BAD_REQUEST:
      throw new BadRequestError(error)

    case HttpStatuses.UNAUTHORIZED:
      throw new UnauthorizedError(error)

    case HttpStatuses.UNPROCESSABLE_ENTITY:
      throw new ValidationError(error)

    default:
      throw new Error('Server error. ' + error.response?.data.detail)
  }
}

export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const formHeaders = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
}

const bodyToForm = (params: RequestBody): FormData => {
  return !(params instanceof FormData)
    ? Object.keys(params)
        .filter(key => Boolean(params[key]))
        .reduce((form, key) => {
          form.append(key, params[key])
          return form
        }, new FormData())
    : params
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}
class ApiService extends EventEmitter implements IApiService {
  private runningRequests: Dict<Promise<unknown> | undefined> = {}
  private cancelTokenMap = new WeakMap<Promise<unknown>, () => void>()
  private readonly axios: AxiosInstance
  private lang = 'en'
  private tokenService: TokenService
  private refreshAndRetry: ReturnType<typeof makeRefreshTokenAndRetry>
  constructor({
    tokenService,
    baseURL,
    license,
    lang,
  }: {
    tokenService: TokenService
    baseURL: string
    license: string
    lang?: string
  }) {
    super()
    this.tokenService = tokenService
    this.lang = lang || this.lang
    let headers = {}

    if (license) {
      headers = { 'WL-LICENSE': license }
    }

    this.axios = axios.create({
      baseURL,
      headers,
    })
    this.axios.interceptors.request.use(
      config => {
        this.emit('request', config)
        return config
      },
      error => {
        this.emit('requestError', error)
        return Promise.reject(error)
      },
    )
    this.axios.interceptors.response.use(
      response => {
        this.emit('response', response)
        return response
      },
      error => {
        this.emit('responseError', error)
        return Promise.reject(error)
      },
    )

    this.refreshAndRetry = makeRefreshTokenAndRetry({
      getRefreshToken: this.tokenService.getRefreshToken,
      refreshTokenPair: refreshTokenPair(this),
      client: this.axios,
      saveAccessToken: this.tokenService.saveAccessToken,
      saveRefreshToken: this.tokenService.saveRefreshToken,
      clearAccessToken: this.tokenService.clearToken,
      clearRefreshToken: this.tokenService.clearRefreshToken,
    })

    this.axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        if (error.response?.status !== HttpStatuses.UNAUTHORIZED || error.response?.config.url?.includes('/auth/'))
          throw error
        return this.refreshAndRetry(error)
      },
    )
  }

  private performRequest<T extends unknown>(
    method: Method,
    lang: string,
    url: string,
    params: UrlParams | null,
    _body: RequestBody | null,
    options: RequestOptions = {},
  ): Promise<T> {
    const sagaEmitter = options.sagaEmitter ? options.sagaEmitter : noop
    const cancelToken = axios.CancelToken.source()
    const body = _body ? (options.multipart ? bodyToForm(_body) : _body) : undefined
    const headers = options.multipart ? formHeaders : defaultHeaders
    const token = this.tokenService.getAccessToken()
    const uuid = this.tokenService.getUuid()

    const authHeaders: { [key: string]: string } = {}
    if (token) {
      authHeaders['Authorization'] = `Token ${token}`
    }

    if (uuid) {
      authHeaders['X-UUID'] = uuid
    }

    const optionsAsString = JSON.stringify({ method, lang, url, params, body: _body, options })
    if (this.runningRequests[optionsAsString]) {
      return this.runningRequests[optionsAsString] as Promise<T>
    }

    const request = this.axios({
      headers: {
        ...options.headers,
        ...headers,
        ...authHeaders,
        'Accept-Language': lang,
      },
      method,
      url,
      params,
      data: body,
      onUploadProgress: ({ total, loaded }) => sagaEmitter(Math.round((loaded * 100) / total)),
      cancelToken: cancelToken.token,
      responseType: options.responseType || 'json',
      paramsSerializer: params => stringify(params),
    })
      .then(res => {
        sagaEmitter(END)
        return res.data
      })
      .catch(err => {
        sagaEmitter(END)
        return handleApiErrors(err)
      })
      .finally(() => {
        if (this.runningRequests[optionsAsString]) delete this.runningRequests[optionsAsString]
      })

    this.runningRequests[optionsAsString] = request

    this.cancelTokenMap.set(request, () => cancelToken.cancel())
    return request
  }

  get<T>(path: string, params?: UrlParams, options?: RequestOptions) {
    return this.performRequest<T>('get', this.lang, path, params || null, null, options)
  }
  post<T>(path: string, body?: RequestBody, options?: RequestOptions) {
    return this.performRequest<T>('post', this.lang, path, null, body || null, options)
  }
  put<T>(path: string, body?: RequestBody, options?: RequestOptions) {
    return this.performRequest<T>('put', this.lang, path, null, body || null, options)
  }
  delete<T>(path: string, body?: RequestBody, options?: RequestOptions) {
    return this.performRequest<T>('delete', this.lang, path, null, body || null, options)
  }
  patch<T>(path: string, body?: RequestBody, options?: RequestOptions) {
    return this.performRequest<T>('patch', this.lang, path, null, body || null, options)
  }
  cancelRequest(req: Promise<unknown>) {
    const cancelHandler = this.cancelTokenMap.get(req)
    cancelHandler && cancelHandler()
  }

  setLanguage(lang?: string) {
    this.lang = lang || 'en'
  }
}

export default ApiService

import { Flag, IconUser, SimpleSelect } from '@agro-club/frontend-shared'
import { GroupBase, SelectComponentsConfig } from 'react-select'
import useAnalyticDataFromLocation from 'hooks/useAnalyticDataFromLocation'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useCookieValue from 'hooks/useCookieValue'
import { useCountryPath } from 'hooks/useCountryPath'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import useWhiteLabelConfig from 'hooks/useWhiteLabelConfig'
import useWLFeatureFlags from 'hooks/useWLFeatureFlags'
import { COOKIE_KEYS } from 'modules/constants'
import AuthSelectors from 'modules/domain/auth/selectors'
import CartSelectors from 'modules/domain/cart/selectors'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { components as selectComponents, OptionProps } from 'react-select'
import Link from 'views/components/Link/Link'
import { Routes } from 'views/pages/routes'
import CartButton from '../CartButton/CartButton'
import HeaderEvents from './events'
import * as Styled from './styles'
import { theme } from 'theme/theme'

const ProfileButton = () => {
  const generateCountryPath = useCountryPath()
  const { t } = useTranslation('header')
  const profile = useSelector(AuthSelectors.profile)
  const userName = [profile?.first_name, profile?.last_name].filter(Boolean).join(' ')
  const { track } = useAnalyticsSSR()
  const analyticData = useAnalyticDataFromLocation()
  return (
    <Styled.IconButton
      aria-label={t('profileLinkLabel')}
      onClick={() => track(HeaderEvents.UpperlineClick, { type: 'profile', ...analyticData })}
      to={generateCountryPath(Routes.Profile)}
      Icon={IconUser}
      hideTextOnMobile
      iconColor={theme.color.accentApproving100}
      className="primaryAction"
    >
      {userName}
    </Styled.IconButton>
  )
}

const SignInButton = () => {
  const { t } = useTranslation(['common', 'header'])
  const { track } = useAnalyticsSSR()
  const analyticData = useAnalyticDataFromLocation()
  const location = useLocation()
  const generateCountryPath = useCountryPath()

  const locationWithState = {
    pathname: generateCountryPath(Routes.SignIn),
    state: {
      from: location.pathname,
    },
  }

  return (
    <Styled.IconButton
      aria-label={t('header:signInLinkLabel')}
      onClick={() => track(HeaderEvents.UpperlineClick, { type: 'signin', ...analyticData })}
      to={locationWithState}
      Icon={IconUser}
      hideTextOnMobile
      iconColor={theme.color.accentApproving100}
      className="primaryAction"
    >
      {t('signIn')}
    </Styled.IconButton>
  )
}

const Buttons = () => {
  const count = useSelector(CartSelectors.count)
  const countSku = useSelector(CartSkuSelectors.count)
  const countAll = useMemo(() => count + countSku, [count, countSku])
  const isAuthorized = useSelector(AuthSelectors.isAuthenticated)
  const checkFeatureFlag = useWLFeatureFlags()

  const isCountriesEnabled = checkFeatureFlag(generateCustomFeatureFlag('header', 'showCountrySelect'))

  return (
    <Styled.ButtonsWrapper>
      {/* <Styled.Button icon={SearchIcon} /> */}
      {isCountriesEnabled && <CountrySelect />}
      {isAuthorized ? <ProfileButton /> : <SignInButton />}
      <CartButton>
        {!!countAll && <Styled.CountBadge data-test-id={'cart-count-badge'}>{countAll}</Styled.CountBadge>}
      </CartButton>
    </Styled.ButtonsWrapper>
  )
}

const CountryOptionItem = ({ data, children, ...props }: OptionProps<any>) => (
  <selectComponents.Option {...props} data={data}>
    <Styled.CountryOptionWrapper>
      <Flag code={data.id} />
      <Styled.OptionTextWrapper>{children}</Styled.OptionTextWrapper>
    </Styled.CountryOptionWrapper>
  </selectComponents.Option>
)

const CountryControl = ({ children, ...props }: any) => (
  <Styled.CountrySelectControl {...props}>{children}</Styled.CountrySelectControl>
)

const CountrySelectSingleValue = ({ children, data, ...props }: any) => (
  <Styled.CountrySelectValue {...props} data={data}>
    {data.id && (
      <>
        <Flag code={data.id} />
        <Styled.OptionTextWrapper>{children}</Styled.OptionTextWrapper>
      </>
    )}
  </Styled.CountrySelectValue>
)

const CountrySelect = () => {
  const detectedCountry = useDetectedCountry()
  const [ipCountry] = useCookieValue(COOKIE_KEYS.ipCountry)
  const [dismissed, setDismissed] = useCookieValue(COOKIE_KEYS.countrySuggestionDismissed)
  const { t } = useTranslation(['header', 'common'])
  const { track } = useAnalyticsSSR()
  const { config } = useWhiteLabelConfig()

  const countries = useMemo(
    () =>
      config?.available_countries?.map(country => ({ id: country, title: t(`common:country.full.${country}`) })) ?? [],
    [config, t],
  )

  const generateCountryPath = useCountryPath()

  const onChangeCountry = useCallback(
    (country: string) => {
      const path = generateCountryPath(Routes.Root, { country })
      window.location.replace(path)
      track(HeaderEvents.CountryChoice, { choice: country })
    },
    [generateCountryPath, track],
  )
  const ipCountryOption = useMemo(() => countries.find(c => ipCountry && c.id === ipCountry.toUpperCase()), [
    ipCountry,
    countries,
  ])
  const detectedCountryOption = useMemo(
    () => countries.find(c => detectedCountry && c.id === detectedCountry.toUpperCase()),
    [detectedCountry, countries],
  )

  if (!countries.length) return null

  const suggestCountrySwitch =
    ipCountry && ipCountryOption && detectedCountry.toUpperCase() !== ipCountry.toUpperCase() && !dismissed

  const selectComponents: SelectComponentsConfig<any, any, GroupBase<unknown>> = {
    Option: CountryOptionItem,
    SingleValue: CountrySelectSingleValue,
    Control: CountryControl,
    IndicatorsContainer: () => null,
  }

  return (
    <Styled.CountrySelectWrapper>
      <SimpleSelect
        value={detectedCountry}
        options={countries}
        customStyles={{
          menu: provided => ({
            ...provided,
            width: 'auto',
          }),
        }}
        minWidth="0"
        onChange={onChangeCountry}
        components={selectComponents}
        isSearchable={false}
      />
      <Styled.CountrySuggestionPopoverBody
        show={suggestCountrySwitch}
        cancelText={t('stayInCountry', { country: detectedCountryOption?.title })}
        acceptText={t('switchToCountry', { country: ipCountryOption?.title })}
        onAccept={() => onChangeCountry(ipCountry)}
        onCancel={() => setDismissed(1)}
      />
    </Styled.CountrySelectWrapper>
  )
}

const Header: React.FC = () => {
  const { t } = useTranslation(['common', 'header'])
  const { config: wlConfig } = useWhiteLabelConfig()
  const logoUrl = wlConfig?.logo_url
  const websiteUrl = wlConfig?.company_website_url
  const generateCountryPath = useCountryPath()
  const { track } = useAnalyticsSSR()
  const analyticData = useAnalyticDataFromLocation()

  const handleLogoClick = () => {
    track(HeaderEvents.UpperlineClick, { type: 'home', ...analyticData })
  }

  const logoJSX = logoUrl ? <Styled.WhiteLabelLogo src={logoUrl} /> : <Styled.AgroClubLogo />
  const linkWrap = websiteUrl => {
    if (websiteUrl) {
      return (
        <a onClick={handleLogoClick} href={websiteUrl} target="_blank" rel="noopener noreferrer">
          {logoJSX}
        </a>
      )
    } else {
      return (
        <Link
          onClick={handleLogoClick}
          to={generateCountryPath(Routes.Root)}
          aria-label={t('header:mainPageLinkLabel')}
        >
          {logoJSX}
        </Link>
      )
    }
  }
  return (
    <Styled.Wrapper>
      {linkWrap(websiteUrl)}
      {/*</Link>*/}
      {/* {isMobile ? <MobileNav /> : <Nav />} */}
      {/*{!isMobile && <LangSwitch />}*/}
      <Buttons />
    </Styled.Wrapper>
  )
}

export default Header

import {
  Button,
  IconQuestion,
  NumberInput,
  OptionalNumber,
  Progress,
  SectionBody,
  SectionContainer,
  Tooltip,
  useAction,
} from '@agro-club/frontend-shared'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useCountryPath } from 'hooks/useCountryPath'
import useLangPicker from 'hooks/useLangPicker'
import CartSkuActions from 'modules/domain/cartSku/duck'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { PackageTypes } from 'modules/domain/packageTypes/types'
import { useProducer } from 'modules/domain/producer/hooks'
import { ProductOptions as ProductOptionsType } from 'modules/domain/productOptions/types'
import { cardToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import { getPrettyPrice } from 'modules/utils/helpers'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { Currency } from 'types/entities'
import Link from 'views/components/Link/Link'
import { SelectLabel } from 'views/components/Select/Select'
import SkuOptionsMatcher from 'views/components/SkuOptionsMatcher/SkuOptionsMatcher'
import { Routes } from 'views/pages/routes'
import { HowItWorksButton } from 'views/ui/HowItWorks/HowItWorks'
import { ChangeQtyType } from '../../constants'
import { AdderWidgetEvents } from '../../events'
import OutOfStockWidget from '../OutOfStockWidget'
import { BestOfferSuggestion } from '../suggestions/BestOfferSuggestion'
import { DiscountSuggestion } from '../suggestions/DiscountSuggestion'
import { AdderWidgetProps } from './AdderWidgetManager'
import { BaseUnits } from '../BaseUnits'
import { useCurrentSeasonOnce } from 'modules/domain/season/hooks'

const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-gap: 16px;
  & > a {
    display: flex;
    flex-direction: column;
  }
`

const Quantity = styled(NumberInput)`
  margin-bottom: 16px;
  width: 100% !important;
`

const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${props => props.theme.color.secondary200};
`

const SectionHint = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
  margin-top: 5px;
  margin-bottom: 15px;
`

const MaxQtyHint = styled.div`
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: ${props => props.theme.color.backgroundBase};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 8px;
  margin-top: 8px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

const MinMaxContainer = styled.div`
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-top: -10px;
  margin-bottom: 8px;
`

const LegalNotesSectionContainer = styled.div`
  position: relative;
  border-radius: 8px;
  margin: 16px -16px 0;
  padding: 12px 16px 16px;
  background-color: ${props => props.theme.color.accentDestructive50};
`

const LegalNotesSectionHint = styled(SectionHint)`
  margin-bottom: 0;
`

const LegalNotesDescriptionTooltipContent = styled.div`
  width: 260px;
`

const LegalNotesQuestionMark = styled(IconQuestion)`
  position: absolute;
  fill: ${props => props.theme.color.onSurfaceMidEmphasys};
  right: 10px;
  top: 10px;
`

export const TitleStyled = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  text-align: center;
`

export const PriceStyled = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin: 0;
  text-align: center;
`
export const HowItWorksButtonStyled = styled(HowItWorksButton)`
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
`

export const CustomSectionBody = styled.div`
  padding: 16px 24px 24px;
`

export const QuantityWrapper = styled.div`
  margin-top: 12px;
`

export const LegalNotes: React.FC<{ text: string; description: string; title: string }> = ({
  text,
  title,
  description,
}) => {
  return (
    <Tooltip
      visibleOnHover={true}
      tooltipContent={<LegalNotesDescriptionTooltipContent>{description}</LegalNotesDescriptionTooltipContent>}
      temporary={true}
    >
      <LegalNotesSectionContainer>
        <SectionTitle>{title}</SectionTitle>
        <LegalNotesSectionHint>{text}</LegalNotesSectionHint>
        <LegalNotesQuestionMark />
      </LegalNotesSectionContainer>
    </Tooltip>
  )
}

const MinMax: React.FC<{ min?: number; max?: number }> = ({ min, max }) => {
  const { t } = useTranslation('order')
  const content = useMemo(
    () => [min && t('minPcs', { count: min }), max && t('maxPcs', { count: max })].filter(Boolean).join('; '),
    [min, max, t],
  )
  if (!min && !max) {
    return null
  }

  return <MinMaxContainer>{content}</MinMaxContainer>
}

const StyledHint = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

type AdderDetailsProps = AdderWidgetDesktopProps

const AdderDetails: React.VFC<AdderDetailsProps> = ({
  card,
  sku,
  cartItem,
  qty,
  packageId,
  options,
  productOptions,
  packageTypes,
  progress,
  onQtyChange,
  onSkuMatched,
  onPackageTypeChange,
  onOptionsChange,
  isQtyDisabled,
  discounts,
  currentDiscountAmountPerBag = 0,
  nextLvlQuantity = 1,
  bestOffer,
}) => {
  const params = useParams<{ producerSlug: string; productOrCardSlug: string }>()
  const { track } = useAnalyticsSSR()
  const [, seller] = useProducer(params.producerSlug)
  const hasSimilarProductInCart = useSelector(state => CartSkuSelectors.hasCategory(state, card.subcategory_id || ''))
  const { pick } = useLangPicker()
  const [seasonProgress, season] = useCurrentSeasonOnce({ companies_id: seller?.id })

  const { order_now_hint_i18n } = { ...seller?.config?.seller_config }

  const hasDiscounts = Boolean(discounts && discounts.length)
  const { t } = useTranslation('order')
  const maxQty = OptionalNumber(sku?.max_qty)
  const minQty = OptionalNumber(sku?.min_qty)

  const isMaxQtyReached = !!(maxQty && qty && qty >= maxQty)

  const formatPrice = useCallback(
    (price: number | string) => getPrettyPrice(price, seller?.currency || Currency.CAD, sku?.price_type),
    [seller?.currency, sku?.price_type],
  )

  const handleAddDiscountSuggestionQty = (addQty: number) => {
    const newQty = (qty || 0) + addQty / (sku?.params.package.conversion_rate ?? 1)
    track(AdderWidgetEvents.ChangeProductQty, {
      ...cardToEventParams(card),
      type: ChangeQtyType.AddMore,
      quantity: newQty,
    })
    onQtyChange(newQty)
  }

  const isFake = Boolean(cartItem?.is_fake)
  const inTruck = cartItem && !cartItem?.is_fake

  return (
    <>
      {order_now_hint_i18n && <StyledHint>{pick(order_now_hint_i18n)}</StyledHint>}
      {<TitleStyled>{sku?.is_out_of_stock ? t('outOfStock') : t('orderNow')}</TitleStyled>}
      <StyledHint>
        {t('common:season')}: {seasonProgress === Progress.SUCCESS && season?.title}
      </StyledHint>
      {sku?.is_out_of_stock && <OutOfStockWidget card={card} />}
      {sku?.price && <PriceStyled>{formatPrice(sku?.price)}</PriceStyled>}
      <SkuOptionsMatcher
        sku_package_id={packageId}
        sku_options={options}
        availableSku={card.sku_resp}
        productOptions={productOptions}
        packageTypes={packageTypes}
        progress={progress}
        onSkuMatched={onSkuMatched}
        onPackageTypeChange={onPackageTypeChange}
        onOptionsChange={onOptionsChange}
      />
      <QuantityWrapper>
        <SelectLabel>{t('sections.quantity')}</SelectLabel>
        <Quantity
          disabled={!sku || sku?.is_out_of_stock || isFake || isQtyDisabled}
          min={minQty}
          max={maxQty}
          name={'quantity'}
          selectTextOnFocus
          size="medium"
          onDecrease={() =>
            track(inTruck ? AdderWidgetEvents.ChangeProductQtyInTruck : AdderWidgetEvents.ChangeProductQty, {
              ...cardToEventParams(card),
              type: ChangeQtyType.Minus,
            })
          }
          onIncrease={() =>
            track(inTruck ? AdderWidgetEvents.ChangeProductQtyInTruck : AdderWidgetEvents.ChangeProductQty, {
              ...cardToEventParams(card),
              type: ChangeQtyType.Plus,
            })
          }
          value={qty || 0}
          onChange={onQtyChange}
          testId={'item-count'}
          showNothing={!sku}
          inputStep={0.1}
        />
        <MinMax max={maxQty} min={minQty} />
        <BaseUnits qty={qty} sku={sku} />
        {isMaxQtyReached && (
          <MaxQtyHint dangerouslySetInnerHTML={{ __html: t('maxQtyHint') }} data-test-id={'max-qty-hint'} />
        )}
      </QuantityWrapper>

      {hasDiscounts ? (
        <DiscountSuggestion
          units={sku?.product?.units}
          nextLvlQty={nextLvlQuantity}
          currentDiscountAmountPerBag={currentDiscountAmountPerBag}
          isCartEmpty={!hasSimilarProductInCart}
          currency={card.seller?.currency || Currency.CAD}
          onQtyAdd={handleAddDiscountSuggestionQty}
        />
      ) : (
        <BestOfferSuggestion currency={card.seller?.currency || Currency.CAD} bestOffer={bestOffer} />
      )}
      {card.docusign_document_id && sku?.product?.legal_notes ? (
        <LegalNotes
          text={sku?.product?.legal_notes.text}
          description={sku?.product?.legal_notes.description}
          title={sku?.product?.legal_notes.title}
        />
      ) : null}
    </>
  )
}

export type AdderWidgetDesktopProps = Omit<AdderWidgetProps, 'values'> & {
  productOptions: ProductOptionsType[]
  packageTypes: PackageTypes[]
  progress: Progress
  isQtyDisabled: boolean
} & AdderWidgetProps['values']

const AlreadyAdded: React.FC<AdderWidgetDesktopProps> = ({ card, sku, qty, cartItem, ...props }) => {
  const generateCountryPath = useCountryPath()
  const { t } = useTranslation('order')
  const { track } = useAnalyticsSSR()
  const { producerSlug } = useParams<{ producerSlug: string }>()
  const progress = useSelector(CartSkuSelectors.addProgress)

  return (
    <SectionBody noGrid>
      <AdderDetails card={card} sku={sku} qty={qty} cartItem={cartItem} {...props} />
      <ButtonsContainer>
        <Link to={generateCountryPath(Routes.ProducerCart, { producerSlug })}>
          <Button
            id={'proceed-to-checkout-button'}
            data-test-id={'proceed-to-checkout-button'}
            intent={'primary-action'}
            filled={false}
            onClick={() => track(AdderWidgetEvents.GoToCheckout, { ...cardToEventParams(card), quantity: qty })}
            disabled={!sku || sku?.is_out_of_stock || progress === Progress.WORK}
            progress={progress}
          >
            {t('gotoCheckout')}
          </Button>
        </Link>
      </ButtonsContainer>
    </SectionBody>
  )
}

type NotAddedProps = Omit<AdderWidgetDesktopProps, 'cartItem'>

const NotAdded: React.FC<NotAddedProps> = ({ card, sku, qty, ...props }) => {
  const { t } = useTranslation('order')
  const addAction = useAction(CartSkuActions.itemAddRequested)
  const params = useParams<{ producerSlug: string; productOrCardSlug: string }>()
  const { track } = useAnalyticsSSR()
  const [, seller] = useProducer(params.producerSlug)
  const { how_it_works } = { ...seller?.config?.seller_config }

  const handleAdd = () => {
    if (!sku) return
    track(AdderWidgetEvents.AddToTruckClick, { ...cardToEventParams(card), quantity: qty })

    addAction({
      card,
      sku,
      seller,
      quantity: String(qty),
    })
  }

  const handleHowItWorksClick = () => {
    track(AdderWidgetEvents.HowItWorksClick, cardToEventParams(card))
  }

  return (
    <CustomSectionBody>
      <AdderDetails card={card} sku={sku} qty={qty} {...props} />
      <ButtonsContainer>
        <Button
          data-test-id={'add-to-cart-button'}
          intent={'primary-action'}
          filled
          disabled={!sku || sku?.is_out_of_stock}
          onClick={handleAdd}
          size="big"
        >
          {t('addToCart')}
        </Button>
      </ButtonsContainer>
      <HowItWorksButtonStyled items={how_it_works || []} onClick={handleHowItWorksClick} />
    </CustomSectionBody>
  )
}

const AdderWidgetDesktop: React.VFC<AdderWidgetDesktopProps> = ({ cartItem, isQtyDisabled, ...props }) => {
  const cartSkuItem = useSelector(state => CartSkuSelectors.cartEntryBySkuId(state, props.sku?.id || ''))
  const isAdded = cartSkuItem && props.sku
  const addedSkuItem = isAdded ? cartSkuItem : cartItem

  return (
    <div data-test-id="adder-widget-desktop">
      <SectionContainer noDivider>
        {isAdded ? (
          <AlreadyAdded {...props} cartItem={addedSkuItem} isQtyDisabled={isQtyDisabled} />
        ) : (
          <NotAdded {...props} isQtyDisabled={isQtyDisabled} />
        )}
      </SectionContainer>
    </div>
  )
}

export default AdderWidgetDesktop

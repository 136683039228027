import { useSelector } from 'react-redux'
import CartSelectors from 'modules/domain/cart/selectors'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { Company } from 'types/entities'
import { CartSkuInfo } from 'modules/domain/cartSku/types'
import { CartInfo } from 'modules/domain/cart/types'

const useCartInfo = (producer?: Company): CartInfo | CartSkuInfo | undefined => {
  const cartInfoProduct = useSelector(state => CartSelectors.producerCartInfo(state, producer?.slug))
  const cartInfoSku = useSelector(state => CartSkuSelectors.sellerCartInfo(state, producer?.slug))
  return producer?.sku_orders ? cartInfoSku : cartInfoProduct
}

export default useCartInfo

import React, { useCallback, useMemo } from 'react'
import * as DiscountWidgetStyles from 'views/components/DiscountWidget/styled'

import { useProductDiscounts } from 'modules/domain/product/hooks'
import { CalculatedDiscountDto } from 'types/entities/discount'
import { Product } from 'modules/domain/product/types'
import SpecialOfferDiscountWidget from 'views/components/DiscountWidget/SpecialOfferDiscountWidget'
import { Currency } from 'types/entities'
import { DiscountTypes } from 'types/entities/discount'
import GiftCampaignInfo from 'views/components/GiftCampaignInfo/GiftCampaignInfo'
import useLangPicker from 'hooks/useLangPicker'
import { QtyDiscountWidget } from 'views/components/DiscountWidget/QtyDiscountWidget'

type Props = {
  onApply: (qtyToApply: number) => void
  product: Product
}

type CampaignsDict = {
  [key: string]: CalculatedDiscountDto[]
}

const DiscountBar: React.FC<Props> = ({ onApply, product }) => {
  const [, discounts] = useProductDiscounts()
  const { pick } = useLangPicker()

  const getDiscount = useCallback(
    (qty: number) => {
      onApply(qty)
    },
    [onApply],
  )

  const monetaryDiscounts = useMemo(
    () => (discounts ? discounts.filter(d => d.rule.rule_type !== DiscountTypes.Gift) : []),
    [discounts],
  )
  const giftDiscounts = useMemo(
    () => (discounts ? discounts.filter(d => d.rule.rule_type === DiscountTypes.Gift) : []),
    [discounts],
  )

  const campaigns = useMemo(
    () =>
      monetaryDiscounts
        ? monetaryDiscounts.reduce((dict: CampaignsDict, discount: CalculatedDiscountDto) => {
            if (!discount.rule.campaign_name) return dict

            if (discount.rule.campaign_name in dict) {
              return { ...dict, [discount.rule.campaign_name]: [...dict[discount.rule.campaign_name], discount] }
            } else {
              return { ...dict, [discount.rule.campaign_name]: [discount] }
            }
          }, {})
        : [],
    [monetaryDiscounts],
  )

  const renderGiftDiscounts = () => {
    return giftDiscounts.map(giftDiscount => (
      <GiftCampaignInfo key={giftDiscount.rule.id} campaign={giftDiscount.rule} product={product} />
    ))
  }

  const renderMonetaryDiscounts = () => {
    return Object.keys(campaigns).map((campaignName, idx) => {
      //TODO refactor it and make more universal
      if (campaigns[campaignName].length && campaigns[campaignName][0].rule.slug?.includes('special-offer')) {
        const discount = campaigns[campaignName][0] as CalculatedDiscountDto

        return (
          <SpecialOfferDiscountWidget
            key={campaignName}
            dataTestId={`campaign-${idx}`}
            title={campaignName}
            learnMoreUrl={pick(campaigns[campaignName][0].rule.link_url_i18n)}
            learnMoreUrlLabel={pick(campaigns[campaignName][0].rule.link_label_i18n)}
            discount={discount}
            onGetDiscount={getDiscount}
          />
        )
      }
      return (
        <QtyDiscountWidget
          key={campaignName}
          currency={product.producer?.currency || Currency.CAD}
          dataTestId={`${idx}`}
          title={campaignName}
          discounts={campaigns[campaignName]}
          onGetDiscount={getDiscount}
          data-test-id="qty-discount-widget"
          units={product.units}
        />
      )
    })
  }

  return discounts ? (
    <DiscountWidgetStyles.WidgetContainer data-test-id="discount-bar">
      {giftDiscounts ? renderGiftDiscounts() : null}
      {monetaryDiscounts ? renderMonetaryDiscounts() : null}
    </DiscountWidgetStyles.WidgetContainer>
  ) : null
}

export default DiscountBar

import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button } from '@agro-club/frontend-shared'

import GiftImage from './gifts.png'
import GiftPopup from '../GiftPopup/GiftPopup'
import { CartItem } from 'modules/domain/cart/types'
import { CalculatedDiscountDto } from 'types/entities/discount'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { CartEvents } from 'views/components/Cart/events'
import { Company } from 'types/entities'
import { producerToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  background-color: ${props => props.theme.color.onPrimaryLight};
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  font-family: Montserrat;
`

const Image = styled.img`
  position: absolute;
  top: -8px;
  left: calc(50% - 131px); // half of max-width
  display: block;
  max-width: 262px;
  width: 100%;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 136px 16px 16px;
`

const Title = styled.h2`
  margin: 0 0 24px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.color.onPrimaryDark};
  ${props => props.theme.media.mobile`
    font-size: 16px;
    line-height: 20px;
  `}
`

type Props = {
  giftDiscounts: CalculatedDiscountDto[]
  cartItems: CartItem[]
  producer?: Company
}

const GiftWidget: React.FC<Props> = ({ giftDiscounts, cartItems, producer }) => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation('gift')
  const [modalOpen, setModalOpen] = useState(false)
  const handleCloseGiftModal = useCallback(() => setModalOpen(false), [])
  const handleOpenGiftModal = useCallback(() => {
    setModalOpen(true)
    track(CartEvents.GiftWidgetButtonClick, producerToEventParams(producer))
  }, [track, producer])

  return (
    <Wrapper>
      <Image src={GiftImage} />
      <Inner>
        <Title>{t('widgetTitle')}</Title>
        <Button intent="minor-action" size="medium" filled onClick={handleOpenGiftModal}>
          {t('widgetButton')}
        </Button>
        <GiftPopup
          giftDiscounts={giftDiscounts}
          cartItems={cartItems}
          isOpen={modalOpen}
          onClose={handleCloseGiftModal}
        />
      </Inner>
    </Wrapper>
  )
}

export default GiftWidget

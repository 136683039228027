import { Credentials } from './duck'
import { TokenResponse, Uuid, UserGdprDTO } from './types'
import { endpoints } from 'modules/endpoints'
import { ApiService } from 'service/api/interface'
import { UserProfile } from 'types/entities/userProfile'
import { ProfileDTO } from 'types/dto/userProfile'
import { TokensPair } from '@agro-club/frontend-shared'

export const requestSmsCode = (apiClient: ApiService) => (phone: string) => {
  return apiClient.post(endpoints.requestSmsCode(), { phone_number: phone })
}

export const checkSmsCode = (apiClient: ApiService) => async (phone: string, code: string): Promise<TokensPair> => {
  const res = await apiClient.post<TokenResponse>(endpoints.phoneLogin(), { phone_number: phone, code })
  return { accessToken: res.access_token, refreshToken: res.refresh_token }
}

export const obtainToken = (apiClient: ApiService) => async ({ email, password }: Credentials): Promise<TokensPair> => {
  const response = await apiClient.post<TokenResponse>(endpoints.emailLogin(), { email, password })

  return { accessToken: response.access_token, refreshToken: response.refresh_token }
}

export const refreshToken = (apiClient: ApiService) => async (token: string): Promise<TokensPair> => {
  const response = await apiClient.post<TokenResponse>(endpoints.refreshToken(), { token })
  return {
    accessToken: response.access_token,
    refreshToken: response.refresh_token,
  }
}

export const logout = (apiClient: ApiService) => async (token: string) => {
  return await apiClient.post<string>(endpoints.logout(), { token })
}

export const obtainUuid = (apiClient: ApiService) => (): Promise<Uuid> => {
  return apiClient.get<Uuid>(endpoints.getUuid())
}

export const fetchCurrentUser = (apiClient: ApiService) => () => {
  return apiClient.get<UserProfile>(endpoints.profile())
}

export const updateCurrentUser = (apiClient: ApiService) => (profile: Partial<ProfileDTO>) => {
  return apiClient.put<UserProfile>(endpoints.profile(), profile)
}

export const createUserGdprRequest = (apiClient: ApiService) => (userGdprRequest: Partial<UserGdprDTO>) => {
  return apiClient.post<UserGdprDTO>(endpoints.userGdprRequest(), userGdprRequest)
}

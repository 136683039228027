import {
  OptionalString,
  ProcoteSku,
  ProcoteWizardNode,
  ProductWizardType,
  SubmitOptionsType,
  UpdateCartItemParams,
  useAction,
} from '@agro-club/frontend-shared'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useCountryPath } from 'hooks/useCountryPath'
import { useWizardCardListOnce } from 'modules/domain/card/hooks'
import { Card } from 'modules/domain/card/types'
import CartSkuActions from 'modules/domain/cartSku/duck'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { useProducer } from 'modules/domain/producer/hooks'
import { useSkuById } from 'modules/domain/sku/hooks'
import { AnalyticsEvent } from 'modules/types'
import { cardToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import { Page } from 'modules/utils/analytics-utils/pageNames'
import React, { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Routes } from 'views/pages/routes'
import WizardEvents from '../events'

type PropsType = {
  opened: boolean
  onClose: () => void
  isMobile: boolean
  productWizard: ProcoteWizardNode | null
  wizardCard: Card
}

export const CardWizardProcote: FC<PropsType> = ({
  productWizard,
  opened,
  onClose,
  isMobile,
  wizardCard,
}: PropsType) => {
  const sellerId = wizardCard.seller_id
  const subcategoryId = wizardCard.subcategory_id || ''
  const [cardId, setCardId] = useState('')
  const [skuId, setSkuId] = useState('')

  const generateCountryPath = useCountryPath()
  const history = useHistory()
  const { producerSlug } = useParams<{
    producerSlug: string
    productOrCardSlug: string
  }>()
  const fakeItemAddAction = useAction(CartSkuActions.itemAddRequested)
  const [, seller] = useProducer(sellerId)
  const updateItemAction = useAction(CartSkuActions.itemUpdateRequested)
  const cartItem = useSelector(state => CartSkuSelectors.cartEntryBySkuId(state, skuId))
  const { track } = useAnalyticsSSR()

  const [, cards = []] = useWizardCardListOnce(sellerId, subcategoryId)
  const [, sku] = useSkuById(skuId)

  const card = useMemo(() => cards.find(card => card.id === cardId), [cards, cardId])

  const handleGoToCart = () => {
    history.push(generateCountryPath(Routes.ProducerCart, { producerSlug }))
  }

  const handleAddToCart = (
    _: string,
    __: string,
    options?: SubmitOptionsType<ProductWizardType.Procote, true> | undefined,
  ) => {
    if (seller && card && sku) {
      fakeItemAddAction({ ...options, seller, quantity: String(options?.qty ?? 1), card, sku })
    }
  }

  const handleUpdateCart = (params: UpdateCartItemParams<ProductWizardType.Procote, true>) => {
    cartItem?.id &&
      updateItemAction(cartItem.id, {
        ...params,
        quantity: OptionalString(params.quantity),
        seller_id: sellerId,
      })
  }

  const handleSendAnalyticsTrack = additionalParams => {
    track(WizardEvents.WizardClick, {
      ...cardToEventParams(card),
      ...additionalParams,
    })
  }

  const handleSendAnalyticsPage = additionalParams => {
    track(AnalyticsEvent.Page, {
      name: Page.ProcoteWizardNode,
      ...cardToEventParams(card),
      ...additionalParams,
    })
  }

  const handleResultCardChange = (cardId: string, skuId: string) => {
    setCardId(cardId)
    setSkuId(skuId)
  }

  const wizardJSX = productWizard ? (
    <ProcoteSku
      allowChooseExactCard
      productWizard={productWizard}
      opened={opened}
      isMobile={isMobile}
      onClose={onClose}
      handleGoToCart={handleGoToCart}
      handleMarketAddProduct={handleAddToCart}
      handleMarketUpdateProduct={handleUpdateCart}
      onResultCardChange={handleResultCardChange}
      cards={cards}
      onSendAnalyticsTrack={handleSendAnalyticsTrack}
      onSendAnalyticsPage={handleSendAnalyticsPage}
    />
  ) : null

  return <>{wizardJSX}</>
}

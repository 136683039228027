import React, { FC, useCallback, useState } from 'react'
import { DuckFoot, useAction } from '@agro-club/frontend-shared'
import CartActions from 'modules/domain/cart/duck'
import { useSelector } from 'react-redux'
import CartSelectors from 'modules/domain/cart/selectors'
import { AnalyticsEvent, Progress } from 'modules/types'
import { useCountryPath } from 'hooks/useCountryPath'
import { useHistory } from 'react-router-dom'
import { Routes } from 'views/pages/routes'
import { useParams } from 'react-router'
import { useProduct } from 'modules/domain/product/hooks'
import { getPrettyPrice } from 'modules/utils/helpers'
import { useProducer } from 'modules/domain/producer/hooks'
import { Product } from 'types/entities/product'
import { SubmitOptionsType } from '@agro-club/frontend-shared/dist/cjs/ui/wizards/types'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import WizardEvents from '../events'
import { Page } from 'modules/utils/analytics-utils/pageNames'
import ProductSelectors from 'modules/domain/product/selectors'
import { productToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'

type PropsType = {
  opened: boolean
  onClose: () => void
  isMobile: boolean
}

export const ProductWizardDuckFoot: FC<PropsType> = ({ opened, onClose, isMobile }: PropsType) => {
  const { track } = useAnalyticsSSR()
  const productWizardData = useSelector(CartSelectors.productWizardDuckFoot)
  const progress = useSelector(CartSelectors.productWizardProgress)
  const addAction = useAction(CartActions.itemAddRequested)
  const [productId, setProductId] = useState('')
  const generateCountryPath = useCountryPath()
  const history = useHistory()
  const { producerSlug, productOrCardSlug: commonProductSlug } = useParams<{
    producerSlug: string
    productOrCardSlug: string
  }>()
  const commonProduct = useSelector(state => ProductSelectors.item(state, commonProductSlug))
  const [, item] = useProduct(productId)
  const [, producer] = useProducer(producerSlug)

  const redirectToCart = useCallback(() => {
    history.push(generateCountryPath(Routes.ProducerCart, { producerSlug }))
  }, [generateCountryPath, history, producerSlug])

  const submit = useCallback(
    (product: Product, options?: SubmitOptionsType) => producer && addAction(product.id, producer, options),
    [addAction, producer],
  )

  const handleSendAnalyticsTrack = additionalParams => {
    track(WizardEvents.WizardClick, {
      ...productToEventParams(commonProduct),
      ...additionalParams,
    })
  }

  const handleSendAnalyticsPage = additionalParams => {
    track(AnalyticsEvent.Page, {
      name: Page.ProcoteWizardNode,
      ...productToEventParams(commonProduct),
      ...additionalParams,
    })
  }

  const wizardJSX =
    progress === Progress.SUCCESS && opened ? (
      <DuckFoot
        opened={opened}
        onClose={onClose}
        isMobile={isMobile}
        productWizardData={productWizardData}
        handleProductId={setProductId}
        redirectToCart={redirectToCart}
        submit={submit}
        getPrettyPrice={getPrettyPrice}
        product={item}
        onSendAnalyticsTrack={handleSendAnalyticsTrack}
        onSendAnalyticsPage={handleSendAnalyticsPage}
      />
    ) : null

  return <>{wizardJSX}</>
}

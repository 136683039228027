import styled, { StyledProps } from 'styled-components'

export enum WidgetType {
  Total = 'totalDiscount',
  EarlyBooking = 'earlyBooking',
  CreditOffer = 'creditOffer',
  GerminatingSuccess = 'germinatingSuccess',
  Promocode = 'promocode',
}

export const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.onPrimaryLight};
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  font-family: Montserrat;
  font-style: normal;

  margin-bottom: 16px;
  padding: 16px 24px;
  ${props => props.theme.media.mobile`
    padding: 8px 12px;
  `}
`

export const Title = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;

  color: ${({ theme }) => theme.color.accentNotify};

  max-width: 136px;
  margin: 0 0 16px 0;

  ${props => props.theme.media.mobile`
    font-size: 14px;
    line-height: 20px;
  `}
`

export const EntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`

export const EntryCondition = styled.div`
  display: flex;
  flex-direction: row;

  & > :first-child {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-right: 4px;

    color: ${({ theme }) => theme.color.onPrimaryDark};
  }

  & > :nth-child(2) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
  }

  ${props => props.theme.media.mobile`
    flex-direction: column;
  `}
`

export const EntryAction = styled.div<StyledProps<{ isApplied?: boolean }>>`
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  ${({ theme, isApplied }) => ({
    color: isApplied ? theme.color.accentApproving : theme.color.accentNotify,
    cursor: isApplied ? 'default' : 'pointer',
  })}
`

export const SectionTitle = styled.h3`
  margin: 0 0 8px 0;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;

  text-transform: uppercase;

  color: ${({ theme }) => theme.color.secondary300};
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.media.mobile`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  `}
`

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  ${props => props.theme.media.mobile`
    &:not(:last-child) {
      margin-right: 16px;
      margin-bottom: 0;
    }
  `}
`

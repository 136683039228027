import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { Order, OrderDTO, OrderStatus } from 'types/order'
import { OrderListRequestFilter, OrderListRequestSorting } from './types'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { OrderState } from './types'
import { LIST_PAGE_SIZE } from 'modules/constants'

const initialState: OrderState = {
  items: {},
  ids: [],
  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  cancelOrderProgress: Progress.IDLE,
  cancelOrderError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

class OrderReducer extends ImmerReducer<OrderState> {
  listRequested(params: { filter?: OrderListRequestFilter; sorting?: OrderListRequestSorting; page?: number }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }
  listRequestSucceed(list: Order[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = arrToDict(list)
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }
  listRequestFailed() {
    this.draftState.listFetchProgress = Progress.ERROR
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK
  }
  itemRequestSucceed(item: Order) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
  }
  itemRequestFailed() {
    this.draftState.itemFetchProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addRequested(dto: OrderDTO) {
    this.draftState.addProgress = Progress.WORK
  }
  addSucceed(item: Order) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
  }
  addFailed() {
    this.draftState.addProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateRequested(id: string, order: Partial<OrderDTO>, redirectPath: string) {
    this.draftState.updateProgress = Progress.WORK
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateSkuRequested(id: string, order: Partial<OrderDTO>, redirectPath: string) {
    this.draftState.updateProgress = Progress.WORK
  }
  updateSucceed(item: Order) {
    this.draftState.items[item.id] = item
    this.draftState.updateProgress = Progress.SUCCESS
  }
  updateFailed() {
    this.draftState.updateProgress = Progress.ERROR
  }

  filterUpdated(filter: OrderListRequestFilter) {
    this.draftState.filter = filter
  }

  sortingUpdated(sorting: OrderListRequestSorting) {
    this.draftState.sorting = sorting
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
  }

  listRequestNextSucceed(list: Order[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...new Set([...this.draftState.ids, ...getIds(list)])]
  }

  listRequestNextFailed() {
    this.draftState.listFetchNextProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
  }
  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    const i = this.draftState.ids.findIndex(item => item === id)
    if (-1 === i) {
      const ids = this.draftState.ids
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }
  removeFailed() {
    this.draftState.removeProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cancelOrderRequested(order: Order) {
    this.draftState.cancelOrderProgress = Progress.WORK
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cancelSkuOrderRequested(order: Order) {
    this.draftState.cancelOrderProgress = Progress.WORK
  }

  cancelOrderSucceed(orderId: string) {
    this.draftState.cancelOrderProgress = Progress.SUCCESS
    this.draftState.items[orderId] = { ...this.draftState.items[orderId], status: OrderStatus.Canceled }
  }

  cancelOrderFailed() {
    this.draftState.cancelOrderProgress = Progress.SUCCESS
  }
}

export const OrderActions = createActionCreators(OrderReducer)
export default OrderActions
export const reducer = createReducerFunction(OrderReducer, initialState)

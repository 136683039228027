import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, IconBin, IconButton } from '@agro-club/frontend-shared'

import useLangPicker from 'hooks/useLangPicker'
import { useCountryPath } from 'hooks/useCountryPath'
import { Progress } from 'modules/types'
import { Product } from 'modules/domain/product/types'
import { theme } from 'theme/theme'
import { Routes } from 'views/pages/routes'
import BadgeNew from 'views/components/BadgeNew/BadgeNew'
import BadgeLimited from 'views/components/BadgeLimited/BadgeLimited'
import Link from 'views/components/Link/Link'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { CartEvents } from 'views/components/Cart/events'
import { giftToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'

const Wrapper = styled.article`
  height: 100%;
  padding: 8px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.color.onPrimaryLight};
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.04);
  font-family: Montserrat;
`

const ImageContainer = styled.div`
  position: relative;
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: 128px;
  border-radius: 8px;
  object-fit: cover;

  ${props => props.theme.media.custom({ maxWidth: 820 })`
    height: 160px;
  `}

  ${props => props.theme.media.custom({ maxWidth: 600 })`
    height: 220px;
  `}
`

const ImageStub = styled.div`
  position: relative;
  width: 100%;
  height: 128px;
  border-radius: 8px;
  background-color: ${props => props.theme.color.secondary50};
  opacity: 0.5;
`

const Badges = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  & > * {
    margin-left: 4px;
  }
`

const Inner = styled.div`
  padding: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const Info = styled.div`
  flex-grow: 1;
`

const Title = styled.h2`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${props => props.theme.color.textPrimary};
`

const Description = styled.p`
  margin: 4px 0 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${props => props.theme.color.textSecondary};
`

const ShortDescription = styled.p`
  margin: 8px 0 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${props => props.theme.color.textPrimary};
`

const Buttons = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
`

// TODO-999 consider extending IconButton with size & colors
const RemoveFromTruckButton = styled(IconButton)`
  min-width: 40px;
  height: 40px;
  padding: 8px;
  margin-left: 8px;
  background-color: rgba(255, 59, 48, 0.16);
  &:hover {
    background-color: ${props => props.theme.color.accentDestruct200};
  }
  &:focus {
    box-shadow: 0 0 3px ${props => props.theme.color.accentDestruct500};
  }
`

const AddToTruckButton = styled(Button)`
  width: 100%;
`

const AddedToTruck = styled.div`
  width: 100%;
  padding: 10px 24px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${props => props.theme.color.primary600};
  background-color: ${props => props.theme.color.primary50};
`

type Props = {
  product: Product
  isGiftAdded: boolean
  isGiftAvailable: boolean
  onAddGiftClick: (product: Product) => void
  onRemoveGiftClick: (cartItemId: string) => void
  loading?: boolean
}

const GiftItem: React.FC<Props> = ({
  product,
  isGiftAdded,
  isGiftAvailable,
  onAddGiftClick,
  onRemoveGiftClick,
  loading,
}) => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation('gift')
  const { pick } = useLangPicker()
  const generateCountryPath = useCountryPath()

  const url = generateCountryPath(Routes.ProductOrCardItem, {
    producerSlug: product.producer?.slug,
    categorySlug: product.category?.slug || '',
    subCategorySlug: product.subcategory?.slug || '',
    productOrCardSlug: product.slug,
  })

  const handleAddToTruckClick = () => {
    onAddGiftClick(product)
    track(CartEvents.GiftPopupGetGiftClick, giftToEventParams(product))
  }

  const addToTruckButtonDisabled = !isGiftAvailable

  return (
    <Wrapper>
      <Link to={url}>
        <ImageContainer>
          {product?.images?.length ? <Image src={product?.images[0]} /> : <ImageStub />}
          <Badges>
            {product?.is_new ? <BadgeNew /> : null}
            {product?.is_limited ? <BadgeLimited /> : null}
          </Badges>
        </ImageContainer>
      </Link>

      <Inner>
        <Info>
          <Title>{pick(product?.title_i18n) || ''}</Title>
          <Description>{pick(product?.description_i18n) || ''}</Description>
          {/* short descriptions is used as gift applying terms */}
          <ShortDescription>{pick(product?.short_description_i18n) || ''}</ShortDescription>
        </Info>

        <Buttons>
          {isGiftAdded ? (
            <>
              <AddedToTruck>{t('addedToCart')}</AddedToTruck>
              <RemoveFromTruckButton
                Icon={IconBin}
                iconColor={theme.color.accentDestructive}
                onClick={() => onRemoveGiftClick(product.id)}
              />
            </>
          ) : (
            <AddToTruckButton
              intent="primary"
              size="medium"
              filled
              onClick={handleAddToTruckClick}
              disabled={addToTruckButtonDisabled}
              progress={loading ? Progress.WORK : Progress.IDLE}
            >
              {t('addToCart')}
            </AddToTruckButton>
          )}
        </Buttons>
      </Inner>
    </Wrapper>
  )
}

export default GiftItem

import { EntityMetadata, FetchError } from 'modules/domain/types'
import { Progress } from 'modules/types'
import { LocalizedValue } from 'types/entities'
import { Dict } from 'types/generics'

export type ProductOptionsListRequestFilter = {
  type?: ProductOptionType
}

export type ProductOptionsListRequestSorting = {
  sort_field?: keyof ProductOptions
  sort_reversed?: boolean
}

export type ProductOptionsState = {
  items: Dict<ProductOptions>
  meta: Dict<EntityMetadata>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchNextProgress: Progress
  listFetchNextError: FetchError | null
  itemFetchProgress: Progress
  itemFetchError: FetchError | null

  filter: ProductOptionsListRequestFilter
  sorting: ProductOptionsListRequestSorting
}

export enum ProductOptionType {
  INOCULANT = 'inoculant',
  DELIVERY_OPTION = 'delivery_option',
  SEED_TREATMENT = 'seed_treatment',
  PRODUCT = 'product',
  ACTIVE_INGREDIENT = 'active_ingredient',
}

export type ProductOptions = {
  id: string
  created_at: string
  updated_at: string
  title?: string
  title_i18n: LocalizedValue
  type: string
  is_stackable: boolean
  params?: {
    [key: string]: any // todo: I don't know what type of option should be here
  }
}

export type ProductOptionsData = {
  type: ProductOptionType
  option_ids: string[]
  required: boolean
}

export type ProductOptionsDTO = Omit<ProductOptions, 'id' | 'created_at' | 'updated_at'> & {
  id?: string
  title_i18n: LocalizedValue
  type: string
}

export type ProductOptionsGrouped = {
  type: ProductOptionType
  required: boolean
  data: {
    id: string
    title: string
  }[]
  selectedId: string | undefined
}

import { useAnalytics } from 'use-analytics'
import env from 'env'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

let _useAnalyticsSSR = useAnalytics

if (!env.BROWSER) {
  _useAnalyticsSSR = () => ({
    page: noop,
    track: noop,
    identify: noop,
  })
}

export const useAnalyticsSSR = _useAnalyticsSSR

import { loadableReady } from '@loadable/component'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Sentry } from './sentry'
import TokenService from 'service/token/browser'
import ApiService from 'service/api/api'
import env from 'env'
import { createBrowserHistory } from 'history'
import { configureStore } from 'modules/configureStore'
import analyticsInstance from 'initAnalytics'
import rootSaga from 'modules/rootSaga'
import { boot as intercomBoot } from 'intercom/boot'
import { init as initI18n } from 'i18n'

const main = async () => {
  const tokenService = new TokenService()
  tokenService.init(window.__cookies)

  const apiService = new ApiService({
    tokenService,
    baseURL: env.API_BASE_URL,
    license: window.__wl_config.id,
    lang: window.__wl_config.default_lang,
  })
  apiService.on('response', Sentry.handleAxiosResponse)
  apiService.on('responseError', Sentry.handleAxiosError)
  apiService.on('request', Sentry.handleAxiosRequest)
  apiService.on('requestError', Sentry.handleAxiosError)
  apiService.on('error', err => console.error(err))

  if (window.Cypress) {
    window.__apiService = apiService
    window.__tokenService = tokenService
  }

  const history = createBrowserHistory({ basename: env.BASE_ROUTER_PATH })
  const [store, sagaMiddleware] = await configureStore({
    history,
    initialState: { ...(window.__initial_state__ || {}), ...(window.__cy_state_override__ || {}) },
    apiService,
    tokenService,
    analyticsInstance,
  })
  sagaMiddleware.run(rootSaga)

  await Sentry.init()
  const language = window.__wl_config.default_lang || 'en'
  intercomBoot({ app_id: window.__wl_config?.third_party_keys?.intercom_app_id })
  initI18n(language)
  ReactDOM.hydrate(
    <App store={store} history={history} wlConfig={{ config: window.__wl_config }} apiService={apiService} />,
    document.getElementById('root'),
  )
}

loadableReady(main)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

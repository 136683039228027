import styled, { css, keyframes, StyledProps } from 'styled-components'

type TabProps = StyledProps<{
  isActive?: boolean
  disabled?: boolean
}>

const fadeIn = keyframes`
  to {
    opacity: 1;
  }
`

export const TabContent = styled.div`
  opacity: 0;
  animation: ${fadeIn} 0.4s ease-in;
  animation-fill-mode: forwards;
`

export const Tabs = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(${(props: { count: number }) => props.count}, max-content);
  grid-template-rows: 72px;
  height: 100%;

  ${props => props.theme.media.mobile`
    grid-gap: 24px;
    grid-template-rows: 48px;
  `}
`

export const Tab = styled.div<TabProps>`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  color: ${(props: TabProps) => props.theme.color.secondary200};
  transition: 0.3s color, 0.3s border-bottom-color;
  position: relative;
  top: 1px;
  user-select: none;
  padding-top: 24px;

  &:hover {
    color: ${(props: TabProps) => props.theme.color.secondary300};
  }

  ${props => props.theme.media.mobile`
    font-size: 16px;
    line-height: 24px;
    padding-top: 11px;
  `}

  ${(props: TabProps) => {
    if (props.disabled) {
      return css`
        opacity: 0.5;
        pointer-events: none;
      `
    }

    if (props.isActive) {
      return css`
        color: ${props.theme.color.onSurfaceHighEmphasys};
        border-bottom-color: ${props.theme.color.primary600};
        cursor: default;

        &:hover {
          color: ${props.theme.color.onSurfaceHighEmphasys};
        }
      `
    }
  }}
`

import { ProductUnits } from 'modules/domain/product/types'
import { getPrettyPrice } from 'modules/utils/helpers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { StyledProps } from 'styled-components'
import { Currency } from 'types/entities'
import { SuggestionWrapper } from './SuggestionWrapper'

const CurrentDiscountAmount = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  text-align: center;
  margin-bottom: 8px;

  color: ${({ theme }) => theme.color.accentApproving};
`

const AddItemsButton = styled.button`
  outline: none;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  margin-bottom: 4px;

  text-align: center;
  text-decoration-line: underline;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.color.primary600};
`

const Description = styled.div<StyledProps<{ isHighestDiscountReached?: boolean }>>`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: ${({ theme, isHighestDiscountReached }) =>
    isHighestDiscountReached ? theme.color.accentApproving : theme.color.secondary300};
`

export type DiscountSuggestionProps = {
  currency: Currency
  nextLvlQty?: number
  currentDiscountAmountPerBag?: number
  onQtyAdd: (qty: number) => void
  isCartEmpty?: boolean
  units?: ProductUnits
  disabled?: boolean
}

export const DiscountSuggestion: React.FC<DiscountSuggestionProps> = ({
  currency,
  nextLvlQty = 1,
  onQtyAdd,
  currentDiscountAmountPerBag = 0,
  isCartEmpty = false,
  units,
  disabled,
}) => {
  const { t } = useTranslation(['cart', 'common'])
  const isHighestDiscountReached = nextLvlQty <= 0
  const discountAmountString = getPrettyPrice(currentDiscountAmountPerBag, currency)
  return (
    <SuggestionWrapper isHighestDiscountReached={isHighestDiscountReached}>
      {!isHighestDiscountReached ? (
        <>
          <CurrentDiscountAmount>
            {currentDiscountAmountPerBag !== 0
              ? t('yourSaving', { amount: discountAmountString, units: units?.singular || t('common:bag') })
              : t('startSaving')}
          </CurrentDiscountAmount>
          <AddItemsButton onClick={() => onQtyAdd(nextLvlQty)} disabled={disabled}>
            {isCartEmpty ? t('addAmount', { amount: nextLvlQty }) : t('addMore', { amount: nextLvlQty })}
          </AddItemsButton>
          <Description isHighestDiscountReached={isHighestDiscountReached}>
            {currentDiscountAmountPerBag ? t('getDiscountHint') : t('toYourTruck')}
          </Description>
        </>
      ) : (
        <Description isHighestDiscountReached={isHighestDiscountReached}>
          {currentDiscountAmountPerBag
            ? t('reachedMaxDiscountAmount', {
                amount: discountAmountString,
                units: units?.singular || t('common:bag'),
              })
            : t('reachedMaxDiscount')}
        </Description>
      )}
    </SuggestionWrapper>
  )
}

import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { CheckoutState, CheckoutStep, DeliveryAddressType } from 'modules/domain/checkout/types'
import { Progress } from 'modules/types'
import { OrderDeliveryAddress } from 'types/order'

const initialState: CheckoutState = {
  step: CheckoutStep.INFO,
  deliverAddressType: DeliveryAddressType.MAIN,
  deliverySplitAddresses: [],
  checkoutProgress: Progress.IDLE,
  distributorId: undefined,
  distributorTitle: undefined,
}

class CheckoutReducer extends ImmerReducer<CheckoutState> {
  stepUpdated(step: CheckoutStep) {
    this.draftState.step = step
  }

  distributorSelected({ id, title }: { id?: string; title?: string }) {
    this.draftState.distributorId = id
    this.draftState.distributorTitle = id ? title : undefined
  }

  addressTypeUpdated(addrType: DeliveryAddressType) {
    this.draftState.deliverAddressType = addrType
  }

  addressesUpdated(addresses: OrderDeliveryAddress[]) {
    this.draftState.deliverySplitAddresses = addresses
  }
  orderCreateRequested(_producerId: string) {
    this.draftState.checkoutProgress = Progress.WORK
  }
  orderCreateSucceed() {
    this.draftState.checkoutProgress = Progress.SUCCESS
    this.draftState.step = CheckoutStep.SUCCESS
  }
  orderCreateFailed() {
    this.draftState.checkoutProgress = Progress.ERROR
  }

  orderSkuCreateRequested(_sellerId: string) {
    this.draftState.checkoutProgress = Progress.WORK
  }
  orderSkuCreateSucceed() {
    this.draftState.checkoutProgress = Progress.SUCCESS
    this.draftState.step = CheckoutStep.SUCCESS
  }
  orderSkuCreateFailed() {
    this.draftState.checkoutProgress = Progress.ERROR
  }

  setDesiredDeliveryDate(date: string) {
    this.draftState.desiredDeliveryDate = date
  }

  reset() {
    Object.assign(this.draftState, initialState)
  }
}

export const CheckoutActions = createActionCreators(CheckoutReducer)
export default CheckoutActions
export const reducer = createReducerFunction(CheckoutReducer, initialState)

import React, { useState } from 'react'
import * as Styled from './styles'
import { SectionBody, SectionContainer, SectionTitle } from '@agro-club/frontend-shared'

type TabsProps = {
  children: React.ReactElement[]
  defaultTab?: unknown
  onTabChange?: (id: unknown) => void
}

type TabProps = {
  id: unknown
  label: React.ReactElement | string
  className?: string
  isActive?: boolean
  disabled?: boolean
  onClick?: (id: unknown, disabled?: boolean) => void
}

export const Tabs: React.FC<TabsProps> = ({ children, defaultTab, onTabChange }) => {
  const [activeTab, setActiveTab] = useState(defaultTab || children[0].props.id)
  const handleTabClick = (onClick?: (id: unknown) => void) => (id: unknown, disabled?: boolean): void => {
    if (!disabled) {
      setActiveTab(id)
      onTabChange && onTabChange(id)
    }

    onClick && onClick(id)
  }

  const tabs = children.map((child: React.ReactElement<TabProps>, i) =>
    React.cloneElement(child, {
      onClick: handleTabClick(child.props.onClick),
      isActive: child.props.id === activeTab,
      key: i,
    }),
  )

  let tabContent = null
  const childTabContent = children.find(child => child.props.id === activeTab)
  if (childTabContent && !childTabContent.props.disabled) {
    tabContent = childTabContent.props.children
  }

  return (
    <SectionContainer noDivider>
      <SectionTitle>
        <Styled.Tabs count={children.length}>{tabs}</Styled.Tabs>
      </SectionTitle>
      <SectionBody paddedOnMobile noGrid data-active-tab={activeTab}>
        <Styled.TabContent key={activeTab}>{tabContent}</Styled.TabContent>
      </SectionBody>
    </SectionContainer>
  )
}

export const Tab: React.FC<TabProps> = ({ id, className, onClick, label, isActive = false, disabled = false }) => {
  const handleTabClick = (): void => {
    onClick && onClick(id, disabled)
  }

  return (
    <Styled.Tab
      className={className}
      onClick={handleTabClick}
      isActive={isActive}
      disabled={disabled}
      data-test-id={id}
    >
      {label}
    </Styled.Tab>
  )
}

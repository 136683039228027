import styled, { StyledProps, keyframes, css } from 'styled-components'
import React from 'react'
import Link from 'views/components/Link/Link'
import {
  Input,
  SimpleSelect,
  IconCrossCircle,
  IconWarning,
  SectionTableBodyRow,
  IconPencil,
  Button,
} from '@agro-club/frontend-shared'

type ThemedProps = StyledProps<{}>

const BlinkAnimation = keyframes`
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
`

export const Image = styled.div`
  height: 60px;
  width: 60px;
  background: ${(props: StyledProps<{ url?: string }>) => {
    if (props.url) {
      return `url(${props.url}) no-repeat center center / cover`
    } else {
      return props.theme.color.secondary50
    }
  }};
  margin-right: 16px;
  flex-shrink: 0;
  border-radius: ${(props: StyledProps<{ url: string }>) => (props.url ? 0 : '8px')};
  opacity: ${(props: StyledProps<{ url: string }>) => (props.url ? 1 : 0.5)};
`

export const FakeImageWrapper = styled.div`
  margin-right: 16px;
  flex-shrink: 0;
`

export const ProductCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProductCellLegalWarning = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.color.onPrimaryDark};
  margin-top: 8px;
  > svg {
    fill: ${props => props.theme.color.accentDestructive};
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }
`

export const ProductCellLegalWarningTooltipContent = styled.div`
  width: 300px;
`

export const ProductSeedTreatment = styled.div<StyledProps<{ flexDirection: string }>>`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  & > :first-child {
    ${({ flexDirection }) => ({
      maxWidth: flexDirection === 'row' ? '60px' : '100px',
      marginRight: flexDirection === 'row' ? '16px' : '0px',
    })}
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;

    text-align: right;

    color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
  }

  & > :last-child {
    width: 160px;
    min-height: 32px;
  }
`

export const SeedTreatmentValue = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`

export const SeedTreatmentSelect = styled(SimpleSelect)`
  width: 160px;
  max-height: 32px;
`

export const PendingProductCell = styled.div`
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  color: unset;
  max-width: 320px;

  margin-bottom: 8px;
`

export const productCellStyle = `
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  color: unset;
  max-width: 320px;
  margin-bottom: 8px;
`

export const ProductCell = styled.div`
  ${productCellStyle}
`

export const ClickableProductCell = styled(Link)<{ $clickable?: boolean }>`
  ${productCellStyle}
  cursor: ${({ $clickable = true }) => ($clickable ? 'pointer' : 'default')};
`

export const BoldText = styled.span`
  font-weight: 600;
  font-size: 16px;

  ${props => props.theme.media.tablet`
    font-weight: 600;
    font-size: 14px;
  `}

  ${props => props.theme.media.mobile`
    font-weight: 600;
    font-size: 14px;
  `}
`

export const PackSize = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: ${({ theme }) => theme.color.onSurfaceHighEmphasys};

  ${props => props.theme.media.tablet`
    margin-top: 8px;
  `}
`

export const NoWrapText = styled.span`
  white-space: nowrap;
`
export const ProductCellText = styled.span`
  display: grid;
  grid-template-columns: auto;
`
export const RemoveIconStyled = styled(IconCrossCircle)`
  transition: 0.3s fill;
  width: 20px;
  height: 20px;
`

export const IconPencilStyled = styled(IconPencil)`
  transition: 0.3s fill;
  width: 20px;
  height: 20px;
`

export const ActionIconWrapper = styled(Button)`
  padding: 10px;
  cursor: pointer;
  width: 40px;

  svg {
    fill: ${props => props.theme.color.secondary200};
  }

  &:hover svg {
    fill: ${(props: ThemedProps) => props.theme.color.primary600};
  }

  ${props => props.theme.media.mobile`
    display: flex;
    align-self: start;
    width: auto;
    padding: 4px;
    & svg {
      margin-left: auto;
      margin-right: 0;
      width: 18px;
      height: 18px;
      fill: ${(props: ThemedProps) => props.theme.color.secondary100};
    }
  `}
`

export const Remove: React.FC<React.HTMLAttributes<HTMLButtonElement>> = ({ onClick, ...props }) => {
  return (
    <ActionIconWrapper variant="no-style" onClick={onClick} {...props}>
      <RemoveIconStyled />
    </ActionIconWrapper>
  )
}

export const TabletCartItemContainer = styled.div`
  display: grid;
  grid-template-columns: auto 150px 30px;
  grid-gap: 20px;
`

export const TabletCartItem = styled(TabletCartItemContainer)`
  padding-bottom: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const TabletCartList = styled.div`
  padding: 8px 0 16px;
`
export const TabletBlock = styled.div``

export const TabletHeading = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${props => props.theme.color.secondary200};
  margin-bottom: 16px;
  white-space: nowrap;
`

export const TabletLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

type MobileCartItemProps = StyledProps<{ isFake: boolean }>

export const MobileCartItem = styled.div<MobileCartItemProps>`
  border-bottom: 1px solid ${props => props.theme.color.onSurfaceLowEmphasys};
  padding-bottom: 16px;

  animation: ${props =>
    props.isFake
      ? css`
          ${BlinkAnimation} 0.5s infinite alternate ease-in-out
        `
      : 'none'};

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`
export const MobileCartList = styled.div`
  padding: 8px 0 16px;
`
export const MobileBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  gap: 8px;

  &:first-child {
    margin-top: 0;
    padding-right: 0;
  }
`
export const MobileLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: ${(props: StyledProps<{}>) => props.theme.color.onSurfaceMidEmphasys};

  ${props => props.theme.media.tablet`
    font-size: 12px;
    line-height: 16px;
  `}
`

export const MobilePackaging = styled.div`
  ${props => props.theme.media.tablet`
    display: grid;
    grid-template-columns: auto max-content;
    margin-top: 8px;
    align-items: center;
  `}
`

export const QtyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props => props.theme.media.mobile`
    width: 160px;
  `}
`

export const QtyContainerInner = styled.div`
  display: grid;
  grid-gap: 8px;
  width: 160px;
`

export const QtyValue = styled.div`
  text-align: right;
  flex-grow: 1;
  font-weight: 600;
`

export const PackagingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  justify-self: flex-start;
  min-width: 130px;
  max-width: 230px;

  ${props => props.theme.media.mobile`
    max-width: none;
    width: 100%;
  `}
`

export const PackagingSwitchWrapper = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  & > :first-child {
    margin-right: 8px;
  }

  ${props => props.theme.media.mobile`
    & > :last-child {
      justify-self: flex-end;
      margin-right: 0px;
      margin-left: auto;
    }
  `}

  color: ${({ theme }) => theme.color.onSurfaceHighEmphasys};
`

export const PackagingHint = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};

  margin-bottom: 16px;
`

export const PackagingInputWrapper = styled.div<StyledProps<{ show: boolean }>>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
`

export const PackagingLabel = styled.span<StyledProps<{ size?: 'small' | 'big' }>>`
  font-weight: 500;
  ${({ size }) => ({
    fontSize: size === 'small' ? '12px' : '14px',
    maxWidth: size === 'small' ? '50px' : '64px',
    lineHeight: size === 'small' ? '16px' : '20px',
  })};
  color: ${(props: StyledProps<{}>) => props.theme.color.onSurfaceMidEmphasys};
  margin-right: 16px;
  align-self: center;

  ${props => props.theme.media.mobile`
    max-width: none;
  `}
`

export const PackagingInput = styled(Input)`
  max-width: 64px;
  flex-shrink: 0;

  background: ${({ theme }) => theme.color.onPrimaryLight};
  height: 32px;

  box-sizing: border-box;
  border-radius: 8px;

  ${props => props.theme.media.tablet`
    input {
      height: 40px;
    }
  `}

  ${props => props.theme.media.mobile`
    input {
      height: 32px;
    }

    justify-self: flex-end;
    margin-right: 0px;
    margin-left: auto;
  `}
`

export const BodyRowSpacer = styled(SectionTableBodyRow)`
  height: 32px;
`

export const EmptyText = styled.div`
  text-align: center;
  padding: 15px;
  color: ${(props: StyledProps<{}>) => props.theme.color.secondary300};
`

export const TabletPackage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
    padding-right: 0;
  }
`

export const MaxQtyHint = styled.div`
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: ${props => props.theme.color.backgroundBase};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 8px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  min-height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const LegalHintContainer = styled.div`
  width: 100%;
  & > * {
    width: 100%;
  }
`

export const LegalHint = styled.div`
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 8px;
  min-height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.color.onPrimaryDark};
  > svg {
    fill: ${props => props.theme.color.accentDestructive};
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
`

export const LegalHintTooltipContent = styled.div`
  min-width: 90vw;
`

export const CostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  white-space: nowrap;
`

export const CostTotal = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  text-align: right;
`

export const CostPrice = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  text-align: right;
`
export const IconWarningRed = styled(IconWarning)`
  fill: ${props => props.theme.color.accentDestructive};
`

export const TruckText = styled.div`
  margin-bottom: 8px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.onPrimaryDark};
`

export const TruckDescription = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

type CellWrapperProps = StyledProps<{ isFake: boolean }>
export const CellWrapper = styled.div<CellWrapperProps>`
  animation: ${props =>
    props.isFake
      ? css`
          ${BlinkAnimation} 0.5s infinite alternate ease-in-out
        `
      : 'none'};
`

export const TableItemSkeletonWrapper = styled.div`
  display: grid;
  grid-template-columns: 76px auto;
  grid-template-rows: 60px;
`

export const TableItemSkeletonTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const ProductOption = styled.div`
  line-height: 20px;
  font-size: 14px;
`

export const UnitsContainer = styled.div`
  text-align: center;
`

export const UnitsContainerLeft = styled.div`
  text-align: left;
`

export const FarmerComment = styled(Input)`
  margin: 12px 0px;
`

export const Packaging = styled.div`
  text-align: center;
`

export const ErrorText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.accentDestruct500};
`

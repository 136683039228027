import { format } from 'date-fns'
import { DATE_FORMAT_SHORT, DATE_FORMAT_SHORT_FULL_YEAR } from 'modules/constants'

export const formatDate = (date: string | Date, dateFormat: string) => {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  return format(date, dateFormat)
}

export const toShort = (date: string | Date) => (date ? formatDate(date, DATE_FORMAT_SHORT) : '')
export const toShortFullYear = (date: string | Date) => (date ? formatDate(date, DATE_FORMAT_SHORT_FULL_YEAR) : '')

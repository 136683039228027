import { useSelector } from 'react-redux'
import { Progress, ResourceHook } from 'modules/types'
import CartSkuActions from 'modules/domain/cartSku/duck'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { CartSkuItem } from 'modules/domain/cartSku/types'
import { useDidMount, useAction } from '@agro-club/frontend-shared'

export const useCartSkuList: ResourceHook<CartSkuItem[]> = producerSlug => {
  const progress = useSelector(CartSkuSelectors.initProgress)
  const initAction = useAction(CartSkuActions.cartInitRequested)
  const items = useSelector(CartSkuSelectors.cartItemsBySellerSlug(producerSlug))

  useDidMount(() => {
    if (progress === Progress.IDLE) {
      initAction()
    }
  })

  return [progress, items]
}

import { all, takeLatest, call, put, take, select, race } from 'redux-saga/effects'
import CartActions from './duck'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import { Progress } from '../../types'
import { exponentialDelay } from 'modules/sagaHelpers'
import CartSelectors from 'modules/domain/cart/selectors'
import { TokenService } from 'service/token/interface'
import { getTokenService } from 'modules/sagaEffects'

export const cartReinitSaga = function*() {
  const tokenService: TokenService = yield getTokenService()
  const uuid = tokenService.getUuid()
  const uuidRequestProgress = yield select(AuthSelectors.uuidRequestProgress)
  const attemptNo = yield select(CartSelectors.initRetryAttempts)
  yield call(exponentialDelay, attemptNo)

  if (attemptNo > 5) {
    return
  }

  if (uuidRequestProgress !== Progress.ERROR) {
    try {
      if (!uuid) {
        yield put(AuthActions.uuidRequested())
        const { fail } = yield race({
          success: take(AuthActions.uuidRequestSucceed.type),
          fail: take(AuthActions.uuidRequestFailed.type),
        })
        if (fail) {
          return
        }
      }
      yield put(CartActions.cartInitRequested())
    } catch (err) {
      console.error('Failed to receive uuid for cart initialization', err)
    }
  }
}

export const loginWatchSaga = function*() {
  yield put(CartActions.cartInitRequested())
}

const WatchersSaga = function*() {
  yield all([takeLatest(CartActions.cartInitRequestFailed.type, cartReinitSaga)])
  yield all([takeLatest(AuthActions.smsCodeVerificationsSucceed.type, loginWatchSaga)])
}

export default WatchersSaga

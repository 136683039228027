import { Company, LocalizedValue } from 'types/entities'
import { Progress } from 'modules/types'
import { Dict } from 'types/generics'
import { CountryCodeEntry } from '@agro-club/frontend-shared'
import { CountryCode } from 'libphonenumber-js'

export type Region = {
  id: string
  title: string
  parent_id?: string
  country: CountryCode
  selected?: boolean
}

export type DistributorFilter = {
  for_producer_id?: string
  regions_ids?: string[]
  can_deliver_products_ids?: string[]
}

export type Category = {
  id: string
  slug: string
  parent_id: string | null
  parent?: Category | null
  images: string[]
  title: string
  title_i18n: LocalizedValue
  country: CountryCode
  description?: string
  description_i18n?: LocalizedValue
  discount_text?: string
  discount_text_i18n?: LocalizedValue
}

export type CategorySku = Category & {
  seller_ids?: string[]
}

export type FilterAttribute = {
  title: string
  values: string[]
}

export enum PromoInfoType {
  CatalogProducer = 'catalog_producer',
  CatalogCategory = 'catalog_category',
  CartDiscount = 'cart_discount',
}

export type PromoInfoStyles = {
  startColor: string
  endColor: string
  primaryColor: string
  secondaryColor: string
}

export type PromoInfo = {
  title: string
  description: string
  message?: {
    title: string
    description: string
  }
  producer_ids: string[]
  category_ids: string[]
  type: PromoInfoType
  styles: PromoInfoStyles | null
}

export type Country = {
  id: CountryCode
  title: string
}

export type CollectionState = {
  regions: Region[]
  regionsFetchProgress: Progress

  categories: Dict<Category>
  categoriesFetchProgress: Progress

  companyCategories: Dict<{ progress: Progress; items: Category[] }>
  companySubCategories: Dict<{ progress: Progress; items: Category[] }>

  distributors: Dict<Company>
  distributorsFetchProgress: Progress
  distributorsProducerRelation: string | null

  filterAttributes: Record<
    string,
    Record<
      string,
      {
        title: string
        values: string[]
      }[]
    >
  >
  filterAttributesProducerId: string | null
  filterAttributesSubCategoryId: string | null
  filterAttributesProgress: Progress

  promoInfoProgress: Progress
  promoInfo: PromoInfo[]

  countryPhoneCodes: CountryCodeEntry[]
  countryPhoneCodesProgress: Progress

  countries: Country[]
  countriesProgress: Progress
}

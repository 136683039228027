import React, { useState } from 'react'
import DiscountInfoPopup, { CloseType } from 'views/components/DiscountInfoPopup/DisountInfoPopup'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import BannerEvents from './events'
import { Banner, useDidMount } from '@agro-club/frontend-shared'
import { PromoInfoStyles } from 'modules/domain/collection/types'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { Page } from 'modules/utils/analytics-utils/pageNames'
import { ProductParams } from 'modules/utils/analytics-utils/eventParametersTypes'

const Wrapper = styled.div`
  ${props => props.theme.media.mobile`
    margin: 0 16px;
  `}
`

type Size = 'large' | 'small'
type AnalyticData = ProductParams & { page: Page }
type DiscountBannerProps = {
  size?: Size
  primaryText: string
  secondaryText: string
  modalText: string
  closable?: boolean
  className?: string
  modalTitle: string
  styles: PromoInfoStyles | null
  analyticData?: AnalyticData
}

const DiscountBanner: React.FC<DiscountBannerProps> = ({
  size = 'small',
  primaryText,
  secondaryText,
  modalTitle,
  modalText,
  closable,
  className,
  styles,
  analyticData,
}) => {
  const [open, setOpen] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const { track } = useAnalyticsSSR()
  const { pathname } = useLocation()

  const sendAnalyticData = (eventName: BannerEvents, extraProps?: any) => {
    track(eventName, {
      banner_type: 'main_head',
      title: primaryText,
      url: pathname,
      ...analyticData,
      ...extraProps,
    })
  }

  const handleOpenModal = () => {
    setModalOpen(true)
    sendAnalyticData(BannerEvents.BannerPopupDisplayed)
  }

  const handleCloseModal = (closeType: CloseType) => {
    setModalOpen(false)
    sendAnalyticData(BannerEvents.BannerPopupClicked, { type: closeType })
  }

  useDidMount(() => {
    sendAnalyticData(BannerEvents.BannerDisplayed)
  })

  if (!open) {
    return null
  }

  return (
    <>
      <Wrapper>
        <Banner
          id="page-discount-banner"
          size={size}
          className={className}
          onClick={() => {
            sendAnalyticData(BannerEvents.BannerClicked)
            modalText && handleOpenModal()
          }}
          onClose={closable ? () => setOpen(false) : undefined}
          styles={styles}
          primaryText={primaryText}
          secondaryText={secondaryText}
          learnMore={!!modalText}
        />
      </Wrapper>
      <DiscountInfoPopup isOpen={modalOpen} title={modalTitle} text={modalText} onClose={handleCloseModal} />
    </>
  )
}

export default DiscountBanner

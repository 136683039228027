import { ListResponse } from 'types/api'
import { Document } from 'modules/domain/documents/types'

export const listResponse: ListResponse<Document> = {
  page: 1,
  total_count: 3,
  data: [
    {
      id: '1',
      name: 'Technology Use Agreement and Product Patents',
      date: new Date().toISOString(),
      status: 'signed',
    },
    {
      id: '2',
      name: 'China Safety Certificate Application',
      date: new Date().toISOString(),
      status: 'need_sign',
    },
    {
      id: '3',
      name: 'Product Use Guides',
      date: new Date().toISOString(),
      status: 'envelope_created',
    },
    {
      id: '4',
      name: 'Product Use Guides',
      date: new Date().toISOString(),
      status: 'processing',
    },
  ],
}

export const oneItem: Document = {
  id: '1',
  name: 'Technology Use Agreement and Product Patents',
  date: new Date().toISOString(),
  status: 'signed',
}

import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'
import { Routes } from 'views/pages/routes'
import { useCountryPath } from './useCountryPath'

const useMatchedRouteWithTitle = () => {
  const { t } = useTranslation('routes')
  const isCart = useRouteMatch(Routes.Cart)?.isExact
  const isSignIn = useRouteMatch(Routes.SignIn)?.isExact
  const isAccountInfo = useRouteMatch(Routes.AccountInfo)?.isExact
  const isDeliveryPoint = useRouteMatch(Routes.DeliveryPoint)?.isExact
  const isOrders = useRouteMatch(Routes.Orders)?.isExact
  const isCheckout = useRouteMatch(Routes.Checkout)?.isExact
  const generateCountryPath = useCountryPath()

  if (isCart) {
    return [generateCountryPath(Routes.Cart), t('cart')]
  }

  if (isSignIn) {
    return [generateCountryPath(Routes.SignIn), t('signIn')]
  }

  if (isAccountInfo) {
    return [generateCountryPath(Routes.AccountInfo), t('accountInfo')]
  }

  if (isDeliveryPoint) {
    return [generateCountryPath(Routes.DeliveryPoint), t('deliveryPoint')]
  }

  if (isOrders) {
    return [generateCountryPath(Routes.Orders), t('orders')]
  }

  if (isCheckout) {
    return [generateCountryPath(Routes.Checkout), t('checkout')]
  }

  return null
}

const useBreadcrumbs = (): { path: string; title: string }[] => {
  const { t } = useTranslation('routes')
  const match = useMatchedRouteWithTitle()
  const isMain = useRouteMatch(Routes.Root)?.isExact
  const generateCountryPath = useCountryPath()

  if (isMain) return []

  if (!match) {
    return [{ path: generateCountryPath(Routes.Root), title: t('home') }]
  }
  const [path, title] = match

  return [
    { path: generateCountryPath(Routes.Root), title: t('home') },
    { path, title },
  ]
}

export default useBreadcrumbs

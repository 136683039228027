import React, { useMemo, useCallback, useState, VFC } from 'react'
import { useSelector } from 'react-redux'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Input } from '@agro-club/frontend-shared'
import { IconArrowRight, useAction } from '@agro-club/frontend-shared'
import ProductActions from 'modules/domain/product/duck'
import CardActions from 'modules/domain/card/duck'
import ProductSelectors from 'modules/domain/product/selectors'
import CardSelectors from 'modules/domain/card/selectors'
import { useParams } from 'react-router-dom'

type ReOrderFormType = {
  code: string
}

const ReOrderForm: VFC<{ isStorefront?: boolean }> = ({ isStorefront = false }) => {
  const { producerSlug } = useParams<{ producerSlug: string }>()
  const { t } = useTranslation('reorder')
  const fetchExclusiveProductAction = useAction(ProductActions.exclusiveItemRequested)
  const fetchExclusiveCardAction = useAction(CardActions.exclusiveItemRequested)
  const fetchExclusiveItemAction = isStorefront ? fetchExclusiveCardAction : fetchExclusiveProductAction
  const fetchProductError = useSelector(ProductSelectors.fetchExclusiveItemError)
  const fetchCardError = useSelector(CardSelectors.fetchExclusiveItemError)
  const fetchError = isStorefront ? fetchCardError : fetchProductError

  const [code, setCode] = useState('')
  const validator = useMemo(() => {
    return Yup.object({
      code: Yup.string().required(),
    })
  }, [])

  const formik = useFormik<ReOrderFormType>({
    initialValues: {
      code: '',
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validator,
  })

  const errorText = useMemo(() => {
    if (formik.touched.code && !!formik.errors.code) return formik.errors.code
    if (fetchError && formik.values.code === code) return t('productNotFound')
    return null
  }, [t, formik.errors.code, formik.touched.code, formik.values.code, fetchError, code])

  const handleSubmitCode = useCallback(() => {
    if (!formik.isValid) return

    if (formik.values.code !== code) {
      setCode(formik.values.code)
      fetchExclusiveItemAction(formik.values.code, producerSlug)
    }
  }, [formik.isValid, formik.values.code, code, fetchExclusiveItemAction, producerSlug])

  return (
    <Styled.ReOrderContainer data-test-id="re-order-form">
      <Styled.ReOrderTitle>{t('title')}</Styled.ReOrderTitle>
      <Styled.ReOrderDescription>{t('description')}</Styled.ReOrderDescription>
      <Styled.InputWrapper>
        <Input {...formik.getFieldProps('code')} invalid={formik.touched.code && !!formik.errors.code} />
        <Styled.SubmitCodeButton
          id="submit-code-button"
          data-test-id="submit-code-button"
          Icon={IconArrowRight}
          onClick={handleSubmitCode}
        />
      </Styled.InputWrapper>
      {code && <Styled.ErrorText data-test-id="submit-code-error">{errorText}</Styled.ErrorText>}
    </Styled.ReOrderContainer>
  )
}

export default ReOrderForm

import { isEmpty } from 'ramda'
import { FeatureFlags } from 'types/entities/config'

export const checkFeatureFlags = (featureFlagsConfig?: FeatureFlags) => (
  featureFlag: string[] | string,
  some?: boolean,
): boolean => {
  if (!featureFlagsConfig) return false

  const featureFlagsKeys = Object.keys(featureFlagsConfig)

  const testFlag = (flag: string) => {
    const flagKey = featureFlagsKeys.find(access => access === flag)
    if (!flagKey) return false

    return featureFlagsConfig[flagKey]
  }

  if (Array.isArray(featureFlag)) {
    return isEmpty(featureFlag) || some
      ? featureFlag.some(flag => testFlag(flag))
      : featureFlag.every(flag => testFlag(flag))
  }

  return testFlag(featureFlag)
}

import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useHelmet from 'hooks/useHelmet'
import usePersistentScroll from 'hooks/usePersistentScroll'
import Breadcrumbs from 'views/ui/Breadcrumbs/Breadcrumbs'
import * as Styled from './styled'
import LoginForm from 'views/components/LoginForm/LoginForm'
import {
  Button,
  BackButton,
  FormComponents,
  SimpleSelect,
  useAction,
  useDidMount,
  useFormManager,
  useScrollTrigger,
  DatePicker,
} from '@agro-club/frontend-shared'
import useMatchMedia from 'hooks/useMatchMedia'
import { queries } from 'theme/theme'
import * as StepSection from 'views/ui/StepSection/StepSection'
import OrderPreviewTable from 'views/components/OrderPreviewTable/OrderPreviewTable'
import OrderSkuPreviewTable from 'views/components/OrderSkuPreviewTable/OrderSkuPreviewTable'
import CartDesktop from './Cart/CartDesktop'
import CartMobile from './Cart/CartMobile'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import CheckoutSelectors from 'modules/domain/checkout/selectors'
import CheckoutActions from 'modules/domain/checkout/duck'
import { CheckoutStep } from 'modules/domain/checkout/types'
import CartSelectors from 'modules/domain/cart/selectors'
import { useDistributorsList } from 'modules/domain/collection/hooks'
import { Routes as AppRoutes } from 'views/pages/routes'
import { clone, equals } from 'ramda'
import { AnalyticsEvent, Progress } from 'modules/types'
import { Redirect, useParams } from 'react-router'
import { OrderEvents } from './events'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { ProfileAddress } from 'types/entities/userProfile'
import OrderPreviewTotal from 'views/components/OrderPreviewTotal/OrderPreviewTotal'
import { useProducer } from 'modules/domain/producer/hooks'
import useLangPicker from 'hooks/useLangPicker'
import { TruckLearnMore } from 'views/components/Cart/styles'
import { Company } from 'types/entities'
import useWLFeatureFlags from 'hooks/useWLFeatureFlags'
import {
  generateCustomFeatureFlag,
  generateFieldAccessString,
  generateFieldModifierString,
} from 'modules/utils/generateStringHelpers'
import { DistributorFilter } from 'modules/domain/collection/types'
import useCompanyFeatureFlags from 'hooks/useCompanyFeatureFlags'
import { CustomFeatureName, FeatureFlagModifiers, FieldLocation, FieldNames, Sections } from 'types/entities/config'
import { useCountryPath } from 'hooks/useCountryPath'
import { CartItem } from 'modules/domain/cart/types'
import styled from 'styled-components'
import { toShortFullYear } from 'helpers/date'
import { FacebookPixel } from './hooks'
import { producerToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import CartSkuMobile from 'views/pages/Checkout/CartSku/CartSkuMobile'
import CartSkuDesktop from 'views/pages/Checkout/CartSku/CartSkuDesktop'
import usePromocodes from 'hooks/usePromocodes'
import useHasTotalInfo from 'hooks/useHasTotalInfo'
import useFarmerComment from 'hooks/useFarmerComment'
import { Page } from 'modules/utils/analytics-utils/pageNames'
import { useCurrentSeasonOnce } from 'modules/domain/season/hooks'

const SeasonHint = styled.div`
  font-size: 12px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};

  ${props => props.theme.media.mobile`
     padding-bottom: 16px;
  `}
`

const StepLogin: React.FC<{ onLoginSuccess(): void }> = ({ onLoginSuccess }) => {
  return (
    <Styled.LoginStepContainer>
      <LoginForm onLoginSuccess={onLoginSuccess} />
    </Styled.LoginStepContainer>
  )
}

const DatePickerWrapper = styled.div`
  width: 220px;
`
const deliveryDatesEnabledFlag = generateFieldAccessString(
  Sections.Orders,
  'desiredDeliveryDates',
  FeatureFlagModifiers.Enabled,
)

const StepOrderInfo: React.FC<{
  onNext(): void
  producer?: Company
  checkProductOptions?: () => boolean
}> = ({ onNext, producer, checkProductOptions }) => {
  const { t } = useTranslation(['checkout', 'common'])
  const { track } = useAnalyticsSSR()
  const formManager = useFormManager()
  const profile = useSelector(AuthSelectors.profile)
  const selectedDistributorId = useSelector(CheckoutSelectors.distributorId)
  const selectDistributorAction = useAction(CheckoutActions.distributorSelected)
  const desiredDeliveryDate = useSelector(CheckoutSelectors.desiredDeliveryDate)
  const setDesiredDeliveryDate = useAction(CheckoutActions.setDesiredDeliveryDate)
  const { producerSlug } = useParams<{ producerSlug: string }>()
  const checkWLFeatureFlag = useWLFeatureFlags()
  const checkCompanyFeatureFlags = useCompanyFeatureFlags(producerSlug)
  const filterByProvince = checkWLFeatureFlag(
    generateCustomFeatureFlag(Sections.Checkout, CustomFeatureName.FilterRetailersByProvince),
  )

  const desiredDeliveryDatesEnabled = checkCompanyFeatureFlags([deliveryDatesEnabledFlag])

  const distributorFilter: DistributorFilter = {
    for_producer_id: producer?.id,
  }
  if (filterByProvince && (profile?.delivery_address?.region_id || profile?.retailer_regions_ids?.length)) {
    distributorFilter.regions_ids = profile.retailer_regions_ids?.length
      ? profile.retailer_regions_ids
      : profile.delivery_address?.region_id
      ? [profile.delivery_address?.region_id]
      : []
  }

  const [, distributors = []] = useDistributorsList(distributorFilter)
  const distributorOptions = useMemo(() => distributors.map(d => ({ title: d.official_name, id: d.id })), [
    distributors,
  ])

  const handleDistributorSelection = useCallback(
    (id?: string) => {
      const distributor = distributorOptions.find(item => item.id === id)
      selectDistributorAction({ id, title: distributor ? distributor.title : undefined })
    },
    [distributorOptions, selectDistributorAction],
  )

  const handleFormSubmit = async () => {
    const optionsIsValid = checkProductOptions ? checkProductOptions() : true
    if (!optionsIsValid) {
      return
    }

    const [valid] = await formManager.submitAll()
    if (valid) {
      onNext()
    }
  }

  useDidMount(() => {
    track(AnalyticsEvent.Page, { name: Page.CheckoutRetailerScreen, ...producerToEventParams(producer) })
  })

  if (!profile) {
    // TODO handle this
    return null
  }

  return (
    <Styled.OrderInfoContainer data-test-id={'step-order-info'}>
      <FormComponents.FormSection title={t('confirmationForm.retailerTitle')}>
        <SimpleSelect
          name={'distributor-select'}
          labelStyle={{ whiteSpace: 'pre-wrap' }}
          openMenuOnFocus={filterByProvince}
          openMenuOnClick={filterByProvince}
          placeholder={t('confirmationForm.distributorSelectPlaceholder')}
          label={t('confirmationForm.retailerLabel')}
          onChange={handleDistributorSelection}
          onFocus={() => {
            handleDistributorSelection()
          }}
          options={distributorOptions}
          value={selectedDistributorId}
          isSearchable
          isClearable
        />
        {desiredDeliveryDatesEnabled && (
          <DatePickerWrapper>
            <DatePicker
              onChange={setDesiredDeliveryDate}
              label={t('confirmationForm.desiredDeliveryDate')}
              date={desiredDeliveryDate}
            />
          </DatePickerWrapper>
        )}
      </FormComponents.FormSection>
      <Styled.StepButtonsContainer>
        <Button
          id="order-info-next-button"
          size={'big'}
          intent={'primary-action'}
          filled={true}
          onClick={() => {
            handleFormSubmit()
            track(OrderEvents.RetailerNextClicked, producerToEventParams(producer))
          }}
        >
          {t('common:next')}
        </Button>
      </Styled.StepButtonsContainer>
    </Styled.OrderInfoContainer>
  )
}

const StepConfirmation: React.FC<{
  onBack(): void
  onNext(): void
  checkProductOptions?: () => boolean
}> = ({ onBack, onNext, checkProductOptions }) => {
  const { t } = useTranslation('checkout')
  const { track } = useAnalyticsSSR()
  const { producerSlug } = useParams<{ producerSlug: string }>()
  const [, producer] = useProducer(producerSlug)
  const profile = useSelector(AuthSelectors.profile)
  const progress = useSelector(CheckoutSelectors.checkoutProgress)
  const distributorId = useSelector(CheckoutSelectors.distributorId)
  const distributorTitle = useSelector(CheckoutSelectors.distributorTitle)
  const promocodes = usePromocodes(producer)
  const hasTotalInfo = useHasTotalInfo(producer)
  const farmerComment = useFarmerComment(producer)
  const [seasonProgress, season] = useCurrentSeasonOnce({ companies_id: producer?.id })

  const desiredDeliveryDate = useSelector(CheckoutSelectors.desiredDeliveryDate)
  const { pick } = useLangPicker()
  const hasAccess = useCompanyFeatureFlags(producerSlug)
  const skipRetailerSelection = hasAccess([
    generateCustomFeatureFlag(Sections.Checkout, CustomFeatureName.SkipRetailerSelection),
  ])

  const { truck_info } = { ...producer?.config?.seller_config }

  useDidMount(() => {
    track(AnalyticsEvent.Page, { name: Page.CheckoutConfirmationScreen, ...producerToEventParams(producer) })
  })

  const handleSubmit = useCallback(() => {
    const optionsIsValid = checkProductOptions ? checkProductOptions() : true
    if (!optionsIsValid) {
      return
    }

    onNext()

    track(OrderEvents.ConfirmClicked, producerToEventParams(producer))
  }, [checkProductOptions, onNext, producer, track])

  if (!profile) {
    // TODO handle this
    return null
  }

  const name = [profile.first_name, profile.last_name].filter(Boolean).join(' ')
  const phone = profile.phone_number
  const email = profile.email

  const address: Partial<ProfileAddress> = profile.legal_address || {}
  const deliveryAddress: Partial<ProfileAddress> = profile.delivery_address || {}
  const deliverySameAsMailing = equals(address, deliveryAddress)

  const citySlashState = [address.city, address.state].filter(Boolean).join(' / ')
  const countrySlashZip = [address.country, address.postal_code].filter(Boolean).join(' / ')
  const deliveryAddressCitySlashState = [deliveryAddress.city, deliveryAddress.state].filter(Boolean).join(' / ')
  const deliveryAddressCountrySlashZip = [deliveryAddress.country, deliveryAddress.postal_code]
    .filter(Boolean)
    .join(' / ')
  const isStorefront = producer?.sku_orders

  return (
    <Styled.ConfirmationContainer data-test-id={'step-confirmation'}>
      {hasTotalInfo && (
        <Styled.PreorderTotalContainer>
          <FormComponents.FormSection title={t('confirmationForm.total')}>
            <OrderPreviewTotal producerSlug={producerSlug} producer={producer} />
          </FormComponents.FormSection>
        </Styled.PreorderTotalContainer>
      )}
      <Styled.ConfirmationBlock>
        <Styled.PreorderTableContainer>
          <FormComponents.FormSection title={t('confirmationForm.preOrderListTitle')}>
            {isStorefront ? (
              <>
                <SeasonHint>
                  {t('common:season')}: {seasonProgress === Progress.SUCCESS && season?.title}
                </SeasonHint>
                <OrderSkuPreviewTable producer={producer} />
              </>
            ) : (
              <OrderPreviewTable producer={producer} />
            )}
          </FormComponents.FormSection>
        </Styled.PreorderTableContainer>
      </Styled.ConfirmationBlock>
      {farmerComment && <Styled.FarmerComment>{farmerComment}</Styled.FarmerComment>}
      {truck_info?.title_i18n && (
        <>
          <Styled.TruckText>
            {pick(truck_info.title_i18n)}
            {truck_info.learn_more_url && (
              <TruckLearnMore href={truck_info.learn_more_url} target={'blank'}>
                {t('common:learnMore')}
              </TruckLearnMore>
            )}
          </Styled.TruckText>
          {truck_info.description_i18n && (
            <Styled.TruckDescription>{pick(truck_info.description_i18n)}</Styled.TruckDescription>
          )}
        </>
      )}
      <Styled.ConfirmationBlock>
        <FormComponents.FormSection title={t('confirmationForm.personalInformationTitle')}>
          <FormComponents.FormSectionTextBlock>
            <span>{name}</span>
            <span>{phone}</span>
            <span>{email}</span>
          </FormComponents.FormSectionTextBlock>
        </FormComponents.FormSection>
        <FormComponents.FormSection
          title={
            deliverySameAsMailing
              ? t('confirmationForm.mailingAndDeliveryAddressTitle')
              : t('confirmationForm.mailingAddressTitle')
          }
        >
          <FormComponents.FormSectionTextBlock>
            {address.address ? <span>{address.address}</span> : null}
            {citySlashState ? <span>{citySlashState}</span> : null}
            {countrySlashZip ? <span>{countrySlashZip}</span> : null}
          </FormComponents.FormSectionTextBlock>
        </FormComponents.FormSection>
        {!deliverySameAsMailing && (
          <FormComponents.FormSection title={t('confirmationForm.deliveryPointTitle')}>
            <FormComponents.FormSectionTextBlock>
              {deliveryAddress.address ? <span>{deliveryAddress.address}</span> : null}
              {deliveryAddressCitySlashState ? <span>{deliveryAddressCitySlashState}</span> : null}
              {deliveryAddressCountrySlashZip ? <span>{deliveryAddressCountrySlashZip}</span> : null}
            </FormComponents.FormSectionTextBlock>
          </FormComponents.FormSection>
        )}
        {desiredDeliveryDate && (
          <FormComponents.FormSection title={t('confirmationForm.desiredDeliveryDate')}>
            <FormComponents.FormSectionTextBlock>
              {toShortFullYear(desiredDeliveryDate)}
            </FormComponents.FormSectionTextBlock>
          </FormComponents.FormSection>
        )}
      </Styled.ConfirmationBlock>
      {!!distributorId && (
        <Styled.ConfirmationBlock>
          <FormComponents.FormSection title={t('confirmationForm.preferredRetailerTitle')}>
            <FormComponents.FormSectionTextBlock>
              <span data-test-id="preferred-retailer">{distributorTitle}</span>
            </FormComponents.FormSectionTextBlock>
          </FormComponents.FormSection>
        </Styled.ConfirmationBlock>
      )}
      {promocodes.length ? (
        <Styled.ConfirmationBlock>
          <FormComponents.FormSection title={t('confirmationForm.promocode')}>
            {promocodes.map(item => (
              <FormComponents.FormSectionTextBlock key={item.code}>
                <span>{item.code}</span>
              </FormComponents.FormSectionTextBlock>
            ))}
          </FormComponents.FormSection>
        </Styled.ConfirmationBlock>
      ) : null}
      <Styled.ConfirmationBlock>
        <Styled.StepButtonsContainer>
          {!skipRetailerSelection && <BackButton onClick={onBack} size={'big'} />}
          <Button
            id="checkout-confirm-button"
            size={'big'}
            intent={'primary-action'}
            filled={true}
            onClick={handleSubmit}
            progress={progress}
          >
            {t('confirmOrder')}
          </Button>
        </Styled.StepButtonsContainer>
      </Styled.ConfirmationBlock>
    </Styled.ConfirmationContainer>
  )
}

const StepComplete: React.FC<{ showDocsLink: boolean; renderPixel: boolean; producer?: Company }> = ({
  showDocsLink,
  renderPixel,
  producer,
}) => {
  const generateCountryPath = useCountryPath()
  const { t } = useTranslation('checkout')

  const { track } = useAnalyticsSSR()
  useDidMount(() => {
    track(AnalyticsEvent.Page, { name: Page.CheckoutDoneScreen, ...producerToEventParams(producer) })
  })

  return (
    <Styled.CompeteContainer data-test-id={'step-complete'}>
      <Styled.CompleteTitle>{t('confirmationForm.completeHeader')}</Styled.CompleteTitle>
      <Styled.CompleteText>
        {t('confirmationForm.completeText1')}
        <br />
        {t('confirmationForm.completeText2')}
      </Styled.CompleteText>
      {showDocsLink && (
        <Styled.CompleteLink
          onClick={() => {
            track(OrderEvents.CheckoutSignDocuments, producerToEventParams(producer))
          }}
          to={generateCountryPath(AppRoutes.Documents)}
        >
          {t('confirmationForm.documentsLink')}
        </Styled.CompleteLink>
      )}
      <Styled.CompleteLink
        onClick={() => {
          track(OrderEvents.CheckoutGotoCatalog, producerToEventParams(producer))
        }}
        to={generateCountryPath(AppRoutes.Root)}
      >
        {t('confirmationForm.nextOrderLink')}
      </Styled.CompleteLink>
      <Styled.CompleteLink
        onClick={() => {
          track(OrderEvents.CheckoutOrderListTap, producerToEventParams(producer))
        }}
        to={generateCountryPath(AppRoutes.Orders)}
      >
        {t('confirmationForm.orderListLink')}
      </Styled.CompleteLink>
      {renderPixel && <FacebookPixel />}
    </Styled.CompeteContainer>
  )
}

const StepForm: React.FC<{ producerSlug: string; checkProductOptions?: () => boolean }> = ({
  producerSlug,
  checkProductOptions,
}) => {
  const { t } = useTranslation('checkout')
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated)
  const isProfileFulfilled = useSelector(AuthSelectors.isProfileFulfilled)
  const [, producer] = useProducer(producerSlug)
  const stepChangeAction = useAction(CheckoutActions.stepUpdated)
  const step = useSelector(CheckoutSelectors.step)
  const submitOrderAction = useAction(CheckoutActions.orderCreateRequested)
  const submitOrderSkuAction = useAction(CheckoutActions.orderSkuCreateRequested)
  const cartItems = useSelector(state => CartSelectors.cartItemsByProducer(state, producerSlug))
  const cartSkuItems = useSelector(CartSkuSelectors.cartItemsBySellerSlug(producerSlug))
  const isStorefront = producer?.sku_orders

  const handleAuthSuccess = () => {
    stepChangeAction(CheckoutStep.INFO)
  }

  const handleSubmit = (producerId?: string) => {
    isStorefront ? submitOrderSkuAction(producerId) : submitOrderAction(producerId)
  }

  const handleTabClick = useCallback(
    (step: number) => {
      const optionsIsValid = checkProductOptions ? checkProductOptions() : true
      if (!optionsIsValid) {
        return
      }
      stepChangeAction(step)
    },
    [checkProductOptions, stepChangeAction],
  )

  const userOk = isAuthenticated && isProfileFulfilled
  const completed = step === CheckoutStep.SUCCESS
  const needDocuments = useMemo(() => {
    const needSignInProductCart = cartItems.some(item => item.product.docusign_document_id)
    const needSignInSkuCart = cartSkuItems.some(item => item.card?.docusign_document_id)
    const isStorefront = producer?.sku_orders

    return isStorefront ? needSignInSkuCart : needSignInProductCart
  }, [cartItems, cartSkuItems, producer?.sku_orders])

  const renderPixel = useMemo(
    () =>
      cartItems.some(item =>
        ['komplekt-lg59580-ekspress-gold', 'komplekt-lg50479-ekspress-gold'].includes(item.product.slug),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  const hasAccess = useCompanyFeatureFlags(producerSlug)
  const skipRetailerSelection = hasAccess([
    generateCustomFeatureFlag(Sections.Checkout, CustomFeatureName.SkipRetailerSelection),
  ])
  const { track } = useAnalyticsSSR()

  return (
    <Styled.StepFormContainer wide={step === CheckoutStep.CONFIRMATION}>
      <StepSection.Container
        step={step}
        completed={completed}
        completeContent={<StepComplete showDocsLink={needDocuments} renderPixel={renderPixel} producer={producer} />}
        onTabClick={handleTabClick}
      >
        <StepSection.Step id="checkout-step-login" title={t('confirmationForm.stepSignIn')} disabled={userOk}>
          <StepLogin onLoginSuccess={handleAuthSuccess} />
        </StepSection.Step>
        {!skipRetailerSelection && (
          <StepSection.Step
            id="checkout-step-retailer"
            title={t('confirmationForm.stepOrderInfo')}
            disabled={!userOk || completed}
          >
            <StepOrderInfo
              producer={producer}
              onNext={() => stepChangeAction(CheckoutStep.CONFIRMATION)}
              checkProductOptions={checkProductOptions}
            />
          </StepSection.Step>
        )}
        <StepSection.Step
          id="checkout-step-confirmation"
          title={t('confirmationForm.stepConfirmation')}
          disabled={!userOk || completed}
        >
          <StepConfirmation
            onBack={() => {
              track(OrderEvents.CheckoutConfirmBackTap, producerToEventParams(producer))
              stepChangeAction(CheckoutStep.INFO)
            }}
            onNext={() => handleSubmit(producer?.id)}
            checkProductOptions={checkProductOptions}
          />
        </StepSection.Step>
      </StepSection.Container>
    </Styled.StepFormContainer>
  )
}

const CheckoutProduct: React.FC<{ producer?: Company }> = ({ producer }) => {
  const { t } = useTranslation('checkout')
  useHelmet({ title: t('metaTitle') })
  usePersistentScroll('checkout')
  const products = useSelector(state => CartSelectors.cartItemsByProducer(state, producer?.slug || ''))
  const progress = useSelector(CartSelectors.initProgress)
  const isMobile = useMatchMedia(queries.mobile)
  const step = useSelector(CheckoutSelectors.step)
  const [sticky, setSticky] = useState(false)
  const generateCountryPath = useCountryPath()
  const [optionsRequiredErrors, setOptionsRequiredErrors] = useState<string[][]>([])
  const [seedTreatmentErrors, setSeedTreatmentErrors] = useState<boolean[]>([])
  const hasAccess = useCompanyFeatureFlags(producer?.slug || '')

  const isQtyDisabled = hasAccess(
    generateFieldModifierString(FieldLocation.CartList, FieldNames.Qty, FeatureFlagModifiers.Disabled),
  )

  const { anchor } = useScrollTrigger(
    () => {
      setSticky(false)
    },
    () => {
      setSticky(true)
    },
  )

  const requiredOptionsValid = useCallback(
    (id?: string, updatedOptions?: string[], updatedSeedTreatment?: string) => {
      const cartItems = clone(products)
      const errors: string[][] = []
      const seedTreatmentErrors: boolean[] = []
      let isValid = true

      cartItems.forEach((item: CartItem) => {
        const allSeedTreatmentsInactive = item.product.seed_treatment.every(s => !s.is_active)
        if (allSeedTreatmentsInactive) {
          seedTreatmentErrors.push(true)
        } else if (item.product.seed_treatment.length && !item.seed_treatment_id) {
          if (id && id === item.id && updatedSeedTreatment) {
            seedTreatmentErrors.push(true)
          } else {
            seedTreatmentErrors.push(false)
            isValid = false
          }
        } else {
          seedTreatmentErrors.push(true)
        }

        const opts = item.product.options
        const foundedErrors: string[] = []
        if (!opts || !opts.length) {
          errors.push([])
          return
        }

        let options = item.options || []
        if (id && id === item.id && updatedOptions) {
          options = updatedOptions
        }

        opts.forEach(o => {
          const found = o.option_ids.filter(x => options.includes(x))
          if (o.required && !found.length) {
            foundedErrors.push(o.type)
            isValid = false
          }
        })

        errors.push(foundedErrors)
      })

      setOptionsRequiredErrors(errors)
      setSeedTreatmentErrors(seedTreatmentErrors)
      return isValid
    },
    [products],
  )

  const checkProductOptions = useCallback(() => (requiredOptionsValid() ? true : false), [requiredOptionsValid])

  const isCartEmpty = !products.length && step !== CheckoutStep.SUCCESS && progress == Progress.SUCCESS

  if (isCartEmpty) {
    // TODO empty cart screen https://gitlab.agro.club/agroclub/issues/issues/947
    return <Redirect to={generateCountryPath(AppRoutes.Cart)} />
  }

  const showCart = step !== CheckoutStep.CONFIRMATION && step !== CheckoutStep.SUCCESS

  const renderCart = (): React.ReactElement | null => {
    if (!showCart || !producer?.slug) {
      return null
    }
    if (isMobile) {
      return (
        <CartMobile
          isQtyDisabled={isQtyDisabled}
          sticky={sticky}
          producerSlug={producer?.slug}
          optionsRequiredErrors={optionsRequiredErrors}
          seedTreatmentErrors={seedTreatmentErrors}
          requiredOptionsValid={requiredOptionsValid}
        />
      )
    }
    return (
      <CartDesktop
        isQtyDisabled={isQtyDisabled}
        producerSlug={producer?.slug}
        optionsRequiredErrors={optionsRequiredErrors}
        seedTreatmentErrors={seedTreatmentErrors}
        requiredOptionsValid={requiredOptionsValid}
      />
    )
  }

  const breadcrumbs = [
    {
      title: t('cartTitle'),
      path: producer?.slug
        ? generateCountryPath(AppRoutes.ProducerCart, { producerSlug: producer?.slug })
        : generateCountryPath(AppRoutes.Cart),
    },
    { title: t('metaTitle') },
  ]

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} />
      <Styled.Wrapper>
        <StepForm producerSlug={producer?.slug || ''} checkProductOptions={checkProductOptions} />
        <Styled.CartContainer>{renderCart()}</Styled.CartContainer>

        {/*
          just because wrapper has padding-bottom
          and we need to trigger anchor appearance handler properly
          (place it on exact bottom of the Wrapper)
        */}
        <div style={{ position: 'absolute', bottom: '0' }}>{anchor}</div>
      </Styled.Wrapper>
    </div>
  )
}

const CheckoutSku: React.FC<{ producer?: Company }> = ({ producer }) => {
  const { t } = useTranslation('checkout')
  useHelmet({ title: t('metaTitle') })
  usePersistentScroll('checkout')
  const cartItems = useSelector(CartSkuSelectors.cartItemsBySellerSlug(producer?.slug || ''))
  const progress = useSelector(CartSkuSelectors.initProgress)
  const isMobile = useMatchMedia(queries.mobile)
  const step = useSelector(CheckoutSelectors.step)
  const [sticky, setSticky] = useState(false)
  const generateCountryPath = useCountryPath()

  const { anchor } = useScrollTrigger(
    () => {
      setSticky(false)
    },
    () => {
      setSticky(true)
    },
  )

  const isCartEmpty = !cartItems.length && step !== CheckoutStep.SUCCESS && progress == Progress.SUCCESS
  const optionsNotChosen = cartItems.some(item => !item.sku)

  if (isCartEmpty || optionsNotChosen) {
    return <Redirect to={generateCountryPath(AppRoutes.Cart)} />
  }

  const showCart = step !== CheckoutStep.CONFIRMATION && step !== CheckoutStep.SUCCESS

  const renderCart = (): React.ReactElement | null => {
    if (!showCart || !producer?.slug) {
      return null
    }
    if (isMobile) {
      return <CartSkuMobile sticky={sticky} producerSlug={producer?.slug} />
    }
    return <CartSkuDesktop producerSlug={producer?.slug} />
  }

  const breadcrumbs = [
    {
      title: t('cartTitle'),
      path: producer?.slug
        ? generateCountryPath(AppRoutes.ProducerCart, { producerSlug: producer?.slug })
        : generateCountryPath(AppRoutes.Cart),
    },
    { title: t('metaTitle') },
  ]

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} />
      <Styled.Wrapper>
        <StepForm producerSlug={producer?.slug || ''} />
        <Styled.CartContainer>{renderCart()}</Styled.CartContainer>
        <div style={{ position: 'absolute', bottom: '0' }}>{anchor}</div>
      </Styled.Wrapper>
    </div>
  )
}

const Checkout: React.FC = () => {
  const { producerSlug } = useParams<{ producerSlug: string }>()
  const [, producer] = useProducer(producerSlug)
  const generateCountryPath = useCountryPath()
  const cartInfo = useSelector(CartSelectors.cartInfo)
  const cartSkuInfo = useSelector(CartSkuSelectors.cartInfo)

  if (!producer) return null

  if (!producerSlug) {
    let slug = ''
    if (Object.keys(cartInfo).length) {
      slug = Object.keys(cartInfo)[0]
    } else if (Object.keys(cartSkuInfo).length) {
      slug = Object.keys(cartSkuInfo)[0]
    }

    if (slug) {
      const path = generateCountryPath(AppRoutes.CheckoutProducer, { producerSlug: slug })
      return <Redirect to={path} />
    }
  }

  return producer.sku_orders ? <CheckoutSku producer={producer} /> : <CheckoutProduct producer={producer} />
}

export default Checkout

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import appStore from 'assets/images/app-store-badge.svg'
import googlePlay from 'assets/images/google-play-badge.svg'
import useAnalyticDataFromLocation from 'hooks/useAnalyticDataFromLocation'
import useLangPicker from 'hooks/useLangPicker'
import useMatchMedia from 'hooks/useMatchMedia'
import useUserAgentDevice from 'hooks/useUserAgentDevice'
import useWhiteLabelConfig from 'hooks/useWhiteLabelConfig'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { queries } from 'theme/theme'
import { FooterEvents } from './events'
import * as Styled from './styles'
// import useUserAgentDevice from 'hooks/useUserAgentDevice'

const currentYear = new Date().getFullYear()

const Footer: React.FC = () => {
  const { t } = useTranslation(['footer', 'common'])
  const { track } = useAnalyticsSSR()
  const analyticData = useAnalyticDataFromLocation()

  // const lang = i18n.language.slice(0, 2)
  const { pick } = useLangPicker()
  const { config: wlConfig } = useWhiteLabelConfig()
  const config = wlConfig?.legal_docs
  const contactsConfig = wlConfig?.contacts
  const logoUrl = wlConfig?.logo_url
  const copyright = wlConfig?.copyright
  const appLinks = wlConfig?.mobile_apps?.links
  const hasLogo = Boolean(logoUrl)
  const hasFooterLogo = Boolean(!!wlConfig?.footer_logo_url)
  const { os } = useUserAgentDevice()
  const isIOS = useMemo(() => {
    if (process.browser) {
      return ['iPhone', 'iPad', 'MacIntel'].some(str => navigator.platform.indexOf(str) !== 0)
    }
    return os === 'iOS'
  }, [os])
  const isMobile = useMatchMedia(queries.mobile)
  const isTablet = useMatchMedia(queries.tablet)

  const wideFooter = Boolean(
    (hasLogo || hasFooterLogo) && config?.user_agreement && config?.privacy_policy && config?.cookies_policy,
  )

  const isLGCompany = wlConfig?.company_official_names?.some(
    item => item.official_name === 'SEMILLAS ONLINE' || item.official_name === 'LG Life Sciences',
  )

  const needToShowAppleLink = !!(((isLGCompany && !isMobile) || isIOS) && appLinks?.app_store)
  const needToShowGoogleLink = !!(((isLGCompany && !isMobile) || !isIOS) && appLinks?.google_play)
  const needToShowAppLinks = isLGCompany || (isMobile && (needToShowAppleLink || needToShowGoogleLink))

  return (
    <>
      <Styled.Wrapper hasLogo={hasLogo} hasFooterLogo={hasFooterLogo} isWide={wideFooter}>
        <Styled.Area area={'copyright'} isFooterWide={wideFooter}>
          <Styled.Text>
            {copyright} © {currentYear}
          </Styled.Text>
        </Styled.Area>
        <Styled.Area area={'policies'} isFooterWide={wideFooter}>
          {/* {config?.user_agreement && (
            <Styled.Link>
              <a
                onClick={() => track(FooterEvents.BasementClick, { type: 'user_agreement', ...analyticData })}
                href={config?.user_agreement ? pick(config.user_agreement) : `/agreement_${lang}.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('common:termsOfUse')}
              </a>
            </Styled.Link>
          )} */}
          {config?.privacy_policy && (
            <Styled.Link>
              <a
                onClick={() => track(FooterEvents.BasementClick, { type: 'privacy_policy', ...analyticData })}
                // href={config?.privacy_policy ? pick(config.privacy_policy) : `/privacy-policy_${lang}.pdf`}
                href="https://canterra.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('common:privacyPolicy')}
              </a>
            </Styled.Link>
          )}
          {/* {config?.cookies_policy && (
            <Styled.Link>
              <a
                onClick={() => track(FooterEvents.BasementClick, { type: 'cookies_policy_link', ...analyticData })}
                href={pick(config.cookies_policy)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('common:cookiesPolicy')}
              </a>
            </Styled.Link>
          )} */}
        </Styled.Area>
        {needToShowAppLinks && (
          <>
            {(isTablet || isMobile) && (
              <Styled.Area area={'apps-text'}>
                <Styled.AppsText dangerouslySetInnerHTML={{ __html: t('getAppsText') }} />
              </Styled.Area>
            )}
            <Styled.Area area={'apps'}>
              <Styled.Apps>
                {needToShowAppleLink && (
                  <Styled.AppBadge
                    onClick={() => track(FooterEvents.BasementClick, { type: 'apple_store', ...analyticData })}
                    href={appLinks?.app_store}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={appStore} alt={'App Store'} />
                  </Styled.AppBadge>
                )}
                {needToShowGoogleLink && (
                  <Styled.AppBadge
                    onClick={() => track(FooterEvents.BasementClick, { type: 'google_play', ...analyticData })}
                    href={appLinks?.google_play}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={googlePlay} alt={'Google Play'} />
                  </Styled.AppBadge>
                )}
              </Styled.Apps>
            </Styled.Area>
          </>
        )}
        <Styled.Area area={'contacts'} isFooterWide={wideFooter}>
          {contactsConfig?.phone && (
            <Styled.Text>
              <a
                onClick={() => track(FooterEvents.BasementClick, { type: 'our_phone', ...analyticData })}
                href={`tel:${contactsConfig?.phone.value ? contactsConfig?.phone.value : t('common:supportTel')}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {contactsConfig?.phone.title ? pick(contactsConfig.phone.title) : t('common:supportTel')}
              </a>
            </Styled.Text>
          )}
          <Styled.Link>
            <a
              onClick={() => track(FooterEvents.BasementClick, { type: 'our_email', ...analyticData })}
              href={`mailto:${contactsConfig?.email?.value ? contactsConfig?.email?.value : t('common:supportEmail')}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contactsConfig?.email?.title ? pick(contactsConfig.email.title) : t('common:supportEmail')}
            </a>
          </Styled.Link>
        </Styled.Area>
        {hasFooterLogo && (
          <Styled.Area area={'left-logo'} isFooterWide={wideFooter}>
            <Styled.WhiteLabelLogo src={wlConfig?.footer_logo_url} />
          </Styled.Area>
        )}
      </Styled.Wrapper>
    </>
  )
}

export default Footer

import { CardBestOffer } from 'modules/domain/card/types'
import { getPrettyPrice } from 'modules/utils/helpers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Currency } from 'types/entities'
import { SuggestionWrapper } from './SuggestionWrapper'

export type BestOfferSuggestionProps = React.HTMLAttributes<HTMLDivElement> & {
  currency: Currency
  bestOffer?: CardBestOffer
}

const Title = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  text-align: center;
  color: ${({ theme }) => theme.color.accentApproving};
`

const Content = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 6px 0 0 0;
  text-align: center;
  color: ${({ theme }) => theme.color.textPrimary};
`

export const BestOfferSuggestion: React.VFC<BestOfferSuggestionProps> = ({ currency, bestOffer, ...props }) => {
  const { t } = useTranslation('discount')

  if (!bestOffer) return null

  return (
    <SuggestionWrapper {...props}>
      <Title data-test-id={'special-offer-widget-title'}>
        {t('bestOfferTitle', { amount: bestOffer?.amount && getPrettyPrice(+bestOffer.amount, currency) })}
      </Title>
      <Content data-test-id={'special-offer-widget-content'}>{t('bestOfferDescription')}</Content>
    </SuggestionWrapper>
  )
}

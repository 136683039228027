import { useSelector } from 'react-redux'
import { Progress, ResourceHook } from 'modules/types'
import { useDidMount, useAction, usePageQuery } from '@agro-club/frontend-shared'
import OrderActions from './duck'
import OrderSelectors from './selectors'
import { Order } from 'types/order'

export const useOrderList: ResourceHook<Order[]> = () => {
  const progress = useSelector(OrderSelectors.listFetchProgress)
  const list = useSelector(OrderSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(OrderActions.listRequested, page ? { page } : {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const useOrder: ResourceHook<Order> = (id: string) => {
  const progress = useSelector(OrderSelectors.itemFetchProgress)
  const item = useSelector(state => OrderSelectors.item(state, id))
  const fetchAction = useAction(OrderActions.itemRequested, id)

  useDidMount(() => {
    if (!item || (item.id !== id && item.slug !== id)) {
      fetchAction()
    }
  })

  const realProgress = progress === Progress.IDLE && item ? Progress.SUCCESS : progress
  return [realProgress, item]
}

enum ProducerRoutes {
  ProducerInfoPage = '/:country/producer/:producerSlug',
  CategoryList = '/:country/producer/:producerSlug/category',
  SubCategoryList = '/:country/producer/:producerSlug/category/:categorySlug/sub-category',
  ProductOrCardList = '/:country/producer/:producerSlug/category/:categorySlug/sub-category/:subCategorySlug',
  ProductOrCardItem = '/:country/producer/:producerSlug/category/:categorySlug/sub-category/:subCategorySlug/product/:productOrCardSlug',

  // deprecated routs, needed for redirect
  ProductListDeprecated = '/producer/:producerSlug/category/:categorySlug',
  ProductItemDeprecated = '/producer/:producerSlug/category/:categorySlug/product/:productOrCardSlug',

  ProductListNoCountry = '/producer/:producerSlug/category/:categorySlug/sub-category/:subCategorySlug',
  ProductItemNoCountry = '/producer/:producerSlug/category/:categorySlug/sub-category/:subCategorySlug/product/:productOrCardSlug',
  ProducerListPageNoCountry = '/producer',
  ProducerInfoPageNoCountry = '/producer/:producerSlug',
  CategoryListNoCountry = '/producer/:producerSlug/category',
  SubCategoryListNoCountry = '/producer/:producerSlug/category/:categorySlug/sub-category',
}

export default ProducerRoutes

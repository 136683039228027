import { endpoints } from 'modules/endpoints'
import { ProducerListRequestSorting, ProducerListRequestFilter } from './types'
import { ListResponse } from 'types/api'
import { Company, CompanyType } from 'types/entities'
import { ApiService } from 'service/api/interface'

export const getList = (apiClient: ApiService) => (
  filter: ProducerListRequestFilter,
  sorting: ProducerListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<Company>>(endpoints.producer(), {
    ...filter,
    ...sorting,
    company_type: CompanyType.Producer,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (apiClient: ApiService) => (id: string) => {
  return apiClient.get<Company>(endpoints.producer(id))
}

import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter as Router } from 'connected-react-router'
import GlobalStyle from 'views/globalStyles'
import RootComponent from 'views/RootComponent'
import { Sentry } from 'sentry'
import { HelmetProps, HelmetContext } from 'hooks/useHelmet'
import analyticsInstance from 'initAnalytics'
import { AnalyticsProvider } from 'use-analytics'
import { theme } from 'theme/theme'
import { ThemeProvider } from 'styled-components'
import { Store } from 'redux'
import { History } from 'history'
import { WhiteLabelContext } from 'hooks/useWhiteLabelConfig'
import '@agro-club/frontend-shared/dist/esm/style.css'
import { WhiteLabelConfig } from 'service/whiteLabelConfig/interface'
import { ApiService as IApiService } from 'service/api/interface'
import { ApiServiceContext } from 'hooks/useApiService'

class App extends React.Component<{
  store: Store
  history: History<unknown>
  wlConfig: { config: WhiteLabelConfig }
  apiService: IApiService
}> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.handleReactException(error, errorInfo, this.props.store.getState())
  }

  state = {
    helmet: {},
    updateHelmet: (props: HelmetProps) => this.setState({ helmet: props }),
  }

  static contextType = WhiteLabelContext

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <WhiteLabelContext.Provider value={this.props.wlConfig}>
        <Provider store={this.props.store}>
          <HelmetContext.Provider value={{ helmet: this.state.helmet, updateHelmet: this.state.updateHelmet }}>
            <AnalyticsProvider instance={analyticsInstance}>
              <ThemeProvider theme={theme}>
                <ApiServiceContext.Provider value={{ apiService: this.props.apiService }}>
                  <GlobalStyle />
                  <Router history={this.props.history}>
                    <RootComponent />
                  </Router>
                </ApiServiceContext.Provider>
              </ThemeProvider>
            </AnalyticsProvider>
          </HelmetContext.Provider>
        </Provider>
      </WhiteLabelContext.Provider>
    )
  }
}

export default App

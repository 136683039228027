import { all, call, put, select, takeLatest, fork, take, race } from 'redux-saga/effects'
import { LOCATION_CHANGE, LocationChangeAction, push, replace } from 'connected-react-router'

import AuthActions from './duck'
import {
  checkSmsCode,
  fetchCurrentUser,
  requestSmsCode,
  updateCurrentUser,
  obtainUuid,
  createUserGdprRequest,
  logout,
} from './managers'
import AuthSelectors from './selectors'
import { Routes } from 'views/pages/routes'
import { TokensPair } from '@agro-club/frontend-shared'
import { Status } from 'types/entities'
import CartActions from 'modules/domain/cart/duck'
import CartSkuActions from 'modules/domain/cartSku/duck'
import { apiCall, getAnalyticsInstance, getTokenService } from 'modules/sagaEffects'
import { TokenService } from 'service/token/interface'
import env from 'env'
import CollectionActions from '../collection/duck'
import { UserProfile } from 'types/entities/userProfile'
import { generateCountryPath } from 'modules/sagaHelpers'
import { RequestError } from 'service/api/errors'

export const getLocation = function*() {
  const pathname: string = yield select(AuthSelectors.pathname)
  const search: string = yield select(AuthSelectors.search)
  const state = yield select(AuthSelectors.locationState)
  let fromPath: string | null = null

  const rootPath = yield call(generateCountryPath, Routes.Root)
  const signInPath = yield call(generateCountryPath, Routes.SignIn)

  if (state) {
    const fromState = state.from || null
    fromPath = fromState === signInPath ? rootPath : fromState || null
  }

  if (pathname === signInPath) {
    return {
      pathname: `${rootPath}${search}`,
      from: fromPath,
    }
  }

  return {
    pathname: `${pathname}${search}`,
    from: fromPath,
    search,
  }
}

export const initSaga = function*(_action: ReturnType<typeof AuthActions.initRequested>) {
  const { pathname, from } = yield call(getLocation)
  const tokenService: TokenService = yield getTokenService()
  try {
    const token = tokenService.getAccessToken()
    const uuid = tokenService.getUuid()
    yield put(CollectionActions.promoInfoRequested())

    if (!uuid) {
      yield put(AuthActions.uuidRequested())
      const { fail } = yield race({
        success: take(AuthActions.uuidRequestSucceed.type),
        fail: take(AuthActions.uuidRequestFailed.type),
      })
      if (fail) {
        throw new Error('UUID fetch failed.')
      }
    }

    if (token) {
      const response: UserProfile = yield apiCall(fetchCurrentUser)
      if (response.status === Status.Active) {
        yield put(AuthActions.initRequestSucceed(response))
        yield put(replace(from || pathname))
        return
      }
    }
    tokenService.clearToken()
    yield put(AuthActions.initRequestFailed())
  } catch (e) {
    tokenService.clearToken()
    yield put(AuthActions.initRequestFailed())
    const signInPath = yield call(generateCountryPath, Routes.SignIn)
    yield put(replace(signInPath, { from: pathname }))
  }
}

export const verifySmsCodeSaga = function*({
  payload: [phone, code, analyticsPayload],
}: ReturnType<typeof AuthActions.smsCodeVerificationRequested>) {
  try {
    const tokensPair: TokensPair = yield apiCall(checkSmsCode, phone, code)
    const tokenService: TokenService = yield getTokenService()
    const analyticsInstance = yield getAnalyticsInstance()
    tokenService.saveAccessToken(tokensPair.accessToken)
    tokenService.saveRefreshToken(tokensPair.refreshToken)
    const userProfile: UserProfile = yield apiCall(fetchCurrentUser)
    analyticsInstance.track('signin_success', analyticsPayload)
    if (userProfile.status === Status.Active) {
      analyticsInstance.identify(userProfile.id, {
        id: userProfile.id,
        email: userProfile.email,
        country: userProfile.country,
      })
      yield put(AuthActions.smsCodeVerificationsSucceed(tokensPair, userProfile))
      return
    }
    throw new Error('TODO: inactive user error text')
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
    yield put(AuthActions.smsCodeVerificationFailed(err as any))
  }
}

export const updateTokensSaga = function*({
  payload: [token, _profile],
}: ReturnType<typeof AuthActions.smsCodeVerificationsSucceed>) {
  const tokenService: TokenService = yield getTokenService()
  tokenService.saveAccessToken(token.accessToken)
  tokenService.saveRefreshToken(token.refreshToken)
}

export const updateUserProfile = function*({ payload }: ReturnType<typeof AuthActions.userUpdateRequested>) {
  try {
    const profile = yield apiCall(updateCurrentUser, payload)
    yield put(AuthActions.userUpdateSucceed(profile))
  } catch (err) {
    yield put(AuthActions.userUpdateFailed())
  }
}

export const fetchUuidSaga = function*(_: ReturnType<typeof AuthActions.uuidRequested>) {
  try {
    const uuid = yield apiCall(obtainUuid)
    const tokenService: TokenService = yield getTokenService()
    tokenService.saveUuid(uuid.uuid)
    yield put(AuthActions.uuidRequestSucceed())
  } catch (err) {
    yield put(AuthActions.uuidRequestFailed())
  }
}

export const phoneSubmitSaga = function*({ payload }: ReturnType<typeof AuthActions.phoneSubmitted>) {
  try {
    yield apiCall(requestSmsCode, payload)
    yield put(AuthActions.submitSucceed())
  } catch (err) {
    yield put(AuthActions.submitFailed('TODO add error text'))
  }
}

export const emailSubmitSaga = function*(_action: ReturnType<typeof AuthActions.emailSubmitted>) {
  // try {
  //
  // } catch (err){
  //
  // }
}

export const signOutSaga = function*(_action: ReturnType<typeof AuthActions.signOutRequested>) {
  const tokenService: TokenService = yield getTokenService()
  const refreshToken = yield call(tokenService.getRefreshToken)
  yield apiCall(logout, refreshToken)
  tokenService.clearToken()
  const path = yield call(generateCountryPath, Routes.SignIn)
  yield put(push(path))
  yield put(AuthActions.initRequested())
}

export const userRegisterSaga = function*({
  payload: [profilePayload, analyticsPayload],
}: ReturnType<typeof AuthActions.userRegisterRequested>) {
  try {
    const profile: UserProfile = yield apiCall(updateCurrentUser, profilePayload)
    const analyticsInstance = yield getAnalyticsInstance()

    analyticsInstance.track('signup_success', analyticsPayload)
    yield put(AuthActions.userRegisterSucceed(profile))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(AuthActions.userRegisterFailed(errType))
  }
}

const routerWatcher = function*() {
  while (1) {
    const action: LocationChangeAction = yield take(LOCATION_CHANGE)
    if (action.payload.isFirstRendering && !env.BROWSER) {
      yield put(AuthActions.initRequested())
      yield race({
        success: take(AuthActions.initRequestSucceed.type),
        fail: take(AuthActions.initRequestFailed.type),
      })
      yield put(CartActions.cartInitRequested())
      yield put(CartSkuActions.cartInitRequested())
    }
  }
}

export const gdprSendSaga = function*({ payload }: ReturnType<typeof AuthActions.gdprSendRequested>) {
  try {
    yield apiCall(createUserGdprRequest, payload)
    yield put(AuthActions.gdprSendSucceed())
  } catch (err) {
    yield put(AuthActions.gdprSendFailed())
  }
}

export const gdprEraseSaga = function*({ payload }: ReturnType<typeof AuthActions.gdprEraseRequested>) {
  try {
    yield apiCall(createUserGdprRequest, payload)
    yield put(AuthActions.gdprEraseSucceed())
  } catch (err) {
    yield put(AuthActions.gdprEraseFailed())
  }
}

const AuthSaga = function*() {
  yield all([
    takeLatest(AuthActions.initRequested.type, initSaga),
    takeLatest(AuthActions.uuidRequested.type, fetchUuidSaga),
    takeLatest(AuthActions.phoneSubmitted.type, phoneSubmitSaga),
    takeLatest(AuthActions.emailSubmitted.type, emailSubmitSaga),
    takeLatest(AuthActions.smsCodeVerificationRequested.type, verifySmsCodeSaga),
    takeLatest(AuthActions.smsCodeVerificationsSucceed.type, updateTokensSaga),
    takeLatest(AuthActions.userUpdateRequested.type, updateUserProfile),
    takeLatest(AuthActions.signOutRequested.type, signOutSaga),
    takeLatest(AuthActions.userRegisterRequested.type, userRegisterSaga),
    fork(routerWatcher),
    takeLatest(AuthActions.gdprSendRequested.type, gdprSendSaga),
    takeLatest(AuthActions.gdprEraseRequested.type, gdprEraseSaga),
  ])
}

export default AuthSaga

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Breadcrumbs from 'views/ui/Breadcrumbs/Breadcrumbs'
import LoginForm from 'views/components/LoginForm/LoginForm'
import useHelmet from 'hooks/useHelmet'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import { Routes } from 'views/pages/routes'
import { SectionBody, SectionContainer, SectionTitle } from '@agro-club/frontend-shared'
import { useCountryPath } from 'hooks/useCountryPath'

const Wrapper = styled.div`
  ${props => props.theme.media.desktop`
    max-width: 628px;
  `}
  ${props => props.theme.media.tablet`
    max-width: 468px;
  `}
  ${props => props.theme.media.mobile`
    width: 100%;
  `}
`

const SignInPage: React.FC = () => {
  const { t } = useTranslation(['auth', 'common'])
  const history = useHistory()
  const locationState = useSelector(AuthSelectors.locationState)
  const generateCountryPath = useCountryPath()
  useHelmet({ title: t('metaTitle') })

  const authSuccessAction = () => {
    history.replace(locationState?.from || generateCountryPath(Routes.Root))
  }

  return (
    <div>
      <Breadcrumbs />
      <Wrapper>
        <SectionContainer noDivider>
          <SectionTitle>{t('signIn')}</SectionTitle>
          <SectionBody paddedOnMobile noGrid>
            <LoginForm onLoginSuccess={authSuccessAction} />
          </SectionBody>
        </SectionContainer>
      </Wrapper>
    </div>
  )
}

export default SignInPage

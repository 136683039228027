import { CountryCode } from 'libphonenumber-js'
import { generatePath } from 'react-router-dom'
import { useDetectedCountry } from './useDetectedCountry'

export const useCountryPath = (overrideCountry?: CountryCode) => {
  const country = useDetectedCountry()

  return (pattern: string, params?: { [paramName: string]: string | number | boolean | undefined }) => {
    return generatePath(pattern, {
      ...params,
      country: params?.country || overrideCountry || country.toLowerCase(),
    })
  }
}

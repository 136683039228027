import { helpersColor } from '@agro-club/frontend-shared'
import useCartInfo from 'hooks/useCartInfo'
import { getPrettyPrice } from 'modules/utils/helpers'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { StyledProps } from 'styled-components'
import { Company, Currency } from 'types/entities'
import * as Styled from './styled'

const Wrapper = styled(Styled.Wrapper)`
  ${props => props.theme.media.mobile`
    border-radius: 0;
    margin: 0;
    box-shadow: none;
    border-bottom: 1px solid ${(props: StyledProps<{}>) => props.theme.color.secondary50};
    padding: 16px;
  `}
`

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 0px 0;

  & > :first-child {
    color: ${props => props.theme.color.onSurfaceMidEmphasys};
  }

  & > :last-child {
    color: ${props => props.theme.color.onPrimaryDark};
  }
`

const TotalTitle = styled(TotalRow)`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.color.onPrimaryDark};
  margin: 0 0 12px 0;
  & > :first-child,
  & > :last-child {
    color: ${props => props.theme.color.onPrimaryDark};
  }
`

const DiscountTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;

  background: ${props => helpersColor.alphaColor(props.theme.color.accentNotify, 0.08)};
  border-radius: 8px;
  margin: 8px -16px -8px -16px;

  ${props => props.theme.media.tablet`
    margin: 8px -18px -8px -18px;
  `}

  ${props => props.theme.media.mobile`
    margin: 8px 0 0 0;
  `}
`

const DiscountTotalTitle = styled(TotalRow)`
  & > :first-child {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.color.accentNotify};
  }
  & > :last-child {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: ${props => props.theme.color.accentNotify};
  }
`

const TotalInfo: React.FC<{ subtotal: number; promo_savings: number; producer?: Company }> = ({
  subtotal,
  promo_savings,
  producer,
}) => {
  const { t } = useTranslation('discount')
  const formatPrice = useCallback((price: number) => getPrettyPrice(price, producer?.currency || Currency.CAD), [
    producer,
  ])

  return (
    <>
      {subtotal > 0 ? (
        <TotalRow data-test-id={'subtotal'}>
          <span>{t('subtotal')}</span>
          <span>{formatPrice(subtotal)}</span>
        </TotalRow>
      ) : null}
      {promo_savings > 0 ? (
        <TotalRow data-test-id={'promo-savings'}>
          <span>{t('promo')}</span>
          <span>{'-' + formatPrice(promo_savings)}</span>
        </TotalRow>
      ) : null}
    </>
  )
}

const DiscountTotal: React.FC<{ producer?: Company }> = ({ producer }) => {
  const { t } = useTranslation('discount')
  const cartInfo = useCartInfo(producer)
  const formatPrice = useCallback((price: number) => getPrettyPrice(price, producer?.currency || Currency.CAD), [
    producer,
  ])
  if (!cartInfo) return null

  const onlyDiscountTotal = cartInfo.total <= 0 && cartInfo.promo_savings <= 0 && cartInfo.subtotal <= 0
  if (cartInfo.total_savings <= 0 && onlyDiscountTotal) return null

  const totalSavingJSX = cartInfo.total_savings ? (
    <DiscountTotalWrapper
      style={onlyDiscountTotal ? { margin: '0', width: '100%', height: '100%', borderRadius: '16px' } : {}}
    >
      <DiscountTotalTitle data-test-id={'total-savings'}>
        <span>{t('discountTotal')}</span>
        <span>{formatPrice(cartInfo.total_savings)}</span>
      </DiscountTotalTitle>
    </DiscountTotalWrapper>
  ) : null
  return (
    <Wrapper data-test-id={'total-widget'} style={onlyDiscountTotal ? { padding: '0' } : {}}>
      {cartInfo.total && cartInfo.total > 0 ? (
        <TotalTitle>
          <span>{t('total')}</span>
          <span>{formatPrice(cartInfo.total)}</span>
        </TotalTitle>
      ) : null}
      <TotalInfo subtotal={cartInfo.subtotal} promo_savings={cartInfo.promo_savings} producer={producer} />
      {totalSavingJSX}
    </Wrapper>
  )
}

const TotalCheckoutWrapper = styled(Wrapper)`
  padding: 0;
`

const TotalCheckoutTitle = styled(TotalRow)`
  border-bottom: 1px solid ${(props: StyledProps<{}>) => props.theme.color.secondary50};
  padding: 20px 40px;
  & > :first-child,
  & > :last-child {
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    color: ${props => props.theme.color.onPrimaryDark};
  }
`

const TotalCheckoutContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 24px 24px 40px;

  & > :nth-child(2) {
    margin-left: 40px;

    ${props => props.theme.media.tablet`
    margin-left: 24px;
  `}
  }
`

const TotalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const TotalDiscountCheckoutWrapper = styled(DiscountTotalWrapper)`
  margin: 0;
  min-width: 262px;
`

export const TotalCheckoutWidget: React.FC<{ producer?: Company }> = ({ producer }) => {
  const { t } = useTranslation('discount')
  const cartInfo = useCartInfo(producer)

  if (!cartInfo) return null

  const totalSavingJSX = cartInfo.total_savings ? (
    <TotalDiscountCheckoutWrapper>
      <DiscountTotalTitle data-test-id={'total-savings'}>
        <span>{t('discountTotal')}</span>
        <span>{getPrettyPrice(cartInfo.total_savings, producer?.currency || Currency.CAD)}</span>
      </DiscountTotalTitle>
    </TotalDiscountCheckoutWrapper>
  ) : null

  return (
    <TotalCheckoutWrapper data-test-id={'total-checkout-widget'}>
      {cartInfo.total > 0 ? (
        <>
          <TotalCheckoutTitle>
            <span>{t('total')}</span>
            <span>{getPrettyPrice(cartInfo.total, producer?.currency || Currency.CAD)}</span>
          </TotalCheckoutTitle>

          <TotalCheckoutContentWrapper>
            <TotalInfoWrapper>
              <TotalInfo subtotal={cartInfo.subtotal} promo_savings={cartInfo.promo_savings} producer={producer} />
            </TotalInfoWrapper>
            {totalSavingJSX}
          </TotalCheckoutContentWrapper>
        </>
      ) : (
        <>
          <TotalCheckoutTitle>
            <span>{t('discountTotal')}</span>
            <span>{getPrettyPrice(cartInfo.total_savings, producer?.currency || Currency.CAD)}</span>
          </TotalCheckoutTitle>

          {(cartInfo.subtotal > 0 || cartInfo.promo_savings > 0) && (
            <TotalCheckoutContentWrapper style={{ padding: '16px 40px 24px' }}>
              <TotalInfoWrapper>
                <TotalInfo subtotal={cartInfo.subtotal} promo_savings={cartInfo.promo_savings} producer={producer} />
              </TotalInfoWrapper>
            </TotalCheckoutContentWrapper>
          )}
        </>
      )}
    </TotalCheckoutWrapper>
  )
}

export default DiscountTotal

import { useScrollToTop } from '@agro-club/frontend-shared'
import { useCountryPath } from 'hooks/useCountryPath'
import useHelmet from 'hooks/useHelmet'
import useLangPicker from 'hooks/useLangPicker'
import { useCard } from 'modules/domain/card/hooks'
import CardSelectors from 'modules/domain/card/selectors'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import CollectionSelectors from 'modules/domain/collection/selectors'
import { PromoInfoType } from 'modules/domain/collection/types'
import { useProducer } from 'modules/domain/producer/hooks'
import { Progress } from 'modules/types'
import { cardToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import { Page } from 'modules/utils/analytics-utils/pageNames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Layout404 from 'views/layouts/404/404'
import ErrorLayout from 'views/layouts/ErrorLayout/ErrorLayout'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { Routes } from 'views/pages/routes'
import Breadcrumbs from 'views/ui/Breadcrumbs/Breadcrumbs'
import DiscountBannerComponent from 'views/ui/DiscountBanner/DiscountBanner'
import { CardDetails } from './components/CardDetails'

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: stretch;
  flex-grow: 1;
  padding: 100px 0;
`

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 40px;
`

const DiscountBanner = styled(DiscountBannerComponent)`
  margin-bottom: 24px;

  ${props => props.theme.media.tablet`
    max-width: 960px;
  `}
`

const CardItem: React.FC = () => {
  useScrollToTop()
  const { t } = useTranslation(['order', 'discountBanner'])
  const params = useParams<{ productOrCardSlug: string; producerSlug: string }>()
  const generateCountryPath = useCountryPath()
  const { pick } = useLangPicker()
  const [progress, card] = useCard(params.producerSlug, params.productOrCardSlug)
  const [, seller] = useProducer(params.producerSlug)

  const cartInitProgress = useSelector(CartSkuSelectors.initProgress)
  const meta = useSelector(state => CardSelectors.meta(state, params.productOrCardSlug))
  const promoInfo = useSelector(state =>
    CollectionSelectors.promoInfo(state, PromoInfoType.CatalogCategory, card?.seller_id, card?.subcategory_id),
  )

  const cardData = useMemo(() => {
    if (progress === Progress.SUCCESS && card) {
      return {
        title: pick(card.title_i18n),
        titleMeta: pick(card.title_meta_i18n) || '',
        descriptionMeta: pick(card.description_meta_i18n) || '',
      }
    }
    return {
      title: '',
    }
  }, [progress, card, pick])

  const breadcrumbs = useMemo(() => {
    const items: { path?: string; title: string }[] = []

    if (progress === Progress.SUCCESS && card) {
      if (seller) {
        items.push({
          path: generateCountryPath(Routes.CategoryList, { producerSlug: seller.slug }),
          title: seller.internal_name,
        })
      }
      if (card.category && seller) {
        items.push({
          path: generateCountryPath(Routes.SubCategoryList, {
            producerSlug: seller.slug,
            categorySlug: card.category.slug,
          }),
          title: card.category.title,
        })
      }
      if (card.subcategory && seller) {
        items.push({
          path: generateCountryPath(Routes.ProductOrCardList, {
            producerSlug: seller.slug,
            categorySlug: card.category?.slug,
            subCategorySlug: card.subcategory.slug,
          }),
          title: card.subcategory.title,
        })
      }
      if (cardData) {
        items.push({
          title: cardData.title,
        })
      }
    }
    return items
  }, [progress, card, seller, cardData, generateCountryPath])

  const promoInfoAnalyticData = {
    page: Page.ProductItem,
    ...cardToEventParams(card),
  }

  useHelmet({ title: cardData.titleMeta, description: cardData.descriptionMeta, ogImage: card?.images?.[0] })

  const loading = () => (
    <SpinnerWrapper>
      <SpinnerLayout />
    </SpinnerWrapper>
  )
  const errorUnknown = () => <ErrorLayout />
  const error404 = () => <Layout404 />

  if (progress === Progress.WORK || cartInitProgress === Progress.WORK) {
    return loading()
  }

  if (!card) {
    return error404()
  }

  if (progress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  return (
    <Wrapper>
      <Breadcrumbs items={breadcrumbs} />
      {promoInfo && (
        <DiscountBanner
          primaryText={promoInfo ? promoInfo.title : t('primary')}
          secondaryText={promoInfo ? promoInfo.description : t('secondary')}
          modalText={promoInfo && promoInfo.message ? promoInfo.message.description : t('modalText')}
          modalTitle={promoInfo && promoInfo.message ? promoInfo.message.title : ''}
          size={'large'}
          styles={promoInfo.styles}
          analyticData={promoInfoAnalyticData}
        />
      )}
      <CardDetails card={card} />
    </Wrapper>
  )
}

export default CardItem

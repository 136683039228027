import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CellProps, useTable } from 'react-table'
import { useTranslation } from 'react-i18next'
import * as Styled from './styled'
import { CartItem, PendingCartItem, UpdateCartItemParams } from 'modules/domain/cart/types'
import { Routes } from 'views/pages/routes'
import useMatchMedia from 'hooks/useMatchMedia'
import { queries } from 'theme/theme'
import CartSelectors from 'modules/domain/cart/selectors'
import { useSelector } from 'react-redux'
import useLangPicker from 'hooks/useLangPicker'
import { Company, Currency, LocalizedValue } from 'types/entities'
import {
  Switch,
  SectionTable,
  SectionTableBody,
  SectionTableBodyCell,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableBodyRow,
  SectionTableHeadRow,
  controlStyle,
  Tooltip,
  NumberInput,
  useDebounce,
  ProcoteWizardData,
  ProductWizardType,
} from '@agro-club/frontend-shared'
import { CartEvents } from '../Cart/events'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { getPrettyPrice } from 'modules/utils/helpers'
import { QtyHintMobile } from 'views/components/QtyHintMobile/QtyHintMobile'
import { QtyDiscountHint } from 'views/components/QtyDiscountHint/QtyDiscountHint'
import { useCountryPath } from 'hooks/useCountryPath'
import ProductOptions, { Place } from '../ProductOptions/ProductOptions'
import { ProductOptions as ProductOptionsType } from 'modules/domain/productOptions/types'
import { placeholderStyleFn } from 'views/components/ProductOptions/styled'
import { Product, SeedTreatment } from 'modules/domain/product/types'
import { useProducer } from 'modules/domain/producer/hooks'
import { producerToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import { Skeleton } from '@agro-club/frontend-shared'

type SeedTreatmentSelectType = {
  seedTreatment: SeedTreatment[]
  value?: string
  onChange: (val: string) => void
  editable?: boolean
  seedTreatmentInvalid: boolean
  mobile?: boolean
}
const SeedTreatmentSelect: React.FC<SeedTreatmentSelectType> = ({
  seedTreatment,
  value,
  onChange,
  editable,
  seedTreatmentInvalid,
  mobile,
}: SeedTreatmentSelectType) => {
  const { t } = useTranslation(['cart', 'productOptions'])
  const { pick } = useLangPicker()

  const treatmentOptions = useMemo(
    () =>
      seedTreatment
        .filter(s => s.is_active || s.id === value)
        .map((st: { title_i18n: LocalizedValue; id: string }) => ({
          title: pick(st.title_i18n),
          id: st.id,
        })),
    [seedTreatment, value, pick],
  )

  const seedTreatmentErrorText = useMemo(() => (seedTreatmentInvalid ? t('validation:field_required') : ''), [
    seedTreatmentInvalid,
    t,
  ])

  const labelJSX = mobile ? (
    <Styled.MobileLabel>{t('seedTreatment')}</Styled.MobileLabel>
  ) : (
    <span>{t('seedTreatment')}</span>
  )
  return treatmentOptions.length ? (
    <>
      {labelJSX}
      <Styled.SeedTreatmentSelect
        name={'seed-treatment-select'}
        customStyles={{
          control: (base, props) => ({ ...controlStyle(base, props), minHeight: '32px', height: '32px' }),
          placeholder: placeholderStyleFn,
        }}
        placeholder={`${t('productOptions:placeholderPrefix')} ${t(
          `productOptions:types.seed_treatment`,
        ).toLowerCase()}`}
        options={treatmentOptions}
        value={value}
        onChange={onChange}
        isDisabled={!editable}
        invalid={seedTreatmentInvalid}
        errorText={seedTreatmentErrorText}
      />
    </>
  ) : null
}

const ProductCell: React.FC<CellProps<CartItem> & {
  showImages?: boolean
  onSeedTreatmentChange: (id: string, val: string) => void
  onOptionsChange: (id: string, options: string[]) => void
  editable?: boolean
  optionsRequiredErrors: string[][]
  seedTreatmentErrors: boolean[]
  productOptions: ProductOptionsType[]
}> = ({
  row,
  showImages = true,
  onSeedTreatmentChange,
  onOptionsChange,
  editable,
  optionsRequiredErrors,
  seedTreatmentErrors,
  productOptions,
}) => {
  const generateCountryPath = useCountryPath()
  const { pick } = useLangPicker()

  const isDetailsAllowed = row.values.product.category?.slug !== 'duck-foot'
  const url = isDetailsAllowed
    ? generateCountryPath(Routes.ProductOrCardItem, {
        producerSlug: row.values.product.producer?.slug,
        categorySlug: row.values.product.category?.slug || '',
        subCategorySlug: row.values.product.subcategory?.slug || '',
        productOrCardSlug: row.values.product?.slug,
      })
    : '#'

  const onSeedTreatmentUpdate = useCallback(val => onSeedTreatmentChange(row.values.id, val as string), [
    onSeedTreatmentChange,
    row.values.id,
  ])

  const handleOptionsChange = useCallback(val => onOptionsChange(row.values.id, val), [onOptionsChange, row.values.id])

  const seedTreatmentInvalid = useMemo(() => !!(seedTreatmentErrors.length && !seedTreatmentErrors[row.index]), [
    row.index,
    seedTreatmentErrors,
  ])

  const getSeedTreatmentSelectStyle = useMemo(() => ({ marginBottom: seedTreatmentInvalid ? '20px' : 'initial' }), [
    seedTreatmentInvalid,
  ])

  return (
    <Styled.ProductCellWrapper>
      <Styled.ClickableProductCell $clickable={isDetailsAllowed} to={url}>
        {showImages && row.values.images ? <Styled.Image url={row.values.images[0]} /> : null}
        <Styled.ProductCellText>
          <Styled.BoldText>{pick(row.values.title)}</Styled.BoldText>
          {row.original.wizard_data?.type === ProductWizardType.Procote ? (
            <ProcoteWizardData data={row.original.wizard_data} />
          ) : (
            <Styled.PackSize>{pick(row.original.default_packaging)}</Styled.PackSize>
          )}
        </Styled.ProductCellText>
      </Styled.ClickableProductCell>

      <Styled.ProductSeedTreatment flexDirection={showImages ? 'row' : 'column'} style={getSeedTreatmentSelectStyle}>
        <SeedTreatmentSelect
          seedTreatment={row.original.product.seed_treatment}
          value={row.original.seed_treatment_id}
          onChange={onSeedTreatmentUpdate}
          editable={editable}
          seedTreatmentInvalid={seedTreatmentInvalid}
        />
      </Styled.ProductSeedTreatment>
      {!row.original.wizard_data && row.original.wizard_comment && (
        <Styled.PackSize>{row.original.wizard_comment}</Styled.PackSize>
      )}
      <ProductOptions
        selectedIds={row.values.options || []}
        options={row.values.product.options || []}
        onChange={handleOptionsChange}
        optionsRequiredErrors={optionsRequiredErrors[row.index]}
        place={Place.CART}
        isDisabled={!editable}
        productOptions={productOptions}
      />
      {row.values.product?.docusign_document_id && row.values.product?.legal_notes ? (
        <Tooltip
          visibleOnHover={true}
          tooltipContent={
            <Styled.ProductCellLegalWarningTooltipContent>
              {row.values.product.legal_notes.description}
            </Styled.ProductCellLegalWarningTooltipContent>
          }
          temporary={true}
        >
          <Styled.ProductCellLegalWarning data-test-id={'legal-hint'}>
            <Styled.IconWarningRed />
            {row.values.product.legal_notes.text}
          </Styled.ProductCellLegalWarning>
        </Tooltip>
      ) : null}
    </Styled.ProductCellWrapper>
  )
}

const DiscountCell: React.FC<CellProps<CartItem>> = ({ value, row }) => {
  const producer = row.values.product.producer
  return (
    <div data-test-id={`discount-cell`} style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
      {!!parseFloat(value) && getPrettyPrice(value, producer.currency, row.values.product.price_type)}
    </div>
  )
}

const CostCell: React.FC<CellProps<CartItem>> = ({ value, row }) => {
  const producer = row.values.product.producer
  const formatPrice = useCallback((price: number) => getPrettyPrice(price, producer.currency), [producer.currency])
  return (
    <Styled.CostWrapper>
      <Styled.CostTotal>{formatPrice(value)}</Styled.CostTotal>
      {row.values.product.category.slug !== 'duck-foot' && (
        <Styled.CostPrice>{formatPrice(row.values.product.price)}</Styled.CostPrice>
      )}
    </Styled.CostWrapper>
  )
}

const RemoveBtn: React.FC<{ id: string; product: Product; onClick?: (id: string) => void }> = ({
  product,
  id,
  onClick,
}) => {
  const { track } = useAnalyticsSSR()
  const handleClick = useCallback(() => {
    onClick && onClick(id)
    track(CartEvents.UpdateCart, { product: product.id, action: 'delete', ...producerToEventParams(product.producer) })
  }, [id, onClick, product, track])
  return <Styled.Remove onClick={handleClick} />
}

const RemoveCell: React.FC<CellProps<CartItem> & { editable?: boolean; onRemove: (id: string) => void }> = ({
  row,
  editable,
  onRemove,
}) => {
  return editable ? (
    <span data-test-id={`remove-product-${row.values.product_id}`}>
      <RemoveBtn id={row.values.id} product={row.values.product} onClick={onRemove} />
    </span>
  ) : null
}

const Qty: React.FC<{
  value: number
  max?: number
  min?: number
  producerSlug: string
  id: string
  onChange?: (id: string, qty: number) => void
  disabled?: boolean
}> = ({ value, onChange, id, disabled, max, min, producerSlug }) => {
  const [qty, setQty] = useState(value)
  const isMobile = useMatchMedia(queries.mobile)
  const qtySuggestions = useSelector(state => CartSelectors.nextDiscountLvlSuggestions(state, producerSlug))
  const hasDiscount = useSelector(CartSelectors.hasDiscount(producerSlug, id))
  const [, producer] = useProducer(producerSlug)
  const { track } = useAnalyticsSSR()
  const handleChange = useCallback(
    value => {
      setQty(value)
      onChange && onChange(id, value)
    },
    [id, onChange],
  )

  useEffect(() => {
    setQty(value)
  }, [value])

  return (
    <>
      <NumberInput
        min={min}
        max={max}
        selectTextOnFocus={true}
        key={id}
        value={qty}
        onDecrease={val =>
          track(CartEvents.UpdateCart, {
            action: 'minus',
            qty: val,
            ...producerToEventParams(producer),
          })
        }
        onIncrease={val =>
          track(CartEvents.UpdateCart, {
            action: 'plus',
            qty: val,
            ...producerToEventParams(producer),
          })
        }
        onChange={val => handleChange(val)}
        size={'medium'}
        disabled={disabled}
        testId={'item-count'}
      />
      {!disabled && !isMobile && hasDiscount && (
        <QtyDiscountHint
          // Exclude negative and zero suggestions and find min qty to apply next level discount.
          missingQty={qtySuggestions[id]}
          onAddItems={amount => {
            handleChange(qty + amount)
            track(CartEvents.UpdateCart, {
              action: 'add_more',
              qty: qty + amount,
              ...producerToEventParams(producer),
            })
          }}
          // If max suggestion for category equals to zero or negative number - it is max discount
          isHighestDiscountReached={qtySuggestions[id] === 0}
          active={!disabled}
        />
      )}
    </>
  )
}

const AltPackagingInput: React.FC<{
  itemId: string
  initialValue: number
  altPackagingTitle: string
  editable: boolean
  onPackagingChange: (id: string, val: number) => void
  producer?: Company
}> = ({ itemId, initialValue, altPackagingTitle, editable, onPackagingChange, producer }) => {
  const { t } = useTranslation('cart')
  const [value, setValue] = useState(initialValue || 0)
  const [applyAltPackaging, setApplyCustomPackaging] = useState(!!initialValue)
  const { track } = useAnalyticsSSR()
  const onChange = useCallback(
    val => {
      onPackagingChange(itemId, val)
    },
    [onPackagingChange, itemId],
  )

  const isInitial = useRef(true)
  const debouncedValue = useDebounce(value, 300)

  const handleInputChange = useCallback(e => {
    const value = e.target.value.replace(/\D/g, '')
    const parsedValue = parseInt(value)
    setValue(Number.isNaN(parsedValue) ? 0 : parsedValue)
  }, [])

  useEffect(() => {
    if (!isInitial.current) {
      onChange(applyAltPackaging ? debouncedValue : 0)
    } else {
      isInitial.current = false
    }
  }, [onChange, debouncedValue, applyAltPackaging])
  return (
    <Styled.PackagingWrapper>
      <Styled.PackagingSwitchWrapper>
        <span>{t('list.enableAltPackaging')}</span>
        <Switch
          name={'alt-packaging-switch'}
          on={applyAltPackaging}
          onClick={val => {
            setApplyCustomPackaging(val)
            track(CartEvents.UpdateCart, {
              action: 'add_custom_packaging',
              value: val,
              ...producerToEventParams(producer),
            })
          }}
          disabled={!editable}
        />
      </Styled.PackagingSwitchWrapper>
      <Styled.PackagingHint>{t('list.altPackagingHint')}</Styled.PackagingHint>
      <Styled.PackagingInputWrapper show={applyAltPackaging}>
        <Styled.PackagingLabel size={'small'}>{altPackagingTitle}</Styled.PackagingLabel>
        <Styled.PackagingInput
          name={'packaging'}
          inputStyles={{ height: '100%' }}
          value={value}
          onChange={handleInputChange}
          readOnly={!editable}
        />
      </Styled.PackagingInputWrapper>
    </Styled.PackagingWrapper>
  )
}

const PackagingCell: React.FC<CellProps<CartItem> & {
  editable?: boolean
  onPackagingChange: (id: string, params: UpdateCartItemParams['packaging']) => void
  showImages?: boolean
}> = ({ cell, row, editable, onPackagingChange }) => {
  const { pick } = useLangPicker()
  const altPackaging = pick(row.values.alt_packaging)
  if (!altPackaging) {
    return null
  }
  return (
    <AltPackagingInput
      editable={!!editable}
      initialValue={cell.value}
      itemId={row.values.id}
      altPackagingTitle={altPackaging}
      onPackagingChange={onPackagingChange}
      producer={row.values.product.producer}
    />
  )
}

const QtyCell: React.FC<CellProps<CartItem> & {
  editable?: boolean
  onQtyChange: (id: string, qty: number) => void
  showUnits: boolean
  disabled: boolean
}> = ({ cell, row, editable, onQtyChange, showUnits, disabled }) => {
  const { t } = useTranslation('cart')

  const isMaxQtyReached = row.values.product.max_qty && cell.value && cell.value >= row.values.product.max_qty
  if (row.values.product.category.slug === 'duck-foot') return null
  return (
    <Styled.QtyContainer>
      <Styled.QtyContainerInner>
        <Qty
          max={row.values.product.max_qty}
          min={row.values.product.min_qty}
          value={cell.value}
          id={row.values.id}
          producerSlug={row.values.product?.producer?.slug}
          onChange={onQtyChange}
          key={row.values.id}
          disabled={!editable || disabled}
        />
        {showUnits && (
          <Styled.UnitsContainer>
            {cell.value > 1 ? row.values.product.units.plural : row.values.product.units.singular}
          </Styled.UnitsContainer>
        )}
        {isMaxQtyReached ? (
          <Styled.MaxQtyHint dangerouslySetInnerHTML={{ __html: t('reachedMaxQty') }} data-test-id={'max-qty-hint'} />
        ) : null}
      </Styled.QtyContainerInner>
    </Styled.QtyContainer>
  )
}

type Props = {
  data: CartItem[]
  producer?: Company
  onRemove: (id: string) => void
  onQtyChange: (id: string, value: UpdateCartItemParams['quantity']) => void
  onPackagingChange: (id: string, value: UpdateCartItemParams['packaging']) => void
  onSeedTreatmentChange: (id: string, value: UpdateCartItemParams['seed_treatment_id']) => void
  onOptionsChange: (id: string, options: UpdateCartItemParams['options']) => void
  showImages?: boolean
  editable?: boolean
  emptyText?: string
  removeDisabled?: boolean
  optionsRequiredErrors: string[][]
  seedTreatmentErrors: boolean[]
  productOptions: ProductOptionsType[]
  pendingData?: PendingCartItem[]
  showUnits: boolean
  isQtyDisabled: boolean
}

export const CartList: React.FC<Props> = ({
  showImages = true,
  editable = true,
  data,
  pendingData,
  onRemove,
  onQtyChange,
  onPackagingChange,
  onSeedTreatmentChange,
  onOptionsChange,
  emptyText,
  removeDisabled,
  optionsRequiredErrors,
  seedTreatmentErrors,
  productOptions,
  showUnits,
  isQtyDisabled,
}) => {
  const { t } = useTranslation('cart')
  const { pick } = useLangPicker()

  const tableData = useMemo(() => data || ([] as const), [data])
  const allColumns = useMemo(
    () => [
      {
        Header: t('list.product'),
        accessor: 'title' as const,
        Cell: props => (
          <ProductCell
            optionsRequiredErrors={optionsRequiredErrors}
            seedTreatmentErrors={seedTreatmentErrors}
            productOptions={productOptions}
            {...props}
          />
        ),
      },
      {
        Header: 'images',
        accessor: 'images' as const,
      },
      {
        Header: t('list.cost'),
        accessor: 'cost' as const,
        Cell: CostCell,
      },
      {
        Header: t('list.discount'),
        accessor: 'discount_amount' as const,
        Cell: DiscountCell,
      },
      {
        Header: t('list.qty'),
        accessor: 'qty' as const,
        Cell: QtyCell,
      },
      {
        Header: 'description',
        accessor: 'description' as const,
      },
      {
        Header: 'id',
        accessor: 'id' as const,
      },
      {
        Header: 'product_id',
        accessor: 'product_id' as const,
      },
      {
        Header: t('list.packaging'),
        accessor: 'packaging' as const,
        Cell: PackagingCell,
      },
      {
        Header: '',
        accessor: 'remove' as const,
        Cell: RemoveCell,
      },
      {
        Header: '',
        accessor: 'producer_id' as const,
      },
      {
        Header: '',
        accessor: 'default_packaging' as const,
      },
      {
        Header: '',
        accessor: 'alt_packaging' as const,
      },
      {
        Header: '',
        accessor: 'product' as const,
      },
      {
        Header: '',
        accessor: 'seed_treatment_id' as const,
      },
      {
        Header: '',
        accessor: 'options' as const,
      },
      {
        Header: '',
        accessor: 'subcategory_id' as const,
      },
      {
        Header: '',
        accessor: 'wizard_comment' as const,
      },
      {
        Header: '',
        accessor: 'wizard_data' as const,
      },
    ],
    [optionsRequiredErrors, productOptions, seedTreatmentErrors, t],
  )

  const isSomeProductHaveCustomPackaging = useMemo(() => tableData.some(item => !!pick(item.alt_packaging)), [
    tableData,
    pick,
  ])
  const isSomeProductHasPrice = useMemo(
    () => tableData.some(item => item.product.price && parseFloat(item.product.price) > 0),
    [tableData],
  )

  const isOnlyDuckFoot = useMemo(() => tableData.every(item => item.product?.category?.slug === 'duck-foot'), [
    tableData,
  ])

  const hiddenColumns = useMemo(
    () =>
      [
        'images',
        'description',
        'id',
        'producer_id',
        'package_size',
        'unit',
        'default_packaging',
        'alt_packaging',
        'product_id',
        'product',
        'seed_treatment_id',
        'options',
        'subcategory_id',
        'wizard_comment',
        'wizard_data',
        isOnlyDuckFoot && 'qty',
        !isSomeProductHaveCustomPackaging && 'packaging',
        !isSomeProductHasPrice && 'cost',
        removeDisabled && 'remove',
      ].filter(Boolean) as string[],
    [isSomeProductHaveCustomPackaging, isSomeProductHasPrice, removeDisabled, isOnlyDuckFoot],
  )
  const { columns, rows, prepareRow, setHiddenColumns } = useTable<CartItem>({
    data: tableData,
    columns: allColumns,
    initialState: { hiddenColumns },
  })
  useEffect(() => {
    setHiddenColumns(hiddenColumns)
  }, [hiddenColumns, setHiddenColumns])

  return data?.length || pendingData?.length ? (
    <>
      <SectionTable>
        <SectionTableHead>
          <SectionTableHeadRow>
            {columns.map(column => {
              if (hiddenColumns.includes(column.id)) {
                return null
              }

              if (
                column.id === 'discount_amount' &&
                !rows.some(row => row.values.discount_amount && parseFloat(row.values.discount_amount || '0') !== 0)
              ) {
                return <SectionTableHeadCell key={column.getHeaderProps().key} />
              }

              let align: 'left' | 'center' | 'right' = 'left' as const

              if (['qty'].includes(column.id)) {
                align = 'center'
              }
              return (
                <SectionTableHeadCell key={column.getHeaderProps().key} textTransform={'uppercase'} textAlign={align}>
                  {column.render('Header')}
                </SectionTableHeadCell>
              )
            })}
          </SectionTableHeadRow>
        </SectionTableHead>
        <SectionTableBody>
          {rows.map(row => {
            prepareRow(row)
            const isFake = Boolean(row.original.is_fake)
            return (
              <React.Fragment key={row.values.id || row.values.product_id}>
                <SectionTableBodyRow data-test-id={`cart-entry-${row.values.id}`}>
                  {row.cells.map(cell => {
                    if (hiddenColumns.includes(cell.column.id)) {
                      return null
                    }

                    const isEditable = editable && !row.values.product.is_out_of_stock && !isFake

                    return (
                      <SectionTableBodyCell verticalAlign={'top'} key={cell.getCellProps().key}>
                        <Styled.CellWrapper isFake={isFake}>
                          {cell.render('Cell', {
                            showImages,
                            editable: isEditable,
                            onRemove,
                            onQtyChange,
                            onPackagingChange,
                            onSeedTreatmentChange,
                            onOptionsChange,
                            showUnits,
                            disabled: isQtyDisabled,
                          })}
                        </Styled.CellWrapper>
                      </SectionTableBodyCell>
                    )
                  })}
                </SectionTableBodyRow>
                <Styled.BodyRowSpacer />
              </React.Fragment>
            )
          })}
          {pendingData?.map(item => (
            <Styled.TableItemSkeletonWrapper key={item.id}>
              <Skeleton width="60px" height="60px" />
              <Styled.TableItemSkeletonTitleWrapper>
                <Skeleton width="130px" />
                <Skeleton width="100px" />
              </Styled.TableItemSkeletonTitleWrapper>
            </Styled.TableItemSkeletonWrapper>
          ))}
        </SectionTableBody>
      </SectionTable>
    </>
  ) : (
    <Styled.EmptyText>{emptyText}</Styled.EmptyText>
  )
}

export const MobileCartList: React.FC<Props> = ({
  showImages = true,
  editable = true,
  data,
  producer,
  onRemove,
  onQtyChange,
  onPackagingChange,
  onSeedTreatmentChange,
  onOptionsChange,
  emptyText,
  removeDisabled,
  optionsRequiredErrors,
  seedTreatmentErrors,
  productOptions,
  pendingData,
  showUnits,
  isQtyDisabled,
}) => {
  const generateCountryPath = useCountryPath()
  const { t } = useTranslation(['cart', 'productOptions'])
  const { pick } = useLangPicker()
  const { track } = useAnalyticsSSR()
  const tableData = useMemo(() => data || ([] as const), [data])
  const formatPrice = useCallback((price: string) => getPrettyPrice(price, producer?.currency || Currency.CAD), [
    producer,
  ])

  const seedTreatmentInvalid = useCallback(
    (idx: number) => !!(seedTreatmentErrors.length && !seedTreatmentErrors[idx]),
    [seedTreatmentErrors],
  )

  const getSeedTreatmentSelectStyle = useCallback(
    (idx: number) => ({ marginBottom: seedTreatmentInvalid(idx) ? '20px' : 'initial' }),
    [seedTreatmentInvalid],
  )

  const isOnlyDuckFoot = useMemo(() => tableData.every(item => item.product?.category?.slug === 'duck-foot'), [
    tableData,
  ])

  return tableData?.length || pendingData?.length ? (
    <Styled.MobileCartList>
      {tableData.map((item: CartItem, idx: number) => {
        const url = generateCountryPath(Routes.ProductOrCardItem, {
          producerSlug: item.product?.producer?.slug,
          categorySlug: item.product?.category?.slug || '',
          subCategorySlug: item.product?.subcategory?.slug || '',
          productOrCardSlug: item.product.slug,
        })
        const altPackagingTitle = pick(item.alt_packaging)
        const qty = item.qty
        const isMaxQtyReached = item.product.max_qty && qty && Number(qty) >= item.product.max_qty
        const isFake = Boolean(item.is_fake)
        const isEditable = editable && !item.product.is_out_of_stock && !isFake

        return (
          <Styled.MobileCartItem key={item.id || item.product_id} isFake={isFake}>
            <Styled.MobileBlock>
              <Styled.ClickableProductCell to={url}>
                {showImages && item.images ? <Styled.Image url={item.images[0]} /> : null}
                <Styled.ProductCellText>
                  <Styled.BoldText>{pick(item.title)}</Styled.BoldText>
                  {item.wizard_data?.type === ProductWizardType.Procote ? (
                    <ProcoteWizardData data={item.wizard_data} />
                  ) : (
                    <Styled.PackSize>{pick(item.default_packaging)}</Styled.PackSize>
                  )}
                </Styled.ProductCellText>
              </Styled.ClickableProductCell>
              {isEditable && !removeDisabled && <RemoveBtn id={item.id} product={item.product} onClick={onRemove} />}
            </Styled.MobileBlock>
            {item.cost && parseFloat(item.cost) > 0 && (
              <Styled.MobileBlock>
                <Styled.MobileLabel>{t('list.costFull')}</Styled.MobileLabel>
                <Styled.BoldText>{formatPrice(item.cost)}</Styled.BoldText>
              </Styled.MobileBlock>
            )}
            {item.product.price && parseFloat(item.product.price) > 0 && item.product?.category?.slug !== 'duck-foot' && (
              <Styled.MobileBlock>
                <Styled.MobileLabel>{t('list.price')}</Styled.MobileLabel>
                <Styled.BoldText>
                  {getPrettyPrice(item.product.price, producer?.currency || Currency.CAD, item.product.price_type)}
                </Styled.BoldText>
              </Styled.MobileBlock>
            )}
            {item.discount_amount && parseFloat(item.discount_amount) > 0 && (
              <Styled.MobileBlock>
                <Styled.MobileLabel>{t('list.discountFull')}</Styled.MobileLabel>
                <Styled.BoldText>{formatPrice(item.discount_amount)}</Styled.BoldText>
              </Styled.MobileBlock>
            )}
            <Styled.MobileBlock style={getSeedTreatmentSelectStyle(idx)}>
              <SeedTreatmentSelect
                seedTreatment={item.product.seed_treatment}
                value={item.seed_treatment_id}
                onChange={(value: string) => {
                  onSeedTreatmentChange(item.id, value)
                }}
                editable={isEditable}
                seedTreatmentInvalid={seedTreatmentInvalid(idx)}
                mobile
              />
            </Styled.MobileBlock>
            <ProductOptions
              selectedIds={item.options || []}
              options={item.product.options || []}
              onChange={(value: string[]) => onOptionsChange(item.id, value)}
              optionsRequiredErrors={optionsRequiredErrors[idx]}
              place={Place.MOBILE}
              isDisabled={!isEditable}
              productOptions={productOptions || []}
            />

            {!isOnlyDuckFoot && (
              <Styled.MobileBlock>
                <Styled.MobileLabel>{t('list.quantity')}</Styled.MobileLabel>
                <Styled.QtyContainer>
                  <Qty
                    max={item.product.max_qty}
                    min={item.product.min_qty}
                    value={qty}
                    producerSlug={item.product?.producer?.slug || ''}
                    id={item.id}
                    onChange={onQtyChange}
                    disabled={!isEditable || isQtyDisabled}
                  />
                  {showUnits && (
                    <Styled.UnitsContainer>
                      {qty > 1 ? item.product?.units?.plural : item.product?.units?.singular}
                    </Styled.UnitsContainer>
                  )}
                </Styled.QtyContainer>
              </Styled.MobileBlock>
            )}
            {isMaxQtyReached ? (
              <Styled.MobileBlock>
                <Styled.MaxQtyHint
                  dangerouslySetInnerHTML={{ __html: t('reachedMaxQty') }}
                  data-test-id={'max-qty-hint'}
                />
              </Styled.MobileBlock>
            ) : null}
            <Styled.MobileBlock>
              {isEditable && (
                <QtyHintMobile
                  item={item}
                  onChange={(id, val) => {
                    onQtyChange(id, val)
                    track(CartEvents.UpdateCart, {
                      action: 'add_more',
                      product: item.product_id,
                      ...producerToEventParams(producer),
                    })
                  }}
                  active={isEditable}
                />
              )}
            </Styled.MobileBlock>
            {altPackagingTitle ? (
              <Styled.MobileBlock>
                <AltPackagingInput
                  itemId={item.id}
                  altPackagingTitle={pick(item.alt_packaging)}
                  initialValue={item.packaging || 0}
                  onPackagingChange={onPackagingChange}
                  editable={isEditable}
                  producer={producer}
                />
              </Styled.MobileBlock>
            ) : null}
            {item.product.docusign_document_id && item.product.legal_notes ? (
              <Styled.MobileBlock>
                <Styled.LegalHintContainer>
                  <Tooltip
                    visibleOnHover={true}
                    position={'top'}
                    tooltipContent={
                      <Styled.LegalHintTooltipContent>
                        {item.product.legal_notes.description}
                      </Styled.LegalHintTooltipContent>
                    }
                    temporary={true}
                  >
                    <Styled.LegalHint data-test-id={'legal-hint'}>
                      <Styled.IconWarningRed />
                      {item.product.legal_notes.text}
                    </Styled.LegalHint>
                  </Tooltip>
                </Styled.LegalHintContainer>
              </Styled.MobileBlock>
            ) : null}
            {!item.wizard_data && item.wizard_comment && <Styled.PackSize>{item.wizard_comment}</Styled.PackSize>}
          </Styled.MobileCartItem>
        )
      })}
      {pendingData?.map(item => (
        <Styled.MobileCartItem isFake={false} key={item.id}>
          <Styled.MobileBlock>
            <Styled.PendingProductCell>
              <Styled.FakeImageWrapper>
                <Skeleton width="60px" height="60px" />
              </Styled.FakeImageWrapper>

              <Styled.ProductCellText>
                <Styled.BoldText>
                  <Skeleton width="120px" />
                </Styled.BoldText>
                <Styled.PackSize>
                  <Skeleton width="90px" />
                </Styled.PackSize>
              </Styled.ProductCellText>
            </Styled.PendingProductCell>
          </Styled.MobileBlock>
        </Styled.MobileCartItem>
      ))}
    </Styled.MobileCartList>
  ) : (
    <Styled.EmptyText>{emptyText}</Styled.EmptyText>
  )
}

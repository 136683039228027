import ProducerSelectors from 'modules/domain/producer/selectors'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const useAnalyticDataFromParams = () => {
  const { country, producerSlug } = useParams<{ country: string; producerSlug?: string }>()
  const producer = useSelector(state => ProducerSelectors.item(state, producerSlug || ''))

  const analyticData = {
    country: country?.toUpperCase(),
    producer_id: producer?.id,
    producer_name: producer?.official_name,
  }

  return analyticData
}

export default useAnalyticDataFromParams

import { put, select, delay, take } from 'redux-saga/effects'
import { getLocation, LocationChangeAction, LOCATION_CHANGE, replace } from 'connected-react-router'
import { generatePath, matchPath } from 'react-router'
import { stringifyUrl } from 'helpers/stringifyUrl'
import { getAnalyticsInstance } from './sagaEffects'
import { Routes } from 'views/pages/routes'
import { routesEventsMap, routeToEventName } from './utils/analytics-utils/routeToEventName'
import CartSelectors from './domain/cart/selectors'
import { AnalyticsEvent } from './types'
import env from 'env'

type Param = string | number | undefined

export function* updateLocationQuery(nextLocation: string, query?: Record<string, Param | Param[]>) {
  const currentLocation = yield select(getLocation)
  const match = matchPath(currentLocation.pathname, { path: nextLocation, exact: true })
  if (match) {
    const result = stringifyUrl({
      url: generatePath(nextLocation, match.params),
      query,
    })
    const oldResult = stringifyUrl({ url: currentLocation.pathname, query: currentLocation.query })
    // prevent unnecessary push
    if (oldResult !== result) {
      yield put(replace(result, { silent: true }))
    }
  }
}

export function* generateCountryPath(pattern: string, params?: { [paramName: string]: Param }) {
  const currentLocation = yield select(getLocation)
  const match = matchPath<{ country: string }>(currentLocation.pathname, { path: Routes.Root })

  return generatePath(pattern, { ...params, country: match?.params.country })
}

export function* exponentialDelay(attemt = 0) {
  attemt = attemt < 0 ? 0 : attemt
  const delayMs = Math.pow(attemt, 2) * 1000
  yield delay(delayMs)
}

export function* locationChangeWatcher() {
  const analyticsInstance = yield getAnalyticsInstance()
  const routes = Object.values(Routes)
    .map(r => r.split('/'))
    .sort((a, z) => z.filter(s => !!s).length - a.filter(s => !!s).length)
    .map(r => r.join('/'))
  let prevPath = ''

  while (1) {
    const action: LocationChangeAction = yield take(LOCATION_CHANGE)
    const match = matchPath(action.payload.location.pathname, routes)
    const params = match?.params
    const props: { [key: string]: unknown } = {
      ...params,
      url: match?.url,
    }

    if (!env.BROWSER && !action.payload.isFirstRendering) {
      continue
    }

    if (env.BROWSER && action.payload.isFirstRendering) {
      continue
    }

    const eventName = routeToEventName(match?.path as never)

    if (eventName && prevPath !== action.payload.location.pathname) {
      if (eventName === routesEventsMap[Routes.Cart] || eventName === routesEventsMap[Routes.ProducerCart]) {
        const cartCount = yield select(CartSelectors.cartItems)
        props.cart_count = cartCount.length
      }
      prevPath = action.payload.location.pathname
      analyticsInstance.track(AnalyticsEvent.Page, { ...props, name: eventName })
    }
  }
}

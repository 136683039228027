import React, { FC } from 'react'
import { Spinner } from '@agro-club/frontend-shared'
import useWhiteLabelConfig from 'hooks/useWhiteLabelConfig'

type PropsType = {
  size?: 'small' | 'medium' | 'big'
  className?: string
  filled?: boolean
}

export const SpinnerLayout: FC<PropsType> = ({ size, className, filled }: PropsType) => {
  const { config } = useWhiteLabelConfig()
  return <Spinner spinnerImageUrl={config?.images?.spinner_url} size={size} className={className} filled={filled} />
}

export default SpinnerLayout

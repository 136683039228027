import CollectionSelectors from 'modules/domain/collection/selectors'
import { useSelector } from 'react-redux'
import { DEFAULT_COUNTRY } from 'modules/constants'

import { useCallback } from 'react'
import { matchPath } from 'react-router-dom'
import AuthSelectors from 'modules/domain/auth/selectors'
import { Routes } from 'views/pages/routes'
import { CountryCode } from 'libphonenumber-js'

export const useDetectedCountry = () => {
  const phoneCodes = useSelector(CollectionSelectors.countryPhoneCodes)
  const pathname = useSelector(AuthSelectors.pathname)
  const match = matchPath<{ country: string }>(pathname, {
    path: Routes.Root,
  })
  const countryFromPath = match?.params.country.toUpperCase()

  const isValidCountryCode = useCallback((countryCode: string) => phoneCodes.some(item => item.id === countryCode), [
    phoneCodes,
  ])

  return (isValidCountryCode(countryFromPath || '') ? countryFromPath : DEFAULT_COUNTRY) as CountryCode
}

import { all, put, select, takeLatest, fork, call, race, take } from 'redux-saga/effects'
import ProducerActions from './duck'
import ProducerSelectors from './selectors'
import * as managers from './managers'
import * as seasonManagers from '../season/managers'
import { ListResponse } from 'types/api'
import { Company } from 'types/entities'
import { apiCall, makeRouteWatcher } from 'modules/sagaEffects'
import ProducerRoutes from 'views/pages/Producer/routes'
import MainPageRoutes from 'views/pages/Main/routes'
import CollectionActions from 'modules/domain/collection/duck'
import { fetchCompanyCategories } from 'modules/domain/collection/sagas'
import { RequestError } from 'service/api/errors'
import { Season } from '../season/types'

export const fetchList = function*() {
  try {
    let currentPage = yield select(ProducerSelectors.page)
    const filter = yield select(ProducerSelectors.filter)
    const sorting = yield select(ProducerSelectors.sorting)
    const pageSize = yield select(ProducerSelectors.pageSize)

    let response: ListResponse<Company> = yield call(apiCall, managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(apiCall, managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response

    const seasons = yield all(
      data.map((company: Company) =>
        call(apiCall, seasonManagers.getCurrentSeason, {
          companies_id: company.id,
          is_current: true,
        }),
      ),
    )

    yield all(
      // sorry
      data.map((company: Company) => {
        const isStorefront = company.sku_orders
        if (seasons) {
          const season = seasons.find((s: ListResponse<Season>) => s.data[0]?.company_id === company.id)
          if (season) company.currentSeason = season.data[0]
        }
        return call(fetchCompanyCategories, { payload: [company.id, isStorefront], type: 'companyCategoriesRequested' })
      }),
    )
    yield put(ProducerActions.listRequestSucceed(data, total_count, page))

    // yield put(push(`${routes.List}/?page=${currentPage}`))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProducerActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(ProducerSelectors.page)
    const filter = yield select(ProducerSelectors.filter)
    const sorting = yield select(ProducerSelectors.sorting)
    const pageSize = yield select(ProducerSelectors.pageSize)
    const { data, total_count }: { data: Company[]; total_count: number } = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(ProducerActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProducerActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof ProducerActions.itemRequested>) {
  try {
    const cached = yield select(ProducerSelectors.item, id)
    if (cached) {
      yield put(ProducerActions.itemRequestSucceed(cached))
      return
    }
    const company: Company = yield call(apiCall, managers.getItem, id)
    yield put(ProducerActions.itemRequestSucceed(company))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProducerActions.itemRequestFailed(id, errType))
  }
}

export const fetchSeason = function*({ payload: company_id }: ReturnType<typeof ProducerActions.seasonFetchRequested>) {
  try {
    const producer: Company = yield select(ProducerSelectors.item, company_id)
    const cached = yield select(ProducerSelectors.season, company_id)
    if (cached) {
      yield put(ProducerActions.seasonFetchSucceed(company_id, producer.slug, cached))
      return
    }
    const season: ListResponse<Season> = yield call(apiCall, seasonManagers.getCurrentSeason, {
      companies_id: company_id,
      is_current: true,
    })

    yield put(ProducerActions.seasonFetchSucceed(company_id, producer.slug, season.data[0]))
  } catch (err) {
    yield put(ProducerActions.seasonFetchError())
  }
}

const routeWatcher = makeRouteWatcher(ProducerRoutes, function*(result) {
  if (result.silent) {
    return
  }
  switch (result.match) {
    case ProducerRoutes.CategoryList:
      yield all([
        put(ProducerActions.itemRequested(result.params.producerSlug)),
        put(CollectionActions.categoriesRequested()),
      ])
      break
    case ProducerRoutes.SubCategoryList:
      yield put(ProducerActions.itemRequested(result.params.producerSlug))
      yield race([take(ProducerActions.itemRequestSucceed.type), take(ProducerActions.itemRequestFailed.type)])

      const producer: Company = yield select(ProducerSelectors.item, result.params.producerSlug)
      if (!producer) {
        return
      }

      const isStorefront = producer.sku_orders

      yield all([
        put(CollectionActions.categoriesRequested()),
        put(
          CollectionActions.companySubCategoriesRequested(
            result.params.producerSlug,
            result.params.categorySlug,
            isStorefront,
          ),
        ),
      ])
      break
    default:
      break
  }
})

const mainPageRoutesWatcher = makeRouteWatcher(MainPageRoutes, function*(result) {
  if (result.silent) {
    return
  }
  switch (result.match) {
    case MainPageRoutes.Root:
      yield put(ProducerActions.listRequested({ page: 1, sorting: { sort_field: 'internal_name' } }))
      break
    default:
      break
  }
})

const ProducerSaga = function*() {
  yield all([
    takeLatest(ProducerActions.itemRequested.type, fetchItem),
    takeLatest(ProducerActions.listRequested.type, fetchList),
    takeLatest(ProducerActions.filterUpdated.type, fetchList),
    takeLatest(ProducerActions.sortingUpdated.type, fetchList),
    takeLatest(ProducerActions.filterHasBeenReset.type, fetchList),
    takeLatest(ProducerActions.sortingHasBeenReset.type, fetchList),
    takeLatest(ProducerActions.listRequestedNext.type, fetchListNext),
    takeLatest(ProducerActions.seasonFetchRequested.type, fetchSeason),
    fork(routeWatcher),
    fork(mainPageRoutesWatcher),
  ])
}

export default ProducerSaga

import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import { useDidMount, useAction } from '@agro-club/frontend-shared'
import ProductActions from './duck'
import ProductSelectors from './selectors'
import { Product, ProductDiscountInfo, ProductListRequestFilter, ProductListRequestSorting } from './types'
import { useEffect } from 'react'

export const useProductList: ResourceHook<Product[]> = () => {
  const progress = useSelector(ProductSelectors.listFetchProgress)
  const list = useSelector(ProductSelectors.list)

  return [progress, list]
}

export const useProduct: ResourceHook<Product> = (idOrSlug: string) => {
  const meta = useSelector(state => ProductSelectors.meta(state, idOrSlug))
  const item = useSelector(state => ProductSelectors.item(state, idOrSlug))
  const fetchAction = useAction(ProductActions.itemRequested, idOrSlug)

  useEffect(() => {
    if (!idOrSlug) return
    if (!item || (item.id !== idOrSlug && item.slug !== idOrSlug)) {
      fetchAction()
    }
  }, [fetchAction, idOrSlug, item])

  return [meta.fetchProgress, item]
}

export const useProductDiscounts: ResourceHook<ProductDiscountInfo['discounts']> = () => {
  const progress = useSelector(ProductSelectors.discountsFetchProgress)
  const discounts = useSelector(ProductSelectors.discounts)
  return [progress, discounts]
}

export const useGiftProductList: ResourceHook<Product[]> = (filter: ProductListRequestFilter) => {
  const progress = useSelector(ProductSelectors.giftListFetchProgress)
  const gifts = useSelector(ProductSelectors.giftList)
  const fetchAction = useAction(ProductActions.giftListRequested, filter)

  useDidMount(fetchAction)

  return [progress, gifts]
}

export const useWizardProductList: ResourceHook<Product[]> = (params: {
  filter: ProductListRequestFilter
  sorting?: ProductListRequestSorting
  page?: number
  pageSize?: number
  isExclusive?: boolean
}) => {
  const progress = useSelector(ProductSelectors.customProductListFetchProgress)
  const items = useSelector(ProductSelectors.customProductList)
  const fetchAction = useAction(ProductActions.customProductListRequested)

  useDidMount(() => {
    fetchAction(params)
  })

  return [progress, items]
}

import { endpoints } from 'modules/endpoints'
import { Order, OrderDTO } from 'types/order'
import { OrderListRequestSorting, OrderListRequestFilter } from './types'
import { ListResponse } from 'types/api'
import { ApiService } from 'service/api/interface'

export const getList = (apiClient: ApiService) => (
  filter: OrderListRequestFilter,
  sorting: OrderListRequestSorting,
  page: number,
  pageSize: number,
) => {
  return apiClient.get<ListResponse<Order>>(endpoints.allOrders(), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

export const getItem = (apiClient: ApiService) => (id: string) => {
  return apiClient.get<Order>(endpoints.orders(id))
}

export const addItem = (apiClient: ApiService) => (dto: OrderDTO) => {
  return apiClient.post<Order>(endpoints.orders(), dto)
}

export const updateItem = (apiClient: ApiService) => (id: string, dto: Partial<OrderDTO>) => {
  return apiClient.put<Order>(endpoints.orders(id), dto)
}

export const updateSkuItem = (apiClient: ApiService) => (id: string, dto: Partial<OrderDTO>) => {
  return apiClient.put<Order>(endpoints.skuOrders(id), dto)
}

export const removeItem = (apiClient: ApiService) => (id: string) => {
  return apiClient.delete<Order>(endpoints.orders(id))
}

export const removeSkuItem = (apiClient: ApiService) => (id: string) => {
  return apiClient.delete<Order>(endpoints.skuOrders(id))
}

export const cancelOrder = (apiClient: ApiService) => (id: string, dto: OrderDTO) => {
  return apiClient.put<Order>(endpoints.orders(id), dto)
}

export const cancelSkuOrder = (apiClient: ApiService) => (id: string, dto: OrderDTO) => {
  return apiClient.put<Order>(endpoints.skuOrders(id), dto)
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as Styled from './styled'
import { CalculatedDiscountDto } from 'types/entities/discount'
import useLangPicker from 'hooks/useLangPicker'

const Wrapper = styled(Styled.Wrapper)`
  background-image: url('/img/umbrella.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 200px;
`

const Text = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  max-width: 190px;
  margin-bottom: 8px;
`

const LearnMore = styled.a`
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  margin-bottom: 12px;
`

export const SectionTitle = styled.h3`
  margin: 0 0 8px 0;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;

  text-transform: uppercase;

  color: ${({ theme }) => theme.color.secondary300};
`
export type Props = {
  type?: Styled.WidgetType
  dataTestId?: string
}

export const SpecialOfferDiscountListWidget: React.FC<Props & { discounts: CalculatedDiscountDto[] }> = ({
  discounts,
  dataTestId,
}) => {
  const { t } = useTranslation(['discount', 'common'])
  const { pick } = useLangPicker()

  return (
    <Wrapper data-test-id={`widget-${dataTestId}`}>
      <>
        <Styled.Title data-test-id={'discount-list-widget-title'}>{discounts[0].rule.campaign_name}</Styled.Title>
        <Styled.SectionContainer data-test-id={'links-container'}>
          <Text>{t('specialOfferText')}</Text>
          {discounts.map(discount => {
            return (
              <>
                <div>
                  {discount.rule.link_url_i18n && (
                    <LearnMore href={pick(discount.rule.link_url_i18n)} target={'blank'}>
                      {pick(discount.rule.link_label_i18n) || t('common:learnMore')}
                    </LearnMore>
                  )}
                </div>
              </>
            )
          })}
        </Styled.SectionContainer>
      </>
    </Wrapper>
  )
}

export default SpecialOfferDiscountListWidget

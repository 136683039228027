export const getEnv = (key: keyof typeof window.__env) => {
  const v = (process.browser ? window.__env[key] : process.env[key]) || ''

  if (typeof v === 'string') {
    // strip quotes
    return v.replace(/["']*([^"']*)["']*/, '$1')
  }

  return v
}

const API_BASE_URL = getEnv('API_BASE_URL') || ''
const LICENSE = getEnv('LICENSE') || ''
const REDUX_LOGGER = getEnv('REDUX_LOGGER')
const REDUX_LOGGER_SERVER_VERBOSE = getEnv('REDUX_LOGGER_SERVER_VERBOSE') === 'true'
const BASE_ROUTER_PATH = getEnv('BASE_ROUTER_PATH') || '/'
const SENTRY_DSN = getEnv('SENTRY_DSN') || null
const DEVELOPMENT = getEnv('NODE_ENV') === 'development'
const PUBLIC_URL = getEnv('PUBLIC_URL') || ''
const SEGMENT_WRITE_KEY = getEnv('SEGMENT_WRITE_KEY')
const GTM_ID = getEnv('GTM_ID') || ''
const BROWSER = typeof process === 'undefined' || process.browser

export default {
  API_BASE_URL,
  REDUX_LOGGER,
  REDUX_LOGGER_SERVER_VERBOSE,
  BASE_ROUTER_PATH,
  SENTRY_DSN,
  DEVELOPMENT,
  PUBLIC_URL,
  SEGMENT_WRITE_KEY,
  BROWSER,
  GTM_ID,
  LICENSE,
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useLangPicker from 'hooks/useLangPicker'
import { ProductOptions, ProductOptionType } from 'modules/domain/productOptions/types'
import { useTranslation } from 'react-i18next'
import { Input, Progress, SimpleSelect } from '@agro-club/frontend-shared'
import { SkuOption, SkuOptionsGrouped } from 'modules/domain/sku/types'
import * as Styled from './styled'
import { remove } from 'ramda'
import { Select } from '../Select/Select'

// todo fix - it is only for CM
export enum SkuOptionsContext {
  Cart = 'Cart',
  CardDetails = 'CardDetails',
}

const canRemoveOption = (type: ProductOptionType) => {
  return type === ProductOptionType.ACTIVE_INGREDIENT
}

const mapToSkuOptions = (group: SkuOptionsGrouped[]): SkuOption[] =>
  group.map(item => ({
    option_id: item.selectedId as string,
    type: item.type,
    params: item.params,
  }))

const SkuOptionsComponent: React.FC<{
  initialValues?: SkuOption[]
  onChange: (value: SkuOption[]) => void
  productOptions: ProductOptions[]
  progress?: Progress
  filter?: (option: ProductOptions) => boolean
  context?: SkuOptionsContext
  optionsRequired?: boolean
  showErrors?: boolean
  disabled?: boolean
}> = ({
  initialValues = [],
  onChange,
  productOptions,
  progress,
  filter,
  context = SkuOptionsContext.CardDetails,
  optionsRequired,
  showErrors,
  disabled = false,
}) => {
  const { t } = useTranslation(['productOptions', 'storefront'])
  const { pick } = useLangPicker()

  const selects = useMemo(() => {
    const groupes: SkuOptionsGrouped[] = []
    let filteredList = productOptions
    if (filter) filteredList = productOptions.filter(filter)

    Object.values(ProductOptionType)
      // todo cut all active ingredient multi-options
      .filter(type => type !== ProductOptionType.ACTIVE_INGREDIENT)
      .map(type => {
        const options = filteredList.filter(o => o.type === type)
        if (!!options.length) {
          let selectedId
          const data = options.map(opt => {
            const found = initialValues.find(init => init.option_id === opt.id)
            if (!selectedId && found) selectedId = found.option_id
            return {
              id: opt.id,
              title: pick(opt?.title_i18n),
              is_stackable: !!opt?.is_stackable,
            }
          })

          groupes.push({
            type,
            data,
            selectedId: selectedId ?? undefined,
            params: {},
          })
        }
      })

    return groupes
  }, [productOptions, filter, initialValues, pick])

  const [selectsState, setSelectsState] = useState(selects)

  const fieldRequired = showErrors && optionsRequired

  const isLoading = useMemo(() => {
    return progress !== Progress.SUCCESS && !productOptions.length
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])

  const errorText = fieldRequired ? t('validation:field_required') : undefined

  useEffect(() => {
    if (progress === Progress.SUCCESS) {
      setSelectsState(selects)
    }
  }, [progress, initialValues, selects])

  const handleOptionChange = useCallback(
    (idx: number, type: ProductOptionType, selectedId: string, option_id?: string) => {
      if (selectedId === option_id) return

      const updatedState = [...selectsState]
      updatedState[idx].selectedId = option_id
      setSelectsState(updatedState)

      const filtered = updatedState.filter(item => !!item.selectedId)
      const updated = mapToSkuOptions(filtered)
      onChange(updated)
    },
    [onChange, selectsState],
  )

  const handlePercentageChange = useCallback(
    (idx: number, percentage?: string) => {
      if (percentage === undefined) return

      const updatedState = [...selectsState]
      updatedState[idx].params = { ...updatedState[idx].params, percentage: parseFloat(percentage) }
      setSelectsState(updatedState)

      const filtered = updatedState.filter(item => !!item.selectedId)
      const updated = mapToSkuOptions(filtered)
      onChange(updated)
    },
    [onChange, selectsState],
  )

  const handleOptionRemove = useCallback(
    (idx: number) => {
      let updatedState = [...selectsState]
      updatedState = remove(idx, 1, updatedState)
      setSelectsState(updatedState)
      const updated = mapToSkuOptions(updatedState)
      onChange(updated)
    },
    [onChange, selectsState],
  )

  const SelectComponent = context === SkuOptionsContext.CardDetails ? Select : SimpleSelect

  return (
    <>
      {selectsState.map((select, idx: number) => (
        <Styled.SkuOptionSelect data-test-id={`sku-option-select-${select.type}`} key={select.type}>
          {canRemoveOption(select.type) && <Styled.RemoveOption onClick={() => handleOptionRemove(idx)} />}
          <div style={{ paddingBottom: errorText ? '24px' : 0 }}>
            <SelectComponent
              label={context === SkuOptionsContext.CardDetails ? t(`types.${select.type}`) : undefined}
              onChange={id => handleOptionChange(idx, select.type, select.selectedId as string, id as string)}
              placeholder={`${t('placeholderPrefix')} ${t(`types.${select.type}`).toLowerCase()}`}
              options={select.data}
              value={select.selectedId}
              isSearchable
              invalid={!select.selectedId}
              isLoading={isLoading}
              isDisabled={isLoading || disabled}
              required={fieldRequired}
              errorText={errorText}
              progress={progress}
              wrapOptionsText
              wrapValueText
              variant={context === SkuOptionsContext.Cart ? 'small' : 'default'}
            />
          </div>
          {select.type === ProductOptionType.ACTIVE_INGREDIENT && (
            <Styled.PercentageOption>
              <Input
                onChange={e => handlePercentageChange(idx, e.target.value)}
                value={select.params?.percentage}
                label={t('storefront:skuForm.labels.percentage')}
                type={'number'}
                disabled={!select.selectedId || disabled}
              />
            </Styled.PercentageOption>
          )}
        </Styled.SkuOptionSelect>
      ))}
    </>
  )
}

export default SkuOptionsComponent

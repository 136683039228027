import React, { useContext, useState } from 'react'
import JsCookies from 'js-cookie'
import env from 'env'

export const CookieContext = React.createContext<{ cookies: Record<string, string> }>({
  cookies: {},
})

let _useCookieValue

if (env.BROWSER) {
  _useCookieValue = (name: string) => {
    const [value, setValue] = useState(JsCookies.get(name) || null)
    const handleSetValue = (value?: string | null, expires = 365) => {
      // default cookie lifespan is 365 days
      if (value) {
        JsCookies.set(name, value, { expires })
        setValue(value)
      } else {
        JsCookies.remove(name)
        setValue(null)
      }
    }
    return [value, handleSetValue] as const
  }
} else {
  _useCookieValue = (name: string) => {
    const { cookies } = useContext(CookieContext)
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return [cookies[name] || null, (_name?: string | null) => {}] as const
  }
}

const useCookieValue = _useCookieValue
export default useCookieValue

import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { RelatedProduct } from './types'

const productId = (state: AppGlobalState) => state.relatedProduct.productId
const dict = (state: AppGlobalState) => state.relatedProduct.items
const ids = (state: AppGlobalState) => state.relatedProduct.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, id: string): RelatedProduct | undefined => {
  return state.relatedProduct.items[id]
}
const meta = (state: AppGlobalState, id: string) =>
  state.relatedProduct.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const listFetchProgress = (state: AppGlobalState) => state.relatedProduct.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.relatedProduct.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.relatedProduct.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.relatedProduct.listFetchNextError
const list = createSelector(dict, ids, (dict, ids) => {
  const result: RelatedProduct[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.relatedProduct.filter
const sorting = (state: AppGlobalState) => state.relatedProduct.sorting
const page = (state: AppGlobalState) => state.relatedProduct.page
const total = (state: AppGlobalState) => state.relatedProduct.total
const hasNext = (state: AppGlobalState) =>
  state.relatedProduct.page * state.relatedProduct.pageSize < state.relatedProduct.total
const pageSize = (state: AppGlobalState) => state.relatedProduct.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.relatedProduct.total / state.relatedProduct.pageSize)

export const RelatedProductSelectors = {
  productId,
  filter,
  sorting,
  page,
  total,
  item,
  meta,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
}

export default RelatedProductSelectors

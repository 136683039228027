import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { queries } from 'theme/theme'
import { useHistory } from 'react-router-dom'
import Routes from 'views/pages/Profile/routes'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs } from 'views/ui/Tabs/Tabs'
import useMatchMedia from 'hooks/useMatchMedia'
import useHelmet from 'hooks/useHelmet'
import { useOrderList } from 'modules/domain/order/hooks'
import * as dateHelpers from 'helpers/date'
import { Progress } from 'modules/types'
import { clone } from 'ramda'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useCountryPath } from 'hooks/useCountryPath'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import useCompaniesFeatureFlags from 'hooks/useCompaniesFeatureFlags'
import { CustomFeatureName, Sections } from 'types/entities/config'
import { generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'
import { getPrettyPrice } from 'modules/utils/helpers'
import { Currency } from 'types/entities'
import { useQuery } from '@agro-club/frontend-shared'
import { OrderStatus } from 'types/order'

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px 24px;
  border: 1px solid ${({ theme }) => theme.color.onSurfaceLowEmphasys};
  box-sizing: border-box;
  border-radius: 16px;

  cursor: pointer;
  transition: 0.2s border-color ease-in-out;
  transition: 0.2s background-color ease-in-out;
  &:hover {
    border-color: ${({ theme }) => theme.color.primary25};
    background: ${({ theme }) => theme.color.primary5};
  }
`

const ListContainer = styled.div`
  display: grid;
  padding: 8px 0 16px;
  grid-gap: 24px;
  grid-template-columns: auto auto;

  ${props => props.theme.media.tablet`
    padding: 0 0 0 16px;
  `}

  ${props => props.theme.media.mobile`
    flex-direction: column;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    padding: 8px 0 0;
  `}
`

const OrderTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;

  &:first-child {
    margin-bottom: 16px;
  }

  & > :last-child {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
  }

  & > :first-child,
  & > :first-child:last-child {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    color: ${({ theme }) => theme.color.onPrimaryDark};
  }
`

const OrderInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;

  &:first-child {
    margin-bottom: 16px;
  }

  & > :last-child {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
  }

  & > :first-child,
  & > :first-child:last-child {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;

    color: ${({ theme }) => theme.color.onPrimaryDark};
  }
`

const SpinnerContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const EmptyList = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.secondary300};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 40px;
`

const Discount = styled.div`
  color: ${({ theme }) => theme.color.accentNotify};
  text-align: right;
`

const OrderHeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const OrderTotalAndDiscountWrapper = styled.div`
  font-weight: 600;
`

const OrderItem: React.FC<{
  date: string
  producer: string
  count: number
  id: string
  distributor?: string
  orderTotal?: string
  discount?: string
  onClick: () => void
  producerCurrency?: Currency
}> = ({
  date,
  count,
  producer,
  distributor,
  onClick,
  id,
  orderTotal = '0',
  discount = '0',
  producerCurrency = Currency.USD,
}) => {
  const { t } = useTranslation('profile')
  const formatPrice = useCallback((price: number) => getPrettyPrice(price, producerCurrency), [producerCurrency])

  return (
    <ItemWrapper onClick={onClick} data-test-id={'order-wrapper'}>
      <OrderHeaderTitleWrapper>
        <OrderTitleWrapper>
          <div>{t('orders.list.title', { id: id || '' })}</div>
          <div>{date}</div>
        </OrderTitleWrapper>
        <OrderTotalAndDiscountWrapper>
          {!!+orderTotal && !orderTotal.startsWith('-') ? <div>{formatPrice(+orderTotal)}</div> : <br />}
          {!!+discount && <Discount>{formatPrice(+discount)}</Discount>}
        </OrderTotalAndDiscountWrapper>
      </OrderHeaderTitleWrapper>
      <OrderInfoWrapper>
        <div>
          {producer} {t('orders.list.items', { count })}
        </div>
        {distributor ? <div>{distributor}</div> : null}
      </OrderInfoWrapper>
    </ItemWrapper>
  )
}

const OrdersList = () => {
  const history = useHistory()
  const generateCountryPath = useCountryPath()
  const { t } = useTranslation('profile')
  const isMobile = useMatchMedia(queries.mobile)
  useHelmet({ title: t('orders.metaTitle') })
  const [activeOrdersProgress, activeOrders = []] = useOrderList()
  const { track } = useAnalyticsSSR()
  const [hasSome] = useCompaniesFeatureFlags()
  const searchParams = useQuery()
  const activeTab = searchParams.get('tab') || 'active'

  const showPreorders = hasSome(generateCustomFeatureFlag(Sections.Orders, CustomFeatureName.FarmerConfirmRequired))

  const items = useMemo(() => {
    return clone(activeOrders)
      .reverse()
      .filter(item => item.status !== OrderStatus.Canceled && item.interaction.confirmed_by_farmer === true)
  }, [activeOrders])

  const canceledItems = useMemo(() => {
    return clone(activeOrders)
      .reverse()
      .filter(item => item.status === OrderStatus.Canceled)
  }, [activeOrders])

  const preorderItems = useMemo(() => {
    return clone(activeOrders)
      .reverse()
      .filter(
        ({ status, interaction: i }) =>
          status !== OrderStatus.Canceled &&
          i.confirmed_by_producer &&
          i.confirmed_by_agroclub &&
          !i.confirmed_by_farmer,
      )
  }, [activeOrders])

  return (
    <>
      <Tabs defaultTab={activeTab}>
        <Tab
          id={'active'}
          label={t(isMobile ? 'orders.activeOrdersShort' : 'orders.activeOrders', { amount: items.length })}
        >
          {activeOrdersProgress === Progress.WORK ? (
            <SpinnerContainer>
              <SpinnerLayout />
            </SpinnerContainer>
          ) : (
            <ListContainer>
              {items.length ? (
                items.map(order => (
                  <OrderItem
                    key={order.id}
                    date={dateHelpers.toShort(order.created_at)}
                    id={order.slug}
                    producer={order.producer?.official_name || ''}
                    distributor={order.distributor?.official_name}
                    count={order.items.length ? order.items.length : order.sku_items.length}
                    orderTotal={order.total}
                    discount={order.discount_total}
                    onClick={() => {
                      track('order_active_tap')
                      history.push(generateCountryPath(Routes.OrderDetails, { orderId: order.slug }))
                    }}
                    producerCurrency={order.producer?.currency}
                  />
                ))
              ) : (
                <EmptyList>{t('orders.emptyList')}</EmptyList>
              )}
            </ListContainer>
          )}
        </Tab>
        <Tab
          id={'completed'}
          label={t(isMobile ? 'orders.completedOrdersShort' : 'orders.completedOrders', {
            amount: 0,
          })}
        >
          <EmptyList>{t('orders.emptyList')}</EmptyList>
        </Tab>
        <Tab
          id={'canceled'}
          label={t(isMobile ? 'orders.canceledOrdersShort' : 'orders.canceledOrders', {
            amount: canceledItems.length,
          })}
        >
          <ListContainer>
            {canceledItems.length ? (
              canceledItems.map(order => (
                <OrderItem
                  key={order.id}
                  date={dateHelpers.toShort(order.created_at)}
                  id={order.slug}
                  producer={order.producer?.official_name || ''}
                  distributor={order.distributor?.official_name}
                  count={order.items.length ? order.items.length : order.sku_items.length}
                  onClick={() => {
                    track('order_canceled_tap')
                    history.push(generateCountryPath(Routes.OrderDetails, { orderId: order.slug }))
                  }}
                  producerCurrency={order.producer?.currency}
                />
              ))
            ) : (
              <EmptyList>{t('orders.emptyList')}</EmptyList>
            )}
          </ListContainer>
        </Tab>
        {(showPreorders && (
          <Tab id={'preorders'} label={t('orders.preorders', { amount: preorderItems.length })}>
            <ListContainer>
              {preorderItems.length ? (
                preorderItems.map(order => (
                  <OrderItem
                    key={order.id}
                    date={dateHelpers.toShort(order.created_at)}
                    id={order.slug}
                    producer={order.producer?.official_name || ''}
                    distributor={order.distributor?.official_name}
                    count={order.items.length ? order.items.length : order.sku_items.length}
                    onClick={() => {
                      track('preorders_tap')
                      history.push(generateCountryPath(Routes.OrderDetails, { orderId: order.slug }))
                    }}
                    producerCurrency={order.producer?.currency}
                  />
                ))
              ) : (
                <EmptyList>{t('orders.emptyList')}</EmptyList>
              )}
            </ListContainer>
          </Tab>
        )) || <></>}
      </Tabs>
    </>
  )
}

export default OrdersList

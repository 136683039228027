import styled from 'styled-components'
import Link from 'views/components/Link/Link'

export const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 24px;

  ${props => props.theme.media.smallTablet`
    grid-template-columns: auto;
  `}

  ${props => props.theme.media.mobile`
    display: block;
    padding-bottom: 56px;
    background-color: ${(props: any) => props.theme.color.onPrimaryLight};
  `}
`

export const CartContainer = styled.div`
  ${props => props.theme.media.smallTablet`
    order: 1;
    width: 100%;
  `}
`

export const StepFormContainer = styled.div<{ wide?: boolean }>`
  width: ${({ wide }: { wide?: boolean }) => (wide ? '800px' : '685px')};
  ${props => props.theme.media.tablet`
    width: ${({ wide }: { wide?: boolean }) => (wide ? '800px' : '100%')};
  `}
  ${props => props.theme.media.smallTablet`
    width: 100%;
     order: 2;
  `}
  ${props => props.theme.media.mobile`
    width: 100%;
  `}
`

export const StepButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-right: 16px;
  }
`

export const LoginStepContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const JoinContainer = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.backgroundBase};
  padding: 16px 24px;
  margin: 16px 0 0;

  width: calc(100% + 48px);
  left: -24px;

  border-radius: 12px;

  ${props => props.theme.media.tablet`
    width: calc(100% + 32px);
    left: -16px;
    padding: 16px;
    margin: 16px 0 0;
  `}
  ${props => props.theme.media.mobile`
    width: calc(100% + 32px);
    left: -16px;
    border-radius: 0;
    padding: 24px 16px 48px;
    margin: 24px 0 0;
  `}
`

export const JoinHeader = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`

export const JoinButtonContainer = styled.div`
  width: 200px;
  margin-top: 16px;
  & > * {
    width: 100%;
  }
  ${props => props.theme.media.mobile`
    width: 100%;
  `}
`

export const CompeteContainer = styled.div`
  display: grid;
  grid-gap: 16px;
`
export const CompleteTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`
export const CompleteText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`
export const CompleteLink = styled(Link)`
  color: ${props => props.theme.color.primary600};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`

export const OrderInfoContainer = styled.div`
  display: grid;
  grid-gap: 32px;
`

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  > *:not(:first-child) {
    margin-top: 32px;
  }
  :nth-child(3) {
    margin-top: 40px;
  }
`

export const ConfirmationBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  > *:not(:first-child) {
    margin-left: 55px;
  }

  ${props => props.theme.media.mobile`
    flex-direction: column;

    > *:not(:first-child) {
      margin-left: 0;
      margin-top: 20px;
    }
  `}
`

export const PreorderTableContainer = styled.div`
  width: 100%;
`

export const PreorderTotalContainer = styled.div`
  max-width: 300px;
`

export const ShippingAddressRadioContainer = styled.div`
  display: grid;
  grid-gap: 16px;
`
export const ShippingAddressRadioInner = styled.div`
  display: flex;
  flex-direction: column;
`
export const ShippingAddressRadioTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
`
export const ShippingAddressRadioSubTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`
export const SplitButton = styled.button`
  background-color: transparent;
  outline: none;
  border: 0;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  text-align: left;
  color: ${props => props.theme.color.primary600};

  &:focus {
    border: 1px solid ${props => props.theme.color.primary600};
  }
`

export const TotalDiscountBanner = styled.div`
  background: linear-gradient(180deg, #ebb320 0%, #f7901e 100%);
  border-radius: 8px;
  color: ${({ theme }) => theme.color.onPrimaryLight};
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  padding: 8px 16px;
  min-width: 224px;

  & > :first-child {
    font-size: 14px;
    line-height: 20px;
  }

  & > :last-child {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
`

export const TruckText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.onPrimaryDark};
`

export const TruckDescription = styled.div`
  max-width: 500px;
  margin-top: 8px !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

export const FarmerComment = styled.div`
  word-break: break-word;
  font-style: Italic;
`

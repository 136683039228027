import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import ProductOptionsActions from './duck'
import ProductOptionsSelectors from './selectors'
import { ProductOptions } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { RequestError } from 'service/api/errors'
import { apiCall } from 'modules/sagaEffects'

export const fetchList = function*() {
  try {
    const filter = yield select(ProductOptionsSelectors.filter)
    const sorting = yield select(ProductOptionsSelectors.sorting)
    const response: ListResponse<ProductOptions> = yield call(apiCall, managers.getList, filter, sorting)
    const { data } = response
    yield put(ProductOptionsActions.listRequestSucceed(data))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductOptionsActions.listRequestFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof ProductOptionsActions.itemRequested>) {
  try {
    const productOptions: ProductOptions = yield call(apiCall, managers.getItem, id)
    yield put(ProductOptionsActions.itemRequestSucceed(productOptions))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductOptionsActions.itemRequestFailed(id, errType))
  }
}

const ProductOptionsSaga = function*() {
  yield all([
    takeLatest(ProductOptionsActions.itemRequested.type, fetchItem),
    takeLatest(ProductOptionsActions.listRequested.type, fetchList),
  ])
}

export default ProductOptionsSaga

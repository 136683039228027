import { apiCall, makeRouteWatcher } from 'modules/sagaEffects'
import { all, call, put, select, takeLatest, fork, race, take } from 'redux-saga/effects'
import CardActions from './duck'
import CardSelectors from './selectors'
import { Card, CardListRequestFilter } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { generateCountryPath, updateLocationQuery } from 'modules/sagaHelpers'
import ProducerRoutes from 'views/pages/Producer/routes'
import ProducerActions from 'modules/domain/producer/duck'
import CollectionActions from 'modules/domain/collection/duck'
import CollectionSelectors from 'modules/domain/collection/selectors'
import ProducerSelectors from 'modules/domain/producer/selectors'
import { Company } from 'types/entities'
import { Category } from 'modules/domain/collection/types'
import { RequestError } from 'service/api/errors'
import { replace } from 'connected-react-router'

export const fetchList = function*({
  payload: { sellerSlug, subCategorySlug },
}: ReturnType<typeof CardActions.listRequested>) {
  try {
    let currentPage = yield select(CardSelectors.page)
    const filter = yield select(CardSelectors.filter)
    const sorting = yield select(CardSelectors.sorting)
    const pageSize = yield select(CardSelectors.pageSize)

    let response: ListResponse<Card> = yield call(
      apiCall,
      managers.getList,
      sellerSlug,
      subCategorySlug,
      filter,
      sorting,
      currentPage,
      pageSize,
    )

    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(apiCall, managers.getList, sellerSlug, subCategorySlug, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(CardActions.listRequestSucceed(data, total_count, page))

    yield call(updateLocationQuery, ProducerRoutes.ProductOrCardList, {
      page: currentPage,
      filter_attributes: filter.attributes ? JSON.stringify(filter.attributes) : undefined,
    })
  } catch (err) {
    console.error(err)
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CardActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*({
  payload: { sellerSlug, subCategorySlug },
}: ReturnType<typeof CardActions.listRequestedNext>) {
  try {
    const page = yield select(CardSelectors.page)
    const filter = yield select(CardSelectors.filter)
    const sorting = yield select(CardSelectors.sorting)
    const pageSize = yield select(CardSelectors.pageSize)

    const { data, total_count }: { data: Card[]; total_count: number } = yield call(
      apiCall,
      managers.getList,
      sellerSlug,
      subCategorySlug,
      filter,
      sorting,
      page,
      pageSize,
    )

    yield put(CardActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    console.error(err)
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CardActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: [sellerSlug, idOrSlug] }: ReturnType<typeof CardActions.itemRequested>) {
  try {
    const cached = yield select(CardSelectors.item, idOrSlug)
    if (cached && cached.sku_resp !== null) {
      yield put(CardActions.itemRequestSucceed(cached))
      return
    }
    const card = yield call(apiCall, managers.getItem, sellerSlug, idOrSlug)
    yield put(CardActions.itemRequestSucceed(card))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CardActions.itemRequestFailed(idOrSlug, errType))
  }
}

export const fetchBestOffer = function*({
  payload: [sellerSlug, cardSlug],
}: ReturnType<typeof CardActions.bestOfferRequested>) {
  try {
    const cached = yield select(CardSelectors.bestOffer, cardSlug)
    if (cached) {
      yield put(CardActions.bestOfferRequestSucceed(cardSlug, cached))
      return
    }
    const card = yield call(apiCall, managers.getBestOffer, sellerSlug, cardSlug)
    yield put(CardActions.bestOfferRequestSucceed(cardSlug, card))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CardActions.bestOfferRequestFailed(cardSlug, errType))
  }
}

export const fetchExclusiveItem = function*({
  payload: [id, producerSlug],
}: ReturnType<typeof CardActions.exclusiveItemRequested>) {
  try {
    const card: Card = yield call(apiCall, managers.getExclusiveItem, id)

    if (!card || !card.is_exclusive) {
      throw new Error()
    }

    const categorySlug = card.category?.slug
    const subCategorySlug = card.subcategory?.slug
    const productOrCardSlug = card.slug

    const path = yield call(generateCountryPath, ProducerRoutes.ProductOrCardItem, {
      producerSlug,
      categorySlug,
      subCategorySlug,
      productOrCardSlug,
    })

    yield put(CardActions.exclusiveItemRequestSucceed())
    yield put(replace(path))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(CardActions.exclusiveItemRequestFailed(errType))
  }
}

const routerWatcher = makeRouteWatcher(ProducerRoutes, function*(result) {
  if (result.silent) {
    return
  }

  switch (result.match) {
    case ProducerRoutes.ProductOrCardList: {
      yield put(ProducerActions.itemRequested(result.params.producerSlug))
      yield race([take(ProducerActions.itemRequestSucceed.type), take(ProducerActions.itemRequestFailed.type)])

      const seller: Company = yield select(ProducerSelectors.item, result.params.producerSlug)
      const isStorefront = seller?.sku_orders

      if (!isStorefront) {
        return
      }

      yield put(CollectionActions.categoriesRequested())
      yield race([
        take(CollectionActions.categoriesRequestSucceed.type),
        take(CollectionActions.categoriesRequestFailed.type),
      ])

      const subCategory: Category = yield select(CollectionSelectors.categoryByIdOrSlug, result.params.subCategorySlug)
      if (!subCategory || !seller) {
        return
      }

      const cardFilter: CardListRequestFilter = yield select(CardSelectors.filter)
      // todo also check subcategory slug here
      if (cardFilter.sellerId === seller.id) {
        return
      }

      let filter = cardFilter
      // todo and here
      if (cardFilter.sellerId && cardFilter.sellerId !== seller.id) {
        filter = {}
      }

      yield all([
        put(
          CardActions.listRequested({
            sellerSlug: result.params.producerSlug,
            subCategorySlug: result.params.subCategorySlug,
            filter,
          }),
        ),
        put(CollectionActions.filterAttributesRequested(seller.slug, subCategory.slug)),
        put(
          CollectionActions.companySubCategoriesRequested(
            result.params.producerSlug,
            result.params.categorySlug,
            isStorefront,
          ),
        ),
      ])
      break
    }

    case ProducerRoutes.ProductOrCardItem: {
      yield put(ProducerActions.itemRequested(result.params.producerSlug))
      yield race([take(ProducerActions.itemRequestSucceed.type), take(ProducerActions.itemRequestFailed.type)])

      const seller: Company = yield select(ProducerSelectors.item, result.params.producerSlug)
      const isStorefront = seller?.sku_orders

      if (!isStorefront) {
        return
      }

      yield all([
        put(CardActions.itemRequested(result.params.producerSlug, result.params.productOrCardSlug)),
        put(CardActions.bestOfferRequested(result.params.producerSlug, result.params.productOrCardSlug)),
      ])

      const { error } = yield race({
        error: take(CardActions.itemRequestFailed.type),
        success: take(CardActions.itemRequestSucceed.type),
      })

      if (error) {
        return
      }

      break
    }

    default:
      break
  }
})

const CardSaga = function*() {
  yield all([
    takeLatest(CardActions.itemRequested.type, fetchItem),
    takeLatest(CardActions.listRequested.type, fetchList),
    takeLatest(CardActions.filterUpdated.type, fetchList),
    takeLatest(CardActions.sortingUpdated.type, fetchList),
    takeLatest(CardActions.filterHasBeenReset.type, fetchList),
    takeLatest(CardActions.sortingHasBeenReset.type, fetchList),
    takeLatest(CardActions.listRequestedNext.type, fetchListNext),
    takeLatest(CardActions.bestOfferRequested.type, fetchBestOffer),
    takeLatest(CardActions.exclusiveItemRequested.type, fetchExclusiveItem),
    fork(routerWatcher),
  ])
}

export default CardSaga

import { combineReducers, Reducer } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { reducer as auth } from './domain/auth/duck'
import { reducer as signUp } from './domain/signup/duck'
import { reducer as collection } from './domain/collection/duck'
import { reducer as product } from './domain/product/duck'
import { reducer as producer } from './domain/producer/duck'
import { reducer as cart } from './domain/cart/duck'
import { reducer as cartSku } from './domain/cartSku/duck'
import { reducer as checkout } from './domain/checkout/duck'
import { reducer as order } from './domain/order/duck'
import { reducer as documents } from './domain/documents/duck'
import { reducer as productOptions } from './domain/productOptions/duck'
import { reducer as packageTypes } from './domain/packageTypes/duck'
import { reducer as relatedProduct } from './domain/relatedProduct/duck'
import { reducer as card } from './domain/card/duck'
const rootReducer = (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    auth,
    signUp,
    collection,
    product,
    producer,
    cart,
    cartSku,
    checkout,
    order,
    documents,
    productOptions,
    packageTypes,
    relatedProduct,
    card,
  })

export default rootReducer

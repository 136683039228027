import { useProducerList } from 'modules/domain/producer/hooks'
import { checkFeatureFlags } from 'helpers/featureFlags'
import { useCallback } from 'react'
import { CompanyConfig } from 'types/entities'

const useCompaniesFeatureFlags = () => {
  const [, producerList] = useProducerList()
  const configs = producerList?.map(producer => producer.config).filter(Boolean) as CompanyConfig[]

  const hasSome = useCallback(configs => {
    const checkers = configs.map(config => checkFeatureFlags(config?.seller_config?.feature_flags))
    return (featureFlag: string) => checkers.some(check => check(featureFlag))
  }, [])

  const hasEvery = useCallback(configs => {
    const checkers = configs.map(config => checkFeatureFlags(config?.seller_config?.feature_flags))
    return (featureFlag: string) => checkers.every(check => check(featureFlag))
  }, [])

  return [hasSome(configs), hasEvery(configs)]
}

export default useCompaniesFeatureFlags

import styled from 'styled-components'
import Link from 'views/components/Link/Link'
import DiscountBannerComponent from 'views/ui/DiscountBanner/DiscountBanner'
import { DiscountBadge as DiscountBadgeComponent } from '@agro-club/frontend-shared'

export const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`
export const MainContent = styled.div`
  position: relative;
  display: grid;
  min-height: 0;
  min-width: 0;
  flex-grow: 1;
  grid-template-areas:
    'sidebar discount'
    'sidebar list';
  grid-template-columns: minmax(min-content, 302px) auto;
  grid-gap: 24px;
  margin-bottom: 24px;

  ${props => props.theme.media.desktop`
      grid-template-rows: min-content 1fr;
  `}

  ${props => props.theme.media.tablet`
    grid-template-rows: min-content 1fr;
    grid-template-areas:
      'discount discount'
      'sidebar list';
  `}

  ${props => props.theme.media.mobile`
    grid-template-columns: auto;
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas:
      'sidebar'
      'discount'
      'list';
  `}
`

export const ProducerBlock = styled.div`
  background: #ffffff;
  color: ${props => props.theme.color.onPrimaryLight};
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 16px 24px 24px;
  align-self: start;

  ${props => props.theme.media.mobile`
    margin: 0 16px;
  `}
`

export const ProducerImage = styled.img`
  max-width: 100%;
  max-height: 100px;
`

export const ProducerName = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
  margin: 16px 0 8px;
`

export const ProducerDescription = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
`

export const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  grid-area: list;
  align-self: flex-start;

  ${props => props.theme.media.smallTablet`
    grid-template-columns: 1fr;
  `}

  ${props => props.theme.media.mobile`
    grid-template-columns: 1fr;
    grid-area: initial;
    margin: 0 16px;
  `}
`

export const Item = styled(Link)`
  background: #ffffff;
  border-radius: 16px;
  min-height: 120px;
  display: flex;
  padding: 0 24px 0 0;
  position: relative;
  overflow: hidden;
  transition: box-shadow 0.2s ease-in;
  &:hover {
    box-shadow: ${props => props.theme.shadow.blur40};
  }
`

export const ItemTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.color.onPrimaryDark};
  margin-top: 16px;
`
export const ItemDescription = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  margin-top: 8px;
  margin-bottom: 16px;
`

export const ItemImage = styled.img`
  max-height: 120px;
  margin-right: 20px;
  align-self: center;
`

export const ItemImageStub = styled.div`
  height: 104px;
  width: 96px;
  margin: 8px 20px 8px 8px;
  background-color: ${props => props.theme.color.secondary50};
  border-radius: 8px;
  opacity: 0.5;
`

export const PromoInfo = styled(DiscountBannerComponent)`
  grid-area: discount;
`

export const DiscountBadge = styled(DiscountBadgeComponent)`
  position: absolute;
  top: 8px;
  right: 8px;

  ${props => props.theme.media.tablet`
    top: 0;
    right: 8px;
    transform: translateY( -50%);
  `}

  ${props => props.theme.media.smallTablet`
      top: 8px;
      right: 8px;
      left: auto;
      transform: none;
  `}
`

export const SidebarBlock = styled.div`
  grid-area: sidebar;
`

export const ReOrderFormContainer = styled.div`
  margin-top: 16px;

  ${props => props.theme.media.mobile`
      margin-top: 0;
  `}
`

import { Button } from '@agro-club/frontend-shared'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import ErrorLayout from '../ErrorLayout/ErrorLayout'

const StyledButton = styled(Button)`
  margin-top: 40px;
`

const Layout404 = () => {
  const { t } = useTranslation('404')
  const history = useHistory()

  return (
    <>
      <Helmet>
        <title>{t('header')}</title>
        <meta name="description" content={t('descriptionText')} />
      </Helmet>
      <ErrorLayout header={t('header')} description={t('descriptionText')} imageSrc={require('./eyes.png')}>
        <StyledButton intent="primary" filled onClick={() => history.push('/')}>
          {t('descriptionLink')}
        </StyledButton>
      </ErrorLayout>
    </>
  )
}

export default Layout404

import {
  FeatureFlagModifiers,
  FeatureFlagTypes,
  FieldLocation,
  FieldNames,
  Sections,
  Actions,
  Buttons,
} from 'types/entities/config'

export const generateFieldModifierString = (
  location: FieldLocation,
  fieldName: FieldNames,
  modifier: FeatureFlagModifiers,
): string => {
  return `${FeatureFlagTypes.Field}-${location}-${fieldName}-${modifier}`
}

export const generateCustomFeatureFlag = (location: string, featureName: string) => {
  return `${FeatureFlagTypes.Custom}-${location}-${featureName}`
}

export const generateFieldAccessString = (
  section: string,
  fieldName: string,
  modifier: FeatureFlagModifiers,
): string => {
  return `${FeatureFlagTypes.Field}-${section}-${fieldName}-${modifier}`
}

export const generateActionAccessString = (
  section: Sections,
  action: Actions,
  modifier: FeatureFlagModifiers,
): string => {
  return `${FeatureFlagTypes.Action}-${section}-${action}-${modifier}`
}

export const generateButtonAccessString = (
  section: Sections,
  button: Buttons,
  modifier: FeatureFlagModifiers,
): string => {
  return `${FeatureFlagTypes.Button}-${section}-${button}-${modifier}`
}

import styled, { css, StyledProps } from 'styled-components'

type WrapperProps = StyledProps<{
  hasLogo: boolean
  hasFooterLogo: boolean
  isWide: boolean
}>
const areaLeftLogoCheck = (props: WrapperProps) => (props.hasLogo ? 'left-logo' : '')
const areaLogoCheck = (props: WrapperProps) => (props.hasFooterLogo ? 'logo' : '')
const columnLeftLogoCheck = (props: WrapperProps) => (props.hasLogo ? 'min-content' : '')
const columnLogoCheck = (props: WrapperProps) => (props.hasLogo ? 'max-content' : '')

export const Link = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${(props: StyledProps<{}>) => props.theme.color.primary600};
  white-space: nowrap;

  a {
    color: ${(props: StyledProps<{}>) => props.theme.color.primary600};
  }
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    '${areaLeftLogoCheck} copyright apps ... contacts ${areaLogoCheck}'
    '${areaLeftLogoCheck} policies apps ... contacts ${areaLogoCheck}';
  grid-template-columns: ${columnLeftLogoCheck} ${(props: WrapperProps) =>
  props.hasLogo ? '25%' : 'max-content'} auto auto max-content ${columnLogoCheck};

  height: 80px;
  max-width: ${props => props.theme.size.maxContentWidth}px;
  column-gap: 24px;
  margin: 0 auto;


  ${props => props.theme.media.custom({
    maxWidth: props.isWide ? props.theme.breakpoints.tablet.maxWidth : props.theme.breakpoints.mobile.maxWidth,
  })`
    grid-template-areas:
      'contacts apps'
      'copyright apps-text'
      'policies policies'
      'left-logo logo' ;
    height: auto;
    padding: ${props.theme.padding.mobile.content}px;
    grid-template-columns: auto auto;
  `}

  ${props =>
    props.isWide &&
    props.theme.media.custom({
      maxWidth: props.theme.breakpoints.tablet.maxWidth,
    })`
      ${Link} + ${Link} {
      margin-top: 4px;
      margin-left: 0;
    }
  `}
`

export const Text = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${(props: StyledProps<{}>) => props.theme.color.onPrimaryDark};

  a {
    color: ${(props: StyledProps<{}>) => props.theme.color.onPrimaryDark};
  }

  ${props => props.theme.media.mobile`
    font-size: 16px;
  `}
`

export const Apps = styled.div`
  display: flex;
  margin-left: 12px;
  align-items: center;
`

export const AppBadge = styled.a`
  margin-left: 4px;
  opacity: 0.65;
  img {
    display: block;
  }
`

export const AppsText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: ${(props: StyledProps<{}>) => props.theme.color.onSurfaceMidEmphasys};

  ${props => props.theme.media.mobile`
    margin-top: 4px;
    text-align: center;
  `}
`

export const WhiteLabelLogo = styled.img`
  display: block;
  max-height: 54px;
  margin-bottom: 8px;
  ${(props: StyledProps<{}>) => props.theme.media.smallTablet`height: 60px;`}
  ${(props: StyledProps<{}>) => props.theme.media.mobile`height: 48px;`}
`

type AreaProps = StyledProps<{
  area: 'copyright' | 'policies' | 'contacts' | 'apps' | 'apps-text' | 'logo' | 'left-logo'
  isFooterWide?: boolean
}>
export const Area = styled.div`
  grid-area: ${(props: AreaProps) => props.area};
  ${(props: AreaProps) => {
    switch (props.area) {
      case 'logo':
      case 'apps-text':
      case 'apps':
        return css`
          align-self: center;
          justify-self: center;
          text-align: center;
        `
      case 'left-logo':
        return css`
          align-self: center;
          justify-self: start;
        `
      case 'contacts':
        return css`
          align-self: center;
          justify-self: center;
        `
      case 'apps-text':
        return css`
          align-self: center;
          justify-self: end;
        `
      case 'copyright':
        return css`
          align-self: end;
          margin-bottom: 4px;
        `
      case 'policies':
        return css`
          display: flex;
          flex-wrap: wrap;
          gap: 0 12px;
        `
    }
  }}

${props =>
  props.theme.media.custom({
    maxWidth: props.theme.breakpoints.tablet.maxWidth,
  })`
    ${(props: AreaProps) => {
      switch (props.area) {
        case 'apps-text':
        case 'apps':
          return css`
            justify-self: end;
            text-align: right;
          `
      }
    }}`}

  ${props =>
    props.theme.media.custom({
      maxWidth: props.isFooterWide ? props.theme.breakpoints.tablet.maxWidth : props.theme.breakpoints.mobile.maxWidth,
    })`
    ${(props: AreaProps) => {
      switch (props.area) {
        case 'left-logo':
          return `margin-top: 16px;`
        case 'logo':
          return css`
            margin-top: 16px;
            justify-self: end;
          `
        case 'apps-text':
        case 'apps':
          return css`
            justify-self: end;
          `
        case 'contacts':
          return css`
            align-self: center;
            justify-self: start;
          `
        case 'copyright':
          return css`
            align-self: end;
            margin-top: 16px;

            ${Text} {
              font-size: 14px;
            }
          `
        case 'apps-text':
          return css`
            align-self: center;
            justify-self: end;
          `
        case 'policies':
          return css`
            display: flex;
            justify-content: space-between;
            gap: 12px;

            ${props.isFooterWide &&
              `
              justify-content: start;
              flex-direction: column;
              margin-top: 16px;
            `}

            ${Link} a {
              color: ${props.theme.color.secondary200};
            }
          `
      }
    }}
  `}
`

import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Input, Checkbox, useAction, useDidMount } from '@agro-club/frontend-shared'
import styled, { css, StyledProps } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { AnalyticsEvent, Progress } from 'modules/types'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import PhoneInput from 'views/components/PhoneInput/PhoneInput'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import useWhiteLabelConfig from 'hooks/useWhiteLabelConfig'
import useLangPicker from 'hooks/useLangPicker'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import { Page } from 'modules/utils/analytics-utils/pageNames'

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`

const Tab = styled.div<StyledProps<{ selected?: boolean }>>`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.secondary200};
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  ${props =>
    props.selected &&
    css`
      color: ${props.theme.color.onPrimaryDark};
      background-color: ${props.theme.color.secondary50};
      cursor: default;
    `}
`

const BodyInner = styled.form`
  max-width: 320px;
  ${props => props.theme.media.mobile`
    max-width: 100%;
  `}
`

const SwitchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  & > :first-child {
    margin-right: 4px;
  }

  & > :last-child {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: ${({ theme }) => theme.color.onPrimaryDark};
  }
`

const Terms = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.secondary200};

  a {
    color: ${props => props.theme.color.primary500};
  }
`

const ButtonContainer = styled.div`
  position: relative;
  margin-top: 24px;
  width: 180px;

  ${props => props.theme.media.mobile`
    margin-top: 20px;
    width: 100%;
  `}

  & > * {
    width: 100%;
  }
`

const ErrorText = styled.div`
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.color.accentDestructive};
  white-space: nowrap;
`

type AccountFormProps = {
  mode: 'phone' | 'email'
  email: string
  phone?: string
}

type PropsType = {
  initialValues: AccountFormProps
  onSubmit(props: AccountFormProps): void
  progress?: Progress
}

const AccountForm: React.FC<PropsType> = ({ initialValues, onSubmit, progress }: PropsType) => {
  const { track } = useAnalyticsSSR()
  const { t, i18n } = useTranslation(['auth', 'common', 'components', 'validation'])
  const detectedCountry = useDetectedCountry()
  const lang = i18n.language.slice(0, 2)
  const [isPhoneValid, setIsPhoneValid] = useState(!!initialValues.phone)
  const formik = useFormik<AccountFormProps>({
    initialValues,
    onSubmit: values => {
      if (isPhoneValid) {
        onSubmit(values)
      } else {
        formik.setFieldError('phone', t('validation:invalidPhone'))
      }
    },
  })

  const { config: wlConfig } = useWhiteLabelConfig()
  const config = wlConfig?.legal_docs
  const { pick } = useLangPicker()

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    formik.submitForm()
    track('signin_phone_next_tap', { country: detectedCountry, valid: isPhoneValid })
  }

  const termsAgreed = useSelector(AuthSelectors.getTermsAgreed)
  const setTermsAgreed = useAction(AuthActions.setTermsAgreed)

  const handleTermsAgreed = useCallback(
    (_, isChecked) => {
      setTermsAgreed(isChecked)
    },
    [setTermsAgreed],
  )

  // const setEmailMode = () => formik.values.mode !== 'email' && formik.setFieldValue('mode', 'email')
  const setPhoneMode = () => formik.values.mode !== 'phone' && formik.setFieldValue('mode', 'phone')

  const termsOfUse = useMemo(() => {
    return `
      <a id="signin_phone_agreement_tap" href="${
        config?.user_agreement ? pick(config.user_agreement) : `/agreement_${lang}.pdf`
      }" target="_blank" rel="noopener noreferrer">
        ${t('components:AuthForm:termsOfUse')}
      </a>
    `
  }, [lang, t, config?.user_agreement, pick])

  const privacyPolicy = useMemo(() => {
    return `
      <a id="signin_phone_personal_data_tap" href="${
        config?.privacy_policy ? pick(config.privacy_policy) : `/privacy-policy_${lang}.pdf`
      }" target="_blank" rel="noopener noreferrer">
        ${t('components:AuthForm:privacyPolicy')}
      </a>
    `
  }, [lang, t, config?.privacy_policy, pick])

  useDidMount(() => {
    track(AnalyticsEvent.Page, {
      name: Page.SignIn,
      country: detectedCountry,
    })
  })

  return (
    <BodyInner onSubmit={handleSubmit} data-test-id="account-form" id="account-form">
      <TabContainer>
        <Tab selected={formik.values.mode === 'phone'} onClick={setPhoneMode}>
          {t('components:AuthForm.loginByPhone')}
        </Tab>
      </TabContainer>
      {formik.values.mode === 'phone' ? (
        <PhoneInput
          id={'phone-input'}
          phoneNumber={formik.values.phone}
          onBlur={() => {
            formik.setTouched({ phone: true })
          }}
          onChange={(phoneNumber, _code, _national, isValid) => {
            formik.setFieldValue('phone', phoneNumber)
            formik.setFieldError('phone', undefined)
            setIsPhoneValid(isValid)
          }}
          errorText={formik.errors.phone}
          invalid={!!formik.errors.phone}
        />
      ) : (
        <Input {...formik.getFieldProps('email')} type={'email'} />
      )}
      <SwitchContainer>
        <Checkbox isChecked={termsAgreed} onChange={handleTermsAgreed} id={'terms_of_use'} />
        <Terms
          dangerouslySetInnerHTML={{ __html: t('components:AuthForm.terms', { termsOfUse, privacyPolicy }) }}
          onClick={e => {
            const id = (e.target as any).id
            id && track(id, { country: detectedCountry })
          }}
        />
      </SwitchContainer>
      <ButtonContainer>
        {progress === Progress.ERROR ? (
          <ErrorText data-test-id="error">{t('common:errors.unknownError')}</ErrorText>
        ) : null}
        <Button
          id="account-form-next-button"
          intent={'primary-action'}
          filled
          type={'submit'}
          size={'big'}
          progress={progress}
          disabled={!termsAgreed}
        >
          {t('common:next')}
        </Button>
      </ButtonContainer>
    </BodyInner>
  )
}

export default AccountForm

import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { HashLink } from 'react-router-hash-link'
import { Button as ButtonComponent, useAction } from '@agro-club/frontend-shared'

import { GiftTierRule } from 'types/entities/discount'
import { Product } from 'modules/domain/product/types'
import CartActions from 'modules/domain/cart/duck'
import CartSelectors from 'modules/domain/cart/selectors'
import Link from 'views/components/Link/Link'
import { useCountryPath } from 'hooks/useCountryPath'
import { Routes } from 'views/pages/routes'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import ProductItemEvents from 'views/pages/Producer/Product/ProductItem/events'
import { giftToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 16px 24px 20px;
  background-color: ${props => props.theme.color.onPrimaryLight};
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  font-family: Montserrat;
`

const Title = styled.h1`
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`

const CongratsSubtitle = styled.p`
  margin: 8px 0 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.color.accentApprove400};
  text-align: center;
`

const Description = styled.p`
  margin: 8px 0 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`

const ExploreLink = styled(HashLink)`
  height: 40px;
  margin: 24px 0 0;
  padding: 10px 24px;
  border-radius: 8px;
  text-align: center;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryLight};
  background-color: ${props => props.theme.color.primary600};

  &:hover {
    background-color: ${props => props.theme.color.primary500};
  }
`

const Button = styled(ButtonComponent)`
  width: 100%;
  margin: 24px 0 0;
`

type Props = {
  product: Product
}

const GiftApplyingTerms: React.FC<Props> = ({ product }) => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation('gift')
  const generateCountryPath = useCountryPath()
  const { producerSlug } = useParams<{ producerSlug: string }>()
  const discounts = useSelector(state => CartSelectors.discounts(state, producerSlug))
  const cartItems = useSelector(state => CartSelectors.cartItemsByProducer(state, producerSlug))

  const isGiftAvailable = discounts.some(discountRule => {
    const appliedTier = discountRule.tiers.find(t => t.is_applied)
    const availableGiftIds = (appliedTier?.tier_rule as GiftTierRule)?.available_gifts || []
    const giftsAddedToCart = cartItems.filter(cartItem => availableGiftIds.includes(cartItem.product_id))
    const isGiftQtyUnderLimit = (appliedTier?.tier_rule as GiftTierRule)?.limit > giftsAddedToCart.length
    return availableGiftIds.includes(product.id) && isGiftQtyUnderLimit
  })

  const addGiftAction = useAction(CartActions.fakeItemAddRequested)
  const handleAddGift = () => {
    addGiftAction(product, {
      qty: product.min_qty || 1,
      packaging: null,
    })
    track(ProductItemEvents.GiftItemAddToTruck, giftToEventParams(product))
  }
  const isGiftAdded = cartItems.some(cartItem => cartItem.product_id === product.id)

  return (
    <Wrapper>
      <Title>{t('giftApplyingTermsTitle')}</Title>
      {isGiftAvailable ? (
        <>
          <CongratsSubtitle>{t('congratsTitle')}</CongratsSubtitle>
          <Description>{t('conditionsFulfilled')}</Description>
          <Button onClick={handleAddGift} intent="primary" size="medium" filled>
            {t('getThisGiftButton')}
          </Button>
        </>
      ) : isGiftAdded ? (
        <>
          <CongratsSubtitle>{t('congratsTitle')}</CongratsSubtitle>
          <Description>{t('conditionsFulfilled')}</Description>
          <Link
            to={generateCountryPath(Routes.ProducerCart, { producerSlug })}
            onClick={() => track(ProductItemEvents.GiftItemGoToCheckout, giftToEventParams(product))}
          >
            <Button intent="secondary" size="medium">
              {t('order:gotoCheckout')}
            </Button>
          </Link>
        </>
      ) : (
        <>
          {/* TODO-1341 use Discount Rule -> campaign_description when API is ready */}
          <Description>{t('giftApplyingTermsDescription')}</Description>
          <ExploreLink
            smooth
            to="#related-products"
            onClick={() => track(ProductItemEvents.GiftItemExploreProducts, giftToEventParams(product))}
          >
            {t('exploreProducts')}
          </ExploreLink>
        </>
      )}
    </Wrapper>
  )
}

export default GiftApplyingTerms

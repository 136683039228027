import {
  ProducerParams,
  CategoryParams,
  SubcategoryParams,
  ProductParams,
  GiftParams,
  CardParams,
} from './eventParametersTypes'
import { Category } from 'modules/domain/collection/types'
import { Product } from 'modules/domain/product/types'
import { Company } from 'types/entities'
import { Card } from 'modules/domain/card/types'

export const producerToEventParams = (producer?: Company): ProducerParams => {
  return {
    country: producer?.country,
    producer_id: producer?.id,
    producer_name: producer?.official_name,
  }
}

export const categoryToEventParams = (producer?: Company, category?: Category): CategoryParams => {
  return {
    ...producerToEventParams(producer),
    category_id: category?.id,
    category_name: category?.title,
  }
}

export const subcategoryToEventParams = (
  producer?: Company,
  category?: Category,
  subcategory?: Category,
): SubcategoryParams => {
  return {
    ...categoryToEventParams(producer, category),
    subcategory_id: subcategory?.id,
    subcategory_name: subcategory?.title,
  }
}

export const productToEventParams = (product?: Product): ProductParams => {
  return {
    country: product?.producer?.country,
    producer_id: product?.producer_id,
    producer_name: product?.producer?.official_name,
    category_id: product?.category_id,
    category_name: product?.category?.title,
    subcategory_id: product?.subcategory_id,
    subcategory_name: product?.subcategory?.title,
    product_id: product?.id,
    product_title: product?.title,
  }
}

// todo card event params
export const cardToEventParams = (card?: Card): CardParams => {
  return {
    // country: card?.seller?.country,
    producer_id: card?.seller_id,
    // producer_name: card?.seller?.official_name,
    category_id: card?.category_id,
    category_name: card?.category?.title,
    subcategory_id: card?.subcategory_id,
    subcategory_name: card?.subcategory?.title,
    card_id: card?.id,
    card_title: card?.title,
  }
}

export const giftToEventParams = (gift?: Product): GiftParams => {
  return {
    country: gift?.producer?.country,
    producer_id: gift?.producer_id,
    producer_name: gift?.producer?.official_name,
    category_id: gift?.category_id,
    category_name: gift?.category?.title,
    subcategory_id: gift?.subcategory_id,
    subcategory_name: gift?.subcategory?.title,
    gift_id: gift?.id,
    gift_title: gift?.title,
  }
}

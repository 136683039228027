import { LocalizedValue } from 'types/entities'
import useLanguage from 'hooks/useLanguage'
import { useCallback } from 'react'

const fallbackSeq: (keyof LocalizedValue)[] = ['en', 'fr', 'es']

const useLangPicker = () => {
  const language = useLanguage()
  const pick = useCallback(
    (prop?: LocalizedValue): string => {
      if (!prop) {
        return ''
      }

      if (prop[language]) {
        return prop[language] || ''
      }

      const fallbackLanguage = fallbackSeq.find(l => prop[l])
      if (fallbackLanguage) {
        return prop[fallbackLanguage] || ''
      }
      return ''
    },
    [language],
  )

  return { pick, language }
}

export default useLangPicker

import { IconDocument, IconLinkOpener, Button } from '@agro-club/frontend-shared'
import React from 'react'
import styled from 'styled-components'

const FooterButtonStyled = styled(Button)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  background-color: ${props => props.theme.color.accentApproving100 + '11'};
`

const DocumentIconStyled = styled(IconDocument)`
  margin-right: 8px;
`
const LinkIconStyled = styled(IconLinkOpener)`
  margin-right: 8px;
  color: ${props => props.theme.color.primary600};
`

export const FooterButton: React.FC<{
  to?: string
  icon: 'document' | 'link'
  target?: string
  onClick?: () => void
}> = ({ to, children, icon, target, onClick }) => {
  const iconComponent = icon === 'link' ? <LinkIconStyled /> : <DocumentIconStyled />
  return (
    <FooterButtonStyled intent={'primary-action'} filled={false} size={'big'} onClick={onClick}>
      {to ? (
        <a href={to} target={target}>
          {iconComponent}
          {children}
        </a>
      ) : (
        <>
          {iconComponent}
          {children}
        </>
      )}
    </FooterButtonStyled>
  )
}

import { endpoints } from 'modules/endpoints'
import { CartItemResponse, AddCartItemParams, DiscountResponse, UpdateCartItemParams, CartResponse } from './types'
import { ApiService } from 'service/api/interface'
import { Promocode } from 'types/entities/promocode'
import { ProcoteWizardNode } from '@agro-club/frontend-shared'

export const fetchCart = (apiClient: ApiService) => (promocodes: string[] = []) => {
  return apiClient.get<CartResponse>(endpoints.cart('producers'), { promocodes })
}

export const addItem = (apiClient: ApiService) => (params: AddCartItemParams) => {
  return apiClient.post<CartItemResponse>(endpoints.cart(), params)
}

export const updateItem = (apiClient: ApiService) => (id: string, params: UpdateCartItemParams) => {
  return apiClient.put<CartItemResponse>(endpoints.cart(id), params)
}

export const deleteItem = (apiClient: ApiService) => (id: string) => {
  return apiClient.delete(endpoints.cart(id))
}

export const clearCart = (apiClient: ApiService) => (params: { producer_id?: string }) => {
  return apiClient.delete<{ deleted: number }>(endpoints.cart(), params)
}

export const fetchDiscount = (apiClient: ApiService) => (promocodes: string[] = []) => {
  return apiClient.get<DiscountResponse>(endpoints.discount(), { promocodes })
}

export const calculateDiscounts = (apiClient: ApiService) => (producer_id: string, promocodes: string[] = []) => {
  return apiClient.get<DiscountResponse>(endpoints.discountRules('cart/calculate'), { producer_id, promocodes })
}

export const fetchPromocodeStatus = (apiClient: ApiService) => ({
  code,
  company_id,
  appliedPromocodes,
}: {
  code: string
  company_id: string
  appliedPromocodes: string
}) => {
  return apiClient.get<Promocode>(endpoints.checkPromocode(), {
    code,
    company_id,
    applied_promocodes: appliedPromocodes,
  })
}

export const fetchProductWizard = (apiClient: ApiService) => (id: string, params: { sort_field: string }) => {
  return apiClient.get<ProcoteWizardNode>(endpoints.productWizard(id), params)
}

import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'
import env from 'env'

const plugins = []

if (env.SEGMENT_WRITE_KEY) {
  plugins.push(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    segmentPlugin({
      writeKey: env.SEGMENT_WRITE_KEY,
      disableAnonymousTraffic: false,
    }),
  )
}

const analyticsInstance = Analytics({
  app: 'Enterprise Market',
  version: '1',
  plugins,
})

export default analyticsInstance

import React from 'react'
import styled, { StyledProps } from 'styled-components'
import { useTranslation } from 'react-i18next'

const FormFieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 330px;

  :first-child {
    margin-top: 24px;
  }

  ${props => props.theme.media.mobile`
    width: 100%;
    max-width: 400px;
  `}
`

const FormFieldsetTitle = styled.legend`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  width: 100%;

  text-transform: uppercase;

  color: ${({ theme }) => theme.color.secondary200};
`

const Optional = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.accentNotify};
  margin-left: auto;
  text-transform: none;
`

type FormSectionProps = {
  title?: string
  optional?: boolean
  className?: string
}

export const FormSection: React.FC<FormSectionProps> = ({ title, children, optional, className }) => {
  const { t } = useTranslation('common')
  return (
    <FormFieldset className={className}>
      {!!title && (
        <FormFieldsetTitle>
          {title}
          {!!optional && <Optional>{t('optional')}</Optional>}
        </FormFieldsetTitle>
      )}
      {children}
    </FormFieldset>
  )
}

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: grid;
  grid-gap: 14px;
  grid-template-columns: max-content max-content;
  color: ${(props: StyledProps<{}>) => props.theme.color.onSurfaceHighEmphasys};
  margin-bottom: 24px;

  &:not(:first-child) {
    margin-top: 40px;
  }
`

export const CheckboxWrapper = styled.div`
  margin: 0 0 24px;
`

export const PartnershipInfo = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.onPrimaryDark};
  margin: -8px 0 16px;
`

export const PartnershipButton = styled.button`
  border: none;
  background: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.primary600};
  margin: 16px 0 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

import React, { useCallback, useEffect, useMemo } from 'react'
import { CellProps, useTable } from 'react-table'
import { useTranslation } from 'react-i18next'
import * as Styled from './styled'
import useMatchMedia from 'hooks/useMatchMedia'
import { queries } from 'theme/theme'
import { useCartList } from 'modules/domain/cart/hooks'
import { CartItem } from 'modules/domain/cart/types'
import useLangPicker from 'hooks/useLangPicker'
import { ProductUnits, SeedTreatment } from 'modules/domain/product/types'
import { getPrettyPrice } from 'modules/utils/helpers'
import { Company, Currency } from 'types/entities'
import {
  IconWarning,
  SectionTable,
  SectionTableBody,
  SectionTableBodyCell,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableBodyRow,
  SectionTableHeadRow,
  ProductWizardType,
  ProcoteWizardData,
} from '@agro-club/frontend-shared'
import { useParams } from 'react-router'
import { generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import { FeatureFlagModifiers, FieldLocation, FieldNames } from 'types/entities/config'
import ProductOptions, { Place } from '../ProductOptions/ProductOptions'
import { useProductOptionsList } from 'modules/domain/productOptions/hooks'
import { ProductOptions as ProductOptionsType } from 'modules/domain/productOptions/types'
import { WizardComment } from '../WizardNote/WizardComment'
import useCompanyFeatureFlags from 'hooks/useCompanyFeatureFlags'

const ProductCell: React.FC<CellProps<CartItem> & {
  productOptions: ProductOptionsType[]
}> = ({ row, productOptions }) => {
  const { t } = useTranslation(['checkout', 'common'])

  const { pick } = useLangPicker()
  let seedTreatment: React.ReactNode = null

  if (row.values.seed_treatment_id) {
    const value = row.values.product.seed_treatment.find(
      (item: SeedTreatment) => item.id === row.values.seed_treatment_id,
    )

    if (value) {
      seedTreatment = (
        <Styled.SeedTreatment>
          <Styled.SmallText>
            {t('common:seedTreatment')}
            {': '}
          </Styled.SmallText>
          {pick(value.title_i18n)}
        </Styled.SeedTreatment>
      )
    }
  }

  return (
    <Styled.ProductCell>
      <Styled.ProductCellText>
        <Styled.BoldText>{pick(row.values.title)}</Styled.BoldText>
        {seedTreatment}
        <ProductOptions
          selectedIds={row.values.options || []}
          options={row.values.product.options || []}
          optionsRequiredErrors={[]}
          place={Place.CONFIRMATION}
          productOptions={productOptions}
        />
        {row.original.wizard_comment && !row.original.wizard_data && <WizardComment note={row.values.wizard_comment} />}
        {row.original.wizard_data?.type === ProductWizardType.Procote && (
          <ProcoteWizardData data={row.original.wizard_data} />
        )}
        {row.original.product?.docusign_document_id && row.values.product?.legal_notes ? (
          <Styled.LegalNotes
            visibleOnHover={true}
            tooltipContent={
              <Styled.LegalNotesTooltipContent>
                {row.values.product.legal_notes.description}
              </Styled.LegalNotesTooltipContent>
            }
            temporary={true}
          >
            <IconWarning />
            {row.values.product.legal_notes.text}
          </Styled.LegalNotes>
        ) : null}
      </Styled.ProductCellText>
    </Styled.ProductCell>
  )
}
const StandardUnitsCell: React.FC<CellProps<CartItem>> = ({ cell }) => {
  const { pick } = useLangPicker()
  return <div>{pick(cell.value)}</div>
}

const Qty: React.FC<{ value: number; showUnits: boolean; units?: ProductUnits }> = ({ value, showUnits, units }) => {
  return (
    <Styled.QtyCell>
      {value}
      {showUnits && ` (${value > 1 ? units?.plural : units?.singular})`}
    </Styled.QtyCell>
  )
}

const QtyCell: React.FC<CellProps<CartItem> & {
  showUnits: boolean
}> = ({ cell, showUnits }) => {
  if (cell.row?.values.product.category.slug === 'duck-foot') return null
  return <Qty value={cell.value} showUnits={showUnits} units={cell.row?.values.product.units} />
}

const Cost: React.FC<{ currency: Currency; priceType: string; cost?: string; price?: string; slug?: string }> = ({
  cost,
  price,
  currency,
  slug,
  priceType,
}) => {
  const formatPrice = useCallback((price: string) => getPrettyPrice(price, currency), [currency])
  if (!cost) {
    return null
  }
  return (
    <Styled.Cost>
      <div>{formatPrice(cost)}</div>
      {price && slug !== 'duck-foot' && <div>{getPrettyPrice(price, currency || Currency.CAD, priceType)}</div>}
    </Styled.Cost>
  )
}

const CostCell: React.FC<CellProps<CartItem>> = ({ row }) => {
  const producer = row.values.product.producer
  return (
    <Cost
      cost={row.values.cost}
      price={row.values.product.price}
      currency={producer.currency}
      slug={row.values.product.category.slug}
      priceType={row.values.product.price_type}
    />
  )
}

const DiscountCell: React.FC<CellProps<CartItem>> = ({ value, row }) => {
  const producer = row.values.product.producer
  const formatPrice = useCallback(
    (price: number) => getPrettyPrice(price, producer.currency, row.values.product.price_type),
    [producer.currency, row.values.product.price_type],
  )
  return (
    <Styled.Cost>
      <div>{formatPrice(value)}</div>
    </Styled.Cost>
  )
}

const DesktopOrderPreviewTable: React.FC = () => {
  const { t } = useTranslation('components')
  const { producerSlug } = useParams<{ producerSlug: string }>()
  const [, itemsList = []] = useCartList(producerSlug)
  const [, productOptions] = useProductOptionsList()
  const hasAccess = useCompanyFeatureFlags(producerSlug)
  const showUnits = hasAccess(
    generateFieldModifierString(FieldLocation.CartList, FieldNames.Units, FeatureFlagModifiers.Enabled),
  )
  const allColumns = useMemo(
    () => [
      {
        Header: t('OrderPreviewTable.list.product'),
        accessor: 'title' as const,
        Cell: props => <ProductCell productOptions={productOptions} {...props} />,
      },
      {
        Header: t('OrderPreviewTable.list.cost'),
        accessor: 'cost' as const,
        Cell: CostCell,
      },
      {
        Header: t('OrderPreviewTable.list.discount'),
        accessor: 'discount_amount' as const,
        Cell: DiscountCell,
      },
      {
        Header: t('OrderPreviewTable.list.standardUnits'),
        accessor: 'default_packaging' as const,
        Cell: StandardUnitsCell,
      },
      {
        Header: 'alt_packaging',
        accessor: 'alt_packaging' as const,
      },
      {
        Header: 'product',
        accessor: 'product' as const,
      },
      {
        Header: 'seed_treatment_id',
        accessor: 'seed_treatment_id' as const,
      },
      {
        Header: 'options',
        accessor: 'options' as const,
      },
      {
        Header: t('OrderPreviewTable.list.qty'),
        accessor: 'qty' as const,
        Cell: QtyCell,
      },
      {
        Header: 'description',
        accessor: 'description' as const,
      },
      {
        Header: 'id',
        accessor: 'id' as const,
      },
      {
        Header: '',
        accessor: 'wizard_comment' as const,
      },
    ],
    [t, productOptions],
  )

  const someProductHasPrice = itemsList.some(item => !!item.product.price)
  const someEntryHasDiscount = itemsList.some(item => !!parseFloat(item.discount_amount || ''))
  const isOnlyDuckFoot = useMemo(() => {
    return itemsList.every(item => item.product?.category?.slug === 'duck-foot')
  }, [itemsList])

  const hiddenColumns = useMemo(
    () =>
      [
        'description',
        'id',
        'alt_packaging',
        'packaging',
        'product',
        'seed_treatment_id',
        'options',
        'wizard_comment',
        !someProductHasPrice && 'cost',
        !someEntryHasDiscount && 'discount_amount',
        isOnlyDuckFoot && 'qty',
      ].filter(Boolean) as string[],
    [someProductHasPrice, someEntryHasDiscount, isOnlyDuckFoot],
  )
  const { columns, rows, prepareRow, setHiddenColumns } = useTable<CartItem>({
    data: itemsList,
    columns: allColumns,
    initialState: { hiddenColumns },
  })
  useEffect(() => {
    setHiddenColumns(hiddenColumns)
  }, [hiddenColumns, setHiddenColumns])
  return (
    <SectionTable>
      <SectionTableHead>
        <SectionTableHeadRow>
          {columns.map(column => {
            if (hiddenColumns.includes(column.id)) {
              return null
            }
            return (
              <SectionTableHeadCell
                key={column.getHeaderProps().key}
                textAlign={['qty', 'cost', 'discount_amount'].includes(column.id) ? 'center' : 'left'}
              >
                {column.render('Header')}
              </SectionTableHeadCell>
            )
          })}
        </SectionTableHeadRow>
      </SectionTableHead>
      <SectionTableBody data-test-id={'order-preview-table'}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <SectionTableBodyRow key={row.getRowProps().key}>
              {row.cells.map(cell => {
                return (
                  <SectionTableBodyCell key={cell.getCellProps().key}>
                    {cell.render('Cell', { showUnits })}
                  </SectionTableBodyCell>
                )
              })}
            </SectionTableBodyRow>
          )
        })}
      </SectionTableBody>
    </SectionTable>
  )
}

const MobileOrderPreviewTable: React.FC<{ producer?: Company }> = ({ producer }) => {
  const { t } = useTranslation(['components', 'common'])
  const { pick } = useLangPicker()
  const { producerSlug } = useParams<{ producerSlug: string }>()
  const [, itemsList = []] = useCartList(producerSlug)
  const formatPrice = useCallback(
    (price: number | string) => getPrettyPrice(price, producer?.currency || Currency.CAD),
    [producer],
  )
  const hasAccess = useCompanyFeatureFlags(producerSlug)
  const showUnits = hasAccess(
    generateFieldModifierString(FieldLocation.CartList, FieldNames.Units, FeatureFlagModifiers.Enabled),
  )

  const qtyJSX = (item: CartItem) => (
    <Styled.MobileBlock>
      <Styled.MobileLabel>{t('OrderPreviewTable.list.quantity')}</Styled.MobileLabel>
      {item?.product?.category?.slug !== 'duck-foot' && (
        <Qty value={Number(item.qty)} showUnits={showUnits} units={item?.product?.units} />
      )}
    </Styled.MobileBlock>
  )

  return (
    <Styled.MobileCartList>
      {itemsList.map(item => {
        let seedTreatment: React.ReactNode = null

        if (item.seed_treatment_id) {
          const value = item.product.seed_treatment.find((el: SeedTreatment) => el.id === item.seed_treatment_id)

          if (value) {
            seedTreatment = (
              <Styled.SeedTreatment>
                <Styled.SmallText>
                  {t('common:seedTreatment')}
                  {': '}
                </Styled.SmallText>
                {pick(value.title_i18n)}
              </Styled.SeedTreatment>
            )
          }
        }
        const hasCost = !!parseFloat(item.cost || '')
        const hasDiscount = !!parseFloat(item.discount_amount || '')
        return (
          <Styled.MobileCartItem key={item.id}>
            <Styled.MobileBlock>
              <Styled.ProductCell>
                <Styled.ProductCellText>
                  <Styled.Text>{pick(item.title)}</Styled.Text>
                  {seedTreatment}
                </Styled.ProductCellText>
              </Styled.ProductCell>
            </Styled.MobileBlock>
            {hasCost && (
              <Styled.MobileBlock>
                <Styled.MobileLabel>{t('OrderPreviewTable.list.cost')}</Styled.MobileLabel>
                <Styled.NoWrapText>
                  <Cost
                    price={item.product.price || ''}
                    currency={producer?.currency || Currency.CAD}
                    cost={item.cost}
                    slug={item?.product?.category?.slug}
                    priceType={item?.product?.price_type}
                  />
                </Styled.NoWrapText>
              </Styled.MobileBlock>
            )}
            {hasDiscount && (
              <Styled.MobileBlock>
                <Styled.MobileLabel>{t('OrderPreviewTable.list.discount')}</Styled.MobileLabel>
                <Styled.NoWrapText>
                  <Styled.Text>{formatPrice(item.discount_amount || 0)}</Styled.Text>
                </Styled.NoWrapText>
              </Styled.MobileBlock>
            )}
            {qtyJSX(item)}
            <Styled.MobileBlock>
              <Styled.MobileLabel>{t('OrderPreviewTable.list.standardUnits')}</Styled.MobileLabel>
              <div>
                <Styled.StandardUnitsText>{pick(item.default_packaging)}</Styled.StandardUnitsText>
              </div>
            </Styled.MobileBlock>
            <Styled.MobileBlock>
              {item.wizard_comment && !item.wizard_data && <WizardComment note={item.wizard_comment} />}
              {item.wizard_data?.type === ProductWizardType.Procote && <ProcoteWizardData data={item.wizard_data} />}
            </Styled.MobileBlock>
          </Styled.MobileCartItem>
        )
      })}
    </Styled.MobileCartList>
  )
}

const OrderPreviewTable: React.FC<{ producer?: Company }> = ({ producer }) => {
  const isMobile = useMatchMedia(queries.mobile)
  if (isMobile) {
    return <MobileOrderPreviewTable producer={producer} />
  } else {
    return <DesktopOrderPreviewTable />
  }
}

export default OrderPreviewTable

import { createSelector } from 'reselect'
import { CalculatedDiscountDto, CalculatedTier } from 'types/entities/discount'
import { AppGlobalState, Progress } from '../../types'
import { Product } from './types'

const dict = (state: AppGlobalState) => state.product.items
const ids = (state: AppGlobalState) => state.product.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, idOrSlug: string): Product | undefined => {
  return state.product.items[idOrSlug]
    ? state.product.items[idOrSlug]
    : Object.values(state.product.items).find(p => p.slug === idOrSlug)
}
const meta = (state: AppGlobalState, id: string) =>
  state.product.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.product.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.product.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.product.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.product.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.product.listFetchNextError
const list = createSelector(dict, ids, (dict, ids) => {
  const result: Product[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const discountsFetchProgress = (state: AppGlobalState) => state.product.discountsFetchProgress
const discountsFetchError = (state: AppGlobalState) => state.product.discountsFetchError
const discounts = (state: AppGlobalState) => state.product.discounts

const fetchExclusiveItemError = (state: AppGlobalState) => state.product.fetchExclusiveItemError

const giftDict = (state: AppGlobalState) => state.product.gifts
const giftList = (state: AppGlobalState) => Object.values(state.product.gifts)

const giftFilter = (state: AppGlobalState) => state.product.giftFilter
const giftListFetchProgress = (state: AppGlobalState) => state.product.giftListFetchProgress
const giftListFetchError = (state: AppGlobalState) => state.product.giftListFetchError

const nextLvlSuggestion = createSelector(discounts, discounts => {
  // TODO Let's use ramda here?
  const allTiers: CalculatedTier[] = []

  discounts?.forEach(d => {
    allTiers.push(...d.tiers)
  })

  const allPositiveSuggestions = allTiers.map(tier => tier.qty_to_apply).filter(qty => qty > 0)

  const qty = Math.min(...allPositiveSuggestions)
  return isFinite(qty) ? qty : 0
})

const currentDiscountAmount = createSelector(discounts, discounts =>
  discounts ? discounts.reduce((sum, d) => sum + parseFloat(d.amount), 0) : 0,
)

const currentDiscountPerBag = createSelector(discounts, discounts => {
  const appliedTiers = discounts?.reduce((tiers: CalculatedTier[], discounts: CalculatedDiscountDto) => {
    const tier = discounts.tiers.find(t => t.is_applied)
    if (tier) {
      return [...tiers, tier]
    }
    return tiers
  }, [])

  const totalDiscountPerBag = appliedTiers?.reduce(
    (acc: number, tier) => acc + parseFloat(tier.tier_rule.amount || '0'),
    0,
  )

  return totalDiscountPerBag || 0
})

const filter = (state: AppGlobalState) => state.product.filter
const sorting = (state: AppGlobalState) => state.product.sorting
const page = (state: AppGlobalState) => state.product.page
const total = (state: AppGlobalState) => state.product.total
const updateProgress = (state: AppGlobalState) => state.product.updateProgress
const removeProgress = (state: AppGlobalState) => state.product.removeProgress
const addProgress = (state: AppGlobalState) => state.product.addProgress
const hasNext = (state: AppGlobalState) => state.product.page * state.product.pageSize < state.product.total
const pageSize = (state: AppGlobalState) => state.product.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.product.total / state.product.pageSize)

const customProductList = (state: AppGlobalState) => Object.values(state.product.customProductList)
const customProductListFetchProgress = (state: AppGlobalState) => state.product.customProductListFetchProgress
const customProductListFetchError = (state: AppGlobalState) => state.product.customProductListFetchError

export const ProductSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,

  discountsFetchProgress,
  discountsFetchError,
  discounts,

  fetchExclusiveItemError,

  giftDict,
  giftList,
  giftFilter,
  giftListFetchProgress,
  giftListFetchError,

  nextLvlQty: nextLvlSuggestion,
  currentDiscountAmount,
  currentDiscountPerBag,

  customProductList,
  customProductListFetchProgress,
  customProductListFetchError,
}

export default ProductSelectors

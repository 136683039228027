import useMatchMedia from 'hooks/useMatchMedia'
import React from 'react'
import { useRef, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { queries } from 'theme/theme'

const VideoIFrame = styled.iframe`
  margin-top: 40px;
  ${props => props.theme.media.mobile`
    margin-top: 24px;
  `}
`

export const VideoBlock: React.FC<{ src: string; onVideoClick: () => void }> = ({ src, onVideoClick }) => {
  const isMobile = useMatchMedia(queries.mobile)
  const isTablet = useMatchMedia(queries.tablet)
  const height = isMobile ? 162 : isTablet ? 270 : 270
  const iFrame = useRef<HTMLIFrameElement | null>(null)
  const handleClickVideo = useCallback((): void => {
    if (iFrame.current) {
      if (document.activeElement === iFrame.current) {
        onVideoClick()
      }
    }
  }, [onVideoClick])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.focus() //force focus on the current window;
      window.addEventListener('blur', handleClickVideo)
    }
    return () => window.removeEventListener('blur', handleClickVideo)
  }, [handleClickVideo])

  return (
    <VideoIFrame
      ref={iFrame}
      width="100%"
      height={`${height}px`}
      src={src}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={'Video'}
    />
  )
}

import { useSelector } from 'react-redux'
import CartSelectors from 'modules/domain/cart/selectors'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { Company } from 'types/entities'

const useHasTotalInfo = (producer?: Company): boolean => {
  const productHasTotalInfo = useSelector(state => CartSelectors.hasTotalInfo(state, producer?.slug))
  const skuHasTotalInfo = useSelector(state => CartSkuSelectors.hasTotalInfo(state, producer?.slug))
  return producer?.sku_orders ? skuHasTotalInfo || false : productHasTotalInfo
}

export default useHasTotalInfo

import { useSelector } from 'react-redux'
import { Progress, ResourceHook } from 'modules/types'
import CartActions from 'modules/domain/cart/duck'
import { CartItem } from 'modules/domain/cart/types'
import CartSelectors from 'modules/domain/cart/selectors'
import { useDidMount, useAction } from '@agro-club/frontend-shared'
import { useMemo } from 'react'

export const useCartList: ResourceHook<CartItem[]> = producerSlug => {
  const progress = useSelector(CartSelectors.initProgress)
  const items = useSelector(CartSelectors.cartItems)
  const initAction = useAction(CartActions.cartInitRequested)

  useDidMount(() => {
    if (progress === Progress.IDLE) {
      initAction()
    }
  })

  const filteredItems = useMemo(
    () => (producerSlug ? items.filter(i => i.product?.producer?.slug === producerSlug) : items),
    [producerSlug, items],
  )

  return [progress, filteredItems]
}

export const boot = (opts: { userId?: string; email?: string; app_id?: string } = {}) => {
  if (!process.browser) {
    return
  }
  if (!opts.app_id || !window.Intercom) {
    return
  }

  try {
    window.Intercom('boot', {
      app_id: opts.app_id,
      email: opts.email,
      user_id: opts.userId,
      created_at: Date.now(),
    })
  } catch (err) {
    console.error('Failed to boot intercom', err)
  }
}

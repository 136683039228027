import { OrderDeliveryAddress } from 'types/order/order'
import { Progress } from 'modules/types'

export enum CheckoutStep {
  SIGN_IN,
  INFO,
  CONFIRMATION,
  SUCCESS,
}

export enum DeliveryAddressType {
  MAIN = 'main',
  ADDITIONAL = 'additional',
  SELF_PICKUP = 'self-pickup',
  SPLIT = 'split',
}

export type CheckoutState = {
  step: CheckoutStep
  deliverAddressType: DeliveryAddressType
  deliverySplitAddresses: OrderDeliveryAddress[]
  checkoutProgress: Progress
  distributorId?: string
  distributorTitle?: string
  desiredDeliveryDate?: string
}

import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { FetchError } from 'modules/domain/types'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { Card, CardBestOffer, CardListRequestFilter, CardListRequestSorting, CardState } from './types'

export const initialState: CardState = {
  items: {},
  meta: {},

  bestOfferFetchProgress: Progress.IDLE,
  bestOfferFetchError: null,
  bestOffers: {},
  bestOffersMeta: {},

  fetchExclusiveItemProgress: Progress.IDLE,
  fetchExclusiveItemError: null,

  ids: [],
  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

class CardReducer extends ImmerReducer<CardState> {
  listRequested(params: {
    sellerSlug: string
    subCategorySlug: string
    filter?: CardListRequestFilter
    sorting?: CardListRequestSorting
    page?: number
  }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }
  listRequestSucceed(list: Card[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }
  listRequestFailed(error: FetchError) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }

  itemRequested(sellerSlug: string, idOrSlug: string) {
    this.draftState.itemFetchProgress = Progress.WORK
    const meta = {
      idOrSlug,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[idOrSlug] = {
      ...meta,
      ...this.draftState.meta[idOrSlug],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }
  itemRequestSucceed(item: Card) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    if (!this.draftState.meta[item.id] && this.draftState.meta[item.slug]) {
      this.draftState.meta[item.id] = this.draftState.meta[item.slug]
    }
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchError = null
    if (!this.draftState.meta[item.slug]) {
      this.draftState.meta[item.slug] = {
        id: item.id,
        updateProgress: Progress.IDLE,
        updateError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
      }
    } else {
      this.draftState.meta[item.slug].fetchProgress = Progress.SUCCESS
      this.draftState.meta[item.slug].fetchError = null
    }
    this.draftState.items[item.id] = item
    this.draftState.items[item.slug] = item
  }
  itemRequestFailed(id: string, error: FetchError) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
  }

  filterUpdated(params: { sellerSlug: string; subCategorySlug: string; filter?: CardListRequestFilter }) {
    this.draftState.filter = params.filter ? params.filter : {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingUpdated(sorting: CardListRequestSorting) {
    this.draftState.sorting = sorting
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
  }

  listRequestedNext(params: { sellerSlug: string; subCategorySlug: string; page?: number }) {
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
    this.draftState.listFetchNextProgress = Progress.WORK
  }

  listRequestNextSucceed(list: Card[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...new Set([...this.draftState.ids, ...getIds(list)])]
  }

  listRequestNextFailed(error: FetchError) {
    this.draftState.listFetchNextProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }

  bestOfferRequested(_sellerSlug: string, cardSlug: string) {
    this.draftState.bestOfferFetchProgress = Progress.WORK
    this.draftState.bestOffersMeta[cardSlug] = {
      slug: cardSlug,
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  bestOfferRequestSucceed(cardSlug: string, bestOffer: CardBestOffer) {
    this.draftState.bestOfferFetchProgress = Progress.SUCCESS
    this.draftState.bestOffersMeta[cardSlug].fetchProgress = Progress.SUCCESS
    this.draftState.bestOffersMeta[cardSlug].fetchError = null
    if (!this.draftState.bestOffersMeta[cardSlug]) {
      this.draftState.bestOffersMeta[cardSlug] = {
        slug: cardSlug,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
      }
    } else {
      this.draftState.bestOffersMeta[cardSlug].fetchProgress = Progress.SUCCESS
      this.draftState.bestOffersMeta[cardSlug].fetchError = null
    }
    this.draftState.bestOffers[cardSlug] = bestOffer
  }

  bestOfferRequestFailed(cardSlug: string, error: FetchError) {
    this.draftState.bestOfferFetchProgress = Progress.ERROR
    this.draftState.bestOffersMeta[cardSlug].fetchProgress = Progress.ERROR
    this.draftState.bestOffersMeta[cardSlug].fetchError = error
  }

  exclusiveItemRequested(_id: string, _producerSlug: string) {
    this.draftState.fetchExclusiveItemProgress = Progress.WORK
    this.draftState.fetchExclusiveItemError = null
  }
  exclusiveItemRequestSucceed() {
    this.draftState.fetchExclusiveItemProgress = Progress.SUCCESS
    this.draftState.fetchExclusiveItemError = null
  }
  exclusiveItemRequestFailed(error: FetchError) {
    this.draftState.fetchExclusiveItemProgress = Progress.ERROR
    this.draftState.fetchExclusiveItemError = error
  }
}

export const CardActions = createActionCreators(CardReducer)
export default CardActions
export const reducer = createReducerFunction(CardReducer, initialState)

import React from 'react'
import { CountrySelect as UICountrySelect, CountrySelectProps } from '@agro-club/frontend-shared'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import { useSelector } from 'react-redux'
import CollectionSelectors from 'modules/domain/collection/selectors'

export const CountrySelect: React.FC<CountrySelectProps> = ({ value, list, ...props }) => {
  const detectedCountry = useDetectedCountry()
  const countries = useSelector(CollectionSelectors.countries)
  return <UICountrySelect {...props} value={value || detectedCountry} list={list || countries} />
}

import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, useAction } from '@agro-club/frontend-shared'

import RelatedProductSelectors from 'modules/domain/relatedProduct/selectors'
import RelatedProductActions from 'modules/domain/relatedProduct/duck'
import useLangPicker from 'hooks/useLangPicker'
import { useCountryPath } from 'hooks/useCountryPath'
import { Routes } from 'views/pages/routes'
import Link from 'views/components/Link/Link'
import { Progress } from 'modules/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import ErrorLayout from 'views/layouts/ErrorLayout/ErrorLayout'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import ProductItemEvents from 'views/pages/Producer/Product/ProductItem/events'
import { Product } from 'modules/domain/product/types'
import { giftToEventParams, productToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'

const ListTitle = styled.h2`
  margin: 48px 0 24px;
  text-align: center;
`
const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 32px 0;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;

  ${props => props.theme.media.tablet`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${props => props.theme.media.smallTablet`
    grid-template-columns: repeat(2, 1fr);
  `}

${props => props.theme.media.custom({ maxWidth: 420 })`
    grid-template-columns: 1fr;
  `}
`

const Item = styled.article`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 16px;
  background-color: ${props => props.theme.color.onPrimaryLight};
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.04);
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: 104px;
  border-radius: 8px;
  object-fit: cover;
`

const ImageStub = styled.div`
  width: 100%;
  height: 104px;
  border-radius: 8px;
  background-color: ${props => props.theme.color.secondary50};
  opacity: 0.5;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 8px;
`

const ItemSubcategory = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.secondary300};
`

const ItemTitle = styled.h3`
  margin: 4px 0 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
`

const ItemShortDescription = styled.p`
  flex-grow: 1;
  margin: 4px 0 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.color.onPrimaryDark};
`

const ItemConditions = styled.p`
  margin: 8px 0 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.color.secondary300};
`

const ShowMoreButton = styled(Button)`
  display: flex;
  margin: 24px auto 0;
`

type Props = {
  title: string
  product: Product
}

const RelatedProducts: React.FC<Props> = ({ title, product }) => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation('relatedProducts')
  const { pick } = useLangPicker()
  const generateCountryPath = useCountryPath()

  const progress = useSelector(RelatedProductSelectors.listFetchProgress)
  const relatedProducts = useSelector(RelatedProductSelectors.list)
  const page = useSelector(RelatedProductSelectors.page)
  const pageSize = useSelector(RelatedProductSelectors.pageSize)
  const total = useSelector(RelatedProductSelectors.total)

  const isShowMoreButtonVisible = total > page * pageSize

  const fetchListNextAction = useAction(RelatedProductActions.listRequestedNext)
  const showMoreProgress = useSelector(RelatedProductSelectors.listFetchNextProgress)

  const handleFetchNextPage = () => {
    fetchListNextAction(page + 1)
    track(
      product.is_gift ? ProductItemEvents.GiftItemShowMoreProducts : ProductItemEvents.ProductItemShowMoreGifts,
      productToEventParams(product),
    )
  }

  if (progress === Progress.WORK) {
    return (
      <SpinnerWrapper>
        <SpinnerLayout />
      </SpinnerWrapper>
    )
  }

  if (progress === Progress.ERROR) {
    return (
      <>
        <ListTitle>{title}</ListTitle>
        <ErrorLayout />
      </>
    )
  }

  if (relatedProducts.length === 0) {
    return null
  }

  return (
    <>
      <ListTitle id="related-products">{title}</ListTitle>
      <List>
        {relatedProducts.map((relatedProduct, idx) => {
          const url = generateCountryPath(Routes.ProductOrCardItem, {
            producerSlug: relatedProduct.item.producer?.slug,
            categorySlug: relatedProduct.item.category?.slug || '',
            subCategorySlug: relatedProduct.item.subcategory?.slug || '',
            productOrCardSlug: relatedProduct.item.slug,
          })

          const hasImage = relatedProduct.item.images.length > 0
          return (
            <li
              key={idx}
              onClick={() => {
                if (product.is_gift) {
                  track(ProductItemEvents.GiftItemRelatedProductClick, {
                    ...giftToEventParams(product),
                    product_id: relatedProduct.item.id,
                    product_title: relatedProduct.item.title,
                  })
                } else {
                  track(ProductItemEvents.ProductItemRelatedGiftClick, {
                    ...productToEventParams(product),
                    gift_id: relatedProduct.item.id,
                    gift_title: relatedProduct.item.title,
                  })
                }
              }}
            >
              <Link to={url}>
                <Item>
                  {hasImage ? <Image src={relatedProduct.item.images[0]} /> : <ImageStub />}
                  <Inner>
                    <ItemSubcategory>{pick(relatedProduct.item.subcategory?.title_i18n)}</ItemSubcategory>
                    <ItemTitle>{pick(relatedProduct.item.title_i18n)}</ItemTitle>
                    <ItemShortDescription>{pick(relatedProduct.item.description_i18n)}</ItemShortDescription>
                    <ItemConditions>
                      {relatedProduct.item.is_gift
                        ? t('applyingConditionsForGift', {
                            minQty: relatedProduct.tier_min_qty,
                            product: pick(product.title_i18n),
                          })
                        : t('applyingConditions', { minQty: relatedProduct.tier_min_qty })}
                    </ItemConditions>
                  </Inner>
                </Item>
              </Link>
            </li>
          )
        })}
      </List>
      {isShowMoreButtonVisible && (
        <ShowMoreButton intent="secondary" onClick={handleFetchNextPage} progress={showMoreProgress}>
          {t('showMoreButton')}
        </ShowMoreButton>
      )}
    </>
  )
}

export default RelatedProducts

import { LocalizedValue } from '../entities'

export type PromocodeBase = {
  id: string
  company_id: string
  is_stackable: boolean
  code: string
  has_discount: boolean
  is_active: boolean
}

export enum PromocodeType {
  Simple = 'simple',
  WithComment = 'with_comment_and_prompt',
  WithLegalText = 'with_legal_text',
}

export type PromocodeSimple = PromocodeBase & {
  params: {
    type: PromocodeType.Simple
  }
}

export type PromocodeWithComment = PromocodeBase & {
  comment: string | null
  params: {
    type: PromocodeType.WithComment
    prompt_i18n: LocalizedValue | null
    prompt?: string | null
  }
}

export type PromocodeWithLegalText = PromocodeBase & {
  params: {
    type: PromocodeType.WithLegalText
    legal_text_i18n: LocalizedValue | null
    legal_text?: string | null
  }
}

export type Promocode = PromocodeSimple | PromocodeWithComment | PromocodeWithLegalText

export function isPromocodeSimple(promocode: Promocode): promocode is PromocodeSimple {
  return (promocode as PromocodeSimple).params.type === 'simple'
}

export function isPromocodeWithComment(promocode: Promocode): promocode is PromocodeWithComment {
  return (promocode as PromocodeWithComment).params.type === 'with_comment_and_prompt'
}

export function isPromocodeWithLegalText(promocode: Promocode): promocode is PromocodeWithLegalText {
  return (promocode as PromocodeWithLegalText).params.type === 'with_legal_text'
}

import { ProcoteWizardNode } from '@agro-club/frontend-shared'
import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import {
  AddCartItemParams,
  CalculatedCartResponse,
  CartInfo,
  CartItem,
  CartItemResponse,
  CartState,
  ProducerCartDto,
  PromocodeErrorType,
  UpdateCartItemParams,
} from 'modules/domain/cart/types'
import { Progress } from 'modules/types'
import { arrToDict, convertAllToNumbers } from 'modules/utils/helpers'
import { remove } from 'ramda'
import { Company } from 'types/entities'
import { isPromocodeWithComment, isPromocodeWithLegalText, Promocode } from 'types/entities/promocode'
import { Product } from '../product/types'

type CartItemAddProducerParams = Pick<Company, 'id' | 'slug' | 'official_name'>

type CartItemAddParams = Pick<AddCartItemParams, 'options' | 'wizard_comment' | 'wizard_data'> & {
  qty: number
  packaging: number | null
  seedTreatment?: AddCartItemParams['seed_treatment_id']
}

const createFakeItemInfo = (producer: CartItemAddProducerParams) => ({
  slug: producer.slug,
  title: producer.official_name,
  discounts: [],
  promocodes: [],
  total: 0,
  subtotal: 0,
  promo_savings: 0,
  total_savings: 0,
  creditOfferAccepted: false,
  is_fake: true,
})

const initialState: CartState = {
  addProgress: Progress.IDLE,
  addItemsProgress: Progress.IDLE,
  updateProgress: Progress.IDLE,
  removeProgress: Progress.IDLE,
  clearProgress: Progress.IDLE,
  initProgress: Progress.IDLE,
  refreshProgress: Progress.IDLE,
  initRetryAttempts: 0,

  entries: {},
  pendingEntries: {},
  cartInfo: {},

  discountsInitProgress: Progress.IDLE,
  termsAgreed: false,

  checkPromocodeProgress: Progress.IDLE,
  lastPromocodeStatus: 'empty',

  productWizardData: null,
  productWizardProgress: Progress.IDLE,
}

// TODO fix cart items
const mapCartItem = (item: CartItemResponse): CartItem => ({
  id: item.id,
  qty: parseInt(item.quantity),
  product: item.product,
  discount_amount: item.discount_amount,
  cost: item.cost,
  packaging: item.packaging,
  product_id: item.product_id,
  producer_id: item.product.producer_id,
  title: item.product.title_i18n, // item.product.title,
  description: item.product.description_i18n, // item.product.description,
  default_packaging: item.product.default_packaging_i18n,
  alt_packaging: item.product.alt_packaging_i18n,
  seed_treatment_id: item.seed_treatment_id,
  subcategory_id: item.product.subcategory_id,
  images: item.product.images,
  options: item.options,
  wizard_comment: item.wizard_comment,
  wizard_data: item.wizard_data,
})
class CartReducer extends ImmerReducer<CartState> {
  cartInitRequested() {
    this.draftState.initProgress = Progress.WORK
  }
  cartInitRequestSucceed(cart: CalculatedCartResponse) {
    this.draftState.initProgress = Progress.SUCCESS

    cart.producers.forEach(item => {
      if (this.draftState.cartInfo[item.slug]) {
        this.draftState.cartInfo[item.slug] = {
          ...this.draftState.cartInfo[item.slug],
          ...item,
          slug: item.slug,
          title: item.official_name,
          ...convertAllToNumbers({
            total: item.total,
            total_savings: item.total_savings,
            subtotal: item.subtotal,
            promo_savings: item.promo_savings,
          }),
          is_fake: false,
        }
      } else {
        this.draftState.cartInfo[item.slug] = {
          ...item,
          promocodes: [],
          creditOfferAccepted: false,
          slug: item.slug,
          title: item.official_name,
          ...convertAllToNumbers({
            total: item.total,
            total_savings: item.total_savings,
            subtotal: item.subtotal,
            promo_savings: item.promo_savings,
          }),
        }
      }
    })

    const items = cart.producers.reduce(
      (acc: CartItem[], item: ProducerCartDto) => [...acc, ...item.entries.map(mapCartItem)],
      [],
    )
    this.draftState.entries = arrToDict(items)
  }
  cartInitRequestFailed() {
    this.draftState.initProgress = Progress.ERROR
    this.draftState.initRetryAttempts += 1
  }

  refreshCartRequested() {
    this.draftState.refreshProgress = Progress.WORK
  }
  refreshCartRequestSucceed(cart: CalculatedCartResponse) {
    this.draftState.refreshProgress = Progress.SUCCESS
    // This is to prevent race condition
    // This function executes on every cart change,
    // so after all events done it will properly update the cart
    if (this.state.addProgress !== Progress.WORK && this.state.removeProgress !== Progress.WORK) {
      if (cart.producers.length === 0) {
        this.draftState.cartInfo = {}
      }

      cart.producers.forEach(item => {
        if (this.draftState.cartInfo[item.slug]) {
          this.draftState.cartInfo[item.slug] = {
            ...this.draftState.cartInfo[item.slug],
            ...item,
            slug: item.slug,
            title: item.official_name,
            ...convertAllToNumbers({
              total: item.total,
              total_savings: item.total_savings,
              subtotal: item.subtotal,
              promo_savings: item.promo_savings,
            }),
            is_fake: false,
          }
        } else {
          this.draftState.cartInfo[item.slug] = {
            ...item,
            slug: item.slug,
            title: item.official_name,
            ...convertAllToNumbers({
              total: item.total,
              total_savings: item.total_savings,
              subtotal: item.subtotal,
              promo_savings: item.promo_savings,
            }),
            promocodes: [],
            creditOfferAccepted: false,
          }
        }
      })

      const items = cart.producers.reduce(
        (acc: CartItem[], item: ProducerCartDto) => [...acc, ...item.entries.map(mapCartItem)],
        [],
      )
      this.draftState.entries = arrToDict(items)
    }
  }
  refreshCartRequestFailed() {
    this.draftState.refreshProgress = Progress.ERROR
  }

  fakeItemAddRequested(_product: Product, _options: CartItemAddParams, _fakeId: string) {
    this.draftState.addProgress = Progress.WORK
  }

  fakeItemAdd(product: Product, options: CartItemAddParams, fakeId: string) {
    this.draftState.entries[fakeId] = {
      ...options,
      seed_treatment_id: options.seedTreatment,
      product_id: product.id,
      producer_id: product.producer_id,
      id: fakeId,
      product: product,
      images: product.images,
      title: product.title_i18n,
      description: product.description_i18n,
      default_packaging: product.default_packaging_i18n,
      alt_packaging: product.alt_packaging_i18n,
      is_fake: true,
    }

    const producer = product.producer

    if (producer && !this.draftState.cartInfo[producer.slug])
      this.draftState.cartInfo[producer.slug] = createFakeItemInfo(producer)
  }

  itemAddRequested(
    _product_id: string,
    _producer: CartItemAddProducerParams,
    _options: CartItemAddParams,
    _pendingId: string,
  ) {
    this.draftState.addProgress = Progress.WORK
  }

  itemAdd(product_id: string, producer: CartItemAddProducerParams, pendingId: string) {
    this.draftState.pendingEntries[pendingId] = {
      id: pendingId,
      product_id,
      producer_id: producer.id,
      producer_slug: producer.slug,
    }

    if (!this.draftState.cartInfo[producer.slug]) this.draftState.cartInfo[producer.slug] = createFakeItemInfo(producer)
  }

  itemAddSucceed(item: CartItem, pendingId: string) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.entries[item.id] = item
    delete this.draftState.pendingEntries[pendingId]
  }

  fakeItemAddSucceed() {
    this.draftState.addProgress = Progress.SUCCESS
  }

  itemAddFailed(pendingId: string) {
    this.draftState.addProgress = Progress.ERROR

    delete this.draftState.pendingEntries[pendingId]
  }

  itemsAddRequested(_options, _producerSlug: string) {
    this.draftState.addItemsProgress = Progress.WORK
  }
  itemsAddSucceed() {
    this.draftState.addItemsProgress = Progress.SUCCESS
  }
  itemsAddFailed() {
    this.draftState.addItemsProgress = Progress.ERROR
  }

  itemUpdateRequested(_id: string, _params: UpdateCartItemParams) {
    this.draftState.updateProgress = Progress.WORK
  }
  itemUpdateSucceed(_item: CartItemResponse) {
    this.draftState.updateProgress = Progress.SUCCESS
  }
  itemUpdateFailed(id: string, params: UpdateCartItemParams) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.entries[id] = { ...this.draftState.entries[id], ...params }
  }

  itemUpdated(id: string, params: UpdateCartItemParams) {
    this.draftState.entries[id] = { ...this.draftState.entries[id], ...params }
  }

  itemRemoveRequested(_id: string) {
    this.draftState.removeProgress = Progress.WORK
  }
  itemRemoveSucceed() {
    this.draftState.removeProgress = Progress.SUCCESS
  }
  itemRemoveFailed(removedItem: CartItem) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.entries[removedItem.id] = removedItem
  }

  itemRemove(id: string) {
    delete this.draftState.entries[id]
  }

  cartInfoRemove(producerSlug: string) {
    delete this.draftState.cartInfo[producerSlug]
  }

  cartInfoRemoveFailed(cartInfo: CartInfo) {
    this.draftState.cartInfo[cartInfo.slug] = cartInfo
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  clearRequested(_producerId: string) {
    this.draftState.clearProgress = Progress.WORK
  }
  clearRequestSucceed() {
    this.draftState.clearProgress = Progress.SUCCESS
    this.draftState.entries = {}
    this.draftState.termsAgreed = false
  }

  clearRequestFailed() {
    this.draftState.clearProgress = Progress.ERROR
  }

  setCreditOfferAccepted(producerSlug: string, accepted: boolean) {
    this.draftState.cartInfo[producerSlug].creditOfferAccepted = accepted
  }

  setTermsAgreed(agreed: boolean) {
    this.draftState.termsAgreed = agreed
  }

  updatePromocodeComment(producerSlug: string, code: string, text: string) {
    if (!this.draftState.cartInfo[producerSlug]) return
    const i = this.draftState.cartInfo[producerSlug].promocodes.findIndex(item => item.code === code)
    const promocode = this.draftState.cartInfo[producerSlug].promocodes[i]
    if (-1 !== i && promocode && isPromocodeWithComment(promocode)) {
      promocode.comment = text
    }
  }
  removePromocode(producerSlug: string, code: string) {
    const i = this.draftState.cartInfo[producerSlug].promocodes.findIndex(item => item.code === code)
    if (-1 !== i) {
      this.draftState.cartInfo[producerSlug].promocodes = remove(
        i,
        1,
        this.draftState.cartInfo[producerSlug].promocodes,
      )
    }
    if (code === this.draftState.selectedPromocode) {
      this.draftState.selectedPromocode = this.draftState.cartInfo[producerSlug].promocodes.find(item => {
        if (isPromocodeWithComment(item)) {
          return !!item.params.prompt
        }
        if (isPromocodeWithLegalText(item)) {
          return !!item.params.legal_text
        }
      })?.code
    }
  }

  checkPromocode(_params: { code: string; company_id: string; producerSlug: string }) {
    this.draftState.checkPromocodeProgress = Progress.WORK
    this.draftState.lastPromocodeStatus = 'empty'
  }

  checkPromocodeSuccess(producerSlug: string, code: string, promocode: Promocode) {
    this.draftState.checkPromocodeProgress = Progress.SUCCESS

    this.draftState.cartInfo[producerSlug].promocodes.push(promocode)
    this.draftState.lastPromocodeStatus = 'valid'
  }

  selectEditablePromocode(producerSlug: string, code: string) {
    const item = this.draftState.cartInfo[producerSlug].promocodes.find(
      item =>
        item.code === code &&
        ((isPromocodeWithComment(item) && item.params.prompt) ||
          (isPromocodeWithLegalText(item) && item.params.legal_text)),
    )
    if (item) {
      this.draftState.selectedPromocode = code
    }
  }

  checkPromocodeError(errType: PromocodeErrorType) {
    this.draftState.checkPromocodeProgress = Progress.ERROR
    this.draftState.lastPromocodeStatus =
      errType === 'promocode_not_stackable'
        ? 'discount_used'
        : errType === 'product_missing'
        ? 'product_missing'
        : 'invalid'
  }

  resetPromocode(producerSlug: string) {
    this.draftState.checkPromocodeProgress = Progress.IDLE
    this.draftState.lastPromocodeStatus = 'empty'
    this.draftState.cartInfo[producerSlug].promocodes = []
    this.draftState.selectedPromocode = undefined
  }

  productWizardRequested(
    _id: string,
    _params: {
      sort_field: string
    },
  ) {
    this.draftState.productWizardProgress = Progress.WORK
  }
  productWizardSucceed(data: ProcoteWizardNode[]) {
    this.draftState.productWizardProgress = Progress.SUCCESS
    this.draftState.productWizardData = data
  }
  productWizardFailed() {
    this.draftState.productWizardProgress = Progress.ERROR
  }

  setFarmerComment(producerSlug: string, comment: string) {
    this.draftState.cartInfo[producerSlug].farmerComment = comment
  }
}

export const CartActions = createActionCreators(CartReducer)
export default CartActions
export const reducer = createReducerFunction(CartReducer, initialState)

import { endpoints } from 'modules/endpoints'
import { OrderDTO, Order } from 'types/order'
import { ApiService } from 'service/api/interface'

export const orderCreate = (apiClient: ApiService) => (params: OrderDTO) => {
  return apiClient.post<Order>(endpoints.orders(), params)
}

export const preOrderCreate = (apiClient: ApiService) => (params: OrderDTO) => {
  return apiClient.post<Order>(endpoints.orders(), params)
}

export const skuOrderCreate = (apiClient: ApiService) => (params: OrderDTO) => {
  return apiClient.post<OrderDTO>(endpoints.skuOrders(), params)
}

import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Button,
  Switch,
  Checkbox,
  IconPencil,
  SectionTitle,
  SectionContainer,
  useAction,
  SectionBody,
  useFormManager,
} from '@agro-club/frontend-shared'
import * as Styled from 'views/pages/Profile/styled'
import { useFormik } from 'formik'
import AddressForm from 'views/components/AddressForm/AddressForm'
import { useTranslation } from 'react-i18next'
import IconButton from 'views/ui/IconButton/IconButton'
import useHelmet from 'hooks/useHelmet'
import { useSelector } from 'react-redux'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import { Progress } from 'modules/types'
import { equals } from 'ramda'
import { ProfileAddress } from 'types/entities/userProfile'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import { ProfileEvents } from 'views/pages/Profile/events'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { theme } from 'theme/theme'

const FormTitle = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  margin: 0;

  color: ${({ theme }) => theme.color.onPrimaryDark};

  ${props => props.theme.media.mobile`
    display: none;
  `}
`

const SelfPickupSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`

const SwitchLabel = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  margin-right: 16px;

  color: ${({ theme }) => theme.color.onPrimaryDark};
`

type DeliveryPointData = {
  preferSelfPickUp?: boolean
  sameAsMailingAddress: boolean
}

type FormikManagerData = {
  deliveryPoint: DeliveryPointData
  main: ProfileAddress
}

const EditButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation('common')
  return (
    <IconButton
      onClick={onClick}
      Icon={IconPencil}
      iconColor={theme.color.accentApproving100}
      className="primaryAction"
    >
      {t('edit')}
    </IconButton>
  )
}

const ButtonsWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: max-content max-content;
`

const DeliveryPoint: React.FC = () => {
  const { t } = useTranslation('profile')
  const updateProfile = useAction(AuthActions.userUpdateRequested)
  const resetUserUpdateProgress = useAction(AuthActions.resetUserUpdateProgress)
  const { track } = useAnalyticsSSR()

  const [editable, setEditable] = useState(false)
  const formManager = useFormManager<FormikManagerData>()

  const profile = useSelector(AuthSelectors.profile)
  const profileUpdateProgress = useSelector(AuthSelectors.profileUpdateProgress)
  const defaultCountry = useDetectedCountry()

  const mailingAddress = profile?.legal_address || { country: defaultCountry }
  const mainAddress = profile?.delivery_address || { country: defaultCountry }

  const formik = useFormik<DeliveryPointData>({
    initialValues: {
      preferSelfPickUp: profile?.self_pickup || false,
      sameAsMailingAddress: equals(mailingAddress, mainAddress),
    },
    // eslint-disable-next-line no-console
    onSubmit: () => console.log('Submitted'),
  })

  formManager.register('deliveryPoint', formik)

  useHelmet({ title: t('deliveryPoint.metaTitle') })

  useEffect(() => {
    if (profileUpdateProgress === Progress.SUCCESS) {
      setEditable(false)
      resetUserUpdateProgress()
    }
  }, [profileUpdateProgress, resetUserUpdateProgress])

  const handleFormSubmit = useCallback(async () => {
    const [valid, forms] = await formManager.submitAll()
    if (!valid) {
      return
    }
    track(ProfileEvents.DeliveryPointEditSaveTap, { pickUp: forms.deliveryPoint.preferSelfPickUp })
    updateProfile({
      delivery_address: forms.main,
      self_pickup: forms.deliveryPoint.preferSelfPickUp,
    })
  }, [formManager, track, updateProfile])

  const handleCancelEditing = useCallback(async () => {
    const [, forms] = await formManager.submitAll()
    track(ProfileEvents.DeliveryPointEditCancelTap, { pickUp: forms.deliveryPoint.preferSelfPickUp })
    setEditable(false)
    formManager.reset()
  }, [formManager, track])

  const handleSaveClick = useCallback(() => {
    handleFormSubmit()
  }, [handleFormSubmit])

  const formIsPristine = !formik.dirty && !formManager.dirty
  const formIsValid = formManager.valid && formik.isValid

  const saveEnabled = !formIsPristine && formIsValid

  return (
    <SectionContainer noDivider>
      <Styled.HiddenOnMediaBlock hideMobile={true}>
        <SectionTitle>
          <FormTitle>{t('deliveryPoint.title')}</FormTitle>
          {!editable && (
            <EditButton
              onClick={() => {
                track(ProfileEvents.DeliveryPointEditTap)
                setEditable(true)
              }}
            />
          )}
        </SectionTitle>
      </Styled.HiddenOnMediaBlock>
      <SectionBody paddedOnMobile>
        <Styled.FormContent>
          <SelfPickupSwitchContainer>
            <SwitchLabel style={!editable ? { opacity: 0.5 } : { opacity: 1 }}>
              {t('deliveryPoint.preferSelfPickUp')}
            </SwitchLabel>
            <Switch
              name={'self-pickup-switch'}
              on={!!formik.values.preferSelfPickUp}
              onClick={() => formik.setFieldValue('preferSelfPickUp', !formik.values.preferSelfPickUp)}
              disabled={!editable}
            />
          </SelfPickupSwitchContainer>
          <Styled.FormSection>
            <Styled.FormRow>
              <Checkbox
                label={t('deliveryPoint.sameAsMailing')}
                hint={t('deliveryPoint.sameAsMailingHint')}
                isChecked={formik.values.sameAsMailingAddress}
                onChange={() => formik.setFieldValue('sameAsMailingAddress', !formik.values.sameAsMailingAddress)}
                disabled={!editable}
              />
            </Styled.FormRow>
            <AddressForm
              initialValues={formik.values.sameAsMailingAddress ? mailingAddress : mainAddress}
              editable={editable && !formik.values.sameAsMailingAddress}
              useFormik={formManager.bind('main')}
            />
          </Styled.FormSection>
        </Styled.FormContent>

        <Styled.HiddenOnMediaBlock hideTablet={true} hideDesktop={true}>
          {!editable && (
            <ButtonsWrapper style={{ marginTop: '24px', justifyContent: 'flex-end' }}>
              <EditButton onClick={() => setEditable(true)} />
            </ButtonsWrapper>
          )}
        </Styled.HiddenOnMediaBlock>
        {editable && (
          <ButtonsWrapper style={{ marginTop: '40px' }}>
            <Button
              intent={'primary-action'}
              size={'big'}
              filled
              onClick={handleSaveClick}
              disabled={!saveEnabled}
              progress={profileUpdateProgress}
            >
              {t('common:saveChanges')}
            </Button>
            <Button intent={'cancel'} size={'big'} onClick={handleCancelEditing}>
              {t('common:cancel')}
            </Button>
          </ButtonsWrapper>
        )}
      </SectionBody>
    </SectionContainer>
  )
}

export default DeliveryPoint

import { AxiosError } from 'axios'
import i18n from 'i18n'
import { PromocodeErrorType } from 'modules/domain/cart/types'

export type NetworkErrorType =
  | 'bad_request'
  | 'unauthorized_error'
  | 'not_found'
  | 'validation_error'
  | 'server_error'
  | PromocodeErrorType

export type CustomErrorType = 'email_exists' | 'phone_exists'

export type ErrorDetail = {
  type: NetworkErrorType | CustomErrorType | 'unknown'
  detail?: string
  errors?: Record<string, string>
  httpCode?: number
}

export abstract class RequestError extends Error {
  static parseError(error: unknown) {
    const defaultMessage = window?.navigator?.onLine
      ? i18n.t('common:errors.unknownErrorTitle')
      : i18n.t('common:errors.noConnection')
    if (error instanceof RequestError) {
      return {
        type: error.type,
        detail: error.detail || defaultMessage,
        errors: error.errors,
      } as const
    }
    return {
      type: 'unknown',
      detail: defaultMessage,
    } as const
  }
  method?: string
  url?: string
  code?: number
  params?: any

  detail?: string
  errors: Record<string, string> = {}

  public abstract readonly type: NetworkErrorType
  protected constructor(e: AxiosError) {
    super(e.name)

    const errors = e.response?.data?.errors
    if (errors) {
      this.errors = errors
    }
    const detail = e.response?.data?.detail
    this.detail = typeof detail === 'string' ? detail : e.response?.data?.message || 'unknown error'

    this.url = e.config.url
    this.code = e.response?.status
    this.method = e.config.method
    this.params = e.config.params
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class BadRequestError extends RequestError {
  readonly type = 'bad_request'
  constructor(e: AxiosError) {
    super(e)
    // TODO Fix it after error convention will be satisfied
    if (e.response?.data.error && e.response?.data.error !== this.type) {
      this.type = e.response?.data.error
    }

    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class UnauthorizedError extends RequestError {
  readonly type = 'unauthorized_error'
  constructor(e: AxiosError) {
    super(e)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class NotFoundError extends RequestError {
  readonly type = 'not_found'
  constructor(e: AxiosError) {
    super(e)
    this.message = e.response?.data.detail
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class ValidationError extends RequestError {
  readonly type = 'validation_error'
  data = []

  constructor(e: AxiosError) {
    super(e)
    const detail = e.response?.data.detail
    if (detail && detail.length) {
      this.data = detail.map((item: { type: string; msg: string }) => ({ type: item.type, message: item.msg }))
    }
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class ServerError extends RequestError {
  readonly type = 'server_error'
  constructor(e: AxiosError) {
    super(e)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

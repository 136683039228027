import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import OrderActions from './duck'
import OrderSelectors from './selectors'
import { Order, OrderStatus } from 'types/order'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { generateCountryPath, updateLocationQuery } from 'modules/sagaHelpers'
import Routes from 'views/pages/Profile/routes'
import { apiCall } from 'modules/sagaEffects'
import { push, replace } from 'connected-react-router'

export const fetchList = function*() {
  try {
    let currentPage = yield select(OrderSelectors.page)
    const filter = yield select(OrderSelectors.filter)
    const sorting = yield select(OrderSelectors.sorting)
    const pageSize = yield select(OrderSelectors.pageSize)

    let response: ListResponse<Order> = yield call(apiCall, managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(apiCall, managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(OrderActions.listRequestSucceed(data, total_count, page))

    yield call(updateLocationQuery, Routes.Orders, { page: currentPage })
  } catch (err) {
    yield put(OrderActions.listRequestFailed())
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(OrderSelectors.page)
    const filter = yield select(OrderSelectors.filter)
    const sorting = yield select(OrderSelectors.sorting)
    const pageSize = yield select(OrderSelectors.pageSize)
    const { data, total_count }: { data: Order[]; total_count: number } = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(OrderActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    yield put(OrderActions.listRequestNextFailed())
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof OrderActions.itemRequested>) {
  try {
    const order: Order = yield call(apiCall, managers.getItem, id)
    yield put(OrderActions.itemRequestSucceed(order))
  } catch (err) {
    yield put(OrderActions.itemRequestFailed())
  }
}

export const addItem = function*({ payload: dto }: ReturnType<typeof OrderActions.addRequested>) {
  try {
    const order: Order = yield call(apiCall, managers.addItem, dto)
    yield put(OrderActions.addSucceed(order))
  } catch (err) {
    yield put(OrderActions.addFailed())
  }
}

export const updateItem = function*({
  payload: [id, dto, redirectPath = ''],
}: ReturnType<typeof OrderActions.updateRequested>) {
  try {
    const order: Order = yield call(apiCall, managers.updateItem, id, dto)
    yield put(OrderActions.updateSucceed(order))

    if (redirectPath) {
      yield put(push(redirectPath))
    }
  } catch (err) {
    yield put(OrderActions.updateFailed())
  }
}

export const updateSkuItem = function*({
  payload: [id, dto, redirectPath = ''],
}: ReturnType<typeof OrderActions.updateSkuRequested>) {
  try {
    const order: Order = yield call(apiCall, managers.updateSkuItem, id, dto)
    yield put(OrderActions.updateSucceed(order))

    if (redirectPath) {
      yield put(push(redirectPath))
    }
  } catch (err) {
    yield put(OrderActions.updateFailed())
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof OrderActions.removeRequested>) {
  try {
    yield call(apiCall, managers.removeItem, payload)
    yield put(OrderActions.removeSucceed(payload))
  } catch (err) {
    yield put(OrderActions.removeFailed())
  }
}

export const cancelOrder = function*({ payload }: ReturnType<typeof OrderActions.cancelOrderRequested>) {
  try {
    yield call(apiCall, managers.cancelOrder, payload.id, {
      ...payload,
      interaction: {
        ...payload.interaction,
        canceled_by_role: 'user',
      },
      status: OrderStatus.Canceled,
    })
    yield put(OrderActions.cancelOrderSucceed(payload.id))
    const path = yield call(generateCountryPath, Routes.Orders)
    yield put(replace(path))
  } catch (err) {
    yield put(OrderActions.cancelOrderFailed())
  }
}

export const cancelSkuOrder = function*({ payload }: ReturnType<typeof OrderActions.cancelSkuOrderRequested>) {
  try {
    yield call(apiCall, managers.cancelSkuOrder, payload.id, {
      ...payload,
      interaction: {
        ...payload.interaction,
        canceled_by_role: 'user',
      },
      status: OrderStatus.Canceled,
    })
    yield put(OrderActions.cancelOrderSucceed(payload.id))
    const path = yield call(generateCountryPath, Routes.Orders)
    yield put(replace(path))
  } catch (err) {
    yield put(OrderActions.cancelOrderFailed())
  }
}

const OrderSaga = function*() {
  yield all([
    takeLatest(OrderActions.itemRequested.type, fetchItem),
    takeLatest(OrderActions.listRequested.type, fetchList),
    takeLatest(OrderActions.filterUpdated.type, fetchList),
    takeLatest(OrderActions.sortingUpdated.type, fetchList),
    takeLatest(OrderActions.filterHasBeenReset.type, fetchList),
    takeLatest(OrderActions.sortingHasBeenReset.type, fetchList),

    takeLatest(OrderActions.listRequestedNext.type, fetchListNext),

    takeLatest(OrderActions.addRequested.type, addItem),
    takeLatest(OrderActions.updateRequested.type, updateItem),
    takeLatest(OrderActions.updateSkuRequested.type, updateSkuItem),
    takeLatest(OrderActions.removeRequested.type, removeItem),
    takeLatest(OrderActions.cancelOrderRequested.type, cancelOrder),
    takeLatest(OrderActions.cancelSkuOrderRequested.type, cancelSkuOrder),
  ])
}

export default OrderSaga

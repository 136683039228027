import { ProcoteWizardNode } from '@agro-club/frontend-shared'
import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { PromocodeErrorType } from 'modules/domain/cart/types'
import {
  AddCartSkuItemParams,
  CartSkuInfo,
  CartSkuItem,
  CartSkuItemResponse,
  CartSkuState,
  SellersCalculatedCart,
  UpdateCartSkuItemParams,
} from 'modules/domain/cartSku/types'
import { Progress } from 'modules/types'
import { arrToDict, convertAllToNumbers } from 'modules/utils/helpers'
import { remove } from 'ramda'
import { Company } from 'types/entities'
import { isPromocodeWithComment, isPromocodeWithLegalText, Promocode } from 'types/entities/promocode'

type CartSkuItemAddSellerParams = Pick<Company, 'id' | 'slug' | 'official_name'>

type CartSkuItemAddParams = Pick<AddCartSkuItemParams, 'card' | 'sku' | 'seller' | 'wizard_comment' | 'wizard_data'> & {
  quantity: string
}

const createFakeItemInfo = (seller: CartSkuItemAddSellerParams, entries: CartSkuItem[]): CartSkuInfo => ({
  slug: seller.slug,
  title: seller.official_name,
  discounts: [],
  promocodes: [],
  total: 0,
  subtotal: 0,
  promo_savings: 0,
  total_savings: 0,
  creditOfferAccepted: false,
  is_fake: true,
  entries,
})

const initialState: CartSkuState = {
  addProgress: Progress.IDLE,
  addErrorDetails: null,
  addItemsProgress: Progress.IDLE,
  addItemsErrorDetails: null,
  updateProgress: Progress.IDLE,
  updateErrorDetails: null,
  removeProgress: Progress.IDLE,
  removeErrorDetails: null,
  clearProgress: Progress.IDLE,
  clearErrorDetails: null,
  initProgress: Progress.IDLE,
  initErrorDetails: null,
  refreshProgress: Progress.IDLE,
  refreshErrorDetails: null,
  initRetryAttempts: 0,

  entries: {},
  pendingEntries: {},
  cartInfo: {},

  discountsInitProgress: Progress.IDLE,
  discountsInitErrorDetails: null,
  termsAgreed: false,

  checkPromocodeProgress: Progress.IDLE,
  checkPromocodeErrorDetails: null,
  lastPromocodeStatus: 'empty',

  productWizardData: null,
  productWizardProgress: Progress.IDLE,
  productWizardErrorDetails: null,
}

const mapCartSkuItem = (item: CartSkuItemResponse): CartSkuItem => ({
  id: item.id,
  quantity: item.quantity,
  sku: item.sku,
  sku_id: item.sku_id,
  seller_id: item.seller_id,
  card: item.card,
  discount_amount: item.discount_amount,
  cost: item.cost,
  title: item.card.title_i18n,
  description: item.card.description_i18n,
  images: item.card.images,
  wizard_comment: item.wizard_comment,
  wizard_data: item.wizard_data,
  default_packaging: {},
  added_from_crm: item.added_from_crm,
  added_from_crm_options: item.added_from_crm_options,
})

class CartSkuReducer extends ImmerReducer<CartSkuState> {
  cartInitRequested() {
    this.draftState.initProgress = Progress.WORK
  }
  cartInitRequestSucceed(cart: SellersCalculatedCart) {
    this.draftState.initProgress = Progress.SUCCESS

    cart.sellers.forEach(item => {
      if (this.draftState.cartInfo[item.slug]) {
        this.draftState.cartInfo[item.slug] = {
          ...this.draftState.cartInfo[item.slug],
          ...item,
          entries: [...item.entries.map(mapCartSkuItem)],
          slug: item.slug,
          title: item.official_name,
          ...convertAllToNumbers({
            total: item.total,
            total_savings: item.total_savings,
            subtotal: item.subtotal,
            promo_savings: item.promo_savings,
          }),
          is_fake: false,
        }
      } else {
        this.draftState.cartInfo[item.slug] = {
          ...item,
          entries: [...item.entries.map(mapCartSkuItem)],
          promocodes: [],
          creditOfferAccepted: false,
          slug: item.slug,
          title: item.official_name,
          ...convertAllToNumbers({
            total: item.total,
            total_savings: item.total_savings,
            subtotal: item.subtotal,
            promo_savings: item.promo_savings,
          }),
        }
      }
    })

    const items = cart.sellers.reduce((acc: CartSkuItem[], item) => [...acc, ...item.entries.map(mapCartSkuItem)], [])
    this.draftState.entries = arrToDict(items)
  }
  cartInitRequestFailed(errorDetails: string) {
    this.draftState.initProgress = Progress.ERROR
    this.draftState.initRetryAttempts += 1
    this.draftState.initErrorDetails = errorDetails
  }

  refreshCartRequested() {
    this.draftState.refreshProgress = Progress.WORK
  }
  refreshCartRequestSucceed(cart: SellersCalculatedCart) {
    this.draftState.refreshProgress = Progress.SUCCESS
    // This is to prevent race condition
    // This function executes on every cart change,
    // so after all events done it will properly update the cart
    if (this.state.addProgress !== Progress.WORK && this.state.removeProgress !== Progress.WORK) {
      if (cart.sellers.length === 0) {
        this.draftState.cartInfo = {}
      }

      cart.sellers.forEach(item => {
        if (this.draftState.cartInfo[item.slug]) {
          this.draftState.cartInfo[item.slug] = {
            ...this.draftState.cartInfo[item.slug],
            ...item,
            entries: [...item.entries.map(mapCartSkuItem)],
            slug: item.slug,
            title: item.official_name,
            ...convertAllToNumbers({
              total: item.total,
              total_savings: item.total_savings,
              subtotal: item.subtotal,
              promo_savings: item.promo_savings,
            }),
            is_fake: false,
          }
        } else {
          this.draftState.cartInfo[item.slug] = {
            ...item,
            entries: [...item.entries.map(mapCartSkuItem)],
            slug: item.slug,
            title: item.official_name,
            ...convertAllToNumbers({
              total: item.total,
              total_savings: item.total_savings,
              subtotal: item.subtotal,
              promo_savings: item.promo_savings,
            }),
            promocodes: [],
            creditOfferAccepted: false,
          }
        }
      })

      const items = cart.sellers.reduce((acc: CartSkuItem[], item) => [...acc, ...item.entries.map(mapCartSkuItem)], [])
      this.draftState.entries = arrToDict(items)
    }
  }
  refreshCartRequestFailed(errorDetails: string) {
    this.draftState.refreshProgress = Progress.ERROR
    this.draftState.refreshErrorDetails = errorDetails
  }

  itemAddRequested(_options: CartSkuItemAddParams) {
    this.draftState.addProgress = Progress.WORK
  }

  itemAdd(options: CartSkuItemAddParams, fakeId: string) {
    const entry = {
      ...options,
      id: fakeId,
      sku: options.sku,
      sku_id: options.sku.id,
      seller_id: options.card.seller_id,
      seller: options.seller as Company,
      card: options.card,
      images: options.card.images,
      title: options.card.title_i18n,
      description: options.card.description_i18n,
      is_fake: true,
      default_packaging: {},
    }
    this.draftState.entries[fakeId] = entry

    const seller = options.seller

    if (seller) {
      if (!this.draftState.cartInfo[seller.slug])
        this.draftState.cartInfo[seller.slug] = createFakeItemInfo(seller, [entry])
      else this.draftState.cartInfo[seller.slug].entries?.push(entry)
    }
  }

  itemAddSucceed() {
    this.draftState.addProgress = Progress.SUCCESS
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  itemAddFailed(fakeId: string, errorDetails: string, sellerSlug?: string) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.refreshErrorDetails = errorDetails

    if (sellerSlug) {
      if (!this.draftState.cartInfo[sellerSlug]) delete this.draftState.cartInfo[sellerSlug][fakeId]
      else {
        const index = this.draftState.cartInfo[sellerSlug].entries?.findIndex((item: CartSkuItem) => item.id === fakeId)
        if (index !== undefined && index !== -1) this.draftState.cartInfo[sellerSlug].entries?.splice(index, 1)
      }
    }
    delete this.draftState.pendingEntries[fakeId]
    delete this.draftState.entries[fakeId]
  }

  itemsAddRequested(_options, _producerSlug: string) {
    this.draftState.addItemsProgress = Progress.WORK
  }
  itemsAddSucceed() {
    this.draftState.addItemsProgress = Progress.SUCCESS
  }
  itemsAddFailed(errorDetails: string) {
    this.draftState.addItemsProgress = Progress.ERROR
    this.draftState.addItemsErrorDetails = errorDetails
  }

  itemUpdateRequested(_id: string, _params: UpdateCartSkuItemParams) {
    this.draftState.updateProgress = Progress.WORK
  }
  itemUpdateSucceed(_item: CartSkuItemResponse) {
    this.draftState.updateProgress = Progress.SUCCESS
  }
  itemUpdateFailed(id: string, index: number, slug: string, beforeUpdate: CartSkuItem, errorDetails: string) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.updateErrorDetails = errorDetails
    this.draftState.entries[id] = beforeUpdate
    if (this.draftState.cartInfo[slug].entries && index !== -1)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.draftState.cartInfo[slug].entries![index] = beforeUpdate
  }

  itemUpdated(id: string, index: number, slug: string, params: UpdateCartSkuItemParams) {
    const newEntry = { ...this.draftState.entries[id], ...params }
    if (this.draftState.cartInfo[slug].entries && index !== -1)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.draftState.cartInfo[slug].entries![index] = newEntry
    this.draftState.entries[id] = newEntry
  }

  itemRemoveRequested(_id: string) {
    this.draftState.removeProgress = Progress.WORK
  }
  itemRemoveSucceed() {
    this.draftState.removeProgress = Progress.SUCCESS
  }
  itemRemoveFailed(removedItem: CartSkuItem, errorDetails: string) {
    this.draftState.removeErrorDetails = errorDetails
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.entries[removedItem.id] = removedItem
  }

  itemRemove(id: string, slug: string) {
    const entries = this.draftState.cartInfo[slug].entries?.filter(item => item.id !== id) ?? []

    delete this.draftState.entries[id]
    if (entries.length === 0) {
      delete this.draftState.cartInfo[slug]
    } else {
      this.draftState.cartInfo[slug].entries = entries
    }
  }

  cartInfoRemove(producerSlug: string) {
    delete this.draftState.cartInfo[producerSlug]
  }

  cartInfoRemoveFailed(cartInfo: CartSkuInfo) {
    this.draftState.cartInfo[cartInfo.slug] = cartInfo
  }

  clear(sellerId: string) {
    this.draftState.entries = {}
    this.draftState.termsAgreed = false
    delete this.draftState.cartInfo[sellerId]
  }

  clearRequested(_sellerId: string) {
    this.draftState.clearProgress = Progress.WORK
  }
  clearRequestSucceed() {
    this.draftState.clearProgress = Progress.SUCCESS
  }

  clearRequestFailed(prevState: CartSkuState, errorDetails: string) {
    this.draftState = prevState
    this.draftState.clearProgress = Progress.ERROR
    this.draftState.clearErrorDetails = errorDetails
  }

  setCreditOfferAccepted(producerSlug: string, accepted: boolean) {
    this.draftState.cartInfo[producerSlug].creditOfferAccepted = accepted
  }

  setTermsAgreed(agreed: boolean) {
    this.draftState.termsAgreed = agreed
  }

  updatePromocodeComment(producerSlug: string, code: string, text: string) {
    if (!this.draftState.cartInfo[producerSlug]) return
    const i = this.draftState.cartInfo[producerSlug].promocodes.findIndex(item => item.code === code)
    const promocode = this.draftState.cartInfo[producerSlug].promocodes[i]
    if (-1 !== i && promocode && isPromocodeWithComment(promocode)) {
      promocode.comment = text
    }
  }
  removePromocode(producerSlug: string, code: string) {
    const i = this.draftState.cartInfo[producerSlug].promocodes.findIndex(item => item.code === code)
    if (-1 !== i) {
      this.draftState.cartInfo[producerSlug].promocodes = remove(
        i,
        1,
        this.draftState.cartInfo[producerSlug].promocodes,
      )
    }
    if (code === this.draftState.selectedPromocode) {
      this.draftState.selectedPromocode = this.draftState.cartInfo[producerSlug].promocodes.find(item => {
        if (isPromocodeWithComment(item)) {
          return !!item.params.prompt
        }
        if (isPromocodeWithLegalText(item)) {
          return !!item.params.legal_text
        }
      })?.code
    }
  }

  checkPromocode(_params: { code: string; company_id: string; producerSlug: string }) {
    this.draftState.checkPromocodeProgress = Progress.WORK
    this.draftState.lastPromocodeStatus = 'empty'
  }

  checkPromocodeSuccess(producerSlug: string, code: string, promocode: Promocode) {
    this.draftState.checkPromocodeProgress = Progress.SUCCESS

    this.draftState.cartInfo[producerSlug].promocodes.push(promocode)
    this.draftState.lastPromocodeStatus = 'valid'
  }

  selectEditablePromocode(producerSlug: string, code: string) {
    const item = this.draftState.cartInfo[producerSlug].promocodes.find(
      item =>
        item.code === code &&
        ((isPromocodeWithComment(item) && item.params.prompt) ||
          (isPromocodeWithLegalText(item) && item.params.legal_text)),
    )
    if (item) {
      this.draftState.selectedPromocode = code
    }
  }

  checkPromocodeError(errType: PromocodeErrorType, errorDetails: string) {
    this.draftState.checkPromocodeProgress = Progress.ERROR
    this.draftState.checkPromocodeErrorDetails = errorDetails
    this.draftState.lastPromocodeStatus =
      errType === 'promocode_not_stackable'
        ? 'discount_used'
        : errType === 'product_missing'
        ? 'product_missing'
        : 'invalid'
  }

  resetPromocode(producerSlug: string) {
    this.draftState.checkPromocodeProgress = Progress.IDLE
    this.draftState.lastPromocodeStatus = 'empty'
    this.draftState.cartInfo[producerSlug].promocodes = []
    this.draftState.selectedPromocode = undefined
  }

  productWizardRequested(
    _id: string,
    _params: {
      sort_field: string
    },
  ) {
    this.draftState.productWizardProgress = Progress.WORK
  }
  productWizardSucceed(data: ProcoteWizardNode[]) {
    this.draftState.productWizardProgress = Progress.SUCCESS
    this.draftState.productWizardData = data
  }
  productWizardFailed(errorDetails: string) {
    this.draftState.productWizardProgress = Progress.ERROR
    this.draftState.productWizardErrorDetails = errorDetails
  }

  setFarmerComment(producerSlug: string, comment: string) {
    this.draftState.cartInfo[producerSlug].farmerComment = comment
  }
}

export const CartSkuActions = createActionCreators(CartSkuReducer)
export default CartSkuActions
export const reducer = createReducerFunction(CartSkuReducer, initialState)

import useLangPicker from 'hooks/useLangPicker'
import { CartSkuItem } from 'modules/domain/cartSku/types'
import { CalculatedSkuDiscountDto } from 'modules/domain/discountsSku/types'
import { ProductUnits } from 'modules/domain/product/types'
import React, { useMemo } from 'react'
import { Currency } from 'types/entities'
import { SkuQtyDiscountWidget } from 'views/components/DiscountWidget/QtyDiscountWidget'
import SpecialOfferDiscountWidget from 'views/components/DiscountWidget/SpecialOfferDiscountWidget'
import * as DiscountWidgetStyles from 'views/components/DiscountWidget/styled'

export type SkuDiscountBarProps = {
  onApply: (qtyToApply: number, cartItem?: CartSkuItem) => void
  units?: ProductUnits
  discounts?: CalculatedSkuDiscountDto[]
  currency?: Currency
  cartItems?: CartSkuItem[]
}

export const SkuDiscountBar: React.FC<SkuDiscountBarProps> = ({
  onApply,
  currency = Currency.CAD,
  units,
  discounts,
  cartItems,
}) => {
  const { pick } = useLangPicker()

  //const monetaryDiscounts = useMemo(
  //  () => (discounts ? discounts.filter(d => d.program.tiers_rules[0].type !== DiscountTypes.Gift) : []),
  //  [discounts],
  //)

  const monetaryDiscounts = discounts

  const monetaryDiscountsComponents = useMemo(() => {
    if (!monetaryDiscounts || !monetaryDiscounts.length) return null
    return monetaryDiscounts?.map((discount, idx) => {
      const cartItem = cartItems?.find(e => discount.program.sku_ids.includes(e.sku_id || ''))
      const program = discount?.program
      //TODO refactor it and make more universal
      if (program.slug?.includes('special-offer')) {
        return (
          <SpecialOfferDiscountWidget
            key={program.slug}
            dataTestId={`campaign-${idx}`}
            title={program.title}
            learnMoreUrl={pick(program.link_url_i18n)}
            learnMoreUrlLabel={pick(program.link_label_i18n)}
            discount={discount}
            onGetDiscount={qty => onApply(qty, cartItem)}
          />
        )
      }
      return (
        <SkuQtyDiscountWidget
          key={program.slug}
          currency={currency}
          dataTestId={`${idx}`}
          title={program.title}
          discounts={[discount]}
          onGetDiscount={qty => onApply(qty, cartItem)}
          data-test-id="qty-discount-widget"
          units={units}
        />
      )
    })
  }, [monetaryDiscounts, cartItems, currency, units, pick, onApply])

  return discounts ? (
    <DiscountWidgetStyles.WidgetContainer data-test-id={`discount-bar`}>
      {monetaryDiscountsComponents}
    </DiscountWidgetStyles.WidgetContainer>
  ) : null
}

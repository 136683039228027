import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { Company } from 'types/entities'
import { Season } from '../season/types'

const dict = (state: AppGlobalState) => state.producer.items
const ids = (state: AppGlobalState) => state.producer.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, idOrSlug: string): Company | undefined => {
  return state.producer.items[idOrSlug]
    ? state.producer.items[idOrSlug]
    : Object.values(state.producer.items).find(item => item.slug === idOrSlug)
}

const meta = (state: AppGlobalState, id: string) =>
  state.producer.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }

const itemFetchProgress = (state: AppGlobalState) => state.producer.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.producer.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.producer.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.producer.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.producer.listFetchNextError
const list = createSelector(dict, ids, (dict, ids) => {
  const result: Company[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.producer.filter
const sorting = (state: AppGlobalState) => state.producer.sorting
const page = (state: AppGlobalState) => state.producer.page
const total = (state: AppGlobalState) => state.producer.total
const updateProgress = (state: AppGlobalState) => state.producer.updateProgress
const removeProgress = (state: AppGlobalState) => state.producer.removeProgress
const addProgress = (state: AppGlobalState) => state.producer.addProgress
const hasNext = (state: AppGlobalState) => state.producer.total > state.producer.ids.length
const pageSize = (state: AppGlobalState) => state.producer.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.producer.total / state.producer.pageSize)
const season = (state: AppGlobalState, producerIdOrSlug: string): Season | undefined => {
  if (state.producer.items[producerIdOrSlug].currentSeason) {
    return state.producer.items[producerIdOrSlug].currentSeason
  } else {
    const producer = Object.values(state.producer.items).find(item => item.slug === producerIdOrSlug)
    return producer?.currentSeason
  }
}
const seasonFetchProgress = (state: AppGlobalState) => state.producer.seasonFetchProgress

export const ProducerSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
  season,
  seasonFetchProgress,
}

export default ProducerSelectors

import React from 'react'
import { TotalCheckoutWidget } from 'views/components/DiscountWidget/DiscountTotal'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { SectionContainer, SectionTitle, SectionBody } from '@agro-club/frontend-shared'
import { useProducer } from 'modules/domain/producer/hooks'
import CartSkuDefault from 'views/pages/Checkout/CartSku/CartSkuDefault'

const SectionBodyStyled = styled(SectionBody)`
  padding: 16px 24px 40px;
`

const TruckContainer = styled.div`
  margin-bottom: 32px;
  ${props => props.theme.media.smallTablet`
    max-width: 100%;
  `}
`

const CartSkuDesktop: React.FC<{
  producerSlug: string
}> = ({ producerSlug }) => {
  const { t } = useTranslation(['checkout', 'discount'])
  const hasTotalInfo = useSelector(state => CartSkuSelectors.hasTotalInfo(state, producerSlug))
  const [, producer] = useProducer(producerSlug)

  return (
    <div>
      {hasTotalInfo && <TotalCheckoutWidget producer={producer} />}
      <TruckContainer>
        <SectionContainer noDivider>
          <SectionTitle>
            {t('cart.title')} | {producer?.official_name || ''}
          </SectionTitle>
          <SectionBodyStyled paddedOnMobile noGrid>
            <CartSkuDefault producerSlug={producerSlug} />
          </SectionBodyStyled>
        </SectionContainer>
      </TruckContainer>
    </div>
  )
}

export default CartSkuDesktop

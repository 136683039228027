import CartRoutes from './Cart/routes'
import SignInRoutes from './SignIn/routes'
import ProducerRoutes from './Producer/routes'
import ProfileRoutes from './Profile/routes'
import CheckoutRoutes from './Checkout/routes'
import MainRoutes from './Main/routes'

// import CartPage from './Cart/loadable'
// import CheckoutPage from './Checkout/loadable'
// import MainPage from './Main/loadable'
// import ProducerPage from './Producer/loadable'
// import ProductPage from './Producer/Product/ProductItem/loadable'
// import ProductListPage from './Producer/Product/ProductList/loadable'
// import CategoryListPage from './Producer/Category/CategoryList/loadable'
// import SubCategoryListPage from './Producer/SubCategory/SubCategoryList/loadable'

// import ProfilePage from './Profile/loadable'
// import ProfileOrderDetailsPage from './Profile/OrderDetails/loadable'
// import ProfileOrdersListPage from './Profile/OrdersList/loadable'
// import ProfileAccountInfoPage from './Profile/AccountInfoForm/loadable'
// import ProfileDeliveryPointPage from './Profile/DeliveryPointForm/loadable'

// import SignInPage from './SignIn/loadable'
import { LoadableComponent } from '@loadable/component'

export const Routes = {
  ...SignInRoutes,
  ...CartRoutes,
  ...ProducerRoutes,
  ...ProfileRoutes,
  ...MainRoutes,
  ...CheckoutRoutes,
}

export const Loadables: Record<string, LoadableComponent<any>> = {
  // [Routes.Main]: MainPage,
  // [Routes.ProducerListPage]: ProducerPage,
  // [Routes.CategoryList]: CategoryListPage,
  // [Routes.SubCategoryList]: SubCategoryListPage,
  // [Routes.ProductItem]: ProductPage,
  // [Routes.ProductList]: ProductListPage,
  // [Routes.SignIn]: SignInPage,
  // TODO Reenable
  // [Routes.Cart]: CartPage,
  // [Routes.Checkout]: CheckoutPage,
  // [Routes.Profile]: ProfilePage,
  // [Routes.Orders]: ProfileOrdersListPage,
  // [Routes.OrderDetails]: ProfileOrderDetailsPage,
  // [Routes.AccountInfo]: ProfileAccountInfoPage,
  // [Routes.DeliveryPoint]: ProfileDeliveryPointPage,
}

import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import ErrorLayout from '../ErrorLayout/ErrorLayout'

const WLErrorLayout = () => {
  const { t } = useTranslation('wlErrorLayout')

  return (
    <div data-test-id="wl-error">
      <Helmet>
        <title>{t('header')}</title>
        <meta name="description" content="" />
      </Helmet>
      <ErrorLayout header={t('header')} />
    </div>
  )
}

export default WLErrorLayout

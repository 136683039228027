import React from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import Header from 'views/components/Header/Header'
import Footer from 'views/components/Footer/Footer'

const PageWrapper: React.FC = ({ children }) => {
  return (
    <Layout.Root>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <Layout.Main>{children}</Layout.Main>
      <Layout.Footer>
        <Footer />
      </Layout.Footer>
    </Layout.Root>
  )
}

export default PageWrapper

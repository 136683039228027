import {
  AddCartSkuItemDTO,
  CartSkuItemResponse,
  CartSkuResponse,
  UpdateCartSkuItemParams,
} from 'modules/domain/cartSku/types'
import { endpoints } from 'modules/endpoints'
import { ApiService } from 'service/api/interface'
import { Promocode } from 'types/entities/promocode'

export const fetchCart = (apiClient: ApiService) => (promocodes: string[] = []) => {
  return apiClient.get<CartSkuResponse>(endpoints.cartSku('sellers'), { promocodes })
}

export const addItem = (apiClient: ApiService) => (params: AddCartSkuItemDTO) => {
  return apiClient.post<CartSkuItemResponse>(endpoints.cartSku(), params)
}

export const updateItem = (apiClient: ApiService) => (id: string, params: UpdateCartSkuItemParams) => {
  return apiClient.put<CartSkuItemResponse>(endpoints.cartSku(id), params)
}

export const deleteItem = (apiClient: ApiService) => (id: string) => {
  return apiClient.delete(endpoints.cartSku(id))
}

export const clearCart = (apiClient: ApiService) => (params: { seller_id?: string }) => {
  return apiClient.delete<{ deleted: number }>(endpoints.cartSku(), params)
}

export const fetchPromocodeStatus = (apiClient: ApiService) => ({
  code,
  company_id,
  appliedPromocodes,
}: {
  code: string
  company_id: string
  appliedPromocodes: string
}) => {
  return apiClient.get<Promocode>(endpoints.incentivePrograms('promocode/find'), {
    code,
    company_id,
    applied_promocodes: appliedPromocodes,
  })
}

import { useSelector } from 'react-redux'
import CartSelectors from 'modules/domain/cart/selectors'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { Company } from 'types/entities'
import { Promocode } from 'types/entities/promocode'

const usePromocodes = (producer?: Company): Promocode[] => {
  const productPromocodes = useSelector(state => CartSelectors.promocodes(state, producer?.slug || ''))
  const skuPromocodes = useSelector(state => CartSkuSelectors.promocodes(state, producer?.slug || ''))
  return producer?.sku_orders ? skuPromocodes : productPromocodes
}

export default usePromocodes

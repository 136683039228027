import React, { useCallback, useEffect, useMemo } from 'react'
import { CheckboxSelect, FormikHook, SimpleSelect } from '@agro-club/frontend-shared'
import { useRegions } from 'modules/domain/collection/hooks'
import { generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import { FeatureFlagModifiers, FieldLocation, FieldNames } from 'types/entities/config'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'
import useWLFeatureFlags from 'hooks/useWLFeatureFlags'
import { useSelector } from 'react-redux'
import CollectionSelectors from 'modules/domain/collection/selectors'

export type OrderProvinceForm = {
  retailer_regions_ids: string[]
  country: string
}

type OrderProvinceProps = {
  initialValues: OrderProvinceForm
  editable: boolean
  useFormik: FormikHook
  defaultProvinceId?: string
}

export const OrderProvince: React.FC<OrderProvinceProps> = ({
  initialValues,
  editable,
  useFormik,
  defaultProvinceId,
}) => {
  const { t } = useTranslation('profile')
  const checkFeatureFlag = useWLFeatureFlags()
  const hasOrderProvinceAccess = !checkFeatureFlag(
    generateFieldModifierString(FieldLocation.AccountForm, FieldNames.RetailerRegions, FeatureFlagModifiers.Hidden),
  )

  const multiProvinceAllowed = false // TODO Rely on producer company config
  const countries = useSelector(CollectionSelectors.countries)
  const [regionsProgress, regions] = useRegions(countries.map(country => country.id))
  const regionsOptions = useMemo(
    () =>
      regions
        ? regions
            .filter(e => e.country === initialValues.country)
            .map(reg => ({
              id: reg.id,
              title: reg.title,
            }))
        : [],
    [regions, initialValues.country],
  )

  const formik = useFormik<OrderProvinceForm>({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })

  const handleRetailerRegionsChange = useCallback(
    (regionId: string) => {
      if (formik.values.retailer_regions_ids?.includes(regionId)) {
        formik.setFieldValue(
          'retailer_regions_ids',
          formik.values.retailer_regions_ids.filter(id => id !== regionId),
        )
      } else {
        formik.setFieldValue(
          'retailer_regions_ids',
          formik.values.retailer_regions_ids ? [...formik.values.retailer_regions_ids, regionId] : [regionId],
        )
      }
    },
    [formik],
  )

  const handleRetailerRegionSet = useCallback(
    (regionId: string) => {
      formik.setFieldValue('retailer_regions_ids', [regionId])
    },
    [formik],
  )

  useEffect(() => {
    if (defaultProvinceId && formik.values.retailer_regions_ids.length === 0) {
      formik.setFieldValue('retailer_regions_ids', [defaultProvinceId])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultProvinceId])

  if (!hasOrderProvinceAccess) return null

  return (
    <Styled.FormRow width={'330px'}>
      {multiProvinceAllowed ? (
        <CheckboxSelect
          label={t('profile:accountInformation.orderProvince')}
          selected={formik.values.retailer_regions_ids || []}
          onChange={handleRetailerRegionsChange}
          options={regionsOptions}
          progress={regionsProgress}
          isDisabled={!editable}
          isSearchable={true}
          noneSelected={t('profile:accountInformation.chooseProvince')}
        />
      ) : (
        <SimpleSelect
          label={t('profile:accountInformation.orderProvince')}
          value={formik.values.retailer_regions_ids?.length && formik.values.retailer_regions_ids[0]}
          onChange={handleRetailerRegionSet}
          progress={regionsProgress}
          isDisabled={!editable}
          isSearchable={true}
          options={regionsOptions}
        />
      )}
    </Styled.FormRow>
  )
}

export default OrderProvince

import { endpoints } from 'modules/endpoints'
import { Product, ProductListRequestSorting, ProductListRequestFilter, ProductDTO } from './types'
import { ListResponse } from 'types/api'
import { CartDiscountEntryDTO, DiscountResponse } from '../cart/types'
import { ApiService } from 'service/api/interface'
import { pick } from 'ramda'

export const getList = (apiClient: ApiService) => (
  filter: ProductListRequestFilter,
  sorting: ProductListRequestSorting,
  page: number,
  pageSize: number,
  isExclusive?: boolean,
) => {
  return apiClient.get<ListResponse<Product>>(endpoints.product(), {
    ...sorting,
    product_id: filter.productIds,
    status: filter.status,
    country: filter.country,
    search: filter.search,
    subcategory: filter.subCategory,
    category: filter.category,
    producer_id: filter.producerId,
    filter_attributes:
      filter.attributes && Object.keys(filter.attributes).length ? JSON.stringify(filter.attributes) : undefined,
    page: page,
    slug: filter.slug,
    page_size: pageSize,
    is_exclusive: isExclusive,
  })
}

export const getItem = (apiClient: ApiService) => (id: string) => {
  return apiClient.get<Product>(endpoints.product(id))
}

export const addItem = (apiClient: ApiService) => (dto: ProductDTO) => {
  return apiClient.post<Product>(endpoints.product(), dto)
}

export const updateItem = (apiClient: ApiService) => (id: string, dto: Partial<ProductDTO>) => {
  return apiClient.put<Product>(endpoints.product(id), dto)
}

export const removeItem = (apiClient: ApiService) => (id: string) => {
  return apiClient.delete<Product>(endpoints.product(id))
}

export const fetchDiscounts = (apiClient: ApiService) => (id: string) => {
  return apiClient.get<DiscountResponse['discounts']>(endpoints.discount(id))
}

export const productDiscount = (apiClient: ApiService) => (productId: string, entries: CartDiscountEntryDTO[]) => {
  const reqFieldPicker = pick([
    'producer_id',
    'category_id',
    'subcategory_id',
    'title_i18n',
    'short_description_i18n',
    'description_i18n',
  ])

  return apiClient.post(endpoints.productDiscount(productId), {
    entries: entries.map(item => ({
      quantity: item.quantity,
      product: reqFieldPicker(item.product),
    })),
  })
}

export const calculateProductDiscount = (apiClient: ApiService) => (
  productId: string,
  entries: CartDiscountEntryDTO[],
) => {
  return apiClient.post(endpoints.productDiscountRules(productId), {
    entries: entries.map(item => ({
      quantity: item.quantity,
      product_id: item.product.id,
    })),
  })
}

export const getExclusiveItem = (apiClient: ApiService) => (id: string) => {
  return apiClient.get<Product>(endpoints.exclusiveProduct(id))
}

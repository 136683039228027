import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { ProducerListRequestFilter, ProducerListRequestSorting } from './types'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { ProducerState } from './types'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { Company } from 'types/entities'
import { FetchError } from 'modules/domain/types'
import { Season } from '../season/types'

const initialState: ProducerState = {
  items: {},
  meta: {},
  ids: [],
  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  seasonFetchProgress: Progress.IDLE,
  seasonFetchError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

class ProducerReducer extends ImmerReducer<ProducerState> {
  listRequested(params: { filter?: ProducerListRequestFilter; sorting?: ProducerListRequestSorting; page?: number }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }
  listRequestSucceed(list: Company[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }
  listRequestFailed(error: FetchError) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK
    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }
  itemRequestSucceed(item: Company) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    if (!this.draftState.meta[item.id] && this.draftState.meta[item.slug]) {
      this.draftState.meta[item.id] = this.draftState.meta[item.slug]
    }
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchError = null
    this.draftState.meta[item.slug].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.slug].fetchError = null
    this.draftState.items[item.id] = item
    this.draftState.items[item.slug] = item
  }
  itemRequestFailed(id: string, error: FetchError) {
    this.draftState.itemFetchProgress = Progress.ERROR
    if (this.draftState.meta[id]) {
      this.draftState.meta[id].fetchProgress = Progress.ERROR
      this.draftState.meta[id].fetchError = error
    }
  }

  filterUpdated(filter: ProducerListRequestFilter) {
    this.draftState.filter = filter
  }

  sortingUpdated(sorting: ProducerListRequestSorting) {
    this.draftState.sorting = sorting
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
  }

  listRequestNextSucceed(list: Company[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...new Set([...this.draftState.ids, ...getIds(list)])]
  }

  listRequestNextFailed(error: FetchError) {
    this.draftState.listFetchNextProgress = Progress.ERROR
    this.draftState.listFetchNextError = error
  }

  seasonFetchRequested(_company_id: string) {
    this.draftState.seasonFetchProgress = Progress.WORK
  }
  seasonFetchSucceed(company_id: string, slug: string, season: Season) {
    this.draftState.seasonFetchProgress = Progress.SUCCESS
    this.draftState.items[company_id].currentSeason = season
    this.draftState.items[slug].currentSeason = season
  }
  seasonFetchError() {
    this.draftState.seasonFetchProgress = Progress.ERROR
  }
}

export const ProducerActions = createActionCreators(ProducerReducer)
export default ProducerActions
export const reducer = createReducerFunction(ProducerReducer, initialState)

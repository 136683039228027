import { SectionContainer } from '@agro-club/frontend-shared'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { Card } from 'modules/domain/card/types'
import { useProducer } from 'modules/domain/producer/hooks'
import { cardToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import ProducerRoutes from '../../../routes'
import { AdderWidgetEvents } from '../events'
import { CustomSectionBody, HowItWorksButtonStyled, TitleStyled } from './AdderWidget/AdderWidgetDesktop'

const OutOfStockContent = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
  font-weight: 500;
`

type Props = {
  withContainer?: boolean
  card: Card
}

const OutOfStockWidget: React.FC<Props> = ({ withContainer, card }) => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation('order')
  const match = useRouteMatch<{ producerSlug?: string }>(ProducerRoutes.ProducerInfoPage)
  const [, producer] = useProducer(match?.params.producerSlug)
  const sellerConfig = producer?.config?.seller_config
  const outOfStockInfo = sellerConfig?.out_of_stock_info
  const emailValue = outOfStockInfo?.email
  const text = (outOfStockInfo?.text || t('contactLocalRep')) + ' '

  const handleHowItWorksClick = () => track(AdderWidgetEvents.HowItWorksClick, cardToEventParams(card))

  const content = (
    <OutOfStockContent>
      <div data-test-id="out-of-stock-text">{text}</div>
      {!!emailValue && (
        <a data-test-id="out-of-stock-email" href={`mailto:${emailValue}`}>
          {emailValue}
        </a>
      )}
    </OutOfStockContent>
  )

  return withContainer ? (
    <div data-test-id="out-of-stock-widget">
      <SectionContainer noDivider>
        <CustomSectionBody>
          <TitleStyled>{t('outOfStock')}</TitleStyled>
          {content}
          <HowItWorksButtonStyled items={sellerConfig?.how_it_works || []} onClick={handleHowItWorksClick} />
        </CustomSectionBody>
      </SectionContainer>
    </div>
  ) : (
    content
  )
}

export default OutOfStockWidget

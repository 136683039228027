export enum CartEvents {
  UpdateCart = 'cart_change_tap',
  ServiceOffer = 'cart_offer_tap',
  TermsAccepted = 'cart_agree',
  // Event "cart_order" is missing in Enterprise events https://docs.google.com/spreadsheets/d/1FOgyikcd9MRAVOGK06X3W67p5lenQDqGTIk46M-t3EQ/edit#gid=898106122. Is it deprecated?
  Checkout = 'cart_order',
  ProceedToCheckout = 'cart_proceed_to_checkout',
  CartContinueShopping = 'cart_continue_shopping',
  CartHowItWorks = 'cart_how_it_works',
  CartPromoCode = 'cart_apply_promo_tap',
  GiftWidgetButtonClick = 'cart_choose_gift',
  GiftPopupGetGiftClick = 'cart_get_gift_tap',
}

export type Uuid = {
  uuid: string
}

export type TokenResponse = {
  access_token: string
  refresh_token: string
}
export type Credentials = {
  email: string
  password: string
}
export type TokenRequestFailedPayload = {
  message: string
}

export enum GdprType {
  GET = 'get',
  DELETE = 'delete',
}

export enum GdprStatus {
  NEW = 'new',
  DONE = 'done',
}

export type UserGdprDTO = {
  id: string
  type: GdprType
  status: GdprStatus
  user_id: string
  email?: string | null
  comment?: string
}

import { AppGlobalState } from 'modules/types'
import { createSelector } from 'reselect'
import { Category, FilterAttribute, PromoInfoType } from 'modules/domain/collection/types'
import { Dict } from 'types/generics'

export const getRegionsFetchProgress = (state: AppGlobalState) => state.collection.regionsFetchProgress
export const getRegions = (state: AppGlobalState) => state.collection.regions
export const getCategoriesFetchProgress = (state: AppGlobalState) => state.collection.categoriesFetchProgress
export const getCategories = (state: AppGlobalState) => state.collection.categories
export const getCompanyCategory = (state: AppGlobalState, companyId: string) =>
  state.collection.companyCategories[companyId]
export const getCompanySubCategory = (state: AppGlobalState, companyId: string, categorySlug: string) =>
  state.collection.companySubCategories[`${companyId}-${categorySlug}`]
export const getDistributors = (state: AppGlobalState) => state.collection.distributors
export const getDistributorsFetchProgress = (state: AppGlobalState) => state.collection.distributorsFetchProgress
export const getDistributorsProducerRelation = (state: AppGlobalState) => state.collection.distributorsProducerRelation
export const getFilterAttributesProducerId = (state: AppGlobalState) => state.collection.filterAttributesProducerId
export const getFilterAttributesSubCategoryId = (state: AppGlobalState) =>
  state.collection.filterAttributesSubCategoryId
export const getFilterAttributesProgress = (state: AppGlobalState) => state.collection.filterAttributesProgress
export const getFilterAttributes = (state: AppGlobalState, producerSlug: string, subCategorySlug: string) =>
  state.collection.filterAttributes[producerSlug]?.[subCategorySlug] || []
export const getPromoInfo = (state: AppGlobalState, type: PromoInfoType, producerId?: string, categoryId?: string) => {
  return state.collection.promoInfo.find(p => {
    return (
      p.type === type &&
      (producerId ? p.producer_ids.includes(producerId) : true) &&
      (categoryId ? p.category_ids.includes(categoryId) : true)
    )
  })
}

const categoriesSelector = createSelector(getCategories, categories => {
  const result: Dict<Category> = {}
  Object.values(categories).forEach(category => {
    if (!category.parent_id) {
      result[category.id] = category
    }
  })
  return result
})
const subCategoriesSelector = createSelector(getCategories, categories => {
  const result: Dict<Category> = {}
  Object.values(categories).forEach(category => {
    if (category.parent_id) {
      result[category.id] = category
    }
  })
  return result
})

const categoryByIdOrSlug = (state: AppGlobalState, idOrSlug: string) => {
  return Object.values(state.collection.categories).find(c => c.slug === idOrSlug || c.id === idOrSlug)
}

const distributorsSelector = createSelector(getDistributors, distributors => distributors)

const filterAttributesSelector = createSelector(getFilterAttributes, attrs => {
  const result: FilterAttribute[] = [...attrs]
    .sort((a, z) => a.title.localeCompare(z.title))
    .map(attr => {
      const values = [...attr.values].sort((a, z) => a.localeCompare(z))
      return {
        title: attr.title,
        values,
      }
    })
  return result
})

export const getCountryPhoneCodesFetchProgress = (state: AppGlobalState) => state.collection.countryPhoneCodesProgress
export const getCountryPhoneCodes = (state: AppGlobalState) => state.collection.countryPhoneCodes
export const getCountriesProgress = (state: AppGlobalState) => state.collection.countriesProgress
export const getCountries = (state: AppGlobalState) => state.collection.countries

const CollectionSelectors = {
  regions: getRegions,
  regionsFetchProgress: getRegionsFetchProgress,
  categoriesFetchProgress: getCategoriesFetchProgress,
  categories: categoriesSelector,
  categoryByIdOrSlug,
  subCategories: subCategoriesSelector,
  distributors: distributorsSelector,
  distributorsFetchProgress: getDistributorsFetchProgress,
  distributorsProducerRelation: getDistributorsProducerRelation,
  filterAttributes: filterAttributesSelector,
  promoInfo: getPromoInfo,
  filterAttributesSubCategoryId: getFilterAttributesSubCategoryId,
  filterAttributesProducerId: getFilterAttributesProducerId,
  filterAttributesProgress: getFilterAttributesProgress,
  countryPhoneCodes: getCountryPhoneCodes,
  countryPhoneCodesProgress: getCountryPhoneCodesFetchProgress,
  countriesProgress: getCountriesProgress,
  countries: getCountries,
}

export default CollectionSelectors

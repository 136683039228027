import { BusinessModel, Company, Currency } from 'types/entities'
import { Product } from 'modules/domain/product/types'
import { UserProfile } from 'types/entities/userProfile'
import { CountryCode } from 'libphonenumber-js'
import { Sku } from 'modules/domain/sku/types'
import { Card } from 'modules/domain/card/types'
import { WizardData } from '@agro-club/frontend-shared'

export enum OrderStatus {
  New = 'new',
  Changed = 'changed',
  Confirmed = 'confirmed',
  Canceled = 'canceled',
  Completed = 'completed',
  Deleted = 'deleted',
}

export type OrderItem = {
  id: string
  product_id: string
  quantity: string
  discount: string
  price: string
  cost: string
  total: string
  packaging: number
  product: Product
  seed_treatment_id?: string
  options?: string[]
  sku_id?: string
  sku?: Sku
  card_id?: string
  sku_card?: Card
}

export type OrderPayment = {
  payment_type: 'pre_payment' | 'payment'
  amount: number
  is_paid: boolean
  pay_date: string
  percentage: number
}
export type OrderDeliveryAddress = {
  delivery_date?: string
  address: string
  city: string
  region_id?: string
  state?: string
  country: CountryCode
  postal_code?: string
  desired_delivery_date?: string
}
export type Order = {
  id: string
  currency: Currency
  status: OrderStatus
  region_id: string | null
  producer_id: string | null
  distributor_id?: string | null
  owner_id: string
  producer: Company | null // TODO proper type
  distributor?: Company | null // TODO proper type
  items: OrderItem[]
  payments?: OrderPayment[]
  created_at: string
  delivery_addresses: OrderDeliveryAddress[]
  customer: UserProfile | null // TODO proper type
  country: CountryCode
  business_model: BusinessModel
  price?: string
  cost?: string
  discount?: number
  total?: string
  comment?: string
  num?: number // fake order number
  slug: string
  revision?: string
  interaction: {
    credit_offer_accepted: boolean
    canceled_by_role?: string
    confirmed_by_agroclub?: boolean
    confirmed_by_distributor?: string
    confirmed_by_producer?: boolean
    confirmed_by_farmer?: boolean
  }
  discount_total: string
  farmer_comment?: string
  seller_id: string | null
  sku_items: OrderItem[]
}

export type OrderItemDTO = {
  id?: string
  product_id?: string
  sku_id?: string
  card_id?: string
  quantity: string
  price?: number
  seed_treatment_id?: string | null
  options?: string[]
  packaging?: number | null
  revision?: string
  wizard_comment?: string
  wizard_data?: WizardData<any>
  discount?: string
}

export type OrderDTO = {
  producer_id: string
  region_id?: string
  currency?: Currency
  distributor_id?: string
  items?: OrderItemDTO[]
  payments?: OrderPayment[]
  delivery_addresses: OrderDeliveryAddress[]
  country?: CountryCode
  comment?: string
  status?: OrderStatus
  interaction: {
    credit_offer_accepted: boolean
    confirmed_by_farmer?: boolean
    confirmed_by_agroclub?: boolean
    confirmed_by_producer?: boolean
  }
  promocodes?: { code: string; comment?: string }[]
  revision?: string
  farmer_comment?: string
  seller_id: string | null
  sku_items: OrderItemDTO[]
  owner_id?: string
}

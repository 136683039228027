import React, { useMemo } from 'react'
import useLangPicker from 'hooks/useLangPicker'
import styled, { StyledProps } from 'styled-components'
import { Sku } from 'modules/domain/sku/types'

type ContainerAlign = 'left' | 'center'

const BaseUnitsContainer = styled.div<StyledProps<{ align?: ContainerAlign }>>`
  color: ${props => props.theme.color.textPrimary};
  display: flex;
  align-items: center;
  justify-content: ${props => (props.align ? props.align : 'center')};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

export const BaseUnits: React.FC<{
  qty: number
  sku?: Sku
  align?: ContainerAlign
}> = ({ qty, sku, align }) => {
  const { pick } = useLangPicker()
  const { conversion_rate } = { ...sku?.params.package }
  const singularUnit = pick(sku?.product?.units?.singular_i18n)
  const pluralUnit = pick(sku?.product?.units?.plural_i18n)
  const rate = conversion_rate || 1
  const calculated = Number((qty * rate).toFixed(1))
  const baseUnit = calculated === 1 ? singularUnit : pluralUnit
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const content = useMemo(() => `${calculated} ${baseUnit}`, [calculated, baseUnit, rate])

  if (!sku) {
    return null
  }

  return <BaseUnitsContainer align={align}>{content}</BaseUnitsContainer>
}

import React, { useContext } from 'react'
import { Redirect, Switch, Route, useRouteMatch } from 'react-router-dom'
import { Routes } from 'views/pages/routes'
import { HelmetContext } from 'hooks/useHelmet'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import { Progress } from 'modules/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import ErrorLayout from './layouts/ErrorLayout/ErrorLayout'
import CookiesPolicy from './components/CookiesPolicy/CookiesPolicy'
import PageWrapper from 'views/components/PageWrapper/PageWrapper'
import CartPage from './pages/Cart/loadable'
import SignInPage from './pages/SignIn/SignInPage'
import ProducerPage from './pages/Producer/ProducerPage'
import MainPage from './pages/Main/MainPage'
import Checkout from './pages/Checkout/loadable'
import ProfilePage from './pages/Profile/loadable'
import { useCountryPath } from 'hooks/useCountryPath'
import styled from 'styled-components'
import { useZendeskWidget } from '@agro-club/frontend-shared'
import useWhiteLabelConfig from 'hooks/useWhiteLabelConfig'
import { DefaultWhiteLabelConfig } from 'service/whiteLabelConfig/defaultConfig'
import WLErrorLayout from './layouts/WLErrorLayout/WLErrorLayout'
import MaintenanceLayout from './layouts/MaintenanceLayout/MaintenanceLayout'
import useLanguage from 'hooks/useLanguage'

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: stretch;
  flex-grow: 1;
  padding: 100px 0;
`

const RootComponent: React.FC = () => {
  const authProgress = useSelector(AuthSelectors.initProgress)
  const uuidRequestProgress = useSelector(AuthSelectors.uuidRequestProgress)
  const rootMatch = useRouteMatch<{ country: string }>(Routes.Root)
  const generateCountryPath = useCountryPath()
  const { config: wlConfig } = useWhiteLabelConfig()
  const profile = useSelector(AuthSelectors.profile)
  const language = useLanguage()

  useZendeskWidget(wlConfig?.third_party_keys?.zendesk_key, {
    name:
      profile && profile.first_name
        ? `${profile.first_name}${profile.last_name ? ' ' + profile.last_name : ''}`
        : undefined,
    email: profile?.email ?? '',
    phone: profile?.phone_number,
    language,
  })

  const { helmet } = useContext(HelmetContext)

  if (!wlConfig || wlConfig.slug === DefaultWhiteLabelConfig.slug) {
    return <WLErrorLayout />
  }

  if (wlConfig.on_maintenance) {
    return <MaintenanceLayout />
  }

  if (uuidRequestProgress === Progress.ERROR) {
    return <ErrorLayout />
  }

  if (authProgress === Progress.WORK || authProgress === Progress.IDLE) {
    return (
      <SpinnerWrapper>
        <SpinnerLayout />
      </SpinnerWrapper>
    )
  }

  return (
    <>
      <Helmet>
        <title>{helmet.title}</title>
        <meta name="description" content={helmet.description} />
      </Helmet>
      <PageWrapper>
        <Switch>
          <Route path={Routes.Root} exact={true}>
            <MainPage />
          </Route>
          <Route path={Routes.SignIn}>
            <SignInPage />
          </Route>
          <Route path={Routes.ProducerCart}>
            <CartPage />
          </Route>
          <Route path={Routes.Cart}>
            <CartPage />
          </Route>
          <Route path={Routes.CheckoutProducer}>
            <Checkout />
          </Route>
          <Route path={Routes.Checkout}>
            <Checkout />
          </Route>
          <Route path={Routes.Profile}>
            <ProfilePage />
          </Route>
          <Route path={Routes.ProducerInfoPage}>
            <ProducerPage />
          </Route>
          <Redirect to={generateCountryPath(Routes.SignIn, { country: rootMatch?.params.country })} />
        </Switch>
      </PageWrapper>
      <CookiesPolicy />
    </>
  )
}

export default RootComponent

export enum CardItemEvents {
  ProductInfoFileClick = 'product_info_file_tap',
  VideoClick = 'product_video_tap',
  ProductItemGiftCampaignLearnMore = 'product_gift_know_more',
  ProductItemRelatedGiftClick = 'product_gift_tap',
  ProductItemShowMoreGifts = 'product_gift_show_more',
  GiftItemRelatedProductClick = 'gift_eligible_product_tap',
  GiftItemShowMoreProducts = 'gift_product_show_more',
  GiftItemAddToTruck = 'gift_get_gift_tap',
  GiftItemExploreProducts = 'gift_choose_eligible_tap',
  GiftItemGoToCheckout = 'gift_go_to_cart',
  GiftItemGiftCampaignLearnMore = 'gift_know_more',
}

export enum AdderWidgetEvents {
  AddToTruckClick = 'product_add_to_cart_tap',
  ChangeProductQty = 'product_change_qty',
  ChangeProductQtyInTruck = 'product_change_cart',
  GoToCheckout = 'product_go_to_cart',
  HowItWorksClick = 'product_how_it_works',
}

import { Progress } from 'modules/types'

export const composedProgress = (progressList: Progress[]): Progress => {
  let result: Progress = Progress.SUCCESS

  loop: for (const progress of progressList) {
    switch (progress) {
      case Progress.WORK:
      case Progress.IDLE:
      case Progress.ERROR:
        result = progress
        break loop
    }
  }

  return result
}

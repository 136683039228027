import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { PackageTypes, PackageTypesListRequestFilter, PackageTypesListRequestSorting } from './types'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { PackageTypesState } from './types'
import { LIST_PAGE_SIZE } from 'modules/constants'

const initialState: PackageTypesState = {
  items: {},
  meta: {},
  ids: [],

  allItems: {},
  allIds: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listAllFetchProgress: Progress.IDLE,
  listAllFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

class PackageTypesReducer extends ImmerReducer<PackageTypesState> {
  listRequested(params: {
    filter?: PackageTypesListRequestFilter
    sorting?: PackageTypesListRequestSorting
    page?: number
    pageSize?: number
  }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
    this.draftState.pageSize = typeof params.pageSize === 'undefined' ? this.draftState.pageSize : params.pageSize
  }

  listRequestSucceed(list: PackageTypes[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }

  listRequestFailed() {
    this.draftState.listFetchProgress = Progress.ERROR
  }

  listAllRequested() {
    this.draftState.listAllFetchProgress = Progress.WORK
    this.draftState.listAllFetchError = null
  }

  listAllRequestSucceed(list: PackageTypes[]) {
    this.draftState.listAllFetchProgress = Progress.SUCCESS
    this.draftState.allItems = arrToDict(list)
    this.draftState.allIds = getIds(list)
  }

  listAllRequestFailed() {
    this.draftState.listAllFetchProgress = Progress.ERROR
  }

  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  itemRequestSucceed(item: PackageTypes) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchError = null
    this.draftState.items[item.id] = item
  }

  itemRequestFailed(id: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
  }

  filterUpdated(filter: PackageTypesListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingUpdated(sorting: PackageTypesListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
    this.draftState.listFetchError = null
  }

  listRequestNextSucceed(list: PackageTypes[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...this.draftState.ids, ...getIds(list)]
  }

  listRequestNextFailed() {
    this.draftState.listFetchNextProgress = Progress.ERROR
  }
}

export const PackageTypesActions = createActionCreators(PackageTypesReducer)
export default PackageTypesActions
export const reducer = createReducerFunction(PackageTypesReducer, initialState)

import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import { useAction } from '@agro-club/frontend-shared'
import SmsCodeVerificationForm, { SmsCodeVerificationFormProps } from './SmsCodeVerificationForm'
import AccountForm from 'views/components/LoginForm/AccountForm'
import { Progress } from 'modules/types'
import SignUpForm, { SignUpFormData } from 'views/components/SignUpForm/SignUpForm'
import { AddressFormType } from 'views/components/AddressForm/AddressForm'
import { ProfileAddress } from 'types/entities/userProfile'
import { CountryCode } from 'libphonenumber-js'
import { useDetectedCountry } from 'hooks/useDetectedCountry'

type AccountFormProps = {
  mode: 'phone' | 'email'
  email: string
  phone?: string
  country?: CountryCode
}

const useLoginSuccessCallback = (cb?: () => void) => {
  const currentAuthProgress = useSelector(AuthSelectors.authProgress)
  const prevAuthProgress = useRef(currentAuthProgress)
  const clearAction = useAction(AuthActions.clearAuthProgress)

  useEffect(() => {
    if (cb && prevAuthProgress.current !== Progress.SUCCESS && currentAuthProgress === Progress.SUCCESS) {
      cb()
      clearAction()
    }
  }, [currentAuthProgress, cb, clearAction])
}

const mapAddr = (addr: Required<AddressFormType>): ProfileAddress => ({
  address: addr.address,
  city: addr.city,
  country: addr.country,
  postal_code: addr.postal_code,
  region_id: addr.region_id,
})

type PropsType = {
  onLoginSuccess?(): void
}

const LoginForm: React.FC<PropsType> = ({ onLoginSuccess }: PropsType) => {
  const email = useSelector(AuthSelectors.email)
  const phone = useSelector(AuthSelectors.phone)
  const step = useSelector(AuthSelectors.step)
  const profile = useSelector(AuthSelectors.profile)
  const phoneSubmitProgress = useSelector(AuthSelectors.submitCredentialsProgress)
  const registerProgress = useSelector(AuthSelectors.registerProgress)

  const suggestedPhone = useSelector(AuthSelectors.suggestedPhone)
  const submitEmailAction = useAction(AuthActions.emailSubmitted)
  const submitPhoneAction = useAction(AuthActions.phoneSubmitted)
  const submitSmsCodeAction = useAction(AuthActions.smsCodeVerificationRequested)
  const submitRegisterAction = useAction(AuthActions.userRegisterRequested)

  const detectedCountry = useDetectedCountry()

  const handleCredentialsSubmit = (props: AccountFormProps) => {
    if (props.mode === 'email') {
      submitEmailAction(props.email)
    } else if (props.mode === 'phone' && props.phone) {
      submitPhoneAction(props.phone)
    }
  }
  const handleSmsVerificationSubmit = (props: SmsCodeVerificationFormProps) => {
    submitSmsCodeAction(phone, props.code, { country: detectedCountry })
  }

  const handleSignUpSubmit = (form: SignUpFormData) => {
    submitRegisterAction(
      {
        // addresses: form.additionalAddresses.map(mapAddr),
        legal_address: mapAddr(form.mailingAddress),
        delivery_address: mapAddr(form.mainAddress),
        country: form.userInfo.country,
        email: form.userInfo.email || null,
        phone_number: form.userInfo.phone_number,
        first_name: form.userInfo.first_name,
        last_name: form.userInfo.last_name,
        farm_name: form.userInfo.farm_name,
        partnership: form.partnership,
        tax_id: form.userInfo.tax_id,
        retailer_regions_ids: form.orderProvince.retailer_regions_ids,
      },
      {
        country: detectedCountry,
      },
    )
  }

  useLoginSuccessCallback(onLoginSuccess)

  switch (step) {
    case 'credentials':
      return (
        <AccountForm
          initialValues={{ email, phone, mode: 'phone' }}
          onSubmit={handleCredentialsSubmit}
          progress={phoneSubmitProgress}
        />
      )
    case 'sms':
      return <SmsCodeVerificationForm onSubmit={handleSmsVerificationSubmit} phone={suggestedPhone} />
    case 'register':
      return (
        <SignUpForm
          onSubmit={handleSignUpSubmit}
          email={email}
          phone={profile?.phone_number || phone}
          progress={registerProgress}
        />
      )
  }
}

export default LoginForm

import { RouterState } from 'connected-react-router'

import { SignUpState } from './domain/signup/types'
import { CollectionState } from './domain/collection/types'
import { ProductState } from './domain/product/types'
import { ProducerState } from './domain/producer/types'
import { CartState } from './domain/cart/types'
import { OrderState } from './domain/order/types'
import { CheckoutState } from 'modules/domain/checkout/types'
import { AuthState } from 'types/entities/userProfile'
import { DocumentState } from './domain/documents/types'
import { ProductOptionsState } from './domain/productOptions/types'
import { RelatedProductState } from './domain/relatedProduct/types'
import { CardState } from './domain/card/types'
import { CartSkuState } from './domain/cartSku/types'
import { PackageTypesState } from './domain/packageTypes/types'

export type AppGlobalState = {
  router: RouterState
  auth: AuthState
  signUp: SignUpState
  collection: CollectionState
  product: ProductState
  producer: ProducerState
  cart: CartState
  cartSku: CartSkuState
  checkout: CheckoutState
  order: OrderState
  documents: DocumentState
  productOptions: ProductOptionsState
  packageTypes: PackageTypesState
  relatedProduct: RelatedProductState
  card: CardState
}

export type ActionPayload<T extends (...args: any[]) => { payload: any }> = Pick<ReturnType<T>, 'payload'>['payload']

export enum Progress {
  IDLE = 'IDLE',
  WORK = 'WORK',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum AnalyticsEvent {
  Page = 'Page',
}

export type ResourceHook<T = any, Args extends any[] = any[]> = (...args: Args) => readonly [Progress, T | undefined]

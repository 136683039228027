import React, { useCallback, useMemo, useState } from 'react'
import * as Styled from 'views/pages/Producer/Product/ProductList/styled'
import Drawer from 'views/ui/Drawer/Drawer'
import { Button, Chip, useAction, useBoolean, useScrollTrigger } from '@agro-club/frontend-shared'
import Breadcrumbs from 'views/ui/Breadcrumbs/Breadcrumbs'
import { useParams } from 'react-router-dom'
import { Routes } from 'views/pages/routes'
import { useCategory, useFilterAttributes, useSubcategory } from 'modules/domain/collection/hooks'
import { Progress } from 'modules/types'
import { useSelector } from 'react-redux'
import CardSelectors from 'modules/domain/card/selectors'
import { FilterAttribute, PromoInfoType } from 'modules/domain/collection/types'
import CardActions from 'modules/domain/card/duck'
import { useTranslation } from 'react-i18next'
import ErrorLayout from 'views/layouts/ErrorLayout/ErrorLayout'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import useHelmet from 'hooks/useHelmet'
import useLangPicker from 'hooks/useLangPicker'
import usePersistentScroll from 'hooks/usePersistentScroll'
import { useProducer } from 'modules/domain/producer/hooks'
import BadgeNew from 'views/components/BadgeNew/BadgeNew'
import BadgeLimited from 'views/components/BadgeLimited/BadgeLimited'
import BadgeOutOfStock from 'views/components/BadgeOutOfStock/BadgeOutOfStock'
import useComposedResourceHooks from 'hooks/useComposedResourceHooks'
import { CardListRequestParams, useCardList } from 'modules/domain/card/hooks'
import Layout404 from 'views/layouts/404/404'
import CollectionSelectors from 'modules/domain/collection/selectors'
import ReOrderForm from 'views/components/ReOrderForm/ReOrderForm'
import { useCountryPath } from 'hooks/useCountryPath'
import { queries } from 'theme/theme'
import useMatchMedia from 'hooks/useMatchMedia'
import { Page } from 'modules/utils/analytics-utils/pageNames'
import { subcategoryToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'

const Card: React.FC<{
  image?: string
  variety: string
  slug: string
  description: string
  title: string
  discountText?: string
  isNew: boolean
  isLimited: boolean
  isOutOfStock: boolean
  onClick?: () => void
}> = ({ image, variety, slug, description, title, discountText, isNew, isLimited, isOutOfStock, onClick }) => {
  const params = useParams<{ producerSlug: string }>()
  const generateCountryPath = useCountryPath()
  const path = generateCountryPath(Routes.ProductOrCardItem, { ...params, productOrCardSlug: slug })
  return (
    <Styled.ListItem to={path} data-test-id={'card-item'} onClick={onClick}>
      {image ? (
        <Styled.ItemImageWrapper>
          <Styled.ItemImage src={image} />
        </Styled.ItemImageWrapper>
      ) : (
        <Styled.ItemImageStub />
      )}
      <Styled.ItemDescriptionContainer>
        <Styled.ItemTitle>{variety}</Styled.ItemTitle>
        <Styled.ItemId title={title}>{title}</Styled.ItemId>
        <Styled.ItemDescription title={description}>{description}</Styled.ItemDescription>
        {discountText && <Styled.ItemSaveUpToContainer>{discountText}</Styled.ItemSaveUpToContainer>}
      </Styled.ItemDescriptionContainer>
      <Styled.BadgeContainer>
        {isNew ? <BadgeNew /> : null}
        {isLimited ? <BadgeLimited /> : null}
        {isOutOfStock ? <BadgeOutOfStock /> : null}
      </Styled.BadgeContainer>
    </Styled.ListItem>
  )
}

const FilterCardContent: React.FC<{
  anchorReached: boolean
  attributes: FilterAttribute[]
  producerName?: string
  producerLogo?: string
}> = ({ anchorReached, producerName, attributes, producerLogo }) => {
  const { t } = useTranslation(['product', 'common'])
  const params = useParams<{ producerSlug: string; subCategorySlug: string }>()
  const [collapsed, collapse] = useBoolean(true)
  const isSomeNonDefaultFilterActive = true
  const filter = useSelector(CardSelectors.filter)
  const filterParams = {
    sellerSlug: params.producerSlug,
    subCategorySlug: params.subCategorySlug,
  }
  const updateFilter = useAction(CardActions.filterUpdated)
  const clearFilter = () => {
    updateFilter({
      filter: { ...filter, attributes: undefined },
      ...filterParams,
    })
  }

  //TODO refactor this shit. It was created for DSM subcategory filter's order
  const splittedAttributes = attributes.map(attr => {
    const splittedTitle = attr.title.split('::')
    return { ...attr, title: splittedTitle.pop() || '' }
  })

  const toggleAttribute = (title: string, value: string) => {
    const newAttribute = filter.attributes?.[title] ? Array.from(filter.attributes?.[title]) : []
    const map = {
      ...filter.attributes,
      [title]: newAttribute,
    }

    if (newAttribute.includes(value)) {
      newAttribute.splice(newAttribute.indexOf(value), 1)
    } else {
      newAttribute.push(value)
    }

    if (newAttribute.length === 0) {
      delete map[title]
    }

    updateFilter({ filter: { ...filter, attributes: map }, ...filterParams })
  }

  const resetAttribute = (title: string) => {
    const map = {
      ...filter.attributes,
      [title]: [],
    }
    delete map[title]
    updateFilter({ filter: { ...filter, attributes: map }, ...filterParams })
  }

  return (
    <Drawer open={!collapsed} onClickOutside={collapse} initialHeight={56}>
      <Styled.FilterContainer data-sticky={true} data-anchor-reached={anchorReached} data-collapsed={collapsed}>
        <Styled.FilterHeader>
          <Styled.FilterLogoContainer>
            {producerLogo && <Styled.FilterLogo src={producerLogo} noFilters={!splittedAttributes.length} />}
          </Styled.FilterLogoContainer>
          {splittedAttributes.length > 0 && (
            <Styled.FilterButton active={isSomeNonDefaultFilterActive} onClick={() => collapse(!collapsed)} />
          )}
          <Styled.FilterTitle>{producerName}</Styled.FilterTitle>
        </Styled.FilterHeader>

        {splittedAttributes.length > 0 && (
          <>
            <Styled.FilterBody collapsed={collapsed}>
              {attributes.map(attr => {
                const selectedAttr = filter.attributes?.[attr.title]
                return (
                  <Styled.FilterGroup key={attr.title.split('::').pop()} title={attr.title.split('::').pop() || ''}>
                    {attr.values.length > 1 && (
                      <Chip
                        active={!selectedAttr}
                        color={'darkgreen'}
                        size={'small'}
                        key={'all'}
                        onClick={() => resetAttribute(attr.title)}
                      >
                        {t('filter.selectAll')}
                      </Chip>
                    )}
                    {attr.values.map(value => {
                      return (
                        <Chip
                          active={!!selectedAttr && selectedAttr.includes(value)}
                          color={'darkgreen'}
                          size={'small'}
                          key={value}
                          onClick={() => toggleAttribute(attr.title, value)}
                        >
                          {value}
                        </Chip>
                      )
                    })}
                  </Styled.FilterGroup>
                )
              })}
            </Styled.FilterBody>
            <Styled.ClearFilterButtonDesktop type={'button'} onClick={clearFilter}>
              {t('filter.clear')}
            </Styled.ClearFilterButtonDesktop>
            <Styled.FilterFooter>
              <Styled.ClearFilterButtonMobile type={'button'} onClick={clearFilter}>
                {t('filter.clear')}
              </Styled.ClearFilterButtonMobile>
              <Button onClick={collapse} intent={'primary'} filled>
                {t('filter.done')}
              </Button>
            </Styled.FilterFooter>
          </>
        )}
      </Styled.FilterContainer>
    </Drawer>
  )
}

const FilterCard: React.FC<{ anchorReached: boolean; producerName?: string; producerLogo?: string }> = ({
  anchorReached,
  producerName,
  producerLogo,
}) => {
  const params = useParams<{ producerSlug: string; categorySlug: string; subCategorySlug: string }>()
  const [progress, attributes = []] = useFilterAttributes(params.producerSlug, params.subCategorySlug)
  if (progress === Progress.ERROR) {
    return <div />
  }

  return (
    <FilterCardContent
      anchorReached={anchorReached}
      producerName={producerName}
      attributes={attributes}
      producerLogo={producerLogo}
    />
  )
}

const EmptyListMessage = () => {
  const { t } = useTranslation('product')
  return (
    <Styled.EmptyListContainer>
      <Styled.EmptyListHeading>{t('list.emptyListHeading')}</Styled.EmptyListHeading>
      <Styled.EmptyListText dangerouslySetInnerHTML={{ __html: t('list.emptyListText') }} />
    </Styled.EmptyListContainer>
  )
}

const ItemsList: React.FC = () => {
  const params = useParams<{ producerSlug: string; categorySlug: string; subCategorySlug: string }>()
  const { pick } = useLangPicker()
  const nextProgress = useSelector(CardSelectors.listFetchNextProgress)
  const [progress, cards = []] = useCardList(params.producerSlug, params.subCategorySlug)

  usePersistentScroll('cardList')
  if (progress === Progress.ERROR) {
    return <ErrorLayout />
  }

  if ([Progress.WORK, Progress.IDLE].includes(progress)) {
    return (
      <Styled.SpinnerWrapper>
        <SpinnerLayout />
      </Styled.SpinnerWrapper>
    )
  }

  if (cards.length === 0 && progress !== Progress.IDLE) {
    return <EmptyListMessage />
  }

  return (
    <Styled.ListContainer>
      {cards.map(card => {
        const variety = pick(card?.subcategory?.title_i18n)
        return (
          <Card
            key={card.id}
            image={card.images[0]}
            variety={variety}
            slug={card.slug}
            title={pick(card.title_i18n)}
            description={pick(card.short_description_i18n)}
            discountText={pick(card.discount_text_i18n)}
            isNew={card.is_new ?? false}
            isLimited={card.is_limited ?? false}
            isOutOfStock={card.is_out_of_stock ?? false}
          />
        )
      })}
      {nextProgress === Progress.WORK ? (
        <Styled.SpinnerContainer>
          <SpinnerLayout />
        </Styled.SpinnerContainer>
      ) : null}
    </Styled.ListContainer>
  )
}

const CardList: React.FC = () => {
  const params = useParams<{ producerSlug: string; categorySlug: string; subCategorySlug: string }>()
  const generateCountryPath = useCountryPath()
  const [progress, subcategory, category, producer] = useComposedResourceHooks(
    [useSubcategory, [params.subCategorySlug]],
    [useCategory, [params.categorySlug]],
    [useProducer, [params.producerSlug]],
  )
  const producerMeta = useSelector(state => CardSelectors.meta(state, params.producerSlug))
  const { t } = useTranslation('discountBanner')
  const { pick } = useLangPicker()
  const [anchorReached, setAnchorReached] = useState(false)
  const hasNext = useSelector(CardSelectors.hasNext)
  const page = useSelector(CardSelectors.page)

  const listNextParams: CardListRequestParams = useMemo(
    () => ({
      sellerSlug: params.producerSlug,
      subCategorySlug: params.subCategorySlug,
      page,
    }),
    [page, params.producerSlug, params.subCategorySlug],
  )

  const getNextAction = useAction(CardActions.listRequestedNext, listNextParams)

  const fetchNextProgress = useSelector(CardSelectors.listFetchNextProgress)
  const promoInfo = useSelector(state =>
    CollectionSelectors.promoInfo(state, PromoInfoType.CatalogCategory, producer?.id, subcategory?.id),
  )
  const isMobile = useMatchMedia(queries.mobile)
  const isTablet = useMatchMedia(queries.tablet)

  const enterAnchorHandler = useCallback(() => {
    setAnchorReached(true)
    if (hasNext && fetchNextProgress !== Progress.WORK && fetchNextProgress !== Progress.ERROR) {
      getNextAction({ ...listNextParams, page: page + 1 })
    }
  }, [fetchNextProgress, getNextAction, hasNext, listNextParams, page])

  const leaveAnchorHandler = useCallback(() => {
    setAnchorReached(false)
  }, [])

  const { anchor } = useScrollTrigger(enterAnchorHandler, leaveAnchorHandler)

  const producerData = useMemo(() => {
    if (producer && producer.slug) {
      const producerName = producer.official_name || ''
      const category = subcategory
      return {
        name: producerName,
        title: `${producerName}${category ? ` - ${pick(category.title_i18n)}` : ''}`,
        path: generateCountryPath(Routes.CategoryList, { producerSlug: producer.slug }),
        description: producer.description || '',
        logo: producer.logo_url,
      }
    }
    return {}
  }, [producer, subcategory, pick, generateCountryPath])

  const breadcrumbs = useMemo(() => {
    const items: { path?: string; title: string }[] = []
    if (producerData.name && producerData.path) {
      items.push({
        path: producerData.path,
        title: producerData.name,
      })
    }

    if (category && producer?.slug) {
      items.push({
        title: category.title || '',
        path: generateCountryPath(Routes.SubCategoryList, {
          producerSlug: producer?.slug,
          categorySlug: category.slug,
        }),
      })
    }

    if (subcategory) {
      items.push({
        title: subcategory.title || '',
      })
    }

    return items
  }, [producerData.name, producerData.path, category, subcategory, producer?.slug, generateCountryPath])

  const promoInfoAnalyticData = {
    page: Page.ProductList,
    ...subcategoryToEventParams(producer, category, subcategory),
  }

  useHelmet({ title: producerData.title, description: producerData.description })

  const loading = () => (
    <Styled.SpinnerWrapper>
      <SpinnerLayout />
    </Styled.SpinnerWrapper>
  )
  const errorUnknown = () => <ErrorLayout />
  const error404 = () => <Layout404 />

  if (progress === Progress.WORK) {
    return loading()
  }

  if (!subcategory) {
    return error404()
  }

  if (progress === Progress.ERROR) {
    if (producerMeta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  const hasExclusiveGoods = producer?.config?.seller_config?.has_exclusive_goods

  return (
    <Styled.Wrapper>
      <Breadcrumbs items={breadcrumbs} />
      <Styled.MainContent>
        <Styled.SidebarBlock>
          <FilterCard
            anchorReached={anchorReached}
            producerName={producerData.name}
            producerLogo={producer?.logo_url}
          />
          {!(isMobile || isTablet) && hasExclusiveGoods && (
            <Styled.ReOrderFormContainer>
              <ReOrderForm isStorefront={producer?.sku_orders} />
            </Styled.ReOrderFormContainer>
          )}
        </Styled.SidebarBlock>
        <div>
          {promoInfo && (
            <Styled.DiscountBanner
              primaryText={promoInfo ? promoInfo.title : t('primary')}
              secondaryText={promoInfo ? promoInfo.description : t('secondary')}
              modalText={promoInfo && promoInfo.message ? promoInfo.message.description : t('modalText')}
              modalTitle={promoInfo && promoInfo.message ? promoInfo.message.title : ''}
              size={'large'}
              styles={promoInfo.styles}
              analyticData={promoInfoAnalyticData}
            />
          )}
          {(isMobile || isTablet) && hasExclusiveGoods && (
            <Styled.ReOrderFormContainer>
              <ReOrderForm isStorefront={producer?.sku_orders} />
            </Styled.ReOrderFormContainer>
          )}
          <ItemsList />
        </div>
        {anchor}
      </Styled.MainContent>
    </Styled.Wrapper>
  )
}

export default CardList

import { Progress, useAction, useDidMount } from '@agro-club/frontend-shared'
import { ResourceHook } from 'modules/types'
import { useSelector } from 'react-redux'
import ProducerActions from '../producer/duck'
import ProducerSelectors from '../producer/selectors'
import { Season, SeasonListRequestFilter } from './types'

export const useCurrentSeasonOnce: ResourceHook<Season | null, [SeasonListRequestFilter]> = (
  filter: SeasonListRequestFilter,
) => {
  const season = useSelector(state => ProducerSelectors.season(state, filter.companies_id as string))
  const progressSelector = useSelector(ProducerSelectors.seasonFetchProgress)
  const fetchAction = useAction(ProducerActions.seasonFetchRequested)
  const progress = season ? Progress.SUCCESS : progressSelector

  useDidMount(() => {
    if (!season || season.company_id !== filter.companies_id) {
      fetchAction(filter.companies_id as string)
    }
  })

  return [progress, season]
}

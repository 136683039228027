import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Modal as ModalComponent, useAction } from '@agro-club/frontend-shared'

import { CalculatedDiscountDto, GiftTierRule } from 'types/entities/discount'
import { Progress } from 'modules/types'
import { CartItem } from 'modules/domain/cart/types'
import { Product } from 'modules/domain/product/types'
import CartActions from 'modules/domain/cart/duck'
import ProductSelectors from 'modules/domain/product/selectors'
import { useGiftProductList } from 'modules/domain/product/hooks'
import GiftItem from 'views/components/GiftItem/GiftItem'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import ErrorLayout from 'views/layouts/ErrorLayout/ErrorLayout'

const Modal = styled(ModalComponent)`
  // TODO-999 consider extending Modal with bg-color & extra-large size
  max-width: 968px !important;
  background-color: #f1f3f5;
`
const ModalContent = styled.div`
  width: 100%;
  max-height: 60vh;
  display: grid;
  gap: 40px;
  overflow: auto;
`

const GiftListTitle = styled.h2`
  margin: 0 0 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
`

const GiftList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;
  list-style: none;

  ${props => props.theme.media.custom({ maxWidth: 820 })`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${props => props.theme.media.custom({ maxWidth: 600 })`
    grid-template-columns: 1fr;
  `}
`

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: stretch;
  flex-grow: 1;
  padding: 100px 0;
`

type Props = {
  giftDiscounts: CalculatedDiscountDto[]
  cartItems: CartItem[]
  isOpen: boolean
  onClose: () => void
}

const GiftPopup: React.FC<Props> = ({ giftDiscounts, cartItems, isOpen, onClose }) => {
  const { t } = useTranslation('gift')

  const allGiftProductIds = useMemo(
    () => [
      ...new Set(
        giftDiscounts
          .flatMap(d => d.rule.tiers_rules)
          .map(tr => tr as GiftTierRule)
          .flatMap(tr => tr.available_gifts),
      ),
    ],
    [giftDiscounts],
  )

  const [progress] = useGiftProductList({ productIds: allGiftProductIds })
  const giftDict = useSelector(ProductSelectors.giftDict)

  const addGiftAction = useAction(CartActions.fakeItemAddRequested)
  const removeGiftAction = useAction(CartActions.itemRemoveRequested)

  const handleAddGift = useCallback(
    (product: Product) => {
      addGiftAction(product, {
        qty: product.min_qty || 1,
        packaging: null,
      })
    },
    [addGiftAction],
  )

  const handleRemoveGift = useCallback(
    (productId: string) => {
      const cartItem = cartItems.find(cartItem => cartItem.product_id === productId)
      if (cartItem && !cartItem.is_fake) {
        removeGiftAction(cartItem.id)
      }
    },
    [cartItems, removeGiftAction],
  )

  if (progress === Progress.WORK) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} heading={t('modalHeading')} size="large">
        <ModalContent>
          <SpinnerWrapper>
            <SpinnerLayout />
          </SpinnerWrapper>
        </ModalContent>
      </Modal>
    )
  } else if (progress === Progress.ERROR) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} heading={t('modalHeading')} size="large">
        <ModalContent>
          <ErrorLayout />
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} heading={t('modalHeading')} size="large">
      <ModalContent>
        {giftDiscounts.map((discountRule: CalculatedDiscountDto) => {
          const discountRuleGiftIds = [
            ...new Set(discountRule.rule.tiers_rules.map(tr => tr as GiftTierRule).flatMap(tr => tr.available_gifts)),
          ]

          const discountRuleGifts = discountRuleGiftIds.filter(id => !!giftDict[id]).map(id => giftDict[id])

          const checkGiftAvailability = (giftId: string) => {
            const appliedTier = discountRule.tiers.find(t => t.is_applied)
            const availableGiftIds = (appliedTier?.tier_rule as GiftTierRule)?.available_gifts || []
            const giftsAddedToCart = cartItems.filter(cartItem => availableGiftIds.includes(cartItem.product_id))
            const isGiftQtyUnderLimit = (appliedTier?.tier_rule as GiftTierRule)?.limit > giftsAddedToCart.length
            return availableGiftIds.includes(giftId) && isGiftQtyUnderLimit
          }

          return (
            <div key={discountRule.rule.id || discountRule.rule._id}>
              <GiftListTitle>{discountRule.rule.campaign_name}</GiftListTitle>
              <GiftList>
                {discountRuleGifts.map(giftProduct => {
                  const isGiftAvailable = checkGiftAvailability(giftProduct.id)
                  const cartItem = cartItems.find(cartItem => cartItem.product_id === giftProduct.id)
                  return (
                    <li key={giftProduct.id}>
                      <GiftItem
                        loading={Boolean(cartItem?.is_fake)}
                        product={giftProduct}
                        onAddGiftClick={handleAddGift}
                        onRemoveGiftClick={handleRemoveGift}
                        isGiftAvailable={isGiftAvailable}
                        isGiftAdded={Boolean(cartItem && !cartItem?.is_fake)}
                      />
                    </li>
                  )
                })}
              </GiftList>
            </div>
          )
        })}
      </ModalContent>
    </Modal>
  )
}

export default GiftPopup

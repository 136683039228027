import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import * as Styled from 'views/pages/Profile/styled'
import AddressForm from 'views/components/AddressForm/AddressForm'
import UserInfoForm, { PersonalData } from 'views/components/UserInfoForm/UserInfoForm'
import { useTranslation } from 'react-i18next'
import IconButton from 'views/ui/IconButton/IconButton'
import {
  Button,
  IconExit,
  IconPencil,
  IconQuestion,
  Modal,
  SectionBody,
  SectionContainer,
  useAction,
  useFormManager,
} from '@agro-club/frontend-shared'
import useHelmet from 'hooks/useHelmet'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import AuthActions from 'modules/domain/auth/duck'
import { GdprType, GdprStatus } from 'modules/domain/auth/types'
import { Progress } from 'modules/types'
import { equals } from 'ramda'
import PartnerInfoForm from 'views/components/PartnerInfoForm/PartnerInfoForm'
import GdprSection from 'views/components/GdprSection/GdprSection'
import GdprEmailPopup from 'views/components/GdprEmailPopup/GdprEmailPopup'
import { Partner, ProfileAddress, UserProfile } from 'types/entities/userProfile'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useWLFeatureFlags from 'hooks/useWLFeatureFlags'
import { FeatureFlagModifiers, FieldLocation, FieldNames } from 'types/entities/config'
import { generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import { OrderProvince, OrderProvinceForm } from 'views/components/OrderProvince/OrderProvince'
import { ProfileEvents } from 'views/pages/Profile/events'
import { theme } from 'theme/theme'

const FormTitle = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin: 0;

  color: ${({ theme }) => theme.color.onPrimaryDark};

  ${props => props.theme.media.mobile`
    display: none;
  `}
`

const ButtonsWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: max-content max-content;
`

const LearnMoreWrapper = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  display: flex;
  color: ${props => props.theme.color.primary600};
  align-items: center;
  transition: opacity 0.2s ease-in;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  > span {
    margin-left: 5px;
  }
`

const SignOutButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation('common')
  return (
    <IconButton onClick={onClick} Icon={IconExit} iconColor={theme.color.accentApproving100} className="primaryAction">
      {t('signOut')}
    </IconButton>
  )
}

const EditButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation('common')
  return (
    <IconButton
      onClick={onClick}
      Icon={IconPencil}
      iconColor={theme.color.accentApproving100}
      className="primaryAction"
    >
      {t('edit')}
    </IconButton>
  )
}

const QuestionIcon = styled(IconQuestion)`
  fill: ${props => props.theme.color.primary600};
  width: 16px;
  height: 16px;
`

const CommonFarmHelp: React.FC = () => {
  const [opened, setOpened] = useState(false)
  const { t } = useTranslation('profile')
  return (
    <LearnMoreWrapper onClick={() => setOpened(!opened)}>
      <QuestionIcon />
      <span>{t('accountInformation.farmLearnMore')}</span>
      <Modal isOpen={opened} onClose={() => setOpened(false)}>
        {t('accountInformation.farmLearnMoreText')}
      </Modal>
    </LearnMoreWrapper>
  )
}

type FormikManagerData = {
  userInfo: Required<PersonalData>
  mailingAddress: ProfileAddress
  orderProvince: OrderProvinceForm
  'partner-0': Partner
  'partner-1': Partner
  'partner-2': Partner
  // and so on
}

const AccountInfoForm: React.FC = () => {
  const { t } = useTranslation(['profile', 'common'])
  const [editable, setEditable] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [defaultOrderProvinceId, setDefaultOrderProvinceId] = useState('')
  const formManager = useFormManager<FormikManagerData>()
  const signOutAction = useAction(AuthActions.signOutRequested)
  const updateProfile = useAction(AuthActions.userUpdateRequested)
  const gdprSend = useAction(AuthActions.gdprSendRequested)
  const gdprErase = useAction(AuthActions.gdprEraseRequested)
  const resetUserUpdateProgress = useAction(AuthActions.resetUserUpdateProgress)
  const defaultCountry = useDetectedCountry()
  const checkFeatureFlag = useWLFeatureFlags()

  const profile = useSelector(AuthSelectors.profile)
  const profileUpdateProgress = useSelector(AuthSelectors.profileUpdateProgress)
  const mailingAddress = useMemo(() => profile?.legal_address || { country: defaultCountry }, [profile, defaultCountry])
  const mainAddress = useMemo(() => profile?.delivery_address || { country: defaultCountry }, [profile, defaultCountry])
  const initialPartners = useMemo(() => {
    return profile && profile.partnership && profile.partnership.partners && profile.partnership.partners.length
      ? profile.partnership.partners
      : [{}]
  }, [profile])
  const [partners, setPartners] = useState(initialPartners)
  const { track } = useAnalyticsSSR()

  const handleCancelEditing = useCallback(() => {
    track(ProfileEvents.InfoEditCancelTap)
    setEditable(false)
    formManager.reset()
    setPartners(initialPartners)
  }, [track, formManager, initialPartners])

  const handleFormSubmit = useCallback(async () => {
    const [valid, forms] = await formManager.submitAll()
    if (!valid) {
      return
    }
    track(ProfileEvents.InfoEditSaveTap)
    setEditable(false)
    const partners = Object.keys(forms)
      .filter(key => {
        if (key.startsWith('partner')) {
          const item = forms[key as 'partner-0']
          return Object.values(item as Partner).some(Boolean)
        }
        return false
      })
      .map(key => forms[key as 'partner-0'])

    const { cheque_payee_name, email, ...userInfo } = forms.userInfo

    const updateInfo: Partial<UserProfile> = {
      ...userInfo,
      email: email || null,
      retailer_regions_ids: forms.orderProvince.retailer_regions_ids,
      legal_address: forms.mailingAddress,
      partnership: {
        cheque_payee_name: cheque_payee_name || '',
        partners: partners || null,
      },
    }
    if (equals(mailingAddress, mainAddress)) {
      updateInfo.delivery_address = forms.mailingAddress
    }
    updateProfile(updateInfo)
  }, [formManager, mailingAddress, mainAddress, updateProfile])

  useHelmet({ title: t('accountInformation.metaTitle') })

  useEffect(() => {
    if (profileUpdateProgress === Progress.SUCCESS) {
      setEditable(false)
      resetUserUpdateProgress()
    }
  }, [profileUpdateProgress, resetUserUpdateProgress])

  const handleAddPartnershipPerson = () => {
    setPartners([...partners, {}])
  }

  const handleCloseGdprEmailModal = useCallback(() => setModalOpen(false), [])
  const handleGdprSendWithoutEmailClick = useCallback(() => setModalOpen(true), [])
  const handleGdprSendClick = useCallback(
    email => {
      const gdprSendRequest = {
        type: GdprType.GET,
        status: GdprStatus.NEW,
        user_id: profile?.id,
        email: email,
        comment: '',
      }

      gdprSend(gdprSendRequest)
    },
    [profile?.id, gdprSend],
  )
  const handleGdprEraseClick = useCallback(() => {
    const gdprEraseRequest = {
      type: GdprType.DELETE,
      status: GdprStatus.NEW,
      user_id: profile?.id,
      email: profile?.email,
      comment: '',
    }

    gdprErase(gdprEraseRequest)
  }, [profile?.id, profile?.email, gdprErase])

  const handleAddressProvinceChange = (provinceId: string) => {
    setDefaultOrderProvinceId(provinceId)
  }

  const onChangeAddressFormCountry = (country: string) => {
    formManager.slots['mailingAddress'].setFieldValue('country', country)
  }

  if (!profile) {
    // TODO handle this
    return null
  }

  return (
    <>
      <SectionContainer noDivider>
        <Styled.HiddenOnMediaBlock hideMobile={true}>
          <Styled.SectionTitle>
            <FormTitle>{t('accountInformation.title')}</FormTitle>
            {!editable && (
              <ButtonsWrapper>
                <SignOutButton
                  onClick={() => {
                    track(ProfileEvents.SignoutTap)
                    signOutAction()
                  }}
                />
                <EditButton
                  onClick={() => {
                    track(ProfileEvents.InfoEditTap)
                    setEditable(true)
                  }}
                />
              </ButtonsWrapper>
            )}
          </Styled.SectionTitle>
        </Styled.HiddenOnMediaBlock>
        <SectionBody paddedOnMobile>
          <Styled.FormContent>
            <Styled.FormSection title={t('accountInformation.personalInfo')}>
              <UserInfoForm
                initialValues={{
                  country: profile.country || defaultCountry,
                  email: profile.email || '',
                  farm_name: profile.farm_name || '',
                  first_name: profile.first_name || '',
                  last_name: profile.last_name || '',
                  phone_number: profile.phone_number || '',
                  cheque_payee_name: profile.partnership?.cheque_payee_name || '',
                  tax_id: profile.tax_id || '',
                }}
                editable={editable}
                useFormik={formManager.bind('userInfo')}
                onChangeAddressFormCountry={onChangeAddressFormCountry}
              />
            </Styled.FormSection>
            <Styled.FormSection title={t('accountInformation.mailingAddress')}>
              <AddressForm
                initialValues={mailingAddress}
                editable={editable}
                useFormik={formManager.bind('mailingAddress')}
                onProvinceChange={handleAddressProvinceChange}
              />
            </Styled.FormSection>
            <OrderProvince
              initialValues={{
                retailer_regions_ids: profile.retailer_regions_ids || [],
                country: profile.country || defaultCountry,
              }}
              editable={editable}
              useFormik={formManager.bind('orderProvince')}
              defaultProvinceId={defaultOrderProvinceId}
            />
            {!checkFeatureFlag(
              generateFieldModifierString(
                FieldLocation.AccountForm,
                FieldNames.Partnership,
                FeatureFlagModifiers.Hidden,
              ),
            ) && (
              <Styled.FormSection title={t('accountInformation.commonFarm')} optional>
                <Styled.PartnershipInfo>
                  {t('partnership.info')}
                  <CommonFarmHelp />
                </Styled.PartnershipInfo>
                {partners.map((item, index) => {
                  return (
                    <PartnerInfoForm
                      initialValues={{
                        first_name: item.first_name || '',
                        last_name: item.last_name || '',
                        phone_number: item.phone_number || '',
                        farm_name: item.farm_name || '',
                      }}
                      key={index}
                      editable={editable}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore computed form keys
                      useFormik={formManager.bind(`partner-${index}`)}
                    />
                  )
                })}
                {editable && (
                  <Styled.PartnershipButton onClick={handleAddPartnershipPerson} type="button">
                    {t('partnership.addPerson')}
                  </Styled.PartnershipButton>
                )}
              </Styled.FormSection>
            )}
            {!checkFeatureFlag(
              generateFieldModifierString(FieldLocation.AccountForm, FieldNames.Gdpr, FeatureFlagModifiers.Hidden),
            ) && (
              <Styled.FormSection title={t('accountInformation.gdpr')}>
                <GdprSection
                  profileEmail={profile.email}
                  onGdprSendClick={handleGdprSendClick}
                  onGdprSendWithoutEmailClick={handleGdprSendWithoutEmailClick}
                  onGdprEraseClick={handleGdprEraseClick}
                />
              </Styled.FormSection>
            )}
          </Styled.FormContent>
          <Styled.HiddenOnMediaBlock hideTablet={true} hideDesktop={true}>
            <ButtonsWrapper style={{ marginTop: '40px', justifyContent: 'space-between' }}>
              <SignOutButton onClick={signOutAction} />
              {!editable && <EditButton onClick={() => setEditable(true)} />}
            </ButtonsWrapper>
          </Styled.HiddenOnMediaBlock>
          {editable && (
            <ButtonsWrapper style={{ marginTop: '40px' }}>
              <Button
                intent={'primary'}
                size={'big'}
                filled
                onClick={handleFormSubmit}
                disabled={!formManager.dirty}
                progress={profileUpdateProgress}
              >
                {t('common:saveChanges')}
              </Button>
              <Button intent={'cancel'} size={'big'} onClick={handleCancelEditing}>
                {t('common:cancel')}
              </Button>
            </ButtonsWrapper>
          )}
        </SectionBody>
      </SectionContainer>

      <GdprEmailPopup isOpen={modalOpen} onClose={handleCloseGdprEmailModal} onEmailSubmit={handleGdprSendClick} />
    </>
  )
}

export default AccountInfoForm

import {
  Button,
  Checkbox,
  Input,
  OptionalString,
  SectionContainer,
  SectionTitle,
  Switch,
  TextAreaWithTags,
  useAction,
} from '@agro-club/frontend-shared'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useCompanyFeatureFlags from 'hooks/useCompanyFeatureFlags'
import { useCountryPath } from 'hooks/useCountryPath'
import useLangPicker from 'hooks/useLangPicker'
import useMatchMedia from 'hooks/useMatchMedia'
import useWhiteLabelConfig from 'hooks/useWhiteLabelConfig'
import AuthActions from 'modules/domain/auth/duck'
import CartSelectors from 'modules/domain/cart/selectors'
import CartSkuActions from 'modules/domain/cartSku/duck'
import { useCartSkuList } from 'modules/domain/cartSku/hooks'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { CartSkuItem, UpdateCartSkuItemParams } from 'modules/domain/cartSku/types'
import { useProducer, useProducerList } from 'modules/domain/producer/hooks'
import { useProductOptionsList } from 'modules/domain/productOptions/hooks'
import { useCurrentSeasonOnce } from 'modules/domain/season/hooks'
import { Progress } from 'modules/types'
import { producerToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import { generateFieldAccessString, generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { queries } from 'theme/theme'
import { Company } from 'types/entities'
import { FeatureFlagModifiers, FieldLocation, FieldNames, Sections } from 'types/entities/config'
import { isPromocodeWithComment, isPromocodeWithLegalText } from 'types/entities/promocode'
import { CartEvents } from 'views/components/Cart/events'
import * as Styled from 'views/components/Cart/styles'
import { CartSkuList, MobileCartSkuList } from 'views/components/CartSkuList/CartSkuList'
import DiscountTotal from 'views/components/DiscountWidget/DiscountTotal'
import * as DiscountWidgetStyles from 'views/components/DiscountWidget/styled'
import Link from 'views/components/Link/Link'
import ErrorLayout from 'views/layouts/ErrorLayout/ErrorLayout'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import CartRoutes from 'views/pages/Cart/routes'
import { Routes } from 'views/pages/routes'
import { HowItWorksButton } from 'views/ui/HowItWorks/HowItWorks'
import { SkuDiscountBar } from '../DiscountWidget/SkuDiscountBar'
import UpdateErrorPopup from '../UpdateErrorPopup/UpdateErrorPopup'

const SeasonHint = styled.div`
  padding: 16px 0 0 24px;
  font-size: 12px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};

  ${props => props.theme.media.mobile`
     padding: 16px;
  `}
`

const PromocodeForm = styled.form`
  display: grid;
  grid-template-columns: 190px minmax(max-content, 90px);
  align-items: center;
  grid-gap: 8px;
  padding-bottom: 4px;
`
type PromocodeProps = {
  className?: string
  company_id: string
  producerSlug: string
  seller?: Company
}

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: stretch;
  flex-grow: 1;
  padding: 100px 0;
`

const PromocodeField: React.FC<PromocodeProps> = ({ className, company_id, producerSlug, seller }) => {
  const { t } = useTranslation(['promocode', 'common'])
  const promocodes = useSelector(state => CartSkuSelectors.promocodes(state, producerSlug))
  const promocodeStatus = useSelector(CartSkuSelectors.lastPromocodeStatus)
  const checkPromocodeProgress = useSelector(CartSkuSelectors.checkPromocodeProgress)
  const [code, setCode] = useState<string | undefined>()
  const checkPromocode = useAction(CartSkuActions.checkPromocode)
  const checked = useRef(false)
  const { track } = useAnalyticsSSR()

  const handleChange = useCallback(e => {
    setCode(e.target.value || undefined)
    checked.current = false
  }, [])

  useEffect(() => {
    if (checkPromocodeProgress === Progress.SUCCESS && promocodeStatus === 'valid') {
      setCode('')
    }
  }, [checkPromocodeProgress, promocodeStatus])

  const alreadyApplied = promocodes.some(item => item.code?.toLowerCase() === code?.toLowerCase())
  const handleApply = useCallback(
    e => {
      e.preventDefault()
      if (code && !alreadyApplied) {
        track(CartEvents.CartPromoCode, { code, ...producerToEventParams(seller) })
        checkPromocode({ code, company_id, producerSlug })
      }
      checked.current = true
    },
    [code, alreadyApplied, track, checkPromocode, company_id, seller, producerSlug],
  )

  const inputErrorText = alreadyApplied
    ? t('alreadyApplied')
    : promocodeStatus === 'discount_used'
    ? t('discountPromocodeUsed')
    : promocodeStatus === 'product_missing'
    ? t('productMissing')
    : t('invalidPromocode')
  const isInvalid =
    (['invalid', 'discount_used', 'product_missing'].includes(promocodeStatus) && checked.current) || alreadyApplied

  return (
    <PromocodeForm className={className} onSubmit={handleApply}>
      <Input
        data-test-id="promocode-input"
        onChange={handleChange}
        value={code}
        invalid={isInvalid}
        name={'promocode'}
        placeholder={t('placeholder')}
        errorText={inputErrorText}
      />
      <Button
        data-test-id="apply-promocode-button"
        intent={'primary-action'}
        filled={false}
        disabled={!code || checked.current}
        progress={checkPromocodeProgress}
        type={'submit'}
      >
        {t('common:apply')}
      </Button>
    </PromocodeForm>
  )
}

const PromocodeListWrapper = styled.div`
  min-width: 450px;
  ${props => props.theme.media.mobile`
    min-width: 100%;
  `}
`

const PromocodeList: React.FC<{ producerSlug: string }> = ({ producerSlug }) => {
  const promocodes = useSelector(state => CartSkuSelectors.promocodes(state, producerSlug))
  const selectedPromocode = useSelector(state => CartSkuSelectors.selectedPromocode(state, producerSlug))
  const selectEditablePromocodeAction = useAction(CartSkuActions.selectEditablePromocode)
  const updatePromocodeCommentAction = useAction(CartSkuActions.updatePromocodeComment)
  const removePromocodeAction = useAction(CartSkuActions.removePromocode)
  const handleRemove = (id: string) => {
    removePromocodeAction(producerSlug, id)
  }

  const items = promocodes.map(item => {
    let placeholder
    let value
    let readOnly = false
    let editable: boolean | undefined = false
    let selectable: boolean | undefined = false

    if (isPromocodeWithComment(item)) {
      placeholder = item.params.prompt || undefined
      editable = !!item.params.prompt
      value = item.comment || undefined
      selectable = true
    }

    if (isPromocodeWithLegalText(item)) {
      value = item.params.legal_text || undefined
      readOnly = true
      selectable = true
    }
    return {
      id: item.code,
      title: item.code,
      placeholder,
      editable,
      value,
      selectable,
      readonly: readOnly,
    }
  })

  // select first editable, readonly or selected
  const selected =
    selectedPromocode ||
    promocodes.find(item => {
      if (isPromocodeWithComment(item)) {
        return !!item.params.prompt
      }
      if (isPromocodeWithLegalText(item)) {
        return !!item.params.legal_text
      }
    })?.code

  const handleChangePromocode = useCallback(
    (code: string): void => {
      if (selected !== code) {
        selectEditablePromocodeAction(producerSlug, code)
      }
    },
    [producerSlug, selectEditablePromocodeAction, selected],
  )

  const handleUpdatePromocodeComment = useCallback(
    (id: string, text: string): void => {
      updatePromocodeCommentAction(producerSlug, id, text)
    },
    [producerSlug, updatePromocodeCommentAction],
  )

  if (promocodes.length < 1) {
    return null
  }

  return (
    <PromocodeListWrapper data-test-id="promocode-textarea">
      <TextAreaWithTags
        items={items}
        selected={selected}
        onTextChange={handleUpdatePromocodeComment}
        onSelectionChange={handleChangePromocode}
        onRemove={handleRemove}
      />
    </PromocodeListWrapper>
  )
}

type DiscountBarProps = {
  producerSlug: string
  seller?: Company
  onAddQty: (id: string, qty: number) => void
  cartItems: CartSkuItem[]
}

// todo sku discounts
export const DiscountBar: React.FC<DiscountBarProps> = ({ onAddQty, seller, producerSlug, cartItems }) => {
  const discounts = useSelector(state => CartSkuSelectors.discounts(state, producerSlug))
  const monetaryDiscounts = discounts

  const isMobile = useMatchMedia(queries.mobile)

  const handleAddQty = useCallback(
    (qty: number, cartItem?: CartSkuItem) => {
      cartItem && onAddQty(cartItem.id, Number(cartItem?.quantity ?? 0) + qty)
    },
    [onAddQty],
  )

  return (
    <DiscountWidgetStyles.WidgetContainer>
      {!isMobile && <DiscountTotal producer={seller} />}
      <SkuDiscountBar
        discounts={monetaryDiscounts}
        onApply={handleAddQty}
        currency={seller?.currency}
        cartItems={cartItems}
      />
    </DiscountWidgetStyles.WidgetContainer>
  )
}

const Spacer = styled.div`
  flex-grow: 1;
`

const HowItWorksButtonStyled = styled(HowItWorksButton)`
  margin-right: 12px;
`

const CartSku = () => {
  const { t } = useTranslation(['cart', 'common'])
  // const lang = i18n.language.slice(0, 2)
  const { producerSlug } = useParams<{ producerSlug: string }>()
  const generateCountryPath = useCountryPath()
  const [, seller] = useProducer(producerSlug)
  const [, producerList] = useProducerList()
  const isMobile = useMatchMedia(queries.mobile)
  const [progress, items = []] = useCartSkuList(producerSlug)
  const pendingEntries = useSelector(state => CartSkuSelectors.cartPendingEntriesBySellerSlug(state, producerSlug))
  const creditOfferAccepted = useSelector(state => CartSkuSelectors.creditOfferAccepted(state, producerSlug))
  const setCreditOfferAccepted = useAction(CartSkuActions.setCreditOfferAccepted)
  const hasAccess = useCompanyFeatureFlags(producerSlug)
  const [, productOptions] = useProductOptionsList()
  const [seasonProgress, season] = useCurrentSeasonOnce({ companies_id: seller?.id })

  const farmerCommentValue = useRef({})
  const setFarmerCommentAction = useAction(CartSkuActions.setFarmerComment)
  const savedFarmerOrder = useSelector(state => CartSkuSelectors.farmerComment(state, producerSlug))
  const [farmerComment, setFarmerComment] = useState({ [producerSlug]: savedFarmerOrder })

  const showFCC = useMemo(() => {
    const fccAllowed = items.some(item => !!item.card?.additional_options?.credit_offer_allowed)
    return fccAllowed
  }, [items])

  const isQtyDisabled = hasAccess(
    generateFieldModifierString(FieldLocation.CartList, FieldNames.Qty, FeatureFlagModifiers.Disabled),
  )
  const { track } = useAnalyticsSSR()
  const history = useHistory()

  const cartInfo = useSelector(CartSelectors.cartInfo)
  const cartSkuInfo = useSelector(CartSkuSelectors.cartInfo)
  const termsAgreed = useSelector(CartSkuSelectors.termsAgreed)
  const itemRemoveAction = useAction(CartSkuActions.itemRemoveRequested)
  const itemUpdateAction = useAction(CartSkuActions.itemUpdateRequested)
  const setTermsAgreed = useAction(CartSkuActions.setTermsAgreed)
  const { pick } = useLangPicker()

  const { config: wlConfig } = useWhiteLabelConfig()
  // const config = wlConfig?.legal_docs

  const isOrderButtonDisabled = useMemo(() => {
    return !termsAgreed || items.some(item => !item.sku)
  }, [items, termsAgreed])

  const { truck_info, how_it_works } = { ...seller?.config?.seller_config }

  const howItWorksItems = useMemo(() => {
    const companyId = wlConfig?.company_id
    const howItWorksFromCompany = producerList?.find(item => item.id === companyId)
    return how_it_works || howItWorksFromCompany?.config?.seller_config?.how_it_works || []
  }, [wlConfig?.company_id, producerList, how_it_works])

  const promocodeIsHidden = hasAccess([
    generateFieldAccessString(Sections.Cart, FieldNames.Promocode, FeatureFlagModifiers.Hidden),
  ])

  const setTermsAgreedInStepLogin = useAction(AuthActions.setTermsAgreed)
  const handleTermsAgreed = useCallback(
    (_, isChecked) => {
      if (isChecked) {
        track(CartEvents.TermsAccepted, { accepted: true, ...producerToEventParams(seller) })
      } else {
        track(CartEvents.TermsAccepted, { accepted: false, ...producerToEventParams(seller) })
      }
      setTermsAgreed(isChecked)
      setTermsAgreedInStepLogin(isChecked)
    },
    [seller, setTermsAgreed, setTermsAgreedInStepLogin, track],
  )

  const handleUpdate = useCallback(
    (id: string, params: UpdateCartSkuItemParams) => {
      itemUpdateAction(id, params)
    },
    [itemUpdateAction],
  )

  const handleQtyUpdate = useCallback(
    (id: string, value?: number) => {
      if (seller) {
        handleUpdate(id, { quantity: OptionalString(value), seller_id: seller.id })
      }
    },
    [handleUpdate, seller],
  )

  const handleRemove = useCallback(
    (id: string) => {
      itemRemoveAction(id)
    },
    [itemRemoveAction],
  )

  const handleCheckout = useCallback(() => {
    if (!termsAgreed) return
    const analyticData = {
      quantity: items.reduce((acc: number, item) => (acc += Number(item.quantity)), 0),
      ...producerToEventParams(seller),
    }

    setFarmerCommentAction(producerSlug, farmerComment[producerSlug])
    track(CartEvents.ProceedToCheckout, analyticData)
    const path = generateCountryPath(Routes.CheckoutProducer, { producerSlug })
    history.push(path)
  }, [
    generateCountryPath,
    history,
    items,
    seller,
    producerSlug,
    termsAgreed,
    track,
    setFarmerCommentAction,
    farmerComment,
  ])

  useEffect(
    () => () => {
      if (farmerCommentValue.current[producerSlug]) {
        setFarmerCommentAction(producerSlug, farmerCommentValue.current[producerSlug])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const handleFarmerCommentChange = comment => {
    setFarmerComment({ ...farmerComment, [producerSlug]: comment })
    farmerCommentValue.current = { ...farmerComment, [producerSlug]: comment }
  }

  // const termsOfUse = useMemo(() => {
  //   return `
  //     <a href="${
  //       config?.user_agreement ? pick(config.user_agreement) : `/agreement_${lang}.pdf`
  //     }" target="_blank" rel="noopener noreferrer">${t('cart:termsOfUse')}</a>
  //   `
  // }, [lang, t, config?.user_agreement, pick])

  //config?.privacy_policy ? pick(config.privacy_policy) : `/privacy-policy_${lang}.pdf`
  const privacyPolicy = useMemo(() => {
    return `
      <a href="https://canterra.com/privacy-policy/" target="_blank" rel="noopener noreferrer">${t(
        'cart:privacyPolicy',
      )}</a>
    `
  }, [t])

  const continueShoppingUrl = useMemo(
    () =>
      producerSlug ? generateCountryPath(Routes.CategoryList, { producerSlug }) : generateCountryPath(Routes.Root),
    [generateCountryPath, producerSlug],
  )

  const cartsInfo = useMemo(() => ({ ...cartInfo, ...cartSkuInfo }), [cartInfo, cartSkuInfo])

  if ((!producerSlug || (producerSlug && !cartsInfo[producerSlug])) && Object.keys(cartsInfo).length) {
    const producerSlug = Object.keys(cartsInfo)[0]
    return <Redirect to={generateCountryPath(CartRoutes.ProducerCart, { producerSlug })} />
  }

  if (progress === Progress.WORK || progress === Progress.IDLE || (!seller && producerSlug)) {
    return (
      <SpinnerWrapper>
        <SpinnerLayout />
      </SpinnerWrapper>
    )
  }

  if (progress === Progress.ERROR) {
    return <ErrorLayout />
  }

  return (
    <Styled.Wrapper>
      <SectionContainer noDivider>
        <SectionTitle>
          {t('title')}
          <Spacer />
          <HowItWorksButtonStyled
            items={howItWorksItems}
            onlyIcon={isMobile}
            onClick={() => track(CartEvents.CartHowItWorks, seller ? producerToEventParams(seller) : {})}
          />
          <Link to={continueShoppingUrl}>
            <Button
              intent={'primary-action'}
              filled={false}
              size={'medium'}
              style={{ border: 'none' }}
              onClick={() => track(CartEvents.CartContinueShopping, seller ? producerToEventParams(seller) : {})}
            >
              {t('continueShopping')}
            </Button>
          </Link>
        </SectionTitle>
        <SeasonHint>
          {t('common:season')}: {seasonProgress === Progress.SUCCESS && season?.title}
        </SeasonHint>
        {isMobile && <DiscountTotal producer={seller} />}
        <Styled.Container paddedOnMobile>
          {isMobile ? (
            <MobileCartSkuList
              data={items}
              seller={seller as Company}
              onQtyChange={handleQtyUpdate}
              onRemove={handleRemove}
              emptyText={t('empty')}
              productOptions={productOptions || []}
              pendingData={pendingEntries}
              isQtyDisabled={isQtyDisabled}
            />
          ) : (
            <CartSkuList
              data={items}
              seller={seller as Company}
              onQtyChange={handleQtyUpdate}
              onRemove={handleRemove}
              emptyText={t('empty')}
              productOptions={productOptions || []}
              pendingData={pendingEntries}
              isQtyDisabled={isQtyDisabled}
            />
          )}
          {!!items.length && (
            <>
              <Styled.CommentWrapper>
                <Styled.CommentInput
                  placeholder={t('enterAComment')}
                  onChange={(_, comment) => handleFarmerCommentChange(comment)}
                  value={farmerComment[producerSlug]}
                />
              </Styled.CommentWrapper>
            </>
          )}
        </Styled.Container>
        {items.length ? (
          <Styled.Footer>
            {!!truck_info && !!pick(truck_info.title_i18n) && (
              <Styled.TruckText>
                {pick(truck_info.title_i18n)}
                {!!truck_info.learn_more_url && (
                  <Styled.TruckLearnMore href={truck_info.learn_more_url} target={'blank'}>
                    {t('common:learnMore')}
                  </Styled.TruckLearnMore>
                )}
              </Styled.TruckText>
            )}
            {!!truck_info && !!pick(truck_info.description_i18n) && (
              <Styled.TruckDescription>{pick(truck_info.description_i18n)}</Styled.TruckDescription>
            )}
            {seller && !promocodeIsHidden && (
              <>
                <PromocodeField company_id={items[0].seller_id} producerSlug={producerSlug} seller={seller} />
                <PromocodeList producerSlug={producerSlug} />
              </>
            )}
            {showFCC &&
              (producerSlug != 'pitura-seeds' ? (
                <Styled.SwitchContainer>
                  <Switch
                    name={'credit-offer-switch'}
                    on={creditOfferAccepted}
                    onClick={val => {
                      track(CartEvents.ServiceOffer, {
                        offer_type: 'credit_offer',
                        value: val,
                        ...producerToEventParams(seller),
                      })
                      setCreditOfferAccepted(producerSlug, val)
                    }}
                  />

                  <Styled.SwitchText>
                    {t('wantToJoinProgram', { company: seller?.official_name })}
                    <Styled.FCCLogo />
                  </Styled.SwitchText>
                </Styled.SwitchContainer>
              ) : (
                <Styled.SwitchContainer>
                  <Switch
                    name={'credit-offer-switch'}
                    on={creditOfferAccepted}
                    onClick={val => {
                      track(CartEvents.ServiceOffer, {
                        offer_type: 'credit_offer',
                        value: val,
                        ...producerToEventParams(seller),
                      })
                      setCreditOfferAccepted(producerSlug, val)
                    }}
                  />
                  <Styled.SwitchText>{t('wantToJoinPituraProgram')}</Styled.SwitchText>
                </Styled.SwitchContainer>
              ))}
            <Styled.SwitchContainer data-test-id={'agreement-checkbox-wrapper'}>
              <Checkbox isChecked={termsAgreed} onChange={handleTermsAgreed} id={'terms_of_use'} />
              <Styled.Terms dangerouslySetInnerHTML={{ __html: t('agreeWithTerms', { privacyPolicy }) }} />
            </Styled.SwitchContainer>
            {items.length ? (
              <Styled.OrderButtonWrapper data-test-id={'checkout-button-wrapper'}>
                <Styled.OrderButton
                  type={'submit'}
                  disabled={isOrderButtonDisabled}
                  size={'big'}
                  intent={'primary-action'}
                  filled
                  id={'checkout-button'}
                  onClick={handleCheckout}
                >
                  {t('common:order')}
                </Styled.OrderButton>
                <Styled.OrderButtonHint>{t('cart:orderHint')}</Styled.OrderButtonHint>
              </Styled.OrderButtonWrapper>
            ) : null}
          </Styled.Footer>
        ) : null}
      </SectionContainer>
      <DiscountBar seller={seller} producerSlug={producerSlug} onAddQty={handleQtyUpdate} cartItems={items} />
      <UpdateErrorPopup />
    </Styled.Wrapper>
  )
}

export default CartSku

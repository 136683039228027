import { controlStyle, SimpleSelect, CustomSelectStyles } from '@agro-club/frontend-shared'
import styled, { StyledProps } from 'styled-components'

export const WidgetSectionWrapper = styled.div`
  margin-top: 16px;
`

export const CartSectionWrapper = styled.div`
  display: flex;
  margin-top: 5px;
`

export const CheckoutSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;

  &:not(:first-child) {
    margin-top: 4px;
  }
`

export const ConfirmationSectionWrapper = styled.div``

export const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
    padding-right: 0;
  }
`

export const MobileLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: ${(props: StyledProps<{}>) => props.theme.color.onSurfaceMidEmphasys};

  ${props => props.theme.media.tablet`
  font-size: 12px;
  line-height: 16px;
`}
`

export const ConfirmationLabel = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

export const ConfirmationTitle = styled.span`
  font-size: 12px;
`

export const WidgetSectionTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${props => props.theme.color.secondary200};
`

export const CartSectionLabel = styled.div`
  display: flex;
  width: 60px;
  margin-right: 16px;
  padding-left: 13px;

  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

export const CheckoutSectionLabel = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

export const CheckoutSelect = styled(SimpleSelect)`
  width: 160px;
  max-height: 32px;
`

export const CartSelect = styled(SimpleSelect)`
  width: 160px;
`

export const controlStyleFn = (base, props) => ({
  ...controlStyle(base, props),
  maxWidth: '160px',
  minHeight: '32px',
  height: '32px',
})

export const placeholderStyleFn = base => ({
  ...base,
  position: 'absolute',
  maxWidth: 'calc(100% - 10px)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

export const inputStyleFn = base => ({
  ...base,
  paddingTop: '0',
  margin: '0',
})

export const customSelectStyles: CustomSelectStyles = {
  placeholder: placeholderStyleFn,
  input: inputStyleFn,
}

export const selectCustomStyles: CustomSelectStyles = {
  control: controlStyleFn,
  placeholder: placeholderStyleFn,
  input: inputStyleFn,
}

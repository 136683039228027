import React, { FC, useEffect, useState } from 'react'
import { ProductWizardType, useAction } from '@agro-club/frontend-shared'
import CartActions from 'modules/domain/cart/duck'
import { useSelector } from 'react-redux'
import CartSelectors from 'modules/domain/cart/selectors'
import { AdderWidget } from './AdderWidget'
import { Card } from 'modules/domain/card/types'
import { CardWizardProcote } from './CardWizardProcote/CardWizardProcote'

type PropsType = {
  card: Card
  isMobile: boolean
}
export const CardWizardManager: FC<PropsType> = ({ card, isMobile }: PropsType) => {
  const requestProductWizard = useAction(CartActions.productWizardRequested)
  const productWizard = useSelector(CartSelectors.productWizard)
  const progress = useSelector(CartSelectors.productWizardProgress)
  const [openedProductWizard, setOpenedProductWizard] = useState(false)

  const handleWizardOpen = () => {
    setOpenedProductWizard(true)
  }
  const handleWizardClose = () => {
    setOpenedProductWizard(false)
  }

  useEffect(() => {
    requestProductWizard(card.wizard_id, { sort_field: 'order' })
  }, [card.wizard_id, requestProductWizard])

  const procoteJSX =
    productWizard?.[0]?.type === ProductWizardType.Procote ? (
      <CardWizardProcote
        opened={openedProductWizard}
        onClose={handleWizardClose}
        isMobile={isMobile}
        productWizard={productWizard[0]}
        wizardCard={card}
      />
    ) : null

  return (
    <>
      {procoteJSX}
      <AdderWidget
        card={card}
        handleWizardOpen={handleWizardOpen}
        productWizardType={productWizard?.[0]?.type}
        progress={progress}
      />
    </>
  )
}

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import GiftImage from 'views/components/GiftWidget/gifts.png'
import { DiscountRule } from 'types/entities/discount'
import useLangPicker from 'hooks/useLangPicker'
import { toShortFullYear } from 'helpers/date'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import ProductItemEvents from 'views/pages/Producer/Product/ProductItem/events'
import { Product } from 'modules/domain/product/types'
import { productToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  background-color: ${props => props.theme.color.onPrimaryLight};
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  font-family: Montserrat;
`

const Image = styled.img`
  position: absolute;
  top: -8px;
  left: calc(50% - 131px); // half of max-width
  display: block;
  max-width: 262px;
  width: 100%;
`

const Inner = styled.div`
  padding: 136px 16px 16px;
`

const Title = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: ${({ theme }) => theme.color.onPrimaryDark};

  ${props => props.theme.media.mobile`
    font-size: 14px;
    line-height: 20px;
  `}
`

const Description = styled.p`
  margin: 8px 0 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
`

const EndDate = styled.p`
  margin: 8px 0 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
`

const LearnMore = styled.a`
  display: block;
  margin: 8px 0 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
`

type Props = {
  campaign: DiscountRule
  product: Product
}

const GiftCampaignInfo: React.FC<Props> = ({ campaign, product }) => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation('discount')
  const { pick } = useLangPicker()
  const endDate = toShortFullYear(campaign.end_date) || '-'

  return (
    <Wrapper>
      <Image src={GiftImage} />
      <Inner>
        <Title>{campaign.campaign_name}</Title>
        <Description>{t('giftCampaignDescription')}</Description>
        <EndDate>{t('campaignEnds', { endDate })}</EndDate>
        {!!pick(campaign.link_url_i18n) && (
          <LearnMore
            href={pick(campaign.link_url_i18n)}
            target="_blank"
            onClick={() =>
              track(ProductItemEvents.ProductItemGiftCampaignLearnMore, {
                ...productToEventParams(product),
                campaign_id: campaign.id || campaign._id,
                campaign_name: campaign.campaign_name,
              })
            }
          >
            {pick(campaign.link_label_i18n) || t('common:learnMore')}
          </LearnMore>
        )}
      </Inner>
    </Wrapper>
  )
}

export default GiftCampaignInfo

import React, { useCallback, useEffect, useMemo } from 'react'
import { CellProps, useTable } from 'react-table'
import { useTranslation } from 'react-i18next'
import useMatchMedia from 'hooks/useMatchMedia'
import { queries } from 'theme/theme'
import { useCartSkuList } from 'modules/domain/cartSku/hooks'
import { CartSkuItem } from 'modules/domain/cartSku/types'
import useLangPicker from 'hooks/useLangPicker'
import { getPrettyPrice } from 'modules/utils/helpers'
import { Company, Currency } from 'types/entities'
import {
  IconWarning,
  SectionTable,
  SectionTableBody,
  SectionTableBodyCell,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableBodyRow,
  SectionTableHeadRow,
  ProcoteWizardData,
  ProductWizardType,
} from '@agro-club/frontend-shared'
import { useParams } from 'react-router'
import useCompanyFeatureFlags from 'hooks/useCompanyFeatureFlags'
import { generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import { FeatureFlagModifiers, FieldLocation, FieldNames } from 'types/entities/config'
import { useProductOptionsList } from 'modules/domain/productOptions/hooks'
import { ProductOptions as ProductOptionsType } from 'modules/domain/productOptions/types'
import * as Styled from '../OrderPreviewTable/styled'
import useSkuOptions from 'hooks/useSkuOptions'
import { WizardComment } from '../WizardNote/WizardComment'
import { BaseUnits } from 'views/pages/Producer/Card/CardItem/components/BaseUnits'
import { Sku } from 'modules/domain/sku/types'

const ProductCell: React.FC<CellProps<CartSkuItem> & {
  productOptions: ProductOptionsType[]
}> = ({ row, productOptions }) => {
  const { pick } = useLangPicker()
  const options = useSkuOptions(row.values.sku.params.options, productOptions)
  const { t } = useTranslation('checkout')

  return (
    <Styled.ProductCell>
      <Styled.ProductCellText>
        <Styled.BoldText>{pick(row.values.title)}</Styled.BoldText>
        <div>
          <Styled.ProductOption key={row.original.sku?.params.package_title}>
            {t('confirmationForm.packageType')}: {row.original.sku?.params.package_title}
          </Styled.ProductOption>
          {options.map(o => (
            <Styled.ProductOption key={o.id}>
              {o.type}: {o.title} {!!o.params?.percentage ? `(${o.params?.percentage}%)` : null}
            </Styled.ProductOption>
          ))}
        </div>
        {row.original.wizard_comment && !row.original.wizard_data && (
          <WizardComment note={row.original.wizard_comment} />
        )}
        {row.original.wizard_data?.type === ProductWizardType.Procote ? (
          <ProcoteWizardData data={row.original.wizard_data} />
        ) : (
          <Styled.PackSize>{pick(row.original.default_packaging)}</Styled.PackSize>
        )}
        {row.original.card?.docusign_document_id && row.original.sku?.product?.legal_notes ? (
          <Styled.LegalNotes
            visibleOnHover={true}
            tooltipContent={
              <Styled.LegalNotesTooltipContent>
                {row.original.sku.product?.legal_notes.description}
              </Styled.LegalNotesTooltipContent>
            }
            temporary={true}
          >
            <IconWarning />
            {row.original.sku.product?.legal_notes.text}
          </Styled.LegalNotes>
        ) : null}
      </Styled.ProductCellText>
    </Styled.ProductCell>
  )
}

const StandardUnitsCell: React.FC<CellProps<CartSkuItem>> = ({ row }) => {
  const { sku, quantity: qty } = { ...row.original }
  return <BaseUnits qty={Number(qty)} sku={sku} />
}

const StandardUnitsCellMobile: React.FC<{ qty: number; sku: Sku }> = ({ qty, sku }) => {
  return (
    <Styled.BoldText>
      <BaseUnits qty={qty} sku={sku} />
    </Styled.BoldText>
  )
}

const Qty: React.FC<{ value: string; packageType: string }> = ({ value, packageType }) => {
  return (
    <Styled.QtyCell>
      {Number(Number(value).toFixed(1))} {packageType}
    </Styled.QtyCell>
  )
}

const QtyCell: React.FC<CellProps<CartSkuItem>> = ({ row }) => {
  if (row?.values.card.category.slug === 'duck-foot') return null
  return <Qty value={row.original.quantity} packageType={row?.original.sku?.params.package_title || ''} />
}

const Cost: React.FC<{
  currency: Currency
  priceType: string
  cost?: string
  price?: string | number
  slug?: string
}> = ({ cost, price, currency, slug, priceType }) => {
  const formatPrice = useCallback((price: string) => getPrettyPrice(price, currency), [currency])
  if (!cost) {
    return null
  }
  return (
    <Styled.Cost>
      <div>{formatPrice(cost)}</div>
      {price && slug !== 'duck-foot' && <div>{getPrettyPrice(price, currency || Currency.CAD, priceType)}</div>}
    </Styled.Cost>
  )
}

const CostCell: React.FC<CellProps<CartSkuItem> & { producer: Company }> = ({ row, producer }) => {
  return (
    <Cost
      cost={row.values.cost}
      price={row.values.sku.price}
      currency={producer.currency}
      slug={row.values.card.category.slug}
      priceType={row.values.sku.price_type}
    />
  )
}

const DiscountCell: React.FC<CellProps<CartSkuItem> & { producer: Company }> = ({ value, row, producer }) => {
  const formatPrice = useCallback(
    (price: number) => getPrettyPrice(price, producer.currency, row.values.sku.price_type),
    [producer.currency, row.values.sku.price_type],
  )
  return (
    <Styled.Cost>
      <div>{formatPrice(value)}</div>
    </Styled.Cost>
  )
}

const DesktopOrderPreviewTable: React.FC<{ producer?: Company }> = ({ producer }) => {
  const { t } = useTranslation('components')
  const { producerSlug } = useParams<{ producerSlug: string }>()
  const [, itemsList = []] = useCartSkuList(producerSlug)
  const [, productOptions] = useProductOptionsList()

  const hasAccess = useCompanyFeatureFlags(producerSlug)
  const showUnits = hasAccess(
    generateFieldModifierString(FieldLocation.CartList, FieldNames.Units, FeatureFlagModifiers.Enabled),
  )
  const allColumns = useMemo(
    () => [
      {
        Header: t('OrderPreviewTable.list.product'),
        accessor: 'title' as const,
        Cell: props => <ProductCell productOptions={productOptions} producer={producer} {...props} />,
      },
      {
        Header: t('OrderPreviewTable.list.cost'),
        accessor: 'cost' as const,
        Cell: props => <CostCell producer={producer} {...props} />,
      },
      {
        Header: t('OrderPreviewTable.list.discount'),
        accessor: 'discount_amount' as const,
        Cell: props => <DiscountCell producer={producer} {...props} />,
      },
      {
        Header: t('OrderPreviewTable.list.standardUnits'),
        Cell: StandardUnitsCell,
      },
      {
        Header: t('OrderPreviewTable.list.qty'),
        accessor: 'quantity' as const,
        Cell: QtyCell,
      },
      {
        Header: 'description',
        accessor: 'description' as const,
      },
      {
        Header: 'id',
        accessor: 'id' as const,
      },
      {
        Header: '',
        accessor: 'sku' as const,
      },
      {
        Header: '',
        accessor: 'card' as const,
      },
      {
        Header: '',
        accessor: 'seller' as const,
      },
      {
        Header: '',
        accessor: 'wizard_comment' as const,
      },
    ],
    [t, productOptions, producer],
  )

  const someProductHasPrice = itemsList.some(item => !!item.sku?.price)
  const someEntryHasDiscount = itemsList.some(item => !!parseFloat(item.discount_amount || ''))
  const isOnlyDuckFoot = useMemo(() => {
    return itemsList.every(item => item.card?.category?.slug === 'duck-foot')
  }, [itemsList])

  const hiddenColumns = useMemo(
    () =>
      [
        'description',
        'id',
        'sku',
        'card',
        'seller',
        'wizard_comment',
        !someProductHasPrice && 'cost',
        !someEntryHasDiscount && 'discount_amount',
        isOnlyDuckFoot && 'quantity',
      ].filter(Boolean) as string[],
    [someProductHasPrice, someEntryHasDiscount, isOnlyDuckFoot],
  )
  const { columns, rows, prepareRow, setHiddenColumns } = useTable<CartSkuItem>({
    data: itemsList,
    columns: allColumns,
    initialState: { hiddenColumns },
  })
  useEffect(() => {
    setHiddenColumns(hiddenColumns)
  }, [hiddenColumns, setHiddenColumns])
  return (
    <SectionTable>
      <SectionTableHead>
        <SectionTableHeadRow>
          {columns.map(column => {
            if (hiddenColumns.includes(column.id)) {
              return null
            }
            return (
              <SectionTableHeadCell
                key={column.getHeaderProps().key}
                textAlign={['qty', 'cost', 'discount_amount'].includes(column.id) ? 'center' : 'left'}
              >
                {column.render('Header')}
              </SectionTableHeadCell>
            )
          })}
        </SectionTableHeadRow>
      </SectionTableHead>
      <SectionTableBody data-test-id={'order-preview-table'}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <SectionTableBodyRow key={row.getRowProps().key}>
              {row.cells.map(cell => {
                return (
                  <SectionTableBodyCell key={cell.getCellProps().key}>
                    {cell.render('Cell', { showUnits })}
                  </SectionTableBodyCell>
                )
              })}
            </SectionTableBodyRow>
          )
        })}
      </SectionTableBody>
    </SectionTable>
  )
}

const MobileOrderPreviewTable: React.FC<{ producer?: Company }> = ({ producer }) => {
  const { t } = useTranslation(['components', 'common'])
  const { pick } = useLangPicker()
  const { producerSlug } = useParams<{ producerSlug: string }>()
  const [, itemsList = []] = useCartSkuList(producerSlug)
  const formatPrice = useCallback(
    (price: number | string) => getPrettyPrice(price, producer?.currency || Currency.CAD),
    [producer],
  )

  const [, productOptions = []] = useProductOptionsList()

  const qtyJSX = (item: CartSkuItem) => (
    <Styled.MobileBlock>
      <Styled.MobileLabel>{t('OrderPreviewTable.list.quantity')}</Styled.MobileLabel>
      {item?.card?.category?.slug !== 'duck-foot' && item.sku && (
        <Qty value={item.quantity} packageType={item.sku.params.package_title} />
      )}
    </Styled.MobileBlock>
  )

  const skuOptions = (item: CartSkuItem) => {
    const data = item.sku?.params.options || []
    const options = data
      .map(({ option_id, params }) => {
        const option = productOptions.find(opt => opt.id === option_id)
        return {
          id: option?.id,
          type: t(`productOptions:types.${option?.type}`),
          title: pick(option?.title_i18n),
          params,
        }
      })
      .filter(o => !!o.title)
    return options.map(o => (
      <Styled.MobileBlock key={o.id}>
        <Styled.MobileLabel>{o.type}:</Styled.MobileLabel> <Styled.BoldText>{o.title}</Styled.BoldText>{' '}
        {!!o.params?.percentage ? `(${o.params?.percentage}%)` : null}
      </Styled.MobileBlock>
    ))
  }

  return (
    <Styled.MobileCartList>
      {itemsList.map(item => {
        const hasCost = !!parseFloat(item.cost || '')
        const hasDiscount = !!parseFloat(item.discount_amount || '')
        const qty = Number(item.quantity)

        return (
          <Styled.MobileCartItem key={item.id}>
            <Styled.MobileBlock>
              <Styled.ProductCell>
                <Styled.ProductCellText>
                  <Styled.Text>{pick(item.title)}</Styled.Text>
                </Styled.ProductCellText>
              </Styled.ProductCell>
            </Styled.MobileBlock>
            {hasCost && item.sku && (
              <Styled.MobileBlock>
                <Styled.MobileLabel>{t('OrderPreviewTable.list.cost')}</Styled.MobileLabel>
                <Styled.NoWrapText>
                  <Cost
                    price={item.sku.price}
                    currency={producer?.currency || Currency.CAD}
                    cost={item.cost}
                    slug={item?.card?.category?.slug}
                    priceType={item?.sku?.price_type}
                  />
                </Styled.NoWrapText>
              </Styled.MobileBlock>
            )}
            {hasDiscount && (
              <Styled.MobileBlock>
                <Styled.MobileLabel>{t('OrderPreviewTable.list.discount')}</Styled.MobileLabel>
                <Styled.NoWrapText>
                  <Styled.Text>{formatPrice(item.discount_amount || 0)}</Styled.Text>
                </Styled.NoWrapText>
              </Styled.MobileBlock>
            )}
            {qtyJSX(item)}
            {item.sku && (
              <>
                <Styled.MobileBlock>
                  <Styled.MobileLabel>{t('OrderPreviewTable.list.standardUnits')}</Styled.MobileLabel>
                  <StandardUnitsCellMobile qty={qty} sku={item.sku} />
                </Styled.MobileBlock>
                <Styled.MobileBlock key={item.sku.params.package_title}>
                  <Styled.MobileLabel>{t('OrderPreviewTable.list.packageType')}:</Styled.MobileLabel>
                  <Styled.BoldText>{item.sku.params.package_title}</Styled.BoldText>
                </Styled.MobileBlock>
              </>
            )}
            {skuOptions(item)}
            <Styled.MobileBlock>
              {item.wizard_comment && !item.wizard_data && <WizardComment note={item.wizard_comment} />}
              {item.wizard_data?.type === ProductWizardType.Procote && <ProcoteWizardData data={item.wizard_data} />}
            </Styled.MobileBlock>
          </Styled.MobileCartItem>
        )
      })}
    </Styled.MobileCartList>
  )
}

const OrderSkuPreviewTable: React.FC<{ producer?: Company }> = ({ producer }) => {
  const isMobile = useMatchMedia(queries.mobile)
  if (isMobile) {
    return <MobileOrderPreviewTable producer={producer} />
  } else {
    return <DesktopOrderPreviewTable producer={producer} />
  }
}

export default OrderSkuPreviewTable

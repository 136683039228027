import { apiCall } from 'modules/sagaEffects'
import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects'
// import { push } from 'connected-react-router'
import DocumentActions from './duck'
import DocumentSelectors from './selectors'
import { Document } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import AuthSelectors from 'modules/domain/auth/selectors'
import { LOCATION_CHANGE } from 'connected-react-router'
import AuthActions from 'modules/domain/auth/duck'
import { RequestError } from 'service/api/errors'
// import { updateLocationQuery } from 'modules/sagaHelpers'

export const fetchList = function*() {
  try {
    let currentPage = yield select(DocumentSelectors.page)
    const filter = yield select(DocumentSelectors.filter)
    const sorting = yield select(DocumentSelectors.sorting)
    const pageSize = yield select(DocumentSelectors.pageSize)

    let response: ListResponse<Document> = yield call(apiCall, managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(apiCall, managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(DocumentActions.listRequestSucceed(data, total_count, page))

    // yield call(updateLocationQuery, DocumentsRoutes.List, { page: currentPage })
  } catch (err) {
    yield put(DocumentActions.listRequestFailed())
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(DocumentSelectors.page)
    const filter = yield select(DocumentSelectors.filter)
    const sorting = yield select(DocumentSelectors.sorting)
    const pageSize = yield select(DocumentSelectors.pageSize)
    const { data, total_count }: { data: Document[]; total_count: number } = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(DocumentActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    yield put(DocumentActions.listRequestNextFailed())
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof DocumentActions.itemRequested>) {
  try {
    const item: Document = yield call(apiCall, managers.getItem, id)
    yield put(DocumentActions.itemRequestSucceed(item))
  } catch (err) {
    yield put(DocumentActions.itemRequestFailed(id))
  }
}

export const fetchItemFileUrl = function*({ payload: id }: ReturnType<typeof DocumentActions.itemFileUrlRequested>) {
  try {
    const res: string = yield call(apiCall, managers.getItemUrl, id)
    yield put(DocumentActions.itemFileUrlRequestSucceed(id, res))
  } catch (err) {
    const detail = RequestError.parseError(err)
    yield put(DocumentActions.itemFileUrlRequestFailed(id, detail))
  }
}

export const fetchItemSignedFileUrl = function*({
  payload: id,
}: ReturnType<typeof DocumentActions.itemSignedFileUrlRequested>) {
  try {
    const res: string = yield call(apiCall, managers.getItemSignedUrl, id)
    yield put(DocumentActions.itemSignedFileUrlRequestSucceed(id, res))
  } catch (err) {
    const detail = RequestError.parseError(err)
    yield put(DocumentActions.itemSignedFileUrlRequestFailed(id, detail))
  }
}

export const addItem = function*({ payload: dto }: ReturnType<typeof DocumentActions.addRequested>) {
  try {
    const item: Document = yield call(apiCall, managers.addItem, dto)
    yield put(DocumentActions.addSucceed(item))
  } catch (err) {
    yield put(DocumentActions.addFailed())
  }
}
export const updateItem = function*({ payload: [id, dto] }: ReturnType<typeof DocumentActions.updateRequested>) {
  try {
    const item: Document = yield call(apiCall, managers.updateItem, id, dto)
    yield put(DocumentActions.updateSucceed(item))
  } catch (err) {
    yield put(DocumentActions.updateFailed(id))
  }
}

export const removeItem = function*({ payload }: ReturnType<typeof DocumentActions.removeRequested>) {
  try {
    yield call(apiCall, managers.removeItem, payload)
    yield put(DocumentActions.removeSucceed(payload))
  } catch (err) {
    yield put(DocumentActions.removeFailed(payload))
  }
}

export const requestSignUrl = function*({ payload }: ReturnType<typeof DocumentActions.signUrlRequested>) {
  try {
    const isAuthenticated = yield select(AuthSelectors.isAuthenticated)
    const isProfileFilled = yield select(AuthSelectors.isProfileFulfilled)
    const isUserOk = isAuthenticated && isProfileFilled
    if (!isUserOk) {
      yield put(DocumentActions.signUrlUserValidationFailed())

      const { accountInformationSuccess } = yield race({
        locationChange: take(LOCATION_CHANGE),
        accountInformationSuccess: take(AuthActions.userRegisterSucceed.type),
      })
      if (accountInformationSuccess) {
        yield put(DocumentActions.signUrlRequested(payload))
      }

      return
    }
    const { url } = yield call(apiCall, managers.getSignUrl, payload)
    if (url) {
      yield put(DocumentActions.signUrlRequestCompleted(url))
    } else {
      yield put(DocumentActions.signUrlRequestedFailed())
    }
  } catch (err) {
    console.error(err)
    yield put(DocumentActions.signUrlRequestedFailed())
  }
}

export const documentSignSuccessCallback = function*({
  payload: [id, event],
}: ReturnType<typeof DocumentActions.signCallbackEventReceived>) {
  try {
    if (event !== 'signing_complete') {
      return
    }
    yield call(apiCall, managers.documentSignSuccessCallback, id)
    yield put(DocumentActions.signSuccessCallbackSuccessfullyHandled())
  } catch (err) {
    console.error(err)
    yield put(DocumentActions.signSuccessCallbackFailedToHandle())
  }
}

const DocumentSaga = function*() {
  yield all([
    takeLatest(DocumentActions.itemRequested.type, fetchItem),
    takeLatest(DocumentActions.itemFileUrlRequested.type, fetchItemFileUrl),
    takeLatest(DocumentActions.itemSignedFileUrlRequested.type, fetchItemSignedFileUrl),
    takeLatest(DocumentActions.listRequested.type, fetchList),
    takeLatest(DocumentActions.filterUpdated.type, fetchList),
    takeLatest(DocumentActions.sortingUpdated.type, fetchList),
    takeLatest(DocumentActions.filterHasBeenReset.type, fetchList),
    takeLatest(DocumentActions.sortingHasBeenReset.type, fetchList),

    takeLatest(DocumentActions.listRequestedNext.type, fetchListNext),

    takeLatest(DocumentActions.addRequested.type, addItem),
    takeLatest(DocumentActions.updateRequested.type, updateItem),
    takeLatest(DocumentActions.removeRequested.type, removeItem),
    takeLatest(DocumentActions.signUrlRequested.type, requestSignUrl),
    takeLatest(DocumentActions.signCallbackEventReceived.type, documentSignSuccessCallback),
  ])
}

export default DocumentSaga

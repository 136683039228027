import { apiCall, makeRouteWatcher } from 'modules/sagaEffects'
import { all, call, put, select, takeLatest, fork, race, take } from 'redux-saga/effects'
import ProductActions from './duck'
import ProductSelectors from './selectors'
import { Product, ProductListRequestFilter } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { generateCountryPath, updateLocationQuery } from 'modules/sagaHelpers'
import ProducerRoutes from 'views/pages/Producer/routes'
import ProducerActions from 'modules/domain/producer/duck'
import CollectionActions from 'modules/domain/collection/duck'
import CollectionSelectors from 'modules/domain/collection/selectors'
import ProducerSelectors from 'modules/domain/producer/selectors'
import { Company } from 'types/entities'
import { Category } from 'modules/domain/collection/types'
import { RequestError } from 'service/api/errors'
import CartSelectors from 'modules/domain/cart/selectors'
import { CartDiscountEntryDTO, CartItem } from 'modules/domain/cart/types'
import { DEFAULT_QTY } from 'views/pages/Producer/Product/ProductItem/AdderWidget/constants'
import { replace } from 'connected-react-router'
import RelatedProductActions from '../relatedProduct/duck'

export const fetchList = function*() {
  try {
    let currentPage = yield select(ProductSelectors.page)
    const filter = yield select(ProductSelectors.filter)
    const sorting = yield select(ProductSelectors.sorting)
    const pageSize = yield select(ProductSelectors.pageSize)
    let response: ListResponse<Product> = yield call(apiCall, managers.getList, filter, sorting, currentPage, pageSize)
    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(apiCall, managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(ProductActions.listRequestSucceed(data, total_count, page))

    yield call(updateLocationQuery, ProducerRoutes.ProductOrCardList, {
      page: currentPage,
      filter_attributes: filter.attributes ? JSON.stringify(filter.attributes) : undefined,
    })
  } catch (err) {
    console.error(err)
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(ProductSelectors.page)
    const filter = yield select(ProductSelectors.filter)
    const sorting = yield select(ProductSelectors.sorting)
    const pageSize = yield select(ProductSelectors.pageSize)
    const { data, total_count }: { data: Product[]; total_count: number } = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(ProductActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    console.error(err)
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductActions.listRequestNextFailed(errType))
  }
}

export const fetchItem = function*({ payload: id }: ReturnType<typeof ProductActions.itemRequested>) {
  try {
    const cached = yield select(ProductSelectors.item, id)
    if (cached) {
      yield put(ProductActions.itemRequestSucceed(cached))
      return
    }
    const product: Product = yield call(apiCall, managers.getItem, id)
    yield put(ProductActions.itemRequestSucceed(product))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductActions.itemRequestFailed(id, errType))
  }
}

export const fetchDiscountsSaga = function*({
  payload: [id, quantity, params],
}: ReturnType<typeof ProductActions.discountsRequested>) {
  try {
    const cartItems: CartItem[] = yield select(CartSelectors.cartItems)
    const alreadyAddedItem: CartItem | undefined = yield select(state =>
      CartSelectors.cartEntryByParams(state, id, params),
    )
    const product: Product = yield select(state => ProductSelectors.item(state, id))
    const entries: CartDiscountEntryDTO[] = [
      ...cartItems.map(item => ({ product: item.product, quantity: item.product_id === id ? quantity : item.qty })),
    ]

    if (!alreadyAddedItem) {
      const wrappedProduct: CartDiscountEntryDTO = {
        quantity,
        product,
      }
      entries.push(wrappedProduct)
    }
    const response = yield call(apiCall, managers.calculateProductDiscount, id, entries)
    yield put(ProductActions.discountsRequestSucceed(response))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductActions.discountsRequestFailed(errType))
  }
}

export const fetchExclusiveItem = function*({ payload }: ReturnType<typeof ProductActions.exclusiveItemRequested>) {
  try {
    const product: Product = yield call(apiCall, managers.getExclusiveItem, payload)

    if (!product || !product.is_exclusive) {
      throw new Error()
    }

    const producerSlug = product.producer?.slug
    const categorySlug = product.category?.slug
    const subCategorySlug = product.subcategory?.slug
    const productOrCardSlug = product.slug

    const path = yield call(generateCountryPath, ProducerRoutes.ProductOrCardItem, {
      producerSlug,
      categorySlug,
      subCategorySlug,
      productOrCardSlug,
    })

    yield put(ProductActions.exclusiveItemRequestSucceed())
    yield put(replace(path))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductActions.exclusiveItemRequestFailed(errType))
  }
}

export const fetchGiftList = function*() {
  try {
    const filter: ProductListRequestFilter = yield select(ProductSelectors.giftFilter)
    const response: ListResponse<Product> = yield call(apiCall, managers.getList, filter)
    const { data } = response
    yield put(ProductActions.giftListRequestSucceed(data))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductActions.giftListRequestFailed(errType))
  }
}

const routerWatcher = makeRouteWatcher(ProducerRoutes, function*(result) {
  if (result.silent) {
    return
  }

  switch (result.match) {
    case ProducerRoutes.ProductOrCardList: {
      yield put(ProducerActions.itemRequested(result.params.producerSlug))
      yield race([take(ProducerActions.itemRequestSucceed.type), take(ProducerActions.itemRequestFailed.type)])

      const producer: Company = yield select(ProducerSelectors.item, result.params.producerSlug)
      const isStorefront = producer?.sku_orders

      if (isStorefront) {
        return
      }

      yield put(CollectionActions.categoriesRequested())
      yield race([
        take(CollectionActions.categoriesRequestSucceed.type),
        take(CollectionActions.categoriesRequestFailed.type),
      ])

      const subCategory: Category = yield select(CollectionSelectors.categoryByIdOrSlug, result.params.subCategorySlug)
      if (!subCategory || !producer) {
        return
      }

      const productFilter: ProductListRequestFilter = yield select(ProductSelectors.filter)
      if (productFilter.producerId === producer.id && productFilter.subCategory === subCategory.id) {
        return
      }

      let filter = productFilter
      if (
        (productFilter.producerId && productFilter.producerId !== producer.id) ||
        (productFilter.subCategory && productFilter.subCategory !== subCategory.id)
      ) {
        filter = {}
      }

      yield all([
        put(
          ProductActions.listRequested({
            filter: { ...filter, producerId: producer.id, subCategory: subCategory.id },
          }),
        ),
        put(CollectionActions.filterAttributesRequested(producer.slug, subCategory.slug)),
        put(
          CollectionActions.companySubCategoriesRequested(
            result.params.producerSlug,
            result.params.categorySlug,
            producer.sku_orders,
          ),
        ),
      ])
      break
    }

    case ProducerRoutes.ProductOrCardItem: {
      yield put(ProducerActions.itemRequested(result.params.producerSlug))
      yield race([take(ProducerActions.itemRequestSucceed.type), take(ProducerActions.itemRequestFailed.type)])

      const producer: Company = yield select(ProducerSelectors.item, result.params.producerSlug)
      if (producer?.sku_orders) {
        return
      }

      yield put(ProductActions.itemRequested(result.params.productOrCardSlug))

      const { error } = yield race({
        error: take(ProductActions.itemRequestFailed.type),
        success: take(ProductActions.itemRequestSucceed.type),
      })

      if (error) {
        return
      }

      const product: Product = yield select(ProductSelectors.item, result.params.productOrCardSlug)
      if (product) {
        const cartItem: CartItem | undefined = yield select(state =>
          CartSelectors.cartEntryByParams(state, product.id, { seed_treatment_id: product.seed_treatment[0]?.id }),
        )
        const qty = cartItem?.qty || product.min_qty || DEFAULT_QTY
        yield put(ProductActions.discountsRequested(product.id, qty, cartItem))
        yield put(RelatedProductActions.listRequested({ productId: product.id }))
      }
      break
    }
    default:
      break
  }
})

export const fetchCustomProductList = function*({
  payload,
}: ReturnType<typeof ProductActions.customProductListRequested>) {
  try {
    const sorting = yield select(ProductSelectors.sorting)
    const currentPage = yield select(ProductSelectors.page)
    const pageSize = yield select(ProductSelectors.pageSize)
    const response: ListResponse<Product> = yield call(
      apiCall,
      managers.getList,
      payload.filter,
      sorting,
      currentPage,
      pageSize,
      payload.isExclusive,
    )
    const { data } = response
    yield put(ProductActions.customProductListRequestSucceed(data))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ProductActions.customProductListListRequestFailed(errType))
  }
}

const ProductSaga = function*() {
  yield all([
    takeLatest(ProductActions.itemRequested.type, fetchItem),
    takeLatest(ProductActions.listRequested.type, fetchList),
    takeLatest(ProductActions.filterUpdated.type, fetchList),
    takeLatest(ProductActions.sortingUpdated.type, fetchList),
    takeLatest(ProductActions.filterHasBeenReset.type, fetchList),
    takeLatest(ProductActions.sortingHasBeenReset.type, fetchList),

    takeLatest(ProductActions.listRequestedNext.type, fetchListNext),

    takeLatest(ProductActions.discountsRequested.type, fetchDiscountsSaga),
    takeLatest(ProductActions.exclusiveItemRequested.type, fetchExclusiveItem),
    takeLatest(ProductActions.giftListRequested.type, fetchGiftList),
    takeLatest(ProductActions.customProductListRequested.type, fetchCustomProductList),
    fork(routerWatcher),
  ])
}

export default ProductSaga

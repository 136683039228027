import {
  Button,
  Checkbox,
  Input,
  SectionContainer,
  SectionTitle,
  Switch,
  TextAreaWithTags,
  useAction,
} from '@agro-club/frontend-shared'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useCompanyFeatureFlags from 'hooks/useCompanyFeatureFlags'
import { useCountryPath } from 'hooks/useCountryPath'
import useLangPicker from 'hooks/useLangPicker'
import useMatchMedia from 'hooks/useMatchMedia'
import useWhiteLabelConfig from 'hooks/useWhiteLabelConfig'
import AuthActions from 'modules/domain/auth/duck'
import CartActions from 'modules/domain/cart/duck'
import { useCartList } from 'modules/domain/cart/hooks'
import CartSelectors from 'modules/domain/cart/selectors'
import { CartItem, UpdateCartItemParams } from 'modules/domain/cart/types'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { useProducer, useProducerList } from 'modules/domain/producer/hooks'
import { useProductOptionsList } from 'modules/domain/productOptions/hooks'
import { Progress } from 'modules/types'
import { producerToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import { generateFieldAccessString, generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import { clone } from 'ramda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { queries } from 'theme/theme'
import { Company, Currency } from 'types/entities'
import { FeatureFlagModifiers, FieldLocation, FieldNames, Sections } from 'types/entities/config'
import { CalculatedDiscountDto, DiscountTypes } from 'types/entities/discount'
import { isPromocodeWithComment, isPromocodeWithLegalText } from 'types/entities/promocode'
import { CartList, MobileCartList } from 'views/components/CartList/CartList'
import DiscountTotal from 'views/components/DiscountWidget/DiscountTotal'
import { QtyDiscountWidget } from 'views/components/DiscountWidget/QtyDiscountWidget'
import * as DiscountWidgetStyles from 'views/components/DiscountWidget/styled'
import GiftWidget from 'views/components/GiftWidget/GiftWidget'
import Link from 'views/components/Link/Link'
import ErrorLayout from 'views/layouts/ErrorLayout/ErrorLayout'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import CartRoutes from 'views/pages/Cart/routes'
import { Routes } from 'views/pages/routes'
import { HowItWorksButton } from 'views/ui/HowItWorks/HowItWorks'
import SpecialOfferDiscountListWidget from '../DiscountWidget/SpecialOfferDiscountListWidget'
import SpecialOfferDiscountWidget from '../DiscountWidget/SpecialOfferDiscountWidget'
import UpdateErrorPopup from '../UpdateErrorPopup/UpdateErrorPopup'
import { CartEvents } from './events'
import * as Styled from './styles'

const PromocodeForm = styled.form`
  display: grid;
  grid-template-columns: 190px minmax(max-content, 90px);
  align-items: center;
  grid-gap: 8px;
  padding-bottom: 4px;
`
type PromocodeProps = {
  className?: string
  company_id: string
  producerSlug: string
  producer?: Company
}

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: stretch;
  flex-grow: 1;
  padding: 100px 0;
`

const PromocodeField: React.FC<PromocodeProps> = ({ className, company_id, producerSlug, producer }) => {
  const { t } = useTranslation(['promocode', 'common'])
  const promocodes = useSelector(state => CartSelectors.promocodes(state, producerSlug))
  const promocodeStatus = useSelector(CartSelectors.lastPromocodeStatus)
  const checkPromocodeProgress = useSelector(CartSelectors.checkPromocodeProgress)
  const [code, setCode] = useState<string | undefined>()
  const checkPromocode = useAction(CartActions.checkPromocode)
  const checked = useRef(false)
  const { track } = useAnalyticsSSR()

  const handleChange = useCallback(e => {
    setCode(e.target.value || undefined)
    checked.current = false
  }, [])

  useEffect(() => {
    if (checkPromocodeProgress === Progress.SUCCESS && promocodeStatus === 'valid') {
      setCode('')
    }
  }, [checkPromocodeProgress, promocodeStatus])

  const alreadyApplied = promocodes.some(item => item.code?.toLowerCase() === code?.toLowerCase())
  const handleApply = useCallback(
    e => {
      e.preventDefault()
      if (code && !alreadyApplied) {
        track(CartEvents.CartPromoCode, { code, ...producerToEventParams(producer) })
        checkPromocode({ code, company_id, producerSlug })
      }
      checked.current = true
    },
    [code, alreadyApplied, track, checkPromocode, company_id, producer, producerSlug],
  )

  const inputErrorText = alreadyApplied
    ? t('alreadyApplied')
    : promocodeStatus === 'discount_used'
    ? t('discountPromocodeUsed')
    : promocodeStatus === 'product_missing'
    ? t('productMissing')
    : t('invalidPromocode')
  const isInvalid =
    (['invalid', 'discount_used', 'product_missing'].includes(promocodeStatus) && checked.current) || alreadyApplied

  return (
    <PromocodeForm className={className} onSubmit={handleApply}>
      <Input
        data-test-id="promocode-input"
        onChange={handleChange}
        value={code}
        invalid={isInvalid}
        name={'promocode'}
        placeholder={t('placeholder')}
        errorText={inputErrorText}
      />
      <Button
        data-test-id="apply-promocode-button"
        intent={'primary'}
        disabled={!code || checked.current}
        progress={checkPromocodeProgress}
        type={'submit'}
      >
        {t('common:apply')}
      </Button>
    </PromocodeForm>
  )
}

const PromocodeListWrapper = styled.div`
  min-width: 450px;
  ${props => props.theme.media.mobile`
    min-width: 100%;
  `}
`

const PromocodeList: React.FC<{ producerSlug: string }> = ({ producerSlug }) => {
  const promocodes = useSelector(state => CartSelectors.promocodes(state, producerSlug))
  const selectedPromocode = useSelector(state => CartSelectors.selectedPromocode(state, producerSlug))
  const selectEditablePromocodeAction = useAction(CartActions.selectEditablePromocode)
  const updatePromocodeCommentAction = useAction(CartActions.updatePromocodeComment)
  const removePromocodeAction = useAction(CartActions.removePromocode)
  const handleRemove = (id: string) => {
    removePromocodeAction(producerSlug, id)
  }

  const items = promocodes.map(item => {
    let placeholder
    let value
    let readOnly = false
    let editable: boolean | undefined = false
    let selectable: boolean | undefined = false

    if (isPromocodeWithComment(item)) {
      placeholder = item.params.prompt || undefined
      editable = !!item.params.prompt
      value = item.comment || undefined
      selectable = true
    }

    if (isPromocodeWithLegalText(item)) {
      value = item.params.legal_text || undefined
      readOnly = true
      selectable = true
    }
    return {
      id: item.code,
      title: item.code,
      placeholder,
      editable,
      value,
      selectable,
      readonly: readOnly,
    }
  })

  // select first editable, readonly or selected
  const selected =
    selectedPromocode ||
    promocodes.find(item => {
      if (isPromocodeWithComment(item)) {
        return !!item.params.prompt
      }
      if (isPromocodeWithLegalText(item)) {
        return !!item.params.legal_text
      }
    })?.code

  const handleChangePromocode = useCallback(
    (code: string): void => {
      if (selected !== code) {
        selectEditablePromocodeAction(producerSlug, code)
      }
    },
    [producerSlug, selectEditablePromocodeAction, selected],
  )

  const handleUpdatePromocodeComment = useCallback(
    (id: string, text: string): void => {
      updatePromocodeCommentAction(producerSlug, id, text)
    },
    [producerSlug, updatePromocodeCommentAction],
  )

  if (promocodes.length < 1) {
    return null
  }

  return (
    <PromocodeListWrapper data-test-id="promocode-textarea">
      <TextAreaWithTags
        items={items}
        selected={selected}
        onTextChange={handleUpdatePromocodeComment}
        onSelectionChange={handleChangePromocode}
        onRemove={handleRemove}
      />
    </PromocodeListWrapper>
  )
}

type DiscountBarProps = {
  producerSlug: string
  producer?: Company
  cartItems: CartItem[]
  onAddQty: (id: string, qty: number) => void
}

export const DiscountBar: React.FC<DiscountBarProps> = ({ onAddQty, producer, producerSlug, cartItems }) => {
  const { t } = useTranslation(['discount', 'common'])
  const discounts = useSelector(state => CartSelectors.discounts(state, producerSlug))
  const monetaryDiscounts = useMemo(() => discounts.filter(d => d.rule.rule_type !== DiscountTypes.Gift), [discounts])
  const giftDiscounts = useMemo(() => discounts.filter(d => d.rule.rule_type === DiscountTypes.Gift), [discounts])

  const isMobile = useMatchMedia(queries.mobile)
  const { pick } = useLangPicker()
  const options = useMemo(
    () =>
      cartItems.map(
        item => {
          const seedTreatment = item.product.seed_treatment.find(st => st.id === item.seed_treatment_id)
          return {
            title: `${pick(item.product.title_i18n)} ${pick(seedTreatment?.title_i18n)}`,
            value: item.id,
            productId: item.product_id,
          }
        },
        [cartItems],
      ),
    [cartItems, pick],
  )
  const units = useMemo(() => {
    const itemWithUnits = cartItems.find(item => item.product.units?.plural || item.product.units?.singular)
    return itemWithUnits?.product.units
  }, [cartItems])

  const groupedOptions = useMemo(() => {
    return monetaryDiscounts.reduce((acc, discount) => {
      return {
        ...acc,
        [discount.rule.id || discount.rule._id]: options.filter(item =>
          discount.rule.products_ids.includes(item.productId),
        ),
      }
    }, {})
  }, [options, monetaryDiscounts])

  const getDiscount = useCallback(
    (qty: number, key?: string) => {
      const entry = cartItems.find(e => e.id === key)
      key && onAddQty(key, (entry?.qty ?? 0) + qty)
    },
    [onAddQty, cartItems],
  )

  const campaigns = useMemo(
    () =>
      monetaryDiscounts
        ? monetaryDiscounts.reduce(
            (dict: { [key: string]: CalculatedDiscountDto[] }, discount: CalculatedDiscountDto) => {
              if (!discount.rule.campaign_name) return dict

              if (discount.rule.slug?.includes('special-offer')) {
                return {
                  ...dict,
                  [t('specialOfferLabel')]:
                    t('specialOfferLabel') in dict ? [...dict[t('specialOfferLabel')], discount] : [discount],
                }
              }
              if (discount.rule.campaign_name in dict) {
                return { ...dict, [discount.rule.campaign_name]: [...dict[discount.rule.campaign_name], discount] }
              } else {
                return { ...dict, [discount.rule.campaign_name]: [discount] }
              }
            },
            {},
          )
        : [],
    [monetaryDiscounts, t],
  )

  const showGiftWidget = () => {
    const isDiscountApplied = (discount: CalculatedDiscountDto) => discount.tiers.some(t => t.is_applied)
    return giftDiscounts.some(d => isDiscountApplied(d))
  }

  return (
    <DiscountWidgetStyles.WidgetContainer>
      {!isMobile && <DiscountTotal producer={producer} />}
      {showGiftWidget() && <GiftWidget giftDiscounts={giftDiscounts} cartItems={cartItems} producer={producer} />}
      {monetaryDiscounts.length
        ? Object.keys(campaigns).map((campaignName, idx) => {
            if (campaignName === t('specialOfferLabel')) {
              return (
                <SpecialOfferDiscountListWidget
                  key={campaignName}
                  dataTestId={`campaign-${idx}`}
                  discounts={campaigns[campaignName]}
                />
              )
            }
            if (campaigns[campaignName].length && campaigns[campaignName][0].rule.slug?.includes('special-offer')) {
              const discount = campaigns[campaignName][0] as CalculatedDiscountDto
              return (
                <SpecialOfferDiscountWidget
                  key={campaignName}
                  dataTestId={`campaign-${idx}`}
                  title={campaignName}
                  learnMoreUrl={producer?.config?.seller_config?.truck_info?.learn_more_url}
                  discount={discount}
                  options={groupedOptions[discount.rule.id || discount.rule._id]}
                  onGetDiscount={getDiscount}
                />
              )
            }
            return (
              <QtyDiscountWidget
                key={campaignName}
                currency={producer?.currency || Currency.CAD}
                dataTestId={`campaign-${idx}`}
                title={campaignName}
                discounts={campaigns[campaignName]}
                options={groupedOptions}
                onGetDiscount={getDiscount}
                units={units}
              />
            )
          })
        : null}
    </DiscountWidgetStyles.WidgetContainer>
  )
}

const Spacer = styled.div`
  flex-grow: 1;
`

const HowItWorksButtonStyled = styled(HowItWorksButton)`
  margin-right: 12px;
`

const Cart = () => {
  const { t, i18n } = useTranslation(['cart', 'common'])
  const lang = i18n.language.slice(0, 2)
  const { producerSlug } = useParams<{ producerSlug: string }>()
  const generateCountryPath = useCountryPath()
  const [, producer] = useProducer(producerSlug)
  const [, producerList] = useProducerList()
  const isMobile = useMatchMedia(queries.mobile)
  const [progress, items = []] = useCartList(producerSlug)
  const pendingEntries = useSelector(state => CartSelectors.cartPendingEntriesByProducerSlug(state, producerSlug))
  const creditOfferAccepted = useSelector(state => CartSelectors.creditOfferAccepted(state, producerSlug))
  const setCreditOfferAccepted = useAction(CartActions.setCreditOfferAccepted)
  const hasAccess = useCompanyFeatureFlags(producerSlug)
  const [, productOptions] = useProductOptionsList()
  const [optionsRequiredErrors, setOptionsRequiredErrors] = useState<string[][]>([])
  const [seedTreatmentErrors, setSeedTreatmentErrors] = useState<boolean[]>([])

  const farmerCommentValue = useRef({})
  const setFarmerCommentAction = useAction(CartActions.setFarmerComment)
  const savedFarmerOrder = useSelector(state => CartSelectors.farmerComment(state, producerSlug))
  const [farmerComment, setFarmerComment] = useState({ [producerSlug]: savedFarmerOrder })

  const checkCompanyFeatureFlags = useCompanyFeatureFlags(producerSlug)
  const showUnits = checkCompanyFeatureFlags(
    generateFieldModifierString(FieldLocation.CartList, FieldNames.Units, FeatureFlagModifiers.Enabled),
  )
  const isQtyDisabled = checkCompanyFeatureFlags(
    generateFieldModifierString(FieldLocation.CartList, FieldNames.Qty, FeatureFlagModifiers.Disabled),
  )

  const showFCC = useMemo(() => {
    const creditOffer = producer?.credit_offer
    const fccAllowed = items.some(item => !!item.product.additional_options?.credit_offer_allowed)
    return creditOffer && fccAllowed
  }, [items, producer?.credit_offer])

  const { track } = useAnalyticsSSR()
  const history = useHistory()

  const cartInfo = useSelector(CartSelectors.cartInfo)
  const cartSkuInfo = useSelector(CartSkuSelectors.cartInfo)
  const termsAgreed = useSelector(CartSelectors.termsAgreed)
  const itemRemoveAction = useAction(CartActions.itemRemoveRequested)
  const itemUpdateAction = useAction(CartActions.itemUpdateRequested)
  const setTermsAgreed = useAction(CartActions.setTermsAgreed)
  const { pick } = useLangPicker()

  const { config: wlConfig } = useWhiteLabelConfig()
  const config = wlConfig?.legal_docs

  const { truck_info, how_it_works } = { ...producer?.config?.seller_config }

  const howItWorksItems = useMemo(() => {
    const companyId = wlConfig?.company_id
    const howItWorksFromCompany = producerList?.find(item => item.id === companyId)
    return how_it_works || howItWorksFromCompany?.config?.seller_config?.how_it_works || []
  }, [wlConfig?.company_id, producerList, how_it_works])

  const promocodeIsHidden = hasAccess([
    generateFieldAccessString(Sections.Cart, FieldNames.Promocode, FeatureFlagModifiers.Hidden),
  ])

  const setTermsAgreedInStepLogin = useAction(AuthActions.setTermsAgreed)
  const handleTermsAgreed = useCallback(
    (_, isChecked) => {
      if (isChecked) {
        track(CartEvents.TermsAccepted, { accepted: true, ...producerToEventParams(producer) })
      } else {
        track(CartEvents.TermsAccepted, { accepted: false, ...producerToEventParams(producer) })
      }
      setTermsAgreed(isChecked)
      setTermsAgreedInStepLogin(isChecked)
    },
    [producer, setTermsAgreed, setTermsAgreedInStepLogin, track],
  )

  const handleUpdate = useCallback(
    (id: string, params: UpdateCartItemParams) => {
      itemUpdateAction(id, params)
    },
    [itemUpdateAction],
  )
  const handlePackagingUpdate = useCallback(
    (id: string, value: UpdateCartItemParams['packaging']) => {
      handleUpdate(id, { packaging: value })
    },
    [handleUpdate],
  )
  const handleQtyUpdate = useCallback(
    (id: string, value: UpdateCartItemParams['quantity']) => handleUpdate(id, { quantity: value }),
    [handleUpdate],
  )

  const requiredOptionsValid = useCallback(
    (id?: string, updatedOptions?: string[], updatedSeedTreatment?: string) => {
      const cartItems = clone(items)
      const errors: string[][] = []
      const seedTreatmentErrors: boolean[] = []
      let isValid = true

      cartItems.forEach((item: CartItem) => {
        const allSeedTreatmentsInactive = item.product.seed_treatment.every(s => !s.is_active)
        if (allSeedTreatmentsInactive) {
          seedTreatmentErrors.push(true)
        } else if (item.product.seed_treatment.length && !item.seed_treatment_id) {
          if (id && id === item.id && updatedSeedTreatment) {
            seedTreatmentErrors.push(true)
          } else {
            seedTreatmentErrors.push(false)
            isValid = false
          }
        } else {
          seedTreatmentErrors.push(true)
        }

        const opts = item.product.options
        const foundedErrors: string[] = []
        if (!opts || !opts.length) {
          errors.push([])
          return
        }

        let options = item.options || []
        if (id && id === item.id && updatedOptions) {
          options = updatedOptions
        }

        opts.forEach(o => {
          const found = o.option_ids.filter(x => options.includes(x))
          if (o.required && !found.length) {
            foundedErrors.push(o.type)
            isValid = false
          }
        })

        errors.push(foundedErrors)
      })

      setOptionsRequiredErrors(errors)
      setSeedTreatmentErrors(seedTreatmentErrors)
      return isValid
    },
    [items],
  )

  const handleRemove = useCallback(
    (id: string) => {
      itemRemoveAction(id)
      requiredOptionsValid(id)
    },
    [itemRemoveAction, requiredOptionsValid],
  )

  const handleOptionsChange = useCallback(
    (id: string, options: UpdateCartItemParams['options']) => {
      handleUpdate(id, { options })
      requiredOptionsValid(id, options)
    },
    [handleUpdate, requiredOptionsValid],
  )

  const handleSeedTreatmentUpdate = useCallback(
    (id: string, value: UpdateCartItemParams['seed_treatment_id']) => {
      handleUpdate(id, { seed_treatment_id: value })
      requiredOptionsValid(id, undefined, value)
    },
    [handleUpdate, requiredOptionsValid],
  )

  const handleCheckout = useCallback(() => {
    if (!termsAgreed) return
    if (!requiredOptionsValid()) {
      return
    }

    const analyticData = {
      quantity: items.reduce((acc: number, item) => (acc += Number(item.qty)), 0),
      ...producerToEventParams(producer),
    }

    setFarmerCommentAction(producerSlug, farmerComment[producerSlug])
    track(CartEvents.ProceedToCheckout, analyticData)
    const path = generateCountryPath(Routes.CheckoutProducer, { producerSlug })
    history.push(path)
  }, [
    generateCountryPath,
    history,
    items,
    producer,
    producerSlug,
    requiredOptionsValid,
    termsAgreed,
    track,
    setFarmerCommentAction,
    farmerComment,
  ])

  useEffect(
    () => () => {
      if (farmerCommentValue.current[producerSlug]) {
        setFarmerCommentAction(producerSlug, farmerCommentValue.current[producerSlug])
      }
    },
    [],
  )

  const handleFarmerCommentChange = comment => {
    setFarmerComment({ ...farmerComment, [producerSlug]: comment })
    farmerCommentValue.current = { ...farmerComment, [producerSlug]: comment }
  }

  const termsOfUse = useMemo(() => {
    return `
      <a href="${
        config?.user_agreement ? pick(config.user_agreement) : `/agreement_${lang}.pdf`
      }" target="_blank" rel="noopener noreferrer">${t('cart:termsOfUse')}</a>
    `
  }, [lang, t, config?.user_agreement, pick])

  const privacyPolicy = useMemo(() => {
    return `
      <a href="${
        config?.privacy_policy ? pick(config.privacy_policy) : `/privacy-policy_${lang}.pdf`
      }" target="_blank" rel="noopener noreferrer">${t('cart:privacyPolicy')}</a>,
    `
  }, [lang, t, config?.privacy_policy, pick])

  const continueShoppingUrl = useMemo(
    () =>
      producerSlug ? generateCountryPath(Routes.CategoryList, { producerSlug }) : generateCountryPath(Routes.Root),
    [generateCountryPath, producerSlug],
  )

  const cartsInfo = useMemo(() => ({ ...cartInfo, ...cartSkuInfo }), [cartInfo, cartSkuInfo])

  if ((!producerSlug || (producerSlug && !cartsInfo[producerSlug])) && Object.keys(cartsInfo).length) {
    const producerSlug = Object.keys(cartsInfo)[0]
    return <Redirect to={generateCountryPath(CartRoutes.ProducerCart, { producerSlug })} />
  }

  if (progress === Progress.WORK || progress === Progress.IDLE || (!producer && producerSlug)) {
    return (
      <SpinnerWrapper>
        <SpinnerLayout />
      </SpinnerWrapper>
    )
  }

  if (progress === Progress.ERROR) {
    return <ErrorLayout />
  }

  return (
    <Styled.Wrapper>
      <SectionContainer noDivider>
        <SectionTitle>
          {t('title')}
          <Spacer />
          <HowItWorksButtonStyled
            items={howItWorksItems}
            onlyIcon={isMobile}
            onClick={() => track(CartEvents.CartHowItWorks, producer ? producerToEventParams(producer) : {})}
          />
          <Link to={continueShoppingUrl}>
            <Button
              intent={'primary-action'}
              filled={false}
              size={'medium'}
              style={{ border: 'none' }}
              onClick={() => track(CartEvents.CartContinueShopping, producer ? producerToEventParams(producer) : {})}
            >
              {t('continueShopping')}
            </Button>
          </Link>
        </SectionTitle>
        {isMobile && <DiscountTotal producer={producer} />}
        <Styled.Container paddedOnMobile>
          {isMobile ? (
            <MobileCartList
              data={items}
              producer={producer}
              onQtyChange={handleQtyUpdate}
              onPackagingChange={handlePackagingUpdate}
              onSeedTreatmentChange={handleSeedTreatmentUpdate}
              onOptionsChange={handleOptionsChange}
              onRemove={handleRemove}
              emptyText={t('empty')}
              optionsRequiredErrors={optionsRequiredErrors}
              seedTreatmentErrors={seedTreatmentErrors}
              productOptions={productOptions || []}
              pendingData={pendingEntries}
              showUnits={showUnits}
              isQtyDisabled={isQtyDisabled}
            />
          ) : (
            <CartList
              data={items}
              onQtyChange={handleQtyUpdate}
              onPackagingChange={handlePackagingUpdate}
              onSeedTreatmentChange={handleSeedTreatmentUpdate}
              onOptionsChange={handleOptionsChange}
              onRemove={handleRemove}
              emptyText={t('empty')}
              optionsRequiredErrors={optionsRequiredErrors}
              seedTreatmentErrors={seedTreatmentErrors}
              productOptions={productOptions || []}
              pendingData={pendingEntries}
              showUnits={showUnits}
              isQtyDisabled={isQtyDisabled}
            />
          )}
          {!!items.length && (
            <Styled.CommentWrapper>
              <Styled.CommentInput
                placeholder={t('enterAComment')}
                onChange={(_, comment) => handleFarmerCommentChange(comment)}
                value={farmerComment[producerSlug]}
              />
            </Styled.CommentWrapper>
          )}
        </Styled.Container>
        {items.length ? (
          <Styled.Footer>
            {!!truck_info && !!pick(truck_info.title_i18n) && (
              <Styled.TruckText>
                {pick(truck_info.title_i18n)}
                {!!truck_info.learn_more_url && (
                  <Styled.TruckLearnMore href={truck_info.learn_more_url} target={'blank'}>
                    {t('common:learnMore')}
                  </Styled.TruckLearnMore>
                )}
              </Styled.TruckText>
            )}
            {!!truck_info && !!pick(truck_info.description_i18n) && (
              <Styled.TruckDescription>{pick(truck_info.description_i18n)}</Styled.TruckDescription>
            )}
            {producer && !promocodeIsHidden && (
              <>
                <PromocodeField company_id={items[0].producer_id} producerSlug={producerSlug} producer={producer} />
                <PromocodeList producerSlug={producerSlug} />
              </>
            )}
            {showFCC &&
              (producerSlug != 'pitura-seeds' ? (
                <Styled.SwitchContainer>
                  <Switch
                    name={'credit-offer-switch'}
                    on={creditOfferAccepted}
                    onClick={val => {
                      track(CartEvents.ServiceOffer, {
                        offer_type: 'credit_offer',
                        value: val,
                        ...producerToEventParams(producer),
                      })
                      setCreditOfferAccepted(producerSlug, val)
                    }}
                  />

                  <Styled.SwitchText>
                    {t('wantToJoinProgram', { company: producer?.official_name })}
                    <Styled.FCCLogo />
                  </Styled.SwitchText>
                </Styled.SwitchContainer>
              ) : (
                <Styled.SwitchContainer>
                  <Switch
                    name={'credit-offer-switch'}
                    on={creditOfferAccepted}
                    onClick={val => {
                      track(CartEvents.ServiceOffer, {
                        offer_type: 'credit_offer',
                        value: val,
                        ...producerToEventParams(producer),
                      })
                      setCreditOfferAccepted(producerSlug, val)
                    }}
                  />
                  <Styled.SwitchText>{t('wantToJoinPituraProgram')}</Styled.SwitchText>
                </Styled.SwitchContainer>
              ))}
            <Styled.SwitchContainer data-test-id={'agreement-checkbox-wrapper'}>
              <Checkbox isChecked={termsAgreed} onChange={handleTermsAgreed} id={'terms_of_use'} />
              <Styled.Terms dangerouslySetInnerHTML={{ __html: t('agreeWithTerms', { termsOfUse, privacyPolicy }) }} />
            </Styled.SwitchContainer>
            {items.length ? (
              <Styled.OrderButtonWrapper data-test-id={'checkout-button-wrapper'}>
                <Styled.OrderButton
                  type={'submit'}
                  disabled={!termsAgreed}
                  size={'big'}
                  intent={'primary-action'}
                  filled
                  id={'checkout-button'}
                  onClick={handleCheckout}
                >
                  {t('common:order')}
                </Styled.OrderButton>
                <Styled.OrderButtonHint>{t('cart:orderHint')}</Styled.OrderButtonHint>
              </Styled.OrderButtonWrapper>
            ) : null}
          </Styled.Footer>
        ) : null}
      </SectionContainer>
      <DiscountBar producer={producer} producerSlug={producerSlug} onAddQty={handleQtyUpdate} cartItems={items} />
      <UpdateErrorPopup />
    </Styled.Wrapper>
  )
}

export default Cart

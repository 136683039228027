import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useDocumentFileUrl, useDocumentItemById, useSignedDocumentFileUrl } from 'modules/domain/documents/hooks'
import { Routes } from 'views/pages/routes'
import {
  Button,
  IconSign,
  SectionBody,
  SectionContainer,
  SectionTitle,
  useAction,
  useBoolean,
} from '@agro-club/frontend-shared'
import PdfViewer from 'views/pages/Profile/DocumentDetails/PdfViewer/loadable'
import { Progress } from 'modules/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import Link from 'views/components/Link/Link'
import DocumentActions from 'modules/domain/documents/duck'
import { useSelector } from 'react-redux'
import LoginForm from 'views/components/LoginForm/LoginForm'
import { SignCallbackEvent } from 'modules/domain/documents/types'
import DocumentSelectors from 'modules/domain/documents/selectors'
import { useCountryPath } from 'hooks/useCountryPath'

const SignButtonStyled = styled(Button)`
  width: auto;
  align-self: flex-start;
  justify-self: flex-start;
  border: 0;
  color: ${props => props.theme.color.onPrimaryDark};
  background-color: ${props => props.theme.color.accentApproving100 + '22'};
  margin-bottom: 12px;
  &:hover {
    background-color: ${props => props.theme.color.accentApproving100 + '44'};
  }
  ${props => props.theme.media.mobile`
    margin-left: 12px;
  `}
  & svg {
    fill: ${props => props.theme.color.accentApproving100};
  }
`

const IconSignStyled = styled(IconSign)`
  margin-right: 8px;
`

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SpinnerLayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: stretch;
  flex-grow: 1;
  padding: 100px 0;
`

const SpinnerContainer: React.FC = () => {
  return (
    <SpinnerWrapper>
      <SpinnerLayout />
    </SpinnerWrapper>
  )
}

const SignStepsContainer = styled.div`
  width: 100%;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const SignIframe = styled.iframe<{ loaded: boolean }>`
  border: 0;
  width: 100%;
  height: 70vh;
  display: ${props => (props.loaded ? 'block' : 'none')};
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px;
`

const ErrorText = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.secondary300};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`

const ErrorRetryButton = styled(Button)`
  margin-top: 12px;
`

const getCallbackEvent = (event: string): SignCallbackEvent => {
  const eventTypes = [
    'signing_complete',
    'viewing_complete',
    'cancel',
    'decline',
    'session_timeout',
    'ttl_expired',
    'access_code_failed',
    'id_check_failed',
    'exception',
    'fax_pending',
  ]

  const isEvent = (e: string): e is SignCallbackEvent => {
    return eventTypes.includes(e)
  }

  if (isEvent(event)) {
    return event
  }
  return 'exception'
}

const SignIframeContainer = styled.div`
  width: 100%;
`

const DocumentSigner: React.FC<{ url: string; onCallback(event: SignCallbackEvent): void }> = ({ url, onCallback }) => {
  const ref = useRef<HTMLIFrameElement>(null)
  const [progress, setProgress] = useState<Progress>(Progress.IDLE)

  useEffect(() => {
    const iframe = ref.current
    if (!iframe) {
      return
    }

    const messageHandler = (e: MessageEvent) => {
      if (e.origin !== location.origin) {
        return
      }
      if (e.data.source !== 'docusign_callback_page') {
        return
      }
      onCallback(getCallbackEvent(e.data.payload?.event))
    }

    const loadStartHandler = () => {
      setProgress(Progress.WORK)
    }
    const loadHandler = () => {
      setProgress(Progress.SUCCESS)
      iframe.scrollIntoView()
      window.scrollBy(0, -64)
    }
    const errorHandler = () => {
      setProgress(Progress.ERROR)
    }
    window.addEventListener('message', messageHandler)
    iframe.addEventListener('loadstart', loadStartHandler)
    iframe.addEventListener('load', loadHandler)
    iframe.addEventListener('error', errorHandler)
    return () => {
      window.removeEventListener('message', messageHandler)
      iframe.removeEventListener('loadstart', loadStartHandler)
      iframe.removeEventListener('load', loadHandler)
      iframe.removeEventListener('error', errorHandler)
    }
  }, [onCallback])

  return (
    <SignIframeContainer>
      {progress === Progress.IDLE || progress === Progress.WORK ? (
        <SpinnerLayoutWrapper>
          <SpinnerLayout />
        </SpinnerLayoutWrapper>
      ) : null}
      <SignIframe data-test-id={'sign-iframe'} ref={ref} src={url} loaded={progress === Progress.SUCCESS} />
    </SignIframeContainer>
  )
}

const SignCallbackContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  & > *:not(:first-child) {
    margin-top: 12px;
  }
`
const SignCallbackText = styled.div`
  text-align: center;
`

const SignCallbackViewer: React.FC<{
  event: SignCallbackEvent
  onReset(): void
  onRetry(): void
}> = ({ event, onRetry }) => {
  const generateCountryPath = useCountryPath()
  const { t } = useTranslation('profile')
  const progress = useSelector(DocumentSelectors.signSuccessCallbackProgress)

  let body: React.ReactNode = null
  const RetryButton = () => (
    <Button intent={'primary'} onClick={onRetry}>
      {t('documentItem.retryButtonText')}
    </Button>
  )

  switch (event) {
    case 'signing_complete':
      body = (
        <SignCallbackText data-test-id={'signing_complete_status'}>
          {progress === Progress.WORK ? (
            <SpinnerLayout />
          ) : (
            <>
              {t('documentItem.sign.successStatusText')}
              <br />
              <Link to={generateCountryPath(Routes.Documents)}>{t('documentItem.sign.successLinkText')}</Link>
            </>
          )}
        </SignCallbackText>
      )
      break
    case 'cancel':
    case 'decline':
      body = (
        <>
          <SignCallbackText data-test-id={'cancel_or_decline_status'}>
            {t('documentItem.sign.cancelStatusText')}
          </SignCallbackText>
          <RetryButton />
        </>
      )
      break
    case 'session_timeout':
    case 'ttl_expired':
      body = (
        <>
          <SignCallbackText data-test-id={'session_timeout_or_ttl_expired_status'}>
            {t('documentItem.sign.timeoutStatusText')}
          </SignCallbackText>
          <RetryButton />
        </>
      )
      break
    default:
      body = (
        <>
          <SignCallbackText data-test-id={'unknown_error_status'}>
            {t('documentItem.sign.errorStatusText')}
          </SignCallbackText>
          <RetryButton />
        </>
      )
  }

  return <SignCallbackContainer>{body}</SignCallbackContainer>
}

const ProfileViewerContainer = styled.div`
  width: 100%;
`
const ProfileViewerInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > :last-child {
    margin-top: 12px;
  }
`

const ProfileViewer: React.FC = () => {
  const [isFormVisible, showForm] = useBoolean(false)
  const { t } = useTranslation('profile')

  const body = isFormVisible ? (
    <LoginForm />
  ) : (
    <ProfileViewerInfoContainer data-test-id={'pre-login-form'}>
      <div>{t('documentItem.loginForm.fillProfileText')}</div>
      <Button data-test-id={'pre-login-form-button'} intent={'primary'} filled onClick={showForm}>
        {t('documentItem.loginForm.fillProfileButtonText')}
      </Button>
    </ProfileViewerInfoContainer>
  )
  return <ProfileViewerContainer>{body}</ProfileViewerContainer>
}

const PdfPreview: React.FC<{ documentId: string }> = ({ documentId }) => {
  const { t } = useTranslation('profile')
  const documentDescriptor = useDocumentFileUrl(documentId)
  const [, doc] = useDocumentItemById(documentId)
  const fetchAction = useAction(DocumentActions.itemFileUrlRequested, documentId)

  if (documentDescriptor.progress === Progress.SUCCESS) {
    return <PdfViewer url={documentDescriptor.url} name={doc?.name} />
  }
  if (documentDescriptor.progress === Progress.IDLE || documentDescriptor.progress === Progress.WORK) {
    return <SpinnerContainer />
  }
  return (
    <ErrorContainer>
      <ErrorText>{t('documentItem.previewLoadError')}</ErrorText>
      <ErrorRetryButton intent={'primary-action'} onClick={() => fetchAction()}>
        {t('documentItem.retryButtonText')}
      </ErrorRetryButton>
    </ErrorContainer>
  )
}

const SignedPdfViewer: React.FC<{ documentId: string }> = ({ documentId }) => {
  const { t } = useTranslation('profile')
  const documentDescriptor = useSignedDocumentFileUrl(documentId)
  const [, doc] = useDocumentItemById(documentId)
  const fetchAction = useAction(DocumentActions.itemSignedFileUrlRequested, documentId)
  if (documentDescriptor.progress === Progress.SUCCESS) {
    return <PdfViewer url={documentDescriptor.url} name={doc?.name} />
  }
  if (documentDescriptor.progress === Progress.IDLE || documentDescriptor.progress === Progress.WORK) {
    return <SpinnerContainer />
  }
  return (
    <ErrorContainer>
      <ErrorText>{t('documentItem.previewLoadError')}</ErrorText>
      <ErrorRetryButton intent={'primary-action'} onClick={() => fetchAction()}>
        {t('documentItem.retryButtonText')}
      </ErrorRetryButton>
    </ErrorContainer>
  )
}

const SignButton: React.FC<{ disabled: boolean; onClick(): void }> = ({ disabled, onClick }) => {
  const { t } = useTranslation('profile')
  return (
    <SignButtonStyled
      data-test-id={'sign-button'}
      disabled={disabled}
      filled={false}
      intent={'primary-action'}
      size={'big'}
      onClick={onClick}
    >
      <IconSignStyled fill="#ffcccc" />
      {t('documentsList.signButton')}
    </SignButtonStyled>
  )
}

const DocumentDetails: React.FC = () => {
  const { t } = useTranslation('profile')
  const history = useHistory()
  const generateCountryPath = useCountryPath()
  const params = useParams<{ documentId: string }>()

  const signStage = useSelector(DocumentSelectors.signStage)
  const signUrl = useSelector(DocumentSelectors.signUrl)
  const signCallbackEvent = useSelector(DocumentSelectors.signCallbackEvent)

  const [progress, item] = useDocumentItemById(params.documentId)

  const itemFetchAction = useAction(DocumentActions.itemRequested, params.documentId)
  const requestSignUrlAction = useAction(DocumentActions.signUrlRequested)
  const signCallbackReceiveAction = useAction(DocumentActions.signCallbackEventReceived, params.documentId)
  const signProgressResetAction = useAction(DocumentActions.signProcessReset)

  const handleBack = useCallback(() => history.push(generateCountryPath(Routes.Documents)), [
    generateCountryPath,
    history,
  ])

  const onReset = useCallback(() => {
    signProgressResetAction()
  }, [signProgressResetAction])

  useEffect(() => () => onReset(), [onReset])

  const beginSignProcess = async () => {
    if (item) {
      requestSignUrlAction({
        returnUrl: `${location.protocol}//${location.host}/document_sign_callback.html`,
        templateId: item.id,
      })
    }
  }

  const showSignButton =
    signStage === 'idle' && progress === Progress.SUCCESS && item?.status !== 'signed' && item?.status !== 'processing'
  const Component: React.FC<{ title: React.ReactNode; paddedOnMobile?: boolean }> = ({
    title,
    paddedOnMobile = false,
    children,
  }) => {
    return (
      <SectionContainer noDivider>
        <SectionTitle onBack={handleBack}>{title}</SectionTitle>
        <SectionBody paddedOnMobile={paddedOnMobile} noGrid>
          {showSignButton ? <SignButton disabled={!showSignButton} onClick={beginSignProcess} /> : null}
          <SignStepsContainer>{children}</SignStepsContainer>
        </SectionBody>
      </SectionContainer>
    )
  }

  if ((progress === Progress.SUCCESS && !item) || progress === Progress.ERROR) {
    return (
      <Component title={t('documentItem.title')}>
        <ErrorContainer>
          <ErrorText>{t('documentItem.loadError')}</ErrorText>
          <ErrorRetryButton intent={'primary-action'} filled={false} onClick={() => itemFetchAction()}>
            {t('documentItem.retryButtonText')}
          </ErrorRetryButton>
        </ErrorContainer>
      </Component>
    )
  }

  if (progress === Progress.WORK || !item) {
    return (
      <Component title={t('documentItem.title')}>
        <SpinnerContainer />
      </Component>
    )
  }

  if (item.status === 'signed') {
    return (
      <Component title={item.name}>
        <SignedPdfViewer documentId={item.id} />
      </Component>
    )
  }

  let body: React.ReactNode = null
  switch (signStage) {
    case 'urlFetch':
      body = <SpinnerContainer />
      break
    case 'urlLoaded':
    case 'sign':
      if (signUrl) {
        body = <DocumentSigner url={signUrl} onCallback={e => signCallbackReceiveAction(e)} />
        break
      }
    // no break statement, fallthrough to urlError if singUrl is empty
    case 'urlError':
      body = (
        <ErrorContainer>
          <ErrorText>{t('documentItem.sign.urlFetchError')}</ErrorText>
          <ErrorRetryButton intent={'primary-action'} onClick={beginSignProcess}>
            {t('documentItem.retryButtonText')}
          </ErrorRetryButton>
        </ErrorContainer>
      )
      break
    case 'userProfile':
      body = <ProfileViewer />
      break
    case 'signComplete':
      if (signCallbackEvent) {
        body = <SignCallbackViewer event={signCallbackEvent} onReset={onReset} onRetry={beginSignProcess} />
      }
      break

    case 'idle':
    default:
      body = <PdfPreview documentId={item.id} />
  }

  const paddedOnMobile = signStage === 'userProfile'
  return (
    <Component title={item.name} paddedOnMobile={paddedOnMobile}>
      {body}
    </Component>
  )
}

export default DocumentDetails

import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import Routes from './routes'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import {
  IconOrder,
  IconTruck,
  IconUser,
  IconDocument,
  MenuContainer,
  MenuItem,
  SectionTitle,
  SectionContainer,
  useDebounce,
} from '@agro-club/frontend-shared'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useDocumentList } from 'modules/domain/documents/hooks'
import useWLFeatureFlags from 'hooks/useWLFeatureFlags'
import { generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'
import { FieldLocation, FieldNames } from 'types/entities/config'
import { ProfileEvents } from 'views/pages/Profile/events'
import { useCountryPath } from 'hooks/useCountryPath'

const Title = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin: 0;

  color: ${({ theme }) => theme.color.onPrimaryDark};

  ${props => props.theme.media.mobile`
    font-size: 16px;
  `}
`

const MenuSection = styled(SectionContainer)`
  overflow-x: hidden;
`

const MenuItemsContainer = styled(MenuContainer)`
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 0;
    background-color: transparent;
  }
`

export const useNavigation = () => {
  const location = useLocation()
  const history = useHistory()

  const isCurrent = useCallback(
    (path?: string | string[]) => {
      if (Array.isArray(path)) {
        return path.some(str => location.pathname.startsWith(str))
      } else {
        return path ? location.pathname.startsWith(path) : false
      }
    },
    [location.pathname],
  )

  return {
    isCurrent,
    navigate: history.push,
  }
}

const Menu = () => {
  const { t } = useTranslation('profile')
  const { isCurrent, navigate } = useNavigation()
  const generateCountryPath = useCountryPath()
  const profile = useSelector(AuthSelectors.profile)
  const name = profile?.first_name
  const { track } = useAnalyticsSSR()
  const [, list] = useDocumentList()
  const debouncedList = useDebounce(list?.length, 300)
  const checkFeatureFlag = useWLFeatureFlags()

  const isDeliveryAddressAvailable = checkFeatureFlag(
    generateCustomFeatureFlag(FieldLocation.AccountForm, FieldNames.DeliveryAddress),
  )

  return (
    <MenuSection noDivider>
      <SectionTitle>
        <Title>{name ? t('menu.titleWithName', { name: profile?.first_name }) : t('menu.title')}</Title>
      </SectionTitle>
      <MenuItemsContainer>
        <MenuItem
          icon={IconUser}
          onClick={() => {
            track(ProfileEvents.ProfileTap, { type: 'info' })
            navigate(generateCountryPath(Routes.AccountInfo))
          }}
          active={isCurrent(Routes.AccountInfo)}
        >
          {t('menu.accountInfo')}
        </MenuItem>
        {isDeliveryAddressAvailable && (
          <MenuItem
            icon={IconTruck}
            onClick={() => {
              track(ProfileEvents.ProfileTap, { type: 'delivery_point' })
              navigate(generateCountryPath(Routes.DeliveryPoint))
            }}
            active={isCurrent(Routes.DeliveryPoint)}
          >
            {t('menu.deliveryPoint')}
          </MenuItem>
        )}
        <MenuItem
          icon={IconOrder}
          onClick={() => {
            track(ProfileEvents.ProfileTap, { type: 'orders' })
            navigate(generateCountryPath(Routes.Orders))
          }}
          active={isCurrent(Routes.Orders)}
        >
          {t('menu.orders')}
        </MenuItem>
        {!!debouncedList && (
          <MenuItem
            icon={IconDocument}
            onClick={() => {
              track(ProfileEvents.ProfileTap, { type: 'documents' })
              navigate(generateCountryPath(Routes.Documents))
            }}
            active={isCurrent(Routes.Documents)}
          >
            {t('menu.documents')}
          </MenuItem>
        )}
      </MenuItemsContainer>
    </MenuSection>
  )
}

export default Menu

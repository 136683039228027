import { useEffect } from 'react'

const useFacebookPixelScript = () => {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    const script = document.createElement('script')
    script.innerHTML = `
      !function(f,b,e,v,n,t,s){
        if(f.fbq)return;
        n=f.fbq=function(){
          n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;
          n.loaded=!0;n.version='2.0';n.queue=[];
          t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)
        }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2976349425778271');
        fbq('track', 'CompleteRegistration');
    `

    const noscript = document.createElement('noscript')

    noscript.innerHTML = `<img height="1" width="1" style="display:none"src="https://www.facebook.com/tr?id=2976349425778271&ev=PageView&noscript=1"/>`
    document.head.insertBefore(script, document.head.lastChild)
    document.head.insertBefore(noscript, document.head.lastChild)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      document.head.removeChild(script)
      document.head.removeChild(noscript)
    }
  }, [])
}

export const FacebookPixel = () => {
  useFacebookPixelScript()
  return null
}

import { endpoints } from 'modules/endpoints'
import { ListResponse } from 'types/api'
import { ApiService } from 'service/api/interface'
import { RelatedProduct, RelatedProductListRequestFilter, RelatedProductListRequestSorting } from './types'

export const getRelatedProductList = (apiClient: ApiService) => (
  id: string,
  filter?: RelatedProductListRequestFilter,
  sorting?: RelatedProductListRequestSorting,
  page?: number,
  pageSize?: number,
) => {
  return apiClient.get<ListResponse<RelatedProduct>>(endpoints.relatedProduct(id), {
    ...filter,
    ...sorting,
    page: page,
    page_size: pageSize,
  })
}

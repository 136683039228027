import styled, { StyledProps } from 'styled-components'

export const SuggestionWrapper = styled.div<StyledProps<{ isHighestDiscountReached?: boolean }>>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: ${({ theme, isHighestDiscountReached }) =>
    isHighestDiscountReached ? theme.color.backgroundBase : theme.color.primary25};
  border-radius: 8px;

  margin: 16px 0 24px 0;
  padding: 16px;

  ${props => props.theme.media.mobile`
    width: 100%;
    max-width: 100%;
  `}
`

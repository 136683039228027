import React from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'
import { useLockedBodyScroll } from '@agro-club/frontend-shared'

const DrawerWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  touch-action: none;
  -webkit-overflow-scrolling: touch;
`

const bgKeyframes = keyframes`
  0%{
    background-color: transparent;
  }
  100%{
    background-color: rgba(0, 0, 0, 0.48);
  }
`
const DrawerBg = styled.div`
  animation-name: ${bgKeyframes};
  animation-timing-function: cubic-bezier(0.06, 0.975, 0.195, 0.985);
  animation-duration: 0.3s;
  background-color: rgba(0, 0, 0, 0.48);
  position: absolute;
  width: 100%;
  height: 100%;
`

const innerKeyframes = keyframes`
  100%{
    transform: translateY(0);
  }
`

type InnerProps = {
  initialHeight: number
}

const DrawerInner = styled.div<InnerProps>`
  animation-name: ${innerKeyframes};
  animation-timing-function: cubic-bezier(0.06, 0.975, 0.195, 0.985);
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
  width: 100%;
  height: 80vh;
  overflow-y: auto;
  position: absolute;
  bottom: 0;
  transform: translateY(calc(80vh - ${props => props.initialHeight || 0}px));
  left: 0;
`

const ScrollLockHelper: React.FC<{ lock: boolean }> = ({ lock }) => {
  useLockedBodyScroll(lock)
  return null
}

class Drawer extends React.Component<{ open: boolean; onClickOutside?(): void; initialHeight?: number }> {
  root!: HTMLElement
  node!: HTMLDivElement

  componentDidMount() {
    const rootNode = document.getElementById('drawer')
    if (!rootNode) {
      throw new Error('Drawer root node not found (#drawer). Check index.html')
    }
    this.root = rootNode
    this.node = document.createElement('div')
    this.node.style.flexGrow = '1'
    this.node.style.height = '100%'
    this.root.appendChild(this.node)
  }

  componentWillUnmount() {
    this.root.removeChild(this.node)
    this.root.setAttribute('data-open', 'false')
  }

  componentDidUpdate() {
    this.root.setAttribute('data-open', this.props.open.toString())
  }

  render() {
    if (this.props.open) {
      return ReactDOM.createPortal(
        <DrawerWrapper onTouchMove={e => e.preventDefault()}>
          <DrawerBg onClick={this.props.onClickOutside} />
          <DrawerInner initialHeight={this.props.initialHeight || 0}>
            {this.props.children}
            <ScrollLockHelper lock={this.props.open} />
          </DrawerInner>
        </DrawerWrapper>,
        this.node,
      )
    } else {
      return this.props.children
    }
  }
}

export default Drawer

import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  justify-self: stretch;
  flex-grow: 1;
  padding: 120px 0;
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Header = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`

const Description = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  max-width: 410px;
`

const ImageWrapper = styled.div`
  text-align: center;
  margin-bottom: 24px;
`

export type ErrorLayoutProps = {
  header?: React.ReactNode
  description?: React.ReactNode
  imageSrc?: string
}
const ErrorLayout: React.FC<ErrorLayoutProps> = ({
  header,
  description,
  children,
  imageSrc = require('./confuse.png'),
}) => {
  const { t } = useTranslation('errorLayout')
  return (
    <Wrapper>
      <Inner>
        <ImageWrapper>
          <img src={imageSrc} alt="Emoji" width="48" height="48" />
        </ImageWrapper>
        <Header>{header || t('header')}</Header>
        <Description>{description || t('description')}</Description>
        {children}
      </Inner>
    </Wrapper>
  )
}

export default ErrorLayout

import { apiCall } from 'modules/sagaEffects'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import RelatedProductActions from './duck'
import RelatedProductSelectors from './selectors'
import { RelatedProduct } from './types'
import * as managers from './managers'
import { ListResponse } from 'types/api'
import { RequestError } from 'service/api/errors'

export const fetchList = function*() {
  try {
    let currentPage = yield select(RelatedProductSelectors.page)
    const filter = yield select(RelatedProductSelectors.filter)
    const sorting = yield select(RelatedProductSelectors.sorting)
    const pageSize = yield select(RelatedProductSelectors.pageSize)
    const id = yield select(RelatedProductSelectors.productId)
    let response: ListResponse<RelatedProduct> = yield call(
      apiCall,
      managers.getRelatedProductList,
      id,
      filter,
      sorting,
      currentPage,
      pageSize,
    )

    const pages = Math.ceil(response.total_count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(apiCall, managers.getRelatedProductList, id, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    const modifiedData = data.map(relatedProduct => ({ ...relatedProduct, id: relatedProduct.item.id }))
    yield put(RelatedProductActions.listRequestSucceed(modifiedData, total_count, page))
  } catch (err) {
    console.error(err)
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(RelatedProductActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(RelatedProductSelectors.page)
    const filter = yield select(RelatedProductSelectors.filter)
    const sorting = yield select(RelatedProductSelectors.sorting)
    const pageSize = yield select(RelatedProductSelectors.pageSize)
    const id = yield select(RelatedProductSelectors.productId)
    const { data, total_count }: { data: RelatedProduct[]; total_count: number } = yield call(
      apiCall,
      managers.getRelatedProductList,
      id,
      filter,
      sorting,
      page,
      pageSize,
    )
    const modifiedData = data.map(relatedProduct => ({ ...relatedProduct, id: relatedProduct.item.id }))
    yield put(RelatedProductActions.listRequestNextSucceed(modifiedData, total_count))
  } catch (err) {
    console.error(err)
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(RelatedProductActions.listRequestNextFailed(errType))
  }
}

const RelatedProductSaga = function*() {
  yield all([
    takeLatest(RelatedProductActions.listRequested.type, fetchList),
    takeLatest(RelatedProductActions.filterUpdated.type, fetchList),
    takeLatest(RelatedProductActions.sortingUpdated.type, fetchList),
    takeLatest(RelatedProductActions.filterHasBeenReset.type, fetchList),
    takeLatest(RelatedProductActions.sortingHasBeenReset.type, fetchList),

    takeLatest(RelatedProductActions.listRequestedNext.type, fetchListNext),
  ])
}

export default RelatedProductSaga

import { Button, ProductWizardType } from '@agro-club/frontend-shared'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useLangPicker from 'hooks/useLangPicker'
import { Card } from 'modules/domain/card/types'
import { useProducer } from 'modules/domain/producer/hooks'
import { Progress } from 'modules/types'
import { cardToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import AdderWidgetEvents from 'views/pages/Producer/Product/ProductItem/AdderWidget/events'
import ProducerRoutes from 'views/pages/Producer/routes'
import { HowItWorksButton } from 'views/ui/HowItWorks/HowItWorks'

const CustomSectionBody = styled.div`
  padding: 16px 24px 24px;
  border-radius: 16px;
  background-color: ${props => props.theme.color.onPrimaryLight};
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.04);
`

const OrderNowHint = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

const Title = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`

const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-gap: 16px;
  & > a {
    display: flex;
    flex-direction: column;
  }
`

const HowItWorksButtonStyled = styled(HowItWorksButton)`
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
`

const SpinnerContainer = styled(SpinnerLayout)`
  width: 100%;
  display: flex;
  align-items: center;
`

type PropsType = {
  card: Card
  handleWizardOpen: () => void
  progress: Progress
  productWizardType?: string
}

export const AdderWidget: FC<PropsType> = ({ card, handleWizardOpen, progress, productWizardType = '' }: PropsType) => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation(['order', 'productWizard'])
  const { pick } = useLangPicker()
  const match = useRouteMatch<{ producerSlug?: string }>(ProducerRoutes.ProducerInfoPage)
  const [, producer] = useProducer(match?.params.producerSlug)
  const { order_now_hint_i18n } = { ...producer?.config?.seller_config }

  const handleHowItWorksClick = () => track(AdderWidgetEvents.HowItWorksClick, cardToEventParams(card))

  const mainBtnTitle =
    productWizardType === ProductWizardType.Procote
      ? 'procote:adderWidget.mainBtnTitle'
      : productWizardType === ProductWizardType.DuckFoot
      ? 'productWizard:stepForm.selectMachine'
      : ''

  return (
    <CustomSectionBody>
      {order_now_hint_i18n && <OrderNowHint>{pick(order_now_hint_i18n)}</OrderNowHint>}
      <Title>{t('orderNow')}</Title>
      <ButtonsContainer>
        {progress === Progress.IDLE || progress === Progress.WORK ? (
          <SpinnerContainer size={'medium'} />
        ) : (
          <Button
            type={'submit'}
            id={'add-to-cart-button'}
            data-test-id={'add-to-cart-button'}
            intent={'primary-action'}
            filled
            onClick={handleWizardOpen}
          >
            {t(mainBtnTitle)}
          </Button>
        )}
      </ButtonsContainer>
      <HowItWorksButtonStyled
        items={producer?.config?.seller_config?.how_it_works || []}
        onClick={handleHowItWorksClick}
      />
    </CustomSectionBody>
  )
}

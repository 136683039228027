import { all, fork } from 'redux-saga/effects'

import AuthSaga from './domain/auth/sagas'
import SignupSaga from './domain/signup/sagas'
import CollectionSaga from './domain/collection/sagas'
import ProductSaga from './domain/product/sagas'
import ProducerSaga from './domain/producer/sagas'
import CartSaga from './domain/cart/sagas'
import CartSkuSaga from './domain/cartSku/sagas'
import CartWatchers from './domain/cart/watchersSaga'
import CheckoutSaga from './domain/checkout/sagas'
import CheckoutWatchers from './domain/checkout/watchersSaga'
import OrderSaga from './domain/order/sagas'
import { locationChangeWatcher } from './sagaHelpers'
import DocumentSaga from './domain/documents/sagas'
import ProductOptionsSaga from './domain/productOptions/sagas'
import PackageTypesSaga from './domain/packageTypes/sagas'
import RelatedProductSaga from './domain/relatedProduct/sagas'
import CardSaga from './domain/card/sagas'

const rootSaga = function*(): Generator {
  yield all([
    fork(AuthSaga),
    fork(SignupSaga),
    fork(CollectionSaga),
    fork(ProductSaga),
    fork(ProducerSaga),
    fork(CartSaga),
    fork(CartSkuSaga),
    fork(CheckoutSaga),
    fork(CheckoutWatchers),
    fork(OrderSaga),
    fork(CartWatchers),
    fork(locationChangeWatcher),
    fork(DocumentSaga),
    fork(ProductOptionsSaga),
    fork(PackageTypesSaga),
    fork(RelatedProductSaga),
    fork(CardSaga),
  ])
}

export default rootSaga

import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { UserGdprDTO } from './types'
import { Progress } from 'modules/types'
import { RegisterProfileDTO, UserProfile } from 'types/entities/userProfile'
import { ProfileDTO } from 'types/dto/userProfile'
import { TokensPair } from '@agro-club/frontend-shared'

export type AuthState = {
  isAuthenticated: boolean
  isProfileFulfilled: boolean
  email: string
  phone: string
  step: 'credentials' | 'sms' | 'register'
  suggestedPhone: string
  error: string
  profile?: UserProfile
  submitCredentialsProgress: Progress
  smsCodeVerificationProgress: Progress
  registerProgress: Progress
  authProgress: Progress
  authError: unknown
  profileUpdateProgress?: Progress
  uuidRequestProgress: Progress
  initProgress: Progress
  termsAgreed: boolean
  gdprSendProgress: Progress
  gdprEraseProgress: Progress
}

export type Credentials = {
  email: string
  password: string
}

export type TokenRequestFailedPayload = {
  message: string
}

const initialState: AuthState = {
  isAuthenticated: false,
  isProfileFulfilled: false,
  error: '',
  email: '',
  phone: '',
  step: 'credentials',
  suggestedPhone: '',
  profile: undefined,
  submitCredentialsProgress: Progress.IDLE,
  smsCodeVerificationProgress: Progress.IDLE,
  registerProgress: Progress.IDLE,
  authProgress: Progress.IDLE,
  authError: null,
  profileUpdateProgress: Progress.IDLE,
  uuidRequestProgress: Progress.IDLE,
  initProgress: Progress.IDLE,
  termsAgreed: false,
  gdprSendProgress: Progress.IDLE,
  gdprEraseProgress: Progress.IDLE,
}

const isProfileFulfilled = (profile: UserProfile): boolean => {
  const hasName = () => {
    return !!profile.first_name && !!profile.last_name
  }
  const hasPhone = () => {
    return !!profile.phone_number
  }
  const hasAddress = () => {
    return !!profile.legal_address
  }

  return hasName() && hasPhone() && hasAddress()
}

class AuthReducer extends ImmerReducer<AuthState> {
  emailSubmitted(email: string) {
    this.draftState.submitCredentialsProgress = Progress.WORK
    this.draftState.email = email
  }
  phoneSubmitted(phone: string) {
    this.draftState.submitCredentialsProgress = Progress.WORK
    this.draftState.phone = phone
  }
  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  submitSucceed(suggestedPhone?: string) {
    this.draftState.suggestedPhone = suggestedPhone || this.draftState.suggestedPhone || ''
    this.draftState.submitCredentialsProgress = Progress.SUCCESS
    this.draftState.step = 'sms'
    this.draftState.smsCodeVerificationProgress = Progress.IDLE
  }
  submitFailed(error: string) {
    this.draftState.submitCredentialsProgress = Progress.ERROR
    this.draftState.error = error
  }

  smsCodeVerificationRequested(_phone: string, _code: string, _analyticsPayload: any) {
    this.draftState.smsCodeVerificationProgress = Progress.WORK
  }

  smsCodeVerificationsSucceed(_payload: TokensPair, profile: UserProfile) {
    this.draftState.smsCodeVerificationProgress = Progress.SUCCESS
    this.draftState.isAuthenticated = true
    this.draftState.profile = profile

    const isFulfilled = isProfileFulfilled(profile)
    this.draftState.isProfileFulfilled = isFulfilled

    if (!isFulfilled) {
      this.draftState.step = 'register'
    } else {
      this.draftState.authProgress = Progress.SUCCESS
    }
  }

  userRegisterRequested(_profilePayload: RegisterProfileDTO, _analyticsPayload: any) {
    this.draftState.registerProgress = Progress.WORK
  }

  userRegisterSucceed(profile: UserProfile) {
    this.draftState.profile = profile
    this.draftState.isProfileFulfilled = isProfileFulfilled(profile)
    this.draftState.registerProgress = Progress.SUCCESS
    this.draftState.authProgress = Progress.SUCCESS
  }

  userRegisterFailed(err: unknown) {
    this.draftState.authProgress = Progress.ERROR
    this.draftState.registerProgress = Progress.ERROR
    this.draftState.authError = err
  }

  userRegisterCancelled() {
    this.draftState.authProgress = Progress.SUCCESS
  }

  smsCodeVerificationFailed(payload: TokenRequestFailedPayload) {
    this.draftState.error = payload.message
    this.draftState.smsCodeVerificationProgress = Progress.ERROR
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  initRequested(_authRequired?: boolean) {
    this.draftState.initProgress = Progress.WORK
  }

  initRequestSucceed(profile: UserProfile) {
    this.draftState.isAuthenticated = true
    this.draftState.profile = profile
    this.draftState.initProgress = Progress.SUCCESS
    const isFulfilled = isProfileFulfilled(profile)
    this.draftState.isProfileFulfilled = isFulfilled
    if (!isFulfilled) {
      this.draftState.step = 'register'
    }
  }

  initRequestFailed() {
    this.draftState.initProgress = Progress.ERROR
  }

  uuidRequested() {
    this.draftState.uuidRequestProgress = Progress.WORK
  }
  uuidRequestSucceed() {
    this.draftState.uuidRequestProgress = Progress.SUCCESS
  }
  uuidRequestFailed() {
    this.draftState.uuidRequestProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  userUpdateRequested(profile: Partial<ProfileDTO>) {
    this.draftState.profileUpdateProgress = Progress.WORK
  }

  userUpdateSucceed(profile: UserProfile) {
    this.draftState.profileUpdateProgress = Progress.SUCCESS
    this.draftState.profile = profile
    this.draftState.isProfileFulfilled = isProfileFulfilled(profile)
  }

  userUpdateFailed() {
    this.draftState.profileUpdateProgress = Progress.ERROR
  }

  resetUserUpdateProgress() {
    this.draftState.profileUpdateProgress = Progress.IDLE
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  gdprSendRequested(userGdprRequest: Partial<UserGdprDTO>) {
    this.draftState.gdprSendProgress = Progress.WORK
  }

  gdprSendSucceed() {
    this.draftState.gdprSendProgress = Progress.SUCCESS
  }

  gdprSendFailed() {
    this.draftState.gdprSendProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  gdprEraseRequested(userGdprRequest: Partial<UserGdprDTO>) {
    this.draftState.gdprEraseProgress = Progress.WORK
  }

  gdprEraseSucceed() {
    this.draftState.gdprEraseProgress = Progress.SUCCESS
  }

  gdprEraseFailed() {
    this.draftState.gdprEraseProgress = Progress.ERROR
  }

  setStep(step: 'sms' | 'credentials') {
    this.draftState.step = step
  }

  clearAuthProgress() {
    this.draftState.profileUpdateProgress = Progress.IDLE
    this.draftState.submitCredentialsProgress = Progress.IDLE
    this.draftState.smsCodeVerificationProgress = Progress.IDLE
    this.draftState.authProgress = Progress.IDLE
    this.draftState.step = 'credentials'
  }

  signOutRequested() {
    Object.assign(this.draftState, initialState)
  }

  setTermsAgreed(agreed: boolean) {
    this.draftState.termsAgreed = agreed
  }
}

export const AuthActions = createActionCreators(AuthReducer)
export default AuthActions
export const reducer = createReducerFunction(AuthReducer, initialState)

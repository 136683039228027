import React from 'react'
import useMatchMedia from 'hooks/useMatchMedia'
import { queries } from 'theme/theme'
import AdderWidgetDesktop from './AdderWidgetDesktop'
import AdderWidgetMobile from './AdderWidgetMobile'
import { useProductOptionsList } from 'modules/domain/productOptions/hooks'
import { CartSkuItem } from 'modules/domain/cartSku/types'
import { Card, CardBestOffer } from 'modules/domain/card/types'
import { Sku, SkuOption } from 'modules/domain/sku/types'
import { useAllPackageTypesList } from 'modules/domain/packageTypes/hooks'
import { useCombinedProgress } from 'hooks/useCombinedProgress'
import OutOfStockWidget from '../OutOfStockWidget'
import { CalculatedSkuDiscountDto } from 'modules/domain/discountsSku/types'
import { useParams } from 'react-router-dom'
import useCompanyFeatureFlags from 'hooks/useCompanyFeatureFlags'
import { generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import { FeatureFlagModifiers, FieldLocation, FieldNames } from 'types/entities/config'
import { CardWizardManager } from '../wizards/CardWizardManager'

export type AdderValues = {
  qty: number
  packageId?: string
  options: SkuOption[]
}

export type AdderWidgetProps = {
  card: Card
  sku?: Sku
  cartItem?: CartSkuItem
  values: AdderValues
  onQtyChange: (qty: number) => void
  onSkuMatched: (sku?: Sku) => void
  onPackageTypeChange: (packageId: string) => void
  onOptionsChange: (options: SkuOption[]) => void
  bestOffer?: CardBestOffer
  discounts?: CalculatedSkuDiscountDto[]
  currentDiscountAmountPerBag?: number
  nextLvlQuantity?: number
}

const AdderWidgetManager: React.FC<AdderWidgetProps> = ({ card, values, ...props }) => {
  const isMobile = useMatchMedia(queries.mobile)
  // todo get only needed options/packages from api
  const [productOptionsProgress, productOptions] = useProductOptionsList()
  const [packageTypesProgress, packageTypes = []] = useAllPackageTypesList()
  const progress = useCombinedProgress([productOptionsProgress, packageTypesProgress])

  const { producerSlug } = useParams<{ producerSlug: string }>()
  const hasAccess = useCompanyFeatureFlags(producerSlug)
  const isQtyDisabled = hasAccess(
    generateFieldModifierString(FieldLocation.CartList, FieldNames.Qty, FeatureFlagModifiers.Disabled),
  )

  if (card.wizard_id) {
    return <CardWizardManager card={card} isMobile={isMobile} />
  }

  if (card?.is_out_of_stock) {
    return <OutOfStockWidget card={card} withContainer />
  }

  if (isMobile) {
    return (
      <AdderWidgetMobile
        {...values}
        card={card}
        productOptions={productOptions || []}
        packageTypes={packageTypes || []}
        progress={progress}
        {...props}
        isQtyDisabled={isQtyDisabled}
      />
    )
  } else {
    return (
      <AdderWidgetDesktop
        {...values}
        card={card}
        productOptions={productOptions || []}
        packageTypes={packageTypes || []}
        progress={progress}
        {...props}
        isQtyDisabled={isQtyDisabled}
      />
    )
  }
}

export default AdderWidgetManager

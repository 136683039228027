import React from 'react'
import styled from 'styled-components'

import ProducerCard from 'views/ui/ProducerCard/ProducerCard'
import { useHistory, Redirect, useParams } from 'react-router-dom'
import { Routes } from '../routes'
import { useProducerList } from 'modules/domain/producer/hooks'
import { Progress } from 'modules/types'
import ErrorLayout from 'views/layouts/ErrorLayout/ErrorLayout'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { useLocation } from 'react-router-dom'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useCountryPath } from 'hooks/useCountryPath'
import { producerToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  padding: 24px 0;

  ${props => props.theme.media.tablet`
    grid-gap: 20px;
  `}
  ${props => props.theme.media.smallTablet`
    grid-template-columns: repeat(2, 1fr);
  `}
  ${props => props.theme.media.mobile`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px;
    padding: 0;
    & * {
      margin-bottom: 16px;
    }
  `}
`

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: stretch;
  flex-grow: 1;
  padding: 100px 0;
`

enum AnalyticsEvent {
  CatalogCompanyTap = 'catalog_company_tap',
}

const MainPage = () => {
  const history = useHistory()
  const { search } = useLocation()
  const { country } = useParams<{ country: string }>()
  const [progress, producers = []] = useProducerList()
  const { track } = useAnalyticsSSR()
  const generateCountryPath = useCountryPath()

  if (progress === Progress.IDLE || progress === Progress.WORK) {
    return (
      <SpinnerWrapper>
        <SpinnerLayout />
      </SpinnerWrapper>
    )
  }

  if (progress === Progress.ERROR) {
    return <ErrorLayout />
  }

  if (producers.length === 1) {
    return (
      <Redirect
        to={`${generateCountryPath(Routes.CategoryList, { country, producerSlug: producers[0].slug })}${search}`}
      />
    )
  }

  return (
    <Wrapper>
      {producers.map(item => (
        <ProducerCard
          key={item.id}
          id={item.id}
          title={item.official_name}
          description={item.description}
          logoUrl={item.logo_url}
          producerSlug={item.slug}
          onClick={() => {
            track(AnalyticsEvent.CatalogCompanyTap, producerToEventParams(item))

            const url = generateCountryPath(Routes.CategoryList, { country, producerSlug: item.slug })

            history.push(url)
          }}
        />
      ))}
    </Wrapper>
  )
}

export default MainPage

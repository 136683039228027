import { EMPTY_ARRAY } from '@agro-club/frontend-shared'
import { CartSkuItem } from 'modules/domain/cartSku/types'
import { useSkuDiscountInfo } from 'modules/domain/discountsSku/hooks'
import { CalculatedSkuDiscountDto } from 'modules/domain/discountsSku/types'
import React from 'react'
import { QtyDiscountHint } from 'views/components/QtyDiscountHint/QtyDiscountHint'

export const QtyHintSku: React.FC<{
  item: CartSkuItem
  onChange: (id: string, value: number) => void
  active?: boolean
  discounts?: CalculatedSkuDiscountDto[]
}> = ({ discounts = EMPTY_ARRAY, item, onChange, active }) => {
  const { id, quantity } = item
  const { hasDiscount, isHighestDiscountReached, nextLvlQuantity } = useSkuDiscountInfo(discounts, item.sku)

  if (!hasDiscount || !item.sku) return null

  const conversionRate = item.sku.params.package.conversion_rate

  return (
    <QtyDiscountHint // Exclude negative and zero suggestions and find min qty to apply next level discount.
      missingQty={nextLvlQuantity}
      onAddItems={amount => onChange(id, Number(quantity) + amount / conversionRate)}
      // If max suggestion for category equals to zero or negative number - it is max discount
      isHighestDiscountReached={isHighestDiscountReached}
      active={active}
    />
  )
}

import {
  helpersColor,
  IconCrossCircle,
  IconWarning,
  NumberInput,
  OptionalNumber,
  OptionalString,
  ProcoteWizardDataProps,
  ProductWizardType,
  Switch,
  Tooltip,
  useAction,
} from '@agro-club/frontend-shared'
import { ReactComponent as FCCIcon } from 'assets/images/fcc-logo.svg'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useLangPicker from 'hooks/useLangPicker'
import CartSkuActions from 'modules/domain/cartSku/duck'
import { useCartSkuList } from 'modules/domain/cartSku/hooks'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { CartSkuItem, UpdateCartSkuItemParams } from 'modules/domain/cartSku/types'
import { useProducer } from 'modules/domain/producer/hooks'
import { useProductOptionsList } from 'modules/domain/productOptions/hooks'
import { useCurrentSeasonOnce } from 'modules/domain/season/hooks'
import { Progress } from 'modules/types'
import { producerToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import { getPrettyPrice } from 'modules/utils/helpers'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { StyledProps } from 'styled-components'
import { Currency } from 'types/entities'
import { CartEvents } from 'views/components/Cart/events'
import { TruckLearnMore } from 'views/components/Cart/styles'
import * as Styled from 'views/components/CartList/styled'
import { QtyHintSku } from 'views/components/QtyHintSku/QtyHintSku'
import { WizardComment } from 'views/components/WizardNote/WizardComment'
import { BaseUnits } from 'views/pages/Producer/Card/CardItem/components/BaseUnits'

const SwitchesWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 16px;
  margin-top: 10px;
`
const SwitchText = styled.span`
  display: flex;
  align-items: flex-start;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  flex-direction: column;
`

const FCCLogo = styled(FCCIcon)`
  flex-shrink: 0;
  margin-top: 10px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Bold = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

const ItemContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 64px auto;
  grid-gap: 16px;
  padding-bottom: 16px;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => helpersColor.alphaColor(props.theme.color.secondary200, 0.24)};
  }
  &:not(:first-child) {
    margin-top: 16px;
  }

  ${props => props.theme.media.mobile`
    grid-template-columns: auto;
  `}
`
const ProductImage = styled.div`
  height: 64px;
  width: 64px;
  background: ${(props: StyledProps<{ url: string }>) => {
    if (props.url) {
      return `url(${props.url}) no-repeat center center / cover`
    } else {
      return props.theme.color.secondary50
    }
  }};
  flex-shrink: 0;
  border-radius: ${(props: StyledProps<{ url: string }>) => (props.url ? 0 : '8px')};
  opacity: ${(props: StyledProps<{ url: string }>) => (props.url ? 1 : 0.5)};
`

const ProductHeader = styled.div`
  display: grid;
  grid-template-columns: auto 40px;
`

const ProductInner = styled.div``
const ProductBody = styled.div`
  ${props => props.theme.media.desktop`
    padding-right: 40px;
  `}
`
const ProductName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`
const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
`

const InfoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;

  &:not(:first-child) {
    margin-top: 4px;
  }
`

const Label = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  display: flex;
  align-items: center;
`

const Val = styled.div`
  ${props => props.theme.media.mobile`
    justify-self: end;
  `}
`

const DiscountValue = styled.div`
  position: relative;
  &::before {
    position: absolute;
    right: 100%;
  }
`

const QtyInputStyled = styled(NumberInput)`
  width: 100%;
  ${props => props.theme.media.mobile`
    justify-self: end;
  `}
`

const SeasonHint = styled.div`
  padding: 0 16px 16px 0;
  font-size: 12px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};

  ${props => props.theme.media.mobile`
     padding-bottom: 16px;
  `}
`

const RemoveIconStyled = styled(IconCrossCircle)`
  transition: 0.3s fill;
  width: 20px;
  height: 20px;
`
const RemoveWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: 40px;
  align-items: center;
  justify-content: flex-end;
  &:hover ${RemoveIconStyled} {
    fill: ${props => props.theme.color.primary600};
  }

  ${props => props.theme.media.mobile`
    position: absolute;
    top: 0;
    right: 0;
  `}
`

const RemoveButton: React.FC<{ onClick: () => void }> = ({ onClick, ...props }) => {
  return (
    <RemoveWrapper onClick={onClick} {...props}>
      <RemoveIconStyled />
    </RemoveWrapper>
  )
}

const LegalHintContainer = styled(Tooltip)`
  cursor: pointer;
  margin-top: 18px;
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.color.onPrimaryDark};
  & > svg {
    fill: ${props => props.theme.color.accentDestructive};
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
`

const LegalHintTooltipContent = styled.div``

const QtyInput: React.FC<{
  id: string
  value: number
  onChange: (id: string, value: number) => void
  min?: number
  max?: number
  disabled?: boolean
}> = ({ id, onChange, value, max, min, disabled }) => {
  const [v, setV] = useState(value)

  useEffect(() => {
    setV(value)
  }, [value])

  const handleChange = (v: number) => {
    setV(v)
    onChange(id, v)
  }

  return (
    <QtyInputStyled
      size={'small'}
      min={min}
      max={max}
      value={v}
      onChange={handleChange}
      selectTextOnFocus={true}
      testId={'item-count'}
      inputStep={0.1}
      disabled={disabled}
    />
  )
}

const QtyCell: FC<{
  cartItem: CartSkuItem
  handleQtyUpdate: (id: string, value: number) => void
}> = ({ cartItem, handleQtyUpdate }) => {
  const { t } = useTranslation(['checkout', 'cart'])
  const { id, card, sku, quantity, added_from_crm } = { ...cartItem }

  if (card?.category?.slug === 'duck-foot') return null

  const min = OptionalNumber(sku?.min_qty)
  const max = OptionalNumber(sku?.max_qty)
  const qty = OptionalNumber(quantity)
  return (
    <InfoRow style={{ marginTop: '8px' }}>
      <Label>{t('cart.qty')}</Label>
      <QtyInput min={min} max={max} value={qty} onChange={handleQtyUpdate} id={id} disabled={added_from_crm} />
    </InfoRow>
  )
}

const ProcoteWizardDataRows: React.FC<ProcoteWizardDataProps> = ({ data }) => {
  const { t } = useTranslation('procote')

  if (!data.sku_params) return null

  const { expected_yield_unit, litres_per_tonne, category_name, expected_yield, fertilizer, acres } = data.sku_params

  return (
    <>
      {!!litres_per_tonne && (
        <InfoRow>
          <Label>{t('stepResult.tableHeader.measurement')}</Label>
          <Val>{`${parseFloat(litres_per_tonne.toString())} ${t('measures.short.lTonne')}`}</Val>
        </InfoRow>
      )}
      {!!category_name && (
        <InfoRow>
          <Label>{t('stepResult.tableHeader.crops')}</Label>
          <Val>{category_name}</Val>
        </InfoRow>
      )}
      {!!expected_yield && (
        <InfoRow>
          <Label>{t('stepResult.tableHeader.expectedYield')}</Label>
          <Val>
            {expected_yield}
            {expected_yield_unit ? ` ${expected_yield_unit}` : ''}
          </Val>
        </InfoRow>
      )}
      {!!fertilizer && (
        <InfoRow>
          <Label>{t('stepResult.tableHeader.dryFertilizer')}</Label>
          <Val>{fertilizer}</Val>
        </InfoRow>
      )}
      {!!acres && (
        <InfoRow>
          <Label>{t('stepResult.tableHeader.totalTreated')}</Label>
          <Val>{acres}</Val>
        </InfoRow>
      )}
    </>
  )
}

const CartSkuDefault: React.FC<{
  producerSlug: string
}> = ({ producerSlug }) => {
  const { t } = useTranslation(['checkout', 'cart', 'standardUnits', 'productOptions'])
  const { track } = useAnalyticsSSR()
  const { pick } = useLangPicker()
  const creditOfferAccepted = useSelector(state => CartSkuSelectors.creditOfferAccepted(state, producerSlug))
  const setCreditOfferAccepted = useAction(CartSkuActions.setCreditOfferAccepted)
  const cartInfo = useSelector(state => CartSkuSelectors.sellerCartInfo(state, producerSlug))

  const [, producer] = useProducer(producerSlug)
  const [, itemsList = []] = useCartSkuList(producerSlug)
  const [seasonProgress, season] = useCurrentSeasonOnce({ companies_id: producer?.id })

  // todo get only needed options and packages
  const [, productOptions = []] = useProductOptionsList()

  const showFCC = useMemo(() => {
    const fccAllowed = itemsList.some(item => !!item.card?.additional_options?.credit_offer_allowed)
    return fccAllowed
  }, [itemsList])

  const farmerComment = useSelector(state => CartSkuSelectors.farmerComment(state, producerSlug))
  const { truck_info } = { ...producer?.config?.seller_config }

  const itemRemoveAction = useAction(CartSkuActions.itemRemoveRequested)
  const itemUpdateAction = useAction(CartSkuActions.itemUpdateRequested)

  const handleRemove = useCallback(
    (id: string) => {
      itemRemoveAction(id)
    },
    [itemRemoveAction],
  )
  const handleUpdate = useCallback((id: string, params: UpdateCartSkuItemParams) => itemUpdateAction(id, params), [
    itemUpdateAction,
  ])

  const handleQtyUpdate = useCallback(
    (id: string, value?: number) => {
      if (producer) {
        handleUpdate(id, { quantity: OptionalString(value), seller_id: producer?.id })
      }
    },
    [handleUpdate, producer],
  )

  const formatPrice = useCallback(
    (price: number | string) => getPrettyPrice(price, producer?.currency || Currency.CAD),
    [producer],
  )

  return (
    <Wrapper>
      <SeasonHint>
        {t('common:season')}: {seasonProgress === Progress.SUCCESS && season?.title}
      </SeasonHint>
      <div data-test-id={'cart-container'}>
        {itemsList.map((cartItem: CartSkuItem) => {
          const url = cartItem.images && cartItem.images[0]
          const price = cartItem.sku?.price || 0
          const cost = cartItem.cost || 0
          const qty = OptionalNumber(cartItem.quantity)
          const discountAmount = parseFloat(cartItem.discount_amount || '') || 0
          const packageType = cartItem.sku?.params.package_title
          const options = cartItem.sku?.params.options?.map(({ option_id, params }) => {
            const option = productOptions.find(opt => opt.id === option_id)
            return {
              id: option?.id,
              type: t(`productOptions:types.${option?.type}`),
              title: pick(option?.title_i18n),
              params,
            }
          })

          return (
            <ItemContainer key={cartItem.id} data-test-id={`entry-${cartItem.id}`}>
              <div>{url ? <ProductImage url={cartItem.images[0]} /> : null}</div>
              <ProductInner>
                <ProductHeader>
                  <ProductName>{pick(cartItem.title)}</ProductName>
                  <RemoveButton
                    onClick={() => handleRemove(cartItem.id)}
                    data-test-id={`remove-entry-${cartItem.id}`}
                  />
                </ProductHeader>
                <ProductBody>
                  <ProductInfo>
                    {cartItem.sku && (
                      <InfoRow>
                        <Label>{t('standardUnits:label')}</Label>
                        <Val>
                          <BaseUnits qty={qty} sku={cartItem.sku} align={'left'} />
                        </Val>
                      </InfoRow>
                    )}
                    {cost > 0 && (
                      <InfoRow>
                        <Label style={{ fontSize: '14px', lineHeight: '20px' }}>{t('cart.cost')}</Label>
                        <Val>
                          <Bold>{formatPrice(cost)}</Bold>
                        </Val>
                      </InfoRow>
                    )}
                    {price > 0 && cartItem?.card?.category?.slug !== 'duck-foot' && (
                      <InfoRow>
                        <Label>{t('cart.price')}</Label>
                        <Val>{getPrettyPrice(price, producer?.currency || Currency.CAD, cartItem.sku?.price_type)}</Val>
                      </InfoRow>
                    )}
                    {discountAmount > 0 && (
                      <InfoRow>
                        <Label>{t('cart.discount')}</Label>
                        <Val>
                          <DiscountValue>{formatPrice(discountAmount)}</DiscountValue>
                        </Val>
                      </InfoRow>
                    )}
                    {options
                      ?.filter(o => !!o.title)
                      .map(o => (
                        <InfoRow key={o.id}>
                          <Label>{o.type}</Label>
                          <Val>
                            {o.title} {!!o.params?.percentage ? `(${o.params?.percentage}%)` : null}
                          </Val>
                        </InfoRow>
                      ))}
                    {cartItem.wizard_data?.type === ProductWizardType.Procote && (
                      <ProcoteWizardDataRows data={cartItem.wizard_data} />
                    )}
                    <QtyCell cartItem={cartItem} handleQtyUpdate={handleQtyUpdate} />
                    <InfoRow>
                      <Label>{t('cart.packageType')}</Label>
                      <Val>{packageType}</Val>
                    </InfoRow>
                  </ProductInfo>
                  {!cartItem.added_from_crm && (
                    <QtyHintSku item={cartItem} onChange={handleQtyUpdate} discounts={cartInfo?.discounts} />
                  )}
                  {cartItem.card?.docusign_document_id && cartItem.sku?.product?.legal_notes ? (
                    <LegalHintContainer
                      visibleOnHover={true}
                      tooltipContent={
                        <LegalHintTooltipContent>
                          {cartItem.sku?.product?.legal_notes.description}
                        </LegalHintTooltipContent>
                      }
                      temporary={true}
                    >
                      <IconWarning />
                      {cartItem.sku?.product?.legal_notes.text}
                    </LegalHintContainer>
                  ) : null}
                </ProductBody>
              </ProductInner>
              {cartItem.wizard_comment && !cartItem.wizard_data && <WizardComment note={cartItem.wizard_comment} />}
              {cartItem.wizard_data?.type !== ProductWizardType.Procote && (
                <Styled.PackSize>{pick(cartItem.default_packaging)}</Styled.PackSize>
              )}
            </ItemContainer>
          )
        })}
      </div>
      {farmerComment && <Styled.FarmerComment value={farmerComment} disabled />}
      {truck_info?.title_i18n && (
        <>
          <Styled.TruckText>
            {pick(truck_info.title_i18n)}
            {truck_info.learn_more_url && (
              <TruckLearnMore href={truck_info.learn_more_url} target={'blank'}>
                {t('common:learnMore')}
              </TruckLearnMore>
            )}
          </Styled.TruckText>
          {truck_info.description_i18n && (
            <Styled.TruckDescription>{pick(truck_info.description_i18n)}</Styled.TruckDescription>
          )}
        </>
      )}
      {showFCC &&
        (producerSlug != 'pitura-seeds' ? (
          <SwitchesWrapper>
            <Switch
              name={'credit-offer-switch'}
              on={creditOfferAccepted}
              onClick={val => {
                track(CartEvents.ServiceOffer, {
                  offer_type: 'credit_offer',
                  value: val,
                  ...producerToEventParams(producer),
                })
                setCreditOfferAccepted(producerSlug, val)
              }}
            />
            <SwitchText>
              {t('cart:wantToJoinProgram', { company: producer?.official_name })}
              <FCCLogo />
            </SwitchText>
          </SwitchesWrapper>
        ) : (
          <SwitchesWrapper>
            <Switch
              name={'credit-offer-switch'}
              on={creditOfferAccepted}
              onClick={val => {
                track(CartEvents.ServiceOffer, {
                  offer_type: 'credit_offer',
                  value: val,
                  ...producerToEventParams(producer),
                })
                setCreditOfferAccepted(producerSlug, val)
              }}
            />
            <SwitchText>{t('cart:wantToJoinPituraProgram')}</SwitchText>
          </SwitchesWrapper>
        ))}
    </Wrapper>
  )
}

export default CartSkuDefault

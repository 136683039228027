import { all, put, select, takeLatest } from 'redux-saga/effects'
import AuthActions from '../auth/duck'
import AuthSelectors from '../auth/selectors'
import CheckoutActions from './duck'
import { DeliveryAddressType } from './types'

const setCheckoutAddressType = function*() {
  const profile = yield select(AuthSelectors.profile)

  if (profile) {
    yield put(
      CheckoutActions.addressTypeUpdated(
        profile.self_pickup ? DeliveryAddressType.SELF_PICKUP : DeliveryAddressType.MAIN,
      ),
    )
  }
}

const WatchersSaga = function*() {
  yield all([
    takeLatest(AuthActions.initRequestSucceed.type, setCheckoutAddressType),
    takeLatest(AuthActions.userUpdateSucceed.type, setCheckoutAddressType),
    takeLatest(CheckoutActions.reset.type, setCheckoutAddressType),
  ])
}

export default WatchersSaga

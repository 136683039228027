import { Dict } from 'types/generics'
import { Currency, PriceType } from 'types/entities'
import currency from 'currency.js'
import i18n from 'i18n'

export function arrToDict<T extends { id: string }>(arr: T[]): Dict<T> {
  const result: Dict<T> = {}
  for (const item of arr) {
    result[item.id] = item
  }
  return result
}

export function convertAllToNumbers<T>(dict: { [key in keyof T]: string }): { [key in keyof T]: number } {
  return Object.keys(dict).reduce(
    (result, key) => ({ ...result, [key]: parseFloat(dict[key]) }),
    ({} as unknown) as { [key in keyof T]: number },
  )
}

export function dictToArr<T extends { id: string }>(dict: { [index: string]: T }): T[] {
  return Object.values(dict)
}

export function getIds<T extends { id: string }>(arr: T[]): string[] {
  return arr.map(item => item.id)
}

// TODO currency
export const getPrettyPrice = (num: number | string, cur: Currency, priceType?: string) => {
  let currencySymbol: string
  let pattern = '!#'
  switch (cur) {
    case Currency.CAD:
      currencySymbol = '$'
      break
    case Currency.RUB:
      currencySymbol = '₽'
      pattern = '# !'
      break
    case Currency.UAH:
      currencySymbol = '₴'
      break
    case Currency.USD:
      currencySymbol = '$'
      break
    case Currency.EUR:
      currencySymbol = '€'
      pattern = '# !'
      break
  }
  const prefix = priceType && priceType !== PriceType.None ? priceType : ''
  const formattedNumber = currency(num, { symbol: currencySymbol, pattern }).format()
  if (prefix) {
    return `${formattedNumber} ${i18n.t(`product:priceType.${prefix}`)}`
  } else {
    return formattedNumber
  }
}

import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { Progress } from 'modules/types'
import { Category, CollectionState, Country, DistributorFilter, FilterAttribute, PromoInfo, Region } from './types'
import { arrToDict } from 'modules/utils/helpers'
import { Company } from 'types/entities'
import { CountryCodeEntry } from '@agro-club/frontend-shared'

const initialState: CollectionState = {
  regions: [],
  regionsFetchProgress: Progress.IDLE,

  categories: {},
  categoriesFetchProgress: Progress.IDLE,

  companyCategories: {},
  companySubCategories: {},

  distributors: {},
  distributorsFetchProgress: Progress.IDLE,
  distributorsProducerRelation: null,

  filterAttributes: {},
  filterAttributesProducerId: null,
  filterAttributesSubCategoryId: null,
  filterAttributesProgress: Progress.IDLE,

  promoInfoProgress: Progress.IDLE,
  promoInfo: [],

  countries: [],
  countriesProgress: Progress.IDLE,

  countryPhoneCodes: [],
  countryPhoneCodesProgress: Progress.IDLE,
}

class CollectionReducer extends ImmerReducer<CollectionState> {
  regionsRequested(_country: string) {
    this.draftState.regionsFetchProgress = Progress.WORK
  }

  regionsRequestSucceed(regions: Region[]) {
    this.draftState.regions = regions
    this.draftState.regionsFetchProgress = Progress.SUCCESS
  }

  regionsRequestFailed() {
    this.draftState.regionsFetchProgress = Progress.ERROR
  }

  categoriesRequested() {
    this.draftState.categoriesFetchProgress = Progress.WORK
  }

  categoriesRequestSucceed(list: Category[]) {
    this.draftState.categoriesFetchProgress = Progress.SUCCESS
    this.draftState.categories = { ...this.draftState.categories, ...arrToDict(list) }
  }

  categoriesRequestFailed() {
    this.draftState.categoriesFetchProgress = Progress.ERROR
  }

  companyCategoriesRequested(companyId: string, _isStorefront: boolean) {
    this.draftState.companyCategories[companyId] = { progress: Progress.WORK, items: [] }
  }

  companyCategoriesRequestSucceed(companyId: string, categories: Category[]) {
    this.draftState.companyCategories[companyId] = { progress: Progress.SUCCESS, items: categories }
  }

  companyCategoriesRequestFailed(companyId: string) {
    this.draftState.companyCategories[companyId] = { progress: Progress.ERROR, items: [] }
  }

  companySubCategoriesRequested(companyId: string, categorySlug: string, _isStorefront: boolean) {
    this.draftState.companySubCategories[`${companyId}-${categorySlug}`] = { progress: Progress.WORK, items: [] }
  }

  companySubCategoriesRequestSucceed(companyId: string, categorySlug: string, categories: Category[]) {
    this.draftState.companySubCategories[`${companyId}-${categorySlug}`] = {
      progress: Progress.SUCCESS,
      items: categories,
    }
  }

  companySubCategoriesRequestFailed(companyId: string, categorySlug: string) {
    this.draftState.companySubCategories[`${companyId}-${categorySlug}`] = { progress: Progress.ERROR, items: [] }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  distributorsRequested(filter: DistributorFilter, enableGrouping: boolean) {
    this.draftState.distributorsFetchProgress = Progress.WORK
  }

  distributorsRequestSucceed(distributors: Company[], filter: DistributorFilter) {
    this.draftState.distributorsFetchProgress = Progress.SUCCESS
    this.draftState.distributors = arrToDict(distributors)
    this.draftState.distributorsProducerRelation = filter.for_producer_id ?? null
  }

  distributorsRequestError() {
    this.draftState.distributorsFetchProgress = Progress.ERROR
  }

  filterAttributesRequested(producerId: string, subCategoryId: string) {
    this.draftState.filterAttributesProgress = Progress.WORK
    this.draftState.filterAttributesSubCategoryId = subCategoryId
    this.draftState.filterAttributesProducerId = producerId
  }

  filterAttributesRequestSucceed(attributes: FilterAttribute[], producerId: string, subCategoryId: string) {
    this.draftState.filterAttributesProgress = Progress.SUCCESS
    if (!this.draftState.filterAttributes[producerId]) {
      this.draftState.filterAttributes[producerId] = {}
    }
    this.draftState.filterAttributes[producerId][subCategoryId] = attributes
  }

  filterAttributesRequestFailed() {
    this.draftState.filterAttributesProgress = Progress.ERROR
  }

  promoInfoRequested() {
    this.draftState.promoInfoProgress = Progress.WORK
  }

  promoInfoRequestSucceed(promoInfo: PromoInfo[]) {
    this.draftState.promoInfoProgress = Progress.SUCCESS
    this.draftState.promoInfo = promoInfo
  }

  promoInfoRequestFailed() {
    this.draftState.promoInfoProgress = Progress.ERROR
  }

  countryPhoneCodesRequested() {
    this.draftState.countryPhoneCodesProgress = Progress.WORK
  }

  countryPhoneCodesRequestSucceed(countryCodes: CountryCodeEntry[]) {
    this.draftState.countryPhoneCodesProgress = Progress.SUCCESS
    this.draftState.countryPhoneCodes = countryCodes
  }

  countryPhoneCodesRequestFailed() {
    this.draftState.countryPhoneCodesProgress = Progress.ERROR
  }

  countriesRequested() {
    this.draftState.countriesProgress = Progress.WORK
  }

  countriesRequestSucceed(countries: Country[]) {
    this.draftState.countriesProgress = Progress.SUCCESS
    this.draftState.countries = countries
  }

  countriesRequestFailed() {
    this.draftState.countriesProgress = Progress.ERROR
  }
}

export const CollectionActions = createActionCreators(CollectionReducer)
export default CollectionActions
export const reducer = createReducerFunction(CollectionReducer, initialState)

import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import styled, { StyledProps } from 'styled-components'
import CartActions from 'modules/domain/cart/duck'
import { useCartList } from 'modules/domain/cart/hooks'
import { CartItem, UpdateCartItemParams } from 'modules/domain/cart/types'
import useLangPicker from 'hooks/useLangPicker'
import { Currency, LocalizedValue } from 'types/entities'
import * as Styled from 'views/components/CartList/styled'
import { QtyHintDesktop } from 'views/components/QtyHintDesktop/QtyHintDesktop'
import {
  Switch,
  Input,
  IconCrossCircle,
  IconWarning,
  controlStyle,
  Tooltip,
  NumberInput,
  helpersColor,
  useAction,
  ProductWizardType,
  ProcoteWizardData,
} from '@agro-club/frontend-shared'
import { getPrettyPrice } from 'modules/utils/helpers'
import { useTranslation } from 'react-i18next'
import { CartEvents } from 'views/components/Cart/events'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useSelector } from 'react-redux'
import CartSelectors from 'modules/domain/cart/selectors'
import { ReactComponent as FCCIcon } from 'assets/images/fcc-logo.svg'
import { useProducer } from 'modules/domain/producer/hooks'
import { TruckLearnMore } from 'views/components/Cart/styles'
import useCompanyFeatureFlags from 'hooks/useCompanyFeatureFlags'
import { FeatureFlagModifiers, FieldLocation, FieldNames } from 'types/entities/config'
import { generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import ProductOptions, { Place } from 'views/components/ProductOptions/ProductOptions'
import { useProductOptionsList } from 'modules/domain/productOptions/hooks'
import { placeholderStyleFn } from 'views/components/ProductOptions/styled'
import { producerToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import { WizardComment } from 'views/components/WizardNote/WizardComment'

const SwitchesWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 16px;
  margin-top: 10px;
`
const SwitchText = styled.span`
  display: flex;
  align-items: flex-start;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  flex-direction: column;
`

const FCCLogo = styled(FCCIcon)`
  flex-shrink: 0;
  margin-top: 10px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Bold = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

const ItemContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 64px auto;
  grid-gap: 16px;
  padding-bottom: 16px;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => helpersColor.alphaColor(props.theme.color.secondary200, 0.24)};
  }
  &:not(:first-child) {
    margin-top: 16px;
  }

  ${props => props.theme.media.mobile`
    grid-template-columns: auto;
  `}
`
const ProductImage = styled.div`
  height: 64px;
  width: 64px;
  background: ${(props: StyledProps<{ url: string }>) => {
    if (props.url) {
      return `url(${props.url}) no-repeat center center / cover`
    } else {
      return props.theme.color.secondary50
    }
  }};
  flex-shrink: 0;
  border-radius: ${(props: StyledProps<{ url: string }>) => (props.url ? 0 : '8px')};
  opacity: ${(props: StyledProps<{ url: string }>) => (props.url ? 1 : 0.5)};
`

const ProductHeader = styled.div`
  display: grid;
  grid-template-columns: auto 40px;
`

const ProductInner = styled.div``
const ProductBody = styled.div`
  ${props => props.theme.media.desktop`
    padding-right: 40px;
  `}
`
const ProductName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`
const ProductDefaultPackaging = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`
const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
`

const InfoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;

  &:not(:first-child) {
    margin-top: 4px;
  }
`

const SeedTreatmentRow = styled(InfoRow)`
  margin-top: 12px;
`

const Label = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  display: flex;
  align-items: center;
`

const Val = styled.div`
  ${props => props.theme.media.mobile`
    justify-self: end;
  `}
`

const DiscountValue = styled.div`
  position: relative;
  &::before {
    position: absolute;
    right: 100%;
  }
`

const QtyInputStyled = styled(NumberInput)`
  width: 100%;
`

const RemoveIconStyled = styled(IconCrossCircle)`
  transition: 0.3s fill;
  width: 20px;
  height: 20px;
`
const RemoveWrapper = styled.div`
  display: flex;
  cursor: pointer;
  width: 40px;
  align-items: center;
  justify-content: flex-end;
  &:hover ${RemoveIconStyled} {
    fill: ${props => props.theme.color.primary600};
  }

  ${props => props.theme.media.mobile`
    position: absolute;
    top: 0;
    right: 0;
  `}
`

const RemoveButton: React.FC<{ onClick: () => void }> = ({ onClick, ...props }) => {
  return (
    <RemoveWrapper onClick={onClick} {...props}>
      <RemoveIconStyled />
    </RemoveWrapper>
  )
}

const AltPackagingContainer = styled.div`
  margin-top: 16px;
`

const AltPackagingSwitchContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 32px;
  ${props => props.theme.media.mobile`
    grid-template-columns: 1fr 1fr;
  `}
`

const AltPackagingHeader = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

const AltPackagingDescription = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  margin-top: 8px;
`

const AltPackagingInputContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  margin-top: 8px;
  grid-gap: 16px;
  ${props => props.theme.media.mobile`
    grid-template-columns: 1fr 1fr;
  `}
`

const AltPackagingInput = styled(Input)`
  width: 100px;
`

const LegalHintContainer = styled(Tooltip)`
  cursor: pointer;
  margin-top: 18px;
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.color.onPrimaryDark};
  & > svg {
    fill: ${props => props.theme.color.accentDestructive};
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
`

const LegalHintTooltipContent = styled.div``

const AltPackaging: React.FC<{
  id: string
  value?: number | null
  altPackagingTitle?: LocalizedValue
  onChange: (id: string, value: number | null) => void
}> = ({ id, value, altPackagingTitle, onChange }) => {
  const { t } = useTranslation('checkout')
  const [v, setV] = useState(value)
  const [isOn, setIsOn] = useState(!!value)

  useEffect(() => {
    setV(value)
  }, [value])
  const { pick } = useLangPicker()

  const handleSwitchChange = () => {
    if (isOn) {
      onChange(id, 0)
      setIsOn(false)
      setV(0)
    } else {
      onChange(id, 1)
      setIsOn(true)
      setV(1)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newV = parseInt(e.target.value, 10) || 0
    setV(newV)
    onChange(id, newV)
  }

  return (
    <AltPackagingContainer>
      <AltPackagingSwitchContainer>
        <AltPackagingHeader>{t('cart.addCustomPackaging')}</AltPackagingHeader>
        <Val>
          <Switch on={isOn} onClick={handleSwitchChange} name={'alt-packaging'} />
        </Val>
      </AltPackagingSwitchContainer>
      <AltPackagingDescription>{t('cart.customPackagingDescription')}</AltPackagingDescription>
      {isOn ? (
        <AltPackagingInputContainer>
          <Label>{pick(altPackagingTitle)}</Label>
          <Val>
            <AltPackagingInput
              inputSize={'small'}
              name={'alt-packaging-input'}
              type={'number'}
              value={v || 0}
              onChange={handleChange}
            />
          </Val>
        </AltPackagingInputContainer>
      ) : null}
    </AltPackagingContainer>
  )
}

const QtyInput: React.FC<{
  id: string
  value: number
  onChange: (id: string, value: number) => void
  min?: number
  max?: number
  disabled: boolean
}> = ({ id, onChange, value, max, min, disabled }) => {
  const [v, setV] = useState(value)

  useEffect(() => {
    setV(value)
  }, [value])

  const handleChange = (v: number) => {
    setV(v)
    onChange(id, v)
  }

  return (
    <QtyInputStyled
      size={'small'}
      min={min}
      max={max}
      value={v}
      onChange={handleChange}
      selectTextOnFocus={true}
      testId={'item-count'}
      disabled={disabled}
    />
  )
}

const QtyCell: FC<{
  cartItem: CartItem
  handleQtyUpdate: (id: string, value: UpdateCartItemParams['quantity']) => void
  showUnits: boolean
  disabled: boolean
}> = ({ cartItem, handleQtyUpdate, showUnits, disabled }) => {
  const { t } = useTranslation(['checkout', 'cart'])
  if (cartItem?.product?.category?.slug === 'duck-foot') return null
  const min = cartItem.product.min_qty
  const max = cartItem.product.max_qty
  const qty = Number(cartItem.qty)
  return (
    <InfoRow style={{ marginTop: '8px' }}>
      <Label>{t('cart.qty')}</Label>
      <QtyInput min={min} max={max} value={qty} onChange={handleQtyUpdate} id={cartItem.id} disabled={disabled} />
      {showUnits && (
        <Styled.UnitsContainerLeft>
          {qty > 1 ? cartItem?.product?.units?.plural : cartItem?.product?.units?.singular}
        </Styled.UnitsContainerLeft>
      )}
    </InfoRow>
  )
}

const SeedTreatmentSelect: React.FC<{
  cartItem: CartItem
  onChange: (id: string, treatmentId: string) => void
  seedTreatmentValid: boolean
}> = ({ cartItem, onChange, seedTreatmentValid = true }) => {
  const { t } = useTranslation(['checkout', 'productOptions'])
  const [v, setV] = useState(cartItem.seed_treatment_id)

  useEffect(() => {
    setV(cartItem.seed_treatment_id)
  }, [cartItem.seed_treatment_id])

  const { pick } = useLangPicker()
  const treatmentOptions = useMemo(
    () =>
      cartItem.product.seed_treatment
        .filter(s => s.is_active)
        .map((st: { title_i18n: LocalizedValue; id: string }) => ({
          title: pick(st.title_i18n),
          id: st.id,
        })),
    [cartItem.product.seed_treatment, pick],
  )

  const seedTreatmentErrorText = useMemo(() => (!seedTreatmentValid ? t('validation:field_required') : ''), [
    seedTreatmentValid,
    t,
  ])

  const getSeedTreatmentSelectStyle = useMemo(() => ({ marginBottom: !seedTreatmentValid ? '20px' : 'initial' }), [
    seedTreatmentValid,
  ])

  const handleChange = (v: string) => {
    setV(v)
    onChange(cartItem.id, v)
  }

  return treatmentOptions.length ? (
    <SeedTreatmentRow style={getSeedTreatmentSelectStyle}>
      <Label>{t('cart.seedTreatment')}</Label>
      <Styled.SeedTreatmentSelect
        name={'seed-treatment-select'}
        customStyles={{
          control: (base, props) => ({ ...controlStyle(base, props), minHeight: '32px', height: '32px' }),
          placeholder: placeholderStyleFn,
        }}
        options={treatmentOptions}
        value={v}
        onChange={handleChange}
        invalid={!seedTreatmentValid}
        errorText={seedTreatmentErrorText}
        placeholder={`${t('productOptions:placeholderPrefix')} ${t(
          `productOptions:types.seed_treatment`,
        ).toLowerCase()}`}
      />
    </SeedTreatmentRow>
  ) : null
}

const CartDefault: React.FC<{
  producerSlug: string
  optionsRequiredErrors: string[][]
  seedTreatmentErrors: boolean[]
  requiredOptionsValid: (id: string, updatedOptions?: string[], updatedSeedTreatment?: string) => boolean
  isQtyDisabled: boolean
}> = ({ producerSlug, optionsRequiredErrors, seedTreatmentErrors, requiredOptionsValid, isQtyDisabled }) => {
  const { t } = useTranslation(['checkout', 'cart'])
  const { track } = useAnalyticsSSR()
  const { pick } = useLangPicker()
  const creditOfferAccepted = useSelector(state => CartSelectors.creditOfferAccepted(state, producerSlug))
  const setCreditOfferAccepted = useAction(CartActions.setCreditOfferAccepted)
  const hasAccess = useCompanyFeatureFlags(producerSlug)
  const [, producer] = useProducer(producerSlug)
  const [, itemsList = []] = useCartList(producerSlug)
  const [, productOptions] = useProductOptionsList()
  const farmerComment = useSelector(state => CartSelectors.farmerComment(state, producerSlug))

  const showUnits = hasAccess(
    generateFieldModifierString(FieldLocation.CartList, FieldNames.Units, FeatureFlagModifiers.Enabled),
  )
  const showFCC = useMemo(() => {
    const creditOffer = producer?.credit_offer
    const fccAllowed = itemsList.some(item => !!item.product.additional_options?.credit_offer_allowed)
    return creditOffer && fccAllowed
  }, [itemsList, producer?.credit_offer])

  const { truck_info } = { ...producer?.config?.seller_config }

  const itemRemoveAction = useAction(CartActions.itemRemoveRequested)
  const itemUpdateAction = useAction(CartActions.itemUpdateRequested)
  const handleRemove = useCallback(
    (id: string) => {
      itemRemoveAction(id)
      requiredOptionsValid(id)
    },
    [itemRemoveAction, requiredOptionsValid],
  )
  const handleUpdate = useCallback((id: string, params: UpdateCartItemParams) => itemUpdateAction(id, params), [
    itemUpdateAction,
  ])

  const handlePackagingUpdate = useCallback(
    (id: string, value: UpdateCartItemParams['packaging']) => handleUpdate(id, { packaging: value }),
    [handleUpdate],
  )
  const handleQtyUpdate = useCallback(
    (id: string, value: UpdateCartItemParams['quantity']) => {
      handleUpdate(id, { quantity: value })
    },
    [handleUpdate],
  )
  const handleSeedTreatmentUpdate = useCallback(
    (id: string, value: UpdateCartItemParams['seed_treatment_id']) => {
      handleUpdate(id, { seed_treatment_id: value })
      requiredOptionsValid(id, undefined, value)
    },
    [handleUpdate, requiredOptionsValid],
  )

  const handleOptionsUpdate = useCallback(
    (id: string, options: UpdateCartItemParams['options']) => {
      handleUpdate(id, { options })
      requiredOptionsValid(id, options)
    },
    [handleUpdate, requiredOptionsValid],
  )

  const formatPrice = useCallback(
    (price: number | string) => getPrettyPrice(price, producer?.currency || Currency.CAD),
    [producer],
  )

  return (
    <Wrapper>
      <div data-test-id={'cart-container'}>
        {itemsList.map((cartItem: CartItem, idx: number) => {
          const url = cartItem.images && cartItem.images[0]
          const price = cartItem.product.price || 0
          const cost = cartItem.cost || 0
          const discountAmount = parseFloat(cartItem.discount_amount || '') || 0

          const isCustomPackagingAvailable = !!cartItem.product.alt_packaging
          return (
            <ItemContainer key={cartItem.id} data-test-id={`entry-${cartItem.id}`}>
              <div>{url ? <ProductImage url={cartItem.images[0]} /> : null}</div>
              <ProductInner>
                <ProductHeader>
                  <div>
                    <ProductName>{pick(cartItem.title)}</ProductName>
                    {cartItem.wizard_comment && !cartItem.wizard_data && (
                      <>
                        <ProductDefaultPackaging>{pick(cartItem.default_packaging)}</ProductDefaultPackaging>
                        <WizardComment note={cartItem.wizard_comment} />
                      </>
                    )}
                    {cartItem.wizard_data?.type === ProductWizardType.Procote && (
                      <ProcoteWizardData data={cartItem.wizard_data} />
                    )}
                  </div>
                  <RemoveButton
                    onClick={() => handleRemove(cartItem.id)}
                    data-test-id={`remove-entry-${cartItem.id}`}
                  />
                </ProductHeader>
                <ProductBody>
                  <ProductInfo>
                    {cost > 0 && (
                      <InfoRow>
                        <Label style={{ fontSize: '14px', lineHeight: '20px' }}>{t('cart.cost')}</Label>
                        <Val>
                          <Bold>{formatPrice(cost)}</Bold>
                        </Val>
                      </InfoRow>
                    )}
                    {price > 0 && cartItem?.product?.category?.slug !== 'duck-foot' && (
                      <InfoRow>
                        <Label>{t('cart.price')}</Label>
                        <Val>
                          {getPrettyPrice(price, producer?.currency || Currency.CAD, cartItem.product.price_type)}
                        </Val>
                      </InfoRow>
                    )}
                    {discountAmount > 0 && (
                      <InfoRow>
                        <Label>{t('cart.discount')}</Label>
                        <Val>
                          <DiscountValue>{formatPrice(discountAmount)}</DiscountValue>
                        </Val>
                      </InfoRow>
                    )}
                    <SeedTreatmentSelect
                      cartItem={cartItem}
                      onChange={handleSeedTreatmentUpdate}
                      seedTreatmentValid={seedTreatmentErrors[idx]}
                    />
                    <ProductOptions
                      selectedIds={cartItem.options || []}
                      options={cartItem.product.options}
                      onChange={value => handleOptionsUpdate(cartItem.id, value)}
                      optionsRequiredErrors={optionsRequiredErrors[idx]}
                      place={Place.CHECKOUT}
                      productOptions={productOptions || []}
                    />
                    <QtyCell
                      cartItem={cartItem}
                      handleQtyUpdate={handleQtyUpdate}
                      showUnits={showUnits}
                      disabled={isQtyDisabled}
                    />
                  </ProductInfo>
                  <QtyHintDesktop item={cartItem} onChange={handleQtyUpdate} />
                  {isCustomPackagingAvailable ? (
                    <AltPackaging
                      id={cartItem.id}
                      altPackagingTitle={cartItem.alt_packaging}
                      value={cartItem.packaging}
                      onChange={handlePackagingUpdate}
                    />
                  ) : null}
                  {cartItem.product.docusign_document_id && cartItem.product.legal_notes ? (
                    <LegalHintContainer
                      visibleOnHover={true}
                      tooltipContent={
                        <LegalHintTooltipContent>{cartItem.product.legal_notes.description}</LegalHintTooltipContent>
                      }
                      temporary={true}
                    >
                      <IconWarning />
                      {cartItem.product.legal_notes.text}
                    </LegalHintContainer>
                  ) : null}
                </ProductBody>
              </ProductInner>
            </ItemContainer>
          )
        })}
      </div>
      {farmerComment && <Styled.FarmerComment value={farmerComment} disabled />}
      {truck_info?.title_i18n && (
        <>
          <Styled.TruckText>
            {pick(truck_info.title_i18n)}
            {truck_info.learn_more_url && (
              <TruckLearnMore href={truck_info.learn_more_url} target={'blank'}>
                {t('common:learnMore')}
              </TruckLearnMore>
            )}
          </Styled.TruckText>
          {truck_info.description_i18n && (
            <Styled.TruckDescription>{pick(truck_info.description_i18n)}</Styled.TruckDescription>
          )}
        </>
      )}
      {showFCC &&
        (producerSlug != 'pitura-seeds' ? (
          <SwitchesWrapper>
            <Switch
              name={'credit-offer-switch'}
              on={creditOfferAccepted}
              onClick={val => {
                track(CartEvents.ServiceOffer, {
                  offer_type: 'credit_offer',
                  value: val,
                  ...producerToEventParams(producer),
                })
                setCreditOfferAccepted(producerSlug, val)
              }}
            />
            <SwitchText>
              {t('cart:wantToJoinProgram', { company: producer?.official_name })}
              <FCCLogo />
            </SwitchText>
          </SwitchesWrapper>
        ) : (
          <SwitchesWrapper>
            <Switch
              name={'credit-offer-switch'}
              on={creditOfferAccepted}
              onClick={val => {
                track(CartEvents.ServiceOffer, {
                  offer_type: 'credit_offer',
                  value: val,
                  ...producerToEventParams(producer),
                })
                setCreditOfferAccepted(producerSlug, val)
              }}
            />
            <SwitchText>{t('cart:wantToJoinPituraProgram')}</SwitchText>
          </SwitchesWrapper>
        ))}
    </Wrapper>
  )
}

export default CartDefault

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button, IconCrossCircle } from '@agro-club/frontend-shared'
import useCookieValue from 'hooks/useCookieValue'
import useWhiteLabelConfig from 'hooks/useWhiteLabelConfig'
import useLangPicker from 'hooks/useLangPicker'

const CloseStyled = styled(IconCrossCircle)`
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
  width: 20px;
  height: 20px;
`

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.onPrimaryLight};
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 20px 24px 24px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 320px;
  z-index: ${props => props.theme.zIndex.popup};

  ${props => props.theme.media.mobile`
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  `}
`

const Heading = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.onSurfaceHighEmphasys};
`

const Text = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
  margin: 12px 0 24px;
`

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.primary50};
`

const CookiesPolicy = React.memo(() => {
  const [cookie, setCookie] = useCookieValue('cookie_policy')
  const closeCookiesPopup = () => setCookie('1')
  const { t, i18n } = useTranslation('cookiesPolicy')
  const lang = i18n.language.slice(0, 2)
  const { config: wlConfig } = useWhiteLabelConfig()
  const { pick } = useLangPicker()
  const config = wlConfig?.legal_docs

  if (cookie) {
    return null
  }

  const termsOfUse = `
    <a style="color: #037bff;cursor: pointer;" href="${
      config?.user_agreement ? pick(config.user_agreement) : `/agreement_${lang}.pdf`
    }" target="_blank" rel="noopener noreferrer">${t('termsLinkText')}</a>
  `
  // config?.privacy_policy ? pick(config.privacy_policy) : `/privacy-policy_${lang}.pdf`
  const privacyPolicy = `
    <a style="color: #037bff;cursor: pointer;" href="https://canterra.com/privacy-policy/" target="_blank" rel="noopener noreferrer">${t(
      'privacyPolicyText',
    )}</a>
  `

  return (
    <Wrapper data-test-id={'cookie-policy-popup'}>
      <CloseStyled onClick={closeCookiesPopup} />
      <Heading>{t('heading')}</Heading>
      <Text dangerouslySetInnerHTML={{ __html: t('text', { termsOfUse, privacyPolicy }) }} />
      <StyledButton intent={'minor-action'} filled onClick={closeCookiesPopup} data-test-id="accept-cookies">
        {t('acceptText')}
      </StyledButton>
    </Wrapper>
  )
})
CookiesPolicy.displayName = 'CookiesPolicy'

export default CookiesPolicy

import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import ErrorLayout from '../ErrorLayout/ErrorLayout'

const MaintenanceLayout = () => {
  const { t } = useTranslation('maintenanceLayout')

  return (
    <div data-test-id="maintenance-mode">
      <Helmet>
        <title>{t('header')}</title>
        <meta name="description" content={t('description')} />
      </Helmet>
      <ErrorLayout header={t('header')} description={t('description')} imageSrc={require('./gear.png')} />
    </div>
  )
}

export default MaintenanceLayout

import React, { memo, useMemo } from 'react'
import styled, { StyledProps } from 'styled-components'
import QuoteIcon from './quote.svg'
import { Button, IconCheckCircle, IconDocument, IconLinkOpener } from '@agro-club/frontend-shared'
import { LocalizedValue, Currency } from 'types/entities'
import useLangPicker from 'hooks/useLangPicker'
import { useTranslation } from 'react-i18next'
import DiscountBannerComponent from 'views/ui/DiscountBanner/DiscountBanner'
import { getPrettyPrice } from 'modules/utils/helpers'
import { HowItWorksButton } from 'views/ui/HowItWorks/HowItWorks'
import { ProductUnits } from 'modules/domain/product/types'

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 40px;
`

export const InnerContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  ${props => props.theme.media.desktop`
    grid-template-columns: 978px 278px;
    grid-template-rows: max-content auto;
    align-items: start;
    align-content: start;
    grid-auto-flow: row;
  `}

  ${props => props.theme.media.smallDesktop`
    grid-template-columns: auto 278px;
  `}
  ${props => props.theme.media.tablet`
    grid-template-columns: 658px 278px;
    grid-template-rows: max-content;
    align-items: start;
    align-content: start;
    grid-auto-flow: row;
  `}
  ${props => props.theme.media.smallTablet`
    grid-template-columns: auto 278px;
  `}
  ${props => props.theme.media.mobile`
    grid-template-columns: auto;
    padding: 0 16px;
    background-color: ${(props: StyledProps<{}>) => props.theme.color.onPrimaryLight};
  `}
`

export const MainSectionWrapper = styled.div`
  grid-row: 1 / span 2;
  ${props => props.theme.media.mobile`
    width: 100%;
  `}
`

export const MainSection = styled.div`
  display: grid;
  grid-template-columns: 240px 480px auto;
  grid-template-rows: auto;
  grid-gap: 8px 24px;
  grid-template-areas:
    'photoBox content badges';
  ${props => props.theme.media.tablet`
    grid-template-columns: auto 70px;
    grid-template-rows: auto;
    grid-template-areas:
      'photoBox photoBox'
      'title badges'
      'content content';
  `}
  ${props => props.theme.media.smallTablet`
    width: 100%;
    grid-template-columns: 1fr 70px;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'photoBox photoBox'
      'badges badges'
      'content content';
  `}
  ${props => props.theme.media.mobile`
    width: 100%;
    grid-template-columns: 1fr 70px;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'photoBox photoBox'
      'price price'
      'badges badges'
      'content content';
  `};
`

export const Content = styled.div`
  grid-area: content;
`

export const MainPhoto = styled.div`
  background-repeat: no-repeat;

  width: 240px;
  height: 240px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  justify-self: center;

  ${props => props.theme.media.tablet`
    width: 100%;
  `}

  ${props => props.theme.media.mobile`
    width: 100%;
  `}

  & > img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    ${props => props.theme.media.tablet`
      width: auto;
      max-height: 240px;
    `}
    ${props => props.theme.media.mobile`
      width: auto;
      max-height: 240px;
    `}
  }
`

export const PhotoGallery = styled.div`
  margin: 24px 0px;
  background-repeat: no-repeat;

  width: 240px;
  height: auto;
  flex-wrap: wrap;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-self: center;

  ${props => props.theme.media.tablet`
    width: 100%;
  `}

  ${props => props.theme.media.mobile`
    width: 100%;
  `}

  & > img {
    object-fit: cover;
    max-width: 90px;
    max-height: 90px;

    ${props => props.theme.media.tablet`
      width: auto;
      max-height: 90px;
    `}
    ${props => props.theme.media.mobile`
      width: auto;
      max-height: 90px;
    `}
  }
`

export const PhotoGalleryItem = styled.div`
  width: 90px;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-self: center;
  margin: 0 8px 8px 0;

  ${props => props.theme.media.tablet`
    width: 90px;
  `}
  ${props => props.theme.media.mobile`
    width: 90px;
  `}

    & > img {
    object-fit: cover;
    max-width: 90px;
    max-height: 90px;
    border-radius: 5%;
    ${props => props.theme.media.tablet`
      width: 90px;
      max-height: 90px;
    `}
    ${props => props.theme.media.mobile`
      width: 90px;
      max-height: 90px;
    `}
  }

  img:hover {
    border: 2px solid #f7901e;
  }
`

export const PhotoBox = styled.div`
  grid-area: photoBox;
  width: 240px;
  height: auto;
  border-radius: 8px;
  display: flex-block;
  flex-direction: row;
  justify-self: center;

  ${props => props.theme.media.tablet`
    width: 100%;
  `}
  ${props => props.theme.media.mobile`
    width: 100%;
  `}
`

export const BadgeListContainer = styled.div`
  grid-area: badges;
  overflow: hidden;
  ${props => props.theme.media.desktop`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    align-self: flex-start;
    justify-self: flex-end;
    & > * {
      justify-self: center;
      align-self: center;
    }
  `}
  ${props => props.theme.media.tablet`
    display: flex;
    flex-wrap: wrap;
    overflow: visible;
    align-items: center;
    justify-content: flex-start;
    min-height: 40px;
    & > img {
      margin-bottom: 10px;
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  `}
  ${props => props.theme.media.mobile`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    min-height: 40px;
    & > img {
      margin-bottom: 10px;
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  `}
`

export const Title = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin: 0 0 8px;

  grid-area: title;

  ${props => props.theme.media.mobile`
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
  `}

  & > *:first-child {
    margin-right: 18px;
  }
`

export const Container = styled.div`
  margin-top: 16px;
`

export const BadgeContainer = styled.div`
  margin-top: 2px;
  margin-left: 5px;
`

export const Description = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  ${props => props.theme.media.mobile`
    font-size: 14px;
    line-height: 20px;
  `}
`

const ExtrasContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-self: start;
`

const ExtrasIconContainer = styled.div`
  margin-right: 18px;
  display: flex;
`

const CheckCircleStyled = styled(IconCheckCircle)`
  fill: ${({ theme }) => theme.color.primary600};
  width: 20px;
  height: 20px;
`

const ExtrasItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  &:not(:first-child) {
    margin-top: 8px;
  }
`

const ExtrasDescription = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-left: 4px;
  color: ${props => props.theme.color.secondary200};
`

const ExtrasTitle = styled.div`
  display: flex;
  font-weight: 600;
  justify-content: center;
  padding: 16px 0px;
`

export const Extras: React.FC<{
  items: { title: LocalizedValue; description?: LocalizedValue; icon?: React.ReactNode }[]
}> = ({ items }) => {
  const { pick } = useLangPicker()
  return (
    <ExtrasContainer>
      {items.map(({ title, icon, description }, idx) => {
        if (pick(title).startsWith('***')) {
          return <ExtrasTitle key={idx}>{pick(title).split('***')[1]}</ExtrasTitle>
        }

        return (
          <ExtrasItem key={idx}>
            <ExtrasIconContainer>{icon || <CheckCircleStyled />}</ExtrasIconContainer>
            {pick(title)}
            {description ? <ExtrasDescription>{pick(description)}</ExtrasDescription> : null}
          </ExtrasItem>
        )
      })}
    </ExtrasContainer>
  )
}

const SpecsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-self: start;
  font-size: 16px;
  line-height: 24px;
  width: 100%;

  ${props => props.theme.media.mobile`
    margin-top: 24px;
    font-size: 14px;
    line-height: 20px;
    justify-self: auto;
  `}
`
const SpecsItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  &::after {
    content: '';
    display: block;
    flex-grow: 1;
    order: 2;
    color: ${props => props.theme.color.onSurfaceLowEmphasys};
    background-image: radial-gradient(circle, currentcolor 1px, transparent 1px);
    background-position-y: 15px;
    background-size: 4px 2px;
    background-repeat: repeat-x;
    margin: 0 8px;
  }
  &:not(:first-child) {
    margin-top: 6px;
  }
`
const SpecsKey = styled.div`
  flex-grow: 0;
  font-size: 16px;
  line-height: 24px;
  order: 1;
  font-weight: 600;
`
const SpecsValue = styled.div`
  flex-grow: 0;
  order: 3;
  max-width: 50%;
  text-align: right;
`
export const Specs: React.FC<{ items: { title: LocalizedValue; value: LocalizedValue }[] }> = memo(({ items }) => {
  const { pick } = useLangPicker()
  const attrs = useMemo(() => {
    const buf: Record<string, string[]> = {}
    items.forEach(attr => {
      const title = pick(attr.title)
      const value = pick(attr.value)
      if (!buf[title]) {
        buf[title] = []
      }
      buf[title].push(value)
    })

    const result = Object.entries(buf).map(([title, value]) => {
      return {
        title,
        value: value.filter(Boolean).join(';'),
      }
    })
    return result
  }, [items, pick])

  return (
    <SpecsContainer>
      {attrs.map((item, idx) => (
        <SpecsItemContainer key={idx}>
          <SpecsKey>{item.title}</SpecsKey>
          <SpecsValue dangerouslySetInnerHTML={{ __html: item.value }} />
        </SpecsItemContainer>
      ))}
    </SpecsContainer>
  )
})
Specs.displayName = 'Specs'

export const Footer = styled.div`
  margin-top: 24px;
  justify-self: start;

  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  ${props => props.theme.media.mobile`
    justify-self: auto;
    margin-top: 24px;
  `}
`

const FooterButtonStyled = styled(Button)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: 0;
`

const DocumentIconStyled = styled(IconDocument)`
  margin-right: 8px;
`
const LinkIconStyled = styled(IconLinkOpener)`
  margin-right: 8px;
  color: ${props => props.theme.color.primary600};
`

export const FooterButton: React.FC<{
  to?: string
  icon: 'document' | 'link'
  target?: string
  onClick?: () => void
}> = ({ to, children, icon, target, onClick }) => {
  const iconComponent = icon === 'link' ? <LinkIconStyled /> : <DocumentIconStyled />
  return (
    <FooterButtonStyled intent={'secondary'} filled size={'big'} onClick={onClick}>
      {to ? (
        <a href={to} target={target}>
          {iconComponent}
          {children}
        </a>
      ) : (
        <>
          {iconComponent}
          {children}
        </>
      )}
    </FooterButtonStyled>
  )
}

export const DiscountBannerWrapper = styled.div`
  margin-top: 24px;
`

export const QuoteContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${props => props.theme.color.primary50};
  font-size: 16px;
  line-height: 24px;
  padding: 40px 24px 16px;

  ${props => props.theme.media.desktop`
    margin-top: 48px;
  `}

  ${props => props.theme.media.tablet`
    margin-top: 40px;
  `}

  ${props => props.theme.media.mobile`
    margin-top: 24px;
  `}
  position: relative;
  &::before {
    position: absolute;
    top: -24px;
    left: 24px;
    width: 64px;
    height: 64px;
    content: '';
    background-image: url("${QuoteIcon}");
  }
`

export const QuoteText = styled.div``
export const QuoteAuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;

  > *:not(:first-child) {
    margin-left: 4px;
  }
`

export const QuoteAuthor = styled.div`
  font-weight: bold;
`

export const LegalText = styled.div`
  margin-top: 24px;
  border-top: 1px solid ${props => props.theme.color.onSurfaceLowEmphasys};
  padding-top: 16px;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

export const VideoIFrame = styled.iframe`
  margin-top: 40px;
  ${props => props.theme.media.mobile`
    margin-top: 24px;
  `}
`

const QtyHintWrapper = styled.div<StyledProps<{ isHighestDiscountReached: boolean }>>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: ${({ theme, isHighestDiscountReached }) =>
    isHighestDiscountReached ? theme.color.backgroundBase : theme.color.primary25};
  border-radius: 8px;

  margin-bottom: 16px;
  padding: 8px;
  & > :last-child {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    text-align: center;

    color: ${({ theme, isHighestDiscountReached }) =>
      isHighestDiscountReached ? theme.color.accentApproving : theme.color.secondary300};
  }

  ${props => props.theme.media.mobile`
    width: 100%;
    max-width: 100%;
  `}
`

const CurrentDiscountAmount = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  text-align: center;
  margin-bottom: 8px;

  color: ${({ theme }) => theme.color.accentApproving};
`

export const AddItemsButton = styled.button`
  outline: none;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  margin-bottom: 4px;

  text-align: center;
  text-decoration-line: underline;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.color.primary600};
`

type DiscountSuggestionProps = {
  currency: Currency
  nextLvlQty: number
  currentDiscountAmount: number
  onQtyAdd?: (qty: number) => void
  isCartEmpty?: boolean
  units?: ProductUnits
  disabled?: boolean
}

export const DiscountSuggestion: React.FC<DiscountSuggestionProps> = ({
  currency,
  nextLvlQty,
  onQtyAdd,
  currentDiscountAmount,
  isCartEmpty = false,
  units,
  disabled,
}) => {
  const { t } = useTranslation(['cart', 'common'])
  const isHighestDiscountReached = nextLvlQty <= 0
  const discountAmountString = getPrettyPrice(currentDiscountAmount, currency)
  return (
    <QtyHintWrapper isHighestDiscountReached={isHighestDiscountReached}>
      {!isHighestDiscountReached ? (
        <>
          <CurrentDiscountAmount>
            {currentDiscountAmount !== 0
              ? t('yourSaving', { amount: discountAmountString, units: units?.singular || t('common:bag') })
              : t('startSaving')}
          </CurrentDiscountAmount>
          <AddItemsButton onClick={() => onQtyAdd?.(nextLvlQty)} disabled={disabled}>
            {isCartEmpty ? t('addAmount', { amount: nextLvlQty }) : t('addMore', { amount: nextLvlQty })}
          </AddItemsButton>
          <div>{currentDiscountAmount ? t('getDiscountHint') : t('toYourTruck')}</div>
        </>
      ) : (
        <div>
          {currentDiscountAmount
            ? t('reachedMaxDiscountAmount', {
                amount: discountAmountString,
                units: units?.singular || t('common:bag'),
              })
            : t('reachedMaxDiscount')}
        </div>
      )}
    </QtyHintWrapper>
  )
}

export const DiscountBanner = styled(DiscountBannerComponent)`
  margin-bottom: 24px;

  ${props => props.theme.media.tablet`
    max-width: 960px;
  `}
`

export const Price = styled.div`
  grid-area: price;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.color.onPrimaryDark};
`

export const Spacer = styled.div`
  margin-bottom: 16px;
`

export const HowItWorksButtonStyled = styled(HowItWorksButton)`
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
`

export const TitleStyled = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  text-align: center;
`
export const PriceStyled = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin: 0;
  text-align: center;
`

export const CustomSectionBody = styled.div`
  padding: 16px 24px 24px;
`

export const OutOfStockContent = styled.div`
  margin-top: 16px;
  text-align: center;
`

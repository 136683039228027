export enum FeatureFlagTypes {
  Field = 'field',
  Enum = 'enum',
  CrmSection = 'crmSection',
  Action = 'action',
  Custom = 'custom',
  Button = 'button',
}

export enum Buttons {
  CancelOrder = 'cancelOrder',
}

export enum FeatureFlagModifiers {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Hidden = 'hidden',
  Required = 'required',
  NotRequired = 'notRequired',
  Restricted = 'restricted',
}

export enum FieldNames {
  CheckPayeeName = 'checkPayeeName',
  Partnership = 'partnership',
  TaxID = 'taxId',
  FarmName = 'farmName',
  Gdpr = 'gdpr',
  RetailerRegions = 'retailerRegionsIds',
  DeliveryAddress = 'deliveryAddress',
  Email = 'email',
  Promocode = 'promocode',
  Units = 'units',
  Qty = 'qty',
}

export enum FieldLocation {
  AccountForm = 'accountForm',
  CartList = 'cartList',
}

export type FeatureFlags = {
  [key: string]: boolean
}

export enum Sections {
  OrderDetails = 'ordersDetails',
  Checkout = 'checkout',
  Orders = 'orders',
  ProductList = 'productList',
  Cart = 'cart',
}

export enum Actions {
  Edit = 'edit',
}

export enum CustomFeatureName {
  FilterRetailersByProvince = 'filterRetailersByProvince',
  SkipRetailerSelection = 'skipRetailerSelection',
  FarmerConfirmRequired = 'farmerConfirmRequired',
  AutoConfirmOrderByAgroClub = 'autoConfirmOrderByAgroClub',
  AutoConfirmOrderByProducer = 'autoConfirmOrderByProducer',
  CompanyDescriptionVisible = 'companyDescriptionVisible',
}

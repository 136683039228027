import { AppGlobalState } from 'modules/types'

const getError = (state: AppGlobalState): string => state.auth.error
const pathname = (state: AppGlobalState): string => state.router.location.pathname
const search = (state: AppGlobalState): string => state.router.location.search
const locationState = (state: AppGlobalState) => state.router.location.state as { from: string } | undefined
const profile = (state: AppGlobalState) => state.auth.profile
const profileUpdateProgress = (state: AppGlobalState) => state.auth.profileUpdateProgress
const smsVerificationProgress = (state: AppGlobalState) => state.auth.smsCodeVerificationProgress
const submitCredentialsProgress = (state: AppGlobalState) => state.auth.submitCredentialsProgress
const step = (state: AppGlobalState) => state.auth.step
const suggestedPhone = (state: AppGlobalState) => state.auth.suggestedPhone || state.auth.phone
const email = (state: AppGlobalState) => state.auth.email
const phone = (state: AppGlobalState) => state.auth.phone
const initProgress = (state: AppGlobalState) => state.auth.initProgress
const isAuthenticated = (state: AppGlobalState) => state.auth.isAuthenticated
const isProfileFulfilled = (state: AppGlobalState) => state.auth.isProfileFulfilled
const authProgress = (state: AppGlobalState) => state.auth.authProgress
const uuidRequestProgress = (state: AppGlobalState) => state.auth.uuidRequestProgress
const registerProgress = (state: AppGlobalState) => state.auth.registerProgress
const getAuthError = (state: AppGlobalState) => state.auth.authError
const getTermsAgreed = (state: AppGlobalState) => state.auth.termsAgreed
const getGdprSendProgress = (state: AppGlobalState) => state.auth.gdprSendProgress
const getGdprEraseProgress = (state: AppGlobalState) => state.auth.gdprEraseProgress

const AuthSelectors = {
  getError,
  getAuthError,
  pathname,
  search,
  locationState,
  profile,
  profileUpdateProgress,
  smsVerificationProgress,
  submitCredentialsProgress,
  step,
  suggestedPhone,
  email,
  phone,
  initProgress,
  isAuthenticated,
  isProfileFulfilled,
  authProgress,
  uuidRequestProgress,
  registerProgress,
  getTermsAgreed,
  getGdprSendProgress,
  getGdprEraseProgress,
}

export default AuthSelectors

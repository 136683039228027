import { OptionalNumber, useAction } from '@agro-club/frontend-shared'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useLangPicker from 'hooks/useLangPicker'
import useMatchMedia from 'hooks/useMatchMedia'
import { Card } from 'modules/domain/card/types'
import CartSkuActions from 'modules/domain/cartSku/duck'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { CartSkuItem } from 'modules/domain/cartSku/types'
import { Sku, SkuOption } from 'modules/domain/sku/types'
import { cardToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import { getPrettyPrice } from 'modules/utils/helpers'
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { queries } from 'theme/theme'
import { Currency } from 'types/entities'
import AdderWidgetManager, { AdderValues } from './AdderWidget/AdderWidgetManager'
import { DEFAULT_QTY } from '../constants'
import { CardItemEvents } from '../events'
import styled, { StyledProps } from 'styled-components'
import { SectionBody, SectionContainer } from '@agro-club/frontend-shared'
import BadgeLimited from 'views/components/BadgeLimited/BadgeLimited'
import BadgeNew from 'views/components/BadgeNew/BadgeNew'
import BadgeOutOfStock from 'views/components/BadgeOutOfStock/BadgeOutOfStock'
import { BadgeList } from './BadgeList'
import { QuoteBlock } from './QuoteBlock'
import { VideoBlock } from './VideoBlock'
import { SkuDiscountBar } from '../../../../../components/DiscountWidget/SkuDiscountBar'
import { useParams } from 'react-router-dom'
import { useCardBestOffer } from 'modules/domain/card/hooks'
import { useSkuDiscountInfo, useSkuDiscounts } from 'modules/domain/discountsSku/hooks'
import { Extras } from './Extras'
import { Specs } from './Specs'
import { FooterButton } from './FooterButton'

const InnerContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  ${props => props.theme.media.desktop`
    grid-template-columns: 978px 278px;
    grid-template-rows: max-content auto;
    align-items: start;
    align-content: start;
    grid-auto-flow: row;
  `}

  ${props => props.theme.media.smallDesktop`
    grid-template-columns: auto 278px;
  `}
  ${props => props.theme.media.tablet`
    grid-template-columns: 658px 278px;
    grid-template-rows: max-content;
    align-items: start;
    align-content: start;
    grid-auto-flow: row;
  `}
  ${props => props.theme.media.smallTablet`
    grid-template-columns: auto 278px;
  `}
  ${props => props.theme.media.mobile`
    grid-template-columns: auto;
    padding: 0 16px;
    background-color: ${(props: StyledProps<{}>) => props.theme.color.onPrimaryLight};
  `}
`

const MainSectionWrapper = styled.div`
  grid-row: 1 / span 2;
  ${props => props.theme.media.mobile`
    width: 100%;
  `}
`

const MainSection = styled.div`
  display: grid;
  grid-template-columns: 240px 480px auto;
  grid-template-rows: auto;
  grid-gap: 8px 24px;
  grid-template-areas:
    'photoBox content badges';
  ${props => props.theme.media.tablet`
    grid-template-columns: auto 70px;
    grid-template-rows: auto;
    grid-template-areas:
      'photoBox photoBox'
      'title badges'
      'content content';
  `}
  ${props => props.theme.media.smallTablet`
    width: 100%;
    grid-template-columns: 1fr 70px;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'photoBox photoBox'
      'badges badges'
      'content content';
  `}
  ${props => props.theme.media.mobile`
    width: 100%;
    grid-template-columns: 1fr 70px;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'photoBox photoBox'
      'price price'
      'badges badges'
      'content content';
  `};
`

const Content = styled.div`
  grid-area: content;
`

const MainPhoto = styled.div`
  background-repeat: no-repeat;

  width: 240px;
  height: 240px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  justify-self: center;

  ${props => props.theme.media.tablet`
    width: 100%;
  `}

  ${props => props.theme.media.mobile`
    width: 100%;
  `}

  & > img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    ${props => props.theme.media.tablet`
      width: auto;
      max-height: 240px;
    `}
    ${props => props.theme.media.mobile`
      width: auto;
      max-height: 240px;
    `}
  }
`

const PhotoGallery = styled.div`
  margin: 24px 0px;
  background-repeat: no-repeat;

  width: 240px;
  height: auto;
  flex-wrap: wrap;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-self: center;

  ${props => props.theme.media.tablet`
    width: 100%;
  `}

  ${props => props.theme.media.mobile`
    width: 100%;
  `}

  & > img {
    object-fit: cover;
    max-width: 90px;
    max-height: 90px;

    ${props => props.theme.media.tablet`
      width: auto;
      max-height: 90px;
    `}
    ${props => props.theme.media.mobile`
      width: auto;
      max-height: 90px;
    `}
  }
`

const PhotoGalleryItem = styled.div`
  width: 90px;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-self: center;
  margin: 0 8px 8px 0;

  ${props => props.theme.media.tablet`
    width: 90px;
  `}
  ${props => props.theme.media.mobile`
    width: 90px;
  `}

    & > img {
    object-fit: cover;
    max-width: 90px;
    max-height: 90px;
    border-radius: 5%;
    ${props => props.theme.media.tablet`
      width: 90px;
      max-height: 90px;
    `}
    ${props => props.theme.media.mobile`
      width: 90px;
      max-height: 90px;
    `}
  }

  img:hover {
    border: 2px solid #f7901e;
  }
`

const PhotoBox = styled.div`
  grid-area: photoBox;
  width: 240px;
  height: auto;
  border-radius: 8px;
  display: flex-block;
  flex-direction: row;
  justify-self: center;

  ${props => props.theme.media.tablet`
    width: 100%;
  `}
  ${props => props.theme.media.mobile`
    width: 100%;
  `}
`

const BadgeListContainer = styled.div`
  grid-area: badges;
  overflow: hidden;
  ${props => props.theme.media.desktop`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    align-self: flex-start;
    justify-self: flex-end;
    & > * {
      justify-self: center;
      align-self: center;
    }
  `}
  ${props => props.theme.media.tablet`
    display: flex;
    flex-wrap: wrap;
    overflow: visible;
    align-items: center;
    justify-content: flex-start;
    min-height: 40px;
    & > img {
      margin-bottom: 10px;
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  `}
  ${props => props.theme.media.mobile`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    min-height: 40px;
    & > img {
      margin-bottom: 10px;
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  `}
`

const Title = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin: 0 0 8px;

  grid-area: title;

  ${props => props.theme.media.mobile`
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
  `}

  & > *:first-child {
    margin-right: 18px;
  }
`

const BadgeContainer = styled.div`
  margin-top: 2px;
  margin-left: 5px;
`

const Description = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  ${props => props.theme.media.mobile`
    font-size: 14px;
    line-height: 20px;
  `}
`

const Footer = styled.div`
  margin-top: 24px;
  justify-self: start;

  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  ${props => props.theme.media.mobile`
    justify-self: auto;
    margin-top: 24px;
  `}
`

const LegalText = styled.div`
  margin-top: 24px;
  border-top: 1px solid ${props => props.theme.color.onSurfaceLowEmphasys};
  padding-top: 16px;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

const Price = styled.div`
  grid-area: price;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.color.onPrimaryDark};
`

const Spacer = styled.div`
  margin-bottom: 16px;
`

type CardDetailsProps = {
  card: Card
}

export const CardDetails: React.VFC<CardDetailsProps> = ({ card }) => {
  const { t } = useTranslation(['order'])
  const isDesktop = useMatchMedia(queries.desktop)
  const isTablet = useMatchMedia(queries.tablet)
  const isMobile = useMatchMedia(queries.mobile)
  const { pick } = useLangPicker()
  const { track } = useAnalyticsSSR()
  const [mainPhoto, setMainPhoto] = useState<string | undefined>(undefined)
  const [sku, setSku] = useState<Sku | undefined>(undefined)
  const cartItemBySlug = useSelector(state => CartSkuSelectors.cartEntryByCardSlug(state, card.slug))
  const cartSkuItem = useSelector(state => CartSkuSelectors.cartEntryBySkuId(state, sku?.id || ''))
  const cartItem: CartSkuItem | undefined = cartSkuItem || cartItemBySlug
  const defaultQty = useMemo(
    () =>
      cartItem ? OptionalNumber(cartItem.quantity) : OptionalNumber(sku?.default_qty || sku?.min_qty) || DEFAULT_QTY,
    [cartItem, sku?.default_qty, sku?.min_qty],
  )

  const [adderValues, setAdderValues] = useState<AdderValues>({
    qty: defaultQty,
    packageId: undefined,
    options: [],
  })

  const params = useParams<{ producerSlug: string; productOrCardSlug: string }>()

  const [, discounts] = useSkuDiscounts({
    skuId: sku?.id,
    quantity: String(adderValues.qty),
    sellerSlug: params.producerSlug,
  })

  const { currentDiscountAmountPerBag, nextLvlQuantity } = useSkuDiscountInfo(discounts)

  const [, bestOffer] = useCardBestOffer(params.productOrCardSlug)

  const updateItemAction = useAction(CartSkuActions.itemUpdateRequested)

  const features = (card.features_i18n || []).map(feature => ({ title: feature }))
  const attributes = useMemo(() => {
    return [...(card.attributes || [])].map(attr => ({
      title: attr.title_i18n,
      value: attr.value_i18n,
    }))
  }, [card])

  const handleVideoClick = () => {
    track(CardItemEvents.VideoClick, cardToEventParams(card))
  }

  const handleQtyChange = useCallback(
    (qty: number) => {
      setAdderValues({ ...adderValues, qty })
      if (cartItem && !cartItem.is_fake && cartItem.sku?.id === sku?.id) {
        updateItemAction(cartItem.id, {
          quantity: String(qty),
          seller_id: card.seller_id,
        })
      }
    },
    [adderValues, card.seller_id, cartItem, sku?.id, updateItemAction],
  )

  const handleSkuMatched = useCallback(
    (value?: Sku) => {
      if (value?.sku_id === sku?.sku_id) return

      if (value) {
        setSku(value)
        setAdderValues({
          qty: OptionalNumber(value.default_qty || value.min_qty) || 1,
          packageId: value?.params.package_id,
          options: value?.params.options || [],
        })
      }
    },
    [sku?.sku_id],
  )

  const handlePackageTypeChange = useCallback(
    (packageId: string) => {
      setAdderValues({ ...adderValues, packageId, options: [] })
      if (sku) setSku(undefined)
    },
    [adderValues, sku],
  )

  const handleOptionsChange = useCallback(
    (options: SkuOption[]) => {
      setAdderValues({ ...adderValues, options })
    },
    [adderValues],
  )

  const handleDiscountApply = useCallback(
    (qtyToApply: number) => {
      handleQtyChange(adderValues.qty + qtyToApply / (sku?.params.package.conversion_rate ?? 1))
    },
    [adderValues.qty, handleQtyChange, sku?.params.package.conversion_rate],
  )

  const hasDiscounts = Boolean(discounts && discounts.length)

  const videoUrl = pick(card.video_url_i18n)

  useEffect(() => {
    if (cartItem && cartItem.sku?.id === sku?.id) {
      setAdderValues({
        qty: OptionalNumber(cartItem.quantity),
        packageId: cartItem.sku?.params.package_id,
        options: cartItem.sku?.params.options || [],
      })
      handleSkuMatched(cartItem.sku)
    }
  }, [cartItem, handleSkuMatched, sku?.id])

  useEffect(() => {
    if (cartItem) {
      setAdderValues({
        qty: OptionalNumber(cartItem.quantity),
        packageId: cartItem.sku?.params.package_id,
        options: cartItem.sku?.params.options || [],
      })
      handleSkuMatched(cartItem.sku)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <InnerContainer>
      <MainSectionWrapper>
        <SectionContainer noDivider>
          <SectionBody noGrid>
            <MainSection>
              {card.images[0] ? (
                <>
                  <PhotoBox data-test-id="product-images">
                    <MainPhoto>
                      <img src={mainPhoto ?? card.images[0]} alt={pick(card.title_i18n)} />
                    </MainPhoto>
                    {card.images.length > 1 && (
                      <PhotoGallery>
                        {card.images?.map(image => (
                          <PhotoGalleryItem key={image}>
                            <img src={image} alt={pick(card.title_i18n)} onMouseOver={() => setMainPhoto(image)} />
                          </PhotoGalleryItem>
                        ))}
                      </PhotoGallery>
                    )}
                  </PhotoBox>
                </>
              ) : null}
              <Content>
                <Title data-test-id={'card-title'}>
                  <div>{pick(card.title_i18n)}</div>
                  {card.is_new && (
                    <BadgeContainer>
                      <BadgeNew />
                    </BadgeContainer>
                  )}
                  {card.is_limited && (
                    <BadgeContainer>
                      <BadgeLimited />
                    </BadgeContainer>
                  )}
                  {card.is_out_of_stock && (
                    <BadgeContainer>
                      <BadgeOutOfStock />
                    </BadgeContainer>
                  )}
                </Title>
                {isMobile && sku?.price && !card.wizard_id && (
                  <Price>{getPrettyPrice(sku.price, card.seller?.currency || Currency.CAD, sku.price_type)}</Price>
                )}
                {!isDesktop && (
                  <BadgeListContainer>
                    <BadgeList badges={card?.enrich_badges} />
                  </BadgeListContainer>
                )}
                <Description
                  dangerouslySetInnerHTML={{
                    __html: `<div data-test-id="card-description">${pick(card.description_i18n)}</div>`,
                  }}
                ></Description>
                {isMobile && (
                  <AdderWidgetManager
                    card={card}
                    sku={sku}
                    cartItem={cartItem}
                    values={adderValues}
                    onQtyChange={handleQtyChange}
                    onSkuMatched={handleSkuMatched}
                    onPackageTypeChange={handlePackageTypeChange}
                    onOptionsChange={handleOptionsChange}
                    bestOffer={bestOffer}
                    discounts={discounts}
                    nextLvlQuantity={nextLvlQuantity}
                    currentDiscountAmountPerBag={currentDiscountAmountPerBag}
                  />
                )}
                {!!features.length && <Extras items={features} />}
                {videoUrl && <VideoBlock src={videoUrl} onVideoClick={handleVideoClick} />}
                {card.quote?.text && (
                  <QuoteBlock text={card.quote.text} author={card.quote.author} company={card.quote.company} />
                )}
                {attributes.length > 0 && <Specs items={attributes} />}
                {!!card.files?.length && (
                  <Footer>
                    {card.files.map((file, idx) => (
                      <FooterButton
                        key={idx}
                        icon={'document'}
                        to={pick(file.url_i18n)}
                        target={'_blank'}
                        onClick={() => track(CardItemEvents.ProductInfoFileClick, cardToEventParams(card))}
                      >
                        {pick(file.title_i18n) || t('ProductInfoFileButton')}
                      </FooterButton>
                    ))}
                  </Footer>
                )}
                {pick(card.trademark_language_i18n) ? (
                  <LegalText data-test-id="trademark-text">{pick(card.trademark_language_i18n)}</LegalText>
                ) : null}
              </Content>
              {isDesktop && (
                <BadgeListContainer>
                  <BadgeList badges={card?.enrich_badges} />
                </BadgeListContainer>
              )}
            </MainSection>
          </SectionBody>
        </SectionContainer>
      </MainSectionWrapper>
      <div>
        {(isDesktop || isTablet) && (
          <>
            <AdderWidgetManager
              card={card}
              sku={sku}
              cartItem={cartItem}
              values={adderValues}
              onQtyChange={handleQtyChange}
              onSkuMatched={handleSkuMatched}
              onPackageTypeChange={handlePackageTypeChange}
              onOptionsChange={handleOptionsChange}
              bestOffer={bestOffer}
              discounts={discounts}
              nextLvlQuantity={nextLvlQuantity}
              currentDiscountAmountPerBag={currentDiscountAmountPerBag}
            />
            <Spacer />
            {hasDiscounts && sku && (
              <SkuDiscountBar discounts={discounts} units={sku.product?.units} onApply={handleDiscountApply} />
            )}
          </>
        )}
        {isMobile && hasDiscounts && sku && (
          <SkuDiscountBar discounts={discounts} units={sku.product?.units} onApply={handleDiscountApply} />
        )}
      </div>
    </InnerContainer>
  )
}

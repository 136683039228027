export enum ProfileEvents {
  InfoEditTap = 'info_edit_tap',
  SignoutTap = 'signout_tap',
  InfoEditSaveTap = 'info_edit_save_tap',
  InfoEditCancelTap = 'info_edit_cancel_tap',
  ProfileTap = 'profile_tap',
  DeliveryPointEditTap = 'delivery_point_edit_tap',
  DeliveryPointEditSaveTap = 'delivery_point_edit_save_tap',
  DeliveryPointEditCancelTap = 'delivery_point_edit_cancel_tap',
  OrderDeleteProductTap = 'order_delete_product_tap',
  OrderEditSaveTap = 'order_edit_save_tap',
  OrderEditCancelTap = 'order_edit_cancel_tap',
  OrderEditTap = 'order_edit_tap',
  OrderCancelTap = 'order_cancel_tap',
}

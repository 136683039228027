import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CartSelectors from 'modules/domain/cart/selectors'
import { Snackbar, Progress } from '@agro-club/frontend-shared'
import { useSelector } from 'react-redux'

const UpdateErrorPopup: React.FC = () => {
  const updateProgress = useSelector(CartSelectors.updateProgress)
  const { t } = useTranslation(['common'])
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    if (updateProgress === Progress.ERROR) {
      setVisible(true)
    }
  }, [updateProgress])

  const handleClose = () => {
    setVisible(false)
  }

  return (
    <Snackbar open={isVisible} autoHideDuration={5000} onClose={handleClose} onHide={handleClose}>
      {t('common:errors.unknownError')}
    </Snackbar>
  )
}

export default UpdateErrorPopup

import React from 'react'
import { IconButton as UIIconButton, IconButtonProps } from '@agro-club/frontend-shared'
import Link from '../../components/Link/Link'

type Props = {
  to?: string | { pathname: string; state: { [key: string]: any } }
}

const IconButton: React.FC<Props & IconButtonProps> = ({ to, ...rest }) => {
  if (to) {
    return (
      <Link to={to}>
        <UIIconButton {...rest} button={false} />
      </Link>
    )
  } else {
    return <UIIconButton {...rest} />
  }
}

export default IconButton

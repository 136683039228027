import styled, { StyledProps } from 'styled-components'
import IconButton from 'views/ui/IconButton/IconButton'
import { Button, IconBin, IconQuestion, Input, SectionBody } from '@agro-club/frontend-shared'
import { ReactComponent as FCCIcon } from 'assets/images/fcc-logo.svg'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 262px;
  grid-gap: 40px;
  margin-bottom: 64px;
  color: ${(props: StyledProps<{}>) => props.theme.color.onPrimaryDark};

  ${props => props.theme.media.tablet`
    grid-template-columns: auto;
    grid-template-rows: max-content max-content;
  `}

  ${props => props.theme.media.mobile`
    grid-template-columns: auto;
  `}
`
type ThemedProps = StyledProps<{}>
export const HelpWrapper = styled.div`
  margin-top: 24px;

  ${props => props.theme.media.tablet`
    margin-top: 38px;
    display: flex;
  `}

  ${props => props.theme.media.mobile`
    display: none;
  `}
`
export const HelpTitle = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${(props: ThemedProps) => props.theme.color.onPrimaryDark};
  margin-bottom: 15px;
  align-items: center;

  ${props => props.theme.media.tablet`
    margin-right: 80px;
  `}
`
export const HelpSteps = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props: ThemedProps) => props.theme.color.onPrimaryDark};

  p {
    margin: 8px 0;
  }
`
export const HelpIcon = styled(IconQuestion)`
  margin-right: 10px;
`

export const Container = styled(SectionBody)`
  grid-gap: 16px;

  button {
    justify-self: start;
  }

  ${props => props.theme.media.mobile`
    button {
      justify-self: auto;
    }
  `}
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  border-top: 1px solid ${({ theme }) => theme.color.secondary50};

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  & > :last-child {
    margin-top: 8px;
  }

  ${props => props.theme.media.mobile`
    padding: 0px 16px 24px 16px;
    border-top: none;
  `}
`

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  & > :first-child {
    margin-right: 4px;
  }

  & > :last-child {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: ${({ theme }) => theme.color.onPrimaryDark};
  }
`

export const TrashBinIconStyled = styled(IconBin)`
  fill: ${({ theme }) => theme.color.secondary200};
`

export const IconButtonStyled = styled(IconButton)`
  ${props => props.theme.media.mobile`
    height: 32px;
    width: 109px;
    padding: 6px;
  `}
`

export const OrderButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${props => props.theme.media.mobile`
    flex-direction: column;
    width: 100%;
  `}
`

export const OrderButtonHint = styled.span`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 45%;
  padding-top: 4px;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};

  ${props => props.theme.media.mobile`
    width: 100%;
  `}
`

export const OrderButton = styled(Button)`
  margin-right: 24px;
  ${props => props.theme.media.mobile`
    margin-right: 0;
    width: 100%;
  `};
`

export const Terms = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.secondary200};

  a {
    color: ${props => props.theme.color.primary500};
  }
`

export const SwitchText = styled.span`
  display: flex;
  align-items: flex-start;

  ${props => props.theme.media.mobile`
    flex-direction: column;
  `}
`

export const FCCLogo = styled(FCCIcon)`
  flex-shrink: 0;
  margin-left: 10px;

  ${props => props.theme.media.mobile`
    margin-left: 0;
    margin-top: 10px;
  `}
`

export const TruckText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.onPrimaryDark};
`

export const TruckDescription = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 24px;
  max-width: 500px;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

export const TruckLearnMore = styled.a<{ size?: 'large' | 'small' }>`
  text-decoration-line: underline;
  white-space: nowrap;
  margin: ${props => (props.size === 'small' ? '4px 0 0' : '0 0 0 4px')};
  display: ${props => (props.size === 'small' ? 'block' : 'inline-block')};

  ${props => props.theme.media.mobile`
     margin: 0 0 0 4px;
     display: block;
  `}
`

export const AddCommentButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.primary600};
  display: inline-flex;
  border: 0;
  background: none;
  width: auto;
  transition: opacity 0.2s ease-in;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

export const CommentInput = styled(Input)`
  width: 50%;
`

export const CommentWrapper = styled.div`
  display: flex;
  align-items: center;
`

import { useSelector } from 'react-redux'
import CartSelectors from 'modules/domain/cart/selectors'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { Company } from 'types/entities'

const useFarmerComment = (producer?: Company): string | undefined => {
  const productFarmerComment = useSelector(state => CartSelectors.farmerComment(state, producer?.slug || ''))
  const skuFarmerComment = useSelector(state => CartSkuSelectors.farmerComment(state, producer?.slug || ''))
  return producer?.sku_orders ? skuFarmerComment : productFarmerComment
}

export default useFarmerComment

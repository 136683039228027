import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import styled from 'styled-components'
import { Button, Input, Modal } from '@agro-club/frontend-shared'

const Form = styled.form`
  width: 100%;
`

const Text = styled.p`
  margin: 0 0 16px 0;
  max-height: 600px;
  overflow: auto;
  white-space: pre-wrap;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

const SubmitButton = styled(Button)`
  margin-top: 40px;
  width: 100%;
  min-width: 208px;
`

type FormInputs = {
  email?: string
}

type Props = {
  isOpen: boolean
  onClose: () => void
  onEmailSubmit: (email: string) => void
}

const GdprEmailPopup: React.FC<Props> = ({ isOpen, onClose, onEmailSubmit }) => {
  const { t } = useTranslation(['profile', 'common'])
  const validator = useMemo(() => {
    const required = () => Yup.string().required(t('validation:field_required'))
    return Yup.object({
      email: required().email(t('validation:invalidEmail')),
    })
  }, [t])

  const formik = useFormik<FormInputs>({
    initialValues: {
      email: '',
    },
    validationSchema: validator,
    onSubmit: () => onEmailSubmit(formik.values.email),
    enableReinitialize: true,
    validateOnMount: true,
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    formik.submitForm()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} heading={t('gdpr.EmailPopupTitle')}>
      <Text>{t('gdpr.EmailPopupText')}</Text>
      <Form onSubmit={handleSubmit} noValidate>
        <Input
          {...formik.getFieldProps('email')}
          label={t('common:email')}
          type={'email'}
          invalid={formik.touched.email && !!formik.errors.email}
          errorText={formik.errors.email}
        />
        <SubmitButton type={'submit'} intent={'primary'} filled>
          {t('gdpr.EmailPopupSubmit')}
        </SubmitButton>
      </Form>
    </Modal>
  )
}

export default GdprEmailPopup

import { endpoints } from 'modules/endpoints'
import { ApiService } from 'service/api/interface'
import { ListResponse } from 'types/api'
import { Season, SeasonListRequestFilter, SeasonListRequestSorting } from './types'

export const getCurrentSeason = (apiClient: ApiService) => (
  filter: SeasonListRequestFilter,
  sorting?: SeasonListRequestSorting,
) => {
  return apiClient.get<ListResponse<Season>>(endpoints.season(), {
    ...filter,
    ...sorting,
    page: 1,
    page_size: 1,
  })
}

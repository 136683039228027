import React from 'react'
import styled, { css, StyledProps } from 'styled-components'
import { IconCheck, SectionBody, SectionContainer } from '@agro-club/frontend-shared'

const StepsHeaderContainer = styled.div<StyledProps<{ completed: boolean }>>`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 72px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${props => props.theme.color.secondary200};
  border-bottom: 1px solid ${props => props.theme.color.secondary50};

  &::after {
    position: absolute;
    background-color: ${props => props.theme.color.primary600};
    content: '';
    width: 100%;
    height: 3px;
    display: ${props => (props.completed ? 'block' : 'none')};
    bottom: -1px;
    left: 0;
  }
`

type StepHeaderItemProps = StyledProps<{ current: boolean; completed: boolean; disabled: boolean }>
const stepHeaderCss = (props: StepHeaderItemProps) => {
  let color = props.theme.color.secondary50
  if (props.completed) {
    color = props.theme.color.primary600
  } else if (props.current) {
    color = props.theme.color.onPrimaryDark
  }
  return css`
    color: ${color};
    pointer-events: ${props.disabled ? 'none' : 'default'};
    cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
  `
}

const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  width: 20px;
  ${props => props.theme.media.tablet`
    right: 0;
    margin-left: 5px;
  `}
  ${props => props.theme.media.mobile`
    display: none;
  `}
`

const StepHeaderItemContainer = styled.div<StepHeaderItemProps>`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  white-space: nowrap;
  &:last-child {
    padding-right: 20px;

    ${CheckIconWrapper}{
      right: -5px;
    }
  }
  &::after {
    position: absolute;
    background-color: ${props => props.theme.color.primary600};
    content: '';
    width: 100%;
    height: 3px;
    display: ${props => (props.completed || props.current ? 'block' : 'none')};
    bottom: -1px;
    left: 0;
  }
  ${stepHeaderCss}

  ${props => props.theme.media.tablet`
    padding-left: 20px;
    padding-right: 20px;
    &:last-child {
      padding-right: 40px;

      ${CheckIconWrapper}{
        right: 20px;
      }
    }
  `}

  ${props => props.theme.media.mobile`
    padding-left: 8px;
    padding-right: 8px;
    &:first-child {
      padding-left: 16px;
    }
  `}
`

type StepItemProps = { current: boolean; completed: boolean; title: string; disabled?: boolean; onClick(): void }
const StepHeaderItem: React.FC<StepItemProps> = ({ current, completed, title, disabled = false, onClick }) => {
  return (
    <StepHeaderItemContainer current={current} completed={completed} onClick={onClick} disabled={disabled}>
      {title}
      <CheckIconWrapper>{completed && <IconCheck />}</CheckIconWrapper>
    </StepHeaderItemContainer>
  )
}

export const Step: React.FC<{ title: string; id?: string; disabled?: boolean }> = _props => {
  const props = _props as StepItemProps
  return (
    <StepHeaderItem
      current={props.current}
      completed={props.completed}
      title={props.title}
      onClick={props.onClick}
      disabled={props.disabled}
    />
  )
}

export const Container: React.FC<{
  step: number
  completed: boolean
  completeContent?: React.ReactElement
  onTabClick(step: number): void
}> = ({ step, completed, children, completeContent, onTabClick }) => {
  const nodes = React.Children.toArray(children)
  const current = nodes[step]

  if (!current) {
    console.warn(
      `StepSection: received out-of-bounds step number. Steps total ${nodes.length}, step index passed ${step}`,
    )
  }

  return (
    <SectionContainer noDivider>
      <StepsHeaderContainer completed={completed}>
        {nodes.map((node, idx) => (
          <div data-test-id={`step-tab-${idx}`} key={`step-tab-${idx}`}>
            {// eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            React.cloneElement(node, {
              onClick: () => !completed && onTabClick(idx),
              current: step === idx,
              completed: idx < step || completed,
              key: `step-${idx}`,
            })}
          </div>
        ))}
      </StepsHeaderContainer>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <SectionBody paddedOnMobile id={current?.props?.id} noGrid>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {completed && completeContent ? completeContent : current?.props?.children || null}
      </SectionBody>
    </SectionContainer>
  )
}

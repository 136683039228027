import { all, put, select, take, takeLatest, call } from 'redux-saga/effects'
import CheckoutActions from './duck'
import CheckoutSelectors from './selectors'
import { preOrderCreate, skuOrderCreate } from './managers'
import { DeliveryAddressType } from 'modules/domain/checkout/types'
import CartSelectors from 'modules/domain/cart/selectors'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { CartItem } from 'modules/domain/cart/types'
import AuthSelectors from 'modules/domain/auth/selectors'
import { LOCATION_CHANGE } from 'connected-react-router'
import CartActions from 'modules/domain/cart/duck'
import { OrderDeliveryAddress, OrderDTO, OrderItemDTO, OrderStatus } from 'types/order'
import { apiCall } from 'modules/sagaEffects'
import { pick } from 'ramda'
import { UserProfile } from 'types/entities/userProfile'
import { Promocode } from 'types/entities/promocode'
import { Company, CompanyConfig } from 'types/entities'
import ProducerSelectors from '../producer/selectors'
import { generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'
import { CustomFeatureName, Sections } from 'types/entities/config'
import { checkFeatureFlags } from 'helpers/featureFlags'
import { CartSkuItem } from '../cartSku/types'
import CartSkuActions from '../cartSku/duck'

export const orderCreateSaga = function*({
  payload: producerId,
}: ReturnType<typeof CheckoutActions.orderCreateRequested>) {
  try {
    const profile: UserProfile = yield select(AuthSelectors.profile)
    const producer: Company = yield select(ProducerSelectors.item, producerId)
    const producerConfig: CompanyConfig | undefined = producer.config
    const farmerComment = yield select(CartSelectors.farmerComment, producer.slug)

    const checkCompanyFeatureFlags = config => checkFeatureFlags(config)
    const checkAutoConfirmOrder = checkCompanyFeatureFlags(producer.config?.seller_config?.feature_flags)
    const confirmedByAgroClub = checkAutoConfirmOrder(
      generateCustomFeatureFlag(Sections.Checkout, CustomFeatureName.AutoConfirmOrderByAgroClub),
    )
    const confirmedByProducer = checkAutoConfirmOrder(
      generateCustomFeatureFlag(Sections.Checkout, CustomFeatureName.AutoConfirmOrderByProducer),
    )

    if (!profile || !profile.delivery_address) {
      yield put(CheckoutActions.orderCreateFailed())
      return
    }

    let addrs: OrderDeliveryAddress[] = []
    const addrType: DeliveryAddressType = yield select(CheckoutSelectors.deliveryAddressType)
    const desiredDeliveryDate = yield select(CheckoutSelectors.desiredDeliveryDate)
    switch (addrType) {
      case DeliveryAddressType.MAIN:
        const mainDeliveryAddress = {
          ...profile.delivery_address,
          desired_delivery_date: desiredDeliveryDate,
        }
        addrs.push(mainDeliveryAddress)
        break
      case DeliveryAddressType.ADDITIONAL:
        const additionalDeliveryAddress = {
          ...profile.addresses[0],
          desired_delivery_date: desiredDeliveryDate,
        }
        addrs.push(additionalDeliveryAddress)
        break
      case DeliveryAddressType.SPLIT:
        const splitDeliveryAddress = yield select(CheckoutSelectors.deliveryAddresses)
        const addressWithDeliveryDate: OrderDeliveryAddress[] = splitDeliveryAddress.map(item => {
          return {
            ...item,
            desired_delivery_date: desiredDeliveryDate,
          }
        })
        addrs = addressWithDeliveryDate
        break
      default:
        break
    }

    const distributorId = yield select(CheckoutSelectors.distributorId)
    const cartItems: CartItem[] = yield select(CartSelectors.cartItemsByProducer, producerId)

    let creditOfferAccepted: boolean = yield select(CartSelectors.creditOfferAccepted, producer.slug)
    const foundProductWithFcc = cartItems.some(item => !!item.product.additional_options?.credit_offer_allowed)
    if (!foundProductWithFcc) {
      creditOfferAccepted = false
    }

    const promocodes: Promocode[] = yield select(CartSelectors.promocodes, producer.slug)

    const products: OrderDTO['items'] = cartItems.map(item => {
      const product: OrderItemDTO = {
        quantity: String(item.qty),
        product_id: item.product_id,
        seed_treatment_id: item.seed_treatment_id || null,
      }
      if (item.packaging) {
        product.packaging = item.packaging
      }
      if (item.options?.length) {
        product.options = item.options
      }
      if (item.wizard_comment) {
        product.wizard_comment = item.wizard_comment
      }
      if (item.wizard_data) {
        product.wizard_data = item.wizard_data
      }
      return product
    })

    const orderData: OrderDTO = {
      delivery_addresses: addrs,
      producer_id: producerId,
      seller_id: producerId,
      items: products,
      sku_items: [],
      distributor_id: distributorId || producerConfig?.seller_config?.default_distributor_id,
      status: OrderStatus.New,
      interaction: {
        credit_offer_accepted: creditOfferAccepted,
        confirmed_by_farmer: true,
        confirmed_by_agroclub: confirmedByAgroClub,
        confirmed_by_producer: confirmedByProducer,
      },
      promocodes: promocodes.map(pick(['code', 'comment'])),
      farmer_comment: farmerComment,
    }

    yield call(apiCall, preOrderCreate, orderData)

    yield put(CheckoutActions.orderCreateSucceed())
    yield put(CartActions.clearRequested(producerId))
    yield put(CartActions.cartInfoRemove(producer.slug))
    yield take(LOCATION_CHANGE)
    yield put(CheckoutActions.reset())
    yield put(CartActions.resetPromocode(producer.slug))
  } catch (e) {
    yield put(CheckoutActions.orderCreateFailed())
  }
}

export const orderSkuCreateSaga = function*({
  payload: sellerId,
}: ReturnType<typeof CheckoutActions.orderSkuCreateRequested>) {
  try {
    const profile: UserProfile = yield select(AuthSelectors.profile)
    const seller: Company = yield select(ProducerSelectors.item, sellerId)
    const sellerConfig: CompanyConfig | undefined = seller.config
    const farmerComment = yield select(CartSkuSelectors.farmerComment, seller.slug)

    const checkCompanyFeatureFlags = config => checkFeatureFlags(config)
    const checkAutoConfirmOrder = checkCompanyFeatureFlags(seller.config?.seller_config?.feature_flags)
    const confirmedByAgroClub = checkAutoConfirmOrder(
      generateCustomFeatureFlag(Sections.Checkout, CustomFeatureName.AutoConfirmOrderByAgroClub),
    )
    const confirmedByProducer = checkAutoConfirmOrder(
      generateCustomFeatureFlag(Sections.Checkout, CustomFeatureName.AutoConfirmOrderByProducer),
    )

    if (!profile || !profile.delivery_address) {
      yield put(CheckoutActions.orderCreateFailed())
      return
    }

    let addrs: OrderDeliveryAddress[] = []
    const addrType: DeliveryAddressType = yield select(CheckoutSelectors.deliveryAddressType)
    const desiredDeliveryDate = yield select(CheckoutSelectors.desiredDeliveryDate)
    switch (addrType) {
      case DeliveryAddressType.MAIN:
        const mainDeliveryAddress = {
          ...profile.delivery_address,
          desired_delivery_date: desiredDeliveryDate,
        }
        addrs.push(mainDeliveryAddress)
        break
      case DeliveryAddressType.ADDITIONAL:
        const additionalDeliveryAddress = {
          ...profile.addresses[0],
          desired_delivery_date: desiredDeliveryDate,
        }
        addrs.push(additionalDeliveryAddress)
        break
      case DeliveryAddressType.SPLIT:
        const splitDeliveryAddress = yield select(CheckoutSelectors.deliveryAddresses)
        const addressWithDeliveryDate: OrderDeliveryAddress[] = splitDeliveryAddress.map(item => {
          return {
            ...item,
            desired_delivery_date: desiredDeliveryDate,
          }
        })
        addrs = addressWithDeliveryDate
        break
      default:
        break
    }

    const distributorId = yield select(CheckoutSelectors.distributorId)
    const cartSkuItems: CartSkuItem[] = yield select(CartSkuSelectors.cartItemsBySellerId, sellerId)

    let creditOfferAccepted: boolean = yield select(CartSkuSelectors.creditOfferAccepted, seller.slug)
    const foundItemWithFcc = cartSkuItems.some(item => !!item.card?.additional_options?.credit_offer_allowed)
    if (!foundItemWithFcc) {
      creditOfferAccepted = false
    }

    const promocodes: Promocode[] = yield select(CartSkuSelectors.promocodes, seller.slug)

    const items: OrderItemDTO[] = cartSkuItems.map((item: CartSkuItem) => {
      const skuItem: OrderItemDTO = {
        sku_id: item.sku_id,
        quantity: item.quantity,
        card_id: item.card?.id,
      }
      if (item.wizard_comment) {
        skuItem.wizard_comment = item.wizard_comment
      }
      if (item.wizard_data) {
        skuItem.wizard_data = item.wizard_data
      }
      return skuItem
    })

    const orderData: OrderDTO = {
      delivery_addresses: addrs,
      seller_id: sellerId,
      producer_id: sellerId,
      sku_items: items,
      distributor_id: distributorId || sellerConfig?.seller_config?.default_distributor_id,
      status: OrderStatus.New,
      interaction: {
        credit_offer_accepted: creditOfferAccepted,
        confirmed_by_farmer: true,
        confirmed_by_agroclub: confirmedByAgroClub,
        confirmed_by_producer: confirmedByProducer,
      },
      promocodes: promocodes.map(pick(['code', 'comment'])),
      farmer_comment: farmerComment,
    }

    yield call(apiCall, skuOrderCreate, orderData)

    yield put(CheckoutActions.orderSkuCreateSucceed())
    yield put(CartSkuActions.clearRequested(sellerId))
    yield put(CartSkuActions.cartInfoRemove(seller.slug))
    yield take(LOCATION_CHANGE)
    yield put(CheckoutActions.reset())
    yield put(CartSkuActions.resetPromocode(seller.slug))
  } catch (e) {
    yield put(CheckoutActions.orderSkuCreateFailed())
  }
}

const CheckoutSaga = function*() {
  yield all([
    takeLatest(CheckoutActions.orderCreateRequested.type, orderCreateSaga),
    takeLatest(CheckoutActions.orderSkuCreateRequested.type, orderSkuCreateSaga),
  ])
}

export default CheckoutSaga

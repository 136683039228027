import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { Progress } from '../../types'
import { SignUpState } from 'modules/domain/signup/types'

const initialState: SignUpState = {
  email: '',
  country: 'RU',
  phone: '',
  step: 'form',
  signUpProgress: Progress.IDLE,
}

class SignUpReducer extends ImmerReducer<SignUpState> {
  signUpSubmitted() {}

  signUpSucceeded() {}

  signUpFailed() {}
}

export const SignupActions = createActionCreators(SignUpReducer)
export default SignupActions
export const reducer = createReducerFunction(SignUpReducer, initialState)

const stripTrailingSlash = (url: string) => (url.endsWith('/') ? url.slice(0, -1) : url)
const addTrailingSlash = (url: string) => (url.endsWith('/') ? url : `${url}/`)

const idRx = /{id}/gi
const sellerSlugRx = /{sellerSlug}/gi

const urlMaker = (url: string) => (id?: number | string, sellerSlug?: string) => {
  if (typeof id === 'undefined') {
    return stripTrailingSlash(url)
  } else {
    let replaced = ''

    if (url.match(idRx)) {
      replaced = url.replace(idRx, String(id))
    }
    if (url.match(sellerSlugRx)) {
      replaced = replaced.replace(sellerSlugRx, String(sellerSlug))
    }
    if (replaced) {
      return stripTrailingSlash(replaced)
    }

    return `${addTrailingSlash(url)}${id}`
  }
}
const regex = /\{([^\{\}]+)\}/gim

const urlMaker2 = (url: string) => (obj: any) => {
  return url.replace(regex, (_, key) => obj[key])
}

export const endpoints = {
  requestSmsCode: () => `/auth/login/send_code`,
  emailLogin: () => '/auth/login/email',
  phoneLogin: () => '/auth/login/phone',
  refreshToken: () => '/auth/refresh',
  logout: () => '/auth/logout',
  getUuid: () => '/auth/uuid',
  profile: () => '/users/me',
  users: urlMaker('/users'),
  companies: urlMaker('/companies'),
  cart: urlMaker('/cart'),
  cartSku: urlMaker('/storefront/cart'),
  discount: urlMaker('/cart/discount'),
  discountRules: urlMaker('/discount-rules'),
  productDiscount: urlMaker('/discounts/products'),
  productDiscountRules: urlMaker('/discount-rules/products'),
  incentivePrograms: urlMaker('/incentive-programs'),
  regions: urlMaker('/regions'),
  sku: urlMaker('/sku'),
  orders: urlMaker('/orders'),
  skuOrders: urlMaker('/sku-orders'),
  allOrders: urlMaker('/sku-orders/all_orders'),
  category: urlMaker('/categories'),
  product: urlMaker('/products'),
  card: urlMaker('/storefront/{id}/product-cards'),
  cardById: urlMaker('/storefront/{sellerSlug}/product-cards/{id}'),
  cardBestOffer: urlMaker2('/storefront/{sellerSlug}/product-cards/{cardSlug}/best-offer'),
  producer: urlMaker('/companies'),
  companyCategories: urlMaker('/companies/{id}/categories'),
  storefrontCategories: urlMaker('/storefront/{id}/categories'),
  productFilterAttributes: () => '/products/attributes/filter-items',
  cardFilterAttributes: () => '/storefront/filter-items',
  checkPromocode: urlMaker('/promocode/find'),
  promoInfo: urlMaker('/info/market'),
  documents: urlMaker('/juristic_documents'),
  documentFileUrl: (id: string) => `${endpoints.documents(id)}/get_first_file`,
  documentSignedFileUrl: (id: string) => `${endpoints.documents(id)}/combined_signed_pdf`,
  documentEnvelopeUrl: (id: string) => `${endpoints.documents(id)}/create_envelope`,
  phoneCodes: () => '/phone_codes',
  country: urlMaker('/countries'),
  whiteLabel: urlMaker('/white-label'),
  userGdprRequest: urlMaker('/forms/user-data-request'),
  exclusiveProduct: urlMaker('/products/exclusive'),
  exclusiveCard: urlMaker('/storefront/product-card/exclusive'),
  productWizard: urlMaker('/product-wizards'),
  productOptions: urlMaker('/product-options'),
  relatedProduct: urlMaker('discount-rules/related_products/{id}'),
  companiesWithDelivery: urlMaker('/companies/can-deliver-products/list'),
  packageTypes: urlMaker('/package-type'),
  season: urlMaker('/seasons'),
}

import {
  ButtonFullProps,
  EMPTY_ARRAY,
  NumberInput,
  OptionalNumber,
  ProcoteWizardData,
  ProductWizardType,
  Progress,
  SectionTable,
  SectionTableBody,
  SectionTableBodyCell,
  SectionTableBodyRow,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableHeadRow,
  Skeleton,
  Tooltip,
  useAction,
} from '@agro-club/frontend-shared'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useCountryPath } from 'hooks/useCountryPath'
import useLangPicker from 'hooks/useLangPicker'
import useSkuOptions from 'hooks/useSkuOptions'
import { useCard } from 'modules/domain/card/hooks'
import CartSkuActions from 'modules/domain/cartSku/duck'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { CartSkuItem, PendingCartSkuItem } from 'modules/domain/cartSku/types'
import { useSkuDiscountInfo } from 'modules/domain/discountsSku/hooks'
import { useAllPackageTypesList } from 'modules/domain/packageTypes/hooks'
import { PackageTypes } from 'modules/domain/packageTypes/types'
import { ProductOptions as ProductOptionsType } from 'modules/domain/productOptions/types'
import { Sku } from 'modules/domain/sku/types'
import { producerToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import { getPrettyPrice } from 'modules/utils/helpers'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CellProps, useExpanded, useTable } from 'react-table'
import styled from 'styled-components'
import { Company, Currency } from 'types/entities'
import { CartEvents } from 'views/components/Cart/events'
import * as Styled from 'views/components/CartList/styled'
import { BaseUnits } from 'views/pages/Producer/Card/CardItem/components/BaseUnits'
import { DiscountSuggestion } from 'views/pages/Producer/Product/ProductItem/styled'
import { Routes } from 'views/pages/routes'
import { QtyHintSku } from '../QtyHintSku/QtyHintSku'
import { SkuOptionsContext } from '../SkuOptionsComponent/SkuOptionsComponent'
import SkuOptionsMatcher, { MatcherButton } from '../SkuOptionsMatcher/SkuOptionsMatcher'
import { WizardComment } from '../WizardNote/WizardComment'

const StandardUnitsCell: React.FC<Partial<CellProps<CartSkuItem>> & {
  isMobile?: boolean
  skuItem?: Sku
  qty?: number
}> = ({ row, isMobile = false, skuItem, qty }) => {
  const { t } = useTranslation('standardUnits')
  const sku = row?.original.sku || skuItem
  const quantity = OptionalNumber(row?.original.quantity) || qty || 1

  return isMobile ? (
    <Styled.MobileBlock>
      <Styled.MobileLabel>{t('label')}: </Styled.MobileLabel>
      <Styled.BoldText>
        <BaseUnits qty={quantity} sku={sku} align={'left'} />
      </Styled.BoldText>
    </Styled.MobileBlock>
  ) : (
    <BaseUnits qty={quantity} sku={sku} align={'left'} />
  )
}

const SkuMatcherForProduct: React.FC<{
  sku?: Sku
  cardId: string
  sellerSlug: string
  productOptions: ProductOptionsType[]
  packageTypes: PackageTypes[]
  onSkuMatched: (sku: Sku) => void
  onPackageTypeChange?: (packageType?: string) => void
  saveProgress?: Progress
  isAddedFromCrm?: boolean
}> = ({
  sku,
  cardId,
  sellerSlug,
  saveProgress,
  productOptions,
  packageTypes,
  isAddedFromCrm,
  onSkuMatched,
  onPackageTypeChange,
}) => {
  const [progress, fullCard] = useCard(sellerSlug, cardId)
  const errorDetails = useSelector(CartSkuSelectors.updateErrorDetails)
  const [cachedMatchedSku, setCachedMatchedSku] = useState<Sku | undefined>(sku)
  const { t } = useTranslation('order')

  return (
    <>
      <SkuOptionsMatcher
        sku_package_id={sku?.params.package_id}
        sku_options={sku?.params.options}
        availableSku={fullCard?.sku_resp ?? EMPTY_ARRAY}
        productOptions={productOptions}
        packageTypes={packageTypes}
        progress={progress}
        context={SkuOptionsContext.Cart}
        onSkuMatched={setCachedMatchedSku}
        onPackageTypeChange={onPackageTypeChange}
        error={errorDetails}
        isAddedFromCrm={isAddedFromCrm}
        buttons={
          <MatcherButton
            variant="text"
            intent="primary"
            disabled={!cachedMatchedSku || cachedMatchedSku.id === sku?.id}
            onClick={() => {
              if (cachedMatchedSku && cachedMatchedSku.id !== sku?.id) {
                onSkuMatched(cachedMatchedSku)
              }
            }}
            progress={saveProgress}
            data-test-id="apply-sku"
          >
            {t('saveOptions')}
          </MatcherButton>
        }
      />
    </>
  )
}

const ProductCell: React.FC<CellProps<CartSkuItem> & {
  showImages?: boolean
  editable?: boolean
  productOptions: ProductOptionsType[]
  packageTypes: PackageTypes[]
  producerSlug: string
  onPackageTypeChange?: (packageType?: string) => void
}> = ({ row, showImages = true, packageTypes, productOptions, producerSlug, onPackageTypeChange }) => {
  const generateCountryPath = useCountryPath()
  const { pick } = useLangPicker()
  const { t } = useTranslation('cart')
  const { card, sku, wizard_comment, wizard_data, default_packaging, images, title, added_from_crm } = {
    ...row.original,
  }
  const isDetailsAllowed = card?.category?.slug !== 'duck-foot'
  const url = isDetailsAllowed
    ? generateCountryPath(Routes.ProductOrCardItem, {
        producerSlug,
        categorySlug: card?.category?.slug,
        subCategorySlug: card?.subcategory?.slug,
        productOrCardSlug: card?.slug,
      })
    : '#'

  const editMode = !!row.isExpanded
  const options = useSkuOptions(sku?.params.options || [], productOptions)
  const isClickable = isDetailsAllowed && !added_from_crm

  const updateCartItem = useAction(CartSkuActions.itemUpdateRequested)
  const updateProgress = useSelector(CartSkuSelectors.updateProgress)

  useEffect(() => {
    updateProgress === Progress.SUCCESS && row.toggleRowExpanded(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProgress])

  const cellContent = (
    <>
      {showImages && images ? <Styled.Image url={images[0]} /> : null}
      <Styled.ProductCellText>
        <Styled.BoldText>{pick(title)}</Styled.BoldText>
        {wizard_comment && !wizard_data && (
          <>
            <Styled.PackSize>{pick(default_packaging)}</Styled.PackSize>
            <WizardComment note={wizard_comment} />
          </>
        )}
        {wizard_data?.type === ProductWizardType.Procote && <ProcoteWizardData data={wizard_data} />}
      </Styled.ProductCellText>
    </>
  )

  return (
    <Styled.ProductCellWrapper>
      {isClickable ? (
        <Styled.ClickableProductCell $clickable={isClickable} to={url}>
          {cellContent}
        </Styled.ClickableProductCell>
      ) : (
        <Styled.ProductCell>{cellContent}</Styled.ProductCell>
      )}
      {editMode && card?.id ? (
        <SkuMatcherForProduct
          sku={sku}
          cardId={card.id}
          sellerSlug={producerSlug}
          productOptions={productOptions}
          packageTypes={packageTypes}
          saveProgress={updateProgress}
          onSkuMatched={sku => {
            updateCartItem(row.original.id, { sku_id: sku.id, sku })
          }}
          onPackageTypeChange={onPackageTypeChange}
          isAddedFromCrm={added_from_crm}
        />
      ) : (
        <div>
          {sku && (
            <Styled.ProductOption key={sku.params.package_title}>
              {t('list.packageType')}: {sku.params.package_title}
            </Styled.ProductOption>
          )}
          {options.map(o => (
            <Styled.ProductOption key={o.id}>
              {o.type}: {o.title} {!!o.params?.percentage ? `(${o.params?.percentage}%)` : null}
            </Styled.ProductOption>
          ))}
        </div>
      )}
      {!sku && <Styled.ErrorText data-test-id="no-options-selected">{t('cart:noOptionsSelected')}</Styled.ErrorText>}
      {card?.docusign_document_id && sku?.product?.legal_notes ? (
        <Tooltip
          visibleOnHover={true}
          tooltipContent={
            <Styled.ProductCellLegalWarningTooltipContent>
              {sku.product?.legal_notes.description}
            </Styled.ProductCellLegalWarningTooltipContent>
          }
          temporary={true}
        >
          <Styled.ProductCellLegalWarning data-test-id={'legal-hint'}>
            <Styled.IconWarningRed />
            {sku.product?.legal_notes.text}
          </Styled.ProductCellLegalWarning>
        </Tooltip>
      ) : null}
    </Styled.ProductCellWrapper>
  )
}

const DiscountCell: React.FC<CellProps<CartSkuItem> & { seller: Company }> = ({ value, row, seller }) => {
  return (
    <div data-test-id={`discount-cell`} style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
      {!!parseFloat(value) && getPrettyPrice(value, seller.currency, row.original.sku?.price_type)}
    </div>
  )
}

const CostCell: React.FC<CellProps<CartSkuItem> & { seller: Company }> = ({ value, row, seller }) => {
  const formatPrice = useCallback((price: number) => getPrettyPrice(price, seller.currency), [seller.currency])
  const { card, sku, quantity } = { ...row.original }
  const qty = OptionalNumber(quantity)
  const price = parseFloat(String(sku?.price) ?? '0')
  const cost = value ?? price * qty

  return (
    <Styled.CostWrapper>
      <Styled.CostTotal>{formatPrice(cost)}</Styled.CostTotal>
      {card?.category?.slug !== 'duck-foot' && <Styled.CostPrice>{formatPrice(price)}</Styled.CostPrice>}
    </Styled.CostWrapper>
  )
}

const RemoveBtn: React.FC<{
  id: string
  skuId?: string
  seller?: Company
  onClick?: (id: string) => void
  'data-test-id'?: string
}> = ({ id, onClick, seller, skuId, 'data-test-id': testId }) => {
  const { track } = useAnalyticsSSR()
  const handleClick = () => {
    onClick?.(id)
    track(CartEvents.UpdateCart, { product: skuId, type: 'delete', ...producerToEventParams(seller) })
  }
  return <Styled.Remove onClick={handleClick} data-test-id={testId} />
}

const EditBtn: React.FC<ButtonFullProps> = props => {
  return (
    <Styled.ActionIconWrapper variant="no-style" {...props}>
      <Styled.IconPencilStyled />
    </Styled.ActionIconWrapper>
  )
}

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ActionsCell: React.FC<CellProps<CartSkuItem> & {
  editable?: boolean
  onRemove: (id: string) => void
}> = ({ row, editable, onRemove }) => {
  const { id, sku, sku_id, seller, added_from_crm } = { ...row.original }

  return editable ? (
    <ActionsWrapper>
      <RemoveBtn id={id} onClick={onRemove} seller={seller} skuId={sku_id} data-test-id={`remove-sku-${sku?.id}`} />
      {(!!sku || added_from_crm) && (
        <EditBtn
          data-test-id={`edit-row-${id}`}
          onClick={() => {
            row.toggleRowExpanded()
          }}
        />
      )}
    </ActionsWrapper>
  ) : null
}

const Qty: React.FC<{
  value: number
  max?: number
  min?: number
  seller?: Company
  id: string
  onChange?: (id: string, qty: number) => void
  disabled?: boolean
}> = React.memo(({ value, onChange, id, disabled, max, min, seller }) => {
  const [qty, setQty] = useState(value)
  const { track } = useAnalyticsSSR()
  const handleChange = useCallback(
    value => {
      setQty(value)
      onChange?.(id, value)
    },
    [id, onChange],
  )

  useEffect(() => {
    setQty(value)
  }, [value])

  return (
    <NumberInput
      min={min}
      max={max}
      selectTextOnFocus={true}
      key={id}
      value={qty}
      onDecrease={val =>
        track(CartEvents.UpdateCart, {
          action: 'minus',
          qty: val,
          ...producerToEventParams(seller),
        })
      }
      onIncrease={val =>
        track(CartEvents.UpdateCart, {
          action: 'plus',
          qty: val,
          ...producerToEventParams(seller),
        })
      }
      onChange={handleChange}
      size={'medium'}
      disabled={disabled}
      testId={'item-count'}
      inputStep={0.1}
    />
  )
})

const QtyCell: React.FC<CellProps<CartSkuItem> & {
  editable?: boolean
  seller: Company
  onQtyChange: (id: string, qty: number) => void
  disabled: boolean
}> = ({ seller, row, editable, onQtyChange, disabled }) => {
  const { t } = useTranslation('cart')
  const { id, sku, card, quantity, added_from_crm, added_from_crm_options } = { ...row.original }
  const qty = OptionalNumber(quantity) || 1
  const maxQty = added_from_crm_options?.max_qty
  const max = added_from_crm ? OptionalNumber(maxQty) : OptionalNumber(sku?.max_qty)
  const min = OptionalNumber(sku?.min_qty)
  const cartInfo = useSelector(state => CartSkuSelectors.sellerCartInfo(state, seller.slug))
  const { nextLvlQuantity, currentDiscountAmountPerBag, hasDiscount } = useSkuDiscountInfo(cartInfo?.discounts, sku)

  if (card?.category?.slug === 'duck-foot') return null

  const isMaxQtyReached = max && qty && qty >= max
  const conversionRate = sku?.params?.package?.conversion_rate || 1

  return (
    <Styled.QtyContainer>
      <Styled.QtyContainerInner>
        <Qty
          id={id}
          key={id}
          max={max}
          min={min}
          value={qty}
          seller={seller}
          onChange={onQtyChange}
          disabled={!editable || disabled}
        />
        <Styled.Packaging>{sku?.params.package_title}</Styled.Packaging>
        {isMaxQtyReached ? (
          <Styled.MaxQtyHint dangerouslySetInnerHTML={{ __html: t('reachedMaxQty') }} data-test-id={'max-qty-hint'} />
        ) : null}
        {hasDiscount && !added_from_crm && (
          <DiscountSuggestion
            currency={seller.currency || Currency.CAD}
            nextLvlQty={nextLvlQuantity}
            currentDiscountAmount={currentDiscountAmountPerBag}
            units={sku?.product?.units}
            isCartEmpty={false}
            onQtyAdd={add => onQtyChange?.(id, qty + add / conversionRate)}
          />
        )}
      </Styled.QtyContainerInner>
    </Styled.QtyContainer>
  )
}

type Props = {
  data: CartSkuItem[]
  seller: Company
  onRemove: (id: string) => void
  onQtyChange: (id: string, value?: number) => void
  showImages?: boolean
  editable?: boolean
  emptyText?: string
  removeDisabled?: boolean
  productOptions: ProductOptionsType[]
  pendingData?: PendingCartSkuItem[]
  isQtyDisabled: boolean
}

export const CartSkuList: React.FC<Props> = ({
  showImages = true,
  editable = true,
  data,
  seller,
  pendingData,
  onRemove,
  onQtyChange,
  emptyText,
  removeDisabled,
  productOptions,
  isQtyDisabled,
}) => {
  const { t } = useTranslation('cart')
  const tableData = useMemo(() => data || ([] as const), [data])
  const [, packageTypes = []] = useAllPackageTypesList()
  // This is to prevent unmount of input
  const QtyCellWithSeller = useCallback(props => <QtyCell seller={seller} {...props} />, [seller])

  const allColumns = useMemo(
    () => [
      {
        Header: t('list.product'),
        accessor: 'title' as const,
        Cell: props => (
          <ProductCell
            packageTypes={packageTypes}
            productOptions={productOptions}
            {...props}
            producerSlug={seller.slug}
          />
        ),
      },
      {
        Header: t('list.cost'),
        accessor: 'cost' as const,
        Cell: props => <CostCell seller={seller} {...props} />,
      },
      {
        Header: t('list.discount'),
        accessor: 'discount_amount' as const,
        Cell: props => <DiscountCell seller={seller} {...props} />,
      },
      {
        Header: t('list.qty'),
        accessor: 'quantity' as const,
        Cell: QtyCellWithSeller,
      },
      {
        Header: t('list.standardUnits'),
        Cell: StandardUnitsCell,
      },
      {
        Header: '',
        accessor: 'remove' as const,
        Cell: ActionsCell,
      },
    ],
    [t, QtyCellWithSeller, packageTypes, productOptions, seller],
  )

  const isSomeSkuHasPrice = useMemo(
    () => tableData.some(item => item.sku?.price && parseFloat(item.sku.price as string) > 0),
    [tableData],
  )

  const isOnlyDuckFoot = useMemo(() => tableData.every(item => item.card?.category?.slug === 'duck-foot'), [tableData])

  const hiddenColumns = useMemo(
    () =>
      [isOnlyDuckFoot && 'quantity', !isSomeSkuHasPrice && 'cost', removeDisabled && 'remove'].filter(
        Boolean,
      ) as string[],
    [isOnlyDuckFoot, isSomeSkuHasPrice, removeDisabled],
  )

  const expanded = useMemo(() => {
    return tableData.reduce((obj, row, idx) => ({ ...obj, [idx]: !row.sku }), {})
  }, [tableData])

  const { columns, rows, prepareRow, setHiddenColumns } = useTable<any>(
    {
      data: tableData,
      columns: allColumns,
      autoResetExpanded: false,
      initialState: {
        hiddenColumns,
        expanded,
      },
    },
    useExpanded,
  )

  useEffect(() => {
    setHiddenColumns(hiddenColumns)
  }, [hiddenColumns, setHiddenColumns])

  return data?.length || pendingData?.length ? (
    <>
      <SectionTable>
        <SectionTableHead>
          <SectionTableHeadRow>
            {columns.map(column => {
              if (hiddenColumns.includes(column.id)) {
                return null
              }

              if (
                column.id === 'discount_amount' &&
                !rows.some(row => row.original.discount_amount && parseFloat(row.original.discount_amount || '0') !== 0)
              ) {
                return <SectionTableHeadCell key={column.getHeaderProps().key} />
              }

              let align: 'left' | 'center' | 'right' = 'left' as const

              if (['quantity'].includes(column.id)) align = 'center'
              if (['cost'].includes(column.id)) align = 'right'

              return (
                <SectionTableHeadCell key={column.getHeaderProps().key} textTransform={'uppercase'} textAlign={align}>
                  {column.render('Header')}
                </SectionTableHeadCell>
              )
            })}
          </SectionTableHeadRow>
        </SectionTableHead>
        <SectionTableBody>
          {rows.map(row => {
            prepareRow(row)
            const isFake = Boolean(row.original.is_fake)

            return (
              <React.Fragment key={row.original.id || row.original.sku_id}>
                <SectionTableBodyRow data-test-id={`cart-entry-${row.original.id}`}>
                  {row.cells.map(cell => {
                    if (hiddenColumns.includes(cell.column.id)) {
                      return null
                    }

                    const isEditable = editable && !row.original.sku?.is_out_of_stock && !isFake

                    return (
                      <SectionTableBodyCell verticalAlign={'top'} key={cell.getCellProps().key}>
                        <Styled.CellWrapper isFake={isFake}>
                          {cell.render('Cell', {
                            showImages,
                            editable: isEditable,
                            onRemove,
                            onQtyChange,
                            disabled: isQtyDisabled,
                          })}
                        </Styled.CellWrapper>
                      </SectionTableBodyCell>
                    )
                  })}
                </SectionTableBodyRow>
                <Styled.BodyRowSpacer />
              </React.Fragment>
            )
          })}
          {pendingData?.map(item => (
            <Styled.TableItemSkeletonWrapper key={item.id}>
              <Skeleton width="60px" height="60px" />
              <Styled.TableItemSkeletonTitleWrapper>
                <Skeleton width="130px" />
                <Skeleton width="100px" />
              </Styled.TableItemSkeletonTitleWrapper>
            </Styled.TableItemSkeletonWrapper>
          ))}
        </SectionTableBody>
      </SectionTable>
    </>
  ) : (
    <Styled.EmptyText>{emptyText}</Styled.EmptyText>
  )
}

const MobileActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`

export type MobileCartItemProps = Omit<Props, 'data'> & {
  item: CartSkuItem
  isOnlyDuckFoot: boolean
}

const MobileCartItemRow: React.FC<MobileCartItemProps> = ({
  showImages = true,
  editable = true,
  seller,
  onRemove,
  onQtyChange,
  removeDisabled,
  productOptions,
  isQtyDisabled,
  item,
  isOnlyDuckFoot,
}) => {
  const [editMode, setEditMode] = useState(false)
  const generateCountryPath = useCountryPath()
  const { t } = useTranslation(['cart', 'productOptions'])
  const { pick } = useLangPicker()
  const { track } = useAnalyticsSSR()
  const formatPrice = useCallback((price: string) => getPrettyPrice(price, seller?.currency || Currency.CAD), [seller])
  const cartInfo = useSelector(state => CartSkuSelectors.sellerCartInfo(state, seller?.slug))
  const [, packageTypes = []] = useAllPackageTypesList()

  const updateCartItem = useAction(CartSkuActions.itemUpdateRequested)

  const url = generateCountryPath(Routes.ProductOrCardItem, {
    producerSlug: seller.slug,
    categorySlug: item?.card?.category?.slug,
    subCategorySlug: item?.card?.subcategory?.slug,
    productOrCardSlug: item.card?.slug,
  })
  const qty = OptionalNumber(item.quantity)
  const max = item.added_from_crm_options?.max_qty
  const maxQty = item.added_from_crm ? OptionalNumber(max) : OptionalNumber(item.sku?.max_qty)
  const minQty = OptionalNumber(item.sku?.min_qty)

  const isMaxQtyReached = maxQty && qty && qty >= maxQty
  const isFake = Boolean(item.is_fake)
  const isEditable = editable && !item.sku?.is_out_of_stock && !isFake

  const options = item.sku?.params.options?.map(({ option_id, params }) => {
    const option = productOptions.find(opt => opt.id === option_id)
    return {
      id: option?.id,
      type: t(`productOptions:types.${option?.type}`),
      title: pick(option?.title_i18n),
      params,
    }
  })

  const cellContent = (
    <>
      {showImages && item.images ? <Styled.Image url={item.images[0]} /> : null}
      <Styled.ProductCellText>
        <Styled.BoldText>{pick(item.title)}</Styled.BoldText>
      </Styled.ProductCellText>
    </>
  )

  return (
    <Styled.MobileCartItem key={item.id || item.sku_id} isFake={isFake}>
      <Styled.MobileBlock>
        {item.added_from_crm ? (
          <Styled.ProductCell>{cellContent}</Styled.ProductCell>
        ) : (
          <Styled.ClickableProductCell to={url}>{cellContent}</Styled.ClickableProductCell>
        )}
        <MobileActionsWrapper>
          {isEditable && !removeDisabled && <RemoveBtn id={item.id} onClick={onRemove} />}
          {isEditable && !!item.sku && !!item.card && (
            <EditBtn
              onClick={() => {
                setEditMode(prev => !prev)
              }}
            />
          )}
        </MobileActionsWrapper>
      </Styled.MobileBlock>
      {item.cost && parseFloat(item.cost) > 0 && (
        <Styled.MobileBlock>
          <Styled.MobileLabel>{t('list.costFull')}</Styled.MobileLabel>
          <Styled.BoldText>{formatPrice(item.cost)}</Styled.BoldText>
        </Styled.MobileBlock>
      )}
      {item.sku?.price && parseFloat(item.sku?.price as string) > 0 && item.card?.category?.slug !== 'duck-foot' && (
        <Styled.MobileBlock>
          <Styled.MobileLabel>{t('list.price')}</Styled.MobileLabel>
          <Styled.BoldText>
            {getPrettyPrice(item.sku.price, seller?.currency || Currency.CAD, item.sku.price_type)}
          </Styled.BoldText>
        </Styled.MobileBlock>
      )}
      {item.discount_amount && parseFloat(item.discount_amount) > 0 && (
        <Styled.MobileBlock>
          <Styled.MobileLabel>{t('list.discountFull')}</Styled.MobileLabel>
          <Styled.BoldText>{formatPrice(item.discount_amount)}</Styled.BoldText>
        </Styled.MobileBlock>
      )}
      {editMode && !!item.sku && !!item.card ? (
        <SkuMatcherForProduct
          sku={item.sku}
          cardId={item.card.id}
          sellerSlug={seller.slug}
          productOptions={productOptions}
          packageTypes={packageTypes}
          isAddedFromCrm={item.added_from_crm}
          onSkuMatched={sku => {
            if (sku?.id !== item.sku_id || !item.sku) {
              updateCartItem(item.id, { sku_id: sku?.id, sku })
            }
            setEditMode(false)
          }}
        />
      ) : (
        <>
          <Styled.MobileBlock key={item.sku?.params.package_title}>
            <Styled.MobileLabel>{t('list.packageType')}:</Styled.MobileLabel>
            <Styled.BoldText>{item.sku?.params.package_title}</Styled.BoldText>
          </Styled.MobileBlock>
          {options
            ?.filter(o => !!o.title)
            .map(o => (
              <Styled.MobileBlock key={o.id}>
                <Styled.MobileLabel>{o.type}: </Styled.MobileLabel>{' '}
                <Styled.BoldText>
                  {o.title} {!!o.params?.percentage ? `(${o.params?.percentage}%)` : null}
                </Styled.BoldText>
              </Styled.MobileBlock>
            ))}
        </>
      )}
      {!isOnlyDuckFoot && (
        <Styled.MobileBlock>
          <Styled.MobileLabel>{t('list.quantity')}</Styled.MobileLabel>
          <Styled.QtyContainer>
            <Qty
              max={maxQty}
              min={minQty}
              value={qty}
              seller={seller}
              id={item.id}
              onChange={onQtyChange}
              disabled={!isEditable || isQtyDisabled}
            />
          </Styled.QtyContainer>
          <Styled.BoldText>{item.sku?.params.package_title}</Styled.BoldText>
        </Styled.MobileBlock>
      )}
      {isMaxQtyReached ? (
        <Styled.MobileBlock>
          <Styled.MaxQtyHint dangerouslySetInnerHTML={{ __html: t('reachedMaxQty') }} data-test-id={'max-qty-hint'} />
        </Styled.MobileBlock>
      ) : null}
      <Styled.MobileBlock>
        {isEditable && (
          <QtyHintSku
            item={item}
            onChange={(id, val) => {
              onQtyChange(id, val)
              track(CartEvents.UpdateCart, {
                action: 'add_more',
                product: item.sku_id,
                ...producerToEventParams(seller),
              })
            }}
            discounts={cartInfo?.discounts}
            active={isEditable}
          />
        )}
      </Styled.MobileBlock>
      <StandardUnitsCell skuItem={item.sku} qty={qty} isMobile />
      {item.card?.docusign_document_id && item.sku?.product?.legal_notes ? (
        <Styled.MobileBlock>
          <Styled.LegalHintContainer>
            <Tooltip
              visibleOnHover={true}
              position={'top'}
              tooltipContent={
                <Styled.LegalHintTooltipContent>
                  {item.sku.product?.legal_notes.description}
                </Styled.LegalHintTooltipContent>
              }
              temporary={true}
            >
              <Styled.LegalHint data-test-id={'legal-hint'}>
                <Styled.IconWarningRed />
                {item.sku.product?.legal_notes.text}
              </Styled.LegalHint>
            </Tooltip>
          </Styled.LegalHintContainer>
        </Styled.MobileBlock>
      ) : null}
      {item.wizard_comment && !item.wizard_data && (
        <>
          <Styled.PackSize>{pick(item.default_packaging)}</Styled.PackSize>
          <WizardComment note={item.wizard_comment} />
        </>
      )}
      {item.wizard_data?.type === ProductWizardType.Procote && <ProcoteWizardData data={item.wizard_data} />}
    </Styled.MobileCartItem>
  )
}

export const MobileCartSkuList: React.FC<Props> = ({ data, emptyText, pendingData, ...props }) => {
  const tableData = useMemo(() => data || ([] as const), [data])
  const isOnlyDuckFoot = useMemo(() => tableData.every(item => item.card?.category?.slug === 'duck-foot'), [tableData])

  return tableData.length || pendingData?.length ? (
    <Styled.MobileCartList>
      {tableData.map(item => (
        <MobileCartItemRow key={item.id} item={item} {...props} isOnlyDuckFoot={isOnlyDuckFoot} />
      ))}
      {pendingData?.map(item => (
        <Styled.MobileCartItem isFake={false} key={item.id}>
          <Styled.MobileBlock>
            <Styled.PendingProductCell>
              <Styled.FakeImageWrapper>
                <Skeleton width="60px" height="60px" />
              </Styled.FakeImageWrapper>
              <Styled.ProductCellText>
                <Styled.BoldText>
                  <Skeleton width="120px" />
                </Styled.BoldText>
                <Styled.PackSize>
                  <Skeleton width="90px" />
                </Styled.PackSize>
              </Styled.ProductCellText>
            </Styled.PendingProductCell>
          </Styled.MobileBlock>
        </Styled.MobileCartItem>
      ))}
    </Styled.MobileCartList>
  ) : (
    <Styled.EmptyText>{emptyText}</Styled.EmptyText>
  )
}

import { Button, Currency, NumberInput, OptionalNumber, Progress, useAction } from '@agro-club/frontend-shared'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useCountryPath } from 'hooks/useCountryPath'
import CartSkuActions from 'modules/domain/cartSku/duck'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { PackageTypes } from 'modules/domain/packageTypes/types'
import { useProducer } from 'modules/domain/producer/hooks'
import { ProductOptions as ProductOptionsType } from 'modules/domain/productOptions/types'
import { cardToEventParams } from 'modules/utils/analytics-utils/eventParametersMappers'
import { getPrettyPrice } from 'modules/utils/helpers'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import styled from 'styled-components'
import Link from 'views/components/Link/Link'
import SkuOptionsMatcher from 'views/components/SkuOptionsMatcher/SkuOptionsMatcher'
import { Routes } from 'views/pages/routes'
import { HowItWorksButton } from 'views/ui/HowItWorks/HowItWorks'
import { ChangeQtyType } from '../../constants'
import { AdderWidgetEvents } from '../../events'
import OutOfStockWidget from '../OutOfStockWidget'
import { BestOfferSuggestion } from '../suggestions/BestOfferSuggestion'
import { DiscountSuggestion } from '../suggestions/DiscountSuggestion'
import { AdderWidgetProps } from './AdderWidgetManager'
import { BaseUnits } from '../BaseUnits'
import { useCurrentSeasonOnce } from 'modules/domain/season/hooks'

const Container = styled.div`
  margin-top: 16px;
`

const ContentGroup = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
  margin: 12px 0;
`

const DiscountsWrapper = styled.div`
  margin-top: 24px;
`

const GroupTitle = styled.div`
  margin-right: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

const GroupValue = styled.div`
  margin-right: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
`

const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  & > a {
    display: flex;
    flex-direction: column;
  }
  & > * {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
`

const MaxQtyHint = styled.div`
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: ${props => props.theme.color.backgroundBase};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 8px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`

const NumberInputContainer = styled.div`
  position: relative;
`

const MinMaxContainer = styled.p`
  top: calc(100% + 4px);
  width: 100%;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
`

const MinMax: React.FC<{ min?: number; max?: number }> = ({ min, max }) => {
  const { t } = useTranslation('order')
  const content = useMemo(
    () => [min && t('minPcs', { count: min }), max && t('maxPcs', { count: max })].filter(Boolean).join('; '),
    [min, max, t],
  )
  if (!min && !max) {
    return null
  }

  return <MinMaxContainer>{content}</MinMaxContainer>
}

export type AdderWidgetMobileProps = Omit<AdderWidgetProps, 'values'> & {
  productOptions: ProductOptionsType[]
  packageTypes: PackageTypes[]
  progress: Progress
  isQtyDisabled: boolean
} & AdderWidgetProps['values']

const StyledHint = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
`

type AdderMobileDetailsProps = AdderWidgetMobileProps

const AdderMobileDetails: React.VFC<AdderMobileDetailsProps> = ({
  card,
  sku,
  qty,
  cartItem,
  nextLvlQuantity,
  discounts,
  currentDiscountAmountPerBag,
  bestOffer,
  packageId,
  options,
  productOptions,
  packageTypes,
  progress,
  onQtyChange,
  onSkuMatched,
  onPackageTypeChange,
  onOptionsChange,
  isQtyDisabled,
}) => {
  const params = useParams<{ producerSlug: string; productOrCardSlug: string }>()
  const { track } = useAnalyticsSSR()
  const [, seller] = useProducer(params.producerSlug)
  const hasSimilarProductInCart = useSelector(state => CartSkuSelectors.hasCategory(state, card.subcategory_id || ''))

  const hasDiscounts = Boolean(discounts && discounts.length)
  const { t } = useTranslation('order')
  const maxQty = OptionalNumber(sku?.max_qty)
  const minQty = OptionalNumber(sku?.min_qty)
  const isMaxQtyReached = !!(maxQty && qty && qty >= maxQty)

  const formatPrice = useCallback(
    (price: number | string) => getPrettyPrice(price, seller?.currency || Currency.CAD, sku?.price_type),
    [seller?.currency, sku?.price_type],
  )

  const handleAddDiscountSuggestionQty = (addQty: number) => {
    const newQty = (qty || 0) + addQty / (sku?.params.package.conversion_rate ?? 1)
    track(AdderWidgetEvents.ChangeProductQty, {
      ...cardToEventParams(card),
      type: ChangeQtyType.AddMore,
      quantity: newQty,
    })
    onQtyChange(newQty)
  }

  const isFake = Boolean(cartItem?.is_fake)
  const inTruck = cartItem && !cartItem?.is_fake

  const [seasonProgress, season] = useCurrentSeasonOnce({ companies_id: seller?.id })

  return (
    <>
      {sku?.is_out_of_stock && <OutOfStockWidget card={card} />}
      <StyledHint>
        {t('common:season')}: {seasonProgress === Progress.SUCCESS && season?.title}
      </StyledHint>
      <SkuOptionsMatcher
        sku_package_id={packageId}
        sku_options={options}
        availableSku={card.sku_resp}
        productOptions={productOptions}
        packageTypes={packageTypes}
        progress={progress}
        onSkuMatched={onSkuMatched}
        onPackageTypeChange={onPackageTypeChange}
        onOptionsChange={onOptionsChange}
      />
      <ContentGroup>
        <GroupTitle>{t('sections.price')}</GroupTitle>
        <GroupValue>{sku?.price && formatPrice(sku?.price)}</GroupValue>
      </ContentGroup>
      <ContentGroup>
        <GroupTitle>{t('sections.quantity')}</GroupTitle>
        <NumberInputContainer>
          <NumberInput
            disabled={!sku || sku?.is_out_of_stock || isFake || isQtyDisabled}
            min={minQty}
            max={maxQty}
            value={qty || 0}
            onChange={qty => onQtyChange(qty)}
            size={'large'}
            name={'quantity'}
            onDecrease={() =>
              track(inTruck ? AdderWidgetEvents.ChangeProductQtyInTruck : AdderWidgetEvents.ChangeProductQty, {
                ...cardToEventParams(card),
                type: ChangeQtyType.Minus,
              })
            }
            onIncrease={() =>
              track(inTruck ? AdderWidgetEvents.ChangeProductQtyInTruck : AdderWidgetEvents.ChangeProductQty, {
                ...cardToEventParams(card),
                type: ChangeQtyType.Plus,
              })
            }
            testId={'item-count'}
            inputStep={0.1}
          />
          <MinMax min={minQty} max={maxQty} />
          <BaseUnits qty={qty} sku={sku} />
        </NumberInputContainer>
      </ContentGroup>
      {isMaxQtyReached && (
        <MaxQtyHint dangerouslySetInnerHTML={{ __html: t('maxQtyHint') }} data-test-id={'max-qty-hint'} />
      )}
      {hasDiscounts ? (
        <DiscountsWrapper>
          <DiscountSuggestion
            units={sku?.product?.units}
            nextLvlQty={nextLvlQuantity}
            currentDiscountAmountPerBag={currentDiscountAmountPerBag}
            isCartEmpty={!hasSimilarProductInCart}
            currency={card.seller?.currency || Currency.CAD}
            onQtyAdd={handleAddDiscountSuggestionQty}
          />
        </DiscountsWrapper>
      ) : (
        <DiscountsWrapper>
          <BestOfferSuggestion currency={card.seller?.currency || Currency.CAD} bestOffer={bestOffer} />
        </DiscountsWrapper>
      )}
      {!!card.docusign_document_id && (
        <SectionWrapper>
          <SectionTitle>{t('sections.legalNotesHeader')}</SectionTitle>
          <SectionHint>{t('sections.legalNotesDescription')}</SectionHint>
        </SectionWrapper>
      )}
    </>
  )
}

const AlreadyAdded: React.FC<AdderWidgetMobileProps> = ({ card, sku, cartItem, qty, ...props }) => {
  const generateCountryPath = useCountryPath()
  const { t } = useTranslation('order')
  const { track } = useAnalyticsSSR()
  const isFake = Boolean(cartItem?.is_fake)
  const { producerSlug } = useParams<{ producerSlug: string }>()

  return (
    <Container>
      <AdderMobileDetails cartItem={cartItem} card={card} sku={sku} qty={qty} {...props} />
      <ButtonsGroup>
        <Link to={generateCountryPath(Routes.ProducerCart, { producerSlug })}>
          <Button
            intent={'secondary'}
            size={'big'}
            onClick={() => track(AdderWidgetEvents.GoToCheckout, { ...cardToEventParams(card), quantity: qty })}
            disabled={!sku || sku?.is_out_of_stock || isFake}
            progress={isFake ? Progress.WORK : Progress.IDLE}
          >
            {t('gotoCheckout')}
          </Button>
        </Link>
      </ButtonsGroup>
    </Container>
  )
}

const HowItWorksButtonStyled = styled(HowItWorksButton)`
  margin: 30px auto 0;
`

const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${props => props.theme.color.secondary200};
`

const SectionHint = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
  margin-top: 5px;
  margin-bottom: 15px;
`

const SectionWrapper = styled.div`
  margin-top: 16px;
`

type NotAddedProps = Omit<AdderWidgetMobileProps, 'cartItem'>

const NotAdded: React.FC<NotAddedProps> = ({ card, sku, qty, ...props }) => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation('order')
  const params = useParams<{ producerSlug: string }>()
  const [, seller] = useProducer(params.producerSlug)
  const addAction = useAction(CartSkuActions.itemAddRequested)
  const { how_it_works } = { ...seller?.config?.seller_config }

  const handleAdd = () => {
    if (!sku) return
    track(AdderWidgetEvents.AddToTruckClick, { ...cardToEventParams(card), quantity: qty })

    addAction({
      card,
      sku,
      seller,
      quantity: String(qty),
    })
  }

  const handleHowItWorksClick = () => {
    track(AdderWidgetEvents.HowItWorksClick, cardToEventParams(card))
  }

  return (
    <Container>
      <AdderMobileDetails card={card} sku={sku} qty={qty} {...props} />
      <ButtonsGroup>
        <Button
          id={'add-to-cart-button'}
          size={'big'}
          intent={'primary-action'}
          disabled={!sku || sku?.is_out_of_stock}
          onClick={handleAdd}
          filled
        >
          {t('addToCart')}
        </Button>
      </ButtonsGroup>
      <HowItWorksButtonStyled items={how_it_works || []} onClick={handleHowItWorksClick} />
    </Container>
  )
}

const AdderWidgetMobile: React.VFC<AdderWidgetMobileProps> = ({ cartItem, isQtyDisabled, ...props }) => {
  const cartSkuItem = useSelector(state => CartSkuSelectors.cartEntryBySkuId(state, props.sku?.id || ''))
  const isAdded = cartSkuItem && props.sku
  const addedSkuItem = isAdded ? cartSkuItem : cartItem

  return (
    <div data-test-id="adder-widget-mobile">
      {isAdded ? (
        <AlreadyAdded {...props} isQtyDisabled={isQtyDisabled} cartItem={addedSkuItem} />
      ) : (
        <NotAdded {...props} isQtyDisabled={isQtyDisabled} />
      )}
    </div>
  )
}

export default AdderWidgetMobile

import { useDidMount } from '@agro-club/frontend-shared'

const store = new Map<string, [number, number]>()
const usePersistentScroll = (id: string) => {
  useDidMount(() => {
    const pos = store.get(id)
    if (pos) {
      window.scrollTo(...pos)
    } else {
      window.scrollTo(0, 0)
    }
    return () => {
      store.set(id, [window.scrollX, window.scrollY])
    }
  })
}

export default usePersistentScroll

import React, { FC, useEffect, useState } from 'react'
import { ProductWizardType, useAction } from '@agro-club/frontend-shared'
import CartActions from 'modules/domain/cart/duck'
import { useSelector } from 'react-redux'
import CartSelectors from 'modules/domain/cart/selectors'
import { ProductItemDto } from 'views/pages/Producer/Product/ProductItem/ProductItem'
import { AdderWidget } from 'views/components/ProductWizards/AdderWidget'
import { ProductWizardDuckFoot } from 'views/components/ProductWizards/ProductWizardDuckFoot/ProductWizardDuckFoot'
import { ProductWizardProcote } from 'views/components/ProductWizards/ProductWizardProcote/ProductWizardProcote'

type PropsType = {
  product: ProductItemDto
  isMobile: boolean
}
export const ProductWizardManager: FC<PropsType> = ({ product, isMobile }: PropsType) => {
  const requestProductWizard = useAction(CartActions.productWizardRequested)
  const productWizard = useSelector(CartSelectors.productWizard)
  const progress = useSelector(CartSelectors.productWizardProgress)
  const [openedProductWizard, setOpenedProductWizard] = useState(false)

  const handleWizardOpen = () => {
    setOpenedProductWizard(true)
  }
  const handleWizardClose = () => {
    setOpenedProductWizard(false)
  }

  useEffect(() => {
    requestProductWizard(product.wizard_id, { sort_field: 'order' })
  }, [product.wizard_id, requestProductWizard])

  const duckFootJSX =
    productWizard?.[0]?.type === ProductWizardType.DuckFoot ? (
      <ProductWizardDuckFoot opened={openedProductWizard} onClose={handleWizardClose} isMobile={isMobile} />
    ) : null

  const procoteJSX =
    productWizard?.[0]?.type === ProductWizardType.Procote ? (
      <ProductWizardProcote
        opened={openedProductWizard}
        onClose={handleWizardClose}
        isMobile={isMobile}
        productWizard={productWizard[0]}
        categoryId={product.category_id || ''}
      />
    ) : null

  return (
    <>
      {duckFootJSX}
      {procoteJSX}
      <AdderWidget
        product={product}
        handleWizardOpen={handleWizardOpen}
        productWizardType={productWizard?.[0]?.type}
        progress={progress}
      />
    </>
  )
}

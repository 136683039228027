import React, { useContext } from 'react'
import { WhiteLabelConfig } from 'service/whiteLabelConfig/interface'

export const WhiteLabelContext = React.createContext<{ config?: WhiteLabelConfig }>({})

const useWhiteLabelConfig = () => {
  const { config } = useContext(WhiteLabelContext)
  return { config }
}

export default useWhiteLabelConfig

export const downloadUrl = (url: string, headers: Record<string, string>, fileName?: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName || document.title)
  const mimeType = headers['content-type'] ? headers['content-type'].split(';')[0] : ''
  link.setAttribute('type', mimeType)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const downloadBlob = (data: Blob, headers: any, fileName?: string) => {
  if (!window || !document) return
  const url = URL.createObjectURL(data)
  downloadUrl(url, headers, fileName)
  URL.revokeObjectURL(url)
}

import React from 'react'
import styled from 'styled-components'
import { Chip, DiscountBadge } from '@agro-club/frontend-shared'
import logoPlaceholder from 'assets/images/logo-placeholder.svg'
import { useCompanyCategoryList } from 'modules/domain/collection/hooks'
import { useCountryPath } from 'hooks/useCountryPath'
import { useHistory, useParams } from 'react-router-dom'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { Routes } from 'views/pages/routes'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.onPrimaryLight};
  border-radius: 16px;
  border: 1px solid transparent;
  padding: 24px 40px;
  max-width: 628px;
  width: auto;
  font-style: normal;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in;
  &:hover {
    box-shadow: ${props => props.theme.shadow.blur40};
  }
  ${props => props.theme.media.tablet`
    padding: 16px 40px 16px 16px;
  `}
  ${props => props.theme.media.mobile`
    padding: 16px 16px 8px 16px;
    width: 100%;
  `}
`

const Logo = styled.img`
  height: 48px;
  object-fit: contain;
  object-position: left;

  ${props => props.theme.media.tablet`
    margin-bottom: 40px;
  `}
  ${props => props.theme.media.mobile`
    margin-bottom: 16px;
  `}
`

const Title = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 0;

  color: ${({ theme }) => theme.color.onPrimaryDark};
  ${props => props.theme.media.tablet`
    display: none;
  `}
  ${props => props.theme.media.mobile`
    display: none;
  `}
`

const Description = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 8px;

  ${props => props.theme.media.mobile`
    margin-bottom: 16px;
  `}
`

const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  justify-self: flex-end;
  ${props => props.theme.media.mobile`
    display: none;
  `}
`

const ChipStyled = styled(Chip)`
  margin-right: 8px;
  margin-bottom: 8px;
  &:hover {
    color: ${props => props.theme.color.primary500};
  }
`

const DiscountBadgeStyled = styled(DiscountBadge)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 0px;
  transform: translateY(-40%);
`

type Props = {
  title: string
  id: string
  logoUrl?: string
  discountText?: string
  description: string
  className?: string
  producerSlug?: string

  onClick(): void
}

enum AnalyticsEvent {
  SubCategoryTap = 'sub_category_tap',
}

const ProducerCard: React.FC<Props> = ({
  title,
  id,
  logoUrl,
  description,
  discountText,
  className,
  producerSlug,
  onClick,
}) => {
  const [, categories = []] = useCompanyCategoryList(id)
  const history = useHistory()
  const generateCountryPath = useCountryPath()
  const { country } = useParams<{ country: string }>()
  const { track } = useAnalyticsSSR()

  const onChipClick = categorySlug => {
    track(AnalyticsEvent.SubCategoryTap, { name: categorySlug })

    const url = generateCountryPath(Routes.SubCategoryList, {
      country,
      producerSlug,
      categorySlug,
    })

    history.push(url)
  }

  return (
    <Wrapper className={className} onClick={onClick} data-test-id={'producer-card'}>
      {discountText && <DiscountBadgeStyled text={discountText} />}
      <Logo src={logoUrl ? logoUrl : logoPlaceholder} />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <ChipsWrapper onClick={e => e.stopPropagation()}>
        {categories.map(item => (
          <ChipStyled
            color={'gray'}
            active={true}
            key={item.id}
            value={item.id}
            onClick={onChipClick}
            data-test-id={`chip-${item.slug}`}
          >
            {item.title}
            {/* item.isDiscount && <DiscountBadge text={'%'} size={'small'} /> */}
          </ChipStyled>
        ))}
      </ChipsWrapper>
    </Wrapper>
  )
}

export default ProducerCard

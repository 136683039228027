import {
  Button,
  IconCross,
  IconPencil,
  Input,
  OptionalString,
  RemovePopover,
  SectionContainer,
  SectionTitle,
  useAction,
} from '@agro-club/frontend-shared'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useCompanyFeatureFlags from 'hooks/useCompanyFeatureFlags'
import { useCountryPath } from 'hooks/useCountryPath'
import useHelmet from 'hooks/useHelmet'
import useMatchMedia from 'hooks/useMatchMedia'
import useWLFeatureFlags from 'hooks/useWLFeatureFlags'
import { Card } from 'modules/domain/card/types'
import CartSkuActions from 'modules/domain/cartSku/duck'
import CartSkuSelectors from 'modules/domain/cartSku/selectors'
import { CartSkuItem, UpdateCartSkuItemParams } from 'modules/domain/cartSku/types'
import OrderActions from 'modules/domain/order/duck'
import { useOrder } from 'modules/domain/order/hooks'
import OrderSelectors from 'modules/domain/order/selectors'
import { useProductOptionsList } from 'modules/domain/productOptions/hooks'
import { Sku } from 'modules/domain/sku/types'
import { Progress } from 'modules/types'
import {
  generateActionAccessString,
  generateButtonAccessString,
  generateCustomFeatureFlag,
  generateFieldModifierString,
} from 'modules/utils/generateStringHelpers'
import { clone, equals } from 'ramda'
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { queries } from 'theme/theme'
import { Company, LocalizedValue, Status } from 'types/entities'
import {
  Actions,
  Buttons,
  CustomFeatureName,
  FeatureFlagModifiers,
  FieldLocation,
  FieldNames,
  Sections,
} from 'types/entities/config'
import { OrderItem, OrderItemDTO, OrderStatus } from 'types/order/index'
import { CartSkuList, MobileCartSkuList } from 'views/components/CartSkuList/CartSkuList'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { ProfileEvents } from 'views/pages/Profile/events'
import Routes from 'views/pages/Profile/routes'
import * as Styled from 'views/pages/Profile/styled'

const mapOrderItemToCartSkuItem = (item: OrderItem, discount_total: string, showUnits: boolean): CartSkuItem => ({
  id: item.id,
  sku: item.sku as Sku,
  sku_id: item.sku_id as string,
  card: item.sku_card as Card,
  seller_id: item.sku?.seller_id as string,
  title: item.sku_card?.title_i18n as LocalizedValue,
  description: item.sku_card?.description_i18n as LocalizedValue,
  quantity: item.quantity,
  images: item.sku_card?.images || [],
  cost: item.cost,
  discount_amount: discount_total,
  showUnits,
  units: item.sku?.product?.units,
})

const mapCartSkuItemsToOrderItems = (item: CartSkuItem): OrderItemDTO => ({
  id: item.id,
  sku_id: item.sku_id,
  card_id: item.card?.id,
  quantity: item.quantity,
})

type Action =
  | { type: 'init'; data: CartSkuItem[] }
  | { type: 'remove'; id: string }
  | { type: 'change-qty'; id: string; qty: UpdateCartSkuItemParams['quantity'] }
  | { type: 'reset'; data: CartSkuItem[] }

function reducer(state: CartSkuItem[], action: Action) {
  switch (action.type) {
    case 'init':
      return action.data
    case 'remove':
    case 'change-qty':
      const index = state.findIndex(item => item.id === action.id)
      const newList = clone(state)
      switch (action.type) {
        case 'remove':
          newList.splice(index, 1)
          break
        case 'change-qty':
          if (newList[index]) {
            newList[index].quantity = action.qty || '0'
          }
          break
      }
      return newList
    case 'reset':
      return action.data
    default:
      throw new Error()
  }
}

const SkuOrderDetails = () => {
  const params = useParams<{ orderId: string }>()
  const { t } = useTranslation(['profile', 'common'])
  const isMobile = useMatchMedia(queries.mobile)
  const [editable, setEditable] = useState(false)
  const [progress, order] = useOrder(params.orderId)
  const updateProgress = useSelector(OrderSelectors.updateProgress)
  const prevUpdateProgress = useRef(updateProgress)
  const updateAction = useAction(OrderActions.updateSkuRequested)
  const cancelOrderAction = useAction(OrderActions.cancelSkuOrderRequested)
  const checkFeatureFlag = useWLFeatureFlags()
  const { track } = useAnalyticsSSR()
  const theme = useTheme()
  // todo api for option ids
  const [, productOptions] = useProductOptionsList()

  const history = useHistory()
  const generateCountryPath = useCountryPath()
  const addItemsAction = useAction(CartSkuActions.itemsAddRequested)
  const itemsInCart = useSelector(state => CartSkuSelectors.cartItemsBySellerId(state, order?.seller_id as string))
  const addItemsProgress = useSelector(CartSkuSelectors.addItemsProgress)
  const refreshProgress = useSelector(CartSkuSelectors.refreshProgress)
  const checkCompanyFeatureFlags = useCompanyFeatureFlags(order?.seller_id as string)
  const farmerConfirmRequired = checkCompanyFeatureFlags(
    generateCustomFeatureFlag(Sections.Orders, CustomFeatureName.FarmerConfirmRequired),
  )
  const showUnits = checkCompanyFeatureFlags(
    generateFieldModifierString(FieldLocation.CartList, FieldNames.Units, FeatureFlagModifiers.Enabled),
  )
  const isQtyDisabled = checkCompanyFeatureFlags(
    generateFieldModifierString(FieldLocation.CartList, FieldNames.Qty, FeatureFlagModifiers.Disabled),
  )
  const isPreorder = useMemo(
    () => !order?.interaction.confirmed_by_farmer && order?.status !== 'canceled' && farmerConfirmRequired,
    [farmerConfirmRequired, order?.interaction.confirmed_by_farmer, order?.status],
  )

  const [farmerComment, setFarmerComment] = useState('')

  useEffect(() => {
    if (order) setFarmerComment(order.farmer_comment || '')
  }, [order])

  const cartItems: CartSkuItem[] = useMemo(() => {
    if (!order || !order.sku_items.length) return []
    return order.sku_items.map(item => mapOrderItemToCartSkuItem(item, order.discount_total, showUnits))
  }, [order, showUnits])

  const [editedCartItems, dispatch] = useReducer(reducer, clone(cartItems))

  useEffect(() => {
    dispatch({ type: 'init', data: cartItems })
  }, [cartItems])

  const handleRemoveItem = useCallback(
    (id: string) => {
      track(ProfileEvents.OrderDeleteProductTap)
      dispatch({ type: 'remove', id })
    },
    [track],
  )

  const handleQtyChange = useCallback((id: string, qty?: number) => {
    dispatch({ type: 'change-qty', id, qty: OptionalString(qty) || '0' })
  }, [])

  const cartProps = {
    editable,
    data: editable ? editedCartItems : cartItems,
    onRemove: handleRemoveItem,
    onQtyChange: handleQtyChange,
    emptyText: t('orders.emptyOrder'),
  }

  const handleSaveClick = useCallback(() => {
    if (order) {
      track(ProfileEvents.OrderEditSaveTap)
      updateAction(order.id, { sku_items: editedCartItems.map(mapCartSkuItemsToOrderItems), revision: order.revision })
    }
  }, [editedCartItems, order, track, updateAction])

  useEffect(() => {
    if (prevUpdateProgress.current !== Progress.SUCCESS && updateProgress === Progress.SUCCESS) {
      setEditable(false)
    }
  }, [updateProgress, prevUpdateProgress])

  const handleCancelClick = useCallback(() => {
    track(ProfileEvents.OrderEditCancelTap)
    setEditable(false)
    dispatch({ type: 'reset', data: cartItems })
  }, [cartItems, track])

  const handleRefresh = useCallback(() => {
    setEditable(false)
    dispatch({ type: 'reset', data: cartItems })
    if (order) {
      updateAction(order?.id, {})
    }
  }, [cartItems, order, updateAction])

  const handleCancelOrderClick = useCallback(() => {
    track(ProfileEvents.OrderCancelTap)
    cancelOrderAction(order)
  }, [cancelOrderAction, track, order])

  const handleConfirmOrder = useCallback(() => {
    if (order) {
      const redirectPath = generateCountryPath(Routes.Orders)
      updateAction(
        order.id,
        {
          interaction: {
            ...order.interaction,
            confirmed_by_farmer: true,
          },
          status: OrderStatus.Confirmed,
        },
        redirectPath,
      )
    }
  }, [generateCountryPath, order, updateAction])

  const handleBack = useCallback(() => {
    history.push(generateCountryPath(Routes.Orders))
  }, [generateCountryPath, history])

  const canRepeatOrder = useMemo(() => !cartItems.every(item => itemsInCart.find(i => i.sku?.id === item.sku?.id)), [
    cartItems,
    itemsInCart,
  ])

  const repeatButtonDisabled = useMemo(() => {
    const inProgress = addItemsProgress === Progress.WORK || refreshProgress === Progress.WORK
    return !canRepeatOrder || inProgress
  }, [addItemsProgress, canRepeatOrder, refreshProgress])

  const canAddItemToCart = useCallback(
    (item: CartSkuItem) => {
      const isActive = item.sku?.status === Status.Active
      const inStock = !item.sku?.is_out_of_stock
      const inCart = itemsInCart.find(i => i.sku?.id === item.sku?.id)

      return isActive && inStock && !inCart
    },
    [itemsInCart],
  )

  const handleRepeatOrder = useCallback(() => {
    if (!canRepeatOrder) return

    const reorderedItems = cartItems
      .map((item: CartSkuItem) => {
        if (!canAddItemToCart(item)) return

        return {
          sellerId: item.seller_id,
          quantity: item.quantity,
          sku_id: item.sku_id,
          sku: item.sku,
          product_card_id: item.card?.id,
          wizard_comment: item.wizard_comment,
          wizard_data: item.wizard_data,
          farmer_comment: farmerComment,
        }
      })
      .filter(Boolean)

    if (!reorderedItems.length) return
    addItemsAction(reorderedItems, order?.producer?.slug)
  }, [addItemsAction, canAddItemToCart, canRepeatOrder, cartItems, farmerComment, order?.producer?.slug])

  useHelmet({ title: t('orders.list.title', { id: params.orderId }) })

  if ((progress === Progress.SUCCESS && !order) || progress === Progress.ERROR) {
    return (
      <Styled.ContentWrapper>
        <SectionContainer noDivider>
          <SectionTitle onBack={handleBack}>
            <Styled.Title>{t('orders.list.title', { id: '' })}</Styled.Title>
          </SectionTitle>
        </SectionContainer>
        <Styled.Container paddedOnMobile>{t('orders.notFound')}</Styled.Container>
      </Styled.ContentWrapper>
    )
  }

  if (progress === Progress.WORK || !order) {
    return (
      <Styled.SpinnerWrapper>
        <SpinnerLayout />
      </Styled.SpinnerWrapper>
    )
  }

  const isDirty = cartItems.length && editedCartItems.length && !equals(cartItems, editedCartItems)
  const isEditAllowed =
    ![OrderStatus.Canceled, OrderStatus.Completed, OrderStatus.Deleted].includes(order.status) &&
    !checkFeatureFlag(generateActionAccessString(Sections.OrderDetails, Actions.Edit, FeatureFlagModifiers.Restricted))
  const revisionMismatch = updateProgress === Progress.ERROR

  const isCancelOrderButtonVisible = !checkFeatureFlag(
    generateButtonAccessString(Sections.OrderDetails, Buttons.CancelOrder, FeatureFlagModifiers.Hidden),
  )

  const cancelOrderBtnJSX = isCancelOrderButtonVisible &&
    (order.status === OrderStatus.New || order.status === OrderStatus.Changed) && (
      <RemovePopover
        onRemove={handleCancelOrderClick}
        text={t('common:confirmCancelOrder')}
        position="below"
        cancelText={t('common:no')}
        submitText={t('common:yesCancel')}
      >
        <Styled.CancelButton iconColor={theme.color.secondary200} hideTextOnMobile Icon={IconCross}>
          {t('common:cancel')}
        </Styled.CancelButton>
      </RemovePopover>
    )

  return (
    <Styled.ContentWrapper>
      <SectionContainer noDivider>
        <SectionTitle onBack={handleBack} backButton={!editable}>
          <Styled.Title>
            {editable ? t('orders.list.titleEdit', { id: order.slug }) : t('orders.list.title', { id: order.slug })}
          </Styled.Title>
          {!editable && isEditAllowed && (
            <Styled.EditButton
              Icon={IconPencil}
              hideTextOnMobile={true}
              onClick={() => {
                track(ProfileEvents.OrderEditTap)
                setEditable(true)
              }}
              data-test-id="profile-order-edit"
              iconColor={theme.color.accentApproving100}
              className="primaryAction"
            >
              {t('common:edit')}
            </Styled.EditButton>
          )}
          {cancelOrderBtnJSX}
        </SectionTitle>
        <Styled.Container paddedOnMobile>
          {isMobile ? (
            <MobileCartSkuList
              {...cartProps}
              productOptions={productOptions || []}
              seller={order?.producer as Company}
              isQtyDisabled={isQtyDisabled}
            />
          ) : (
            <CartSkuList
              {...cartProps}
              productOptions={productOptions || []}
              seller={order?.producer as Company}
              isQtyDisabled={isQtyDisabled}
            />
          )}
          {farmerComment && <Input disabled value={farmerComment} />}
          {editable && (
            <Styled.ButtonsWrapper>
              <Button
                intent={'primary-action'}
                size={'big'}
                filled
                onClick={handleSaveClick}
                disabled={!isDirty || revisionMismatch}
                progress={updateProgress}
                data-test-id="save-changes-button"
              >
                {t('common:saveChanges')}
              </Button>
              <Button
                intent={'cancel'}
                size={'big'}
                onClick={handleCancelClick}
                disabled={updateProgress === Progress.WORK}
                data-test-id="cancel-changes-button"
              >
                {t('common:cancel')}
              </Button>
            </Styled.ButtonsWrapper>
          )}
          {!editable && (
            <Styled.ButtonsWrapper>
              {isPreorder ? (
                <Button
                  intent={'primary-action'}
                  size={'big'}
                  filled
                  onClick={handleConfirmOrder}
                  data-test-id="confirm-order-button"
                  progress={updateProgress}
                  disabled={updateProgress === Progress.WORK}
                >
                  {t('orders.orderDetails.confirmOrder')}
                </Button>
              ) : (
                <Button
                  intent={'primary-action'}
                  size={'big'}
                  filled
                  onClick={handleRepeatOrder}
                  disabled={repeatButtonDisabled}
                  progress={addItemsProgress}
                  data-test-id="repeat-order-button"
                >
                  {t('orders.orderDetails.repeatOrder')}
                </Button>
              )}
            </Styled.ButtonsWrapper>
          )}
        </Styled.Container>
      </SectionContainer>
      {revisionMismatch && (
        <Styled.RevisionWarning>
          <div>{t('orders.orderDetails.errors.revisionMismatch')}</div>
          <Button intent={'warning'} filled progress={progress} onClick={handleRefresh}>
            {t('orders.orderDetails.refreshOrder')}
          </Button>
        </Styled.RevisionWarning>
      )}
    </Styled.ContentWrapper>
  )
}

export default SkuOrderDetails

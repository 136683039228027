import { AppGlobalState } from '../../types'
import { createSelector } from 'reselect'
import AuthSelectors from 'modules/domain/auth/selectors'
import { CheckoutStep } from 'modules/domain/checkout/types'

export const getDeliveryAddresses = (state: AppGlobalState) => state.checkout.deliverySplitAddresses
export const getStep = (state: AppGlobalState) => state.checkout.step
export const getCheckoutProgress = (state: AppGlobalState) => state.checkout.checkoutProgress
export const getDeliveryAddressType = (state: AppGlobalState) => state.checkout.deliverAddressType
export const getDistributorId = (state: AppGlobalState) => state.checkout.distributorId
export const getDistributorTitle = (state: AppGlobalState) => state.checkout.distributorTitle
export const deliveryAddressesSelector = createSelector(getDeliveryAddresses, addrs => addrs)
export const desiredDeliveryDate = (state: AppGlobalState) => state.checkout.desiredDeliveryDate

export const stepSelector = createSelector(
  AuthSelectors.isAuthenticated,
  AuthSelectors.isProfileFulfilled,
  getStep,
  (isAuthenticated, isProfileFulfilled, step) => {
    if (!isAuthenticated || !isProfileFulfilled) {
      return CheckoutStep.SIGN_IN
    }
    return step
  },
)

const CheckoutSelectors = {
  deliveryAddresses: deliveryAddressesSelector,
  step: stepSelector,
  checkoutProgress: getCheckoutProgress,
  deliveryAddressType: getDeliveryAddressType,
  distributorId: getDistributorId,
  distributorTitle: getDistributorTitle,
  desiredDeliveryDate,
}

export default CheckoutSelectors

import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'

import ProducerSelectors from 'modules/domain/producer/selectors'
import ProducerRoutes from 'views/pages/Producer/routes'
import { Routes } from 'views/pages/routes'

const useAnalyticDataFromLocation = () => {
  const { pathname } = useLocation()
  const match = matchPath<{ country: string; producerSlug?: string }>(pathname, {
    path: [ProducerRoutes.ProducerInfoPage, Routes.Root],
  })
  const producer = useSelector(state => ProducerSelectors.item(state, match?.params.producerSlug || ''))

  const analyticData = {
    country: match?.params.country.toUpperCase(),
    producer_id: producer?.id,
    producer_name: producer?.official_name,
  }

  return analyticData
}

export default useAnalyticDataFromLocation

import styled, { StyledProps } from 'styled-components'

type FormRowProps = {
  width?: string
  hideTablet?: boolean
  hideMobile?: boolean
  hideDesktop?: boolean
}

export const FormRow = styled.div<StyledProps<FormRowProps>>`
  :not(:last-child) {
    margin-bottom: 20px;
  }

  width: ${({ width }) => (width ? width : '100%')};

  ${props => props.theme.media.desktop`
    display: ${({ hideDesktop }: FormRowProps) => (hideDesktop ? 'none' : 'inherit')};
  `}

  ${props => props.theme.media.tablet`
    display: ${({ hideTablet }: FormRowProps) => (hideTablet ? 'none' : 'inherit')};
  `}

  ${props => props.theme.media.mobile`
    display: ${({ hideMobile }: FormRowProps) => (hideMobile ? 'none' : 'inherit')};
  `}
`

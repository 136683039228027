import React, { useState } from 'react'
import { CollapseButton } from '@agro-club/frontend-shared'
import * as Styled from './styles'

type Props = {
  text: string
  collapsible?: boolean
  textStyles?: string
}

const CollapsibleText: React.VFC<Props> = ({ text, collapsible = false, textStyles = '' }) => {
  const [isTextCollapsed, setIsTextCollapsed] = useState<boolean>(true)

  const toggleTextCollapse = () => {
    setIsTextCollapsed(!isTextCollapsed)
  }

  return (
    <>
      <Styled.Text collapsed={collapsible && isTextCollapsed} textStyles={textStyles}>
        {text}
      </Styled.Text>
      {collapsible && <CollapseButton onClick={toggleTextCollapse} collapsed={!isTextCollapsed} />}
    </>
  )
}

export default React.memo(CollapsibleText)

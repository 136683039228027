import React, { useCallback, useMemo } from 'react'
import useLangPicker from 'hooks/useLangPicker'
import {
  ProductOptionsData,
  ProductOptionsGrouped,
  ProductOptionType,
  ProductOptions as ProductOptionsType,
} from 'modules/domain/productOptions/types'
import { useTranslation } from 'react-i18next'
import { SimpleSelect } from '@agro-club/frontend-shared'
import * as Styled from './styled'

export enum Place {
  WIDGET = 'widget',
  CART = 'cart',
  CHECKOUT = 'checkout',
  CONFIRMATION = 'confirmation',
  MOBILE = 'mobile',
}

export const ProductOptions: React.FC<{
  options: ProductOptionsData[]
  selectedIds?: string[]
  optionsRequiredErrors: string[]
  onChange?: (value: string[]) => void
  place?: Place
  isDisabled?: boolean
  productOptions: ProductOptionsType[]
}> = ({
  options,
  selectedIds = [],
  optionsRequiredErrors = [],
  onChange,
  place = Place.WIDGET,
  isDisabled = false,
  productOptions,
}) => {
  const { t } = useTranslation('productOptions')
  const { pick } = useLangPicker()

  const selects = useMemo(() => {
    const groupes: ProductOptionsGrouped[] = []
    if (!options) return []

    options.map(option => {
      const data = option.option_ids.map(id => {
        const found = productOptions?.find(item => item.id === id)
        return { id, title: pick(found?.title_i18n) as string }
      })
      let selectedId: string | undefined = undefined

      if (selectedIds?.length) {
        selectedIds?.forEach(id => {
          if (option.option_ids.includes(id)) selectedId = id
        })
      }

      groupes.push({ type: option.type, required: option.required, data, selectedId })
    })

    return groupes
  }, [options, pick, productOptions, selectedIds])

  const isInvalid = useCallback((type: ProductOptionType) => !!optionsRequiredErrors.includes(type), [
    optionsRequiredErrors,
  ])

  const getStyle = useCallback((type: ProductOptionType) => ({ marginBottom: isInvalid(type) ? '20px' : 'initial' }), [
    isInvalid,
  ])

  const handleChange = useCallback(
    (prevId?: string, id?: string) => {
      const updatedIds = selectedIds?.filter(id => id !== prevId)
      updatedIds?.push(id as string)
      onChange && onChange(updatedIds as string[])
    },
    [onChange, selectedIds],
  )

  const getErrorText = useCallback(
    (type: ProductOptionType): string =>
      optionsRequiredErrors.length && optionsRequiredErrors.includes(type) ? t('validation:field_required') : '',
    [optionsRequiredErrors, t],
  )

  return (
    <>
      {selects.map((select, idx: number) => {
        if (place === Place.WIDGET) {
          return (
            <Styled.WidgetSectionWrapper
              data-test-id={`widget-option-select-${idx}`}
              style={getStyle(select.type)}
              key={idx}
            >
              <Styled.WidgetSectionTitle>{t(`types.${select.type}`)}</Styled.WidgetSectionTitle>
              <SimpleSelect
                onChange={(id: string) => handleChange(select.selectedId, id)}
                options={select.data}
                value={select.selectedId}
                isSearchable
                required={select.required}
                invalid={isInvalid(select.type)}
                errorText={getErrorText(select.type)}
                isDisabled={isDisabled}
                customStyles={Styled.customSelectStyles}
                placeholder={`${t('placeholderPrefix')} ${t(`types.${select.type}`).toLowerCase()}`}
              />
            </Styled.WidgetSectionWrapper>
          )
        }
        if (place === Place.CART) {
          return (
            <Styled.CartSectionWrapper
              data-test-id={`checkout-option-select-${idx}`}
              style={getStyle(select.type)}
              key={idx}
            >
              <Styled.CartSectionLabel>{t(`types.${select.type}`)}</Styled.CartSectionLabel>
              <Styled.CartSelect
                onChange={(id: string) => handleChange(select.selectedId, id)}
                options={select.data}
                value={select.selectedId}
                isSearchable
                customStyles={Styled.selectCustomStyles}
                required={select.required}
                invalid={isInvalid(select.type)}
                errorText={getErrorText(select.type)}
                isDisabled={isDisabled}
                placeholder={`${t('placeholderPrefix')} ${t(`types.${select.type}`).toLowerCase()}`}
              />
            </Styled.CartSectionWrapper>
          )
        }
        if (place === Place.CHECKOUT) {
          return (
            <Styled.CheckoutSectionWrapper
              data-test-id={`checkout-option-select-${idx}`}
              style={getStyle(select.type)}
              key={idx}
            >
              <Styled.CheckoutSectionLabel>{t(`types.${select.type}`)}</Styled.CheckoutSectionLabel>
              <Styled.CheckoutSelect
                onChange={(id: string) => handleChange(select.selectedId, id)}
                options={select.data}
                value={select.selectedId}
                isSearchable
                customStyles={Styled.selectCustomStyles}
                required={select.required}
                invalid={isInvalid(select.type)}
                errorText={getErrorText(select.type)}
                isDisabled={isDisabled}
                placeholder={`${t('placeholderPrefix')} ${t(`types.${select.type}`).toLowerCase()}`}
              />
            </Styled.CheckoutSectionWrapper>
          )
        }
        if (place === Place.CONFIRMATION) {
          const option = select.data.find(o => o.id === select.selectedId)
          return option ? (
            <Styled.ConfirmationSectionWrapper
              data-test-id={`confirmation-option-select-${idx}`}
              style={getStyle(select.type)}
              key={idx}
            >
              <Styled.ConfirmationLabel>
                {t(`types.${select.type}`)}
                {': '}
              </Styled.ConfirmationLabel>
              <Styled.ConfirmationTitle>{option?.title || '-'}</Styled.ConfirmationTitle>
            </Styled.ConfirmationSectionWrapper>
          ) : null
        }
        if (place === Place.MOBILE) {
          return (
            <Styled.MobileWrapper data-test-id={`mobile-option-select-${idx}`} style={getStyle(select.type)} key={idx}>
              <Styled.MobileLabel>{t(`types.${select.type}`)}</Styled.MobileLabel>
              <Styled.CartSelect
                onChange={(id: string) => handleChange(select.selectedId, id)}
                options={select.data}
                value={select.selectedId}
                isSearchable
                customStyles={Styled.selectCustomStyles}
                required={select.required}
                invalid={isInvalid(select.type)}
                errorText={getErrorText(select.type)}
                isDisabled={isDisabled}
                placeholder={`${t('placeholderPrefix')} ${t(`types.${select.type}`).toLowerCase()}`}
              />
            </Styled.MobileWrapper>
          )
        }
      })}
    </>
  )
}

export default ProductOptions

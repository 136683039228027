import { Routes } from 'views/pages/routes'
import { Page } from './pageNames'

export const routesEventsMap = {
  [Routes.AccountInfo]: Page.ProfileAccountInfo,
  [Routes.DeliveryPoint]: Page.ProfileDeliveryPoint,
  [Routes.Orders]: Page.ProfileOrders,
  [Routes.Documents]: Page.ProfileDocuments,
  [Routes.Cart]: Page.Cart,
  [Routes.ProducerCart]: Page.ProducerCart,
  [Routes.Root]: Page.Root,
  [Routes.CategoryList]: Page.CategoryList,
  [Routes.SubCategoryList]: Page.SubCategoryList,
  [Routes.ProductOrCardList]: Page.ProductList,
  [Routes.ProductOrCardItem]: Page.ProductItem,
}

export const routeToEventName = (route?: keyof typeof routesEventsMap) => {
  return route ? routesEventsMap[route] : ''
}

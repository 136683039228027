import React from 'react'
import styled, { css, keyframes, StyledProps } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { IconButton, SectionBody, SectionTitle as SectionTitleComponent } from '@agro-club/frontend-shared'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.color.onPrimaryLight};
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;

  font-family: Montserrat;
  font-style: normal;

  width: 100%;

  ${props => props.theme.media.mobile`
    flex-direction: column-reverse;
  `}
`

const FormFieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 330px;

  :first-child {
    margin-top: 24px;
  }

  ${props => props.theme.media.mobile`
    width: 100%;
    max-width: 400px;
  `}
`

const FormFieldsetTitle = styled.legend`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  width: 100%;

  text-transform: uppercase;

  color: ${({ theme }) => theme.color.secondary200};
`

const Optional = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.accentNotify};
  margin-left: auto;
  text-transform: none;
`

export const FormContent = styled.form`
  display: grid;
  grid-gap: 32px;
`

type FormSectionProps = {
  title?: string
  optional?: boolean
  className?: string
}

export const FormSection: React.FC<FormSectionProps> = ({ title, children, optional, className }) => {
  const { t } = useTranslation('common')
  return (
    <FormFieldset className={className}>
      {!!title && (
        <FormFieldsetTitle>
          {title}
          {!!optional && <Optional>{t('optional')}</Optional>}
        </FormFieldsetTitle>
      )}
      {children}
    </FormFieldset>
  )
}

export const OnlyDesktop = styled.div`
  width: 100%;
  ${props => props.theme.media.tablet`
    display: none;
  `}

  ${props => props.theme.media.mobile`
    display: none;
  `}
`

type HiddenOnMediaProps = {
  hideTablet?: boolean
  hideMobile?: boolean
  hideDesktop?: boolean
}

type FormRowProps = HiddenOnMediaProps & { width?: string }

export const HiddenOnMedia = css`
  ${props => props.theme.media.desktop`
    display: ${({ hideDesktop }: HiddenOnMediaProps) => (hideDesktop ? 'none' : 'inherit')};
  `}

  ${props => props.theme.media.tablet`
    display: ${({ hideTablet }: HiddenOnMediaProps) => (hideTablet ? 'none' : 'inherit')};
  `}

  ${props => props.theme.media.mobile`
    display: ${({ hideMobile }: HiddenOnMediaProps) => (hideMobile ? 'none' : 'inherit')};
  `}
`

export const HiddenOnMediaBlock = styled.div<HiddenOnMediaProps>`
  ${HiddenOnMedia};
`

export const FormRow = styled.div<StyledProps<FormRowProps>>`
  margin-bottom: 16px;

  width: ${({ width }) => (width ? width : '100%')};

  ${HiddenOnMedia};
`

export const UppercaseText = styled.span`
  text-transform: uppercase;
`

export const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-right: auto;

  color: ${({ theme }) => theme.color.onPrimaryDark};

  ${props => props.theme.media.mobile`
    overflow: hidden;
    max-width: 194px;
    text-overflow: ellipsis;
`}
`

export const PartnershipInfo = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.onPrimaryDark};
  margin: -8px 0 16px;
`

export const PartnershipButton = styled.button`
  border: none;
  background: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.primary600};
  margin: 16px 0 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

export const SectionTitle = styled(SectionTitleComponent)`
  padding: 12px;
`

export const Container = styled(SectionBody)`
  grid-gap: 40px;

  button {
    justify-self: start;
  }

  ${props => props.theme.media.mobile`
    button {
      justify-self: auto;
    }
  `}
`

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: max-content max-content;
`

export const appear = keyframes`
  70% {
    transform: translateX(-50px);
  }

  100% {
    transform: translateX(0);
  }
`

export const RevisionWarning = styled.div`
  background: ${({ theme }) => theme.color.onPrimaryLight};
  color: ${({ theme }) => theme.color.accentNotify};
  border-radius: 16px;
  padding: 20px 24px 24px;
  position: fixed;
  bottom: 96px;
  right: 24px;
  z-index: ${props => props.theme.zIndex.popup};
  max-width: 320px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  transform: translateX(calc(100% + 96px));
  animation: ${appear} 0.3s ease-out;
  animation-fill-mode: forwards;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.04);

  button {
    margin-top: 24px;
  }
`

export const EditButton = styled(IconButton)`
  margin-right: 8px;
`

export const CancelButton = styled(IconButton)`
  color: ${({ theme }) => theme.color.secondary200};
  background-color: ${({ theme }) => theme.color.onPrimaryLight};
  &:hover {
    color: ${({ theme }) => theme.color.secondary200};
    background-color: ${({ theme }) => theme.color.onPrimaryLight + '22'};
  }
  background-color: ${({ theme }) => theme.color.onPrimaryLight};
`

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: stretch;
  flex-grow: 1;
  padding: 100px 0;
`

import { endpoints } from 'modules/endpoints'
import { ProductOptions, ProductOptionsListRequestSorting, ProductOptionsListRequestFilter } from './types'
import { ListResponse } from 'types/api'
import { ApiService } from 'service/api/interface'

export const getList = (apiClient: ApiService) => (
  filter: ProductOptionsListRequestFilter,
  sorting: ProductOptionsListRequestSorting,
) => {
  return apiClient.get<ListResponse<ProductOptions>>(endpoints.productOptions(), {
    ...filter,
    ...sorting,
    page_size: 1000,
  })
}

export const getItem = (apiClient: ApiService) => (id: string) => {
  return apiClient.get<ProductOptions>(endpoints.productOptions(id))
}

import { useSelector } from 'react-redux'
import { Progress, ResourceHook } from 'modules/types'
import { useDidMount, useAction, usePageQuery } from '@agro-club/frontend-shared'
import ProducerActions from './duck'
import ProducerSelectors from './selectors'
import { Company } from 'types/entities'

export const useProducerList: ResourceHook<Company[]> = () => {
  const progress = useSelector(ProducerSelectors.listFetchProgress)
  const list = useSelector(ProducerSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(ProducerActions.listRequested, {
    page: page || undefined,
    filter: {},
  })

  useDidMount(() => {
    if (progress === Progress.IDLE) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useProducer: ResourceHook<Company> = (idOrSlug: string) => {
  const meta = useSelector(state => ProducerSelectors.meta(state, idOrSlug))
  const item = useSelector(state => ProducerSelectors.item(state, idOrSlug))
  const fetchAction = useAction(ProducerActions.itemRequested, idOrSlug)

  useDidMount(() => {
    if (meta.fetchProgress !== Progress.WORK && (!item || (item.id !== idOrSlug && item.slug !== idOrSlug))) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}
